import { Directive, EventEmitter, Output, OnDestroy } from "@angular/core";
import { ConfigurationService } from "src/app/settings/types/configuration.service";
import { LocaleMap } from "src/app/global/constants/text/text-interface";
import { TextProvider } from "src/app/global/constants/text/text-provider";
import { ALERT_TYPE, MainService } from "src/app/global/main.service";

@Directive({
	selector: "[appSpeechToText]"
})
export class SpeechToTextDirective implements OnDestroy {
	@Output() outcome = new EventEmitter<string>();
	@Output() audio = new EventEmitter<Blob>();
	@Output() recordingEnd = new EventEmitter<void>();

	private readonly main: MainService;
	private readonly conf: ConfigurationService;
	private readonly text: () => LocaleMap;
	private isAudio: boolean = false;
	private audioStream: MediaStream | undefined;
	private audioRecorder: MediaRecorder | undefined;
	// @ts-ignore
	private recognition: SpeechRecognition | undefined;

	constructor(main: MainService, conf: ConfigurationService, textProv: TextProvider) {
		this.main = main;
		this.conf = conf;
		this.text = textProv.getStringMap;

		this.initialize();
	}

	ngOnDestroy(): void {
		this.cleanupResources();
	}

	public readonly startRecording = async (): Promise<void> => {
		this.cleanupResources();
		if (!("SpeechRecognition" in window || "webkitSpeechRecognition" in window)) {
			console.error("No speech recognition");
			this.main.addAlert(ALERT_TYPE.DANGER, this.text().SPEECH_NOT_SUPPORTED);
			return;
		}

		const SpeechRecognition = (window as any).SpeechRecognition || (window as any).webkitSpeechRecognition;
		this.recognition = new SpeechRecognition();

		switch (this.conf.configuration.language) {
			case "en":
				this.recognition.lang = "en-GB"; // TODO : maybe allow for locale selecting?
				break;
			case "es":
				this.recognition.lang = "es-ES";
				break;
		}

		// Recogniser doesn't stop listening even if the user pauses
		this.recognition.continuous = false;
		// Start recognising
		this.recognition.onresult = (event: any) => {
			try {
				const last = event.results[event.results.length - 1];
				this.outcome.emit(last[0].transcript);
				if (this.audioRecorder && this.audioRecorder.state === "recording") {
					this.audioRecorder.ondataavailable = this.onBlobAvailable;
					this.audioRecorder.requestData();
				} else {
					console.error("Recorder is inactive, cannot request data.");
				}
			} catch (e) {
				console.error("Error on result from voice.", e);
				this.main.addAlert(ALERT_TYPE.WARNING, this.text().SPEECH_ERROR);
			}
		};

		this.recognition.onerror = (event: any) => {
			console.error("Recognition error: ", event);
			this.main.addAlert(ALERT_TYPE.WARNING, this.text().SPEECH_ERROR);
			this.cleanupResources();
		};

		this.recognition.start();
		this.audioStream = await navigator.mediaDevices.getUserMedia({ audio: true });
		this.recognition.onnomatch = () => {
			this.main.addAlert(ALERT_TYPE.WARNING, this.text().SPEECH_ERROR);
		};
		this.recognition.onend = this.stopAudioRecord;

		this.recognition.onaudiostart = () => {
			this.isAudio = true;
		};

		this.recognition.onaudioend = () => {
			this.isAudio = false;
		};

		this.audioRecorder = new MediaRecorder(this.audioStream, { mimeType: "audio/webm" });
		this.audioRecorder.start();
	};

	public readonly stopRecognition: Function = () => {
		this.recognition.stop();
		if (!this.isAudio) this.stopAudioRecord(); //when there is audio, onend event will call stopAudioRecord after processing result (and not before!).
	};

	private readonly stopAudioRecord = (): void => {
		if (this.audioRecorder && this.audioRecorder.state === "recording") {
			this.audioRecorder.stop();
		}
		if (this.audioStream) {
			this.audioStream.getTracks().forEach((track) => track.stop());
		}
		this.recordingEnd.emit();
		this.cleanupResources();
	};

	private readonly onBlobAvailable = (event: BlobEvent): void => {
		if (event.data.size > 0) {
			this.audio.emit(event.data);
		} else {
			this.main.addAlert(ALERT_TYPE.WARNING, this.text().SPEECH_ERROR);
		}
	};

	private cleanupResources(): void {
		if (this.audioRecorder) {
			if (this.audioRecorder.state !== "inactive") {
				this.audioRecorder.stop();
			}
			this.audioRecorder.ondataavailable = null;
			this.audioRecorder = undefined;
		}
		if (this.audioStream) {
			this.audioStream.getTracks().forEach((track) => track.stop());
			this.audioStream = undefined;
		}
		if (this.recognition) {
			this.recognition.stop();
			this.recognition = undefined;
		}
	}

	private initialize(): void {
		window.AudioContext = window.AudioContext || (window as any).webkitAudioContext;
		(window as any).SpeechRecognition = (window as any).SpeechRecognition || (window as any).webkitSpeechRecognition || null;
		(navigator as any).getUserMedia = (navigator as any).webkitGetUserMedia || (navigator as any).mozGetUserMedia;
	}
}
