<div class="container">
	<div class="header">
		<div
			*ngFor="let condition of selection"
			class="chip top {{ isNotDefault(condition) ? 'selected' : '' }}"
			(click)="selectionClick(condition)"
		>
			<img src="resources/img/icons-fsg/blue-check.svg" class="check-icon" />
			<div *ngIf="isFire(condition)" class="fire-condition-icon">
				<img src="resources/img/Fire.svg" />
			</div>
			{{ getConditionLocalizedString(condition) }}
		</div>
		<div *ngIf="selection && !selection.length" class="chip top {{ 'selected' }}">
			{{ text().CONDITIONS }}
		</div>
	</div>
	<div class="body">
		<div class="set-text">
			<span>{{ this.text().FSG_SELECT_MORE_TEXT }}:</span>
		</div>
		<div class="option-list">
			<div
				*ngFor="let option of options"
				class="chip option {{ !isConditionSelected(option) ? 'available' : '' }}"
				(click)="optionClick(option)"
			>
				<div *ngIf="isFire(option)" class="fire-condition-icon">
					<img src="resources/img/Fire.svg" />
				</div>
				{{ getConditionLocalizedString(option) }}
			</div>
			<div *ngIf="openNewInput" class="chip option">
				<input
					autofocus
					appDetectClickOut
					[onlyOnce]="true"
					(callback)="newValueClickOut()"
					[(ngModel)]="newValue"
					[maxlength]="20"
				/>
			</div>
			<div
				class="add-button {{ openNewInput ? 'disabled' : '' }}"
				(click)="toggleNewInput($event)"
			></div>
		</div>
	</div>
</div>
<div class="main-black-mask" (click)="selectEmit.emit(selection)"></div>
