<div class="incident-header" [class.transparent]="isLoginPage()">
	<div class="logo-id-container">
		<div class="logo-section">
			<img
				data-add-action-name="logo-button"
				src="resources/img/header-logo.svg"
				class="logo"
				(click)="hasAccessToHomePage() ? headerNavigation() : ''"
				[class.disabled]="!hasAccessToHomePage()"
			/>
		</div>

		<div
			*ngIf="incidentId && shouldShowIncidentInfo()"
			data-add-action-name="id-section"
			class="id-container"
		>
			<app-header-chip (clickEvent)="toggleIncidentInfo()">
				<span class="subtext"> {{ text().INCIDENT_ID }}</span>
				<span data-cy="incident-id" class="incident-id">{{ incidentId }}</span>
			</app-header-chip>
			<div class="info-container-hidden" [class.info-container-show]="showIncidentInfo">
				<span class="text" (click)="headerNavigation()">{{
					isInHistoric() ? text().CLOSED_INCIDENT : text().LIVE_INCIDENTS_OFV
				}}</span>
				<img src="resources/img/Chevron-header.svg" class="chevron-header" />
				<span [attr.data-cy]="'name-' + eventName" data-cy="incident-name" class="name">{{
					eventName
				}}</span>
			</div>
		</div>
	</div>

	<app-online-offline-banner
		[showIncidentInfo]="showIncidentInfo"
		[showOnlineBanner]="showOnlineBanner"
		[isOffline]="isOffline"
		[isLoginPage]="isLoginPage()"
	></app-online-offline-banner>

	<div
		[ngClass]="{
			'section-right': true,
			'margin-right-12': !isBurgerMenuVisible,
			'margin-left-0': showIncidentInfo && !isOffline
		}"
	>
		<span *ngIf="!isLoginPage()" class="date">{{ getDateString() }}</span>
		<span *ngIf="isLoginPage()" class="version">V {{ version }} </span>

		<img
			*ngIf="isLoginPage() && isOffline"
			src="resources/img/offline-cloud.svg"
			class="offline"
			(click)="toggleTooltip()"
		/>
		<div *ngIf="isTooltipVisible" class="tooltip-text-container">
			<span>{{ text().MICROSOFT }}</span
			><span>{{ text().SESSION_SYNC }}</span>
		</div>

		<app-support-dialog-container></app-support-dialog-container>
	</div>
</div>
