import { Injectable } from "@angular/core";
import Keycloak, { KeycloakProfile } from "keycloak-js";
import { Subject } from "rxjs";
import { environment } from "src/environments/environment";
import { AUTH } from "../global/constants/enums/auth";
import { ConstantsService } from "../global/constants/constants.service";

@Injectable({
	providedIn: "root"
})
export class SsoService {
	public readonly ssoAuthenticated$ = new Subject<{ username: string; token: string }>();
	public authenticated: boolean = false;

	private cnst: ConstantsService;
	private keycloakService!: Keycloak;

	constructor(cnst: ConstantsService) {
		this.cnst = cnst;
	}

	public readonly initKC = (): Promise<boolean> => {
		if (this.cnst.AUTH === AUTH.SSO) {
			this.keycloakService = new Keycloak(this.cnst.KEYCLOAK_CONFIG);
			this.keycloakService.onAuthSuccess = () => {
				this.authenticated = true;
			};
			return this.keycloakService.init({ onLoad: "check-sso" }).then(
				(authenticated) => {
					if (authenticated && this.token) {
						this.loadKCUserProfile().then((user: KeycloakProfile) => {
							this.ssoAuthenticated$.next({ username: user && user.username ? user.username : "", token: this.token ? this.token : "" });
						});
						return true;
					}
					return false;
				},
				(err: Error) => {
					console.error(err);
					// Timeout error when waiting for 3rd party check iframe message (when KC server is down).
					this.ssoAuthenticated$.next({ username: "", token: "" });
					return false;
				}
			);
		}
		return Promise.resolve(false);
	};

	public readonly loadKCUserProfile = (): Promise<KeycloakProfile> => {
		return this.keycloakService.loadUserProfile();
	};

	public loginKC(): void {
		this.keycloakService.login();
	}

	public async logoutKC(): Promise<void> {
		return await this.keycloakService.logout();
	}

	public readonly isLoggedIn = (): boolean | undefined => {
		return this.keycloakService.authenticated;
	};

	public clearTokenKC(): void {
		return this.keycloakService.clearToken();
	}

	get token(): string | undefined {
		return this.keycloakService.token;
	}
}

// DO NOT DELETE To put inside an interval
// this.keycloakService
// 	.updateToken(5)
// 	.then((refreshed) => {
// 		if (refreshed) {
// 			console.log("Token was successfully refreshed");
// 		} else {
// 			console.log("Token is still valid");
// 		}
// 	})
// 	.catch(() => console.log("Failed to refresh the token, or the session has expired"));>
