import { DTO } from "./dto";

export class WSRequest implements DTO {
	idElement: number | null;
	idMission: number | null;
	DAO: any | null;
	timestamp: number;

	id: number;

	isNullRequest?: boolean; // if a request has to have a null timestamp

	constructor(id?: number | null, id_mission?: number | null, element?: any | null, timestamp?: number | null) {
		this.idElement = id ? id : null;
		this.id = id ? id : -1;
		this.idMission = id_mission ? id_mission : null;
		this.DAO = element ? element : null;
		this.timestamp = timestamp ? timestamp : Date.now();
	}

	public static readonly getNullRequest: Function = () => {
		let ans = new WSRequest();
		ans.isNullRequest = true;
		return ans;
	};

	public readonly getJson: Function = () => {
		var elementJson = this.DAO === null ? "null" : this.DAO.getJson !== undefined ? this.DAO.getJson() : JSON.stringify(this.DAO);
		var timestampJson = this.timestamp == -1 ? "null" : JSON.stringify(this.timestamp);
		return this.isNullRequest ? '{"elementId":null,"missionId":null,"DAO":null,"timestamp":null}' : '{"elementId":' + this.idElement + ',"missionId":' + this.idMission + ',"DAO":' + elementJson + ',"timestamp":' + timestampJson + "}";
	};
}
