<div class="map-label-container poi-label-container">
	<div class="map-label">
		<div class="name">
			{{ poi.name }}
		</div>
		<div class="coords">
			{{ poi.position.latitude.toFixed(4) }}º - {{ poi.position.longitude.toFixed(4) }}º
		</div>
		<div class="status">
			{{ getStatusText() }}
		</div>
	</div>
</div>
