<div class="container  {{ header !== '' ? '' : 'small' }}">
	<div *ngIf="header !== ''" class="header">{{ header }}</div>
	<div class="item">
		<div class="color-box">
			<div [style.backgroundColor]="sector_colours.SEARCH"></div>
		</div>
		<div class="text">
			{{ this.text().FSG_SEARCH_SECTOR }}
		</div>
	</div>
	<div class="item">
		<div class="color-box">
			<div [style.backgroundColor]="sector_colours.FIRE"></div>
		</div>
		<div class="text">
			{{ this.text().FSG_FIRE_SECTOR }}
		</div>
	</div>
	<div class="item">
		<div class="color-box">
			<div [style.backgroundColor]="sector_colours.BRIDGE"></div>
		</div>
		<div class="text">
			{{ this.text().FSG_BRIDGE_SECTOR }}
		</div>
	</div>
	<div class="item">
		<div class="color-box">
			<div [style.backgroundColor]="sector_colours.LOBBY"></div>
		</div>
		<div class="text">
			{{ this.text().FSG_LOBBY_SECTOR }}
		</div>
	</div>
</div>
