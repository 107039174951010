<div
	data-cy="skills-display"
	class="skills-container"
	#skillsContainer
	*ngIf="hasSkills()"
	appDetectClickOut
	(callback)="closeSkills()"
>
	<div
		class="initial-skills"
		[class.open]="overflowSkillsVisible"
		[class.closed]="!overflowSkillsVisible"
		[class.flex]="resource.skills?.length && resource.skills.length <= 3"
	>
		<div
			*ngFor="let skill of getInitialSkills; let i = index"
			class="skill-item"
			[style.width.px]="size"
			[style.height.px]="size"
			(mouseenter)="showTooltip(getGlobalIndex(i, false), $event)"
			(mouseleave)="hideTooltip()"
			(touchend)="toggleTooltip(getGlobalIndex(i, false), $event); $event.preventDefault()"
		>
			<img [src]="getImageUrl(skill.name)" [style.width.px]="size" [style.height.px]="size" />
		</div>
	</div>

	<button
		data-add-action-name="toggle-skills"
		*ngIf="getOverflowSkills.length > 0"
		class="skills-overflow"
		[style.width.px]="size"
		[style.height.px]="size"
		(click)="toggleSkillsOverflow(outerIndex)"
		(touchend)="toggleSkillsOverflow(outerIndex); $event.preventDefault()"
		[class.active]="overflowSkillsVisible"
		[class.disabled]="disabled"
	>
		<span>+{{ getOverflowSkills.length }}</span>
	</button>

	<div *ngIf="overflowSkillsVisible" class="overflow-skills">
		<div
			*ngFor="let skill of getOverflowSkills; let i = index"
			class="skill-item"
			[style.width.px]="size"
			[style.height.px]="size"
			(mouseenter)="showTooltip(getGlobalIndex(i, true), $event)"
			(mouseleave)="hideTooltip()"
			(touchend)="toggleTooltip(getGlobalIndex(i, true), $event); $event.preventDefault()"
		>
			<img [src]="getImageUrl(skill.name)" [style.width.px]="size" [style.height.px]="size" />
		</div>
	</div>
</div>
<div
	*ngIf="currentTooltipIndex !== null && shouldShowTooltip"
	class="tooltip"
	[style.top.px]="tooltipPosition.top"
	[style.left.px]="tooltipPosition.left"
	[style.height.px]="size"
	role="tooltip"
>
	{{ getSkillNameByIndex(currentTooltipIndex) }}
</div>
