<div class="main-black-mask"></div>
<div class="alert-dialog closed-incident-info-dialog">
	<div class="dialog-header">
		{{ headerText }}
	</div>
	<app-white-cross class="dialog-cross-button" (clickCb)="closeCallback.emit()"></app-white-cross>
	<div class="icon-container">
		<div class="info-icon">
			<img src="resources/img/information.svg" />
		</div>
	</div>
	<div class="body">
		{{ bodyText }}
	</div>
</div>
