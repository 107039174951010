<app-login-unblur
	*ngIf="offline || (authMode && authMode === 'unblur')"
	(log)="log($event)"
	[credentials]="credentials"
></app-login-unblur>
<app-login-sso
	*ngIf="!offline && authMode && authMode === 'sso'"
	[authenticated]="authenticated"
	(login)="log()"
></app-login-sso>
