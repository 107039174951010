import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { UIModule } from "../widgets/ui/ui.module";
import { MainDialogComponent } from "./components/main-dialog/main-dialog.component";
import { MainSnackbarComponent } from "./components/main-snackbar/app-main-snackbar.component";
import { IncidentPlayerComponent } from "./automation/components/incident-player/app-incident-player.component";
import { MainLoadSnackbarComponent } from "./components/main-load-snackbar/app-main-load-snackbar.component";

@NgModule({
	declarations: [MainDialogComponent, MainSnackbarComponent, IncidentPlayerComponent, MainLoadSnackbarComponent],
	imports: [CommonModule, UIModule],
	exports: [MainDialogComponent, MainSnackbarComponent, IncidentPlayerComponent, MainLoadSnackbarComponent],
	schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class MainModule {}
