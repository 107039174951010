<div class="main-input-container">
	<div
		class="inner-container main-input-field {{ isFilled() ? 'filled' : '' }} {{
			focus ? 'focus' : ''
		}}"
		[class.disabled]="isDisabled"
		(click)="setFocus($event)"
		[style.paddingTop.px]="padding"
		[style.paddingBottom.px]="padding"
		[style.paddingRight.px]="paddingRight"
	>
		<textarea
			#inputField
			data-cy="simple-text-box"
			*ngIf="fixedHeight"
			class="main-input-field"
			[class.gray]="isDisabled"
			[(ngModel)]="data"
			(ngModelChange)="onChange($event)"
			(blur)="unsetFocus()"
			[placeholder]="!focus ? placeholderText : ''"
			[rows]="rows ? rows : 'auto'"
			wrap="hard"
			[disabled]="isDisabled"
			[class.disabled]="isDisabled"
		>
		</textarea>
		<textarea
			#inputField
			data-cy="simple-text-box"
			*ngIf="!fixedHeight"
			class="main-input-field"
			[class.gray]="isDisabled"
			appAutoResize
			[style.max-height.px]="maxHeight"
			[style.min-height.px]="minHeight"
			[(ngModel)]="data"
			(ngModelChange)="onChange($event)"
			(blur)="unsetFocus()"
			[placeholder]="!focus ? placeholderText : ''"
			[rows]="rows ? rows : 'auto'"
			wrap="hard"
			[class.disabled]="isDisabled"
			maxlength="{{ maxLength }}"
			(wheel)="$event.stopPropagation()"
			[disabled]="isDisabled"
		></textarea>
	</div>
	<label [class.gray]="isDisabled" [ngStyle]="{ 'background-color': labelBackground }">{{
		label
	}}</label>
</div>
