import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { CSSector } from "../dto/command-structure/cs-sector";
import { AraService } from "../incident/incident-tools/ara/ara.service";
import { CommandStructureService } from "../incident/incident-tools/command-structure/command-structure.service";
import { DecisionLogService } from "../incident/incident-tools/forms/decision-log/decision-log.service";
import { FormsService } from "../incident/incident-tools/forms/forms.service";
import { FsgService } from "../incident/incident-tools/fsg/fsg.service";
import { WebRequestFactory } from "../http/web.request.factory";
import { DOCUMENT_TYPE } from "../dto/Documents/document-type";
import { DocumentData } from "../dto/Documents/document-data";
import { PAGE_SIZES } from "../dto/Documents/page-sizes";
import { LocaleMap } from "../global/constants/text/text-interface";
import { TextProvider } from "../global/constants/text/text-provider";
import { DocsInProgress, PagesAsCanvas, PreparedDocs, ZipFileData } from "../dto/Documents/document";
import { CommandStructure } from "../dto/command-structure/command-structure";
import { Decision } from "../dto/decision/decision";
import { FsgBuilding } from "../dto/fsg/fsg-building";
import JSZip from "jszip";
import * as jspdf from "jspdf";
import { FUNCTIONALITY, FunctionalityService } from "../global/functionality.service";
import { VideoService } from "../incident/video/video.service";
import { Incident } from "../dto/items/incident";

@Injectable({
	providedIn: "root"
})
export class DocumentService {
	public readonly downloadSignal$ = new Subject<{ type: DOCUMENT_TYPE; data: DocumentData }>();
	public readonly loadingMessageChange$ = new Subject<string>();
	public readonly unavailableError$ = new Subject<string>();
	public readonly downloadError$ = new Subject<void>();
	public readonly finishedDownloadInit$ = new Subject<void>();
	public readonly onDownloadCancel$ = new Subject<void>();

	public readonly downloadableDocuments = [
		DOCUMENT_TYPE.DECISION_LOG
	];

	public downloadingFullIncident = false;
	public pendingDocumentsToInit = false;
	public isDLOperational: boolean = true;
	public isDLSignature: boolean = true;
	public isDLType: boolean = true;
	public araScore: boolean = true;
	public araRight: boolean = true;

	private readonly jesipService: FormsService;
	private readonly commService: CommandStructureService;
	private readonly araService: AraService;
	private readonly decisionLogService: DecisionLogService;
	private readonly efService: FsgService;
	private readonly wreq: WebRequestFactory;
	private text: () => LocaleMap;
	private readonly funcService: FunctionalityService;
	private readonly videoService: VideoService;

	private incidentVODZip: Blob | undefined;

	constructor(cs: CommandStructureService, ds: DecisionLogService, ara: AraService, jesips: FormsService, efs: FsgService, wreq: WebRequestFactory, tp: TextProvider, funcService: FunctionalityService, vs: VideoService) {
		this.commService = cs;
		this.decisionLogService = ds;
		this.araService = ara;
		this.efService = efs;
		this.jesipService = jesips;
		this.wreq = wreq;
		this.text = tp.getStringMap;
		this.funcService = funcService;

		this.araScore = this.funcService.isFunctionalityAvailable.get(FUNCTIONALITY.ARA_SCORE)!;
		this.araRight = this.funcService.isFunctionalityAvailable.get(FUNCTIONALITY.ARA_RIGHT)!;
		this.videoService = vs;
	}

	/******** NOTE: DO NOT CALL THIS METHOD DIRECTLY in full incident download, instead use downloadDocumentOfFullIncident() ********/
	public async downloadDocument(type: DOCUMENT_TYPE, data?: DocumentData): Promise<void> {
		this.downloadSignal$.next({ type: type, data: data ? data : {} });
		this.funcService.getFunctionalityVisibility();
		this.isDLOperational = this.funcService.isDLOperational;
		this.isDLSignature = this.funcService.isDLSignature;
		this.isDLType = this.funcService.isDLType;
	}

	public async downloadFileFromServer(type: DOCUMENT_TYPE, data?: DocumentData): Promise<Blob | undefined> {
		switch(type){
			case DOCUMENT_TYPE.DECISION_LOG:
				if(!data || !data.evt){
					throw "Attempted decision log download without specifying incident";
				}
				return this.wreq.getIncidentDecisionLog(data.evt);
		}
		return;
	}

	/**
	 * Convert canvas[] to pdf pages, compress and download files.
	 * If no preparedDocs it's being called becauuse there's streamings but no documents
	 * Any error thrown by this or it's child methods will be catched on the caller method.
	 */
	public async downloadAllPreparedDocs(currentEvent: Incident, preparedDocs?: PreparedDocs): Promise<void> {
		this.setLoadingMessage(this.text().DOWNLOADING_FILES);
		const pdfs = this.createPdfs(preparedDocs);
		this.compressAndDownloadFiles(currentEvent, pdfs);
		this.downloadingFullIncident = false;
	}

	public readonly setLoadingMessage = (text: string): void => {
		this.loadingMessageChange$.next(text);
	};

	public readonly setUnavailabeError = (): void => {
		this.unavailableError$.next();
		this.onDownloadCancel();
	};

	public createStreamingsBaseName(currentEvent: Incident): string {
		return currentEvent.getFormattedDate() + "_" + currentEvent.num + "_STREAMINGS.zip";
	}


	public createFileName( 
		currentEvent: Incident,
		docType: DOCUMENT_TYPE, 
		docsInProgress: DocsInProgress,
		index?: number
	
	): string {
		const baseName = currentEvent.getFormattedDate() + "_" + currentEvent.num + "_";
		index = index ?? 0;
		switch (docType) {
			case DOCUMENT_TYPE.DECISION_LOG:
				return `${baseName}DLL`;
			case DOCUMENT_TYPE.ARA:
				let secName;
				const araDoc = docsInProgress.get(DOCUMENT_TYPE.ARA);
				secName = (araDoc && araDoc.documents && araDoc.documents.length && araDoc.documents[index].sectorWithARAs && araDoc.documents[index].sectorWithARAs!.id_sector !== -3 && araDoc.documents[index].sectorWithARAs?.name) || "WI";
				const prefix = this.araRight || this.araScore ? "ARA_" : "TASK_MANAGER_";
				return `${baseName}${prefix}${secName}`;

			case DOCUMENT_TYPE.FSG_BLANK:
				const fsgBlankDoc = docsInProgress.get(DOCUMENT_TYPE.FSG_BLANK);
				const fsgName = (fsgBlankDoc && fsgBlankDoc.documents && fsgBlankDoc.documents.length && fsgBlankDoc.documents[0].fsg && fsgBlankDoc.documents[0].fsg.name) || "";
				return `${baseName}${fsgName}_EF_blank`;

			case DOCUMENT_TYPE.FSG_HISTORY:
				const fsgHistoryDoc = docsInProgress.get(DOCUMENT_TYPE.FSG_HISTORY);
				const fsgHistoryName = (fsgHistoryDoc && fsgHistoryDoc.documents && fsgHistoryDoc.documents.length && fsgHistoryDoc.documents[index].fsg && fsgHistoryDoc.documents[index].fsg!.name) || "";
				return `${baseName}EF_${fsgHistoryName}`;

			case DOCUMENT_TYPE.METHANE_BLANK:
				return `${baseName}JM_blank`;

			case DOCUMENT_TYPE.METHANE_CURRENT:
				return `${baseName}JM1`;

			case DOCUMENT_TYPE.METHANE_HISTORY:
				return `${baseName}JM${index + 1}`;

			case DOCUMENT_TYPE.IIMARCH_BLANK:
				return `${baseName}JII_blank`;

			case DOCUMENT_TYPE.IIMARCH_CURRENT:
				return `${baseName}JII1`;

			case DOCUMENT_TYPE.IIMARCH_HISTORY:
				return `${baseName}JII${index + 1}`;

			case DOCUMENT_TYPE.JDM_BLANK:
				return `${baseName}JJDM_blank`;

			case DOCUMENT_TYPE.JDM_CURRENT:
				return `${baseName}JJDM_blank`;

			case DOCUMENT_TYPE.JDM_HISTORY:
				return `${baseName}JJDM${index + 1}`;

			case DOCUMENT_TYPE.DEBRIEF_BLANK:
				return `${baseName}JD_blank`;

			case DOCUMENT_TYPE.DEBRIEF_LAST:
				return `${baseName}JD`;

			case DOCUMENT_TYPE.CHECKLIST_BLANK:
				return `${baseName}JCL_blank`;

			case DOCUMENT_TYPE.CHECKLIST_LAST:
				return `${baseName}JCL`;

			case DOCUMENT_TYPE.COMMAND_STRUCTURE:
				return `${baseName}CS`;

			default:
				return "";
		}
	}

	public splitString(description: string, splitSize: number): string[] {
		const split: string[] = [];
		let startPos = 0;
		const totalLength = description.length;

		while (startPos < totalLength) {
			const endPos = Math.min(startPos + splitSize, totalLength);
			split.push(description.slice(startPos, endPos));
			startPos += splitSize;
		}

		return split;
	}

	public onDownloadCancel(): void {
		this.downloadingFullIncident = false;
		this.pendingDocumentsToInit = false;
		this.onDownloadCancel$.next();
	}

	// ********** INCIDENT DATA LOAD START **********
	/**
	 * This method is called from the closed incidents screen.
	 * It's purpose is to load the required data for all the documents to be downloaded for an incident
	 * and emit the download call for each of them. As per current implementation, It is required to load all the data first
	 * and then make the download calls all together.
	 * Once all the data is downloaded, the call to downloadAttachments method is fired.
	 * @param evt provides the id_mission
	 * @returns boolean => if false, show a dialog to confirm that there is no document to be downloaded
	 * TODO: A possible refactor may be required to this behaviour which should pass all the data as a single item, instead of
	 * various calls to downloadDocument which may create race condition issues.
	 * ¿shift this data loading to BE?
	 */
	public async initIncidentDownload(evt: Incident): Promise<boolean> {
		try {
			this.downloadingFullIncident = this.pendingDocumentsToInit = true;
			let hasDocumentsToDownload = false;
			let hasAttachments = false;

			this.setLoadingMessage(this.text().PREPARING_DOCUMENTATION);

			const evacuationForms = await this.loadEFData(evt.id);
			if (!this.downloadingFullIncident) return true;
			evacuationForms && evacuationForms.length && (hasDocumentsToDownload = true) && this.initEFDownload(evacuationForms, evt);

			const sectorWithAras = await this.loadIncidentARAs(evt.id);
			if (!this.downloadingFullIncident) return true;
			sectorWithAras && sectorWithAras.length && (hasDocumentsToDownload = true) && this.initARADownload(sectorWithAras, evt);

			const decisionLogs = await this.loadDLData(evt.id);
			if (!this.downloadingFullIncident) return true;
			decisionLogs && decisionLogs.length && (hasDocumentsToDownload = true) && this.initDLDownload(decisionLogs, evt);

			const commandStructer = await this.loadCSData(evt.id);
			if (!this.downloadingFullIncident) return true;
			commandStructer && (hasDocumentsToDownload = true) && this.initCSDownload(commandStructer, evt);

			const debrief = await this.jesipService.getDebrief(evt.id);
			if (!this.downloadingFullIncident) return true;
			debrief && this.jesipService.isDebriefInit() && (hasDocumentsToDownload = true) && this.initDebriefDownload(evt);

			const methane = await this.jesipService.loadMethane(evt.id);
			if (!this.downloadingFullIncident) return true;
			methane && (hasDocumentsToDownload = true) && this.initMethaneDownload(evt);

			const iimarch = await this.jesipService.loadIiMarch(evt.id);
			if (!this.downloadingFullIncident) return true;
			iimarch && (hasDocumentsToDownload = true) && this.initIiMarchDownload(evt);

			const jdm = await this.jesipService.loadJdm(evt.id);
			if (!this.downloadingFullIncident) return true;
			jdm && (hasDocumentsToDownload = true) && this.initJdmDownload(evt);

			this.setLoadingMessage(this.text().DOWNLOADING_CHECKING_VIDEO_DOTTED);
			this.incidentVODZip = await this.downloadVODs(evt);

			// you still get an empty zip file if no recordings, which tends to be around 20~ bytes
			if (this.incidentVODZip && this.incidentVODZip.size > 100) {
				if (!hasDocumentsToDownload) {
					// only streamings done, so proceed to incident download
					this.downloadAllPreparedDocs(evt);
					if (this.downloadingFullIncident) hasAttachments = await this.getIncidentAttachments(evt);
					return true;
				}
			}

			this.setLoadingMessage(this.text().PREPARING_DOCUMENTATION);

			this.pendingDocumentsToInit = false;
			if (this.downloadingFullIncident && hasDocumentsToDownload) this.finishedDownloadInit$.next();

			hasAttachments = await this.getIncidentAttachments(evt);

			if (!hasDocumentsToDownload) {
				this.onDownloadCancel();
			}

			return hasDocumentsToDownload || hasAttachments;
		} catch (error) {
			if (this.downloadingFullIncident) {
				this.onDownloadCancel();
				console.error("error", error);
				return Promise.reject(false);
			} else return true;
		}
	}

	private async downloadVODs(event: Incident): Promise<Blob | undefined> {
		try {
			const vodzip = await this.videoService.downloadIncidentVODs(event);
			return vodzip;
		} catch (e) {
			return undefined;
		}
	}

	private downloadDocumentOfFullIncident(type: DOCUMENT_TYPE, data?: DocumentData): void {
		if (this.downloadingFullIncident) this.downloadDocument(type, data);
	}

	private async loadCSData(id_incident: number): Promise<CommandStructure | undefined> {
		const com = await this.commService.getStructureFromMission(id_incident);
		return com;
	}

	private async loadIncidentARAs(id_incident: number): Promise<CSSector[]> {
		await this.commService.getStructureFromMission(id_incident);
		await this.araService.loadStaticData(id_incident);
		await this.araService.loadMissionData(id_incident);

		let sectorsWithAras: Array<CSSector> = [];
		for (let i = 0; i < this.araService.ARAs.length; i++) {
			const ara = this.araService.ARAs[i];
			await this.araService.loadAraSignature(ara);
			const idx = sectorsWithAras.findIndex((e: CSSector) => e?.id === ara?.id_ics_sector && ara?.id_mission === id_incident);
			if (idx === -1) {
				let sector = this.araService.sectors.find((e) => e?.id === ara?.id_ics_sector);
				sector && sectorsWithAras.unshift(sector);
			}
		}
		return sectorsWithAras;
	}

	private async loadDLData(id_incident: number): Promise<Decision[] | undefined> {
		await this.decisionLogService.updateDecisions(false, id_incident);
		const decisions = this.decisionLogService.Decisions.filter((e) => e.id_mission === id_incident);
		return decisions;
	}

	private async loadEFData(id_incident: number): Promise<FsgBuilding[] | undefined> {
		await this.efService.getFsg(id_incident);
		const ef = this.efService.getCurrentActiveForms(id_incident);
		return ef;
	}

	private initCSDownload(com: CommandStructure, evt: Incident): void {
		const comData: DocumentData = { commandStructure: com, evt: evt };
		this.downloadDocumentOfFullIncident(DOCUMENT_TYPE.COMMAND_STRUCTURE, comData);
	}

	private initARADownload(sectorsWithAras: CSSector[], evt: Incident): void {
		for (let i = 0; i < sectorsWithAras.length; i++) {
			const sector = sectorsWithAras[i];
			if (sector?.aras?.length) {
				const araData: DocumentData = {
					sectorWithARAs: sector,
					evt: evt
				};
				this.downloadDocumentOfFullIncident(DOCUMENT_TYPE.ARA, araData);
			}
		}
	}

	private initDLDownload(decisions: Decision[], evt: Incident): void {
		const decData: DocumentData = {
			decisions: decisions,
			evt: evt
		};
		this.downloadDocumentOfFullIncident(DOCUMENT_TYPE.DECISION_LOG, decData);
	}

	private async initEFDownload(ef: FsgBuilding[], evt: Incident): Promise<void> {
		for (let i = 0; i < ef.length; i++) {
			const el = ef[i];
			const allFlats = await this.efService.getAllFlatValues(el);
			if (allFlats && allFlats.length) el.__history = this.efService.setupFsgHistory(allFlats);
			if (el.__history && el.__history.length) {
				const efData: DocumentData = {
					fsg: el,
					evt: evt
				};
				this.downloadDocumentOfFullIncident(DOCUMENT_TYPE.FSG_HISTORY, efData);
			}
		}
	}

	private async initDebriefDownload(evt: Incident): Promise<void> {
		const df = this.jesipService.currentDebrief;

		if (this.jesipService.isDebriefInit() && df) {
			const dfData: DocumentData = { evt: evt };
			this.downloadDocumentOfFullIncident(DOCUMENT_TYPE.DEBRIEF_LAST, dfData);
		}
	}

	private async initMethaneDownload(evt: Incident): Promise<void> {
		const mth = this.jesipService.methaneHistory.sort((a, b) => (a.timestamp_ms > b.timestamp_ms ? 1 : -1));
		if (mth) {
			for (let i = 0; i < mth.length; i++) {
				const el = mth[i];
				const mthData: DocumentData = { jesipMethane: el, evt: evt };
				el && this.downloadDocumentOfFullIncident(DOCUMENT_TYPE.METHANE_HISTORY, mthData);
			}
		}
	}

	private async initIiMarchDownload(evt: Incident): Promise<void> {
		const iimh = this.jesipService.IiMarchHistory.sort((a, b) => (a.timestamp_ms > b.timestamp_ms ? 1 : -1));
		if (iimh) {
			for (let i = 0; i < iimh.length; i++) {
				const el = iimh[i];
				const iimData: DocumentData = { jesipIimarch: el, evt: evt };
				el && this.downloadDocumentOfFullIncident(DOCUMENT_TYPE.IIMARCH_HISTORY, iimData);
			}
		}
	}

	private async initJdmDownload(evt: Incident): Promise<void> {
		const jdmh = this.jesipService.jdmHistory.sort((a, b) => (a.timestamp_ms > b.timestamp_ms ? 1 : -1));
		if (jdmh) {
			for (let i = 0; i < jdmh.length; i++) {
				const el = jdmh[i];
				const jdmData: DocumentData = { jesipJdm: el, evt: evt };
				el && this.downloadDocumentOfFullIncident(DOCUMENT_TYPE.JDM_HISTORY, jdmData);
			}
		}
	}

	private async getIncidentAttachments(evt: Incident): Promise<boolean> {
		const attachResult = await this.wreq.getIncidentAttachments(evt);
		if (!attachResult || attachResult.size === 0) return false;
		const regex = /\-/g;
		const folderName = evt.start_time.split("T", 1)[0].replace(regex, "");
		const incidentNumber = evt.num;
		const a = document.createElement("a");
		a.href = window.URL.createObjectURL(attachResult);
		a.download = folderName + "_" + incidentNumber + "_MEDIA.zip";
		a.click();
		return true;
	}
	// ********** INCIDENT DATA LOAD END **********

	private createPdfs(preparedDocs?: PreparedDocs): ZipFileData[] | undefined {
		if (!preparedDocs) return;
		const output: ZipFileData[] = [];

		for (const [type, docs] of preparedDocs) {
			if (!docs) return output;
			const size = PAGE_SIZES.STANDARD;
			if(docs instanceof Blob){
				output.push({ fname: docs.name, data: docs, type })
			}
			else{
				for (const doc of docs) {
					const pdf = new jspdf.jsPDF();
					const outputW = pdf.internal.pageSize.getWidth();
					const outputH = (size.height / size.width) * outputW;

					for (let k = 0; k < doc.pages.length; k++) {
						if (k) pdf.addPage();
						pdf.addImage(doc.pages[k], "jpeg", 0, 0, outputW, outputH);
					}

					output.push({ fname: doc.name ? doc.name : type + " " + (output.length + 1), data: pdf, type });
				}
			}
			preparedDocs.delete(type);
		}

		return output;
	}

	private async compressAndDownloadFiles(currentEvent: Incident, data?: ZipFileData[]): Promise<void> {
		this.setLoadingMessage(this.text().COMPRESSING_DOCUMENTATION);

		const zip = new JSZip();
		if (data) {
			for (const doc of data) {
				this.saveDocToZip(currentEvent, doc, zip);
			}
		}
		if (this.incidentVODZip) zip.file(this.createStreamingsBaseName(currentEvent), this.incidentVODZip);
		this.incidentVODZip = undefined;
		this.generateZipAndDownload(zip, currentEvent);
	}

	private saveDocToZip(currentEvent: Incident, doc: ZipFileData, zip: JSZip): void {
		if (currentEvent.closed) {
			let folderName = "";
			let fileName = doc.fname.split("_")[0] + "_" + doc.fname.split("_")[1];
			switch (doc.type) {
				case DOCUMENT_TYPE.ARA:
					folderName = fileName + "_ARAS";
					break;
				case DOCUMENT_TYPE.DECISION_LOG:
					folderName = fileName + "_FORMS/" + fileName + "_DL";
					break;
				case DOCUMENT_TYPE.FSG_HISTORY:
					folderName = fileName + "_FORMS/" + fileName + "_EF";
					break;
				case DOCUMENT_TYPE.IIMARCH_HISTORY:
					folderName = fileName + "_FORMS/" + fileName + "_JESIP/" + fileName + "_IIMARCH";
					break;
				case DOCUMENT_TYPE.METHANE_HISTORY:
					folderName = fileName + "_FORMS/" + fileName + "_JESIP/" + fileName + "_METHANE";
					break;
				case DOCUMENT_TYPE.JDM_HISTORY:
					folderName = fileName + "_FORMS/" + fileName + "_JESIP/" + fileName + "_JDM";
					break;
				case DOCUMENT_TYPE.DEBRIEF_LAST:
					folderName = fileName + "_FORMS/" + fileName + "_JESIP";
					break;
			}
			if (folderName) zip.folder(folderName)?.file(doc.fname + ".pdf", doc.data instanceof File ? doc.data : doc.data.output("blob"));
			else this.createDefaultFileStructure(doc, zip);
		} else this.createDefaultFileStructure(doc, zip);
	}

	private createDefaultFileStructure(doc: ZipFileData, zip: JSZip): void {
		const fileName = doc.fname + ".pdf";
		const output = doc.data instanceof File ? doc.data : doc.data.output("blob") ;
		switch (doc.type) {
			// case DOCUMENT_TYPE.FSG_HISTORY:
			// 	zip.folder("EF")?.file(fileName, output);
			// 	break;
			case DOCUMENT_TYPE.COMMAND_STRUCTURE:
				zip.folder(doc.fname.slice(0, doc.fname.length - 3) + "_AGENCYORGANISATION")?.file(fileName, output);
				break;
			default:
				zip.file(fileName, output);
				break;
		}
	}

	private generateZipAndDownload(zip: JSZip, currentEvent: Incident): void {
		zip.generateAsync({
			type: "base64",
			compression: "DEFLATE",
			compressionOptions: {
				level: 5
			}
		}).then((content: string) => {
			const a = document.createElement("a");
			a.href = "data:application/zip;base64," + content;
			const folderNameMainFolder = currentEvent.getFormattedDate() + "_" + currentEvent.num;
			if (currentEvent) a.download = folderNameMainFolder + ".zip";
			this.setLoadingMessage("");
			a.click();
		});
	}

	
}
