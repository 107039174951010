import { Directive, EventEmitter, HostListener, Output } from "@angular/core";

@Directive({
	selector: "[appHover]"
})
export class HoverDirective {
	@Output() isHovered = new EventEmitter<boolean>();

	@HostListener("mouseover")
	onMouseOver(): void {
		this.isHovered.emit(true);
	}

	@HostListener("mouseout")
	onMouseOut(): void {
		this.isHovered.emit(false);
	}
}
