import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Camera } from "src/app/dto/resources/camera";
import { Personnel } from "src/app/dto/resources/personnel";
import { LocaleMap } from "src/app/global/constants/text/text-interface";
import { TextProvider } from "src/app/global/constants/text/text-provider";
import { ResourceService } from "src/app/settings/resource/resource.service";

@Component({
	selector: "app-add-camera",
	templateUrl: "./add-camera.component.html",
	styleUrls: ["../resource-card.css"]
})
export class AddCameraComponent {
	@Input() personnel!: Personnel;
	@Output() assignHardwareCb = new EventEmitter<void>();

	public text: () => LocaleMap;

	private readonly res: ResourceService;

	constructor(textProv: TextProvider, res: ResourceService) {
		this.text = textProv.getStringMap;
		this.res = res;
	}

	public readonly getResourceCamera: () => Camera | undefined = () => {
		return this.res.Cameras.find((e) => e.id === this.personnel.id_camera);
	};
}
