import { DTO } from "../net/dto";

export class Debrief implements DTO {
	public id: number;
	public id_mission: number;
	public id_user: number;
	public debrief = new Array<DebriefArray>();
	public last_update_ms: number;
	public timestamp_ms: number;
	public timestamp: number;

	constructor(id: number, id_mission: number, id_user?: number, timestamp_ms?: number) {
		this.id = id ? id : -1;
		this.id_mission = id_mission;
		this.id_user = id_user ? id_user : -1;
		this.last_update_ms = timestamp_ms ? timestamp_ms : -1;

		this.debrief.push(new DebriefArray(DEBRIEF_OPTION.COLOCATE));
		this.debrief.push(new DebriefArray(DEBRIEF_OPTION.COMMUNICATE));
		this.debrief.push(new DebriefArray(DEBRIEF_OPTION.COORDINATE));
		this.debrief.push(new DebriefArray(DEBRIEF_OPTION.JOINTLY));
		this.debrief.push(new DebriefArray(DEBRIEF_OPTION.SHARED));

		this.timestamp_ms = timestamp_ms ? timestamp_ms : Date.now();
		this.timestamp = this.timestamp_ms;
	}

	public static readonly fromJson: (json: string) => Debrief = (json: string) => {
		const jc = JSON.parse(json);
		let ans = new Debrief(jc.id, jc.id_mission ? jc.id_mission : jc.mission_id, jc.id_user, jc.timestamp_ms);
		const vals = Object.values(DEBRIEF_OPTION);
		for (let idx = 0; idx < vals.length; idx++) {
			const value = vals[idx];
			for (let i = 0; jc[value + (i + 1)] !== undefined; i++) {
				ans.getOptionItem(value as DEBRIEF_OPTION)!.items.push(jc[value + (i + 1)]);
			}
		}
		return ans;
	};

	public readonly getJson: () => string = () => {
		let item: any = {};
		item.id_mission = this.id_mission;
		item.id = this.id;
		item.id_user = this.id_user;
		item.timestamp_ms = this.last_update_ms;
		let propertiesArr = ["id", "id_mission", "id_user", "timestamp_ms"];
		let array_length = 0;
		for (let i = 0; i < this.debrief.length; i++) {
			array_length = i === 0 || i === 1 ? 5 : i === 2 ? 7 : i == 3 ? 3 : i === 4 ? 6 : 0;
			for (let j = 0; j < array_length; j++) {
				item[this.debrief[i].id + (j + 1)] = this.debrief[i].items[j] ? this.debrief[i].items[j] : "";
				propertiesArr.push(this.debrief[i].id + (j + 1));
			}
		}
		return JSON.stringify(item, propertiesArr);
	};

	public readonly getOptionItem = (opt: DEBRIEF_OPTION): DebriefArray | undefined => {
		return this.debrief.find((e) => e.id === opt);
	};
}
export class DebriefArray {
	public id: DEBRIEF_OPTION;
	public items = new Array<string>();

	constructor(id: DEBRIEF_OPTION) {
		this.id = id;
	}
}
export enum DEBRIEF_OPTION {
	COLOCATE = "colocate",
	COMMUNICATE = "communicate",
	COORDINATE = "coordinate",
	JOINTLY = "jointly",
	SHARED = "shared"
}
