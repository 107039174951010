<div class="skill-section">
	<span class="resource-card-label">{{ text().SKILLS.toUpperCase() }}</span>
	<div class="hardware-field">
		<button
			class="main-blue-gradient-button resource-add-button font14"
			(click)="openSkillModal(); $event.stopPropagation()"
		>
			+ {{ text().ADD }}
		</button>
	</div>
</div>
