<app-completed-aras-screen
	*ngIf="currentARAScreen === 0"
	[canCreateAra]="canCreateAra"
	appExtendToBottom
	(goToScreen)="openCreateScreen($event)"
></app-completed-aras-screen>
<app-new-ara-screen
	[isFuncAvailable]="isFuncAvailable"
	*ngIf="currentARAScreen === 1"
	[canCreateAra]="canCreateAra"
	[selectedSector]="currentSector"
	(navigateToHome)="goToHome()"
></app-new-ara-screen>
<app-confirmation-dialog
	*ngIf="confirmationDialogParams.show && canCreateAra"
	[headerText]="confirmationDialogParams.header"
	[bodyText]="confirmationDialogParams.body"
	[confirmText]="confirmationDialogParams.confirmText"
	[cancelText]="confirmationDialogParams.cancelText"
	(closeCallback)="confirmationDialogParams.show = false"
	(cancelCallback)="confirmationDialogParams.cancelCallback()"
	(confirmCallback)="confirmationDialogParams.confirmCallback()"
>
</app-confirmation-dialog>
