import { Component, Input, OnInit } from "@angular/core";
import { LocaleMap } from "src/app/global/constants/text/text-interface";
import { TextProvider } from "src/app/global/constants/text/text-provider";

@Component({
	selector: "app-change-password-modal",
	templateUrl: "change-password.component.html",
	styleUrls: ["change-password.css"]
})
export class ChangePasswordModalComponent implements OnInit {
	@Input() closeCallback: Function = () => {};
	@Input() saveCallback: Function = () => {};

	public readonly text: () => LocaleMap;
	public header: string = "";
	private newPassword: string = "";
	private confirmPassword: string = "";

	constructor(textProv: TextProvider) {
		this.text = textProv.getStringMap;
	}

	ngOnInit() {
		this.header = this.text().CHANGE_PASSWORD;
	}

	public readonly save = (): void => {
		this.saveCallback(this.newPassword);
	};

	public readonly canSave = (): boolean => {
		if (this.newPassword && this.confirmPassword && this.newPassword === this.confirmPassword) return true;
		return false;
	};

	public readonly refreshNewPassword = (input: string): string => {
		return (this.newPassword = input);
	};

	public readonly refreshNewConfirmPassword = (input: string): string => {
		return (this.confirmPassword = input);
	};
}
