import { Component, EventEmitter, Input, Output } from "@angular/core";
import { LocaleMap } from "src/app/global/constants/text/text-interface";
import { TextProvider } from "src/app/global/constants/text/text-provider";
import { JesipCard, JESIP_CARD_BODY_TYPE, JESIP_CARD_INITIAL_COLOR } from "../../jesip-card.interface";
import { UserService } from "src/app/settings/user/user.service";

@Component({
	selector: "app-fsg-app-void-jesip-card",
	templateUrl: "fsg-void-jesip-card.component.html",
	styleUrls: ["../../jesip-card.css", "../fsg-jesip-card.css"]
})
export class FsgVoidJesipCardComponent implements JesipCard {
	@Input() initial: string | undefined;
	@Input() title: string = "";
	@Output() buttonClick = new EventEmitter<Event>();
	@Input() bodyText: string | (() => string) | undefined;
	@Input() updateAvailable: boolean = false;
	@Input() buttonText: string | (() => string) | undefined;
	color: string | (() => string) = JESIP_CARD_INITIAL_COLOR.BLUE;
	backgroundSrc: string = "";

	public readonly type = JESIP_CARD_BODY_TYPE.SET;

	public readonly text: () => LocaleMap;

	private readonly userServ : UserService;

	constructor(textProv: TextProvider, user : UserService) {
		this.text = textProv.getStringMap;
		this.userServ = user;
	}

	public readonly getBody: () => string = () => {
		if (!this.bodyText) return "";
		if (typeof this.bodyText === "string") return this.bodyText;
		return this.bodyText();
	};

	public readonly getButton: () => string = () => {
		if (!this.buttonText) return "";
		if (typeof this.buttonText === "string") return this.buttonText;
		return this.buttonText();
	};

	public readonly getColour: () => string = () => {
		if (typeof this.color === "string") return this.color;
		return this.color();
	};

	public readonly canEditEF: () => boolean = () => {
		return this.userServ.getCurrentUserLevel()?.edit_ef;
	}
}
