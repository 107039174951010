export enum MESSAGE_TYPE {
	/** MISC AREA */
	LOGIN = "loggedIn1",
	LOGIN_FINISH = "loggedIn",
	LOGOUT = "loggedOut",
	DOLOGOUT = "logOut",
	ERROR_DATABASE = "databaseError",
	ERROR_WEB_REQUEST = "webRequestError",
	ERROR_CONNECTION = "connectionError",
	ERROR_LOGIN = "loginError",
	ERROR_NOT_FOUND = "Not found",
	ERROR_UNKNOW = "Unknown error",
	ERROR_FILE_TOO_LARGE = "File to large",
	SET_DEBUG = "setDebugInfo",
	CLOSE_CURR_MISSION = "currentMissionClosed",
	OPEN_CURR_MISSION = "currentMissionReopen",
	SET_CS_COMMANDER = "commanderSetUpdate",
	ADD_VIDEO_FEED = "videoFeedAdded",
	WINDOW_RESIZE = "windowResized",
	SET_LOADING_INFO = "loadingInfo",
	INIT_CS = "initCommandStructure",
	DOWNLOAD_LOG = "downloadLog",
	DOWNLOAD_SECTOR_ARAS = "downloadSectorARAs",
	BUFFER_UPDATED = "bufferUpdated",
	HISTORY_UPDATED = "historyUpdated",
	/** MISC AREA END */

	/** UPDATE AREA */
	UPDATE_USERS = "usersUpdated",
	UPDATE_AGENT = "agentUpdated",
	UPDATE_AGENT_SERVICE = "agentServiceUpdate",
	UPDATE_HARDWARE = "hardwareUpdated",
	UPDATE_CAMERA = "cameraUpdated",
	UPDATE_CONFIGURATION_ITEM = "confItemUpdated",
	UPDATE_EVENTS = "eventsChanged",
	UPDATE_CS_COMMANDERS = "icsCommandersUpdated",
	UPDATE_CS_COMMANDER = "commanderUpdated",
	UPDATE_CS_SECTOR = "sectorUpdated",
	UPDATE_CS_SUPPORT = "supportUpdated",
	UPDATE_AREA = "areaUpdateReceived",
	UPDATE_SECTOR_NAME = "updateSectorName",
	UPDATE_POI = "poiUpdateReceived",
	UPDATE_OVERLAY = "overlayUpdateReceived",
	/** UPDATE AREA END */

	/** LOAD AREA */
	LOAD_CONFIGURATION = "configurationLoaded",
	LOAD_AGENTS = "agentsLoaded",
	LOAD_KMLs = "KMLsLoaded",
	LOAD_MISSION = "missionLoaded",
	LOAD_EVENTS_MISSION = "eventsMissionsLoaded",
	LOAD_CALLOUT = "resourceLogLoaded",
	LOAD_DECISIONS = "decisionsLoaded",
	LOAD_MAP_ITEMS = "mapItemsLoaded",
	LOAD_NAV_ROUTES = "navRoutesLoaded",
	/** LOAD AREA END */

	/** SAVE AREA */
	SAVE_DECISION_FILE = "decisionFileSaved",
	/** SAVE AREA END */

	/** UNLOAD AREA */
	UNLOAD_MISSION = "missionUnLoaded",
	/** UNLOAD AREA END */

	/** NEW AREA */
	NEW_APPL_REL = "newApplianceRelation",
	NEW_FSG_FLAT = "updatedNewFlat",
	/** NEW AREA END */

	/** DELETE AREA */
	DELETE_APPL_REL = "deleteApplianceRelation",
	DELETE_CS_SECTOR = "sectorDeleted",
	DELETE_CS_SUPPORT = "supportDeleted",
	DELETE_OVERLAY = "overlayDeleted",
	DELETE_USER_UPDATE = "deleteUserUpdate",
	/** DELETE AREA END */

	/** HISTORIC AREA */
	HISTORY_CHANGE = "historyChanged",
	TRY_UPDATE = "tryUpdate",
	PLAY_MODE_CHANGE = "playModeChanged",
	UPLOAD_UNSAVED_POI = "unsavedPoiUpload",
	ON_ONLINE = "onOnline",
	PLAY_PAUSE_CHANGE = "playPauseChanged",
	TIMESTAMP_CHANGE = "historicModeTimestampChanged",
	/** HISTORIC AREA END */

	/** NAVIGATION AREA */
	BEGIN_ROUTE_CHANGE = "routeChangeBegin",
	END_ROUTE_CHANGE = "routeChanged",
	LOAD_MISSION_PAGE = "loadMission",
	STOP_FREE_DRAW = "stopFreeDrawing",
	CANCEL_SELECT = "cancelSelect",
	STOP_FSG = "unsetFSG",
	OPEN_FREE_DRAW = "openFreeDrawing",
	OPEN_SCHEMATIC_UPLOAD = "openBlueprintUpload"
	/** NAVIGATION AREA END */
}
