import { Component, Input } from "@angular/core";
import { NavTabItem } from "src/app/dto/ui/tab";

@Component({
	selector: "app-black-nav-tab",
	templateUrl: "black-nav-tab.component.html",
	styleUrls: ["../nav-tab.css"]
})
export class BlackNavTabComponent {
	@Input() tabs: Array<NavTabItem> = new Array<NavTabItem>();
	@Input() hasNavigation: boolean = false;
	@Input() areThereMoreTabsFw: boolean = false;
	@Input() areThereMoreTabsBw: boolean = false;

	@Input() getActiveTab: Function = () => {
		return 0;
	};

	@Input() incrementTabIndex: Function = () => {
		return 0;
	};

	@Input() decrementTabIndex: Function = () => {
		return 0;
	};

	onClickOption: Function = (tab: NavTabItem, evt: Event) => {
		tab.callback(tab, evt);
	};
}
