export enum PREFERRED_COLORS {
	RED = "#FF0000",
	AQUA = "#54EAFF",
	YELLOW = "#FFEE00",
	BLUE = "#007CFF",
	ORANGE = "#F9650D",
	GREEN = "#10AE09",
	PURPLE = "#B400FF",
	PINK = "#EA00FA"
}
