import { NgModule } from "@angular/core";
import { CommonModule, DatePipe } from "@angular/common";
import { DocumentComponent } from "./component/document.component";
import { DecisionLogTemplateComponent } from "./component/templates/decision-log/decision-log.component";
import { FsgBlankTemplateComponent } from "./component/templates/fsg/blank/fsg-blank";
import { FsgHistoryTemplateComponent } from "./component/templates/fsg/history/fsg-history";
import { AraTemplateComponent } from "./component/templates/ara/ara.component";
import { FormsModule } from "@angular/forms";
import { UIModule } from "../widgets/ui/ui.module";
import { DirectiveModule } from "../widgets/directive/directive.module";
import { BlankDebriefTemplateComponent } from "./component/templates/jesip/debrief/blank/debrief-blank.component";
import { CurrentDebriefTemplateComponent } from "./component/templates/jesip/debrief/current/debrief-current.component";
import { BlankChecklistTemplateComponent } from "./component/templates/jesip/checklist/blank/checklist-blank.component";
import { CurrentChecklistTemplateComponent } from "./component/templates/jesip/checklist/current/checklist-current.component";
import { BlankMethaneTemplateComponent } from "./component/templates/jesip/methane/blank/methane-blank.component";
import { CurrentMethaneTemplateComponent } from "./component/templates/jesip/methane/current/methane-current.component";
import { BlankIimarchTemplateComponent } from "./component/templates/jesip/iimarch/blank/iimarch-blank.component";
import { CurrentIimarchTemplateComponent } from "./component/templates/jesip/iimarch/current/iimarch-current.component";
import { BlankJdmTemplateComponent } from "./component/templates/jesip/jdm/blank/jdm-blank.component";
import { CurrentJdmTemplateComponent } from "./component/templates/jesip/jdm/current/jdm-current.component";
import { EventHeaderComponent } from "./component/templates/event-header/event-header.component";
import { CommandStructureTemplateComponent } from "./component/templates/cs/cs";
import { AraHeaderComponent } from "./component/templates/ara/ara-header/ara-header.component";
import { FsgHeaderComponent } from "./component/templates/fsg/fsg-header/fsg-header.component";
import { CsHeaderComponent } from "./component/templates/cs/cs-header/cs-header.component";

@NgModule({
	declarations: [
		DocumentComponent,
		DecisionLogTemplateComponent,
		FsgBlankTemplateComponent,
		FsgHistoryTemplateComponent,
		AraTemplateComponent,
		BlankDebriefTemplateComponent,
		CurrentDebriefTemplateComponent,
		BlankChecklistTemplateComponent,
		CurrentChecklistTemplateComponent,
		BlankMethaneTemplateComponent,
		CurrentMethaneTemplateComponent,
		BlankIimarchTemplateComponent,
		CurrentIimarchTemplateComponent,
		BlankJdmTemplateComponent,
		CurrentJdmTemplateComponent,
		EventHeaderComponent,
		CommandStructureTemplateComponent,
		AraHeaderComponent,
		FsgHeaderComponent,
		CsHeaderComponent
	],
	imports: [CommonModule, FormsModule, UIModule, DirectiveModule],
	exports: [DocumentComponent],
	providers: [DatePipe]
})
export class DocumentModule {}
