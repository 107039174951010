import { DTO } from "../net/dto";

export class IiMarch implements DTO {
	public id: number;
	public id_user: number;
	public id_mission: number;
	public I: string;
	public _I: string;
	public M: string;
	public A: string;
	public R: string;
	public C: string;
	public H: string;
	public timestamp_ms: number;
	public timestamp: number;

	constructor(id: number, id_user: number, id_mission: number, I?: string, _I?: string, M?: string, A?: string, R?: string, C?: string, H?: string, timestamp_ms?: number) {
		this.id = id ? id : -1;
		this.id_mission = id_mission;
		this.id_user = id_user;
		(this.I = I || ""), (this._I = _I || ""), (this.M = M || ""), (this.A = A || ""), (this.R = R || ""), (this.C = C || ""), (this.H = H || ""), (this.timestamp_ms = timestamp_ms ? timestamp_ms : -1);
		this.timestamp = Date.now();
	}

	public static readonly fromJson = (json: string): IiMarch => {
		const jc = JSON.parse(json);
		return new IiMarch(jc.id ? jc.id : -1, jc.id_user, jc.id_mission, jc.I, jc._I, jc.M, jc.A, jc.R, jc.C, jc.H, jc.timestamp_ms);
	};

	public readonly getJson = (): string => {
		return JSON.stringify(this, ["id", "id_user", "id_mission", "I", "_I", "M", "A", "R", "C", "H", "timestamp_ms"]);
	};
}

export enum IIMARCH_ITEM {
	I = "I",
	_I = "_I",
	M = "M",
	A = "A",
	R = "R",
	C = "C",
	H = "H"
}
