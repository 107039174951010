<div class="container">
	<div
		#pageElements
		*ngFor="let page of pages"
		class="page"
		[style.width.px]="pageSize.width"
		[style.height.px]="pageSize.height"
	>
		<div class="title">IIMARCH</div>
		<div class="header">
			<div class="header-row">
				<div class="header-col">
					<div>
						<b>{{ text().TIME }}:</b>
					</div>
					<div>
						<b>{{ text().NAME_OF_CALLER }}:</b>
					</div>
					<div>
						<b>{{ text().DATE }}:</b>
					</div>
				</div>
				<div class="header-col">
					<div>
						<b>{{ text().COMMANDER }} 1:</b>
					</div>
					<div>
						<b>{{ text().COMMANDER }} 2:</b>
					</div>
					<div>
						<b>{{ text().COMMANDER }} 3:</b>
					</div>
				</div>
			</div>
			<div class="doc-generated-on">
				<b>{{ text().DOCUMENT_GENERATED_ON }}</b
				>: {{ generationTime | date: "dd/MM/YY
				HH:mm" }}
			</div>
		</div>
		<div *ngFor="let option of page" class="debrief-option">
			<div class="option-title">
				<div class="line"></div>
				<div class="title-text">
					<span class="initial">{{ option.header[0] }}</span>
					<span>{{ option.header.substr(1, 999) }}</span>
				</div>
			</div>
			<div class="option-body">
				<span>{{ option.question }}</span>
			</div>
		</div>
	</div>
</div>
