import { DTO } from "src/app/dto/net/dto";
import { RiskInformation } from "./risk-information";
import { SubControlMeasure } from "./sub-control-measure";

export class ControlMeasure implements DTO {
	id_cm: number;
	id_risk_information: number;
	info: string;
	deleted: boolean;
	scm_edit: number;
	newSubCm: SubControlMeasure | undefined;

	subControlMeasures: Array<SubControlMeasure> = [];
	riskInformation: RiskInformation | undefined;

	id: number;
	timestamp = Date.now();
	editing: boolean = false; // Llevar al componente
	editMenu: boolean = false; // Llevar al componente


	//__object: ControlMeasure | undefined;

	constructor(id: number, info: string, id_ri: number, deleted?: boolean, done?: boolean, scm_edit?: number) {
		this.id = this.id_cm = id;

		this.id_risk_information = id_ri;
		this.deleted = deleted ? deleted : false;
		this.info = info;
		this.scm_edit = scm_edit ? scm_edit : -1;
	}

	public static readonly fromJson: (json: string) => ControlMeasure = (json: string) => {
		let jc: any = JSON.parse(json);
		return new ControlMeasure(jc.id_cm, jc.info, jc.id_risk_information, jc.deleted, jc.done, -1);
	};

	public readonly getJson: () => string = () => {
		return JSON.stringify(this, ["id_cm", "info", "id_risk_information", "deleted", "done"]);
	};
}
