import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { WebRequestFactory } from "src/app/http/web.request.factory";
import { ConfigurationService } from "src/app/settings/types/configuration.service";
import { Incident } from "src/app/dto/items/incident";
import { Kml } from "src/app/dto/items/kml";
import { IncidentService } from "src/app/incident/incident.service";
import { MapItemsService } from "src/app/incident/map/map-items.service";
import { ConstantsService } from "src/app/global/constants/constants.service";
import { LocaleMap } from "src/app/global/constants/text/text-interface";
import { TextProvider } from "src/app/global/constants/text/text-provider";
import { MainService } from "src/app/global/main.service";
import JSZip from "jszip";

@Component({
	selector: "app-kml-item",
	templateUrl: "kml-item.component.html",
	styleUrls: ["kml-item.css"]
})
export class KmlItemComponent implements OnInit {
	@Input() kml: Kml = new Kml(-1, -1, "");

	@Input() canDelete: boolean = false;

	@Output() saveCb = new EventEmitter<Kml>();

	@Output() deleteCb = new EventEmitter<Kml>();

	text: () => LocaleMap;

	incidents: Array<Incident> = [];

	private readonly conf: ConfigurationService;
	private readonly cnst: ConstantsService;
	private readonly main: MainService;
	private readonly mis: MapItemsService;
	private readonly wreq: WebRequestFactory;
	private readonly ems: IncidentService;

	constructor(conf: ConfigurationService, cnst: ConstantsService, main: MainService, ems: IncidentService, mis: MapItemsService, wreq: WebRequestFactory, textProv: TextProvider) {
		this.conf = conf;
		this.cnst = cnst;
		this.main = main;
		this.mis = mis;
		this.wreq = wreq;
		this.ems = ems;

		this.text = textProv.getStringMap;
	}

	ngOnInit() {
		this.incidents.push(new Incident(-1, -1, this.text().ALL_INCIDENTS));
		this.incidents.push(...this.ems.Incidents);

		this.save = () => {
			this.saveCb.emit(this.kml);
		};

		this.delete = () => {
			this.deleteCb.emit(this.kml);
		};
	}

	public save: Function = () => {};

	public delete: Function = () => {};

	public hasKmlBeenModified = (): boolean => {
		const original = this.mis.KMLs.find((e) => e.id === this.kml.id);
		if (!original) return true;
		return this.kml.name !== original.name || this.kml.mission_id !== original.mission_id;
	};

	public incidentDropdownGetMainText: Function = (obj: any) => {
		const incident = this.incidents.find((e) => e.id === this.kml.mission_id);
		if (!incident) return this.text().ALL_INCIDENTS;
		return incident.name;
	};

	public incidentDropdownGetOptionText: Function = (option: Incident) => {
		return option.name;
	};

	public incidentDropdownCompareSelect: Function = (obj: Kml, option: Incident) => {
		return option.id === obj.mission_id;
	};

	public incidentDropdownChangeCallback: Function = (option: Incident) => {
		this.kml.mission_id = option.id;
	};

	public parseKmlForUnsupportedAttributes(payload: string): boolean {
		if(payload.match(/<networklink/i))
			return false;
		return true;
	}

	public async uploadFile(file : File): Promise<void> {
		let ans = await this.wreq.uploadFile(file.name, file);
		this.kml.attachment_id = ans.id;
		this.kml.attachment_filename = ans.filename;
	}

	public readonly addNewFile = async (evt: { target: any }): Promise<boolean> => {
		const file = evt.target.files[0];
		if(file.size > KML_MAX_SIZE_BYTES){
			this.main.addDangerAlert(this.text().FILE_MAX_LIMIT_10MB, this.text().FILE_UNSUPPORTED_2);
			return false;
		}

		if(file.name.match(/kml/i)){
			const text = await file.text();
			if(this.parseKmlForUnsupportedAttributes(text)){
				this.uploadFile(file);
				return true;
			}
			else{
				this.main.addDangerAlert(this.text().KML_FEATURE_INVALID, this.text().FILE_UNSUPPORTED_2);
				return false;
			}
		}
		if(file.name.match(/kmz/i)){
			let zip = new JSZip();
			const payload = await zip.loadAsync(file);
			for(const [key, value] of Object.entries(payload.files)){
				if(key.match(/kml/i)){
					const text = await value.async("text");
					if(!this.parseKmlForUnsupportedAttributes(text)){
						this.main.addDangerAlert(this.text().KML_FEATURE_INVALID, this.text().FILE_UNSUPPORTED_2);
						return false;
					}
				}
			}
			this.uploadFile(file);
			return true;
		}

		this.main.addDangerAlert(this.text().KML_FILE_INVALID);
		return false;
	};
}
export const KML_MAX_SIZE_BYTES = 10000000;