import { DTO } from "../net/dto";
import { ControlMeasure } from "./control-measure";
import { ControlMeasureRelation } from "./control-measure-relation";

export class RiskInformation implements DTO {
	id_risk_information: number;
	name: string;
	id_scenario: number;
	deleted: boolean;
	/* 	preselected: boolean;
	selected: boolean; */

	controlMeasures: Array<ControlMeasure> = [];

	id: number;
	timestamp = Date.now();
	constructor(id: number, name: string, id_sce: number, del?: boolean, presel?: boolean, sel?: boolean) {
		this.id = this.id_risk_information = id;
		this.name = name;
		this.id_scenario = id_sce;
		this.deleted = del ? del : false;
		/* 		this.preselected = presel ? presel : false;
		this.selected = sel ? sel : false; */
	}

	public static readonly fromJson: (json: string) => RiskInformation = (json: string) => {
		let jc: any = JSON.parse(json);
		return new RiskInformation(jc.id_risk_information, jc.name, jc.id_scenario, jc.deleted);
	};

	public readonly getJson: () => string = () => {
		return JSON.stringify(this, ["id_risk_information", "name", "id_scenario"]);
	};
}
