import { Injectable } from "@angular/core";
import { Level } from "../dto/user/level";
import { User } from "../dto/user/user";

@Injectable({
	providedIn: "root"
})
export class AccessService {
	public userInfo: { user: User | undefined; level: Level | undefined };

	public OK: number = 200;
	public UNAUTHORIZED: number = 401;
	public FORBIDDEN: number = 403;

	constructor() {
		this.userInfo = { user: undefined, level: undefined };
	}

	public setUserInfo(user: User | undefined, level: Level | undefined): void {
		this.userInfo.user = user;
		this.userInfo.level = level;
	}

	public isConfigPageAllowed: Function = () => {
		if (!this.userInfo.user) return Promise.reject(this.UNAUTHORIZED);
		if (!this.userInfo.level) return Promise.reject(this.FORBIDDEN);
		if (this.userInfo.level.edit_types) return Promise.resolve(this.OK);
		else return Promise.reject(this.FORBIDDEN);
	};

	public isAgentPageAllowed: Function = () => {
		if (!this.userInfo.user) return Promise.reject(this.UNAUTHORIZED);
		if (!this.userInfo.level) return Promise.reject(this.FORBIDDEN);
		if (this.userInfo.level.edit_agents) return Promise.resolve(this.OK);
		else return Promise.reject(this.FORBIDDEN);
	};

	public isUserPageAllowed: Function = () => {
		if (!this.userInfo.user) return Promise.reject(this.UNAUTHORIZED);
		if (!this.userInfo.level) return Promise.reject(this.FORBIDDEN);
		if (this.userInfo.level.users_manage) return Promise.resolve(this.OK);
		else return Promise.reject(this.FORBIDDEN);
	};

	public isAnonymous: Function = () => {
		if (this.userInfo.user) return Promise.reject(this.FORBIDDEN);
		else return Promise.resolve(this.OK);
	};

	public isAuthenticated: Function = () => {
		if (this.userInfo.user) return Promise.resolve(this.OK);
		else return Promise.reject(this.UNAUTHORIZED);
	};
}
