import { Directive, ElementRef, Input, OnChanges } from "@angular/core";
import { Subscription } from "rxjs";
import { WebRequestFactory } from "src/app/http/web.request.factory";
import { IrisImageService } from "./iris-image.service";
import { UploadedFile } from "src/app/dto/net/uploaded-file";

@Directive({
	selector: "[appIrisImage]"
})
export class IRISImageDirective implements OnChanges {
	@Input() id: number | undefined;
	@Input() filename: string | undefined;

	private readonly wreq: WebRequestFactory;
	private elem: HTMLImageElement;
	private subscription: Subscription | undefined;

	private readonly service : IrisImageService;

	constructor(el: ElementRef, wreq: WebRequestFactory, service : IrisImageService) {
		this.elem = el.nativeElement;
		this.wreq = wreq;
		this.service = service;
	}

	ngOnChanges() {
		this.tryLoad();
	}

	tryLoad: () => void = () => {
		if (this.subscription) this.subscription.unsubscribe();
		if (this.wreq.isFileLoading) {
			this.subscription = this.wreq.onFileLoaded$.subscribe(this.tryLoad);
		} else {
			this.service.fetch(new UploadedFile(this.id, this.filename)).then((blob) => {
				this.elem.src = window.URL.createObjectURL(blob);
			});
		}
	};
}
