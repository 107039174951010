import { CSBlock } from "./cs-block";
import { DTO } from "../net/dto";
import { CSSectorResourceAssignation } from "./cs-resource";
import { Resource } from "../resources/resource";
import { Area } from "../items/area";
import { Ara } from "../ara/ara";

export class CSSector extends CSBlock implements DTO {
	id_sector: number;
	id_commander: number;
	id_area: number;
	name: string;
	tasks: string;
	id_resource_comm: number;
	id_safety_officer: number;
	latitude: number | undefined;
	longitude: number | undefined;
	aras: Array<Ara> = new Array<Ara>();
	resources: Array<CSSectorResourceAssignation> = new Array();
	id: number;
	timestamp: number = Date.now();
	download_time_string: string | undefined;
	__sectorCommander: Resource | undefined;
	__safetyOfficer: Resource | undefined;
	__area: Area | undefined;

	constructor(id_sector: number, id_commander: number, id_area: number, name: string, tasks?: string, color?: string, title?: string, x?: number, y?: number, id_agent_comm?: number, id_safety_officer?: number) {
		super(color ? color : "#377ab7", title ? title : "Sector", x ? x : 0, y ? y : 0);
		this.id = this.id_sector = id_sector ? id_sector : -1;
		this.id_commander = id_commander ? id_commander : -1;
		this.id_area = id_area ? id_area : -1;
		this.name = name ? name : "";
		this.tasks = tasks ? tasks : "";
		this.id_resource_comm = id_agent_comm ? id_agent_comm : -1;
		this.id_safety_officer = id_safety_officer ? id_safety_officer : -1;
	}

	public static readonly fromJson: (json: string) => CSSector = (json) => {
		var jc = JSON.parse(json);
		return new CSSector(jc.id_sector, jc.id_commander, jc.id_area, jc.name, jc.tasks, jc.color, jc.title, jc.x, jc.y, jc.id_resource_comm, jc.id_safety_officer);
	};

	public readonly getJson: Function = () => {
		return JSON.stringify(this, ["id_sector", "id_commander", "name", "id_area", "tasks", "color", "title", "x", "y", "id_resource_comm", "id_safety_officer"]);
	};
}
