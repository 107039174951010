import { Component, HostListener, Input, OnInit } from "@angular/core";
import { SCREEN } from "src/app/global/constants/enums/screens";
import { URLMap } from "src/app/global/constants/enums/url-map";
import { MESSAGE_TYPE } from "src/app/global/messaging/messages";
import { MessagingService } from "src/app/global/messaging/messaging.service";

@Component({
	selector: "app-side-nav-menu",
	templateUrl: "side-nav-menu.component.html",
	styleUrls: ["side-menu.css"]
})
export class SideNavMenuComponent implements OnInit {
	@Input() routes: Array<any> = [];

	@HostListener("window:resize") onResize(): void {
		this.__openSubMenu = false;
	}

	public __open: boolean = false;
	public __openSubMenu: boolean = false;
	public selectedRoute: any = {
		options: [],
		subMenuY: 0
	};
	public _year = new Date().getFullYear();

	private readonly mssg: MessagingService;

	constructor(mssg: MessagingService) {
		this.mssg = mssg;
	}

	ngOnInit() {
		this.mssg.registerListener(MESSAGE_TYPE.BEGIN_ROUTE_CHANGE, this.closeMenu);
	}

	get isOffline(): boolean {
		return URLMap.use_backup;
	}

	public openMenu(evt: Event): void {
		this.__open = !this.__open;
	}

	public closeMenu(): void {
		this.__open = false;
	}

	public isMenuOpen(): boolean {
		return this.__open;
	}

	// Bad arg typing!
	public onClick(item: { goCallback: Function }): void {
		this.closeMenu();
		item.goCallback();
	}

	// Bad arg typing!
	setOpenSubMenu(route: { options: Array<any>; subMenuY: any }, elem: HTMLDivElement): void {
		this.selectedRoute = route;
		if (!route.options || route.options.length === 0) {
			return;
		} else {
			route.subMenuY = elem.getBoundingClientRect().y;
			this.openSubMenu();
		}
	}

	public openSubMenu(): boolean {
		return (this.__openSubMenu = true);
	}

	public closeSubMenu(): boolean {
		return (this.__openSubMenu = false);
	}

	public closeBothMenus(): boolean {
		return (this.__open = this.__openSubMenu = false);
	}

	public offlineDisabledRoutes(path: SCREEN): boolean {
		return this.isOffline && !!(path === SCREEN.CONFIGURATION || path === SCREEN.USERS || path === SCREEN.ASSETS);
	}
}
