import { Component, EventEmitter, Input, Output, ViewChild, OnInit, OnChanges, SimpleChanges, OnDestroy } from "@angular/core";
import { AreaType, AREA_TYPE } from "src/app/dto/items/types/area-type";
import { LocaleMap } from "src/app/global/constants/text/text-interface";
import { TextProvider } from "src/app/global/constants/text/text-provider";
import { ConfigurationService } from "src/app/settings/types/configuration.service";

@Component({
	selector: "app-body-area",
	templateUrl: "app-body-area.component.html",
	styleUrls: ["app-body-area.css"]
})
export class BodyAreaComponent implements OnInit, OnChanges, OnDestroy {
	@Input() searchQuery: string | null = "";

	@Output() drawPolygon = new EventEmitter<AreaType>();
	@Output() drawCircle = new EventEmitter<AreaType>();

	@ViewChild("circleIcon", { static: true }) circleSvgRef: any;
	@ViewChild("polylineIcon", { static: true }) polylineSvgRef: any;

	public readonly text: () => LocaleMap;
	public readonly type = AREA_TYPE;
	public areaType: AREA_TYPE = AREA_TYPE.segment;
	public areaTypes: Array<AreaType> = [];
	public selectedTool: AreaType | undefined;

	private readonly conf: ConfigurationService;

	constructor(textProv: TextProvider, conf: ConfigurationService) {
		this.text = textProv.getStringMap;
		this.conf = conf;
	}

	ngOnInit(): void {
		this.resetAllTypes();
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.searchQuery) {
			this.searchArrays();
		}
	}

	ngOnDestroy(): void {
		this.setAreaType(AREA_TYPE.segment);
	}

	public setAreaType(type: AREA_TYPE): void {
		this.areaType = type;
	}

	public selectAreaType(type: AreaType, event: Event): void {
		event.stopPropagation();
		switch (this.areaType) {
			case AREA_TYPE.segment:
				this.polylineSvgRef?.nativeElement.setAttribute("stroke", type.color);
				return this.drawPolygon.emit(type);
			case AREA_TYPE.cordon:
				this.circleSvgRef?.nativeElement.setAttribute("stroke", type.color);
				return this.drawCircle.emit(type);
		}
	}

	public toggleSelectedTool(hoveredElement?: AreaType): void {
		this.selectedTool = hoveredElement || undefined;
	}

	public getAreaTypes(): AreaType[] {
		return this.areaTypes.filter((area) => area.type === this.areaType);
	}

	public searchArrays(): void {
		this.resetAllTypes();

		if (this.searchQuery) {
			const searchValue = this.searchQuery.toLocaleUpperCase();
			const filterFunction = (type: any) => type.name.toLocaleUpperCase().includes(searchValue);

			this.areaTypes = this.areaTypes.filter(filterFunction);
		}
	}

	private resetAllTypes(): void {
		this.areaTypes = this.conf.configuration.areaTypes.filter((e) => e.name.toLowerCase() !== "default");
	}
}
