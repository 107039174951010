import { Component, Input, OnInit } from "@angular/core";
import { LocaleMap } from "src/app/global/constants/text/text-interface";
import { TextProvider } from "src/app/global/constants/text/text-provider";

@Component({
	selector: "app-pad-lock",
	templateUrl: "./pad-lock.component.html",
	styleUrls: ["./pad-lock.component.css"]
})
export class PadLockComponent {
	// @Input() textKey: keyof LocaleMap | undefined;
	@Input() isPrivate: boolean = false;

	public readonly text: () => LocaleMap;

	public readonly currentLanguage: string;

	constructor(tp: TextProvider) {
		this.currentLanguage = tp.getCurrentLanguage();
		this.text = tp.getStringMap;
	}

	public lockIcon = (): string => {
		if (this.isPrivate) {
			return "resources/img/jesip/pad-lock.svg";
		}
		return "resources/img/jesip/padlock-open.svg";
	};

	public lockText = (): string => {
		if (this.isPrivate) {
			return this.text().PRIVATEFORM;
		}
		return this.text().SHARED_FORM;
	};
}
