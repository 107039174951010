// CloneFactory.cloneProperties overwrites readonly properties, which poses a problem particularly with the getJson method
// inherited by all classes implementing the DTO interface, hence all cloning should be done through this abstract class

export abstract class CloneFactory {
	private static bannedProperties: Array<string> = ["getJson", "fromJson", "withFloors", "addAgentObject", "__marker", "__shape", "__markerAttachment", "__typeObj", "__mapComponent", "__showRadialMenu"];

	public static readonly clone: Function = (item: any) => {
		let ans: any;
		for (let prop in item) {
			if (Object.prototype.hasOwnProperty.call(item, prop) && !CloneFactory.bannedProperties.find((e) => e === prop)) {
				ans[prop] = item[prop];
			}
		}
	};

	public static readonly cloneProperties: (output: any, item: any) => void = (output: any, item: any) => {
		for (let prop in item) {
			if (Object.prototype.hasOwnProperty.call(item, prop) && !CloneFactory.bannedProperties.find((e) => e === prop)) {
				output[prop] = item[prop];
			}
		}
	};
}
