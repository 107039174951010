import { HostListener, Injectable } from "@angular/core";
import { Alert } from "../dto/ui/alert";
import { ConstantsService } from "./constants/constants.service";
import { MessagingService } from "./messaging/messaging.service";
import { TIME } from "./constants/enums/time";
import { IncidentService } from "../incident/incident.service";
import { MESSAGE_TYPE } from "./messaging/messages";
import { Incident } from "../dto/items/incident";
import { ConfirmationDialog, ErrorBannerData, ErrorDialogData } from "../dto/ui/confirmation-dialog";
import { Subject } from "rxjs";
import { FunctionalityService } from "./functionality.service";

@Injectable({
	providedIn: "root"
})
export class MainService {
	@HostListener("window:resize", ["$event"])
	private onResize(): void {
		this.calculateViewHeight();
	}

	public incidentHeaderLength = 0;
	public showSnackbar: boolean = false;
	public snackbarText: string = "";
	public showLoadSnackbar: boolean = false;
	public loadSnackbarText: string = "";
	public alertDialog = {};
	public editing: boolean = false;
	public logged: boolean = false;
	public alerts: Array<Alert> = [];
	public solidAlerts: Array<Alert> = [];
	public openSupportDialog = new Subject<void>();
	public ErrorDialogComponent = new ErrorDialogData();
	public confirmationDialog = new ConfirmationDialog();
	public showErrorBanner = new ErrorBannerData();

	private readonly mssg: MessagingService;
	private readonly cnst: ConstantsService;
	private readonly ems: IncidentService;
	private snackbarTimeout: ReturnType<typeof setTimeout> | null = null;
	// private previousPollNetStatus = navigator.onLine;

	constructor(mssg: MessagingService, cnst: ConstantsService, ems: IncidentService, func: FunctionalityService) {
		this.mssg = mssg;
		this.cnst = cnst;
		this.ems = ems;
		this.mssg.registerListener(MESSAGE_TYPE.SET_LOADING_INFO, this.setLoadSnackbar);
		// setInterval(this.updateNetStatus, 3000);
		this.calculateViewHeight();
		func.setFunctionalityVisibility();
	}

	public readonly addDangerAlert: (text: string, header?: string) => void = (text, header?) => {
		this.addAlert(ALERT_TYPE.DANGER, text, header);
	};

	/**
	 * Adds an new alert to the alerts notification system.
	 *
	 * @method addAlert
	 * @param {Constant} type The alert type, from {{#crossLink "Main.this.cnst/ALERT_TYPE:property"}}{{/crossLink}}
	 * @param {String} text The custom message to show in the notification
	 * @param {String} header The custom title to show in the notification
	 */
	public readonly addAlert = (type: ALERT_TYPE, text: string, header: string = "Error", callback?: () => void): void => {
		if (!navigator.onLine && this.logged) return;
		if (type !== ALERT_TYPE.DANGER) {
			for (var i = 0; i < this.alerts.length; i++) {
				if (this.alerts[i].text == text && this.alerts[i].type == type) {
					return;
				}
			}
			this.alerts.push({ type, text, header });
			setTimeout(() => {
				this.closeAlert(this.alerts.length - 1);
			}, TIME.ALERT_TTL);
		} else {
			if (header) this.ErrorDialogComponent.header = header;
			this.ErrorDialogComponent.body = text;
			if (callback) this.ErrorDialogComponent.closeCallback = callback;
			this.ErrorDialogComponent.show = true;
		}
	};

	public readonly areThereSolidAlerts: Function = () => {
		return this.ErrorDialogComponent.show;
	};

	public readonly closeAlert: Function = (index: number) => {
		if (!index && index != 0) this.solidAlerts.splice(0, this.solidAlerts.length);
		else this.alerts.splice(index, 1);
		this.ErrorDialogComponent.show = false;
	};

	public readonly addConfirmation: (params: { header: string; body: string; confirmText: string; cancelCallback: Function; confirmCallback: Function }) => void = (params) => {
		this.confirmationDialog.show = true;
		this.confirmationDialog.header = params.header;
		this.confirmationDialog.body = params.body;
		this.confirmationDialog.confirmText = params.confirmText;
		this.confirmationDialog.cancelCallback = () => {
			this.confirmationDialog.show = false;
			params.cancelCallback();
		};
		this.confirmationDialog.confirmCallback = () => {
			this.confirmationDialog.show = false;
			params.confirmCallback();
		};
	};

	public readonly setSnackbar: (text: string) => void = (text) => {
		this.snackbarText = text;
		this.showSnackbar = true;
		setTimeout(() => {
			this.showSnackbar = false;
		}, this.cnst.SNACKBAR_TIME_MS);
	};

	public readonly setLoadSnackbar: (messageObj: { message: string }) => void = (messageObj) => {
		this.loadSnackbarText = messageObj.message;
		this.showLoadSnackbar = true;

		// Clear the existing timeout if there is one
		if (this.snackbarTimeout) {
			clearTimeout(this.snackbarTimeout);
		}
		// Set a new timeout to hide the Snackbar after the specified time
		this.snackbarTimeout = setTimeout(() => {
			this.showLoadSnackbar = false;
			this.snackbarTimeout = null; // Reset the timeout
		}, this.cnst.SNACKBAR_TIME_MS);
	};

	public readonly setErrorBanner = (): void => {
		if (this.showErrorBanner.show) {
			clearTimeout(this.showErrorBanner.closeBanner);
		} else {
			this.showErrorBanner.show = true;
		}
		this.showErrorBanner.closeBanner = window.setTimeout(() => (this.showErrorBanner.show = false), this.cnst.ERROR_BANNER_TIME_MS);
	};

	public readonly getCurrentIncident = (): Incident | undefined => {
		return this.ems.getCurrentIncident();
	};

	private readonly calculateViewHeight: Function = () => {
		const vh = window.innerHeight * 0.01;
		// Then we set the value in the --vh custom property to the root of the document
		document.documentElement.style.setProperty("--vh", `${vh}px`);
	};
}

export enum ALERT_TYPE {
	INFO,
	WARNING,
	DANGER,
	ERROR
}
