<svg
	xmlns="http://www.w3.org/2000/svg"
	version="1.1"
	class="dynamic_icon"
	x="0px"
	y="0px"
	width="100%"
	height="100%"
	attr.viewBox="0 0 {{ size.x }} {{ size.y }}"
	xml:space="preserve"
>
	<path attr.d="{{ path }}" attr.fill="{{ color }}" />
</svg>
