<div class="main-black-mask" (click)="cancel()"></div>
<div class="edit-modal">
	<div class="title">
		{{ header }}
	</div>
	<app-white-cross class="dialog-cross-button" (clickCb)="cancel()"></app-white-cross>
	<div class="body">
		<div class="text-container">
			<app-simple-text-field
				[data]="item.name"
				[type]="'text'"
				(dataChange)="refreshName($event)"
				[placeholderText]="text().NAME"
				[label]="text().NAME"
			></app-simple-text-field>
		</div>
		<div class="text-container" *ngIf="itemAsCircle">
			<app-simple-text-field
				[data]="itemAsCircle.radius"
				[type]="'number'"
				(dataChange)="refreshRadius($event)"
				[minValue]="0"
				[maxValue]="10000"
				[decimal]="2"
				[placeholderText]="text().RADIUS + ' (m)'"
				[label]="text().RADIUS + ' (m)'"
			></app-simple-text-field>
		</div>
		<app-simple-text-box
			[data]="item.description"
			[type]="'text'"
			(dataChange)="refreshComments($event)"
			[placeholderText]="text().DESCRIPTION"
			[label]="text().DESCRIPTION"
			[labelBackground]="'#051c2a'"
			[maxHeight]="96"
			[padding]="16"
		></app-simple-text-box>
	</div>
	<div class="dialog-single-button">
		<button class="main-blue-gradient-border-button cancel-button" (click)="cancel()">
			{{ text().CANCEL }}
		</button>
		<button class="main-blue-gradient-button" (click)="save()">{{ text().SAVE }}</button>
	</div>
</div>
