import { DTO } from "../net/dto";

export class Station implements DTO {
	id: number;
	name: string;

	timestamp = Date.now();
	constructor(id: number, name: string) {
		this.id = id;
		this.name = name;
	}

	public static readonly fromJson: (json: string) => Station = (json: string) => {
		var jc = JSON.parse(json);
		return new Station(jc.id, jc.name);
	};
	public readonly getJson: Function = () => {
		return JSON.stringify(this, ["id", "name"]);
	};
}
