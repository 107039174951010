<div class="container">
	<div class="header">
		<span class="welcome-message"> {{ text().WELCOME_IRIS }} </span>
		<label>{{ text().LOG_INTO_ACCOUNT }} </label>
	</div>
	<div class="username-field">
		<app-simple-text-field
			#userInputComponent
			data-cy="userInput"
			[type]="'text'"
			[data]="userInput"
			[label]="text().USERNAME"
			[withError]="usernameError"
			(dataChange)="onNameChange($event)"
			[placeholderText]="text().USERNAME"
			(click)="refreshNameError()"
		>
		</app-simple-text-field>
		<span *ngIf="usernameError" class="error-prompt">{{ text().MISSING_NAME_INFO }}</span>
	</div>
	<div class="password-field">
		<app-simple-text-field
			#passwordInputComponent
			data-cy="passwordInput"
			[type]="'password'"
			[data]="passwordInput"
			[label]="text().PASSWORD"
			[withError]="passwordError"
			(dataChange)="onPasswordChange($event)"
			[placeholderText]="text().PASSWORD"
			(click)="refreshPasswordError()"
		>
		</app-simple-text-field>
		<span *ngIf="passwordError" class="error-prompt">{{ text().MISSING_PASSWORD_INFO }}</span>
	</div>
	<div class="bottom-part">
		<div
			*ngIf="!credentials"
			appGrecaptcha
			(recaptchaLoaded)="onRecaptchaLoaded()"
			(recaptchaSuccess)="onRecaptchaSuccess($event)"
		></div>
		<div class="checkbox-container">
			<app-blue-checkbox
				[check]="getShouldStoreTokenFunction()"
				(click)="setSession()"
			></app-blue-checkbox>
			<span>{{ text().KEEP_ME_LOGGED }}</span>
		</div>
		<div class="footer">
			<button
				data-add-action-name="login-button"
				class="main-blue-gradient-button"
				(click)="this.onLog()"
			>
				{{ text().LOG_IN }}
			</button>
		</div>
	</div>
</div>
<img class="logo-unblur" src="resources/img/logos/Unblur powered by.svg" alt="Powered by" />
