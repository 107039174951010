<div class="main-black-mask" (click)="cancel()"></div>
<div class="overlay-viewer">
	<button class="overlay-viewer-left-button">
		<div
			class="overlay-viewer-arrow-container"
			*ngIf="imgIndex > 0"
			(click)="planeViewerGoDown()"
		>
			<span class="overlay-viewer-arrow-up"></span>
			<span class="overlay-viewer-arrow-down"></span>
		</div>
	</button>
	<div class="overlay-viewer-container">
		<img [attr.src]="safeSrc" />
		<svg class="svg-close-button" (click)="cancel()">
			<path
				style="stroke: #0dbff9"
				stroke-width="4"
				fill="none"
				d="M0.25,0.25,23.75,23.75"
			></path>
			<path
				style="stroke: #0dbff9"
				stroke-width="4"
				fill="none"
				d="M0.25,23.75,23.75,0.25"
			></path>
		</svg>
	</div>
	<button class="overlay-viewer-right-button">
		<div
			class="overlay-viewer-arrow-container"
			*ngIf="imgIndex < imagesArr.length - 1"
			(click)="planeViewerGoUp()"
		>
			<span class="overlay-viewer-arrow-up"></span>
			<span class="overlay-viewer-arrow-down"></span>
		</div>
	</button>
</div>
