import { CloneFactory } from "./clone-factory";

export class DTOArray<T> {
	/**
	 * Searches this array for an object with property id equal to provided id
	 * Returns object or empty object if not found.
	 */
	public static readonly FindById = (array: Array<any>, id: number): any | undefined => {
		for (let i = 0; i < array.length; i++) if (array[i].id == id) return array[i];
		return undefined;
	};
	/**
	 * Updates this array using the provided array. If it can find the matches by id, uses to copy the
	 * object, otherwise it adds as new.
	 * @param {Array} sourceArray
	 * @param {Boolean} updateNotAdd    If true, removes all elements in dest that are not in source.
	 *                                  If false, it just tries to match and update and if it can't, it adds the object
	 * @param {Function} newObjectSetter A function to call with an object as an argument, that performs necessary
	 *                  first-time operations to the object from the  array in case it wasn't present in this array.
	 */
	public static readonly FillById = (array: Array<any>, sourceArray: Array<any>, updateNotAdd: boolean, newObjectSetter?: Function | null): Array<any> => {
		let newobj, updatedobj;
		for (let i = 0; i < sourceArray.length; i++) {
			newobj = sourceArray[i];
			if (newobj !== null) {
				updatedobj = DTOArray.FindById(array, newobj.id);
				//If it's not in dest, is a new object, then push, otherwise copy
				if (updatedobj && (updatedobj as any).id) CloneFactory.cloneProperties(updatedobj, newobj);
				else {
					if (newObjectSetter) newObjectSetter(newobj);
					array.push(newobj);
					updatedobj = newobj;
				}
				if (updateNotAdd) updatedobj.__keepInArray = true;
				//if object was present but not found by id and thus pushed new, old one wont get flagged, and thus removed
			}
		}
		if (updateNotAdd) {
			//Remove those that weren't updated
			for (let i = array.length - 1; i >= 0; i--) {
				if (!array[i].__keepInArray) {
					if (array[i].destroy) array[i].destroy();
					array.splice(i, 1);
				} else delete array[i].__keepInArray;
			}
		}
		return array;
	};

	/**
	 * Uses the provided json array to update this array. Uses property id for identification.
	 * @param {any[]} jsonArray
	 * @param {Function} classCtr The class (function) that contains the fromJson method that returns a parsed object
	 * @param {Function} newObjectSetter A function to call with an object as an argument, that performs necessary
	 *                  first-time operations to the object from the json array in case it wasn't present in this array.
	 */
	public static readonly UpdateFromJsonArray = (array: Array<any>, jsonArray: Array<string>, classCtr: unknown, newObjectSetter?: Function, preFilter?: Function): Array<any> => {
		let temp;
		if (preFilter) {
			if (typeof jsonArray[0] === "string") temp = jsonArray.map((classCtr as any).fromJson).filter(preFilter as any);
			else temp = jsonArray.filter(preFilter as any);
		} else {
			if (typeof jsonArray[0] === "string") temp = jsonArray.map((classCtr as any).fromJson);
			else temp = jsonArray;
		}
		DTOArray.FillById(array, temp, true, newObjectSetter);
		return array;
	};
	public static readonly UpdateFromIdlessJsonArray = (array: Array<any>, jsonArray: Array<string>, classCtr: unknown, newObjectSetter?: Function, preFilter?: Function): Array<any> => {
		let temp;
		if (preFilter) {
			if (typeof jsonArray[0] === "string") temp = jsonArray.map((classCtr as any).fromJson).filter(preFilter as any);
			else temp = jsonArray.filter(preFilter as any);
		} else {
			if (typeof jsonArray[0] === "string") temp = jsonArray.map((classCtr as any).fromJson);
			else temp = jsonArray;
		}
		array = temp;
		return array;
	};
	/**
	 * Uses the provided json to add the object to this array. Uses property id for identification. If it is identified in
	 * this array, it is copied (updated), otherwise it's added.
	 * @param {any} json
	 * @param {Function} classCtr The class (function) that contains the fromJson method that returns a parsed object
	 * @param {Function} newObjectSetter A function to call with an object as an argument, that performs necessary
	 *                  first-time operations to the object from the json array in case it wasn't present in this array.
	 * @return {Object} The added element
	 */
	public static readonly AddFromJson = (array: Array<any>, json: unknown, classCtr: unknown, newObjectSetter?: Function | null): any | undefined => {
		const element = typeof json == "string" ? (classCtr as any).fromJson(json) : json;
		DTOArray.FillById(array, [element], false, newObjectSetter ? newObjectSetter : null);
		return DTOArray.FindById(array, element.id);
	};

	/**
	 * Removes object from this array whose id property matches the provided ip
	 */
	public static readonly DeleteById = (array: Array<any>, id: number): Array<any> => {
		for (let i = 0; i < array.length; i++) if (array[i].id === id) array.splice(i, 1);
		return array;
	};
}
