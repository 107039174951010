import { Directive, ElementRef, Input, OnInit } from "@angular/core";

@Directive({
	selector: "[appSetChildrenMaxWidth]"
})
export class SetChildrenMaxWidthDirective {
	private elem: HTMLElement;

	constructor(el: ElementRef) {
		this.elem = el.nativeElement;
		const observer = new MutationObserver(() => {
			for (let i = 0; i < this.elem.childElementCount; i++) {
				(this.elem.children.item(i) as HTMLElement).style.maxWidth = 100 / this.elem.childElementCount + (this.elem.children.item(i)?.innerHTML?.toString()?.length! / this.elem.children.item(i)?.childElementCount! - 5) + "%";
			}
		});
		observer.observe(this.elem, { childList: true });
	}
}
