export class ConfirmationDialog {
	show = false;
	header = "";
	body = "";
	confirmText = "";
	cancelCallback = (): void => {};
	confirmCallback = (): void => {};
}

export class ErrorDialogData {
	show: boolean = false;
	body: string = "";
	header: string = "Error";
	closeCallback?: () => void;
}

export class ErrorBannerData {
	show: boolean = false;
	body: string = "";
	header: string = "";
	closeBanner: number | undefined;
}
