<div *ngIf="areaTypes.length" class="area-container">
	<div class="section-shape">
		<h2>{{ text().SHAPE }}</h2>
		<div class="shape-container">
			<button
				class="shape-button  {{ areaType === type.segment ? 'selected' : '' }}"
				(click)="setAreaType(type.segment); $event.stopPropagation()"
			>
				<div class="text-icon-container">
					<img class="shape-icon" src="resources/img/map-tools/segment-icon.svg" />
					<span> {{ text().SEGMENT }}</span>
				</div>
			</button>

			<button
				class="shape-button  {{ areaType === type.cordon ? 'selected' : '' }}"
				(click)="setAreaType(type.cordon); $event.stopPropagation()"
			>
				<div class="text-icon-container">
					<img class="shape-icon" src="resources/img/map-tools/circle-icon.svg" />
					<span> {{ text().CORDON }} </span>
				</div>
			</button>
		</div>
	</div>

	<div class="section-area">
		<h2>{{ text().TYPE }}</h2>
		<div class="area-grid">
			<button
				class="type-button"
				*ngFor="let type of getAreaTypes()"
				(click)="selectAreaType(type, $event)"
				(mouseover)="toggleSelectedTool(type)"
				(mouseout)="toggleSelectedTool()"
			>
				<div class="circle-text-container">
					<svg class="color-icon" viewBox="0 0 100 100">
						<circle cx="50" cy="50" r="50" [attr.fill]="type.color"></circle>
					</svg>
					<span>{{ type.name }}</span>
				</div>
			</button>
		</div>
	</div>
</div>

<div *ngIf="areaTypes.length" class="section-bottom">
	<div class="bottom-container">
		<svg
			class="bottom-icon"
			*ngIf="areaType === type.cordon"
			width="18"
			height="21"
			viewBox="0 0 18 21"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M8.50022 0H12.4997V2.99993H8.50022V4.99988H7.50024V0H8.49976H8.50022ZM7.99982 19.0006C11.5896 19.0006 14.4996 16.0905 14.4996 12.5008C14.4996 8.91105 11.5896 6.00098 7.99982 6.00098C4.41007 6.00098 1.5 8.91105 1.5 12.5008C1.5 16.0905 4.41007 19.0006 7.99982 19.0006ZM7.99982 20.0006C12.1419 20.0006 15.4996 16.6428 15.4996 12.5008C15.4996 8.35876 12.1419 5.00098 7.99982 5.00098C3.85778 5.00098 0.5 8.35876 0.5 12.5008C0.5 16.6428 3.85778 20.0006 7.99982 20.0006Z"
				[attr.fill]="selectedTool ? selectedTool!.color : 'white'"
			/>
		</svg>

		<svg
			class="bottom-icon"
			*ngIf="areaType !== type.cordon"
			width="18"
			height="21"
			viewBox="0 0 18 21"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M9.18917 0H13.1419V2.96452H9.18917V0ZM9.18906 4.43927V0H8.20089V4.45415L4.01409 6.14091L3.8708 6.19864L3.78504 6.32714L0.585042 11.1223L0.449219 11.3258L0.526596 11.5579L2.3266 16.9579L2.42899 17.2651L2.75119 17.2973L6.59205 17.6814L9.67169 20.3761L9.95977 20.6282L10.2783 20.4158L16.8783 16.0158L17.3735 15.6857L16.9629 15.2549L15.6764 13.9048L16.8557 11.3247L17.1792 10.6169H16.4009H13.7097L14.0988 6.44624L14.1359 6.04801L13.7556 5.92432L9.18906 4.43927ZM8.69917 5.33151L4.53108 7.01073L1.55266 11.4738L3.17288 16.3345L6.85069 16.7023L7.00983 16.7182L7.13019 16.8235L10.0421 19.3714L15.8284 15.5139L14.719 14.3496L14.4855 14.1046L14.6262 13.7968L15.6226 11.6169H13.1609H12.6121L12.6631 11.0704L13.066 6.7516L8.69917 5.33151Z"
				[attr.fill]="selectedTool ? selectedTool!.color : 'white'"
			/>
		</svg>

		<span class="info-text">
			{{ areaType === type.cordon ? text().CORDON : text().SEGMENT }}
		</span>

		<span class="info-text padding-left-2" *ngIf="selectedTool">
			{{ ":" + " " + selectedTool!.name }}
		</span>
	</div>
</div>

<app-no-results *ngIf="!areaTypes.length"></app-no-results>
