import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { PATH } from "../global/constants/enums/screens";

const routes: Routes = [
	{ path: PATH.LOGIN, loadChildren: () => import("../login/login.module").then((m) => m.LoginModule) },
	{ path: PATH.RESOURCES, loadChildren: () => import("../settings/resource/resource.module").then((m) => m.ResourceModule) },
	{ path: PATH.USERS, loadChildren: () => import("../settings/user/user.module").then((m) => m.UserModule) },
	{ path: PATH.HOME, loadChildren: () => import("../navigation/components/homepage/homepage.module").then((m) => m.HomepageModule) },
	{ path: PATH.CONFIGURATION, loadChildren: () => import("../settings/types/settings.module").then((m) => m.SettingsModule) },
	{ path: PATH.INCIDENT, loadChildren: () => import("../incident/incident.module").then((m) => m.IncidentModule) },
	{ path: PATH.ACCOUNT, loadChildren: () => import("../settings/user/profile/profile.module").then((m) => m.ProfileModule) },
	{ path: "", redirectTo: PATH.LOGIN, pathMatch: "full" }
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class RoutingModule {}
