import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
	selector: "app-white-cross",
	templateUrl: "white-cross.component.html",
	styleUrls: ["white-cross.css"]
})
export class WhiteCrossButtonComponent {
	@Output() clickCb = new EventEmitter<Event>();

	constructor() {}

	clickCallback: Function = (evt: Event) => {
		this.clickCb.emit(evt);
	};
}
