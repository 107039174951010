import { DTO } from "../net/dto";
import { MapItem } from "../items/map-item";
import { WGSPoint } from "../map/location";
import { Camera } from "./camera";
import { Hardware } from "./hardware";
import { ResourceSkill } from "./skill";
import { ResourceState } from "./state";
import { Size } from "../map/size";
import { MAP_ITEM_TYPE } from "src/app/global/constants/enums/map-item-type";

export class Resource extends MapItem implements DTO {
	id: number;
	id_incident: number;
	latitude: number;
	longitude: number;
	id_camera: number;
	type: number;
	name: string;
	description: string;
	telemetry_id: number;
	id_gps_device: number;
	deleted: boolean;
	timestamp: number;
	fixed_position: boolean;
	public_id: string;
	id_state: number;
	color: string;
	icon_path: string;
	icon_width: number;
	icon_height: number;
	track_color: string;
	station: string;
	is_appliance: boolean;
	position: WGSPoint;
	icon_size: Size;
	visible: boolean;
	skills: Array<ResourceSkill> | undefined;
	fullCard: boolean | undefined;

	__camera: Camera | undefined;
	__state: ResourceState | undefined;

	__gps_signal: boolean | undefined;

	constructor(
		id: number,
		id_incident: number,
		latitude: number,
		longitude: number,
		id_camera: number,
		type: number,
		name: string,
		description?: string,
		telemetry_id?: number,
		id_gps_device?: number,
		deleted?: boolean,
		timestamp?: number,
		fixed_position?: boolean,
		public_id?: string,
		id_state?: number,
		color?: string,
		icon_path?: string,
		icon_width?: number,
		icon_height?: number,
		track_color?: string,
		station?: string,
		is_appliance?: boolean
	) {
		super(id, name, id_incident, description, MAP_ITEM_TYPE.RESOURCE);
		this.id = id;
		this.id_incident = id_incident;
		this.latitude = latitude;
		this.longitude = longitude;
		this.position = new WGSPoint(this.latitude, this.longitude);
		this.id_camera = id_camera ?? -1;
		this.type = type;
		this.name = name;
		this.description = description ? description : "";
		this.telemetry_id = telemetry_id ? telemetry_id : -1;
		this.id_gps_device = id_gps_device ? id_gps_device : -1;
		this.deleted = deleted ? deleted : false;
		this.timestamp = timestamp ? timestamp : Date.now();
		this.fixed_position = fixed_position ? fixed_position : false;
		this.public_id = public_id ? public_id : "";
		this.id_state = id_state ? id_state : -1;
		this.color = color ? color : "#000000";
		this.icon_path = icon_path ? icon_path : "M20,230 Q40,205 50,230 T90,230";
		this.icon_width = icon_width ? icon_width : 40;
		this.icon_height = icon_height ? icon_height : 40;
		this.icon_size = icon_width && icon_height ? new Size(icon_width, icon_height) : new Size(0, 0);
		this.track_color = track_color ? track_color : "#000000";
		this.station = station ? station : "";
		this.is_appliance = is_appliance ? is_appliance : false;
		this.visible = true;
	}

	public static readonly fromJson: (json: string) => Resource = (json: string) => {
		let ja = JSON.parse(json);
		if (!ja.position) ja.position = new WGSPoint(ja.latitude, ja.longitude);
		if (ja.position.lat) ja.position.latitude = ja.position.lat;
		if (ja.position.longitude) ja.position.longitude = ja.position.lng;
		if (ja.mission_id) ja.id_mission = ja.mission_id;
		return new Resource(
			ja.id,
			ja.id_mission,
			ja.position.lat,
			ja.position.lng,
			ja.camera_id,
			ja.type,
			ja.name,
			ja.description,
			ja.telemetry_id,
			ja.id_gps_device,
			ja.deleted,
			ja.timestamp,
			ja.fixed_position,
			ja.public_id,
			ja.id_state,
			ja.color,
			ja.icon_path,
			ja.icon_width,
			ja.icon_height,
			ja.track_color,
			ja.station,
			ja.is_appliance
		);
	};

	public readonly getJson: Function = () => {
		let dto: any = {
			...this
		};
		dto.camera_id = dto.id_camera;
		dto.mission_id = dto.id_incident;
		dto.station = dto.station ? dto.station : "Unspecified";
		dto.position = {
			lat: this.latitude,
			lng: this.longitude,
			alt: 0
		};
		return JSON.stringify(dto, [
			"camera_id",
			"color",
			"deleted",
			"description",
			"fixed_position",
			"icon_height",
			"icon_path",
			"icon_width",
			"id",
			"id_gps_device",
			"id_state",
			"is_appliance",
			"mission_id",
			"name",
			"position",
			"lat",
			"lng",
			"alt",
			"public_id",
			"station",
			"telemetry_id",
			"timestamp",
			"track_color",
			"type"
		]); //TODO: maybe also telemetry data?, or remove position altogether?
	};
}
