import { Incident } from "../items/incident";
import { DocumentData } from "./document-data";
import { DOCUMENT_TYPE } from "./document-type";
import * as jspdf from "jspdf";

export class PagesAsCanvas {
	name: string = "Document";
	pages = new Array<HTMLCanvasElement>();

	constructor(pages: HTMLCanvasElement[], name?: string) {
		this.pages = pages;
		if (name) this.name = name;
	}
}

/**
 * This Map holds the main logic behind the DocumentComponent. DocumentData[] is an interface which encapsulates
all the document's data classes which can be downloaded.
It is taken as an array because there can be several download calls for the same DOCUMENT_TYPE
while one document is in progress (i.e: ARA makes separate calls for each sector).
The second key 'instances' is used to control the number of download calls for the same document type,
which basically represents the length of the DocumentData[] but has been declared separately as deleting
the correct index from DocumentData is "not possible", as the templates emit the 'docReady' asynchronously.
*/
export type DocsInProgress = Map<DOCUMENT_TYPE, { documents: DocumentData[] | undefined; instances: number }>;
export type PreparedDocs = Map<DOCUMENT_TYPE, PagesAsCanvas[] | File | undefined>;

export type ZipFileData = { fname: string; data: jspdf.jsPDF | File; type: DOCUMENT_TYPE };
export type DownloadDialogs = { loading: string; taskCompleted: boolean; unavailable: boolean };
