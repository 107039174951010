import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
	selector: "app-blue-cross",
	templateUrl: "blue-gradient-cross.component.html",
	styleUrls: ["blue-cross.css"]
})
export class BlueCrossButtonComponent {
	@Input() isInCommandSector: boolean = false;
	@Input() shouldShow: boolean = true;
	@Output() clickCb = new EventEmitter<MouseEvent | TouchEvent>();
	@Input() open: Function | boolean = () => {
		return true;
	};

	public readonly clickCallback = (event: MouseEvent | TouchEvent): void => {
		this.clickCb.emit(event);
	};

	public readonly openFunc: Function = () => {
		return this.open instanceof Function ? this.open() : this.open;
	};
}
