<div class="main-black-mask" (click)="cancel(); $event.stopPropagation()"></div>
<div class="create-container">
	<div class="header">
		<h2>{{ headerText }}</h2>
		<div class="cross-container" (click)="cancel()">
			<img class="white-cross-icon" />
		</div>
	</div>
	<div class="body">
		<div class="side-panel-column">
			<div
				data-add-action-name="description"
				class="text-container"
				[class.inactive]="currentTab !== 'description'"
				[class.active]="currentTab === 'description'"
				(click)="level ? (currentTab = 'description') : 0"
			>
				<div
					[class.inactive]="currentTab !== 'description'"
					[class.active]="currentTab === 'description'"
					class="description-icon-container"
				>
					<img class="description-icon" />
				</div>
				<h3
					[class.inactive]="currentTab !== 'description'"
					[class.active]="currentTab === 'description'"
				>
					{{ optionText }}
				</h3>
			</div>
			<div
				data-add-action-name="permissions"
				class="text-container"
				[class.inactive]="currentTab !== 'permissions'"
				[class.active]="currentTab === 'permissions'"
				(click)="level ? (currentTab = 'permissions') : 0"
			>
				<div
					[class.inactive]="currentTab !== 'permissions'"
					[class.active]="currentTab === 'permissions'"
					class="permissions-icon-container"
				>
					<img class="permissions-icon" />
				</div>
				<h3
					[class.inactive]="currentTab !== 'permissions'"
					[class.active]="currentTab === 'permissions'"
				>
					{{ text().PERMISSIONS }}
				</h3>
			</div>
		</div>

		<div class="details-column">
			<!-- Description tab content -->
			<!-- levelForm -->
			<form *ngIf="currentTab === 'description'" [formGroup]="levelForm" class="permissions">
				<div class="description-container">
					<app-simple-text-field
						data-cy="levelname-input"
						[maxLength]="70"
						class="levelname-input"
						[type]="'text'"
						[data]="levelForm.controls['level'].value"
						formControlName="level"
						[placeholderText]="text().PERMISSION_LEVEL_NAME"
						[label]="text().PERMISSION_LEVEL_NAME"
						[labelBackground]="'#092536'"
						[isDisabled]="isAdmin(level)"
						[errorText]="getLevelNameErrorMessage()"
						[withError]="hasLevelNameError()"
						[margin]="20"
					></app-simple-text-field>
					<app-simple-text-box
						data-cy="levelDescription-input"
						class="levelDescription-input"
						[type]="'text'"
						[data]="levelForm.controls['description'].value"
						formControlName="description"
						[placeholderText]="text().DESCRIPTION"
						[label]="text().DESCRIPTION"
						[labelBackground]="'#092536'"
						[isDisabled]="isAdmin(level)"
						[maxLength]="74"
						[padding]="16"
						ngDefaultControl
					></app-simple-text-box>
				</div>

				<div class="buttons-container">
					<button
						data-add-action-name="cancel-modal"
						type="button"
						class="main-blue-gradient-border-button"
						(click)="cancel()"
					>
						{{ text().CANCEL }}
					</button>
					<button
						data-add-action-name="next"
						type="submit"
						class="main-blue-gradient-button"
						[disabled]="!levelForm.valid || isAdmin(level) || !isEdited()"
						(click)="saveLevel(level)"
					>
						{{ text().SAVE }}
					</button>
				</div>
				<div class="details-divider"></div>
			</form>

			<!-- Permissions tab content -->
			<form *ngIf="currentTab === 'permissions'" [formGroup]="levelForm" class="permissions">
				<div class="permissions-instructions">{{ text().PERMISSION_INSTRUCTIONS }}</div>
				<div class="permissions-container" *ngIf="permissions.length">
					<div class="group-container" *ngFor="let group of groups">
						<div class="group-header">
							<div [attr.data-cy]="'permission-' + group.title" class="group-title">
								{{ group.title }}
								<div data-cy="access" class="group-access">
									{{ getGroupAccess(group) }}
								</div>
							</div>
							<app-toggle-switch
								*ngIf="group.togglePermission"
								[attr.data-add-action-name]="'toggle-' + group.title"
								(clickCb)="togglePermissionSwitch(group)"
								[toggle]="isViewOnly(group)"
								[isDisabled]="isAdmin(level)"
							></app-toggle-switch>
						</div>
						<div class="group-items" *ngIf="group.childPermissions.length">
							<div
								*ngFor="let permission of group.childPermissions | arraySanitizer"
								class="level-item"
							>
								<div class="checkbox">
									<input
										[attr.data-cy]="'checkbox-' + permission.label"
										[checked]="permission.value"
										type="checkbox"
										[disabled]="isAdmin(level)"
										(change)="toggleCheckbox(permission)"
									/>
								</div>
								<label class="level-label">{{ permission.label }}</label>
							</div>
						</div>
					</div>
				</div>
				<div class="buttons-container">
					<button
						data-add-action-name="cancel-user"
						type="button"
						class="main-blue-gradient-border-button"
						(click)="cancel()"
					>
						{{ text().CANCEL }}
					</button>
					<button
						data-add-action-name="save-user"
						type="submit"
						[disabled]="!levelForm.valid"
						class="main-blue-gradient-button"
						(click)="saveLevel(level)"
						[disabled]="isDisabled() || isAdmin(level)"
					>
						{{ text().SAVE }}
					</button>
				</div>
				<div class="details-divider"></div>
			</form>
		</div>
	</div>
</div>

<app-two-option-dialog
	*ngIf="twoOptionDialogParams.show"
	[headerText]="twoOptionDialogParams.header"
	[bodyText]="twoOptionDialogParams.body"
	[leftText]="twoOptionDialogParams.leftText"
	[rightText]="twoOptionDialogParams.rightText"
	(cancelCallback)="twoOptionDialogParams.cancelCallback()"
	(leftCallback)="twoOptionDialogParams.leftCallback()"
	(rightCallback)="twoOptionDialogParams.rightCallback()"
	[rightButtonDisable]="false"
>
</app-two-option-dialog>
