<div class="node-wrapper">
	<div data-cy="csControlRoom" class="node-container">
		<div class="title">
			<span>{{ text().CONTROL_ROOM }}</span>
			<app-blue-cross
				*ngIf="!disable"
				class="menu-button {{ isMenuOpen() ? 'open' : '' }}"
				(clickCb)="openMenu($event)"
				[open]="isMenuOpen"
				[isInCommandSector]="true"
				[shouldShow]="!isIncidentClosed()"
			>
			</app-blue-cross>
		</div>
		<div class="body">
			<div>
				<span *ngIf="!editable">{{ object.control_room }}</span>
				<app-simple-text-field
					class="name-input"
					*ngIf="editable"
					[type]="'text'"
					[padding]="padding"
					[data]="object.control_room"
					(dataChange)="setNewControlRoom($event)"
					[placeholderText]="text().NAME"
					[label]="text().NAME"
					[maxLength]="20"
					[margin]="0"
				></app-simple-text-field>
			</div>
		</div>
	</div>
	<div class="extended-menu" *ngIf="isMenuOpen()">
		<div class="title"></div>
		<div class="option" (click)="toggleEditable()">{{ text().EDIT }}</div>
	</div>
</div>
