<app-error-banner
	*ngIf="errorBanner.show"
	[header]="errorBanner.header"
	[body]="errorBanner.body"
	(closeEvent)="errorBanner.show = false"
></app-error-banner>
<app-error-dialog
	*ngIf="ErrorDialogComponentParams.show && ErrorDialogComponentParams.body"
	[header]="ErrorDialogComponentParams.header || 'Error'"
	[body]="ErrorDialogComponentParams.body"
	(closeCallback)="closeErrorDialogComponent()"
></app-error-dialog>

<app-confirmation-dialog
	*ngIf="confirmationDialogParams.show"
	[headerText]="confirmationDialogParams.header"
	[bodyText]="confirmationDialogParams.body"
	[confirmText]="confirmationDialogParams.confirmText"
	(closeCallback)="confirmationDialogParams.cancelCallback()"
	(cancelCallback)="confirmationDialogParams.cancelCallback()"
	(confirmCallback)="confirmationDialogParams.confirmCallback()"
></app-confirmation-dialog>
