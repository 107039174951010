import { HasImage } from "../items/image-data";
import { IMAGE_STATUS } from "../items/overlay";
import { DTO } from "../net/dto";

export class Floor implements DTO, HasImage {
	id: number;
	id_floor: number;
	index!: number;
	id_parent: number;
	id_img: number;
	name: string;
	timestamp = Date.now();

	
	fileImg: string | undefined;
	imgStatus: IMAGE_STATUS = IMAGE_STATUS.MISSING;

	constructor(id_floor: number, id_parent: number, id_img: number, name: string) {
		this.id = this.id_floor = id_floor;
		this.id_parent = id_parent;
		this.id_img = id_img;
		this.name = name;
	}

	public static readonly fromJson: (json: string) => Floor = (json: string) => {
		let jc: any = JSON.parse(json);
		return new Floor(jc.id_floor, jc.id_parent, jc.id_img, jc.name);
	};

	public readonly getJson: () => string = () => {
		return JSON.stringify(this, ["id_floor", "id_parent", "id_img", "name"]);
	};
}
