import { DTO } from "../net/dto";

export class RiskType implements DTO {
	// obsoleted with new ARA
	id_risk: number;
	name: string;
	on: boolean = false;
	id: number;
	timestamp = Date.now();

	id_mission: number;

	__on: boolean | undefined;

	constructor(id: number, name: string, id_mission: number) {
		this.id = this.id_risk = id;
		this.name = name;
		this.id_mission = id_mission;
	}

	public static readonly fromJson: (json: string) => RiskType = (json: string) => {
		var jc = JSON.parse(json);
		return new RiskType(jc.id, jc.name, jc.id_mission);
	};

	public readonly getJson: () => string = () => {
		return JSON.stringify(this, ["id_risk", "name", "id_mission"]);
	};
}
