<div class="main-black-mask" (click)="cancel()"></div>
<div data-cy="ef-modal" class="edit-modal new-fsg-modal">
	<div class="title" [class.divider-bottom]="fireInfoOpen">
		{{ text().NEW_FSG }}
		<div class="coords-header">{{ fsg.lat }}º - {{ fsg.lon }}º</div>
	</div>
	<app-white-cross class="dialog-cross-button" (clickCb)="cancel()"></app-white-cross>
	<div class="body">
		<div class="name-input-container">
			<app-simple-text-field
				data-cy="ef-name"
				[type]="'text'"
				[data]="fsg.name"
				[maxLength]="20"
				(dataChange)="setNewName($event)"
				[placeholderText]="text().NAME"
				[label]="text().NAME"
			></app-simple-text-field>
		</div>
		<div class="floor-flat-container">
			<div class="floor-input-container">
				<app-simple-text-field
					data-cy="ef-floor-number"
					[type]="'number'"
					(dataChange)="setNewFloorNumber($event)"
					[placeholderText]="text().FSG_CREATION.FLOORS"
					[label]="text().FSG_CREATION.FLOORS"
				></app-simple-text-field>
			</div>
			<div class="flats-input-container">
				<app-simple-text-field
					data-cy="ef-flat-number"
					[type]="'number'"
					(dataChange)="setNewFlatNumber($event)"
					[placeholderText]="text().FSG_CREATION.FLATS_PER_FLOOR"
					[label]="text().FSG_CREATION.FLATS_PER_FLOOR"
					[required]="true"
				></app-simple-text-field>
			</div>
		</div>

		<app-naming-convention [fsg]="fsg"></app-naming-convention>

		<div
			data-add-action-name="ef-fire-origin"
			class="add-fire-location-text {{ floorsAndFlatsSet() ? 'active' : '' }}"
			(click)="toggleFireInfo()"
		>
			<span>{{ text().FSG_CREATION.FIRE_ORIGIN }}</span>
			<img class="chevron" [src]="imageUrl" />
		</div>
		<div *ngIf="fireInfoOpen" class="fire-info {{ floorsAndFlatsSet() ? '' : 'disabled' }}">
			<div class="floor-flat-container">
				<div class="floor-input-container">
					<app-simple-text-field
						data-cy="ef-fo-floor-number"
						[type]="'number'"
						(dataChange)="setNewFireFloor($event)"
						[placeholderText]="text().FSG_FLOOR_NUMBER"
						[label]="text().FSG_FLOOR_NUMBER"
						[withError]="
							(fsg.fire_floor && fsg.fire_floor !== -1 && !floorsAndFlatsSet()) ||
							!isFloorValid()
						"
					></app-simple-text-field>
				</div>
				<div class="flats-input-container">
					<app-simple-text-field
						data-cy="ef-fo-flat-number"
						[type]="'number'"
						(dataChange)="setNewFireFlat($event)"
						[placeholderText]="text().FSG_FLAT_NUMBER"
						[label]="text().FSG_FLAT_NUMBER"
						[withError]="
							(fsg.fire_flat && fsg.fire_flat !== -1 && !floorsAndFlatsSet()) ||
							!isFlatValid()
						"
					></app-simple-text-field>
				</div>
			</div>
			<div
				class="name-input-container bottom-input-container"
				appScrollToElement
				[scrollIdentifierId]="-1"
			>
				<app-simple-text-field
					data-cy="ef-comments"
					[type]="'text'"
					[data]="fsg.description"
					(dataChange)="setNewDescription($event)"
					[placeholderText]="text().COMMENTS2"
					[label]="text().COMMENTS2"
					[withError]="!!fsg.description && !floorsAndFlatsSet()"
					[isDisabled]="
						fsg.fire_floor === -1 ||
						fsg.fire_flat === -1 ||
						!floorsAndFlatsSet() ||
						!isFlatValid() ||
						!isFloorValid()
					"
				></app-simple-text-field>
			</div>
		</div>
	</div>
	<div class="dialog-single-button bottom-buttons" [class.divider-top]="fireInfoOpen">
		<button
			data-add-action-name="ef-cancel"
			class="main-blue-gradient-border-button margin-right-12px"
			(click)="cancel()"
		>
			{{ text().CANCEL }}
		</button>
		<button
			data-add-action-name="ef-create"
			class="main-blue-gradient-button"
			(click)="confirm()"
			[disabled]="!floorsAndFlatsSet() || !isFlatValid() || !isFloorValid()"
		>
			{{ text().CREATE }}
		</button>
	</div>
</div>
