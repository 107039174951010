import { DTO } from "../net/dto";
import { RiskType } from "./risk-type";

export class RiskTypeRelation implements DTO {
	id: number;
	id_risktype: number;
	id_ARA: number;
	id_mission: number;

	__object: RiskType | undefined;

	timestamp: number;
	constructor(id: number, id_risk: number, id_ARA: number, id_mission: number, timestamp?: number) {
		this.id = id;
		this.id_risktype = id_risk;
		this.id_ARA = id_ARA;
		this.id_mission = id_mission;
		this.timestamp = timestamp ? timestamp : Date.now();
	}

	public static readonly fromJson: Function = (json: string) => {
		const jc = JSON.parse(json);
		return new RiskTypeRelation(jc.id, jc.id_risktype, jc.id_ARA, jc.id_mission);
	};

	public readonly getJson: Function = () => {
		return JSON.stringify(this, ["id", "id_risktype", "id_ARA", "id_mission"]);
	};
}
