<div class="container" [class.translate]="open">
	<div class="main-container">
		<app-search-bar
			*ngIf="visible"
			[placeholder]="toolType === toolTypes.POI ? text().SEARCH_POI : text().SEARCH_AREA"
			(searchText)="searchArrays($event)"
		></app-search-bar>

		<app-body-poi
			*ngIf="toolType === toolTypes.POI && visible"
			[searchQuery]="searchQuery"
			(drawPoi)="emitDrawPoi($event)"
		></app-body-poi>

		<app-body-area
			*ngIf="toolType === toolTypes.AREA && visible"
			[searchQuery]="searchQuery"
			(drawCircle)="emitDrawCircle($event)"
			(drawPolygon)="emitDrawPolygon($event)"
		></app-body-area>
	</div>
</div>
