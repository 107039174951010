import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, ViewChild, ViewChildren, ɵdetectChanges } from "@angular/core";
import { LocaleMap } from "src/app/global/constants/text/text-interface";
import { TextProvider } from "src/app/global/constants/text/text-provider";
import * as html2canvas from "html2canvas";
import { DocumentService } from "src/app/document/document.service";
import { DocumentTemplate } from "../template.interface";
import { CommandStructure } from "src/app/dto/command-structure/command-structure";
import { CSSupport } from "src/app/dto/command-structure/cs-support";
import { CSSector } from "src/app/dto/command-structure/cs-sector";
import { PAGE_SIZES } from "src/app/dto/Documents/page-sizes";
import { Incident } from "src/app/dto/items/incident";

@Component({
	selector: "app-cs-template",
	templateUrl: "cs.component.html",
	styleUrls: ["cs.css", "../../document.css"]
})
export class CommandStructureTemplateComponent implements OnInit, DocumentTemplate, AfterViewInit {
	@Input() generationTime!: number;
	@Input() event!: Incident;
	@Input() cs!: CommandStructure;

	@Output() docReady = new EventEmitter<HTMLCanvasElement[]>();

	@ViewChildren("pageElements") pageElements!: QueryList<ElementRef>;
	@ViewChild("inspectedSectorElement") inspectedSectorElement!: ElementRef;
	@ViewChild("inspectedSupportElement") inspectedSupportElement!: ElementRef;

	public inspectedHeader: Event | undefined;
	public inspectedSectorItem: PageItem | undefined;
	public inspectedSupportItem: PageItem | undefined;
	public name = "Command Structure";

	public numberOfPages: number = 1;
	public pages = new Array<PageItem[]>(); //CS equivalent
	public readonly pageSize = PAGE_SIZES.STANDARD;

	public readonly text: () => LocaleMap;

	private readonly docService: DocumentService;
	private abortDownload = false;

	constructor(tp: TextProvider, doc: DocumentService, private cdRef: ChangeDetectorRef) {
		this.text = tp.getStringMap;
		this.docService = doc;
	}

	ngOnInit(): void {
		this.name = this.text().COMMAND_STRUCTURE;
		if (!this.event || !this.cs) {
			this.docService.downloadError$.next();
		} else {
			this.filterClosedPersonnelForIncident();
			this.setPages();
		}
		this.docService.onDownloadCancel$.subscribe(() => (this.abortDownload = true));
	}

	async ngAfterViewInit(): Promise<void> {
		try {
			await this.loadData();
			await new Promise((resolve) => setTimeout(resolve, 3000));
			const pages = this.pageElements["_results"];
			//Patch for preventing browser crash aborting de generation of documents and download
			if (pages.length >= 10) {
				this.docService.setUnavailabeError();
				return;
			}
			const output = new Array<HTMLCanvasElement>();
			for (let i = 0; i < pages.length; i++) {
				if (this.docService.downloadingFullIncident) {
					console.info(this.text().GENERATING_DOCUMENTATION_PAGE(i, pages.length));
					this.docService.setLoadingMessage(this.text().DOWNLOAD_PROCESS_TEXT);
				}
				const canvas = await html2canvas.default(this.pageElements["_results"][i].nativeElement, {
					scale: 3
				});
				if (this.abortDownload) return;
				output.push(canvas);
			}
			this.docReady.emit(output);
		} catch (error) {
			if (this.abortDownload) return;
			console.error(error);
			this.docService.downloadError$.next();
		}
	}

	public readonly setPages: Function = () => {
		const bottomMargin = 200;
		let newPage = new Array<PageItem>();
		let itemCount = 0;
		let currentPageHeight = 124;

		this.sortSectors();

		for (let i = 0; i < this.cs.sectors.length; i++) {
			let newItem: PageItem = { type: "sector", sector: this.cs.sectors[i], idx: itemCount + 1 };
			const h = this.calculateSectorHeight(newItem);
			if (currentPageHeight + h < this.pageSize.height - bottomMargin) {
				newPage.push(newItem);
				currentPageHeight += h;
			} else {
				this.pages.push(newPage);
				newPage = new Array<PageItem>();
				newPage.push(newItem);
				currentPageHeight = 124 + h;
			}
			itemCount++;
		}
		for (let i = 0; i < this.cs.supports.length; i++) {
			let newItem: PageItem = { type: "support", support: this.cs.supports[i], idx: itemCount + 1 };
			const h = this.calculateSupportHeight(newItem);
			if (currentPageHeight + h < this.pageSize.height - bottomMargin) {
				newPage.push(newItem);
				currentPageHeight += h;
			} else {
				this.pages.push(newPage);
				newPage = new Array<PageItem>();
				newPage.push(newItem);
				currentPageHeight = 124 + h;
			}
			itemCount++;
		}
		this.pages.push(newPage);
	};

	public readonly testPages: Function = () => {
		let i = 0;
		this.pages.push([]);
		for (let it = 0; it < this.cs.sectors.length; it++) {
			this.pages[0].push({
				type: "sector",
				sector: this.cs.sectors[it],
				idx: i + 1
			});
			i++;
		}
		for (let it = 0; it < this.cs.supports.length; it++) {
			this.pages[0].push({
				type: "support",
				support: this.cs.supports[it],
				idx: i + 1
			});
			i++;
		}
		return 1;
	};

	public getSectorCommanderName(item: CSSupport | CSSector | undefined): string {
		return item && item instanceof CSSector ? (item.__sectorCommander ? item.__sectorCommander.name : "---") : "---";
	}

	public getSafetyOfficerName(item: CSSupport | CSSector | undefined): string {
		return item && item instanceof CSSector ? (item.__safetyOfficer ? item.__safetyOfficer.name : "---") : "---";
	}

	public getTasks(item: CSSupport | CSSector | undefined): string {
		return item && item instanceof CSSector ? item.tasks : item ? item.capabilities : "";
	}

	public isSector(item: PageItem): boolean {
		if (item.type === "sector" && item.sector) {
			return item.sector.title === "Sector";
		}
		return false;
	}

	public isSupport(item: PageItem): boolean {
		if (item.type === "sector" && item.sector) {
			return item.sector.title === "Support";
		}
		return false;
	}

	public isOldSupport(item: PageItem): boolean {
		return item.type === "support";
	}

	public isLast(idx: number): boolean {
		const lastPage = this.pages[this.pages.length - 1];
		return idx === lastPage[lastPage.length - 1].idx;
	}
	public isFirst(idx: number): boolean {
		return idx === 1;
	}

	public isFirstOfPage(page: PageItem[], idx: number): boolean {
		return page[0].idx === idx;
	}

	public isLastOfPage(page: PageItem[], idx: number): boolean {
		return page[page.length - 1].idx === idx;
	}

	public isFirstPage(page: PageItem[]): boolean {
		return this.pages[0] === page;
	}

	public isLastPage(page: PageItem[]): boolean {
		return this.pages[this.pages.length - 1] === page;
	}

	private sortSectors(): void {
		this.cs.sectors.sort((a, b) => {
			if (a.title === "Sector" && b.title !== "Sector") {
				return -1;
			} else if (a.title !== "Sector" && b.title === "Sector") {
				return 1;
			} else {
				return 0;
			}
		});
	}

	private readonly calculateSectorHeight: (sector: PageItem) => number = (sector) => {
		this.inspectedSectorItem = sector;
		ɵdetectChanges(this);
		const output = this.inspectedSectorElement!.nativeElement.getBoundingClientRect().height;
		this.inspectedSectorItem = undefined;
		return output;
	};

	private readonly calculateSupportHeight: (support: PageItem) => number = (support) => {
		this.inspectedSupportItem = support;
		ɵdetectChanges(this);
		const output = this.inspectedSupportElement!.nativeElement.getBoundingClientRect().height;
		this.inspectedSupportItem = undefined;
		return output;
	};

	private async loadData(): Promise<void> {
		this.cdRef.detectChanges();
	}

	private filterClosedPersonnelForIncident(): void {
		this.cs.sectors.forEach((sector) => {
			sector.resources.forEach((resource) => {
				if (resource.__closed_personnel) {
					resource.__closed_personnel = resource.__closed_personnel.filter((person) => !person.object.deleted && person.id_incident === this.event.id);
				}
			});
		});
	}
}

interface PageItem {
	type: "sector" | "support";
	sector?: CSSector;
	support?: CSSupport;
	idx: number;
}
