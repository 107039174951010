<div class="main-transparent-mask" *ngIf="expanded" (click)="close()"></div>
<div class="banner-container">
	<div class="button">
		<lottie-player
			src="{{ 'resources/lottie/warning-orange.json' | baseHref }}"
			background="transparent"
			speed="1"
			autoplay
		></lottie-player>
		<div class="button-text" (touchstart)="(toggleExpand)" (mouseover)="expanded = true">
			{{ header }}
		</div>
		<img
			src="resources/img/close-small-8.85.svg"
			alt="Cross button"
			(click)="closeEvent.emit()"
			class="close-button"
		/>

		<div *ngIf="expanded" class="error-detail">
			<div>{{ errorMessage1 }}</div>
			<div class="error-detail-line2">
				<div>{{ errorMessage2 }}&nbsp;</div>
				<span class="support-channel" (click)="openSupportMenu(); $event.preventDefault()"
					>support</span
				>
				<div>&nbsp;{{ errorMessage3 }}</div>
			</div>
		</div>
	</div>
</div>
