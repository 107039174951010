import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Level } from "src/app/dto/user/level";
import { User } from "src/app/dto/user/user";
import { LocaleMap } from "src/app/global/constants/text/text-interface";
import { TextProvider } from "src/app/global/constants/text/text-provider";
import { UserService } from "src/app/settings/user/user.service";
import { forbiddenNameValidator, passwordDoesNotContainUsername, passwordValidator, passwordsMatch } from "./user-permission-modal.validators";

@Component({
	selector: "app-user-permission-modal",
	templateUrl: "user-permission-modal.component.html",
	styleUrls: ["user-permission-modal.css"]
})
export class UserPermissionModalComponent implements OnInit {
	@Input() headerText: string = "";
	@Input() selectText: string = "";
	@Input() optionText: string = "";
	@Input() user: User | undefined;
	@Input() edited: boolean = false;

	@Output() cancelCallback = new EventEmitter();
	@Output() confirmCallback = new EventEmitter();
	@Output() editing = new EventEmitter<boolean>();

	public levels: Array<Level>;
	public userForm!: FormGroup;
	public currentTab: "credentials" | "permissions" = "credentials";
	public selectedOption: string = "";
	public done: boolean = false;
	public selected: boolean = false;
	public isAnimationComplete = false;
	public isComplete = false;

	public twoOptionDialogParams: {
		show: boolean;
		header: string;
		body: string;
		leftText: string;
		rightText: string;
		cancelCallback: Function;
		leftCallback: Function;
		rightCallback: Function;
	} = {
		show: false,
		header: "",
		body: "",
		leftText: "",
		rightText: "",
		cancelCallback: () => {},
		leftCallback: () => {},
		rightCallback: () => {}
	};

	public readonly text: () => LocaleMap;

	private readonly userService: UserService;
	private readonly elementRef: ElementRef;

	constructor(textProv: TextProvider, userService: UserService, elementRef: ElementRef) {
		this.text = textProv.getStringMap;
		this.userService = userService;
		this.elementRef = elementRef;
		this.levels = this.userService.Levels;
	}

	ngOnInit(): void {
		this.userForm = new FormGroup(
			{
				username: new FormControl(this.user ? this.user?.name : "", [Validators.required, forbiddenNameValidator(this.userService.Users, this.user?.name)]),
				password: new FormControl(this.user ? this.user?.password : "", [Validators.required, passwordValidator()]),
				confirmPassword: new FormControl(this.user ? this.user?.password : "", [Validators.required, passwordValidator()]),
				level: new FormControl("")
			},
			{
				validators: [passwordDoesNotContainUsername(), passwordsMatch()],
				updateOn: "change"
			}
		);
		if (this.user) this.setLevel(this.userService.Levels.find((level) => level.id === this.user?.id_level)?.name ?? "");
	}

	public isEdited(): boolean {
		return this.user ? this.user.id > 0 : false;
	}

	public shouldEnableButton(): boolean {
		if (this.isEdited()) return this.hasChanged() && this.userForm.controls["username"].valid && !this.hasPasswordError() && !this.hasConfirmationPasswordError() && !(this.userForm.controls["password"].value && !this.userForm.controls["confirmPassword"].value);
		else return this.userForm.valid;
	}

	public readonly editingUser: (evt: any, field: string) => void = (evt, field) => {
		(this.user as any)[field] = evt;
		this.editing.emit(true);
	};
	public addValidator(): void {
		this.userForm.get("level")?.addValidators(Validators.required);
	}

	public hasUsernameError(): boolean {
		return this.userForm.controls["username"].errors && this.userForm.controls["username"].value;
	}
	public hasPasswordError(): boolean {
		const passwordContainsUsername = passwordDoesNotContainUsername()(this.userForm);

		return (this.userForm.controls["password"].value && passwordContainsUsername) || (this.userForm.controls["password"].value && this.userForm.controls["password"].errors);
	}

	public hasConfirmationPasswordError(): boolean {
		return (this.userForm.controls["confirmPassword"].errors || this.userForm.errors?.["passwordsDoNotMatch"]) && this.userForm.controls["confirmPassword"].value;
	}

	public getUsernameErrorMessage(): string {
		return this.hasUsernameError() ? this.text().USERNAME_TAKEN : "";
	}

	public getPasswordErrorMessage(): string {
		return this.hasPasswordError() ? this.text().PASSWORD_REQUIREMENTS : "";
	}

	public getConfirmationPasswordErrorMessage(): string {
		return this.hasConfirmationPasswordError() ? this.text().PASSWORD_MISMATCH : "";
	}

	public setLevel(value: string): void {
		this.selectedOption = value;
		this.userForm.get("level")?.setValue(value);
	}

	public async saveUser(): Promise<void> {
		const userLevel = this.userService.Levels.find((level) => level.name === this.userForm.value.level);
		if (!userLevel) throw new Error("Level does not exist");
		let user = new User(this.user?.id ?? -1, this.userForm.controls["username"].value);
		user.password = this.userForm.controls["password"].value;
		user.id_level = userLevel.id;
		user.__level = userLevel;
		const editedUser = await this.userService.saveUser(user);
		if (editedUser) this.confirmCallback.emit(editedUser);
	}

	public readonly cancel: Function = () => {
		if (this.hasChanged()) {
			this.twoOptionDialogParams = {
				show: true,
				header: this.text().UNSAVED_CHANGES,
				body: this.text().UNSAVED_INFO,
				leftText: this.text().DISCARD,
				rightText: this.text().REVIEW,
				cancelCallback: () => {
					this.twoOptionDialogParams.show = false;
				},
				leftCallback: () => {
					this.cancelCallback.emit();
				},
				rightCallback: () => {
					this.twoOptionDialogParams.show = false;
				}
			};
		} else {
			this.cancelCallback.emit();
		}
	};

	public navigateToNextTab(): void {
		if (this.userForm.get("username")?.value && this.userForm.get("password")?.value && this.userForm.get("confirmPassword")?.value) {
			this.done = true;
			this.currentTab = "permissions";
		}
	}

	public onAnimationComplete(): void {
		const lottiePlayerElement = this.elementRef.nativeElement.querySelector(".check");
		lottiePlayerElement.stop();
		lottiePlayerElement.remove();
		this.isAnimationComplete = true;
		this.isComplete = true;
	}

	public getLevelName(level: Level): string {
		return this.userService.getLevelName(level);
	}

	private hasData(): boolean {
		return this.userForm.controls["username"].value || this.userForm.controls["password"].value || this.userForm.controls["confirmPassword"].value;
	}
	private hasChanged(): boolean {
		if (!this.user) return this.hasData();

		return this.userForm.controls["username"].value !== this.user.name || this.userForm.controls["password"].value || this.userForm.controls["confirmPassword"].value || this.user.__level?.name !== this.userForm.value.level;
	}
}
