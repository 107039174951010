<div
	#pageElements
	*ngFor="let page of pages; let i = index"
	class="page"
	[style.width.px]="pageSize.width"
	[style.height.px]="pageSize.height"
>
	<div class="title">{{ araRight && araScore ? text().ARA : text().TASK_MANAGER }}</div>

	<div class="document-container">
		<app-ara-header [ara]="page.ara" [event]="event"></app-ara-header>

		<section class="ara-header-highest-risk">
			<div>
				<span class="bold-700">{{ getTimeStringText(page) }}:</span>
				<span>{{ page.ara.timestamp_ms | date : "HH:mm" }}</span>
			</div>
			<div *ngIf="araScore && araRight">
				<span class="bold-700">{{ text().HIGHEST_RISK }}:</span>
				<span>{{ getHighestRisk(page.ara) }}</span>
			</div>
		</section>

		<!-- Iterate over scenarios in the ARA -->
		<div *ngFor="let scenario of page.scenarios" class="scenario-section">
			<div class="scenario-title bold-600">
				<span>{{ scenario.__object?.name }}</span>
				<div class="scenario-divider"></div>
			</div>

			<!-- Iterate over risk information for each scenario -->
			<div *ngFor="let ri of scenario.riskInformations" class="ri-element">
				<section id="ri-header">
					<div class="risk-wrapper">
						<div class="ri-title bold-600">
							<span>{{ ri.__object?.name }}</span>
						</div>
						<div *ngIf="araScore && araRight" class="ri-s-l">
							<div class="risk">
								<span class="bold-500">{{ text().RISK + " " }}</span>
								<span class="font-12 bold-700">{{
									ri.severity * ri.likelihood > 0
										? ri.severity * ri.likelihood
										: ""
								}}</span>
							</div>

							<div class="s-l">
								<span class="bold-500" [class.s-l-text]="!ri.severity">{{
									text().SEVERITY + " "
								}}</span>
								<span *ngIf="ri.severity" class="bold-700">{{ ri.severity }}</span>
							</div>

							<div class="s-l">
								<span class="bold-500" [class.s-l-text]="!ri.likelihood">{{
									text().LIKELIHOOD + " "
								}}</span>
								<span *ngIf="ri.likelihood" class="bold-700">{{
									ri.likelihood
								}}</span>
							</div>
						</div>
					</div>
				</section>

				<section>
					<div *ngFor="let cm of ri.controlMeasures" class="cm-item">
						<div class="cm-info">
							<span>- {{ cm.__object && cm.__object.info }}</span>
							<ul *ngIf="cm.comments" class="cm-comments">
								<li>{{ cm.comments }}</li>
							</ul>
						</div>
						<div *ngIf="cm.done !== null && cm.done > -1" class="cm-status font-12 bold-700">
							{{ cm.done ? text().DONE : text().REQ }}
						</div>
						<div *ngIf="cm.done === null || cm.done < 0" class="cm-status font-12 bold-400">-</div>
						<div class="cm-time font-12">
							{{ getCMTimstamp(cm) ? (cm.timestamp_ms | date : "HH:mm") : "-" }}
						</div>
					</div>
				</section>
			</div>
		</div>

		<!-- FOOTER -->
		<section *ngIf="page.printFooter && araRight && araScore" class="ara-footer">
			<div class="footer-row">
				<div class="width-50">
					<span class="bold-700">{{ text().TACTICAL_MODE }}:</span>
					<span>{{ page.ara.tactics }}</span>
				</div>
				<div class="width-50 at-risk">
					<span class="bold-700 at-risk-bold">{{ text().AT_RISK }}:</span>
					<span>{{ getWhoIsAtRisk(page.ara.atRisk) }}</span>
				</div>
			</div>
			<div class="environment">
				<span class="bold-700">{{ text().ENVIRONMENTAL_RISK_MEASURES }}:</span>
				<span>{{ page.ara.environment_details }}</span>
			</div>

			<div class="signature">
				<div>
					<span class="font-12 bold-700">{{ text().SIGNED_BY }}:</span>
					<span class="font-12">{{ page.ara.__sign_user?.name | titlecase }}</span>
				</div>
				<img
					#signatureEl
					*ngIf="page.ara.signImg"
					[src]="page.ara.signImg | sanitizeUrl"
					class="signature-image"
				/>
			</div>
		</section>

		<div class="ara-page-index">
			<span class="bold-700">{{ text().PAGE }}:</span>
			<span class="bold-500">{{ i + 1 }} / {{ pages.length }}</span>
		</div>
	</div>
</div>

<div class="inspector">
	<div #inspectedAraHeaderElement *ngIf="inspectedPage">
		<div class="title">{{ text().ARA }}</div>
		<div class="document-container">
			<app-ara-header [ara]="inspectedPage.ara" [event]="event"></app-ara-header>
		</div>
	</div>
	<section #inspectedScenarioElement *ngIf="inspectedScenario">
		<div class="scenario-title bold-600">
			<span>{{ inspectedScenario.name }}</span>
			<div class="scenario-divider"></div>
		</div>
	</section>
	<section #inspectedRiElement *ngIf="inspectedRiskInfo" class="risk-wrapper">
		<div class="bold-600 ri-title">
			<span>{{ inspectedRiskInfo.name }}</span>
		</div>
		<div *ngIf="araScore && araRight" class="ri-s-l">
			<div class="risk">
				<span class="bold-500">{{ text().RISK + " " }}</span>
			</div>
		</div>
	</section>

	<div #inspectedCmElement *ngIf="inspectedCm" class="cm-info">
		<span>- {{ inspectedCm.info }}</span>
		<ul class="cm-comments">
			<li></li>
		</ul>
	</div>

	<section
		#inspectedFooterEl
		*ngIf="inspectedPage && inspectedPage.printFooter && araScore && araRight"
		class="ara-footer"
	>
		<div class="display-flex margin">
			<div class="width-50"></div>
			<div class="width-50">
				<span class="bold-700">{{ text().AT_RISK }}: </span>
				<span>{{ getWhoIsAtRisk(inspectedPage.ara.atRisk) }}</span>
			</div>
		</div>
		<div class="environment">
			<span class="bold-700">{{ text().ENVIRONMENTAL_RISK_MEASURES }}: </span>
			<span>{{ inspectedPage.ara.environment_details }}</span>
		</div>

		<div class="signature"></div>
	</section>
</div>
