import { APP_INITIALIZER, ErrorHandler, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { AppComponent } from "./app.component";
import { MainModule } from "./global/main.module";
import { RoutingModule } from "./navigation/routing.module";
import { LoginModule } from "./login/login.module";
import { NetModule } from "./http/net.module";
import { UIModule } from "./widgets/ui/ui.module";
import { SsoService } from "./login/sso.service";
import { NavigationModule } from "./navigation/components/navigation.module";
import { DocumentModule } from "./document/document.module";
import { ErrorHandlerService } from "./global/error/error-handler.service";

function initializer(sso: SsoService): () => Promise<boolean> {
	return () => sso.initKC();
}
@NgModule({
	declarations: [AppComponent],
	imports: [BrowserModule, NetModule, MainModule, RoutingModule, LoginModule, UIModule, NavigationModule, DocumentModule],
	providers: [
		{
			provide: APP_INITIALIZER,
			useFactory: initializer,
			multi: true,
			deps: [SsoService]
		},
		{
			provide: ErrorHandler,
			useClass: ErrorHandlerService
		}
	],
	bootstrap: [AppComponent]
})
export class AppModule {}
