import { Component, Input } from "@angular/core";
import { LocaleMap } from "src/app/global/constants/text/text-interface";
import { TextProvider } from "src/app/global/constants/text/text-provider";

@Component({
	selector: "app-plain-input-search",
	templateUrl: "plain-input-search.component.html",
	styleUrls: ["plain-input-search.css"]
})
export class PlainInputSearchComponent {
	@Input() queryOutput: string = "";
	@Input() placeholderText: string = "";
	@Input() alwaysOpen: boolean = false;

	@Input() onChangeCb: Function = () => {};

	public text: () => LocaleMap;
	open: boolean = false;

	constructor(textProv: TextProvider) {
		this.text = textProv.getStringMap;
	}

	change: Function = (queryOutput: string) => {
		this.onChangeCb(queryOutput);
	};
}
