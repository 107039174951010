export class DBError {
	error_code: string;
	severity: string;
	description: string;

	constructor(error_code: string, severity: string, description: string) {
		this.error_code = error_code.trim();
		this.severity = severity.trim();
		this.description = description.trim();
	}

	public static readonly fromJson = (json: string): DBError | null => {
		try{
			var ja = JSON.parse(json);
			if (ja && "error_code" in ja && "severity" in ja && "description" in ja) return new DBError(ja.error_code, ja.severity, ja.description);
			else return null;
		}
		catch(e){
			return new DBError('Unspecified', 'Error', json)
		}
		
	};
}
