<div
	class="calendar-icon"
	[class.calendar-icon-blue]="isFilterApplied && dateFrom && dateTo"
	(click)="open = true; $event.stopPropagation()"
></div>
<div
	class="main-transparent-mask"
	*ngIf="open"
	(click)="open = false; $event.stopPropagation()"
></div>
<div class="calendar-filter-container" *ngIf="open">
	<div class="cancel-button calendar">
		<app-white-cross
			class="cross-button"
			(clickCb)="closeCalendar(); $event.stopPropagation()"
		></app-white-cross>
	</div>
	<div class="calendar-title">{{ text().FILTER_BY + " " + (text().DATE | lowercase) }}</div>
	<div class="calendar-input-dates">
		<div class="main-input-container homepage-calendar">
			<app-simple-text-field
				[data]="dateFromStr"
				[type]="'date'"
				[padding]="padding"
				[fontSize]="fontSize"
				[lineHeight]="lineHeight"
				(dataChange)="refreshDateFrom($event)"
				[label]="text().FROM"
				(blurred)="setCalendarDate(true)"
			></app-simple-text-field>
		</div>
		<div class="main-input-container homepage-calendar">
			<app-simple-text-field
				[data]="dateToStr"
				class="main-input-field"
				[type]="'date'"
				[padding]="padding"
				[fontSize]="fontSize"
				[lineHeight]="lineHeight"
				(dataChange)="refreshDateTo($event)"
				[label]="text().TO"
				(blurred)="setCalendarDate(false)"
			></app-simple-text-field>
		</div>
	</div>
	<div class="calendar-month-arrows">
		<div class="calendar-month">
			{{ text().MONTHS_ARRAY[newDate.getMonth()] + " " + newDate.getFullYear() }}
		</div>
		<div class="calendar-arrows">
			<div class="calendar-arrowLeft" (click)="changeMonth(-1)"></div>
			<div class="calendar-arrowRight" (click)="changeMonth(1)"></div>
		</div>
	</div>
	<div class="calendar-grid-container-days calendar-days-of-week">
		<div *ngFor="let dayName of text().DAYS_ARRAY; let idx = index" class="calendar-cell">
			{{ dayName }}
		</div>
	</div>

	<div class="calendar-grid-container">
		<div
			id="{{ idx }}"
			(click)="selectDate(idx)"
			class="calendar-cell{{
				idx < firstDayPos || idx > firstDayNextMonthPos ? '-grey calendar-no-click' : ''
			}}  {{ styleRange(idx) ? 'range' : '' }} {{ isDaySelected(idx) ? 'selected' : '' }} {{
				rightCase(idx) ? 'leftshadow' : ''
			}} {{ leftCase(idx) ? 'rightshadow' : '' }}"
			*ngFor="let day of monthGrid; let idx = index"
		>
			<div class="calendar-cell-value">{{ day }}</div>
			<div
				class="cell-background {{ isLastRow(idx) ? 'lastrow' : '' }} {{
					isFirstRow(idx) ? 'firstrow' : ''
				}}"
			></div>
		</div>
	</div>
	<div class="calendar-buttons-container">
		<div class="calendar-button" (click)="clearFilter()">{{ text().CLEAR }}</div>
		<div class="calendar-button" (click)="applyFilter()">{{ text().APPLY }}</div>
	</div>
</div>
