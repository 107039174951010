import { WGSPoint } from "./location";

export class Bounds {
	public sw: WGSPoint;
	public ne: WGSPoint;

	constructor(sw: WGSPoint, ne: WGSPoint) {
		this.sw = sw;
		this.ne = ne;
	}
}
