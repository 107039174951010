<div class="main-black-mask" (click)="closeCallback()"></div>
<div data-cy="change-password-modal" class="edit-modal">
	<div class="title">
		{{ header }}
	</div>
	<app-white-cross class="dialog-cross-button" (clickCb)="closeCallback()"></app-white-cross>
	<div class="body">
		<div class="new-password-container">
			<app-simple-text-field
				data-cy="new-password"
				[type]="'password'"
				(dataChange)="refreshNewPassword($event)"
				[placeholderText]="text().NEW_PASSWORD"
				[label]="text().NEW_PASSWORD"
			></app-simple-text-field>
		</div>
		<div class="repeat-password-container">
			<app-simple-text-field
				data-cy="confirm-new-password"
				[type]="'password'"
				(dataChange)="refreshNewConfirmPassword($event)"
				[placeholderText]="text().CONFIRM_NEW_PASSWORD"
				[label]="text().CONFIRM_NEW_PASSWORD"
			></app-simple-text-field>
		</div>
	</div>
	<div class="dialog-single-button">
		<button
			class="main-blue-gradient-border-button margin-right-12px"
			(click)="closeCallback()"
		>
			{{ text().CANCEL }}
		</button>
		<button
			data-add-action-name="update-password"
			class="main-blue-gradient-button {{ canSave() ? '' : 'inactive' }}"
			(click)="save()"
		>
			{{ text().SET_ADDRESS }}
		</button>
	</div>
</div>
