export interface PAGE_SIZE {
	width: number;
	height: number;
}

export abstract class PAGE_SIZES {
	public static readonly STANDARD: PAGE_SIZE = {
		width: 595,
		height: 842
	};

	public static readonly STANDARD_FLIPPED: PAGE_SIZE = {
		width: 1785,
		height: 1280
	};

	public static DECISION: PAGE_SIZE = {
		width: 1280,
		height: 1785
	};

	public static readonly FSG: PAGE_SIZE = {
		width: 842,
		height: 595
	};
}
