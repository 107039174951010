import { DTO } from "../net/dto";

export class ResourceSkill implements DTO {
	id: number;
	id_icon: number;
	name: string;

	timestamp = Date.now();
	constructor(id: number, id_icon: number, name: string) {
		this.id = id;
		this.id_icon = id_icon;
		this.name = name;
	}

	public static readonly fromJson: Function = (json: string) => {
		var jc = JSON.parse(json);
		return new ResourceSkill(jc.id_skill, jc.id_icon, jc.name);
	};

	public readonly getJson: Function = () => {
		return JSON.stringify(this, ["id", "id_icon", "name"]);
	};
}
