import { DTO } from "../net/dto";

export class CheckList implements DTO {
	id: number = -1;
	id_mission: number;
	id_user: number;
	item1: boolean;
	item2: boolean;
	item3: boolean;
	item4: boolean;
	item5: boolean;
	item6: boolean;
	item7: boolean;
	item8: boolean;
	item9: boolean;
	history_string: string;
	timestamp_ms: number;
	timestamp: number;

	constructor(missionId: number, userId: number, item1: boolean, item2: boolean, item3: boolean, item4: boolean, item5: boolean, item6: boolean, item7: boolean, item8: boolean, item9: boolean, history: string, timestamp?: number) {
		this.id_mission = missionId;
		this.id_user = userId;
		this.item1 = item1;
		this.item2 = item2;
		this.item3 = item3;
		this.item4 = item4;
		this.item5 = item5;
		this.item6 = item6;
		this.item7 = item7;
		this.item8 = item8;
		this.item9 = item9;
		this.history_string = history;
		this.timestamp_ms = timestamp ? timestamp : Date.now();
		this.timestamp = this.timestamp_ms;
	}

	public static readonly fromJson: (json: string) => CheckList = (json: string) => {
		var jc = JSON.parse(json);
		return new CheckList(jc.id_mission, jc.id_user, jc.item1, jc.item2, jc.item3, jc.item4, jc.item5, jc.item6, jc.item7, jc.item8, jc.item9, jc.history_string, jc.timestamp_ms);
	};

	public readonly getJson: () => string = () => {
		return JSON.stringify(this, ["id_mission", "id_user", "item1", "item2", "item3", "item4", "item5", "item6", "item7", "item8", "item9", "history_string", "timestamp_ms"]);
	};
}
