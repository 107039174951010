<div class="container">
	<div class="header">
		<div class="chip">
			<div class="icon"><img [attr.src]="getTypeIcon(flat.flat_type)" /></div>
			<div class="floor-number">{{ flat.floor_number }}</div>
		</div>
	</div>
	<div class="body">
		<div class="set-text">
			<span>{{ this.text().FSG_SELECT_TEXT }}:</span>
		</div>
		<div class="option-list">
			<div
				*ngFor="let option of defaults"
				class="chip option {{ isTypeSelected(option) ? 'selected' : '' }}"
				(click)="optionClick(option)"
			>
				<div class="icon"><img [attr.src]="getTypeIcon(option)" /></div>
				<div>{{ getTypeLocalizedString(option) }}</div>
			</div>
		</div>
	</div>
</div>
<div class="main-black-mask" (click)="cancel.emit()"></div>
