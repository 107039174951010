import { AfterViewChecked, AfterViewInit, Directive, ElementRef, Input } from "@angular/core";

@Directive({
	selector: "[appCheckOrientation]"
})
export class DropdownMenuCheckOrientationDirective implements AfterViewInit, AfterViewChecked {

	// this is extra padding that will be used if the orientation has to change, so it can be adjusted better to whatever component is "spawning" from

	@Input() additionalPadding: number = 0;
	public heightOffset = 0;
	private elem: HTMLElement;

	private showMenuAtTop: boolean = false;

	constructor(el: ElementRef) {
		this.elem = el.nativeElement;
	}

	ngAfterViewInit() {
		const dims = this.elem.getBoundingClientRect();
		this.heightOffset = dims.height;
		this.showMenuAtTop = dims.bottom > document.body.offsetHeight;
		this.elem.style.top = this.showMenuAtTop ? (this.heightOffset + this.additionalPadding) * -1 + "px" : "unset";
	}

	ngAfterViewChecked(): void {
		if(this.showMenuAtTop){ // if menu has been inverted we need to adapt to changes in its height, as it has to grow in an inverted manner (from bottom to top)	
			const dims = this.elem.getBoundingClientRect();
			this.heightOffset = dims.height;
			this.elem.style.top = (this.heightOffset + this.additionalPadding) * -1 + "px";
		}
	}
}
