import * as moment from "moment";
import { DTO } from "src/app/dto/net/dto";
import { CSSector } from "../command-structure/cs-sector";
import { User } from "../user/user";
import { ControlMeasureRelation } from "./control-measure-relation";
import { Hazard } from "./hazard";
import { RiskInformationRelation, SLRating } from "./risk-information-relation";
import { RiskTypeRelation } from "./risk-type-relation";
import { Scenario } from "./scenario";
import { ScenarioRelation } from "./scenario-relation";

export class Ara implements DTO {
	id_ara: number;
	id: number;
	id_ics_sector: number;
	id_hazard: number;
	id_fill_user: number;
	id_uploaded_file_signature: number;
	station: string;
	is_environment_considered: boolean; //Removed from the frontend with ARA 3.0 04/05/23. Not removed thoroughly for "backward compatibility"
	environment_details: string;
	address: string;
	id_mission: number;
	timestamp_ms: number;
	tactics: string;
	id_scenario: number;
	id_sign_user: number;
	atRisk: Array<RiskTypeRelation>;
	signImg: string = "";
	is_new: boolean;

	scenarios: Array<ScenarioRelation>;

	__sector: CSSector | undefined;
	__hazard: Hazard | undefined;
	__scenario: Scenario | undefined;
	__likelihoodOption: any;
	__severityOption: any;
	__fill_user: User | undefined;
	__sign_user: User | undefined;
	__timestamp_string: string | undefined;

	timestamp = Date.now();

	private likelihood: number;
	private severity: number;

	constructor(
		id_ara: number,
		id_sector: number,
		id_mission: number,
		id_hazard: number,
		id_scenario: number,
		likelihood: number,
		severity: number,
		id_fill_user?: number,
		id_uploaded_signature?: number,
		station?: string,
		is_environment_considered?: boolean,
		environment_details?: string,
		address?: string,
		ms?: number | null,
		timestamp?: Date | null,
		tactics?: string,
		id_sign_user?: number,
		risks?: Array<RiskTypeRelation>,
		is_new?: boolean,
		scenarios?: Array<ScenarioRelation>
	) {
		this.id_ara = this.id = id_ara;
		this.id_ics_sector = id_sector ? id_sector : -3;
		this.id_hazard = id_hazard ? id_hazard : -1;
		this.likelihood = likelihood;
		this.severity = severity;
		this.id_fill_user = id_fill_user ? id_fill_user : -1;
		this.id_uploaded_file_signature = id_uploaded_signature ? id_uploaded_signature : -1;
		this.station = station ? station : "";
		this.is_environment_considered = is_environment_considered || false;
		this.environment_details = environment_details || "";
		this.address = address ? address : "";
		this.id_mission = id_mission;
		this.timestamp_ms = timestamp ? Date.parse(moment.utc(timestamp).toString()) : ms || Date.parse(moment.utc(Date.now()).toString());
		this.tactics = tactics || "";
		this.id_scenario = id_scenario ? id_scenario : -1;
		this.id_sign_user = id_sign_user ? id_sign_user : -1;
		this.atRisk = risks ? risks : [];
		this.is_new = is_new ?? true;
		this.scenarios = scenarios ?? [];
	}

	public static readonly fromJson: (json: string) => Ara = (json: string) => {
		const jc = JSON.parse(json);
		return new Ara(
			jc.id,
			jc.id_ics_sector,
			jc.id_mission,
			jc.id_hazard,
			jc.id_scenario,
			jc.likelihood,
			jc.severity,
			jc.id_fill_user,
			jc.id_uploaded_file_signature,
			jc.station,
			jc.is_environment_considered,
			jc.environment_details,
			jc.address,
			jc.ms,
			jc.timestamp,
			jc.tactics,
			jc.id_sign_user,
			jc.atRisk,
			jc.is_new,
			jc.scenarios
		);
	};

	getSL(): SLRating {
		if (this.severity && this.likelihood)
			return {
				severity: this.severity,
				likelihood: this.likelihood
			};
		const ri = this.getTopRatedRI();
		if (ri && ri.severity && ri.likelihood)
			return {
				severity: ri.severity,
				likelihood: ri.likelihood
			};
		return {
			severity: 0,
			likelihood: 0
		};
	}

	getTopRatedRI(): RiskInformationRelation | undefined {
		let topRi;
		for(let j = 0;j < this.scenarios.length; j++){
			for (let i = 0; i < this.scenarios[j].riskInformations.length; i++) {
				const rating = this.scenarios[j].riskInformations[i].severity * this.scenarios[j].riskInformations[i].likelihood;
				if (!topRi || topRi.severity * topRi.likelihood < rating) topRi = this.scenarios[j].riskInformations[i];
			}
		}
		return topRi;
	}

	public readonly getJson: () => string = () => {
		return JSON.stringify(this, (prop, val) => {
			if (prop.match(/__|\$\$/)) return undefined;
			return val;
		});
	};
}
