<div class="container">
	<canvas
		class="inner-container main-input-field {{ isFilled() || drawn ? 'filled' : '' }} {{
			isFocus() || drawing ? 'focus' : ''
		}}"
		#canvasRef
		(mouseleave)="canvasBlur($event)"
		(mousedown)="canvasMousedown($event)"
		(touchstart)="canvasTouchdown($event)"
		(mousemove)="canvasMousemove($event)"
		(touchmove)="canvasTouchmove($event)"
		(mouseup)="canvasMouseup($event)"
		(touchend)="canvasTouchup($event)"
	></canvas>
	<img (click)="eraseAllDraw()" class="canvas-undo-icon" src="resources/img/icons-ara/Undo.svg" />
</div>
