import { Component, ElementRef, EventEmitter, Output, ViewChild } from "@angular/core";
import { SLRating } from "src/app/dto/ara/risk-information-relation";

@Component({
	selector: "app-sl-matrix",
	templateUrl: "sl-matrix.component.html",
	styleUrls: ["sl-matrix.css"]
})
export class SLMatrixComponent {
	@Output() cellClick = new EventEmitter<SLRating>();

	@ViewChild("matrix") matrix!: ElementRef;

	private activeCoords: { x: number; y: number } | undefined;

	public readonly clickCell = (x: number, y: number): void => {
		this.selectCell(x, y);
		this.cellClick.emit({ likelihood: x, severity: y });
	};

	public readonly selectCell = (x: number, y: number): void => {
		this.activeCoords = { x, y };
	};

	public readonly unselect = (): void => {
		this.activeCoords = undefined;
	};

	public readonly isActive = (x: number, y: number): boolean => {
		return this.activeCoords?.x === x && this.activeCoords?.y === y;
	};
}
