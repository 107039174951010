import { Component, EventEmitter, HostListener, Input, OnInit, Output } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { WebRequestFactory } from "src/app/http/web.request.factory";

@Component({
	selector: "app-image-viewer",
	templateUrl: "image-viewer.component.html",
	styleUrls: ["image-viewer.css"]
})
export class ImageViewerComponent implements OnInit {
	@Input() image: string | undefined;
	@Input() filename: string | undefined;
	@Input() idfile: number | undefined;
	@Input() images: Array<{ image?: string; filename?: string; idfile?: number }> = [];

	@Output() cancelCb = new EventEmitter<void>();

	@HostListener("window:keydown", ["$event"])
	public handleKeyboardEvent(event: KeyboardEvent): void {
		if (event.key === "ArrowRight") {
			this.nextImage();
		} else if (event.key === "ArrowLeft") {
			this.previousImage();
		}
	}

	public safeSrc!: SafeResourceUrl;
	public currentIndex: number = 0;

	private touchStartX: number = 0;
	private touchEndX: number = 0;
	private readonly wreq: WebRequestFactory;
	private readonly sanitizer: DomSanitizer;

	constructor(sanitizer: DomSanitizer, wreq: WebRequestFactory) {
		this.sanitizer = sanitizer;
		this.wreq = wreq;
	}

	ngOnInit() {
		if (this.images && this.images.length > 0) {
			this.loadImage(this.currentIndex);
		} else if (this.image || this.filename || this.idfile) {
			if (this.image) {
				this.getImgSrc(this.image);
			} else if (this.filename) {
				this.getImgSrcByName(this.filename);
			} else if (this.idfile) {
				this.getImgSrcById(this.idfile);
			}
		} else {
			throw "ImageViewerComponent rendered without an image source";
		}
	}

	public getImgSrc(imageUrl: string): void {
		this.safeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(imageUrl);
	}

	public async getImgSrcByName(filename: string): Promise<void> {
		const blob = await this.wreq.getFileByName(filename);
		this.createAndSetSafeSrcFromBlob(blob);
	}

	public async getImgSrcById(idfile: number): Promise<void> {
		const blob = await this.wreq.getFile(idfile);
		this.createAndSetSafeSrcFromBlob(blob);
	}

	public readonly onCancel: Function = () => {
		this.cancelCb.emit();
	};

	public nextImage(): void {
		if (this.currentIndex < this.images.length - 1) {
			this.currentIndex++;
			this.loadImage(this.currentIndex);
		}
	}

	public previousImage(): void {
		if (this.currentIndex > 0) {
			this.currentIndex--;
			this.loadImage(this.currentIndex);
		}
	}

	public onTouchStart(event: TouchEvent): void {
		this.touchStartX = event.touches[0].clientX;
	}

	public onTouchMove(event: TouchEvent): void {
		this.touchEndX = event.touches[0].clientX;
	}

	public onTouchEnd(): void {
		if (this.touchEndX < this.touchStartX) {
			this.nextImage();
		} else if (this.touchEndX > this.touchStartX) {
			this.previousImage();
		}
	}

	private createAndSetSafeSrcFromBlob(blob: Blob): void {
		if (blob && blob instanceof Blob) {
			const url = URL.createObjectURL(blob);
			this.safeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(url);
		}
	}

	private loadImage(index: number) {
		const imgSource = this.images[index];
		if (imgSource) {
			if (imgSource.idfile) {
				this.getImgSrcById(imgSource.idfile);
			} else if (imgSource.filename) {
				this.getImgSrcByName(imgSource.filename);
			} else if (imgSource.image) {
				this.getImgSrc(imgSource.image);
			} else {
				throw "Invalid image source";
			}
		}
	}
}
