<div class="container">
	<div
		#pageElements
		*ngFor="let page of pages"
		class="page"
		[style.width.px]="pageSize.width"
		[style.height.px]="pageSize.height"
	>
		<div class="title">
			{{ text().DEBRIEF }}
		</div>
		<div class="header">
			<div class="header-row">
				<div class="header-col">
					<div>
						<b>{{ text().DATE_OF_EVENT }}:</b>
					</div>
					<div>
						<b>{{ text().LOCATION }}:</b>
					</div>
					<div>
						<b>{{ text().DEBRIEF_TEAM_NAMES }}:</b>
					</div>
				</div>
				<div class="header-col">
					<div>
						<b>{{ text().TYPE_OF_INCIDENT }}:</b>
					</div>
				</div>
			</div>
			<div class="doc-generated-on">
				<b>{{ text().DOCUMENT_GENERATED_ON }}</b
				>:
				{{
					generationTime
						| date
							: "dd/MM/YY
				HH:mm"
				}}
			</div>
		</div>
		<div *ngFor="let option of page" class="debrief-option">
			<div class="option-title">
				<div class="line"></div>
				<span>{{ option.header }}</span>
			</div>
			<div class="option-body">
				<div *ngFor="let item of option.options" class="option-item">
					{{ item }}
				</div>
			</div>
		</div>
	</div>
</div>

<div class="inspector">
	<div #inspectedOptionElement *ngIf="inspectedOption">
		<div class="option-title">
			<div class="line"></div>
			<span>{{ inspectedOption.header }}</span>
		</div>
		<div class="option-body">
			<div *ngFor="let item of inspectedOption.options" class="option-item">
				{{ item }}
			</div>
		</div>
	</div>
</div>
