import { Component } from "@angular/core";
import { LocaleMap } from "src/app/global/constants/text/text-interface";
import { TextProvider } from "src/app/global/constants/text/text-provider";

@Component({
	selector: "app-no-results",
	templateUrl: "app-no-results.component.html",
	styleUrls: ["app-no-results.css"]
})
export class NoResultsComponent {
	public readonly text: () => LocaleMap;

	constructor(textProv: TextProvider) {
		this.text = textProv.getStringMap;
	}
}
