<div class="section-search">
	<div class="search-container">
		<span
			class="search-icon"
			(click)="isUserSearch ? close() : null"
			[class.user-search]="isUserSearch"
		></span>
		<input
			*ngIf="!isUserSearch"
			class="search-bar"
			(click)="$event.stopPropagation()"
			[formControl]="search"
			[placeholder]="placeholder"
			type="text"
			autocomplete="off"
			data-add-action-name="input-search"
		/>
		<input
			*ngIf="isUserSearch"
			class="search-bar"
			(click)="$event.stopPropagation()"
			[formControl]="search"
			[placeholder]="placeholder"
			type="text"
			autocomplete="off"
			appInstantFocus
			data-add-action-name="input-search"
		/>
		<svg
			*ngIf="search.value"
			(click)="clearSearch(); $event.stopPropagation()"
			class="close-button"
			width="16"
			height="17"
			viewBox="0 0 16 17"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				class="close-button-path"
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M3.75734 4.67144C3.51129 4.91749 3.51129 5.31642 3.75734 5.56247L7.10895 8.91407L3.75729 12.2657C3.51124 12.5118 3.51124 12.9107 3.75729 13.1568C4.00334 13.4028 4.40227 13.4028 4.64832 13.1568L7.99998 9.8051L11.3516 13.1567C11.5976 13.4028 11.9966 13.4028 12.2426 13.1567C12.4887 12.9107 12.4887 12.5117 12.2426 12.2657L8.89101 8.91407L12.2426 5.56251C12.4886 5.31646 12.4886 4.91753 12.2426 4.67148C11.9965 4.42543 11.5976 4.42543 11.3515 4.67148L7.99998 8.02304L4.64837 4.67144C4.40232 4.42539 4.0034 4.42539 3.75734 4.67144Z"
				fill="#DDD9D9"
			/>
		</svg>
	</div>
</div>
