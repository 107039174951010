<div
	class="item-card width-200px box-sizing-border-box {{
		hasPoiBeenEdited() || canDelete ? 'active' : ''
	}}"
>
	<div class="inputs-left" appScrollToElement [scrollIdentifierId]="poiType.id">
		<input
			class="card-input force-uppercase width-87px border-radius-3"
			required
			[(ngModel)]="poiType.name"
			placeholder="{{ text().NAME }}"
			type="text"
		/>
		<div class="height-24px">
			<input
				class="card-input card-color-input force-uppercase"
				required
				[(ngModel)]="poiType.color"
				placeholder="Colour"
				type="text"
				(click)="openColorPicker($event)"
			/>
			<div class="card-color-sample" appSetStyle [ngStyle]="getTypeColorAsBg()"></div>
			<app-color-picker
				*ngIf="showColorPicker"
				(selectedColor)="changeColor($event)"
				[showPreferred]="true"
				[inputColor]="poiType.color"
				[closeCb]="closeColorPicker"
			></app-color-picker>
		</div>

		<label class="card-label">{{ text().PREPLANNED }}</label>
		<app-toggle-switch
			class="card-toggle"
			(clickCb)="toggleGlobalSwitch()"
			[toggle]="isPoiGlobal"
		></app-toggle-switch>
	</div>

	<div class="icon-inputs">
		<div *ngIf="!poiType.icon_path">
			<label class="select-photo">
				<input
					svg-icon-select
					popover-trigger="'mouseenter'"
					accept=".svg"
					(change)="addNewIcon($event)"
					id="file-upload"
					type="file"
				/>
				{{ text().CLICK_ADD_ICON }}
			</label>
		</div>
		<app-svg-preview
			*ngIf="poiType.icon_path"
			class="icon-preview poi-card-icon-preview"
			[path]="poiType.icon_path"
			[color]="poiType.color"
			[size]="poiType.icon_size"
		></app-svg-preview>
		<div *ngIf="poiType.icon_path" class="img-options">
			<label class="icon-edit">
				<input
					svg-icon-select
					accept=".svg"
					uploader="iconUploader"
					id="file-upload"
					(change)="addNewIcon($event)"
					type="file"
				/>
				{{ text().EDIT_ICON }}
			</label>
		</div>
	</div>

	<div class="comments">
		<span class="color-white font-size-12px">{{ text().COMMENTS.toUpperCase() }}:</span>
		<textarea class="card-textarea-input" [(ngModel)]="poiType.description"></textarea>
	</div>
</div>
<button
	type="submit"
	class="main-blue-gradient-button card-bottom-button"
	*ngIf="poiType.id === -1 || (!canDelete && hasPoiBeenEdited())"
	(click)="save()"
>
	{{ text().SAVE }}
</button>
<button
	type="submit"
	class="main-blue-gradient-button card-bottom-button"
	*ngIf="canDelete"
	(click)="delete()"
>
	{{ text().DELETE }}
</button>
