<div class="main-black-mask" (click)="cancel()"></div>
<div class="alert-dialog">
	<div class="dialog-header">
		{{ headerText }}
	</div>
	<app-white-cross class="dialog-cross-button" (clickCb)="cancel()"></app-white-cross>
	<div class="dialog-body">
		{{ bodyText }}
	</div>
	<div class="dialog-single-button">
		<button
			data-add-action-name="two-dialog-left"
			class="main-blue-gradient-border-button margin-right-12px"
			(click)="leftCallback.emit()"
		>
			{{ leftText }}
		</button>
		<button
			data-add-action-name="two-dialog-right"
			class="main-blue-gradient-button"
			[disabled]="rightButtonDisable"
			(click)="rightCallback.emit()"
		>
			{{ rightText }}
		</button>
	</div>
</div>
