import { JESIP_FORMS } from "src/app/global/constants/enums/jesip_forms";
import { DTO } from "../net/dto";

export class Member implements DTO {
	public id: number = -1;
	public id_mission: number;
	public name: string;
	public organization: string;
	public form: JESIP_FORMS;

	public timestamp = Date.now();

	constructor(id: number, id_mission: number, form: JESIP_FORMS, name?: string, organization?: string) {
		this.id = id;
		this.id_mission = id_mission;
		this.name = name ? name : "";
		this.form = form;
		this.organization = organization ? organization : "";
	}

	public static readonly fromJson: (json: string) => Member = (json) => {
		const jc = JSON.parse(json);
		let form: JESIP_FORMS;
		switch (jc.form) {
			case "methane":
				form = JESIP_FORMS.METHANE;
				break;
			case "iimarch":
				form = JESIP_FORMS.IIMARCH;
				break;
			case "jdm":
				form = JESIP_FORMS.JDM;
				break;
			default:
				form = JESIP_FORMS.DEBRIEF;
		}
		return new Member(jc.commander_number, jc.id_mission, form, jc.name, jc.organisation);
	};

	public readonly getJson: () => string = () => {
		let item = this as any;
		item.commander_number = this.id;
		item.organisation = this.organization;
		return JSON.stringify(item, ["id_mission", "name", "form", "commander_number", "organisation"]);
	};
}
