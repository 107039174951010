<div class="event-card">
	<div class="event-name" appScrollToElement [scrollIdentifierId]="incidentType.id">
		<input
			class="card-input force-uppercase event-card-input event-name-input"
			required=""
			[(ngModel)]="incidentType.name"
			placeholder="{{ text().NAME }}"
			type="text"
			title=""
		/>
	</div>
	<div class="event-color">
		<input
			class="card-input card-color-input force-uppercase event-card-input"
			[(ngModel)]="incidentType.color"
			placeholder="Colour"
			type="text"
			(click)="openColorPicker($event); $event.stopPropagation()"
		/>
		<div class="card-color-sample" appSetStyle [ngStyle]="getTypeColorAsBg()"></div>
		<app-color-picker
			*ngIf="showColorPicker"
			(selectedColor)="changeColor($event)"
			[showPreferred]="true"
			[inputColor]="incidentType.color"
			[closeCb]="closeColorPicker"
		></app-color-picker>
	</div>
	<button
		type="submit"
		*ngIf="hasIncidentBeenModified()"
		class="main-blue-gradient-button card-bottom-button incident-card-bottom-button"
		[disabled]="!incidentType.name"
		(click)="save()"
	>
		{{ text().SAVE }}
	</button>
	<button
		type="submit"
		*ngIf="canDelete"
		class="main-blue-gradient-button card-bottom-button incident-card-bottom-button"
		(click)="delete()"
	>
		{{ text().DELETE }}
	</button>
</div>
