<div data-cy="nav-tab" class="nav-tab" appSetChildrenMaxWidth>
	<div
		[attr.data-add-action-name]="tab.text"
		[attr.data-cy]="tab.index"
		*ngFor="let tab of tabs"
		class="blue {{ getActiveTab() === tab.index ? 'active' : '' }}"
		(click)="onClickOption(tab)"
	>
		{{ tab.text }}
	</div>
	<app-white-cross *ngIf="!isCommandStructure" (clickCb)="onCancel()"></app-white-cross>
</div>
<div
	class="nav-divider"
	[class.command]="isInCommandSCreen"
	[class.position]="isInPermissions"
></div>
