<div class="main-black-mask z-index-33" (click)="close(); $event.stopPropagation()"></div>
<div class="alert-dialog general-confirmation-dialog">
	<div class="dialog-header">
		{{ headerText }}
	</div>
	<app-white-cross class="dialog-cross-button" (clickCb)="close()"></app-white-cross>
	<div class="icon-container">
		<div class="confirm-icon">
			<lottie-player
				src="{{ 'resources/lottie/green-check.json' | baseHref }}"
				background="transparent"
				speed="1"
				autoplay
			></lottie-player>
		</div>
	</div>
	<div class="body">
		<div *ngIf="bodyText !== text().ARA">
			{{ bodyText }}
		</div>
		<div *ngIf="bodyText === text().ARA">
			{{ text().ARA_SAVED_POPUP_TEXT_1 }}
			<span class="text ara margin-top-10" (click)="goToAra(1)">{{
				text().ARA_SAVED_UPDATED_POPUP_TEXT_2
			}}</span>
		</div>
		<div *ngIf="infoText" class="body alert">
			<p class="info-text">{{ infoText }}</p>
		</div>
	</div>
</div>
