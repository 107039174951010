import { Observable } from "rxjs";
import { MenuService } from "../menu.service";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { NavbarItem } from "../left-nav-menu.component";

@Component({
	selector: "app-extended-menu",
	templateUrl: "./extended-menu.component.html",
	styleUrls: ["../left-nav-menu.component.css", "./extended-menu.component.css"]
})
export class ExtendedMenuComponent {
	@Input() extended!: boolean;
	@Input() items!: NavbarItem[];

	@Output() closeCb = new EventEmitter<void>();

	private readonly menuService: MenuService;

	constructor(menu: MenuService) {
		this.menuService = menu;
	}

	public readonly getActiveState = (item: NavbarItem): boolean => {
		return item.isActive();
	};

	public readonly getHoveredState = (item: NavbarItem): boolean => {
		return item.isHovered();
	};

	public readonly getNotification = (item: NavbarItem): boolean => {
		return item.hasNotification();
	};

	public zIndex(): Observable<string> {
		return this.menuService.sideMenuExtendedZIndex.asObservable();
	}
}
