import { AfterViewInit, Directive, ElementRef, EventEmitter, Input, NgZone, OnDestroy, Output } from "@angular/core";
import { DetectClickOutService } from "./detect-click-out.service";

@Directive({
	selector: "[appDetectClickOut]"
})
export class DetectClickOutDirective implements AfterViewInit, OnDestroy {
	/*
    It could be necesary to put a stop propagation in the firing function that will open the element where this directive is applied
    */

	@Input() onlyOnce: boolean = false;

	@Output() callback = new EventEmitter<Event>();

	private element: HTMLElement;
	private service: DetectClickOutService;

	constructor(el: ElementRef, service: DetectClickOutService) {
		this.element = el.nativeElement;
		this.service = service;
	}

	ngAfterViewInit(): void {
		this.service.registerListener(this.clickHandler);
	}

	ngOnDestroy(): void {
		this.service.removeListener(this.clickHandler);
	}

	private readonly clickHandler: (evt: Event) => void = (evt) => {
		const target = evt.target as HTMLElement | null;
		if (target && !this.findElem(target, "skills-overflow")) {
			this.callback.emit(evt);
		}
	};

	private readonly findElem: (target: EventTarget | null, className: string) => boolean = (target, className) => {
		if (!(target instanceof HTMLElement)) {
			return false;
		}

		if (target.classList.contains(className)) {
			return true;
		}

		if (target === this.element) {
			return true;
		}
		return this.findElem(target.parentElement, className);
	};
}
