import { Injectable } from "@angular/core";
import { WebRequestFactory } from "src/app/http/web.request.factory";
import { Change } from "src/app/dto/net/change";
import { DTOArray } from "src/app/dto/net/dto-array";
import { ReplayBuffer } from "src/app/dto/replay/replay-buffer";
import { MESSAGE_TYPE } from "../../messaging/messages";
import { MessagingService } from "../../messaging/messaging.service";
import { BufferProvider } from "./buffer.provider";
import { IncidentService } from "src/app/incident/incident.service";

@Injectable({
	providedIn: "root"
})
export class BufferService {
	constructor(private readonly wreq: WebRequestFactory, private readonly ems: IncidentService, private readonly mssg: MessagingService, private readonly bufferProvider: BufferProvider) {
		this.mssg.registerListener(MESSAGE_TYPE.LOAD_MISSION_PAGE, async () => {
			if (this.ems.getCurrentIncident()?.closed) {
				await this.getChanges();
				this.bufferProvider.$filled.next();
			} else this.fillBuffer(); // for resource tracks when live incident, when close, buffer is filled in automation-service
		});
	}

	public isBuffered(): boolean {
		return this.bufferProvider.buffered;
	}

	public isFilled(): boolean {
		return this.bufferProvider.filled;
	}

	public readonly fillBuffer: Function = async () => {
		this.bufferProvider.filled = false;
		let mission = this.ems.getCurrentIncident();
		if (mission) {
			const data = await this.wreq.getBufferData(mission.id);
			if (!data) return false;
			this.bufferProvider.buffer = ReplayBuffer.fromJson(data);
			this.bufferProvider.filled = true;
			return true;
		} else return false;
	};

	public readonly getChanges: Function = async () => {
		const mission = this.ems.getCurrentIncident()!;
		if (mission.closed) {
			const jsonArray = await this.wreq.getChangesTimestamps(mission.id);
			if (jsonArray) {
				this.bufferProvider.changes.length = 0;
				DTOArray.UpdateFromJsonArray(this.bufferProvider.changes, jsonArray, Change);
				for (let i = 0; i < this.bufferProvider.changes.length; i++) {
					this.bufferProvider.changes[i].__time_percent = (100 * (this.bufferProvider.changes[i].last_update - mission.start_time_ms)) / mission.duration;
				}
			}
		}
	};
}
