<div
	class="item-card width-270px box-sizing-border-box {{
		hasResourceBeenEdited() || canDelete ? 'active' : ''
	}}"
>
	<div
		data-cy="appliance-card-name"
		class="item-card-title"
		appScrollToElement
		[scrollIdentifierId]="appliance.id"
	>
		<input
			[attr.data-cy]="'appliance-name-input-' + appliance.name"
			required
			placeholder="{{ text().NAME }}"
			[(ngModel)]="appliance.name"
			type="text"
			(ngModelChange)="editingAppliance()"
		/>
	</div>

	<div>
		<div>
			<div class="icon-section">
				<app-svg-preview
					*ngIf="appliance.icon_path"
					class="icon-preview big"
					[path]="appliance.icon_path"
					[color]="appliance.color"
					[size]="appliance.icon_size"
				></app-svg-preview>
				<label *ngIf="appliance.icon_path" class="edit-photo edit-photo-res">
					<input
						svg-icon-select
						popover-trigger="'mouseenter'"
						(change)="addNewIcon($event)"
						id="file-upload"
						type="file"
						accept=".svg"
					/>
					{{ text().EDIT }}
				</label>

				<div *ngIf="!appliance.icon_path">
					<label class="select-photo">
						<input
							data-add-action-name="appliance-file-uploader"
							svg-icon-select
							popover-trigger="'mouseenter'"
							(change)="addNewIcon($event)"
							id="file-upload"
							type="file"
							accept=".svg"
						/>
						{{ text().CLICK_ADD_ICON }}
					</label>
				</div>

				<div class="resource-color-input">
					<div class="card-color-sample" appSetStyle [ngStyle]="getIconColorAsBg()"></div>
					<input
						data-cy="appliance-colourpicker"
						colorpicker
						class="card-input card-color-input force-uppercase"
						required
						[(ngModel)]="appliance.color"
						placeholder="Colour"
						type="text"
						(click)="openColorPicker($event)"
					/>
				</div>
				<app-color-picker
					*ngIf="showColorPicker"
					(selectedColor)="changeColor($event)"
					[showPreferred]="true"
					[inputColor]="appliance.color"
					[closeCb]="closeColorPicker"
				></app-color-picker>
			</div>
			<div class="role-station-section">
				<div class="dropdown-container">
					<app-blue-dropdown
						class="dropdown"
						[object]="appliance"
						[options]="resourceTypes"
						[getObjectText]="typeDropdownGetMainText"
						[getOptionText]="typeDropdownGetOptionText"
						[compareSelect]="typeDropdownCompareSelect"
						(itemClickCallback)="typeDropdownChangeCallback($event)"
						[placeholderText]="text().TYPE"
					>
					</app-blue-dropdown>
				</div>
				<div class="dropdown-container">
					<app-blue-dropdown
						class="dropdown"
						[object]="appliance"
						[options]="stations"
						[getObjectText]="stationDropdownGetMainText"
						[getOptionText]="stationDropdownGetOptionText"
						[compareSelect]="stationDropdownCompareSelect"
						(itemClickCallback)="stationDropdownChangeCallback($event)"
						[placeholderText]="text().STATION"
					></app-blue-dropdown>
				</div>
				<div class="dropdown-container">
					<app-blue-dropdown
						class="dropdown"
						[object]="appliance"
						[options]="incidents"
						[getObjectText]="incidentDropdownGetMainText"
						[getOptionText]="incidentDropdownGetOptionText"
						[compareSelect]="incidentDropdownCompareSelect"
						(itemClickCallback)="incidentDropdownChangeCallback($event)"
						[placeholderText]="text().INCIDENT"
					></app-blue-dropdown>
				</div>
				<div class="gps-toggle-container">
					<span>GPS</span>
					<app-toggle-switch
						class="toggle-switch"
						(clickCb)="toggleGpsSwitch(); $event.stopPropagation()"
						[toggle]="isResourceTracked"
					>
					</app-toggle-switch>
				</div>
			</div>
			<div class="hardware-section">
				<span class="resource-card-label">{{ text().CAMERA }}</span>
				<div class="hardware-field">
					<button
						class="main-blue-gradient-button resource-add-button"
						(click)="assignHardwareCb.emit(this.appliance); $event.stopPropagation()"
					>
						+{{ text().ADD }}
					</button>
					<li
						class="hardware-item"
						*ngIf="getResourceCamera()"
						title="{{ getResourceCamera()!.name }}"
					>
						<img src="resources/img/icons-hw/cam.png" />
					</li>
				</div>
			</div>
			<div class="comment-section">
				<span class="resource-card-label">{{ text().DETAILS }}</span>
				<textarea
					class="card-textarea-input"
					[(ngModel)]="appliance.description"
				></textarea>
			</div>
		</div>
		<button
			data-add-action-name="appliance-save-button"
			class="main-blue-gradient-button card-bottom-button"
			*ngIf="hasResourceBeenEdited()"
			(click)="save()"
		>
			{{ text().SAVE }}
		</button>
		<button
			data-add-action-name="appliance-delete-button"
			class="main-blue-gradient-button card-bottom-button"
			*ngIf="canDelete"
			(click)="delete()"
		>
			{{ text().DELETE }}
		</button>
	</div>
</div>
