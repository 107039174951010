import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { Note } from "src/app/dto/jesip/notes";
import { LocaleMap } from "src/app/global/constants/text/text-interface";
import { TextProvider } from "src/app/global/constants/text/text-provider";
import { SpeechToTextDirective } from "src/app/widgets/directive/speech.directive";

@Component({
	selector: "app-jesip-notes",
	templateUrl: "./jesip-notes.component.html",
	styleUrls: ["./jesip-notes.component.css"]
})
export class JesipNotesComponent implements AfterViewInit {
	@Input() notes: Array<Note> = [];

	@Output() cancelCb = new EventEmitter<void>();
	@Output() addNote = new EventEmitter<Note>();

	@ViewChild(SpeechToTextDirective) speech!: SpeechToTextDirective;
	@ViewChild("notesInput") notesInput!: ElementRef;
	@ViewChild("notesContainer") notesContainer!: ElementRef;

	public readonly text: () => LocaleMap;
	public recording: boolean = false;

	constructor(textProv: TextProvider) {
		this.text = textProv.getStringMap;
	}

	ngAfterViewInit(): void {
		this.notesInput.nativeElement.focus();
		this.scrollDown();
	}

	public readonly cancel: Function = () => {
		this.cancelCb.emit();
	};

	public readonly addNewNote = (): void => {
		if (!this.recording && this.notesInput.nativeElement.value) {
			const newNote = new Note(Date.now(), this.notesInput.nativeElement.value);
			this.addNote.emit(newNote);
			this.notesInput.nativeElement.value = "";
			this.scrollDown();
		}
	};

	public readonly scrollDown = (): void => {
		setTimeout(() => {
			this.notesContainer.nativeElement.scrollTo({
				top: this.notesContainer.nativeElement.scrollHeight + 100 - this.notesContainer.nativeElement.clientHeight,
				left: 0,
				behavior: "smooth"
			});
		});
	};

	// *************** VOICE RECOGNITION ***************
	public readonly recordNote: () => void = () => {
		if (!this.recording) {
			this.recording = true;
			this.speech.startRecording();
		} else {
			this.speech.stopRecognition();
		}
	};

	public readonly onTranscriptionAvailable = (text: string): void => {
		this.notesInput.nativeElement.value = text;
		this.notesInput.nativeElement.focus();
	};
}
