import { MenuService } from "./menu.service";
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { LocaleMap } from "src/app/global/constants/text/text-interface";
import { TextProvider } from "src/app/global/constants/text/text-provider";
import { INCIDENT_TOOL } from "src/app/global/constants/enums/incident_tools";
import { SCREEN } from "src/app/global/constants/enums/screens";
import { NavigationService } from "src/app/navigation/navigation.service";
import { AraService } from "src/app/incident/incident-tools/ara/ara.service";
import { ConfigurationService } from "src/app/settings/types/configuration.service";
import { FORM_SCREENS } from "src/app/incident/incident-tools/forms/screens.enum";
import { FormsService } from "src/app/incident/incident-tools/forms/forms.service";
import { LoginService } from "src/app/login/login.service";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { FUNCTIONALITY } from "src/app/global/functionality.service";
import { FunctionalityService } from "src/app/global/functionality.service";
import { UserService } from "../../../settings/user/user.service";

@Component({
	selector: "app-left-nav-menu",
	templateUrl: "./left-nav-menu.component.html",
	styleUrls: ["./left-nav-menu.component.css"]
})
export class LeftNavMenuComponent implements OnInit, OnDestroy {
	@Input() isToolAvailable!: Map<INCIDENT_TOOL, boolean>;
	@Input() isFuncAvailable!: Map<FUNCTIONALITY, boolean>;

	@Input() isInHistoric!: boolean;
	@Input() canEditIncidents!: boolean;
	@Input() menuType: "ICS_TOOLS" | "NAV" | "CLOSED_INCIDENTS" = "ICS_TOOLS";

	@Output() setScreen = new EventEmitter<SCREEN>();
	@Output() reopenIncident = new EventEmitter<void>();

	public readonly text: () => LocaleMap;
	public incidentId: number = -1;
	public extended: boolean = false;
	public ARANotification: boolean = false;
	public hoveredItem!: SCREEN | undefined;
	public items: NavbarItem[] = [];
	public araScore: boolean = true;
	public araRight: boolean = true;

	private readonly navServ: NavigationService;
	private readonly ARAService: AraService;
	private readonly jesip: FormsService;
	private readonly loginServ: LoginService;
	private readonly menuService: MenuService;
	private readonly funcService: FunctionalityService;
	private readonly userService: UserService;

	private destroy$ = new Subject<boolean>();

	constructor(tp: TextProvider, nav: NavigationService, araServ: AraService, fs: FormsService, conf: ConfigurationService, menu: MenuService, login: LoginService, funcService: FunctionalityService, userService: UserService) {
		this.text = tp.getStringMap;
		this.navServ = nav;
		this.ARAService = araServ;
		this.jesip = fs;
		this.loginServ = login;
		this.menuService = menu;
		this.incidentId = conf.configuration.id_current_incident;
		this.funcService = funcService;
		this.userService = userService;
	}

	ngOnInit(): void {
		this.araScore = this.funcService.isFunctionalityAvailable.get(FUNCTIONALITY.ARA_SCORE)!;
		this.araRight = this.funcService.isFunctionalityAvailable.get(FUNCTIONALITY.ARA_RIGHT)!;
		this.menuType === "ICS_TOOLS" && this.createICSMenu();
		this.navServ.$authorizedPagesChange.subscribe(() => {
			if (this.menuType === "ICS_TOOLS") setTimeout(() => this.createICSMenu());
		});
		this.ARAService.araNotification$.pipe(takeUntil(this.destroy$)).subscribe((ara) => {
			this.ARANotification = ara && ara.id_fill_user !== this.loginServ.user.id && this.navServ.currentScreen !== SCREEN.ARA;
		});
		this.menuService.isExtendedMenu
			.asObservable()
			.pipe(takeUntil(this.destroy$))
			.subscribe((value) => (this.extended = value));
	}

	ngOnDestroy(): void {
		this.destroy$.next(true);
		this.destroy$.complete();
	}

	public readonly getScreen = (): SCREEN => {
		return this.navServ.currentScreen;
	};

	public readonly goToScreen = (screen: SCREEN): void => {
		this.extended = false;
		if (screen === SCREEN.FORMS && this.hasOnlyDlAccess()) {
			this.goToForm(FORM_SCREENS.DECISION);
			return;
		}

		return this.setScreen.emit(screen);
	};

	public readonly goToForm = (form: FORM_SCREENS): void => {
		this.extended = false;
		this.navServ.setJesipScreen(form);
	};

	public readonly setHoveredItem = (params: { value: boolean; screen: SCREEN }): SCREEN | undefined => {
		return (this.hoveredItem = params.value ? params.screen : undefined);
	};

	public readonly openMenu = (): boolean => {
		return (this.extended = true);
	};

	public readonly closeMenu = (): boolean => {
		return (this.extended = false);
	};

	private readonly createICSMenu = (): void => {
		this.items = [];
		this.jesip.jesipUpdate$.subscribe(() => setTimeout(() => this.getJesipNotifications()));
		for (const item of Object.values(INCIDENT_TOOL)) {
			if (item === INCIDENT_TOOL.OFV && this.isToolAvailable.get(INCIDENT_TOOL.OFV)) {
				this.items.push(this.createMenuItem("ofv", "OFV", SCREEN.INCIDENT));
			}
			if (item === INCIDENT_TOOL.COMMAND && this.isToolAvailable.get(INCIDENT_TOOL.COMMAND) && this.isFuncAvailable.get(FUNCTIONALITY.CS)) {
				this.items.push(this.createMenuItem("command", this.text().COMMAND, SCREEN.COMMAND_STRUCTURE));
			}
			if (item === INCIDENT_TOOL.ARA && this.isToolAvailable.get(INCIDENT_TOOL.ARA) && this.hasAraOrTaskManagerAccess()) {
				this.items.push(this.createMenuItem("ara", this.araRight && this.araScore ? this.text().ARA_SHORT : this.text().TASK_MANAGER, SCREEN.ARA));
			}
			if (item === INCIDENT_TOOL.CALLOUT && this.isToolAvailable.get(INCIDENT_TOOL.CALLOUT) && this.isFuncAvailable.get(FUNCTIONALITY.CALLOUT)) {
				this.items.push(this.createMenuItem("callout", this.text().CALLOUT, SCREEN.CALLOUT));
			}
			if (item === INCIDENT_TOOL.RECOMMENDATION && this.isToolAvailable.get(INCIDENT_TOOL.RECOMMENDATION) && this.isFuncAvailable.get(FUNCTIONALITY.RECOMMENDER)) {
				this.items.push(this.createMenuItem("recommendation", this.text().RECOMMENDATION, SCREEN.RECOMMENDATION));
			}
			if (item === INCIDENT_TOOL.JESIP && this.isToolAvailable.get(INCIDENT_TOOL.JESIP) && this.hasFormsDashboardAccess()) {
				this.items.push(this.createMenuItem("forms", this.text().FORMS, SCREEN.FORMS));
			}
		}
	};

	private readonly createMenuItem = (className: string, title: string, screen: SCREEN): NavbarItem => {
		const item: NavbarItem = {
			id: screen,
			className: className,
			title: title,
			isActive: () => this.getScreen() === screen,
			isHovered: () => this.hoveredItem === screen,
			onHover: (evt: boolean) => this.setHoveredItem({ value: evt, screen: screen }),
			onClick: () => this.goToScreen(screen),
			hasNotification: () => (screen === SCREEN.FORMS ? this.getJesipNotifications() : screen === SCREEN.ARA ? this.ARANotification : false)
		};
		return item;
	};

	private readonly getJesipNotifications = (): boolean => {
		return this.jesip.notifications.some((notification) => !notification.read && notification.mission === this.incidentId);
	};

	private hasAraOrTaskManagerAccess(): boolean {
		return this.userService.getCurrentUserLevel().view_ara;
	}

	private hasFormsDashboardAccess(): boolean {
		const functionalities = [FUNCTIONALITY.EF, FUNCTIONALITY.DEBRIEF, FUNCTIONALITY.JDM, FUNCTIONALITY.METHANE, FUNCTIONALITY.IIMARCH, FUNCTIONALITY.CHECKLIST, FUNCTIONALITY.DECISION];

		const userLevel = this.userService.getCurrentUserLevel();
		const canViewDl = userLevel.view_dl || userLevel.create_dl;
		const hasFunctionalityAccess = functionalities.some((func) => this.funcService.isFunctionalityAvailable.get(func));
		const decisionLogAvailable = this.funcService.isFunctionalityAvailable.get(FUNCTIONALITY.DECISION);

		if (decisionLogAvailable && !canViewDl && !functionalities.some((func) => func !== FUNCTIONALITY.DECISION && this.funcService.isFunctionalityAvailable.get(func))) {
			return false;
		}
		return canViewDl || hasFunctionalityAccess;
	}

	private hasOnlyDlAccess(): boolean {
		const functionalities = [FUNCTIONALITY.EF, FUNCTIONALITY.DEBRIEF, FUNCTIONALITY.JDM, FUNCTIONALITY.METHANE, FUNCTIONALITY.IIMARCH, FUNCTIONALITY.CHECKLIST, FUNCTIONALITY.DECISION];

		const userLevel = this.userService.getCurrentUserLevel();
		const canViewDl = userLevel.view_dl || userLevel.create_dl;
		const decisionLogAvailable = !!this.funcService.isFunctionalityAvailable.get(FUNCTIONALITY.DECISION);
		const otherFunctionalitiesUnavailable = functionalities.filter((func) => func !== FUNCTIONALITY.DECISION).every((func) => !this.funcService.isFunctionalityAvailable.get(func));

		return decisionLogAvailable && otherFunctionalitiesUnavailable && canViewDl;
	}
}

export interface NavbarItem {
	id: SCREEN;
	className: string;
	title: string;
	isActive: () => boolean;
	isHovered: () => boolean;
	onHover: (evt: boolean) => void;
	onClick: () => void;
	hasNotification: () => boolean;
}
