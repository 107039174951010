import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ConfigurationService } from "src/app/settings/types/configuration.service";
import { AreaType, AREA_TYPE, PATTERN } from "src/app/dto/items/types/area-type";
import { LocaleMap } from "src/app/global/constants/text/text-interface";
import { TextProvider } from "src/app/global/constants/text/text-provider";

@Component({
	selector: "app-area-card",
	templateUrl: "area-card.component.html",
	styleUrls: ["area-card.css", "../../dropdown/dropdown.css"]
})
export class AreaCardComponent implements OnInit {
	@Input() canDelete: boolean = false;

	@Input() areaType: AreaType = new AreaType(-1, "", "#FFF");

	@Output() saveCb = new EventEmitter<AreaType>();

	@Output() deleteCb = new EventEmitter<AreaType>();

	public text: () => LocaleMap;

	public areaPatterns: Array<PATTERN> = [];
	public showColorPicker: boolean = false;
	public types = Object.values(AREA_TYPE);
	public openSubmenu: boolean = false;

	private readonly conf: ConfigurationService;

	constructor(conf: ConfigurationService, txtProv: TextProvider) {
		this.conf = conf;
		this.text = txtProv.getStringMap;
		this.areaPatterns = Object.values(PATTERN);
	}

	ngOnInit() {
		if (!this.areaType) throw "AreaCardComponent initialized without a valid areaType input";
	}

	public saveAreaType(): void {
		this.saveCb.emit(this.areaType);
	}

	public deleteAreaType(): void {
		this.deleteCb.emit(this.areaType);
	}

	togglePatternSelect: Function = () => {
		this.openSubmenu = !this.openSubmenu;
	};

	getTypeColorAsBg: Function = () => {
		return {
			"background-color": this.areaType.color
		};
	};

	setPatternSelectBottomBorder: Function = () => {
		return this.openSubmenu ? { "border-bottom": "none" } : {};
	};

	setPattern: Function = (pattern: PATTERN) => {
		this.areaType.pattern = pattern;
	};

	public readonly hasAreaBeenModified = (): boolean => {
		const originalType = { ...this.conf.configuration.areaTypes.find((e) => e.id === this.areaType.id) };
		if (!originalType) return true;
		return (
			this.areaType.name.toUpperCase() !== originalType.name?.toUpperCase() ||
			this.areaType.color.toUpperCase() !== originalType.color?.toUpperCase() ||
			this.areaType.description !== originalType.description ||
			this.areaType.pattern !== originalType.pattern ||
			this.areaType.type.toUpperCase() !== originalType.type?.toUpperCase()
		);
	};

	public readonly getAreaPattern = (): PATTERN => {
		return this.areaType.pattern;
	};

	public readonly getAreaColor = (): string => {
		return this.areaType.color;
	};

	public readonly openColorPicker = (evt: Event): boolean => {
		evt.stopPropagation();
		return (this.showColorPicker = !this.showColorPicker);
	};

	public readonly closeColorPicker = (): boolean => {
		return (this.showColorPicker = false);
	};

	public readonly changeColor = (color: string): string => {
		return (this.areaType.color = color);
	};

	public typeDropdownGetMainText = (areaType: AreaType): string => {
		return areaType.type === AREA_TYPE.segment ? this.text().SEGMENT : this.text().CORDON;
	};

	public typeDropdownGetOptionText = (type: AREA_TYPE): string => {
		return type === AREA_TYPE.segment ? this.text().SEGMENT : this.text().CORDON;
	};

	public typeDropdownCompareSelect = (obj: AreaType, type: AREA_TYPE): boolean => {
		const areaType: AREA_TYPE = type === this.text().SEGMENT.toLowerCase() ? AREA_TYPE.segment : AREA_TYPE.cordon;
		return obj.type.toLowerCase() === areaType;
	};

	public typeDropdownChangeCallback = (type: AREA_TYPE): AREA_TYPE => {
		const areaT: AREA_TYPE = type === this.text().SEGMENT.toLowerCase() ? AREA_TYPE.segment : AREA_TYPE.cordon;
		return (this.areaType.type = areaT);
	};
}
