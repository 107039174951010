import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, ViewChild, ViewChildren, ɵdetectChanges } from "@angular/core";
import { Decision } from "src/app/dto/decision/decision";
import { LocaleMap } from "src/app/global/constants/text/text-interface";
import { TextProvider } from "src/app/global/constants/text/text-provider";
import { DocumentTemplate } from "../template.interface";
import { DocumentService } from "src/app/document/document.service";
import { PAGE_SIZES } from "src/app/dto/Documents/page-sizes";
import * as html2canvas from "html2canvas";
import { Incident } from "src/app/dto/items/incident";

@Component({
	selector: "app-decision-log-template",
	templateUrl: "decision-log.component.html",
	styleUrls: ["decision-log.css", "../../document.css"]
})
export class DecisionLogTemplateComponent implements OnInit, DocumentTemplate, AfterViewInit {
	@Input() generationTime!: number;
	@Input() event!: Incident;
	@Input() decisions?: Array<Decision>;

	@Output() docReady = new EventEmitter<HTMLCanvasElement[]>();

	@ViewChildren("pageElements") pageElements!: QueryList<ElementRef>;
	@ViewChild("inspectedHeaderElement") inspectedHeaderElement!: ElementRef;
	@ViewChild("inspectedDecisionElement") inspectedDecisionElement!: ElementRef;

	public inspectedHeader: Incident | undefined;
	public inspectedDecision: Decision | undefined;

	public numberOfPages: number = 1;
	public pages = new Array<Decision[]>();
	public readonly pageSize = PAGE_SIZES.DECISION;

	public readonly text: () => LocaleMap;
	public isDLOperational: boolean = true;
	public isDLType: boolean = true;
	public isDLSignature: boolean = true;
	private readonly docService: DocumentService;
	private abortDownload = false;

	constructor(tp: TextProvider, doc: DocumentService) {
		this.text = tp.getStringMap;
		this.docService = doc;
	}

	ngOnInit(): void {
		if (!this.event || !this.decisions) this.docService.downloadError$.next();
		this.setPages();
		this.docService.onDownloadCancel$.subscribe(() => (this.abortDownload = true));
		this.getFunctionalityVisibility();
	}

	async ngAfterViewInit(): Promise<void> {
		try {
			const pages = this.pageElements["_results"];
			//Patch for preventing browser crash aborting de generation of documents and download
			if (pages.length >= 20) {
				this.docService.setUnavailabeError();
				return;
			}
			const output = new Array<HTMLCanvasElement>();
			for (let i = 0; i < pages.length; i++) {
				if (!this.docService.downloadingFullIncident) this.docService.setLoadingMessage(this.text().GENERATING_DOCUMENTATION_PAGE(i, pages.length));
				else {
					console.info(this.text().GENERATING_DOCUMENTATION_PAGE(i, pages.length));
					this.docService.setLoadingMessage(this.text().DOWNLOAD_PROCESS_TEXT);
				}
				console.info(this.text().GENERATING_DOCUMENTATION_PAGE(i, pages.length));
				const canvas = await html2canvas.default(this.pageElements["_results"][i].nativeElement);
				if (this.abortDownload) return;
				output.push(canvas);
			}
			this.docReady.emit(output);
		} catch (error) {
			if (this.abortDownload) return;
			this.docService.downloadError$.next();
		}
	}

	public setPages(): Decision[][] {
		const headerHeight = this.calculatePageHeaderHeight(this.event);
		const bottomMargin = 105;
		const targetHeight = this.pageSize.height - headerHeight - bottomMargin;

		let newPage: Decision[] = [];
		let currentPageHeight = 0;

		if (this.decisions && this.decisions.length) {
			for (let i = 0; i < this.decisions.length; i++) {
				const decision = this.decisions[i];
				const decisionHeight = this.calculateDecisionHeight(decision);

				if (decisionHeight > targetHeight) {
					const splitDecisions = this.splitDecision(decision, targetHeight, decisionHeight);
					for (const splitDecision of splitDecisions) {
						if (currentPageHeight + this.calculateDecisionHeight(splitDecision) > targetHeight) {
							this.pages.push(newPage);
							newPage = [];
							currentPageHeight = headerHeight;
						}

						newPage.push(splitDecision);
						currentPageHeight += this.calculateDecisionHeight(splitDecision);
					}
				} else if (currentPageHeight + decisionHeight > targetHeight) {
					this.pages.push(newPage);
					newPage = [decision];
					currentPageHeight = headerHeight + decisionHeight;
				} else {
					newPage.push(decision);
					currentPageHeight += decisionHeight;
				}
			}
		}

		if (newPage.length > 0) {
			this.pages.push(newPage);
		}

		return this.pages;
	}

	public getFunctionalityVisibility: Function = () => {
		this.isDLOperational = this.docService.isDLOperational;
		this.isDLSignature = this.docService.isDLSignature;
		this.isDLType = this.docService.isDLType;
	};
	private splitDecision(decision: Decision, targetHeight: number, decisionHeight: number): Decision[] {
		const splitSize = Math.ceil(decision.description.length / Math.ceil(decisionHeight / targetHeight));

		const splitDescription = this.docService.splitString(decision.description, splitSize);
		const splitRationale = this.docService.splitString(decision.result, splitSize);

		const splitDecisions: Decision[] = [];

		if (splitDescription.length > 0 && splitRationale.length > 0) {
			const maxLength = Math.max(splitDescription.length, splitRationale.length);

			for (let i = 0; i < maxLength; i++) {
				const descriptionPart = splitDescription[i] || "";
				const rationalePart = splitRationale[i] || "";

				const splitDecision: Decision = {
					...decision,
					description: descriptionPart,
					result: rationalePart
				};

				splitDecisions.push(splitDecision);
			}
		} else if (splitDescription.length > 0) {
			splitDescription.forEach((descriptionPart) => {
				const splitDecision: Decision = {
					...decision,
					description: descriptionPart
				};
				splitDecisions.push(splitDecision);
			});
		} else if (splitRationale.length > 0) {
			splitRationale.forEach((rationalePart) => {
				const splitDecision: Decision = {
					...decision,
					result: rationalePart
				};
				splitDecisions.push(splitDecision);
			});
		}

		return splitDecisions;
	}

	// builds a mock header to calculate its height
	private readonly calculatePageHeaderHeight: (event: Incident) => number = (event) => {
		this.inspectedHeader = event;
		ɵdetectChanges(this);
		const output = this.inspectedHeaderElement!.nativeElement.getBoundingClientRect().height;
		this.inspectedHeader = undefined;
		return output;
	};

	// builds a mock decision log to calculate its height
	// TODO: make it so it can read the css classes somehow instead of hardcoding the styling
	private readonly calculateDecisionHeight: (decision: Decision) => number = (decision) => {
		this.inspectedDecision = decision;
		ɵdetectChanges(this);
		const output = this.inspectedDecisionElement!.nativeElement.getBoundingClientRect().height;
		this.inspectedDecision = undefined;
		return output;
	};
}
