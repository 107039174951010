import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
	selector: "app-kebab-menu",
	templateUrl: "./kebab-menu.component.html",
	styleUrls: ["./kebab-menu.component.css"]
})
export class KebabMenuComponent {
	@Input() items: kebabMenuItem[] = [];

	@Output() clickEvent = new EventEmitter<KEBAB_MENU_ITEM_ID>();
	@Output() closed = new EventEmitter<void>();

	public readonly close = (): void => {
		this.closed.emit();
	};
}

export interface kebabMenuItem {
	id: KEBAB_MENU_ITEM_ID;
	title: string;
	description: string;
	disabled?: boolean;
}

export enum KEBAB_MENU_ITEM_ID {
	phone = "phone",
	email = "email",
	domain = "domain",
	download = "download",
	deactivate = "deactivate",
	preferences = "preferences"
}
