<div class="container">
	<div class="section">
		<div class="section-list">
			<div
				data-cy="jesip-item"
				*ngFor="let item of items; let i = index"
				class="item"
				[class.disabled]="isDisabled"
			>
				<div
					data-add-action-name="jesip-open-item"
					class="item-top"
					(click)="!isDisabled ? toggleItem(i) : null"
				>
					<div *ngIf="isJdm" class="number" [class.filled]="item.value">
						{{ i + 1 }}
					</div>
					<div *ngIf="!isJdm && item.key" class="number" [class.filled]="item.value">
						{{
							item.key.split("_")[1] ? item.key.split("_")[1] : item.key.split("_")[0]
						}}
					</div>

					<div>
						<div class="item-header bold">{{ item.title }}</div>
						<div class="text">{{ item.text }}</div>
					</div>
				</div>
				<div class="input-container" *ngIf="item.isExpanded || item.value">
					<textarea
						data-add-action-name="jesip-input"
						#inputElement
						appAutoResize
						appDetectClickOut
						(callback)="onMousedownOutside($event)"
						[id]="'input_' + i"
						[rows]="1"
						[(ngModel)]="item.value"
						[placeholder]="!isDisabled ? text().TYPE_INFORMATION_HERE : ''"
						[disabled]="isDisabled"
						class="jesip-item-value"
						(ngModelChange)="closeItem(item)"
						(keydown)="onKeyPressed($event, inputElement, item)"
					></textarea>
				</div>
			</div>
		</div>
	</div>
</div>
