import { Component, EventEmitter, Input, Output } from "@angular/core";
@Component({
	selector: "app-round-checkbox",
	templateUrl: "round-checkbox.component.html",
	styleUrls: ["round-checkbox.css"]
})
export class RoundCheckboxComponent {
	@Input() check: boolean = false;

	@Output() clickCb = new EventEmitter<boolean>();

	onClick = (): void => this.clickCb.emit(!this.check);
}
