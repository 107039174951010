<div class="side-menu short" [style.zIndex]="zIndex() | async">
	<div
		*ngIf="!extended"
		data-add-action-name="strategic-tools-menu-button"
		class="extend-icon fixed-extend-icon"
		(click)="this.openCb.emit()"
	></div>
	<div class="menu-icons">
		<div
			*ngFor="let item of items"
			appHover
			(isHovered)="item.onHover($event)"
			class="menu-icon"
			[ngClass]="{
				hovered: getHoveredState(item),
				'selected-icon': getActiveState(item)
			}"
			(click)="item.onClick()"
		>
			<div
				attr.data-cy="image-{{ item.className }}"
				class="item-icon decision-icon image-{{ item.className }}"
			></div>
			<div *ngIf="getNotification(item)" class="item-notification"></div>
		</div>

		<div class="transform-hide" [class.transform-show]="extended">
			<!-- hasTool function recognizes the tool by its title as defined in items in the parent component -->
			<app-jesip-tools
				*ngIf="extended && hasTool(text().FORMS)"
				(goToForm)="goToForm.emit($event)"
			>
			</app-jesip-tools>
		</div>
	</div>
	<div
		class="transform-hide close-incident-section {{ isInHistoric ? 'no-button' : '' }}"
		[class.transform-show]="extended"
		[class.iPad]="isIpad"
	>
		<!-- hasTool function recognizes the tool by its title as defined in items in the parent component -->
		<button
			data-add-action-name="close-button"
			*ngIf="hasTool('OFV') && extended && canEditIncidents"
			class="main-blue-gradient-border-button"
			(click)="reopenIncident.emit()"
		>
			{{ isInHistoric ? "" : text().CLOSE_INCIDENT }}
		</button>
	</div>
</div>
