import { Directive, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";

/**
 * Makes an item draggable and droppable to some containers.
 * To flag a container as droppable, a property "dropzone" must be set with whatever tag.
 * If the draggable element is dropped to a container that has no "dropzone" property, the drag is considered
 * cancelled.
 *
 * It doesn't do any DOM modification (it doesn't remove the element from its original father or add it to the outgoing dropzone).
 * That manipulation needs to be done at the above layer.
 */

@Directive({
	selector: "[appDroppable]"
})
export class DroppableDirective implements OnInit, OnChanges {
	@Input() dragInfo: any;
	@Input() disabled: boolean | undefined = false;

	@Output() dragStart = new EventEmitter<any>();
	@Output() dragEnd = new EventEmitter<{ zone: string; info: string | number | undefined; target: HTMLElement | undefined }>();
	@Output() dragCancel = new EventEmitter<{ info: any }>();

	private elem: HTMLElement;
	private draggableElem: HTMLElement | undefined;
	private initialX = 0;
	private initialY = 0;
	private isDragging = false;

	constructor(el: ElementRef) {
		this.elem = el.nativeElement;
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes["disabled"]) {
			this.updateDisabledState();
		}
	}

	ngOnInit(): void {
		this.updateDisabledState();
	}

	private updateDisabledState() {
		if (this.disabled) {
			this.removeDragEventListeners();
			this.elem.style.cursor = "default";
		} else {
			this.addDragEventListeners();
			this.elem.style.cursor = "grab";
		}
	}

	private addDragEventListeners(): void {
		this.elem.addEventListener("mousedown", this.dragBeginClick as any);
		this.elem.addEventListener("touchstart", this.dragBeginTouch as any);
	}

	private removeDragEventListeners(): void {
		this.elem.removeEventListener("mousedown", this.dragBeginClick as any);
		this.elem.removeEventListener("touchstart", this.dragBeginTouch as any);
	}

	private readonly dragBeginTouch: Function = (event: TouchEvent) => {
		this.initialX = event.touches[0].clientX;
		this.initialY = event.touches[0].clientY;
		this.dragBegin(event);
		document.body.addEventListener("touchend", this.dragFinish as any);
		document.body.addEventListener("touchmove", this.onMove as any, { passive: false });
		event.preventDefault();
		event.stopPropagation();
	};

	private readonly dragBeginClick: Function = (event: MouseEvent) => {
		this.initialX = event.clientX;
		this.initialY = event.clientY;
		this.dragBegin(event);
		document.body.addEventListener("mouseup", this.dragFinish as any);
		document.body.addEventListener("mousemove", this.onMove as any);
		event.preventDefault();
		event.stopPropagation();
	};

	private readonly setupDraggableElement: Function = (x: number, y: number) => {
		if (!this.draggableElem) {
			this.draggableElem = this.elem.cloneNode(true) as HTMLElement;
			this.draggableElem.style.position = "fixed";
			this.draggableElem.style.top = y + "px";
			this.draggableElem.style.left = x + "px";
			this.draggableElem.style.pointerEvents = "none";
			this.draggableElem.style.zIndex = "15";
			document.body.appendChild(this.draggableElem);
			document.body.style.cursor = "grabbing";
		}
	};

	private readonly dragFinish: Function = (evt: MouseEvent | TouchEvent) => {
		if (!this.isDragging) {
			document.body.removeEventListener("mouseup", this.dragFinish as any);
			document.body.removeEventListener("mousemove", this.onMove as any);
			document.body.removeEventListener("touchend", this.dragFinish as any);
			document.body.removeEventListener("touchmove", this.onMove as any);

			this.resetDragState();
			return;
		}
		let target;
		if (evt instanceof MouseEvent) target = evt.target;
		if (evt instanceof TouchEvent) target = document.elementFromPoint(evt.changedTouches[0].clientX, evt.changedTouches[0].clientY);
		document.body.style.cursor = "";
		target = this.getDropzoneElement(target as HTMLElement);
		let zone = target?.getAttribute("dropzone");
		if (target) {
			this.dragEnd.emit({ zone: zone!, info: this.dragInfo, target: target as HTMLElement });
		} else {
			this.dragCancel.emit({ info: this.dragInfo });
		}
		if (this.draggableElem && document.body.contains(this.draggableElem)) {
			document.body.removeChild(this.draggableElem as any);
		}
		document.body.removeEventListener("mouseup", this.dragFinish as any);
		document.body.removeEventListener("mousemove", this.onMove as any);
		document.body.removeEventListener("touchend", this.dragFinish as any);
		document.body.removeEventListener("touchmove", this.onMove as any);
		this.resetDragState();
	};

	private readonly onMove: Function = (evt: MouseEvent | TouchEvent) => {
		evt.stopPropagation();
		if (evt instanceof MouseEvent) {
			this.draggableElem!.style.top = evt.y + "px";
			this.draggableElem!.style.left = evt.x + "px";
		}
		if (evt instanceof TouchEvent) {
			this.draggableElem!.style.top = evt.touches[0].clientY + "px";
			this.draggableElem!.style.left = evt.touches[0].clientX + "px";
		}
		evt.preventDefault();
	};

	private readonly getDropzoneElement: (elem: HTMLElement) => HTMLElement | null = (elem) => {
		if (elem.getAttribute("dropzone")) return elem;
		else {
			if (elem.parentElement) return this.getDropzoneElement(elem.parentElement);
			else return null;
		}
	};

	private dragBegin(evt: MouseEvent | TouchEvent): void {
		if (this.disabled) return;
		const isSkillsOverflow = (target: HTMLElement | null): boolean => {
			if (!target) return false;
			if (target.classList.contains("skills-overflow")) return true;
			return isSkillsOverflow(target.parentElement);
		};
		if (isSkillsOverflow(evt.target as HTMLElement)) {
			evt.preventDefault();
			evt.stopPropagation();
			return;
		}
		this.isDragging = false;
		this.setupDraggableElement(this.initialX, this.initialY);
		this.isDragging = true;
		this.dragStart.emit(this.dragInfo);
	}

	private resetDragState(): void {
		this.isDragging = false;
		this.draggableElem = undefined;
		this.elem.style.cursor = "grab";
	}
}
