import { Component, Input } from "@angular/core";
import { ResourceService } from "src/app/settings/resource/resource.service";
import { Hardware } from "src/app/dto/resources/hardware";
import { Resource } from "src/app/dto/resources/resource";
import { Station } from "src/app/dto/resources/station";
import { LocaleMap } from "src/app/global/constants/text/text-interface";
import { TextProvider } from "src/app/global/constants/text/text-provider";
import { Camera } from "src/app/dto/resources/camera";

@Component({
	selector: "app-assign-hardware-modal",
	templateUrl: "assign-hardware.component.html",
	styleUrls: ["assign-hardware.css"]
})
export class AssignHardwareModalComponent {
	@Input() resource: Resource = new Resource(-5, -1, 0, 0, -1, -1, "");
	@Input() closeCb: Function = () => {};
	@Input() assignCb: Function = () => {};

	text: () => LocaleMap;

	nameQuery: string = "";
	statusQuery: any = {};
	stationQuery: Station | undefined;

	hardware: Array<Camera> = [];
	stations: Array<Station> = [];
	statuses: Array<Status> = [];

	resourceService: ResourceService;

	constructor(res: ResourceService, textProv: TextProvider) {
		this.text = textProv.getStringMap;
		this.resourceService = res;
		this.hardware = this.getCameras();
		this.stations = [...res.Stations];
		this.stations.splice(0, 0, new Station(-2, this.text().ALL));
		this.statuses = [
			{
				available: undefined,
				text: this.text().ALL
			},
			{
				available: true,
				text: this.text().AVAILABLE
			},
			{
				available: false,
				text: this.text().UNAVAILABLE
			}
		];
		this.resourceService.cameraUpdate$.subscribe(() => {
			setTimeout(() => (this.hardware = this.getCameras()));
		});
	}

	public readonly isHardwareSelectedByCurrentResource = (hw: Hardware): boolean => {
		return this.resource.id_camera === hw.id;
	};

	public readonly isHardwareSelectedByAnyResource = (hw: Hardware): Resource | undefined => {
		return this.resourceService.Resources.find((e) => e.id_camera === hw.id);
	};

	public readonly stationDropdownGetMainText = (): string => {
		return this.stationQuery ? this.stationQuery.name : "";
	};
	public readonly statusDropdownGetMainText = (): string => {
		return this.statusQuery ? this.statusQuery.text : "";
	};

	public readonly stationDropdownGetOptionText = (obj: Station): string => {
		return obj.name;
	};
	public readonly statusDropdownGetOptionText = (obj: Status): string => {
		return obj.text;
	};

	public readonly stationDropdownCompareSelect = (sel: Station, obj: Station): boolean => {
		return sel && sel.id === obj.id;
	};
	public readonly statusDropdownCompareSelect = (sel: Status, obj: Status): boolean => {
		return sel.available !== undefined && sel.available === obj.available;
	};

	public readonly stationDropdownChangeCallback = (obj: Station): void => {
		this.stationQuery = obj;
		this.applyFilters();
	};
	public readonly statusDropdownChangeCallback = (obj: Status): void => {
		this.statusQuery = obj;
		this.applyFilters();
	};

	public readonly applyFilters = (): Hardware[] => {
		return (this.hardware = this.resourceService.Cameras.filter((e: Hardware) => {
			if (!this.nameFilterFunc(e)) return false;
			if (this.stationQuery && this.stationQuery.id !== -2 && this.stationQuery.name !== e.station) return false;
			if (this.statusQuery && this.statusQuery.available !== undefined) {
				if (this.statusQuery.available && this.resourceService.Resources.findIndex((c) => c.id_camera === e.id) > -1) return false;
				if (!this.statusQuery.available && this.resourceService.Resources.findIndex((c) => c.id_camera === e.id) === -1) return false;
			}
			return true;
		}).reverse());
	};

	public readonly nameFilterFunc = (data: Hardware): boolean => {
		if (this.nameQuery && this.nameQuery.length > 0) {
			for (var i = 0; i < data.name.length; i++) {
				if (this.nameQuery.toUpperCase() === data.name.toUpperCase().substring(i, i + this.nameQuery.length)) return true;
			}
			return false;
		}
		return true;
	};

	private readonly getCameras = (): Camera[] => {
		return this.resourceService.Cameras.reverse();
	};
}

type Status = {
	available: boolean | undefined;
	text: string;
};
