<div class="hardware-section">
	<span class="resource-card-label">{{ text().CAMERA }}</span>
	<div class="hardware-field">
		<button
			[attr.data-cy]="'add-camera-button-' + personnel.name"
			class="main-blue-gradient-button resource-add-button font14"
			(click)="assignHardwareCb.emit(); $event.stopPropagation()"
		>
			+ {{ text().ADD }}
		</button>
		<li
		[attr.data-cy]="'camera-icon-' + getResourceCamera()!.name"
			class="hardware-item"
			*ngIf="getResourceCamera()"
			title="{{ getResourceCamera()!.name }}"
		>
			<img src="resources/img/icons-hw/cam.png" *ngIf="this.personnel.id_camera !== -1" />
		</li>
	</div>
</div>
