import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from "@angular/core";
import { first } from "rxjs/operators";

import * as html2canvas from "html2canvas";

import { LoginService } from "src/app/login/login.service";
import { FormsService } from "src/app/incident/incident-tools/forms/forms.service";
import { DocumentService } from "src/app/document/document.service";

import { JESIP_FORMS } from "src/app/global/constants/enums/jesip_forms";
import { ChecklistHistory } from "src/app/dto/jesip/checklist-history";
import { TextProvider } from "src/app/global/constants/text/text-provider";
import { LocaleMap } from "src/app/global/constants/text/text-interface";
import { CheckList } from "src/app/dto/jesip/checklist";
import { Member } from "src/app/dto/jesip/member";
import { User } from "src/app/dto/user/user";
import { PAGE_SIZES } from "src/app/dto/Documents/page-sizes";
import { IncidentService } from "src/app/incident/incident.service";
import { Incident } from "src/app/dto/items/incident";

@Component({
	selector: "app-checklist-current-template",
	templateUrl: "checklist-current.component.html",
	styleUrls: ["checklist-current.css", "../../jesip.css", "../../../../document.css"]
})
export class CurrentChecklistTemplateComponent implements AfterViewInit {
	@Input() generationTime!: number;
	@Input() event!: Incident;
	@Input() checklist!: CheckList;
	@Input() history!: ChecklistHistory;

	@Output() docReady = new EventEmitter<HTMLCanvasElement[]>();

	@ViewChildren("pageElements") pageElementRef!: QueryList<ElementRef>;

	public numberOfPages: number = 1;
	public pages = new Array<Array<ChecklistItem>>();

	// Comes from JESIP or other Services
	public commander!: Member | undefined;
	public incidentType!: string | undefined;
	public incidentLocation!: string | undefined;
	public filledBy!: User | undefined;

	public readonly pageSize = PAGE_SIZES.STANDARD;
	public readonly text: () => LocaleMap;

	private readonly jesip: FormsService;
	private readonly ems: IncidentService;
	private readonly ls: LoginService;
	private readonly docService: DocumentService;
	private abortDownload = false;

	constructor(ls: LoginService, doc: DocumentService, text: TextProvider, jesip: FormsService, ems: IncidentService) {
		this.text = text.getStringMap;
		this.jesip = jesip;
		this.ems = ems;
		this.ls = ls;
		this.docService = doc;
		this.docService.onDownloadCancel$.subscribe(() => this.abortDownload = true);
	}

	async ngAfterViewInit(): Promise<void> {
		try {
			// Filled by field
			this.filledBy = this.ls.user;

			// Incident fields from Jesip
			const { 0: currentMethane } = this.jesip.methaneHistory;
			this.incidentLocation = currentMethane?.E;
			this.incidentType = currentMethane?.T;

			// Commander field
			this.commander = await this.getCommander();

			// Build HTML and generate PDF doc
			this.pageElementRef.changes.pipe(first()).subscribe((pages) => this.generateDocument(pages));

			this.buildTemplate();
		} catch (error) {
			console.error(error);
			this.docService.downloadError$.next();
		}
	}

	private async generateDocument(pages: QueryList<ElementRef>): Promise<void> {
		const output: HTMLCanvasElement[] = [];

		for (let i = 0; i < pages.length; i++) {
			const page = pages.get(i)!;
			const { nativeElement: element } = page;
			this.docService.setLoadingMessage(this.text().GENERATING_DOCUMENTATION_PAGE(i, pages.length));
			const canvas = await html2canvas.default(element);
			if(this.abortDownload) return;
			output.push(canvas);
		}

		this.docReady.emit(output);
	}

	private buildTemplate() {
		const itemsArray: string[] = [
			this.text().JESIPCHECKLISTITEMS.ITEM1,
			this.text().JESIPCHECKLISTITEMS.ITEM2,
			this.text().JESIPCHECKLISTITEMS.ITEM3,
			this.text().JESIPCHECKLISTITEMS.ITEM4,
			this.text().JESIPCHECKLISTITEMS.ITEM5,
			this.text().JESIPCHECKLISTITEMS.ITEM6,
			this.text().JESIPCHECKLISTITEMS.ITEM7,
			this.text().JESIPCHECKLISTITEMS.ITEM8,
			this.text().JESIPCHECKLISTITEMS.ITEM9
		];

		let page = new Array<ChecklistItem>();
		let height = this.calculateHeaderHeight();

		const history = this.history;
		console.log("history in building template", history);

		for (let i = 0; i < itemsArray.length; i++) {
			let item = new ChecklistItem(itemsArray[i]);

			if (height + this.calculateTimestampHeight() < this.pageSize.height - 150) {
				page.push(item);
				height += this.calculateTimestampHeight();

				const currentHistory: Array<[string, string]> = (history as any)["item" + (i + 1)];

				if (currentHistory) {
					for (let j = 0; j < currentHistory.length; j++) {
						let newTime = new CheckTimestamp(currentHistory[j][1]);
						newTime.checked = currentHistory[j][0] === "Completed";
						if (height + this.calculateTimestampHeight() < this.pageSize.height - 150) {
							item.timestamps.push(newTime);
							height += this.calculateTimestampHeight();
						} else {
							this.pages.push(page);
							height = this.calculateHeaderHeight() + this.calculateTimestampHeight();
							page = new Array<ChecklistItem>();
							item = new ChecklistItem(itemsArray[i]);
							item.timestamps.push(newTime);
							page.push(item);
						}
					}
				}
			} else {
				this.pages.push(page);
				height = this.calculateHeaderHeight();
				page = new Array<ChecklistItem>();
			}
		}

		if (!this.pages.find((e) => e === page)) {
			this.pages.push(page);
		}
	}

	// wow 🤯
	private readonly calculateTimestampHeight: () => number = () => {
		return 26;
	};

	private readonly calculateHeaderHeight: () => number = () => {
		return 132;
	};

	private async getCommander(): Promise<Member> {
		const id_incident = this.ems.getCurrentIncident()?.id ? this.ems.getCurrentIncident()!.id : this.event.id;
		const response = await this.jesip.loadFormTeam(id_incident, JESIP_FORMS.METHANE);

		const { 0: commander } = response;

		return Promise.resolve(commander);
	}
}

class ChecklistItem {
	text: string;
	timestamps = new Array<CheckTimestamp>();

	constructor(text: string) {
		this.text = text;
	}
}

class CheckTimestamp {
	checked: boolean;
	time: string;

	constructor(time: string) {
		this.time = time;
		this.checked = false;
	}
}
