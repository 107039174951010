import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
	selector: "app-login-sso",
	templateUrl: "./login-sso.component.html",
	styleUrls: ["./login-sso.component.css"]
})
export class LoginSsoComponent {
	@Input() authenticated!: boolean;
	@Output() login = new EventEmitter<void>();

	public readonly loginSso = (): void => {
		return this.login.emit();
	};
}
