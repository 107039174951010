import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Area } from "src/app/dto/items/area";
import { CircleArea } from "src/app/dto/items/circle-area";
import { MapItemsService } from "src/app/incident/map/map-items.service";
import { LocaleMap } from "src/app/global/constants/text/text-interface";
import { TextProvider } from "src/app/global/constants/text/text-provider";
import { SHAPE_TYPE } from "src/app/dto/items/shape-type";

@Component({
	selector: "app-area-edit-modal",
	templateUrl: "area-edit.component.html",
	styleUrls: ["../map-item-edit.css", "area-edit.css"]
})
export class AreaEditModalComponent implements OnInit {
	@Input() item!: Area | CircleArea;
	@Output() cancelCb = new EventEmitter<void>();

	public readonly text: () => LocaleMap;
	public header!: string;
	public itemAsCircle: CircleArea | undefined;

	private readonly mis: MapItemsService;

	constructor(textProv: TextProvider, mis: MapItemsService) {
		this.text = textProv.getStringMap;
		this.mis = mis;
	}

	ngOnInit() {
		if (!this.item) throw 'MapItemEditModal initialized without "item" input';
		this.header = this.item.name;
		if (this.item.shapeType === SHAPE_TYPE.CIRCLE) this.item = this.itemAsCircle = Object.create(this.item as CircleArea);
	}

	public readonly refreshName: (value: string) => void = (value) => {
		this.item.name = value;
	};

	public readonly refreshComments: (value: string) => void = (value) => {
		this.item.description = value;
	};

	public refreshRadius(value: string): void {
		if (!(this.item instanceof CircleArea)) throw "AreaEditComponent tried to refresh radius of non CircleArea object";
		(this.item as CircleArea).radius = +value;
	}

	public readonly save: Function = () => {
		this.cancelCb.emit();
		this.itemAsCircle?.radius === 0 ? (this.itemAsCircle.radius = 1) : null;
		return this.mis.saveArea(this.item);
	};

	public readonly cancel: Function = () => {
		this.item = this.mis.Areas.find((area) => area.id === this.item.id)!;
		this.cancelCb.emit();
	};
}
