import { ControlMeasureRelation } from "./control-measure-relation";
import { DTO } from "../net/dto";
import { RiskInformation } from "./risk-information";

export class RiskInformationRelation implements DTO {
	id: number;
	id_ara: number;
	id_risk_information: number;
	controlMeasures: Array<ControlMeasureRelation>;
	selected: boolean | undefined;
	preselected: boolean | undefined;
	severity : number = 0;
	likelihood : number = 0;

	__object: RiskInformation | undefined;

	timestamp = Date.now();
	constructor(id: number, id_ara: number, id_ri: number, cms?: Array<ControlMeasureRelation>, selected?: boolean, preselected?: boolean) {
		this.id = id;
		this.id_ara = id_ara;
		this.id_risk_information = id_ri;
		this.controlMeasures = cms ? cms : [];
		this.selected = selected;
		this.preselected = selected;
	}

	public static readonly fromJson: (json: string) => RiskInformationRelation = (json: string) => {
		let jc: any = JSON.parse(json);
		let ans = new RiskInformationRelation(jc.id, jc.id_ara, jc.id_risk_information, jc.control_measures);
		ans.severity = jc.severity ?? 0,
		ans.likelihood = jc.likelihood ?? 0
		return ans;
	};

	public readonly getJson: () => string = () => {
		let object = {
			id : this.id,
			id_ara : this.id_ara,
			id_risk_information : this.id_risk_information,
			likelihood : this.likelihood,
			severity : this.severity
		}
		return JSON.stringify(object);
	};
}

export interface SLRating {
	likelihood : number,
	severity : number
}