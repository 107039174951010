import { Component, EventEmitter, Input, Output } from "@angular/core";
import { CSCommander } from "src/app/dto/command-structure/cs-commander";
import { CommandStructureService } from "src/app/incident/incident-tools/command-structure/command-structure.service";
import { LocaleMap } from "src/app/global/constants/text/text-interface";
import { TextProvider } from "src/app/global/constants/text/text-provider";
import { CSNode } from "../CSNode";

@Component({
	selector: "app-cs-control-room",
	templateUrl: "control-room.component.html",
	styleUrls: ["../../chart.css", "control-room.css"]
})
export class CSControlRoomNodeComponent implements CSNode {
	@Input() object!: CSCommander;
	@Input() width!: number;
	@Input() height!: number;
	@Input() disable: boolean = false;

	@Output() assignCommander = new EventEmitter<void>();
	@Output() goToCommander = new EventEmitter<void>();

	public readonly text: () => LocaleMap;
	public editable: boolean = false;
	public padding: string = "8px";

	private showMenu: boolean = false;
	constructor(textProv: TextProvider, private readonly cs: CommandStructureService) {
		this.text = textProv.getStringMap;
	}

	public readonly onButtonClick: Function = () => {
		this.goToCommander.emit();
	};

	public readonly isMenuOpen: Function = () => {
		return this.showMenu;
	};

	public readonly openMenu: Function = (evt: MouseEvent) => {
		this.showMenu = !this.showMenu;
	};

	public readonly toggleEditable: Function = () => {
		this.editable = !this.editable;
	};

	public readonly setNewControlRoom: Function = (newData: string) => {
		this.object.control_room = newData;
	};

	public readonly saveChanges: Function = () => {
		if (this.editable) {
			this.cs.saveCommander(this.object);
		}
		this.editable = this.showMenu = false;
	};

	public isIncidentClosed(): boolean {
		return this.cs.isIncidentClosed();
	}
}
