<div class="container">
	<div class="list-row header">
		<div *ngFor="let column of columns" class="header-item" [ngStyle]="getColumnWidth(column)">
			<span>{{ column.columnName }}</span>
			<span
				*ngIf="column.filterType === 'onlySort'"
				class="sort-arrow {{ getFilterStatus(column) }}"
				(click)="setSortFilter(column); $event.stopPropagation()"
			></span>
			<span
				#funnel
				[ngClass]="dataArr.length ? 'funnel default' : 'funnel'"
				*ngIf="column.filterType === 'sortAndFilter'"
				(click)="showSortAndFilter = !showSortAndFilter; $event.stopPropagation()"
				[ngClass]="
					showSortAndFilter || isTypeFilterApplied(column)
						? 'funnel open'
						: 'funnel default'
				"
			></span>
		</div>
		<div class="header-item filler" *ngIf="hasCallbacks(dataArr[0])"></div>
	</div>

	<div class="body min-height-400">
		<div data-cy="list-row" class="list-row" *ngFor="let item of dataArr; let i = index">
			<!-- | filter: filterFunc -->
			<div
				data-add-action-name="blue-list-items"
				class="row-item {{ item?.clickCallback ? 'clickable' : '' }}"
				(click)="item?.clickCallback(); $event.stopPropagation()"
				appSetStyle
				[ngStyle]="getColumnWidth(column)"
				*ngFor="let column of columns"
			>
				<div
					class="color-box"
					*ngIf="column.hasColor"
					[ngStyle]="getColumnColor(item, column)"
				></div>
				{{ item[column.attrName] }}
			</div>
			<div class="row-item filler" *ngIf="hasCallbacks(item)">
				<div class="icon-container">
					<div
						data-add-action-name="download-button"
						class="download-icon"
						(click)="item.downloadCallback()"
					></div>
				</div>
				<!-- KEBAB MENU -->
				<div class="icon-container" *ngIf="hasKebabMenuIcon">
					<div
						data-add-action-name="kebab-button"
						class="kebab-icon {{ kebabMenu && clickedIndex === i ? 'active' : '' }}"
						*ngIf="item.kebabCallback"
						(click)="item.kebabCallback(); clickedIndex = i"
					></div>
				</div>

				<!-- END KEBAB MENU -->
			</div>
			<div *ngIf="kebabMenu && clickedIndex === i" class="kebab-container">
				<div
					data-add-action-name="reopen-button"
					class="reopen-button"
					*ngIf="item.reopenCallback"
					(click)="item.reopenCallback()"
					(mouseover)="reopenIconUrl = 'resources/img/reopen-icon-hover.svg'"
					(mouseleave)="reopenIconUrl = 'resources/img/reopen-icon.svg'"
				>
					<div class="reopen-icon">
						<img [src]="reopenIconUrl" alt="Reopen" />
					</div>
					<p>{{ text().REOPEN + " " + text().INCIDENT }}</p>
				</div>
				<div
					data-add-action-name="delete-button"
					class="delete-button"
					(mouseover)="deleteIconUrl = 'resources/img/remove-24-hover.svg'"
					(mouseleave)="deleteIconUrl = 'resources/img/remove-24-default.svg'"
					*ngIf="item.deleteCallback"
					(click)="item.deleteCallback()"
				>
					<div class="delete-icon">
						<img [src]="deleteIconUrl" alt="Delete" />
					</div>
					<p>{{ text().DELETE + " " + text().INCIDENT }}</p>
				</div>
			</div>
		</div>
		<div
			*ngIf="isFiltering && (!dataArr || !dataArr.length)"
			class="display-flex min-height-400"
		>
			<div>{{ text().NO_RESULTS_FOUND }}</div>
		</div>
	</div>
</div>

<app-incident-type-filter
	appDetectClickOut
	(callback)="closeModal()"
	*ngIf="showSortAndFilter"
	appPositionRespectToElement
	[referenceEl]="funnelEl"
	[offsetTop]="10"
	[offsetLeft]="50"
	[filters]="filters"
	(applyFilterEmit)="setTypeFilter($event)"
	(closeModalEmit)="closeModal()"
>
</app-incident-type-filter>
