import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MessagingService } from "src/app/global/messaging/messaging.service";
import { ConfigurationService } from "src/app/settings/types/configuration.service";
import { TextProvider } from "src/app/global/constants/text/text-provider";
import { LocaleMap } from "src/app/global/constants/text/text-interface";

@Component({
	selector: "app-text-filter",
	templateUrl: "text-filter.component.html",
	styleUrls: ["text-filter.css"]
})
export class TextFilterComponent {
	@Input() filters: Array<string> = [];
	@Output() applyFilterEmit = new EventEmitter<any>();
	@Output() doneModalEmit = new EventEmitter<boolean>();

	public readonly text: () => LocaleMap;
	public readonly selection = new Map<string, boolean>();

	private readonly mssg: MessagingService;
	private readonly conf: ConfigurationService;

	constructor(conf: ConfigurationService, mssg: MessagingService, textProv: TextProvider) {
		this.conf = conf;
		this.mssg = mssg;
		this.text = textProv.getStringMap;
	}

	public readonly isOptionFilter = (option: string): boolean => {
		const ans = this.selection.get(option);
		return !!ans;
	};

	public readonly getCheckboxCheck = (option: string): (() => boolean) => {
		return () => {
			return !!this.selection.get(option);
		};
	};

	public readonly generalCheckboxCheck: Function = () => {
		for (let value of this.selection.values()) {
			if (value === true) {
				return true;
			}
		}
		return false;
	};

	public readonly clearAll = (): void => {
		for (let key of this.selection.keys()) {
			this.selection.set(key, false);
		}
		this.applyFilterEmit.emit(this.selection);
	};

	public readonly toggleFilters = (option: string): void => {
		const currentOptionFilter = this.isOptionFilter(option);
		this.selection.set(option, !currentOptionFilter);
		this.applyFilterEmit.emit(this.selection);
	};

	// "Done" in this modal works like a "Close"
	public doneModal = (): void => {
		return this.doneModalEmit.emit(true);
	};
}
