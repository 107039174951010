import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FLAT_TYPE } from "src/app/dto/fsg/enums";
import { FsgFlat } from "src/app/dto/fsg/fsg-flat";
import { FsgService } from "src/app/incident/incident-tools/fsg/fsg.service";
import { LocaleMap } from "src/app/global/constants/text/text-interface";
import { TextProvider } from "src/app/global/constants/text/text-provider";

@Component({
	selector: "app-select-type-popup",
	templateUrl: "fsg-select-type.component.html",
	styleUrls: ["../fsg-editor-popup.css", "fsg-select-type.css"]
})
export class FsgSelectTypePopupComponent implements OnInit {
	@Input() flat!: FsgFlat;
	@Output() cancel = new EventEmitter<void>();

	public readonly text: () => LocaleMap;
	public readonly defaults: Array<string>;
	public openNewSoiInput = false;
	public newSoiValue = "";

	private readonly fsg: FsgService;

	constructor(tp: TextProvider, fsgS: FsgService) {
		this.text = tp.getStringMap;
		this.fsg = fsgS;
		this.defaults = Object.values(FLAT_TYPE);
	}

	ngOnInit(): void {
		if (!this.flat) throw "FsgSelectType component initialized without a valid flat object";
	}

	public readonly getTypeIcon: (type: string) => string = (type) => {
		return this.fsg.getFlatTypeIcon(type);
	};
	public readonly isTypeSelected: (val: string) => boolean = (val) => {
		return val === this.flat.flat_type;
	};
	public readonly getTypeLocalizedString: (type: string) => string = (type) => {
		return this.fsg.getFlatTypeValueAsLocalizedString(type);
	};

	public readonly optionClick: (val: string) => void = (val) => {
		this.flat.flat_type = val;
	};
}
