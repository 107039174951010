import { Component, Input } from "@angular/core";
import { Resource } from "src/app/dto/resources/resource";

@Component({
	selector: "app-resource-label",
	templateUrl: "resource-label.component.html",
	styleUrls: ["resource-label.css", "../map-label.css"]
})
export class ResourceLabelComponent {
	@Input() resource!: Resource;

	constructor() {}

	public getStateColor(): string {
		return this.resource.__state ? this.resource.__state.color : "black";
	}
}
