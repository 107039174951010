import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { ResourceService } from "src/app/settings/resource/resource.service";
import { ConfigurationService } from "src/app/settings/types/configuration.service";
import { ResourceType } from "src/app/dto/items/types/resource-type";
import { Resource } from "src/app/dto/resources/resource";
import { ResourceSkill } from "src/app/dto/resources/skill";
import { Station } from "src/app/dto/resources/station";
import { NavTabItem } from "src/app/dto/ui/tab";
import { LocaleMap } from "src/app/global/constants/text/text-interface";
import { TextProvider } from "src/app/global/constants/text/text-provider";
import { MESSAGE_TYPE } from "src/app/global/messaging/messages";
import { MessagingService } from "src/app/global/messaging/messaging.service";
import { ListItem } from "src/app/dto/resources/list-items";

@Component({
	selector: "app-assign-resource-modal",
	templateUrl: "assign-resource.component.html",
	styleUrls: ["assign-resource.css"]
})
export class AssignResourceModalComponent implements OnInit {
	@Output() closeCb = new EventEmitter();
	@Output() skipCallback = new EventEmitter();
	@Output() assignCallback = new EventEmitter<Array<Resource>>();

	public readonly text: () => LocaleMap;
	public stations: Array<Station> = [];
	public skills: Array<ResourceSkill> = [];
	public types: Array<ResourceType> = [];
	public navTabs: Array<NavTabItem> = [];
	public filteredResources: ListItem[] = [];
	public generalCheck: boolean = false;
	public skillQuery: ResourceSkill | undefined;
	public typeQuery: ResourceType | undefined;
	public stationQuery: Station | undefined;

	private readonly res: ResourceService;
	private readonly mssg: MessagingService;
	private readonly conf: ConfigurationService;
	private fullResources: Array<ListItem> = [];
	private resources: Array<ListItem> = [];
	private tabIndex: number = 0;
	private nameQuery: string = "";

	constructor(textProv: TextProvider, res: ResourceService, mssg: MessagingService, conf: ConfigurationService) {
		this.text = textProv.getStringMap;
		this.res = res;
		this.mssg = mssg;
		this.conf = conf;
	}

	ngOnInit() {
		this.getFullResources();
		this.getPersonnel();
		this.filterResources();
		this.setLists();
		this.setNavTabs();
		this.mssg.registerListener(MESSAGE_TYPE.UPDATE_AGENT_SERVICE, () => {
			this.getFullResources();
			this.tabIndex ? this.getAppliances() : this.getPersonnel();
			this.filterResources();
			this.setLists();
		});
	}

	public readonly getActiveTab = (): number => {
		return this.tabIndex;
	};

	public readonly isEveryoneChecked = (): boolean => {
		return this.generalCheck;
	};

	public readonly setGeneralCheck = (value: boolean): void => {
		this.generalCheck = value;
		this.resources.forEach((res) => res.checkResource(value));
	};

	public readonly searchUpdate = (newQuery: string): void => {
		this.nameQuery = newQuery;
		this.filterResources();
	};

	public readonly skillDropdownGetMainText = (): string => {
		return this.skillQuery ? this.skillQuery.name : "";
	};

	public readonly skillDropdownGetOptionText = (obj: ResourceSkill): string => {
		return obj.name;
	};

	public readonly skillDropdownCompareSelect = (sel: ResourceSkill, obj: ResourceSkill): boolean => {
		return sel && sel.id === obj.id;
	};

	public readonly skillDropdownChangeCallback = (obj: ResourceSkill): void => {
		this.skillQuery = obj.id !== -2 ? obj : undefined;
		this.filterResources();
	};

	public readonly typeDropdownGetMainText = (): string => {
		return this.typeQuery ? this.typeQuery.name : "";
	};

	public readonly typeDropdownGetOptionText = (obj: ResourceType): string => {
		return obj.name;
	};

	public readonly typeDropdownCompareSelect = (sel: ResourceType, obj: ResourceType): boolean => {
		return sel && sel.id === obj.id;
	};

	public readonly typeDropdownChangeCallback = (obj: ResourceType): void => {
		this.typeQuery = obj.id !== -2 ? obj : undefined;
		this.filterResources();
	};

	public readonly stationDropdownGetMainText = (): string => {
		return this.stationQuery ? this.stationQuery.name : "";
	};

	public readonly stationDropdownGetOptionText = (obj: Station): string => {
		return obj.name;
	};

	public readonly stationDropdownCompareSelect = (sel: Station, obj: Station): boolean => {
		return sel && sel.id === obj.id;
	};

	public readonly stationDropdownChangeCallback = (obj: Station): void => {
		this.stationQuery = obj.id !== -2 ? obj : undefined;
		this.filterResources();
	};

	public readonly close = (): void => {
		this.closeCb.emit();
	};

	public readonly skip = (): void => {
		this.skipCallback.emit();
	};

	public readonly assign = async (): Promise<void> => {
		const selectedResources = this.fullResources.filter((e) => e.checked).map((e) => this.res.Resources.find((resource) => resource.id === e.agentid));

		for (const resource of selectedResources) {
			if (resource) {
				await this.res.freeAppliance(resource.id);
			}
		}

		this.assignCallback.emit(selectedResources as Array<Resource>);
	};

	private readonly setLists = (): void => {
		this.stations = [...this.res.Stations];
		this.stations.splice(0, 0, new Station(-2, this.text().ALL));

		this.skills = [...this.res.Skills];
		this.skills.splice(0, 0, new ResourceSkill(-2, -1, this.text().ALL));

		this.types = [...this.conf.configuration.resourceTypes];
		this.types.splice(0, 0, new ResourceType(-2, this.text().ALL));
	};

	private getFullResources = (): void => {
		const resourceArr = this.res.Resources.filter((e) => !e.deleted);
		resourceArr.forEach((resource: Resource) => {
			const name = resource.name;
			const skills = resource.skills?.slice(0, 6);
			const role = resource.__typeObj?.name;
			const station = resource.station;
			const item = new ListItem(resource.id, name, role ? role : this.text().UNSPECIFIED, station, skills, !resource.id_incident || resource.id_incident === -1);
			this.fullResources.push(item);
		});
	};

	private readonly getPersonnel = (): ListItem[] => {
		const personnel = this.res.Resources.filter((resource) => !resource.is_appliance && !resource.deleted);
		return (this.resources = this.fullResources.filter((resource) => personnel.some((res) => res.id === resource.agentid)));
	};

	private readonly getAppliances = (): ListItem[] => {
		const appliances = this.res.Resources.filter((resource) => resource.is_appliance && !resource.deleted);
		return (this.resources = this.fullResources.filter((resource) => appliances.some((res) => res.id === resource.agentid)));
	};

	private readonly filterResources = (): void => {
		this.filteredResources = [];
		this.filteredResources = this.resources
			.filter((e: ListItem) => {
				return !this.typeQuery || this.typeQuery.name.toUpperCase() === e.role.toUpperCase();
			})
			.filter((e: ListItem) => {
				return !this.stationQuery || this.stationQuery.name.toUpperCase() === e.station.toUpperCase();
			})
			.filter((e: ListItem) => {
				return !this.skillQuery || e.skills?.find((s) => s.id === this.skillQuery!.id);
			})
			.filter((e: ListItem) => {
				if (this.nameQuery && this.nameQuery.length > 0) {
					for (var i = 0; i < e.name.length; i++) {
						if (this.nameQuery.toUpperCase() === e.name.toUpperCase().substring(i, i + this.nameQuery.length)) return true;
					}
					return false;
				}
				return true;
			});
	};

	private setNavTabs = (): void => {
		this.navTabs = [
			new NavTabItem(this.text().PERSONNEL, 0, () => {
				this.tabIndex = 0;
				this.getPersonnel();
				this.filterResources();
			}),
			new NavTabItem(this.text().APPLIANCES, 1, () => {
				this.tabIndex = 1;
				this.getAppliances();
				this.filterResources();
			})
		];
	};
}
