import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { LocaleMap } from "src/app/global/constants/text/text-interface";
import { TextProvider } from "src/app/global/constants/text/text-provider";
import { JesipCard, JESIP_CARD_BODY_TYPE, JESIP_CARD_INITIAL_COLOR } from "../jesip-card.interface";

@Component({
	selector: "app-set-jesip-card",
	templateUrl: "set-jesip-card.component.html",
	styleUrls: ["../jesip-card.css"]
})
export class SetJesipCardComponent implements JesipCard {
	@Input() initial: string | undefined;
	@Input() title: string = "";
	@Input() timeText: string | (() => string) | undefined;
	@Input() authorText: string | (() => string) | undefined;
	@Input() buttonText: string | (() => string) | undefined;
	@Input() color: string | (() => string) = JESIP_CARD_INITIAL_COLOR.BLUE;
	@Input() backgroundSrc: string = "";
	@Input() updateAvailable: boolean = false;

	@Output() buttonClick = new EventEmitter<Event>();

	public readonly type = JESIP_CARD_BODY_TYPE.SET;

	public readonly text: () => LocaleMap;

	constructor(textProv: TextProvider) {
		this.text = textProv.getStringMap;
	}

	public readonly getTime: () => string = () => {
		if (!this.timeText) return "";
		if (typeof this.timeText === "string") return this.timeText;
		return this.timeText();
	};

	public readonly getAuthor: () => string = () => {
		if (!this.authorText) return "";
		if (typeof this.authorText === "string") return this.authorText;
		return this.authorText();
	};

	public readonly getButton: () => string = () => {
		if (!this.buttonText) return "";
		if (typeof this.buttonText === "string") return this.buttonText;
		return this.buttonText();
	};

	public readonly getColour: () => string = () => {
		if (typeof this.color === "string") return this.color;
		return this.color();
	};
}
