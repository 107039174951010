<div class="container">
	<div class="map-label track-point-label">
		<div class="date">{{ point.timestamp | date: "HH:mm:ss dd/MM/YYYY" }}</div>
		<div class="coords">
			{{ point.latitude.toFixed(5) }}º <br />
			{{ point.longitude.toFixed(5) }}º
		</div>
	</div>
	<div class="line"></div>
	<div class="dot"></div>
</div>
