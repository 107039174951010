import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { LocaleMap } from "src/app/global/constants/text/text-interface";
import { TextProvider } from "src/app/global/constants/text/text-provider";

@Component({
	selector: "app-loading-dialog",
	templateUrl: "loading-dialog.component.html",
	styleUrls: ["loading-dialog.css"]
})
export class LoadingDialogComponent implements OnInit {
	@Input() headerText: string = "";
	@Input() bodyText: string = "";
	@Input() infoText: string = "";
	@Input() downloadingVideo: boolean = false;

	@Output() closeCallback = new EventEmitter<void>();

	public readonly text: () => LocaleMap;

	constructor(t: TextProvider) {
		this.text = t.getStringMap;
		this.headerText = "";
	}

	ngOnInit(): void {
		if (!this.headerText) this.headerText = this.text().GENERATING_FILES;
	}

	public refreshMessage(mssg: string): void {
		this.bodyText = mssg;
	}
}
