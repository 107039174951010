export enum DOCUMENT_TYPE {
	DECISION_LOG = "Decision Log",
	ARA = "ARA",
	FSG_BLANK = "Blank EF",
	FSG_HISTORY = "EF History",
	METHANE_BLANK = "Blank METHANE",
	METHANE_CURRENT = "METHANE",
	METHANE_HISTORY = "METHANE History",
	IIMARCH_BLANK = "Blank IIMARCH",
	IIMARCH_CURRENT = "IIMARCH",
	IIMARCH_HISTORY = "IIMARCH History",
	JDM_BLANK = "Blank JDM",
	JDM_CURRENT = "JDM",
	JDM_HISTORY = "JDM History",
	DEBRIEF_BLANK = "Blank Debrief",
	DEBRIEF_LAST = "Debrief",
	CHECKLIST_BLANK = "Blank Checklist",
	CHECKLIST_LAST = "Checklist",
	COMMAND_STRUCTURE = "Command Structure",
	STREAMINGS = "Streamings"
}
