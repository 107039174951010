import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { ConfigurationService } from "src/app/settings/types/configuration.service";
import { MESSAGE_TYPE } from "../../messaging/messages";
import { MessagingService } from "../../messaging/messaging.service";
import { ConstantsService } from "../constants.service";
import { LANGUAGE } from "../enums/languages";
import { TextEN } from "./locales/en";
import { TextES } from "./locales/es";
import { LocaleMap } from "./text-interface";

@Injectable({
	providedIn: "root"
})
export class TextProvider {
	public readonly strings = new Map<LANGUAGE, LocaleMap>();
	public readonly languageChange = new Subject<{ previous: LANGUAGE; next: LANGUAGE }>();

	private current_language: LANGUAGE;
	private text: LocaleMap;

	constructor(cnst: ConstantsService, conf: ConfigurationService, mssg: MessagingService) {
		this.initLocaleMaps();

		this.current_language = cnst.DEFAULT_LANGUAGE;
		if (conf.configuration && conf.configuration.language.trim()) this.current_language = conf.configuration.language;
		this.text = this.strings.get(this.current_language)!;

		mssg.registerListener(MESSAGE_TYPE.LOAD_CONFIGURATION, () => {
			if (conf.configuration && conf.configuration.language.trim()) this.current_language = conf.configuration.language;
			this.text = this.strings.get(this.current_language)!;
		});
	}

	public readonly getCurrentLanguage: () => LANGUAGE = () => {
		return this.current_language;
	};
	public readonly setCurrentLanguage: (lang: LANGUAGE) => void = (lang) => {
		this.languageChange.next({ previous: this.current_language, next: lang });
		this.current_language = lang;
	};

	public readonly getStringMap: () => LocaleMap = () => {
		return this.strings.get(this.current_language)!;
	};

	public readonly getSupportedLanguages: () => Array<LANGUAGE> = () => {
		return [LANGUAGE.ENGLISH, LANGUAGE.SPANISH];
	};

	public getLangLocalizedName(lang: LANGUAGE): string {
		switch (lang) {
			case LANGUAGE.ENGLISH:
				return this.getStringMap().ENGLISH;
			case LANGUAGE.SPANISH:
				return this.getStringMap().SPANISH;
		}
	}

	public getLocalizedLoadingPageText(index: number, total: number): string {
		switch (this.current_language) {
			default:
				return "Page " + (index + 1) + " of " + total;
			case LANGUAGE.SPANISH:
				return "Página " + (index + 1) + " de " + total;
		}
	}

	private readonly initLocaleMaps: Function = () => {
		this.strings.set(LANGUAGE.ENGLISH, new TextEN());
		this.strings.set(LANGUAGE.SPANISH, new TextES());
	};
}
