<div class="chips-container" *ngIf="chips.length">
	<div
		*ngFor="let chip of chips"
		[attr.data-cy]="'chip-' + chip"
		class="chips"
		[class.selected-chips]="getSelectedChips(chip)"
		(click)="clickEvent.emit(chip)"
	>
		{{ chip }}
	</div>
</div>
