<div *ngIf="!this.playing" class="player-mask">
	<div data-cy="play-button-main" class="play-button-main" (click)="startPlaying()"></div>
</div>
<div class="container">
	<div class="button-container">
		<div class="button" (click)="moveBackwards()">
			<img src="resources/img/playback-icon.png" />
		</div>
		<div data-add-action-name="play-button" class="button" (click)="playPause()">
			<img
				*ngIf="isPaused() && !isOver() && this.playing"
				class="pause-img"
				src="resources/img/pause-icon.png"
			/>
			<img
				*ngIf="(!isOver() && !isPaused()) || !this.playing"
				src="resources/img/play-icon.png"
			/>
			<img *ngIf="isOver()" class="replay" src="resources/img/play-button-replay.svg" />
		</div>
		<div class="button" (click)="moveForward()">
			<img src="resources/img/playforward-icon.png" />
		</div>
		<div class="button speed" (click)="increaseSpeed()">x{{ getCurrentSpeed() }}</div>
	</div>
	<div class="bar-container">
		<div class="label first">{{ getFirstTimestamp() | date : "dd/MM/yyyy, HH:mm:ss" }}</div>
		<div class="label last">{{ getLastTimestamp() | date : "dd/MM/yyyy, HH:mm:ss" }}</div>
		<div data-cy="incident-bar" class="bar" (click)="seek($event)">
			<div
				*ngFor="let change of changes"
				class="change-mark"
				[attr.data-cy]="'change-mark-' + change.datatype"
				[style.left.%]="change.__time_percent"
			></div>
			<div
				data-add-action-name="cursor"
				class="cursor"
				(click)="$event.stopPropagation()"
				(mousedown)="cursorMousedown($event)"
				(touchstart)="cursorTouchstart($event)"
				[style.left.%]="getPlaybackFraction()"
			></div>
		</div>
	</div>
	<div class="footer">
		<div class="bottom-label-container" [style.left.%]="getPlaybackFraction()">
			<div class="label bottom" [ngClass]="pastHalfwayPoint() ? 'left' : 'right'">
				{{
					this.playing
						? (getCurrentTimestamp() | date : "dd/MM/yyyy, HH:mm:ss")
						: (getFirstTimestamp() | date : "dd/MM/yyyy, HH:mm:ss")
				}}
			</div>
		</div>
	</div>
</div>
