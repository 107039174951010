<div
	class="item-card person-card width-270px box-sizing-border-box {{
		hasResourceBeenEdited() ? 'active' : ''
	}}"
>
	<div
		class="item-card-title resources-card-name"
		appScrollToElement
		[scrollIdentifierId]="personnel.id"
	>
		<input
			required
			placeholder="{{ text().NAME }}"
			[(ngModel)]="personnel.name"
			type="text"
			(ngModelChange)="editingPersonnel()"
		/>
	</div>
	<div class="card-half-container">
		<div class="personnel-first-half">
			<div>
				<app-resource-icon [personnel]="personnel"></app-resource-icon>
				<div class="role-station-section">
					<div class="dropdown-container">
						<input
							class="blue-plain-input force-uppercase pointer-none"
							[value]="personnel.__user!.role"
							placeholder="{{ text().ROLE }}"
						/>
					</div>
					<div class="dropdown-container">
						<app-blue-dropdown
							class="dropdown"
							[object]="personnel"
							[options]="stations"
							[getObjectText]="stationDropdownGetMainText"
							[getOptionText]="stationDropdownGetOptionText"
							[compareSelect]="stationDropdownCompareSelect"
							(itemClickCallback)="stationDropdownChangeCallback($event)"
							[placeholderText]="text().STATION"
						></app-blue-dropdown>
					</div>
					<div class="dropdown-container">
						<app-blue-dropdown
							class="dropdown"
							[object]="personnel"
							[options]="incidents"
							[getObjectText]="incidentDropdownGetMainText"
							[getOptionText]="incidentDropdownGetOptionText"
							[compareSelect]="incidentDropdownCompareSelect"
							(itemClickCallback)="incidentDropdownChangeCallback($event)"
							[placeholderText]="text().INCIDENT"
						></app-blue-dropdown>
					</div>
					<div class="gps-toggle-container">
						<span>GPS</span>
						<app-toggle-switch
							class="toggle-switch"
							(clickCb)="toggleGpsSwitch(); $event.stopPropagation()"
							[toggle]="isResourceTracked"
						>
						</app-toggle-switch>
					</div>
				</div>
				<app-add-skill
					[personnel]="personnel"
					(showSkillModal)="openSkillModal()"
				></app-add-skill>
				<app-add-camera
					[personnel]="personnel"
					(assignHardwareCb)="assignHardwareCb.emit(personnel)"
				></app-add-camera>
				<div class="comment-section">
					<span class="resource-card-label">{{ text().DETAILS }}</span>
					<textarea
						class="card-textarea-input"
						[(ngModel)]="personnel.description"
					></textarea>
				</div>
			</div>
			<button
				class="main-blue-gradient-button card-bottom-button font14"
				*ngIf="hasResourceBeenEdited()"
				(click)="save()"
			>
				{{ text().SAVE }}
			</button>
		</div>
	</div>
</div>

<app-assign-skill-modal
	*ngIf="showSkillModal"
	(closeCb)="closeSkillModal()"
	(confirmCb)="setNewSkills($event)"
	[resource]="personnel"
></app-assign-skill-modal>
