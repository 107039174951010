import { Component, ElementRef, EventEmitter, Input, Output, ViewChild, AfterViewInit, OnInit, OnDestroy } from "@angular/core";
import { Subject } from "rxjs";
import { debounceTime, takeUntil, throttleTime } from "rxjs/operators";

@Component({
	selector: "app-simple-text-box",
	templateUrl: "simple-text-box.component.html",
	styleUrls: ["simple-text-box.css"]
})
export class SimpleTextBoxComponent implements OnInit, AfterViewInit, OnDestroy {
	@Input() data: string | number = "";
	@Input() isDisabled: boolean = false;
	@Input() placeholderText: string = "";
	@Input() label: string = "";
	@Input() type: string = "text";
	@Input() rows: number = 1;
	@Input() maxHeight: number = 1000;
	@Input() minHeight: number = 0;
	@Input() focus: boolean = false;
	@Input() labelBackground: string = "#080809";
	@Input() fixedHeight: boolean = false; // removes the directive that sets scroll height automatically according to text
	@Input() padding: number = 8;
	@Input() maxLength: number | undefined;
	@Input() paddingRight?: number;

	@Output() isFocused: EventEmitter<boolean> = new EventEmitter();
	@Output() dataChange = new EventEmitter<string>();

	@ViewChild("inputField", { static: false }) inputField!: ElementRef;

	private inputChanged = new Subject<string>();
	private destroy$ = new Subject<void>();

	constructor() {}

	onTouched: () => void = () => {};

	ngOnInit(): void {
		this.inputChanged.pipe(debounceTime(200), takeUntil(this.destroy$)).subscribe((value) => {
			this.data = value;
			this.dataChange.emit(this.data);
		});
	}

	ngAfterViewInit() {
		if (this.focus) {
			this.setFocus();
		}
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	public onChange(newValue: string): void {
		let data: string | number = newValue;
		if (this.maxLength !== undefined && newValue.length > this.maxLength) {
			data = newValue.substring(0, this.maxLength);
		}

		this.inputChanged.next(data);
		this.onTouched();
	}

	public isFilled(): boolean {
		return !!this.data;
	}

	public readonly setFocus: Function = (evt: MouseEvent): void => {
		if (evt) evt.stopPropagation();
		this.inputField.nativeElement.focus();
		this.isFocused.emit(true);
		this.focus = true;
	};

	public readonly unsetFocus: Function = (): void => {
		this.focus = false;
		this.isFocused.emit(false);
		this.inputField.nativeElement.blur();
		this.onTouched();
	};
}
