<div class="main-black-mask" (click)="cancel(); $event.stopPropagation()"></div>
<div class="create-container">
	<div class="header">
		<h2>{{ headerText }}</h2>
		<div class="cross-container" (click)="cancel()">
			<img class="white-cross-icon" />
		</div>
	</div>
	<div class="body">
		<div class="side-panel-column">
			<div class="text-container" (click)="currentTab = 'credentials'">
				<div
					class="permissions-icon-container"
					[class.active]="currentTab === 'credentials'"
					[class.done]="done"
				>
					<img class="credentials-icon" *ngIf="isAnimationComplete || !done" />
					<lottie-player
						*ngIf="done && !isAnimationComplete"
						class="check"
						src="{{ 'resources/lottie/green-check.json' | baseHref }}"
						background="transparent"
						speed="1"
						autoplay
						(complete)="onAnimationComplete()"
					></lottie-player>
				</div>
				<h3 [class.active]="currentTab === 'credentials'">{{ optionText }}</h3>
			</div>
			<img *ngIf="!isEdited()" src="resources/img/Divider.svg" class="separator" />
			<div *ngIf="user" class="separator"></div>
			<div
				class="text-container"
				[class.inactive]="currentTab !== 'permissions' && !user"
				(click)="user ? (currentTab = 'permissions') : 0"
			>
				<div
					class="permissions-icon-container"
					[class.active]="currentTab === 'permissions'"
					[class.done]="selected"
				>
					<img class="permissions-icon" *ngIf="isComplete || !selected" />
					<lottie-player
						*ngIf="selected"
						class="check"
						src="{{ 'resources/lottie/green-check.json' | baseHref }}"
						background="transparent"
						speed="1"
						autoplay
						(complete)="onAnimationComplete()"
					></lottie-player>
				</div>
				<h3 [class.active]="currentTab === 'permissions'">
					{{ text().PERMISSIONS }}
				</h3>
			</div>
		</div>

		<div class="details-column">
			<!-- Credentials tab content -->
			<form
				*ngIf="currentTab === 'credentials'"
				[formGroup]="userForm"
				(ngSubmit)="isEdited() ? null : navigateToNextTab()"
				autocomplete="off"
			>
				<div class="tab-wrapper">
					<div class="credentials-container">
						<app-simple-text-field
							data-cy="username-input"
							[maxLength]="50"
							class="username-input"
							[type]="'text'"
							[data]="userForm.controls['username'].value"
							(dataChange)="editingUser($event, 'name')"
							formControlName="username"
							[placeholderText]="text().USERNAME"
							[label]="text().USERNAME"
							[labelBackground]="'#092536'"
							[errorText]="getUsernameErrorMessage()"
							[withError]="hasUsernameError()"
							[margin]="20"
						></app-simple-text-field>

						<app-simple-text-field
							data-cy="new-password-user"
							class="password-input"
							type="password"
							[data]="userForm.controls['password'].value"
							formControlName="password"
							[placeholderText]="text().PASSWORD"
							[label]="text().PASSWORD"
							[labelBackground]="'#092536'"
							[errorText]="getPasswordErrorMessage()"
							[withError]="hasPasswordError()"
							[margin]="20"
						>
						</app-simple-text-field>

						<app-simple-text-field
							data-cy="confirm-password-user"
							class="password-input"
							[type]="'password'"
							[data]="userForm.controls['confirmPassword'].value"
							formControlName="confirmPassword"
							[placeholderText]="text().PASSWORD_CONFIRM"
							[label]="text().PASSWORD_CONFIRM"
							[labelBackground]="'#092536'"
							[errorText]="getConfirmationPasswordErrorMessage()"
							[withError]="hasConfirmationPasswordError()"
							[margin]="20"
						></app-simple-text-field>
					</div>
					<p class="helper-text">{{ text().PASSWORD_CRITERIA }}</p>
					<ul class="password-list">
						<li>{{ text().PASSWORD_CRITERIA_MIN }}</li>
						<li>
							{{ text().PASSWORD_CRITERIA_LETTERS }}
						</li>
						<li>{{ text().PASSWORD_CRITERIA_USERNAME }}</li>
						<li>{{ text().PASSWORD_CRITERIA_SAFE }}</li>
					</ul>
				</div>

				<div class="details-divider"></div>
				<div class="buttons-container">
					<button
						data-add-action-name="cancel-modal"
						type="button"
						class="main-blue-gradient-border-button"
						(click)="cancel()"
					>
						{{ text().CANCEL }}
					</button>
					<button
						data-add-action-name="next"
						type="submit"
						class="main-blue-gradient-button"
						[disabled]="!shouldEnableButton()"
						(click)="isEdited() ? saveUser() : addValidator()"
					>
						{{ isEdited() ? text().SAVE : text().NEXT }}
					</button>
				</div>
			</form>

			<!-- Permissions tab content -->
			<form *ngIf="currentTab === 'permissions'" [formGroup]="userForm" class="permissions">
				<div class="permissions-container">
					<h3 class="select-text">{{ selectText }}</h3>
					<div
						*ngFor="let level of levels"
						class="permission-container"
						[class.selected]="selectedOption === level.name"
						(click)="setLevel(level.name)"
					>
						<app-round-checkbox
							[attr.data-add-action-name]="level.name.toLowerCase()"
							[check]="selectedOption === level.name"
						></app-round-checkbox>
						<div class="level-container">
							<h3 class="level-name">{{ getLevelName(level) }}</h3>
							<p class="level-description">
								{{ level.description }}
							</p>
						</div>
					</div>
				</div>

				<div class="details-divider"></div>
				<div class="buttons-container">
					<button
						data-add-action-name="cancel-user"
						type="button"
						class="main-blue-gradient-border-button"
						(click)="cancel()"
					>
						{{ text().CANCEL }}
					</button>
					<button
						data-add-action-name="save-user"
						type="submit"
						[disabled]="!shouldEnableButton()"
						class="main-blue-gradient-button"
						(click)="saveUser()"
					>
						{{ text().SAVE }}
					</button>
				</div>
			</form>
		</div>
	</div>
</div>

<app-two-option-dialog
	*ngIf="twoOptionDialogParams.show"
	[headerText]="twoOptionDialogParams.header"
	[bodyText]="twoOptionDialogParams.body"
	[leftText]="twoOptionDialogParams.leftText"
	[rightText]="twoOptionDialogParams.rightText"
	[rightButtonDisable]="false"
	(cancelCallback)="twoOptionDialogParams.cancelCallback()"
	(leftCallback)="twoOptionDialogParams.leftCallback()"
	(rightCallback)="twoOptionDialogParams.rightCallback()"
>
</app-two-option-dialog>
