import { Component, Input, Output, EventEmitter, OnInit } from "@angular/core";
import { LocaleMap } from "src/app/global/constants/text/text-interface";
import { TextProvider } from "src/app/global/constants/text/text-provider";

@Component({
	selector: "app-simple-pagination",
	templateUrl: "simple-pagination.component.html",
	styleUrls: ["simple-pagination.css"]
})
export class SimplePaginationComponent implements OnInit {
	@Input() initialIndex: number = 1;
	@Input() totalPages: number = 0;
	@Input() leftText: string = "";

	@Output() changePage: EventEmitter<number> = new EventEmitter<number>();

	public page: string = "";
	public of: string = "";
	public iconLeftStatus: string = "-off";
	public iconRightStatus: string = "-off";

	private readonly text: () => LocaleMap;

	constructor(textProv: TextProvider) {
		this.text = textProv.getStringMap;
	}

	ngOnInit() {
		this.of = this.text().OF;
		this.page = this.leftText ? this.leftText : this.text().PAGE;
		this.checkIcons();
	}

	onChange = (e: Event): void => {
		let data = Number((e.target as HTMLInputElement).value);
		!data || data <= 0 ? (data = 1) : (this.initialIndex = data);
		!Number.isInteger(data) ? (data = Math.ceil(data)) : data;
		data > this.totalPages ? (this.initialIndex = this.totalPages) : (this.initialIndex = data);
		this.checkIcons();
		this.changePage.emit(this.initialIndex);
	};

	public readonly onNextPage: Function = (evt: Event): void => {
		this.initialIndex >= 1 && this.initialIndex < this.totalPages ? (this.initialIndex += 1) : this.initialIndex === this.totalPages;
		this.checkIcons();
		this.changePage.emit(this.initialIndex);
		evt.stopPropagation();
	};

	public readonly onPreviousPage: Function = (evt: Event): void => {
		this.initialIndex <= 1 ? (this.initialIndex = 1) : (this.initialIndex -= 1);
		this.checkIcons();
		this.changePage.emit(this.initialIndex);
		evt.stopPropagation();
	};

	public readonly checkIcons = (): void => {
		this.initialIndex !== this.totalPages ? (this.iconRightStatus = "-on") : (this.iconRightStatus = "-off");
		this.initialIndex === 1 ? (this.iconLeftStatus = "-off") : (this.iconLeftStatus = "-on");
	};
}
