import { Directive, ElementRef, Input, OnInit } from "@angular/core";

@Directive({
	selector: "[appScrollToElement]"
})
export class ScrollToElementDirective implements OnInit {
	@Input() scrollIdentifierId: number | undefined;

	constructor(private el: ElementRef) {}

	ngOnInit(): void {
		this.scrollIdentifierId && this.scrollIdentifierId === -1 && this.el.nativeElement.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
	}
}
