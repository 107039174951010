<app-splash-screen
	*ngIf="initialLoading()"
	[authenticated]="authenticated"
	(loadedOnce)="splashScreenLoaded()"
></app-splash-screen>
<app-iris-header></app-iris-header>
<router-outlet></router-outlet>
<app-main-dialog></app-main-dialog>
<app-main-snackbar></app-main-snackbar>
<app-main-nav></app-main-nav>
<app-main-load-snackbar></app-main-load-snackbar>
<app-document-generator></app-document-generator>