import { DTO } from "../net/dto";
import { HARDWARE_TYPE, Hardware } from "./hardware";

export class GPSDevice implements DTO, Hardware {
	id: number;
	firmware_version: string;
	device_key: string | null;

	name : string;
	description: string;
	station: string;

	type = HARDWARE_TYPE.GPS;

	timestamp = Date.now();
	constructor(id: number, firmware: string, key: string | null) {
		this.id = id;
		this.firmware_version = firmware;
		this.device_key = key;

		this.name = this.device_key ?? "GPS " + id;
		this.description = "";
		this.station = "";
	}

	public static readonly fromJson: (json: string) => GPSDevice = (json: string) => {
		var jc = JSON.parse(json);
		return new GPSDevice(jc.id, jc.firmware_version, jc.timestamp);
	};

	public readonly getJson: () => string = () => {
		return JSON.stringify(this, ["id", "firmware_version", "timestamp"]);
	};
}
