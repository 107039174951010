import { Component, Input } from "@angular/core";
import { LocaleMap } from "src/app/global/constants/text/text-interface";
import { TextProvider } from "src/app/global/constants/text/text-provider";

@Component({
	selector: "app-notification",
	templateUrl: "./notification.component.html",
	styleUrls: ["./notification.component.css"]
})
export class NotificationComponent {
	@Input() updateAvailable: boolean = false;
	public readonly text: () => LocaleMap;
	public readonly notification: string;

	constructor(tp: TextProvider) {
		this.text = tp.getStringMap;
		this.notification = this.text().UPDATE_AVAILABLE;
	}
}
