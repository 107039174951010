import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { PREFERRED_COLORS } from "src/app/global/constants/enums/preferred_colors";
import { LocaleMap } from "src/app/global/constants/text/text-interface";
import { TextProvider } from "src/app/global/constants/text/text-provider";

@Component({
	selector: "app-color-picker",
	templateUrl: "./color-picker.component.html",
	styleUrls: ["./color-picker.component.css"]
})
export class ColorPickerComponent implements OnInit {
	@Input() inputColor: string = "";
	@Input() showPreferred: boolean = true;
	@Output() selectedColor: EventEmitter<string> = new EventEmitter<string>();
	@Input() closeCb: Function = () => {};

	public readonly text: () => LocaleMap;
	public hue: string = "";
	public color: string = "";
	public showColorPicker: boolean = false;
	public arrowDirection: string = "up";
	public changeOrigin: number = 0; //0: Preferred color selected, 1: Color selected in canvas, 2: Change in hue slider
	public readonly preferredColours: Array<PREFERRED_COLORS> = [];

	constructor(textProv: TextProvider, private cdRef: ChangeDetectorRef) {
		this.text = textProv.getStringMap;
		this.preferredColours = Object.entries(PREFERRED_COLORS).map(([key, value]) => {
			return value;
		});
	}

	ngOnInit(): void {
		if (!this.showPreferred) {
			this.showColorPicker = true;
		}

		if (this.inputColor) {
			this.color = this.hex2rgba(this.inputColor);
			this.hue = this.hsl2rgba(this.hex2hsl(this.inputColor)[0]);
		}
	}

	public readonly close: () => void = () => {
		this.selectedColor.emit(this.rgba2hex(this.color));
		this.closeCb();
	};

	public readonly update: () => void = () => {
		this.selectedColor.emit(this.rgba2hex(this.color));
	};

	public readonly selectPreferredColor: (color: string) => void = (color) => {
		this.changeOrigin = 0;

		this.selectedColor.emit(color); //Emitting color in HEXADECIMAL format
		this.color = this.hex2rgba(color);
		this.hue = this.hsl2rgba(this.hex2hsl(color)[0]);
	};

	public readonly changeColor: (color: string) => void = (color) => {
		this.color = color;
		this.cdRef.detectChanges();
	};

	public readonly changeOriginFunction: (origin: number) => void = (origin) => {
		this.changeOrigin = origin;
	};

	public readonly getArrowDirection: () => void = () => {
		return this.showColorPicker ? "up" : "down";
	};

	// COLOR TRANSFORMATION FUNCTIONS
	public readonly rgba2hex: (rgba: string) => string = (rgbaColor) => {
		const rgb = rgbaColor.match(/^rgba?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i);
		const hex = rgb && rgb.length === 4 ? "#" + ("0" + parseInt(rgb[1], 10).toString(16)).slice(-2) + ("0" + parseInt(rgb[2], 10).toString(16)).slice(-2) + ("0" + parseInt(rgb[3], 10).toString(16)).slice(-2) : "";
		return hex.toUpperCase();
	};

	private readonly hex2rgba: (hex: string) => string = (hexColor: string) => {
		const red = hexColor.substring(1, 3);
		const green = hexColor.substring(3, 5);
		const blue = hexColor.substring(5);

		const red10 = parseInt(red, 16);
		const green10 = parseInt(green, 16);
		const blue10 = parseInt(blue, 16);

		const rgba = "rgba(" + red10 + "," + green10 + "," + blue10 + ",1)";
		return rgba;
	};

	private readonly hex2hsl: (hex: string) => Array<number> = (hexColor: string) => {
		let h = 0;
		let s = 0;
		let l = 0;
		const red = parseInt(hexColor.substring(1, 3), 16) / 255;
		const green = parseInt(hexColor.substring(3, 5), 16) / 255;
		const blue = parseInt(hexColor.substring(5), 16) / 255;

		const max = Math.max(red, green, blue);
		const min = Math.min(red, green, blue);

		l = (max + min) / 2;

		if (max === min) {
			h = s = 0;
		} else {
			const aux = max - min;
			s = l > 0.5 ? aux / (2 - max - min) : aux / (max + min);
			switch (max) {
				case red:
					h = (green - blue) / aux + (green < blue ? 6 : 0);
					break;
				case green:
					h = (blue - red) / aux + 2;
					break;
				case blue:
					h = (red - green) / aux + 4;
					break;
			}
			h /= 6;
		}
		s = s * 100;
		s = Math.round(s);
		l = l * 100;
		l = Math.round(l);
		h = Math.round(360 * h);

		const hsl = [h, s, l];
		return hsl;
	};

	private readonly hsl2rgba: (hue: number, saturation?: number, lightness?: number) => string = (h, s = 100, l = 50) => {
		s /= 100;
		l /= 100;

		let c = (1 - Math.abs(2 * l - 1)) * s;
		let x = c * (1 - Math.abs(((h / 60) % 2) - 1));
		let m = l - c / 2;
		let r = 0;
		let g = 0;
		let b = 0;

		if (0 <= h && h < 60) {
			r = c;
			g = x;
			b = 0;
		} else if (60 <= h && h < 120) {
			r = x;
			g = c;
			b = 0;
		} else if (120 <= h && h < 180) {
			r = 0;
			g = c;
			b = x;
		} else if (180 <= h && h < 240) {
			r = 0;
			g = x;
			b = c;
		} else if (240 <= h && h < 300) {
			r = x;
			g = 0;
			b = c;
		} else if (300 <= h && h < 360) {
			r = c;
			g = 0;
			b = x;
		}
		r = Math.round((r + m) * 255);
		g = Math.round((g + m) * 255);
		b = Math.round((b + m) * 255);

		return "rgba(" + r + "," + g + "," + b + ",1)";
	};
}
