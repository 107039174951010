import { MESSAGE_TYPE } from "src/app/global/messaging/messages";

export class MessageEvent {
	name: MESSAGE_TYPE;
	params: any;

	// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
	constructor(name: MESSAGE_TYPE, params: any) {
		this.name = name;
		this.params = params;
	}
}
