export class NavTabItem {
	public text: string;
	public index: number;
	public callback: Function;
	public disabled?: boolean;

	constructor(text: string, index: number, callback: Function, disabled: boolean = false) {
		this.text = text;
		this.index = index;
		this.callback = callback;
		this.disabled = disabled;
	}
}
