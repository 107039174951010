import { Component, Input } from "@angular/core";
import { Poi, POI_STATE } from "src/app/dto/items/poi";
import { LocaleMap } from "src/app/global/constants/text/text-interface";
import { TextProvider } from "src/app/global/constants/text/text-provider";

@Component({
	selector: "app-poi-label",
	templateUrl: "poi-label.component.html",
	styleUrls: ["poi-label.css", "../map-label.css"]
})
export class PoiLabelComponent {
	@Input() poi!: Poi;

	text: () => LocaleMap;

	constructor(textProv: TextProvider) {
		this.text = textProv.getStringMap;
	}

	getStatusText: () => string = () => {
		switch (this.poi.status) {
			case POI_STATE.PREDICT:
				return this.text().POI_STATUS_PREDICT;
			case POI_STATE.CURRENT:
				return this.text().CURRENT;
			case POI_STATE.OVER:
				return this.text().POI_STATUS_OVER;
		}
	};
}
