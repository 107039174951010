import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";
import { User } from "src/app/dto/user/user";

export function forbiddenNameValidator(users: User[], currentUserName?: string): ValidatorFn {
	return (control: AbstractControl): ValidationErrors | null => {
		if (currentUserName === control.value) return null;
		const forbidden = users.some((user) => user.id !== -1 && user.name && user.name.toLowerCase() === control.value.toLowerCase());
		return forbidden ? { forbiddenName: { value: control.value } } : null;
	};
}

export function passwordValidator(): ValidatorFn {
	return (control: AbstractControl): ValidationErrors | null => {
		const forbiddenSequenceReverse = /(.)(?:(?:9(?=8|\b)|8(?=7|\b)|7(?=6|\b)|6(?=5|\b)|5(?=4|\b)|4(?=3|\b)|3(?=2|\b)|2(?=1|\b)|1(?=0|\b)|0(?=9|\b)){3,})/.test(control.value);
		const forbiddenSequenceUp = /(.)(?:(?:0(?=1|\b)|1(?=2|\b)|2(?=3|\b)|3(?=4|\b)|4(?=5|\b)|5(?=6|\b)|6(?=7|\b)|7(?=8|\b)|8(?=9|\b)|9(?=0|\b)){3,})/.test(control.value);
		const notValidPassword = /^[a-z]+$/.test(control.value) || /^[A-Z]+$/.test(control.value) || /^[0-9]+$/.test(control.value) || /^\W+$/.test(control.value);
		if (notValidPassword || forbiddenSequenceUp || forbiddenSequenceReverse || control.value?.length < 8) {
			return { invalidPassword: { value: control.value } };
		}
		return null;
	};
}

export function passwordDoesNotContainUsername(): ValidatorFn {
	return (formGroup: AbstractControl) => {
		const usernameField = formGroup.get("username");
		const passwordField = formGroup.get("password");

		if (!usernameField || !passwordField) return null;

		const username = usernameField.value;
		const password = passwordField.value;

		if (password.toLowerCase().indexOf(username.toLowerCase()) !== -1) return { usernameInPassword: true };

		return null;
	};
}

export function passwordsMatch(): ValidatorFn {
	return (formGroup: AbstractControl) => {
		const confirmPasswordField = formGroup.get("confirmPassword");
		const passwordField = formGroup.get("password");

		if (!confirmPasswordField || !passwordField) return null;

		const confirmPassword = confirmPasswordField.value;
		const password = passwordField.value;

		if (confirmPassword !== password) return { passwordsDoNotMatch: true };

		return null;
	};
}
