import { JESIP_FORMS } from "src/app/global/constants/enums/jesip_forms";
import { DTO } from "../net/dto";

export class IncidentInformation implements DTO {
	public id = -1;

	public readonly id_mission: number;
	public readonly form: JESIP_FORMS.DEBRIEF | JESIP_FORMS.JDM;
	public type: string | undefined;
	public location: string | undefined;

	public timestamp = Date.now();

	constructor(id_mission: number, form: JESIP_FORMS.DEBRIEF | JESIP_FORMS.JDM, type?: string, location?: string) {
		this.id_mission = id_mission;
		this.form = form;
		this.type = type ? type : "";
		this.location = location ? location : "";
	}

	public static readonly fromJson: (json: string) => IncidentInformation = (json) => {
		const jc = JSON.parse(json);
		let form: JESIP_FORMS = JESIP_FORMS.DEBRIEF;
		switch (jc.form) {
			case "debrief":
				form = JESIP_FORMS.DEBRIEF;
				break;
			case "jdm":
				form = JESIP_FORMS.JDM;
				break;
		}
		return new IncidentInformation(jc.id_mission, form, jc.type, jc.location);
	};

	public readonly getJson: () => string = () => {
		let propertiesArr = ["id_mission", "form", "type", "location"];
		return JSON.stringify(this, propertiesArr);
	};
}
