import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FsgFlat } from "src/app/dto/fsg/fsg-flat";
import { LocaleMap } from "src/app/global/constants/text/text-interface";
import { TextProvider } from "src/app/global/constants/text/text-provider";

@Component({
	selector: "app-select-priority-popup",
	templateUrl: "fsg-select-priority.component.html",
	styleUrls: ["../fsg-editor-popup.css", "fsg-select-priority.css"]
})
export class FsgSelectPriorityPopupComponent implements OnInit {
	@Input() flat!: FsgFlat;
	@Input() flats!: Array<FsgFlat>;
	@Output() selectEmit = new EventEmitter<string>();
	@Output() cancel = new EventEmitter<void>();

	public readonly text: () => LocaleMap;
	public options = [1, 2, 3];

	constructor(tp: TextProvider) {
		this.text = tp.getStringMap;
	}

	ngOnInit(): void {
		if (!this.flat) throw "FsgSelectPriority component initialized without a valid flat object";
	}

	public readonly isPrioritySelected: (val: number) => boolean = (val) => {
		return val === this.flat.priority;
	};

	public readonly optionClick: (val: number) => void = (val) => {
		this.flat.priority = this.flat.priority !== val ? val : -1;
	};
}
