import { AfterViewInit, Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";

@Component({
	selector: "app-infinite-scroll",
	templateUrl: "./infinite-scroll.component.html"
})
export class InfiniteScrollComponent implements OnInit, AfterViewInit, OnDestroy {
	@Output() scrolled = new EventEmitter();
	@ViewChild("anchor") anchor!: ElementRef<HTMLElement>;

	private observer!: IntersectionObserver;

	ngOnInit(): void {
		//this will observe whenever the anchor element (which is placed at the bottom of the list) enters in the viewport
		this.observer = new IntersectionObserver(([entry]) => entry.isIntersecting && this.scrolled.emit());
	}

	ngAfterViewInit(): void {
		this.observer.observe(this.anchor.nativeElement);
	}

	ngOnDestroy() {
		this.observer.disconnect();
	}
}

//source https://netbasal.com/build-an-infinite-scroll-component-in-angular-a9c16907a94d
