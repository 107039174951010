import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { LocaleMap } from "src/app/global/constants/text/text-interface";
import { TextProvider } from "src/app/global/constants/text/text-provider";
import { LoginService } from "../../../../login/login.service";

@Component({
	selector: "app-authentication-dialog",
	templateUrl: "authentication-dialog.component.html",
	styleUrls: ["authentication-dialog.css"]
})
export class AuthenticationDialogComponent implements OnInit {
	@Input() headerText: string = "";
	@Input() bodyText: string = "";
	@Input() confirmText: string = "";
	@Input() withError: boolean = false;
	@Input() label: string | undefined;

	@Output() cancelCallback = new EventEmitter();
	@Output() confirmCallback = new EventEmitter();
	@Output() denyCallback = new EventEmitter();

	public readonly text: () => LocaleMap;
	public show: boolean = false;
	public canConfirm: boolean = false;
	public password: string = "";

	private readonly loginServ: LoginService;

	constructor(textProv: TextProvider, loginServ: LoginService) {
		this.text = textProv.getStringMap;
		this.loginServ = loginServ;
	}

	ngOnInit() {
		this.headerText = this.text().AUTHENTICATION_REQUIRED;
	}

	public readonly canComplete = async (): Promise<boolean> => {
		const result = await this.loginServ.checkPassword({ username: this.loginServ.user.name, password: this.password });
		return result;
	};

	public readonly confirm: Function = () => {
		this.confirmCallback.emit();
	};

	public readonly cancel: Function = () => {
		this.cancelCallback.emit();
	};

	public readonly deny: Function = () => {
		this.denyCallback.emit();
	};

	public togglePasswordVisibility(): void {
		this.show = !this.show;
	}

	public readonly isWithError: () => boolean = () => {
		return this.withError;
	};
}
