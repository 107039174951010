<div class="container">
	<div class="header">
		<div class="title">
			<div *ngIf="initial" class="header-icon" [style.backgroundColor]="getColour()">
				{{ initial }}
			</div>
			<span>{{ title }}</span>
		</div>
		<div class="void-subtitle">
			<div appBoldTextPattern [pattern]="getButton()">
				{{ getBody() }}
			</div>
		</div>
	</div>
	<div class="line"></div>
	<div class="footer">
		<app-notification [updateAvailable]="updateAvailable"></app-notification>
		<button
			data-add-action-name="jesip-confirmation-button"
			class="main-blue-gradient-border-button margin-left-auto"
			(click)="buttonClick.emit($event)"
			[class.button-disabled]="!this.updateAvailable && this.isIncidentClosed()"
			[class.disabled]="disabled"
		>
			{{ getButton() }}
		</button>
	</div>
	<div class="icon hard right" *ngIf="backgroundSrc">
		<img [attr.src]="backgroundSrc" />
	</div>
</div>
