import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { FsgBuilding } from "src/app/dto/fsg/fsg-building";
import { LocaleMap } from "src/app/global/constants/text/text-interface";
import { TextProvider } from "src/app/global/constants/text/text-provider";

@Component({
	selector: "app-naming-convention-modal",
	templateUrl: "./naming-convention-modal.component.html",
	styleUrls: ["./naming-convention-modal.component.css"]
})
export class NamingConventionModalComponent implements OnChanges {
	@Input() fsg!: FsgBuilding;

	@Output() save = new EventEmitter<FsgBuilding>();
	@Output() cancel = new EventEmitter<void>();

	public currentFsg: FsgBuilding | undefined;

	public readonly text: () => LocaleMap;

	constructor(textProv: TextProvider) {
		this.text = textProv.getStringMap;
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.hasOwnProperty("fsg")) this.currentFsg = Object.assign({}, this.fsg);
	}

	confirm(): void {
		this.fsg.name = this.currentFsg!.name;
		this.fsg.naming_convention = this.currentFsg!.naming_convention;
		this.save.emit(this.fsg);
	}

	close(): void {
		this.cancel.emit();
	}
}
