import { Component, EventEmitter, Input, NgZone, OnInit, Output } from "@angular/core";
import { AutomationService } from "src/app/global/automation/automation.service";

@Component({
	selector: "app-splash-screen",
	templateUrl: "./splash-screen.component.html",
	styleUrls: ["./splash-screen.component.css"]
})
export class SplashScreenComponent implements OnInit {
	@Input() authenticated: boolean = false;
	@Output() loadedOnce = new EventEmitter();

	public loaderPosition: number = 0;

	private readonly auto: AutomationService;

	constructor(auto: AutomationService) {
		this.auto = auto;
	}

	ngOnInit(): void {
		this.auto.loadPercentage$.subscribe((value) => {
			this.loaderPosition = value * 300;
			if(this.loaderPosition === 300) setTimeout(() => this.loadedOnce.emit(), 500);
		})
	}
}
