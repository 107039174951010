<label
	class="drag-zone {{ active ? 'active' : '' }}"
	(dragover)="onDragOver($event)"
	(mouseout)="onDragOut($event)"
	(drop)="onDropSuccess($event)"
>
	<div *ngIf="notAccepted" class="file-error">
		<img src="resources/img/exclamation.svg" />
	</div>
	<div *ngIf="!notAccepted" class="file-icon">
		<img src="resources/img/folder.svg" />
	</div>
	<div *ngIf="notAccepted" class="text-container">
		<span class="upload-info"
			>{{ text().FILE_UNSUPPORTED }}. {{ text().ADD_OVERLAY_INFO }}</span
		>
		<span class="upload-info">
			{{
				isPoi
					? text().FILE_TYPES_POI
					: isOverlay
					? text().FILE_TYPES_OVERLAY
					: text().FILE_TYPES
			}}</span
		>
	</div>
	<div *ngIf="!notAccepted" class="text-container">
		<span class="upload-info">{{ text().ADD_OVERLAY_INFO }}</span>
		<span class="upload-info">
			{{
				isPoi
					? text().FILE_TYPES_POI
					: isOverlay
					? text().FILE_TYPES_OVERLAY
					: text().FILE_TYPES
			}}</span
		>
	</div>
	<input
		*ngIf="!loading"
		id="file-upload"
		[accept]="supportedFilesAsArray"
		(change)="onSelectFile($event)"
		type="file"
	/>
</label>
