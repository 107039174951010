import { DTO } from "../net/dto";

export class SkillUserRelation implements DTO {
	id: number;
	id_skill: number;
	id_user: number;

	timestamp = Date.now();
	constructor(id: number, id_skill: number, id_user: number) {
		this.id = id;
		this.id_skill = id_skill;
		this.id_user = id_user;
	}

	public static readonly fromJson: (json: string) => SkillUserRelation = (json: string) => {
		var jc = JSON.parse(json);
		return new SkillUserRelation(jc.id_relation, jc.id_skill, jc.id_user);
	};

	public readonly getJson: () => string = () => {
		return JSON.stringify(this, ["id_relation", "id_skill", "id_user"]);
	};
}
