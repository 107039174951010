import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { LocaleMap } from "src/app/global/constants/text/text-interface";
import { TextProvider } from "src/app/global/constants/text/text-provider";
import { MainService } from "src/app/global/main.service";

@Component({
	selector: "app-error-banner",
	templateUrl: "./error-banner.component.html",
	styleUrls: ["./error-banner.component.css"]
})
export class ErrorBannerComponent implements OnInit {
	@Input() header?: string;
	@Output() closeEvent = new EventEmitter<void>();

	public errorMessage1: string = "";
	public errorMessage2: string = "";
	public errorMessage3: string = "";
	public expanded: boolean = false;

	private main: MainService;
	private readonly text: () => LocaleMap;

	constructor(mainServ: MainService, textProv: TextProvider) {
		this.main = mainServ;
		this.text = textProv.getStringMap;
		this.errorMessage1 = this.text().SERVER_ERROR_MESSAGE_1;
		this.errorMessage2 = this.text().SERVER_ERROR_MESSAGE_2;
		this.errorMessage3 = this.text().SERVER_ERROR_MESSAGE_3;
	}

	ngOnInit(): void {
		if (!this.header) this.header = this.text().REQUEST_ERROR;
	}

	public toggleExpand = (): boolean => (this.expanded = !this.expanded);

	public close = (): boolean => (this.expanded = false);

	public openSupportMenu = (): void => {
		this.main.openSupportDialog.next();
		this.close();
	};
}
