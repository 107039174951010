<div class="main-black-mask" (click)="cancel(); $event.stopPropagation()"></div>
<div class="alert-dialog">
	<div class="white-cross-container">
		<app-white-cross class="dialog-cross-button" (clickCb)="cancel()"></app-white-cross>
	</div>

	<div class="row-upper">
		{{ headerText }}
	</div>
	<div class="row-middle">
		<span class="dialog-text">
			{{ bodyText }}
		</span>
	</div>
	<div class="row-middle input-container">
		<input
			id="password"
			class="password-input {{ isWithError() ? ' error' : '' }}"
			[type]="show ? 'text' : 'password'"
			[(ngModel)]="password"
			placeholder="{{ text().PASSWORD }}"
			(label)="text().PASSWORD"
		/>
		<button
			id="eye"
			class="eye-button {{ show ? 'hidden' : '' }} {{ isWithError() ? ' error' : '' }}"
			(click)="togglePasswordVisibility(); $event.preventDefault()"
		></button>
		<label *ngIf="isWithError">{{ label }}</label>
	</div>

	<div class="row-lower">
		<button class="main-blue-gradient-border-button margin-right-12px" (click)="cancel()">
			{{ text().CANCEL }}
		</button>
		<button class="main-blue-gradient-button" (click)="canComplete()">
			{{ confirmText }}
		</button>
	</div>
</div>
