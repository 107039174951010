import { MenuService } from "./../menu.service";
import { SCREEN } from "./../../../../global/constants/enums/screens";
import { NavigationService } from "./../../../navigation.service";
import { Component, EventEmitter, Output } from "@angular/core";
import { NOTIFICATION_TYPE } from "src/app/dto/notification/notification";
import { LocaleMap } from "src/app/global/constants/text/text-interface";
import { TextProvider } from "src/app/global/constants/text/text-provider";
import { IncidentService } from "src/app/incident/incident.service";
import { FormsService } from "src/app/incident/incident-tools/forms/forms.service";
import { FORM_SCREENS } from "src/app/incident/incident-tools/forms/screens.enum";
import { FunctionalityService, FUNCTIONALITY } from "src/app/global/functionality.service";
import { UserService } from "src/app/settings/user/user.service";

@Component({
	selector: "app-jesip-tools",
	templateUrl: "./jesip-tools.component.html",
	styleUrls: ["./jesip-tools.component.css"]
})
export class JesipToolsComponent {
	@Output() goToForm = new EventEmitter<FORM_SCREENS>();

	public formScreens = FORM_SCREENS;
	public notificationTitle = NOTIFICATION_TYPE;
	public jesipSubMenuOpen: boolean = false;
	public readonly text: () => LocaleMap;
	public readonly functionality = FUNCTIONALITY;

	public forms: { type: FORM_SCREENS; title: string; notificationTitle?: NOTIFICATION_TYPE }[] = [];

	private readonly ems: IncidentService;
	private readonly jesip: FormsService;
	private readonly nav: NavigationService;
	private readonly menuService: MenuService;
	private readonly functServ: FunctionalityService;
	private readonly userServ: UserService;

	constructor(tp: TextProvider, fs: FormsService, ems: IncidentService, nav: NavigationService, menu: MenuService, functServ: FunctionalityService, user: UserService) {
		this.text = tp.getStringMap;
		this.ems = ems;
		this.jesip = fs;
		this.nav = nav;
		this.menuService = menu;
		this.functServ = functServ;
		this.userServ = user;
		this.setAvailableForms();
	}

	get selectedScreen(): FORM_SCREENS | undefined {
		return this.jesip.currentScreen;
	}

	public isInHistoric(): boolean {
		const currentIncident = this.ems.getCurrentIncident();
		return currentIncident!.closed;
	}
	public isFuncionalityAvailable(functionality: FUNCTIONALITY): boolean {
		const isAvailable = !!this.functServ.isFunctionalityAvailable.get(functionality);

		if (functionality === FUNCTIONALITY.DECISION && !this.hasDlAccess()) {
			return false;
		}

		if (functionality === FUNCTIONALITY.EF) {
			return isAvailable && this.userServ.getCurrentUserLevel()?.edit_ef;
		}

		return isAvailable;
	}

	public isDisabled(form: FORM_SCREENS): boolean {
		switch (form) {
			case this.formScreens.METHANE:
				if (this.jesip.currentMethane) return false;
				return true;
			case this.formScreens.IIMARCH:
				if (this.jesip.currentIiMarch) return false;
				return true;
			case this.formScreens.JDM:
				if (this.jesip.currentJdm) return false;
				return true;
			case this.formScreens.DEBRIEF:
				return !this.isDebriefInit();
			case this.formScreens.CHECKLIST:
				if (this.jesip.missionChecklist) return false;
				return true;
			default:
				return true;
		}
	}

	public readonly notification = (form: NOTIFICATION_TYPE): boolean => {
		return this.jesip.getUnreadNotification(form, this.ems.getCurrentIncident()!.id);
	};

	public evacuationFormHandler(): void {
		if (this.isInFsgScreen()) {
			this.menuService.openEvfSideMenu();
		} else {
			this.goToForm.emit(this.formScreens.FSG);
		}
	}

	public isInFsgScreen(): boolean {
		return this.nav.currentScreen === SCREEN.JESIP && this.jesip.currentScreen === FORM_SCREENS.FSG;
	}

	public setAvailableForms(): void {
		if (this.isFuncionalityAvailable(FUNCTIONALITY.METHANE)) this.forms.push({ type: FORM_SCREENS.METHANE, title: "M/ETHANE", notificationTitle: NOTIFICATION_TYPE.METHANE });
		if (this.isFuncionalityAvailable(FUNCTIONALITY.IIMARCH)) this.forms.push({ type: FORM_SCREENS.IIMARCH, title: "IIMARCH", notificationTitle: NOTIFICATION_TYPE.IIMARCH });
		if (this.isFuncionalityAvailable(FUNCTIONALITY.JDM)) this.forms.push({ type: FORM_SCREENS.JDM, title: "JDM", notificationTitle: NOTIFICATION_TYPE.JDM });
		if (this.isFuncionalityAvailable(FUNCTIONALITY.CHECKLIST) && this.canEdit()) this.forms.push({ type: FORM_SCREENS.CHECKLIST, title: "Checklist" });

		if (this.isFuncionalityAvailable(FUNCTIONALITY.DEBRIEF)) this.forms.push({ type: FORM_SCREENS.DEBRIEF, title: "Debrief", notificationTitle: NOTIFICATION_TYPE.DEBRIEF });
	}

	private readonly isDebriefInit = (): boolean => {
		if (!this.jesip.currentDebrief) return false;
		for (let i = 0; i < this.jesip.currentDebrief.debrief.length; i++) {
			const option = this.jesip.currentDebrief.debrief[i];
			if (option.items.find((e) => e && e !== "")) return true;
		}
		return false;
	};

	private canEdit(): boolean {
		return this.userServ.getCurrentUserLevel().edit_preplanned;
	}

	private hasDlAccess(): boolean {
		return this.userServ.getCurrentUserLevel().create_dl || this.userServ.getCurrentUserLevel().view_dl;
	}
}
