import { Component, Input } from "@angular/core";
import { Size } from "src/app/dto/map/size";

@Component({
	selector: "app-svg-preview",
	templateUrl: "svg-preview.component.html"
})
export class SvgPreviewComponent {
	@Input() size: Size = new Size(24, 24);

	@Input() color: string = "#FFF";

	@Input() path: string = "";
}
