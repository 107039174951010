<div *ngIf="totalPages" class="pagination-wrapper">
	<div class="pagination-icon-container">
		<img
			(click)="onPreviousPage($event)"
			[class]="'pagination-left-icon' + iconLeftStatus"
			[attr.src]="'resources/img/pagination-left-icon' + iconLeftStatus + '.svg'"
			alt=""
		/>
	</div>
	<div class="pagination-text">{{ page }}</div>
	<div class="pagination-number-wrapper">
		<input
			type="number"
			class="pagination-page"
			[(ngModel)]="initialIndex"
			(change)="onChange($event)"
		/>
		<img
			class="pagination-number-container"
			src="resources/img/pagination-number.svg"
			alt=""
		/>
	</div>
	<div class="pagination-text">{{ of }} {{ totalPages }}</div>
	<div class="pagination-icon-container">
		<img
			(click)="onNextPage($event)"
			[class]="'pagination-right-icon' + iconRightStatus"
			[attr.src]="'resources/img/pagination-right-icon' + iconRightStatus + '.svg'"
			alt=""
		/>
	</div>
</div>
