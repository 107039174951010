import { formatDate } from "@angular/common";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { LocaleMap } from "src/app/global/constants/text/text-interface";
import { TextProvider } from "src/app/global/constants/text/text-provider";

@Component({
	selector: "app-calendar-filter",
	templateUrl: "calendar-filter.component.html",
	styleUrls: ["calendar-filter.css"]
})
export class CalendarFilterComponent {
	@Output() clearCb = new EventEmitter<void>();
	@Input() applyCb: Function = () => {};

	public readonly text: () => LocaleMap;
	public isFilterApplied: boolean = false;

	public newDate: Date;
	public dateFrom: Date | undefined;
	public dateFromStr: string;
	public dateTo: Date | undefined;
	public dateToStr: string;
	public currentIndexDate: Date | undefined;

	public open: boolean = false;
	public padding: string = "8px";
	public fontSize: string = "14px";
	public lineHeight: string = "22px";

	public monthGrid: Array<number>;
	public firstDayPos: number;
	public firstDayNextMonthPos: number;

	private completedDates: boolean = false;

	constructor(textProv: TextProvider) {
		this.text = textProv.getStringMap;
		this.newDate = new Date();
		this.dateFromStr = "";
		this.dateToStr = "";
		this.monthGrid = [];
		this.firstDayPos = this.firstDayNextMonthPos = -1;
		this.fillMonthGrid(this.newDate);
	}

	public readonly closeCalendar: Function = () => {
		this.open = false;
	};

	public readonly changeMonth: Function = (monthToAdd: number) => {
		let currentMonth = this.newDate.getMonth() + 1;
		let currentYear = this.newDate.getFullYear();
		let newMonth = currentMonth;
		let newYear = currentYear;
		if ((currentMonth == 1 && monthToAdd < 0) || (currentMonth == 12 && monthToAdd > 0)) {
			newYear = currentYear + monthToAdd;
		}
		if (currentMonth == 1 && currentYear != newYear) {
			newMonth = 12;
		} else if (currentMonth == 12 && currentYear != newYear) {
			newMonth = 1;
		} else {
			newMonth = currentMonth + monthToAdd;
		}

		this.newDate = new Date(newMonth + "/01/" + newYear);
		this.fillMonthGrid(this.newDate);
	};

	public readonly fillMonthGrid: Function = (date: Date) => {
		this.monthGrid = new Array(42);
		//day of the week were the month starts
		var weekdayStart = new Date(date.getFullYear(), date.getMonth(), 1).getDay();
		//number of days the month to display has
		var daysOftheMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
		//number of days the month before had
		var daysOfBeforeMonth = new Date(date.getFullYear(), date.getMonth(), 0).getDate();
		//adapting to weeks that start on monday not on sunday, from 0 to 6
		if (weekdayStart != 0) weekdayStart--;
		else weekdayStart = 6;
		for (var i = 0; i < 42; i++) {
			if (i < weekdayStart) {
				//days from the previous month before arrive
				this.monthGrid[i] = daysOfBeforeMonth - (weekdayStart - (i + 1));
			} else if (i == weekdayStart || i == weekdayStart + daysOftheMonth) {
				//first day of month or the next month
				this.monthGrid[i] = 1;
			} else {
				//days of the month after the first
				this.monthGrid[i] = this.monthGrid[i - 1] + 1;
			}
		}
		this.firstDayPos = this.monthGrid.indexOf(1);
		this.firstDayNextMonthPos = this.monthGrid.indexOf(1, this.firstDayPos + 1) - 1;
	};

	public readonly selectDate: Function = (index: number) => {
		var day = this.monthGrid[index];
		var month = this.newDate.getMonth();
		var year = this.newDate.getFullYear();
		let dateTemp = new Date(year, month, day);

		if (this.dateFrom && !this.completedDates && !(this.dateFrom && this.dateTo && this.completedDates)) {
			this.dateTo = dateTemp;
			// this.dateToStr = this.dateTo.toISOString().split('T')[0];
			this.dateToStr = formatDate(this.dateTo, "yyyy-MM-dd", "en-US");
			this.completedDates = true;
		} else {
			this.dateFrom = this.dateTo = dateTemp;
			this.dateFromStr = formatDate(this.dateFrom, "yyyy-MM-dd", "en-US");
			this.dateToStr = formatDate(this.dateTo, "yyyy-MM-dd", "en-US");
			this.completedDates = false;
		}
	};

	public clearFilter: Function = () => {
		this.dateFrom = undefined;
		this.dateFromStr = "";
		this.dateTo = undefined;
		this.dateToStr = "";
		this.newDate = new Date();
		this.isFilterApplied = false;
		this.clearCb.emit();
	};

	public applyFilter = (): void => {
		const oldDateFrom = this.dateFrom;
		const oldDateto = this.dateTo;
		if (this.dateFrom! > this.dateTo!) {
			this.dateFrom = oldDateto;
			this.dateTo = oldDateFrom;
		}
		this.applyCb(this.dateFrom, this.dateTo);
		this.isFilterApplied = true;
		this.open = false;
	};

	public leftCase: Function = (index: number) => {
		if (!this.dateFrom || !this.dateTo) return false;

		var day = this.monthGrid[index];
		var month = this.newDate.getMonth();
		var year = this.newDate.getFullYear();
		this.currentIndexDate = new Date(year, month, day);

		return (this.currentIndexDate.getTime() == this.dateFrom.getTime() && this.currentIndexDate.getTime() < this.dateTo.getTime()) || (this.currentIndexDate.getTime() == this.dateTo.getTime() && this.currentIndexDate.getTime() < this.dateFrom.getTime());
	};

	public rightCase: Function = (index: number) => {
		if (!this.dateFrom || !this.dateTo) return false;

		var day = this.monthGrid[index];
		var month = this.newDate.getMonth();
		var year = this.newDate.getFullYear();
		this.currentIndexDate = new Date(year, month, day);
		return (this.currentIndexDate.getTime() == this.dateFrom.getTime() && this.currentIndexDate.getTime() > this.dateTo.getTime()) || (this.currentIndexDate.getTime() == this.dateTo.getTime() && this.currentIndexDate.getTime() > this.dateFrom.getTime());
	};

	public isFirstRow: Function = (index: number) => {
		return index % 7 == 0;
	};

	public isLastRow: Function = (index: number) => {
		return (index + 1) % 7 == 0;
	};

	public readonly setCalendarDate = (isDateFrom: boolean): Date => {
		if (isDateFrom) return (this.newDate = this.dateFrom ?? new Date());
		else return (this.newDate = this.dateTo ?? new Date());
	};

	public readonly refreshDateFrom = (input: string): void => {
		try {
			const splitted = input.split("-");
			const year: number = +splitted[0];
			const month: number = +splitted[1] - 1;
			const day: number = +splitted[2];
			this.dateFrom = new Date(year, month, day);
			this.dateFromStr = input;
		} catch (e) {
			this.dateFrom = new Date();
			this.dateFromStr = "";
		}
	};

	public readonly refreshDateTo = (input: string): void => {
		try {
			const splitted = input.split("-");
			const year: number = +splitted[0];
			const month: number = +splitted[1] - 1;
			const day: number = +splitted[2];
			this.dateTo = new Date(year, month, day);
			this.dateToStr = input;
		} catch (e) {
			this.dateTo = new Date();
			this.dateToStr = "";
		}
	};

	styleRange: Function = (index: number) => {
		// currentIndexDate is the index of the day in the current month/year where the day is selected
		if (!this.dateFrom || !this.dateTo) return false;

		var day = this.monthGrid[index];
		var month = this.newDate.getMonth();
		var year = this.newDate.getFullYear();
		let currentIndexDate = new Date(year, month, day);

		return (currentIndexDate.getTime() > this.dateFrom.getTime() && currentIndexDate.getTime() < this.dateTo.getTime()) || (currentIndexDate.getTime() < this.dateFrom.getTime() && currentIndexDate.getTime() > this.dateTo.getTime());
	};

	isDaySelected: Function = (index: number) => {
		if (!this.newDate) return false;

		var day = this.monthGrid[index];
		var month = this.newDate.getMonth();
		var year = this.newDate.getFullYear();
		// currentIndexDate is the index of the day in the current month/year where the day is selected
		this.currentIndexDate = new Date(year, month, day);

		return (this.dateTo && this.currentIndexDate.getTime() == this.dateTo.getTime()) || (this.dateFrom && this.dateFrom.getTime() == this.currentIndexDate.getTime());
	};
}
