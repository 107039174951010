import { Component, Input, ViewChild, ElementRef, Output, EventEmitter } from "@angular/core";

@Component({
	selector: "app-blue-button-search",
	templateUrl: "blue-button-search.component.html",
	styleUrls: ["blue-button-search.css"]
})
export class BlueButtonSearchComponent {
	@Input() queryOutput: string = "";

	@Output() changeEmit = new EventEmitter<string>();
	@ViewChild("inputRef") inputRef!: ElementRef;

	public open: boolean = false;

	toggleFilter: Function = () => {
		this.inputRef.nativeElement.focus();
		this.open = !this.open;
	};
}
