<div class="address-container">
	<div class="icon-container {{ isGeocoderEnabled() ? 'position' : '' }}">
		<img class="location-icon" />
	</div>

	<input
		*ngIf="!isGeocoderEnabled()"
		id="selectAddressInputComponent"
		class="address-input"
		[type]="'text'"
		[(ngModel)]="currentAddress"
		placeholder="{{ currentAddress ? currentAddress : text().LOCATION }}"
		(blur)="setAddress(currentAddress)"
	/>
	<app-transparent-dropdown
		*ngIf="isGeocoderEnabled() && results"
		[object]="currentAddress"
		[options]="results"
		[filterEnabled]="false"
		[placeholderText]="text().FSG_SELECT_ADDRESS"
		[filterEnabled]="false"
		[menuMaxHeight]="150"
		[selectingAddress]="true"
		(itemClickCallback)="setAddress($event)"
	>
	</app-transparent-dropdown>
</div>
