import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Personnel } from "src/app/dto/resources/personnel";
import { LocaleMap } from "src/app/global/constants/text/text-interface";
import { TextProvider } from "src/app/global/constants/text/text-provider";

@Component({
	selector: "app-add-skill",
	templateUrl: "./add-skill.component.html",
	styleUrls: ["../resource-card.css"]
})
export class AddSkillComponent {
	@Input() personnel!: Personnel;
	@Output() showSkillModal = new EventEmitter<void>();

	public text: () => LocaleMap;

	constructor(textProv: TextProvider) {
		this.text = textProv.getStringMap;
	}

	public readonly openSkillModal: Function = () => {
		this.showSkillModal.emit();
	};
}
