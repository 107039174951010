import { Directive, ElementRef, OnInit } from "@angular/core";

@Directive({
	selector: "[appInstantFocus]"
})
export class InstantFocusDirective implements OnInit {
	private elem: any;

	constructor(el: ElementRef) {
		this.elem = el.nativeElement;
	}

	ngOnInit() {
		this.elem.focus();
		if (this.elem.select) this.elem.select();
	}
}
