import { Component, ElementRef, EventEmitter, Input, Output } from "@angular/core";
import { FsgBuilding, FSG_NAMING_CONVENTION } from "src/app/dto/fsg/fsg-building";
import { LocaleMap } from "src/app/global/constants/text/text-interface";
import { TextProvider } from "src/app/global/constants/text/text-provider";

export enum CHEVRON_ICON {
	ACTIVE = "resources/img/Chevron_down_active.svg",
	INACTIVE = "resources/img/Chevron_down_inactive.svg",
	EXPANDED = "resources/img/chevron_up_active.svg"
}
@Component({
	selector: "app-new-fsg-modal",
	templateUrl: "new-fsg-modal.component.html",
	styleUrls: ["new-fsg-modal.css"]
})
export class NewFsgModalComponent {
	@Input() fsg!: FsgBuilding;

	@Output() cancelCb = new EventEmitter<void>();
	@Output() confirmCb = new EventEmitter<void>();

	public fireInfoOpen: boolean = false;
	public imageUrl: string = CHEVRON_ICON.INACTIVE;
	public readonly text: () => LocaleMap;
	public namingConvention = FSG_NAMING_CONVENTION;

	constructor(tp: TextProvider) {
		this.text = tp.getStringMap;
	}

	public readonly cancel: Function = () => {
		this.cancelCb.emit();
	};

	public readonly confirm: Function = () => {
		this.confirmCb.emit();
	};

	public readonly setNewName: Function = (val: string) => {
		this.fsg.name = val;
	};

	public readonly setNewFloorNumber = (val: string): number => {
		return (this.fsg.floor_number = +val);
	};

	public readonly setNewFlatNumber = (val: string): number => {
		return (this.fsg.flat_per_floor = +val);
	};

	public readonly setNewFireFloor = (val: string): number => {
		return (this.fsg.fire_floor = +val);
	};

	public readonly setNewFireFlat = (val: string): number => {
		return (this.fsg.fire_flat = +val);
	};

	public readonly setNewDescription: Function = (val: string) => {
		this.fsg.description = val;
	};

	public isFloorValid(): boolean {
		return this.isFlatOrFloorValid(this.fsg.floor_number, this.fsg.fire_floor);
	}

	public isFlatValid(): boolean {
		return this.isFlatOrFloorValid(this.fsg.flat_per_floor, this.fsg.fire_flat);
	}

	public readonly floorsAndFlatsSet: () => boolean = () => {
		const isActive = this.fsg.floor_number > -1 && this.fsg.flat_per_floor > 0;
		this.imageUrl = this.getChevron(isActive, this.fireInfoOpen);
		return isActive;
	};

	public readonly toggleFireInfo = (): void => {
		this.fireInfoOpen = !this.fireInfoOpen;
	};

	public getNamingConvention = (type: FSG_NAMING_CONVENTION): (() => boolean) => {
		return () => this.fsg.naming_convention === type;
	};

	public setNamingConvention = (type: FSG_NAMING_CONVENTION): FSG_NAMING_CONVENTION => {
		return (this.fsg.naming_convention = type);
	};

	private getChevron(isActive: boolean, isExpanded: boolean): string {
		if (isActive && isExpanded) {
			return CHEVRON_ICON.EXPANDED;
		} else if (isActive) {
			return CHEVRON_ICON.ACTIVE;
		}
		return CHEVRON_ICON.INACTIVE;
	}

	private isFlatOrFloorValid(flatOrFloor: number, fireFlatOrFireFloor: number): boolean {
		if (this.floorsAndFlatsSet() && fireFlatOrFireFloor > 0) {
			return !!fireFlatOrFireFloor && fireFlatOrFireFloor <= flatOrFloor;
		}
		return true;
	}
}
