import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { DEFAULT_ADVICE } from "src/app/dto/fsg/enums";
import { FsgFlat } from "src/app/dto/fsg/fsg-flat";
import { FsgService } from "src/app/incident/incident-tools/fsg/fsg.service";
import { LocaleMap } from "src/app/global/constants/text/text-interface";
import { TextProvider } from "src/app/global/constants/text/text-provider";

@Component({
	selector: "app-select-advice-popup",
	templateUrl: "fsg-select-advice.component.html",
	styleUrls: ["../fsg-editor-popup.css"]
})
export class FsgSelectAdvicePopupComponent implements OnInit {
	@Input() flat!: FsgFlat;

	@Output() selectEmit = new EventEmitter<string>();
	@Output() cancel = new EventEmitter<void>();

	public readonly text: () => LocaleMap;
	public readonly defaults = DEFAULT_ADVICE;
	public openNewInput = false;
	public newValue = "";

	public options = new Array<string>();
	public getValIndex = new Map<string, string>();

	private readonly fsg: FsgService;

	constructor(tp: TextProvider, fsgS: FsgService) {
		this.text = tp.getStringMap;
		this.fsg = fsgS;
		let defaults = DEFAULT_ADVICE.values;
		defaults.forEach((val) => {
			if (val !== DEFAULT_ADVICE.defaults.get("DEFAULT")!) {
				this.options.push(this.fsg.getAdviceValueAsLocalizedString(val));
				this.getValIndex.set(this.fsg.getAdviceValueAsLocalizedString(val), val);
			}
		});
	}

	ngOnInit(): void {
		if (!this.flat) throw "FsgSelectAdvice component initialized without a valid flat object";
	}

	public readonly hasAdvice: () => boolean = () => {
		return this.flat.fsg_advice !== DEFAULT_ADVICE.defaults.get("DEFAULT");
	};
	public readonly isAdviceSelected: (val: string) => boolean = (val) => {
		return this.getValIndex.get(val) ? this.getValIndex.get(val) === this.flat.fsg_advice : val === this.flat.fsg_advice; // if custom value compare directly
	};
	public readonly getFlatAdvice: () => string = () => {
		return this.fsg.getAdviceValueAsLocalizedString(this.flat.fsg_advice);
	};

	public readonly toggleNewInput: (evt?: Event) => void = (evt?) => {
		this.openNewInput = !this.openNewInput;
		if (!this.openNewInput) this.newValue = "";
		if (evt) evt.stopPropagation();
	};

	public readonly newValueClickOut: Function = () => {
		if (this.newValue) {
			this.options.push(this.newValue);
			DEFAULT_ADVICE.values.push(this.newValue);
		}
		this.toggleNewInput();
	};

	public readonly optionClick: (val: string) => void = (val) => {
		this.flat.fsg_advice = this.flat.fsg_advice === val ? DEFAULT_ADVICE.defaults.get("DEFAULT")! : val;
	};
}
