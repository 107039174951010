<div class="area-card">
	<section>
		<div class="areatype-row">
			<div class="container" appScrollToElement [scrollIdentifierId]="areaType.id">
				<input
					class="blue-plain-input force-uppercase"
					[(ngModel)]="areaType.name"
					placeholder="{{ text().NAME }}"
					type="text"
				/>
			</div>

			<app-blue-dropdown
				class="dropdown container"
				[object]="areaType"
				[options]="types"
				[getObjectText]="typeDropdownGetMainText"
				[getOptionText]="typeDropdownGetOptionText"
				[compareSelect]="typeDropdownCompareSelect"
				(itemClickCallback)="typeDropdownChangeCallback($event)"
				[placeholderText]="text().TYPE"
			>
			</app-blue-dropdown>
		</div>

		<div class="areatype-row">
			<div (click)="$event.stopPropagation()">
				<div
					appDetectClickOut
					class="pattern-selector container"
					(click)="togglePatternSelect()"
					(callback)="openSubmenu = false"
					appSetStyle
					[ngStyle]="setPatternSelectBottomBorder"
				>
					<div
						class="pattern-option"
						appAreaPatternTypeOption
						[pattern]="getAreaPattern()"
						[color]="getAreaColor()"
					></div>

					<div class="dropdown-arrow-mask">
						<div class="dropdown-triangle {{ openSubmenu ? 'up' : 'down' }}"></div>
					</div>

					<div *ngIf="openSubmenu" class="pattern-menu">
						<div
							class="pattern-option"
							*ngFor="let pattern of areaPatterns"
							appAreaPatternTypeOption
							[pattern]="pattern"
							[color]="areaType.color"
							(click)="setPattern(pattern)"
						></div>
					</div>
				</div>
			</div>

			<div class="container relative">
				<input
					class="card-input force-uppercase area-card-input font-14"
					[(ngModel)]="areaType.color"
					placeholder="Colour"
					type="text"
					(click)="openColorPicker($event)"
				/>
				<div class="card-color-sample" appSetStyle [ngStyle]="getTypeColorAsBg()"></div>
				<div class="dropdown-arrow-mask" (click)="openColorPicker($event)">
					<div class="dropdown-triangle {{ showColorPicker ? 'up' : 'down' }}"></div>
				</div>
				<app-color-picker
					*ngIf="showColorPicker"
					(selectedColor)="changeColor($event)"
					[showPreferred]="true"
					[inputColor]="areaType.color"
					[closeCb]="closeColorPicker"
				></app-color-picker>
			</div>
		</div>
		<div>
			<span class="color-white font-size-16px"> {{ text().DETAILS }} </span>
			<textarea class="card-textarea-input area-textarea" [(ngModel)]="areaType.description">
			</textarea>
		</div>
	</section>
</div>
<button
	type="submit"
	*ngIf="hasAreaBeenModified()"
	class="main-blue-gradient-button card-bottom-button"
	[disabled]="!areaType.name"
	(click)="saveAreaType()"
>
	{{ text().SAVE }}
</button>
<button
	type="submit"
	*ngIf="canDelete"
	class="main-blue-gradient-button card-bottom-button"
	(click)="deleteAreaType()"
>
	{{ text().DELETE }}
</button>
