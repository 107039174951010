<div *ngIf="preplannedPoiTypes.length || poiTypes.length" class="poi-container">
	<div *ngIf="preplannedPoiTypes.length" class="section-poi">
		<div class="title padding-top-16">PP</div>
		<div class="list-container">
			<button
				data-cy="new-poi-pp"
				[attr.data-add-action-name]="'new-poi-' + type.name"
				*ngFor="let type of preplannedPoiTypes"
				class="poi-button"
				(click)="selectPoiType(type, $event)"
				(mouseover)="toggleSelectedTool(type)"
				(mouseout)="toggleSelectedTool()"
			>
				<svg
					class="svg-icon"
					[attr.width]="type.icon_size.x + 'px'"
					[attr.height]="type.icon_size.y + 'px'"
					[attr.viewBox]="getPoiTypeViewBox(type)"
				>
					<path [attr.d]="type.icon_path" [attr.fill]="type.color"></path>
				</svg>
			</button>
		</div>
	</div>

	<div *ngIf="poiTypes.length" class="section-poi">
		<div class="title padding-top-16">POI</div>
		<div class="list-container">
			<button
				data-cy="new-poi"
				[attr.data-add-action-name]="'new-poi-' + type.name"
				*ngFor="let type of poiTypes"
				class="poi-button"
				(click)="selectPoiType(type, $event)"
				(mouseover)="toggleSelectedTool(type)"
				(mouseout)="toggleSelectedTool()"
			>
				<svg
					class="svg-icon"
					[attr.width]="type.icon_size.x + 'px'"
					[attr.height]="type.icon_size.y + 'px'"
					[attr.viewBox]="getPoiTypeViewBox(type)"
				>
					<path [attr.d]="type.icon_path" [attr.fill]="type.color"></path>
				</svg>
			</button>
		</div>
	</div>
</div>

<div *ngIf="preplannedPoiTypes.length || poiTypes.length" class="section-bottom">
	<div class="bottom-container poi-bottom">
		<div *ngIf="selectedTool">
			<svg
				*ngIf="selectedTool.icon_size"
				class="svg-bottom-icon"
				[attr.viewBox]="getPoiTypeViewBox(selectedTool!)"
			>
				<path [attr.d]="selectedTool!.icon_path" [attr.fill]="selectedTool!.color"></path>
			</svg>
			<span class="info-text" *ngIf="selectedTool && selectedTool.name">
				{{ selectedTool!.name }}
			</span>
		</div>
		<div>
			<span class="info-text gray-100" *ngIf="selectedTool">
				{{ selectedTool!.is_global ? "PP" : "POI" }}
			</span>
		</div>
	</div>
</div>

<app-no-results *ngIf="!preplannedPoiTypes.length && !poiTypes.length"></app-no-results>
