import { Injectable } from "@angular/core";
import { WebRequestFactory } from "src/app/http/web.request.factory";
import { ResourceService } from "src/app/settings/resource/resource.service";
import { DTOArray } from "src/app/dto/net/dto-array";
import { Appliance } from "src/app/dto/resources/appliance";
import { ResourceLogEntry } from "src/app/dto/resources/resource-log-entry";
import { MESSAGE_TYPE } from "src/app/global/messaging/messages";
import { MessagingService } from "src/app/global/messaging/messaging.service";

@Injectable({
	providedIn: "root"
})
export class CalloutService {
	public resourceLog: Array<ResourceLogEntry>;

	private readonly wreq: WebRequestFactory;
	private readonly mssg: MessagingService;
	private readonly resources: ResourceService;

	constructor(wreq: WebRequestFactory, mssg: MessagingService, resources: ResourceService) {
		this.wreq = wreq;
		this.mssg = mssg;
		this.resources = resources;
		this.resourceLog = [];
	}

	public readonly updateResourceLog: Function = async () => {
		const jsonArray = await this.wreq.getAgentLog();
		DTOArray.UpdateFromJsonArray(this.resourceLog, jsonArray, ResourceLogEntry);
		this.attachAgentsToResourceLog();
		this.mssg.fire(MESSAGE_TYPE.LOAD_CALLOUT);
		return true;
	};

	private readonly attachAgentsToResourceLog: Function = () => {
		this.resourceLog.forEach(this.attachAgentToResourceLog as (value: ResourceLogEntry, index: number, array: ResourceLogEntry[]) => void);
	};

	private readonly attachAgentToResourceLog: Function = (resource: ResourceLogEntry) => {
		resource.__object = this.resources.Resources.find((e) => e.id == resource.id_agent) as Appliance;
	};
}
