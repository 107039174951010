import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
	selector: "app-white-cross-add",
	templateUrl: "white-cross-add.component.html",
	styleUrls: ["white-cross-add.css"]
})
export class WhiteCrossAddButtonComponent {
	@Output() clickCb = new EventEmitter<Event>();

	constructor() {}

	clickCallback: Function = (evt: Event) => {
		this.clickCb.emit(evt);
	};
}
