import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
	selector: "app-toggle-switch",
	templateUrl: "toggle-switch.component.html",
	styleUrls: ["toggle-switch.css"]
})
export class ToggleSwitchComponent {
	@Output() clickCb: EventEmitter<Event> = new EventEmitter();

	@Input() isDisabled: boolean = false;
	@Input() toggle: Function = () => false;

	get currentToggleState(): Function {
		return this.toggle();
	}
}
