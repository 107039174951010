import { AfterViewInit, Directive, ElementRef, Input } from "@angular/core";

@Directive({
	selector: "[appAutomaticScroll]"
})
export class AutomaticScrollDirective implements AfterViewInit {
	
	@Input() percentage: number = 1;

	private element: HTMLElement;

	constructor(el: ElementRef) {
		this.element = el.nativeElement;
	}

	ngAfterViewInit(): void {
		this.element.scrollTo(0, this.element.scrollHeight * this.percentage);
	}

}
