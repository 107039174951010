import { Directive, ElementRef } from "@angular/core";

@Directive({
	selector: "[appExtendToBottom]"
})
export class ExtendToBottomDirective {
	private elem: any;

	constructor(el: ElementRef) {
		let elem = el.nativeElement;
		elem.style.overflow = "auto";
		elem.style.overflowX = "hidden";
		this.elem = elem;
		window.addEventListener("resize", this.setHeights);

		setTimeout(() => this.setHeights(), 1);
	}

	private readonly setHeights = () => {
		const rect = this.elem.getBoundingClientRect();
		this.elem.style.height = window.innerHeight - rect.y + "px";
		this.elem.style.maxHeight = window.innerHeight - rect.y + "px";
	};
}
