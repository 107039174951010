import { Component, Input, Output, EventEmitter, OnInit } from "@angular/core";
import { TextProvider } from "src/app/global/constants/text/text-provider";
import { LocaleMap } from "src/app/global/constants/text/text-interface";
import { Resource } from "src/app/dto/resources/resource";
import { ResourceService } from "src/app/settings/resource/resource.service";
import { Personnel } from "src/app/dto/resources/personnel";
import { Appliance } from "src/app/dto/resources/appliance";
import { VideoFeedSlot } from "src/app/dto/ui/video-slot";
import { Camera } from "src/app/dto/resources/camera";
import { MESSAGE_TYPE } from "src/app/global/messaging/messages";
import { MessagingService } from "src/app/global/messaging/messaging.service";
import { IncidentService } from "src/app/incident/incident.service";

@Component({
	selector: "app-assign-camera-streaming-modal",
	templateUrl: "assign-camera-streaming.component.html",
	styleUrls: ["camera-streaming.css"]
})
export class AssignCameraStreamingComponent implements OnInit {
	@Input() user: Resource | undefined;

	@Output() closeCallback = new EventEmitter<void>();
	@Output() selectAgentCallback = new EventEmitter<Resource>();
	@Output() selectedAgent = new EventEmitter<string>();
	@Output() selectedCam = new EventEmitter<string>();

	public readonly text: () => LocaleMap;
	public mission_id: number = -1;
	public agentsWithCam: Array<Resource> = [];
	public cameraSelected: Camera | undefined;
	public slot: VideoFeedSlot | undefined;

	constructor(private textProvider: TextProvider, private resServ: ResourceService, private incidentService: IncidentService, private mssg: MessagingService) {
		this.text = this.textProvider.getStringMap;
		this.mission_id = this.incidentService.getCurrentIncident()!.id;
		this.mssg.registerListener(MESSAGE_TYPE.UPDATE_AGENT, this.getAgentsWithCam);
	}
	ngOnInit(): void {
		this.getAgentsWithCam();
	}

	public readonly close: Function = () => {
		this.closeCallback.emit();
	};
	public readonly selectSlot: (agent: Resource) => void = (agent: Resource) => {
		this.user = agent;
		this.cameraSelected = agent.__camera ? agent.__camera : (agent.__camera = new Camera(-1, "", "resourcefeed-" + agent.id, -1, "", "", agent.timestamp, undefined));
		this.selectAgentCallback.emit(agent);
	};

	public getAgentsWithCam: () => Resource[] = () => {
		return (this.agentsWithCam = this.resServ.Resources.filter((e) => {
			return !e.deleted && e.id_incident === this.mission_id && ((e instanceof Appliance && e.id_camera > -1) || e instanceof Personnel);
		}));
	};

	public getCameraName: (agent: Resource) => string = (agent: Resource) => {
		if (agent.id_camera != -1 && agent.__camera) {
			return agent.__camera.milestone_name ? agent.__camera.milestone_name : agent.__camera.ims_name;
		} else return "Tactics app";
	};
}
