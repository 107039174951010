import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
	name: "arraySanitizer"
})
export class ArraySanitizerPipe implements PipeTransform {
	transform(items: any[]): any[] {
		return items.filter(Boolean);
	}
}
