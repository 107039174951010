import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { CSSector } from "src/app/dto/command-structure/cs-sector";
import { LocaleMap } from "src/app/global/constants/text/text-interface";
import { TextProvider } from "src/app/global/constants/text/text-provider";
import { UserService } from "src/app/settings/user/user.service";
import { AraService } from "../../ara.service";
import { FUNCTIONALITY } from "src/app/global/functionality.service";
import { FunctionalityService } from "src/app/global/functionality.service";

@Component({
	selector: "app-ara-screen",
	templateUrl: "ara.component.html",
	styleUrls: ["ara.css"]
})
export class AraComponent implements OnInit {
	@Output() openReviewEmit = new EventEmitter();

	public confirmationDialogParams: any = {};
	public readonly text: () => LocaleMap;
	public araRight: boolean = true;
	public araScore: boolean = true;
	public isFuncAvailable = new Map<FUNCTIONALITY, boolean>();

	private readonly userService: UserService;
	private readonly funcService: FunctionalityService;

	constructor(textProv: TextProvider, userS: UserService, private araService: AraService, funcService: FunctionalityService) {
		this.text = textProv.getStringMap;
		this.userService = userS;
		this.funcService = funcService;
	}
	ngOnInit(): void {
		this.isFuncAvailable = this.funcService.isFunctionalityAvailable;
		this.getFunctionalities();
	}

	public getFunctionalities(): void {
		this.araRight = this.isFuncAvailable.get(FUNCTIONALITY.ARA_RIGHT)!;
		this.araScore = this.isFuncAvailable.get(FUNCTIONALITY.ARA_SCORE)!;
	}
	get canCreateAra(): boolean {
		return this.userService.getCurrentUserLevel().create_review_ara;
	}

	get currentARAScreen(): ARA_SCREENS {
		return this.araService.currentAraScreen;
	}

	set currentARAScreen(screen: ARA_SCREENS) {
		this.araService.currentAraScreen = screen;
	}

	get currentSector(): CSSector {
		return this.araService.currentSector;
	}

	set currentSector(sector: CSSector) {
		this.araService.currentSector = sector;
	}

	public openCreateScreen(sector: CSSector): void {
		this.currentSector = sector ?? new CSSector(-1, -1, -1, "");
		this.araService.hasARAbeenModified(this.currentSector.id) ? this.openConfirmationModal(this.currentSector.id) : (this.currentARAScreen = ARA_SCREENS.CREATE);
	}

	public goToHome(): void {
		this.confirmationDialogParams.show = false;
		this.currentARAScreen = ARA_SCREENS.HOME;
	}

	public openConfirmationModal(idSector: number): void {
		this.confirmationDialogParams = {
			show: true,
			header: this.text().DRAFT_AVAILABLE,
			body: idSector && idSector > -1 ? this.text().ARA_SECTOR_DRAFT : this.araRight || this.araScore ? this.text().ARA_DRAFT_DIALOG_TEXT : this.text().TM_DRAFT_DIALOG_TEXT,
			confirmText: this.text().CONTINUE,
			cancelText: this.text().DISCARD,
			cancelCallback: () => {
				this.confirmationDialogParams.show = false;
				this.araService.resetCurrentARA(idSector);
				this.currentARAScreen = ARA_SCREENS.CREATE;
			},
			confirmCallback: () => {
				this.confirmationDialogParams.show = false;
				this.currentARAScreen = ARA_SCREENS.CREATE;
			}
		};
	}
}

export enum ARA_SCREENS {
	HOME,
	CREATE
}
