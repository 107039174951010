<div class="main-black-mask" (click)="closeCallback.emit(); $event.stopPropagation()"></div>
<div class="alert-dialog" data-cy="error-dialog">
	<app-white-cross class="dialog-cross-button" (clickCb)="closeCallback.emit()"></app-white-cross>
	<div class="dialog-header">
		{{ header }}
	</div>
	<div class="dialog-icon">
		<img src="resources/img/warning-icon.svg" />
	</div>
	<div class="dialog-body">
		{{ body }}
		<span *ngIf="isSaveError()">
			<span
				class="support-channel pointer"
				(click)="openSupportMenu(); $event.preventDefault()"
				>{{ text().SUPPORT }}</span
			>
			{{ text().SERVER_ERROR_MESSAGE_3 }}
		</span>
	</div>
</div>
