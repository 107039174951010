import { Component, EventEmitter, Output, OnInit, Input, OnChanges, SimpleChanges } from "@angular/core";
import { PoiType } from "src/app/dto/items/types/poi-type";
import { ConfigurationService } from "src/app/settings/types/configuration.service";

@Component({
	selector: "app-body-poi",
	templateUrl: "app-body-poi.component.html",
	styleUrls: ["app-body-poi.css"]
})
export class BodyPoiComponent implements OnInit, OnChanges {
	@Input() searchQuery: string | null = "";
	@Output() drawPoi = new EventEmitter<PoiType>();

	public selectedTool: PoiType | undefined;
	public poiTypes: Array<PoiType> = [];
	public preplannedPoiTypes: Array<PoiType> = [];

	private readonly conf: ConfigurationService;

	constructor(conf: ConfigurationService) {
		this.conf = conf;
	}

	ngOnInit(): void {
		this.resetAllTypes();
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.searchQuery) {
			this.searchArrays();
		}
	}

	public selectPoiType(type: PoiType, event: Event): any {
		event.stopPropagation();
		this.drawPoi.emit(type);
	}

	public toggleSelectedTool(hoveredElement?: PoiType): void {
		this.selectedTool = hoveredElement || undefined;
	}

	public getPoiTypeViewBox(type: PoiType): string {
		return `0 0 ${type.icon_size.x} ${type.icon_size.y}`;
	}

	public searchArrays(): void {
		if (!this.searchQuery) this.resetAllTypes();

		if (this.searchQuery) {
			const searchValue = this.searchQuery.toLocaleUpperCase();
			const filterFunction = (type: any) => type.name.toLocaleUpperCase().includes(searchValue);

			this.poiTypes = this.poiTypes.filter(filterFunction);
			this.preplannedPoiTypes = this.preplannedPoiTypes.filter(filterFunction);
		}
	}

	private resetAllTypes(): void {
		this.poiTypes = this.conf.configuration.poiTypes.filter((e) => !e.is_global && !e.specialType);
		this.preplannedPoiTypes = this.conf.configuration.poiTypes.filter((e) => e.is_global && !e.specialType);
	}
}
