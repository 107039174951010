<div #matrix class="sl-matrix">
	<div class="cell white"><div>5</div></div>
	<div data-add-action-name="risk-rating-cell-1-5" class="cell yellow {{ isActive(1, 5) ? 'active' : '' }}" (click)="clickCell(1, 5)">
		<div class="{{ isActive(1, 5) ? 'border' : '' }}">5</div>
	</div>
	<div data-add-action-name="risk-rating-cell-2-5" class="cell yellow {{ isActive(2, 5) ? 'active' : '' }}" (click)="clickCell(2, 5)">
		<div class="{{ isActive(2, 5) ? 'border' : '' }}">10</div>
	</div>
	<div class="cell red    {{ isActive(3, 5) ? 'active' : '' }}" (click)="clickCell(3, 5)">
		<div class="{{ isActive(3, 5) ? 'border' : '' }}">15</div>
	</div>
	<div class="cell red    {{ isActive(4, 5) ? 'active' : '' }}" (click)="clickCell(4, 5)">
		<div class="{{ isActive(4, 5) ? 'border' : '' }}">20</div>
	</div>
	<div class="cell red    {{ isActive(5, 5) ? 'active' : '' }}" (click)="clickCell(5, 5)">
		<div class="{{ isActive(5, 5) ? 'border' : '' }}">25</div>
	</div>
	<div class="cell white"><div>4</div></div>
	<div class="cell green  {{ isActive(1, 4) ? 'active' : '' }}" (click)="clickCell(1, 4)">
		<div class="{{ isActive(1, 4) ? 'border' : '' }}">4</div>
	</div>
	<div class="cell yellow {{ isActive(2, 4) ? 'active' : '' }}" (click)="clickCell(2, 4)">
		<div class="{{ isActive(2, 4) ? 'border' : '' }}">8</div>
	</div>
	<div class="cell red    {{ isActive(3, 4) ? 'active' : '' }}" (click)="clickCell(3, 4)">
		<div class="{{ isActive(3, 4) ? 'border' : '' }}">12</div>
	</div>
	<div class="cell red    {{ isActive(4, 4) ? 'active' : '' }}" (click)="clickCell(4, 4)">
		<div class="{{ isActive(4, 4) ? 'border' : '' }}">16</div>
	</div>
	<div class="cell red    {{ isActive(5, 4) ? 'active' : '' }}" (click)="clickCell(5, 4)">
		<div class="{{ isActive(5, 4) ? 'border' : '' }}">20</div>
	</div>
	<div class="cell white"><div>3</div></div>
	<div class="cell green  {{ isActive(1, 3) ? 'active' : '' }}" (click)="clickCell(1, 3)">
		<div class="{{ isActive(1, 3) ? 'border' : '' }}">3</div>
	</div>
	<div class="cell yellow {{ isActive(2, 3) ? 'active' : '' }}" (click)="clickCell(2, 3)">
		<div class="{{ isActive(2, 3) ? 'border' : '' }}">6</div>
	</div>
	<div class="cell yellow {{ isActive(3, 3) ? 'active' : '' }}" (click)="clickCell(3, 3)">
		<div class="{{ isActive(3, 3) ? 'border' : '' }}">9</div>
	</div>
	<div class="cell red    {{ isActive(4, 3) ? 'active' : '' }}" (click)="clickCell(4, 3)">
		<div class="{{ isActive(4, 3) ? 'border' : '' }}">12</div>
	</div>
	<div class="cell red    {{ isActive(5, 3) ? 'active' : '' }}" (click)="clickCell(5, 3)">
		<div class="{{ isActive(5, 3) ? 'border' : '' }}">15</div>
	</div>
	<div class="cell white"><div>2</div></div>
	<div class="cell green  {{ isActive(1, 2) ? 'active' : '' }}" (click)="clickCell(1, 2)">
		<div class="{{ isActive(1, 2) ? 'border' : '' }}">2</div>
	</div>
	<div class="cell green  {{ isActive(2, 2) ? 'active' : '' }}" (click)="clickCell(2, 2)">
		<div class="{{ isActive(2, 2) ? 'border' : '' }}">4</div>
	</div>
	<div class="cell yellow {{ isActive(3, 2) ? 'active' : '' }}" (click)="clickCell(3, 2)">
		<div class="{{ isActive(3, 2) ? 'border' : '' }}">6</div>
	</div>
	<div class="cell yellow {{ isActive(4, 2) ? 'active' : '' }}" (click)="clickCell(4, 2)">
		<div class="{{ isActive(4, 2) ? 'border' : '' }}">8</div>
	</div>
	<div class="cell yellow {{ isActive(5, 2) ? 'active' : '' }}" (click)="clickCell(5, 2)">
		<div class="{{ isActive(5, 2) ? 'border' : '' }}">10</div>
	</div>
	<div class="cell white"><div>1</div></div>
	<div class="cell green  {{ isActive(1, 1) ? 'active' : '' }}" (click)="clickCell(1, 1)">
		<div class="{{ isActive(1, 1) ? 'border' : '' }}">1</div>
	</div>
	<div class="cell green  {{ isActive(2, 1) ? 'active' : '' }}" (click)="clickCell(2, 1)">
		<div class="{{ isActive(2, 1) ? 'border' : '' }}">2</div>
	</div>
	<div class="cell green  {{ isActive(3, 1) ? 'active' : '' }}" (click)="clickCell(3, 1)">
		<div class="{{ isActive(3, 1) ? 'border' : '' }}">3</div>
	</div>
	<div class="cell green  {{ isActive(4, 1) ? 'active' : '' }}" (click)="clickCell(4, 1)">
		<div class="{{ isActive(4, 1) ? 'border' : '' }}">4</div>
	</div>
	<div class="cell yellow {{ isActive(5, 1) ? 'active' : '' }}" (click)="clickCell(5, 1)">
		<div class="{{ isActive(5, 1) ? 'border' : '' }}">5</div>
	</div>
	<div class="cell white"><div>X</div></div>
	<div class="cell white"><div>1</div></div>
	<div class="cell white"><div>2</div></div>
	<div class="cell white"><div>3</div></div>
	<div class="cell white"><div>4</div></div>
	<div class="cell white"><div>5</div></div>
</div>
