import { WGSPoint } from "../map/location";
import { Orientation } from "../map/orientation";
import { HARDWARE_TYPE, Hardware } from "./hardware";

export class Camera implements Hardware {
	id: number;
	milestone_name: string;
	id_type: number;
	url: string;
	rtmp_url: string;
	timestamp: number;
	rtsp_url: string;
	ims_name: string;
	name: string;
	description: string;
	station: string;

	type = HARDWARE_TYPE.CAMERA;

	constructor(id: number, milestone_name: string, ims_name: string, id_type: number, url?: string, rtmp_url?: string, timestamp?: number, rtsp_url?: string, name?: string, description?: string, station?: string) {
		this.id = id;
		this.milestone_name = milestone_name;
		this.id_type = id_type;
		this.url = url ? url : "";
		this.rtmp_url = rtmp_url ? rtmp_url : "";
		this.timestamp = timestamp ? timestamp : Date.now();
		this.rtsp_url = rtsp_url ? rtsp_url : "";
		this.ims_name = ims_name ? ims_name : "";
		this.name = name ? name : "";
		this.description = description ?? "";
		this.station = station ?? "";
	}

	public static readonly fromJson: (json: string) => Camera = (json) => {
		var jc = JSON.parse(json);

		return new Camera(jc.id, jc.milestone_name, jc.ims_name, jc.id_type, jc.url, jc.rtmp_url, jc.timestamp, jc.rtsp_url, jc.name, jc.description, jc.station);
	};
}
