import { MapItem } from "./map-item";

export class ChangeItem {
	elementId: number;
	type: string;
	oldValue: MapItem | null;

	constructor(id: number, itemType: string, oldValue?: MapItem) {
		this.elementId = id;
		this.type = itemType;
		this.oldValue = oldValue ? oldValue : null;
	}
}
