<div class="main-black-mask" (click)="_cancel()"></div>
<div class="container">
	<div class="header">
		<span>{{ text().RISK_SCORE_AND_RATING }}</span>
	</div>
	<div class="body">
		<span>{{ text().RISK_RATING_MODAL_SELECT_TEXT }}</span>
		<div class="list-container">
			<div class="list">
				<div class="list-header">
					{{ text().SEVERITY_S }}
				</div>
				<div class="list-item" (click)="setSeverity(1)">
					<app-round-checkbox [check]="isSeverityActive(1)"></app-round-checkbox>
					<span class="{{ isSeverityActive(1) ? 'selected' : '' }}">{{
						text().RISK_RATING_MODAL_SEVERITY_1
					}}</span>
				</div>
				<div class="list-item" (click)="setSeverity(2)">
					<app-round-checkbox [check]="isSeverityActive(2)"></app-round-checkbox>
					<span class="{{ isSeverityActive(2) ? 'selected' : '' }}">{{
						text().RISK_RATING_MODAL_SEVERITY_2
					}}</span>
				</div>
				<div class="list-item" (click)="setSeverity(3)">
					<app-round-checkbox [check]="isSeverityActive(3)"></app-round-checkbox>
					<span class="{{ isSeverityActive(3) ? 'selected' : '' }}">{{
						text().RISK_RATING_MODAL_SEVERITY_3
					}}</span>
				</div>
				<div class="list-item" (click)="setSeverity(4)">
					<app-round-checkbox [check]="isSeverityActive(4)"></app-round-checkbox>
					<span class="{{ isSeverityActive(4) ? 'selected' : '' }}">{{
						text().RISK_RATING_MODAL_SEVERITY_4
					}}</span>
				</div>
				<div class="list-item" (click)="setSeverity(5)">
					<app-round-checkbox [check]="isSeverityActive(5)"></app-round-checkbox>
					<span class="{{ isSeverityActive(5) ? 'selected' : '' }}">{{
						text().RISK_RATING_MODAL_SEVERITY_5
					}}</span>
				</div>
			</div>
			<div class="list">
				<div class="list-header">
					{{ text().LIKELIHOOD_L }}
				</div>
				<div class="list-item" (click)="setLikelihood(1)">
					<app-round-checkbox [check]="isLikelihoodActive(1)"></app-round-checkbox>
					<span class="{{ isLikelihoodActive(1) ? 'selected' : '' }}">{{
						text().RISK_RATING_MODAL_LIKELIHOOD_1
					}}</span>
				</div>
				<div class="list-item" (click)="setLikelihood(2)">
					<app-round-checkbox [check]="isLikelihoodActive(2)"></app-round-checkbox>
					<span class="{{ isLikelihoodActive(2) ? 'selected' : '' }}">{{
						text().RISK_RATING_MODAL_LIKELIHOOD_2
					}}</span>
				</div>
				<div class="list-item" (click)="setLikelihood(3)">
					<app-round-checkbox [check]="isLikelihoodActive(3)"></app-round-checkbox>
					<span class="{{ isLikelihoodActive(3) ? 'selected' : '' }}">{{
						text().RISK_RATING_MODAL_LIKELIHOOD_3
					}}</span>
				</div>
				<div class="list-item" (click)="setLikelihood(4)">
					<app-round-checkbox [check]="isLikelihoodActive(4)"></app-round-checkbox>
					<span class="{{ isLikelihoodActive(4) ? 'selected' : '' }}">{{
						text().RISK_RATING_MODAL_LIKELIHOOD_4
					}}</span>
				</div>
				<div class="list-item" (click)="setLikelihood(5)">
					<app-round-checkbox [check]="isLikelihoodActive(5)"></app-round-checkbox>
					<span class="{{ isLikelihoodActive(5) ? 'selected' : '' }}">{{
						text().RISK_RATING_MODAL_LIKELIHOOD_5
					}}</span>
				</div>
			</div>
			<div class="map-wrapper">
				<span></span>
				<app-sl-matrix (cellClick)="setSL($event)"></app-sl-matrix>
				<div class="severity-axis">{{ text().SEVERITY_S }}</div>
				<div class="likelihood-axis">{{ text().LIKELIHOOD_L }}</div>
			</div>
		</div>
		<div class="chip-container">
			<div class="chip green {{ getRating() > 0 && getRating() < 5 ? 'active' : '' }}">
				{{ text().RISK_RATING_MODAL_GREEN_DESCRIPTOR }}
			</div>
			<div class="chip yellow {{ getRating() > 4 && getRating() < 12 ? 'active' : '' }}">
				{{ text().RISK_RATING_MODAL_YELLOW_DESCRIPTOR }}
			</div>
			<div class="chip red {{ getRating() > 11 ? 'active' : '' }}">
				{{ text().RISK_RATING_MODAL_RED_DESCRIPTOR }}
			</div>
		</div>
	</div>
	<div class="footer">
		<button
			data-add-action-name="ara-confirm-rating"
			[disabled]="!canConfirm()"
			class="main-blue-gradient-button"
			(click)="confirm()"
		>
			{{ text().CONFIRM }}
		</button>
	</div>
	<app-white-cross (clickCb)="_cancel()"></app-white-cross>
</div>
