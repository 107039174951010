import { Observable } from "rxjs";
import { MenuService } from "../menu.service";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { LocaleMap } from "src/app/global/constants/text/text-interface";
import { TextProvider } from "src/app/global/constants/text/text-provider";
import { FORM_SCREENS } from "src/app/incident/incident-tools/forms/screens.enum";
import { NavbarItem } from "../left-nav-menu.component";

@Component({
	selector: "app-fixed-bar",
	templateUrl: "./fixed-bar.component.html",
	styleUrls: ["../left-nav-menu.component.css", "./fixed-bar.component.css"]
})
export class FixedBarComponent {
	@Input() items = new Array<NavbarItem>();
	@Input() extended!: boolean;
	@Input() canEditIncidents!: boolean;
	@Input() isInHistoric!: boolean;

	@Output() openCb = new EventEmitter();
	@Output() reopenIncident = new EventEmitter<void>();
	@Output() goToForm = new EventEmitter<FORM_SCREENS>();

	public readonly text: () => LocaleMap;
	public isIpad = false;

	private readonly menuService: MenuService;

	constructor(tp: TextProvider, menu: MenuService) {
		this.text = tp.getStringMap;
		this.menuService = menu;

		this.isIpad = /iPad/.test(navigator.userAgent) || (/Macintosh/.test(navigator.userAgent) && ("ontouchend" in document || navigator.maxTouchPoints > 1));
	}

	public readonly getActiveState = (item: NavbarItem): boolean => {
		return item.isActive();
	};

	public readonly getHoveredState = (item: NavbarItem): boolean => {
		return item.isHovered();
	};

	public readonly getNotification = (item: NavbarItem): boolean => {
		return item.hasNotification();
	};

	public readonly hasTool = (tool: string): boolean => {
		return this.items.some((item: NavbarItem) => item.title === tool);
	};

	public zIndex(): Observable<string> {
		return this.menuService.sideMenuShortZIndex.asObservable();
	}
}
