<div
	*ngIf="resource"
	[ngClass]="{ 'resource-item': true, drag: draggingPersonnel }"
	[attr.applianceid]="resource.id"
	dropzone="applianceResources"
>
	<div
		class="resource-wrapper"
		[class.cursor-pointer]="hasPersonnel(resource)"
		(touchstart)="onTouchStart($event)"
		(touchmove)="onTouchMove($event)"
		(touchend)="onTouchEnd($event)"
		(mousedown)="onMouseDown($event)"
		(mousemove)="onMouseMove($event)"
		(mouseup)="onMouseUp($event)"
	>
		<div
			[ngClass]="{ 'resource-name-container appliance': true, drag: draggingPersonnel }"
			appDroppable
			[disabled]="disabled"
			[dragInfo]="resource.id"
			(dragStart)="onDragStart($event)"
			(dragCancel)="onDragCancel($event)"
			(dragEnd)="onDragEnd($event)"
		>
			<div class="status-display" appSetStyle [ngStyle]="setStatusBackground(resource)"></div>
			<div class="appliance-name">{{ resource.name }}</div>
		</div>

		<div *ngIf="hasPersonnel(resource)" class="appliance-caret-container">
			<div [ngClass]="{ 'main-triangle': true, up: expanded, down: !expanded }"></div>
		</div>
	</div>

	<div
		class="personnel-container"
		*ngIf="expanded && matchesFilter()"
		(touchstart)="onTouchStart($event)"
		(touchmove)="onTouchMove($event)"
		(touchend)="onTouchEnd($event)"
	>
		<div
			*ngFor="
				let person of incidentClosed
					? filterClosedPersonnelList(resource)
					: getPersonnel(resource);
				let k = index
			"
			class="resource-item personnel-list"
			data-cy="appliancePersonnel"
		>
			<div class="personnel-data">
				<div class="resource-name-container personnel-name-container">
					<div
						class="status-display circle"
						appSetStyle
						[ngStyle]="setStatusBackground(person)"
					></div>
					<span *ngIf="isOic(resource, person)" class="oic-icon">OIC</span>
					<div
						class="resource-name"
						[ngClass]="{
							'oic-personnel': isOic(resource, person),
							personnel: !isOic(resource, person)
						}"
						appDroppable
						[disabled]="disabled"
						[dragInfo]="person.id"
						(dragStart)="onDragStart($event)"
						(dragCancel)="onDragCancel($event)"
						(dragEnd)="onDragEnd($event)"
					>
						{{ person.name }}
					</div>
				</div>
				<app-skills-display
					*ngIf="shouldDisplaySkills(person, resource.__object)"
					[resource]="person"
					[size]="20"
					[shouldShowTooltip]="false"
					[shownSkillIndexes]="shownSkillIndexes"
					(toggleSkillsOverflowEvent)="toggleSkills(k)"
				>
				</app-skills-display>
			</div>
		</div>
	</div>
</div>
