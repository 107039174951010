import { SafeResourceUrl } from "@angular/platform-browser";
import { DTO } from "../net/dto";

export class DecisionFile implements DTO {
	id: number;
	id_decision: number;
	id_file: number;
	filename: string;

	format: string | undefined;

	__uri: string | SafeResourceUrl | undefined;

	timestamp = Date.now();
	constructor(id: number, id_d: number, id_f: number, fn?: string) {
		this.id = id;
		this.id_decision = id_d;
		this.id_file = id_f;
		this.filename = fn ? fn : "";
		this.format = (this.filename.split(".").pop() ?? "").toLowerCase();
	}

	public static readonly fromJson: (json: string) => DecisionFile = (json: string) => {
		var jc = JSON.parse(json);
		return new DecisionFile(jc.id, jc.id_decision, jc.id_file, jc.filename);
	};

	public readonly getJson: () => string = () => {
		return JSON.stringify(this, ["id", "id_decision", "id_file", "filename"]);
	};

	public readonly isImage = (): boolean => {
		//html img supported formats
		return this.format === "jpg" || this.format === "jpeg" || this.format === "png" || this.format === "svg" || this.format === "gif" || this.format === "jfif" || this.format === "pjpeg" || this.format === "pjp" || this.format === "apng" || this.format === "ico" || this.format === "cur";
	};

	public readonly isVideo = (): boolean => {
		return this.format === "mov" || this.format === "mp4" || this.format === "wmw" || this.format === "webm" || this.format === "flv" || this.format === "avi";
	};

	public readonly isAudio = (): boolean => {
		return this.format === "wav" || this.format === "m4a" || this.format === "ogg" || this.format === "mp3" || this.format === "flac" || this.format === "wma" || this.format === "aac" || this.format === "aif";
	};
}
