import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
	name: "highlight"
})
export class HighlightPipe implements PipeTransform {
	transform(value: string, searchTerm: string): string {
		if (!searchTerm || searchTerm === "") {
			return value;
		}

		const searchRegex = new RegExp(this.escapeRegExp(searchTerm), "gi");
		return value.replace(searchRegex, (match) => `<mark>${match}</mark>`);
	}

	private escapeRegExp(string: string): string {
		return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
	}
}
