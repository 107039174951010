<div class="main-black-mask" (click)="close()"></div>
<div class="main-container">
	<app-black-nav-tab
		[tabs]="navTabs"
		[getActiveTab]="getActiveTab"
		[hasNavigation]="true"
	></app-black-nav-tab>
	<div class="filter-header">
		<app-blue-checkbox
			[check]="isEveryoneChecked"
			(clickCb)="setGeneralCheck($event)"
		></app-blue-checkbox>
		<app-plain-input-search
			class="search-input"
			[alwaysOpen]="true"
			[onChangeCb]="searchUpdate"
			[placeholderText]="text().NAME"
		></app-plain-input-search>
		<div class="filter-container">
			<app-transparent-dropdown
				class="dropdown"
				[placeholderText]="text().SKILLS"
				[object]="skillQuery"
				[options]="skills"
				[getObjectText]="skillDropdownGetMainText"
				[getOptionText]="skillDropdownGetOptionText"
				[compareSelect]="skillDropdownCompareSelect"
				(itemClickCallback)="skillDropdownChangeCallback($event)"
			>
			</app-transparent-dropdown>
		</div>
		<div class="filter-container">
			<app-transparent-dropdown
				class="dropdown"
				[placeholderText]="text().ROLE"
				[object]="typeQuery"
				[options]="types"
				[getObjectText]="typeDropdownGetMainText"
				[getOptionText]="typeDropdownGetOptionText"
				[compareSelect]="typeDropdownCompareSelect"
				(itemClickCallback)="typeDropdownChangeCallback($event)"
			>
			</app-transparent-dropdown>
		</div>
		<div class="filter-container">
			<app-transparent-dropdown
				class="dropdown"
				[placeholderText]="text().STATION"
				[object]="stationQuery"
				[options]="stations"
				[getObjectText]="stationDropdownGetMainText"
				[getOptionText]="stationDropdownGetOptionText"
				[compareSelect]="stationDropdownCompareSelect"
				(itemClickCallback)="stationDropdownChangeCallback($event)"
			>
			</app-transparent-dropdown>
		</div>
	</div>
	<div class="list-body">
		<div
			class="list-item {{ resource.isResourceChecked() ? 'active' : '' }}"
			*ngFor="let resource of filteredResources"
			(click)="resource.checkResource()"
		>
			<app-blue-checkbox [check]="resource.isResourceChecked"></app-blue-checkbox>
			<div class="item-name">
				<div>
					<div class="color-ball {{ resource.available ? 'green' : 'red' }}"></div>
					{{ resource.name }}
				</div>
			</div>
			<div class="skill-list">
				<div class="text-container">
					<li class="skill-item" *ngFor="let skill of resource.skills">
						<img
							title="{{ skill.name }}"
							src="{{ 'resources/img/icons-skills/' + skill.name + '.png' }}"
						/>
					</li>
				</div>
			</div>
			<div class="role-name">
				<div class="text-container">{{ resource.role }}</div>
			</div>
			<div class="station-name">
				<div class="text-container">{{ resource.station }}</div>
			</div>
		</div>
	</div>
	<div class="footer">
		<div class="container">
			<button
				data-add-action-name="skip-button"
				class="main-blue-gradient-border-button"
				(click)="skip()"
			>
				{{ text().SKIP }}
			</button>
			<button
				data-add-action-name="assign-button"
				class="main-blue-gradient-button"
				(click)="assign()"
			>
				{{ text().ASSIGN }}
			</button>
		</div>
	</div>
</div>
