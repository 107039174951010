import { Component, Input, Output, EventEmitter, ViewChild, ElementRef } from "@angular/core";
import { ResourceSkill } from "src/app/dto/resources/skill";

@Component({
	selector: "app-skills-display",
	templateUrl: "skills-display.component.html",
	styleUrls: ["skills-display.css"]
})
export class SkillsDisplayComponent {
	@Input() resource!: any;
	@Input() shownSkillIndexes = new Map<number, boolean>();
	@Input() outerIndex: number = 0;
	@Input() size: number = 24;
	@Input() disabled: boolean = false;
	@Input() overflowSkillsVisible: boolean = false;
	@Input() shouldShowTooltip: boolean = true;

	@Output() toggleSkillsOverflowEvent = new EventEmitter<number>();

	@ViewChild("skillsContainer") skillsContainerRef!: ElementRef<HTMLDivElement>;

	public currentTooltipIndex: number | null = null;
	public tooltipPosition = { top: 0, left: 0 };

	public toggleSkillsOverflow(index: number): void {
		if (!this.disabled) {
			this.toggleSkillsOverflowEvent.emit();
			this.overflowSkillsVisible = !this.overflowSkillsVisible;
		}
	}

	public closeSkills(): void {
		this.overflowSkillsVisible = false;
	}

	public hasSkills(): boolean {
		return !!this.resource?.skills;
	}

	public getImageUrl(skillName: string): string {
		return `resources/img/icons-skills/${skillName}.png`;
	}

	public toggleTooltip(index: number, event: MouseEvent | TouchEvent): void {
		if (this.skillsContainerRef && this.skillsContainerRef.nativeElement) {
			const isTooltipShown = this.shownSkillIndexes.get(index) || false;
			this.shownSkillIndexes.clear();
			if (isTooltipShown) {
				this.hideTooltip();
			} else {
				this.showTooltip(index, event);
			}
		}
	}

	public showTooltip(index: number, event: MouseEvent | TouchEvent): void {
		const skillElement = (event.target as HTMLElement).closest(".skill-item");
		if (skillElement) {
			const skillRect = skillElement.getBoundingClientRect();
			this.tooltipPosition = {
				top: skillRect.bottom + 8,
				left: skillRect.left
			};
			this.currentTooltipIndex = index;
			this.shownSkillIndexes.set(index, true);
		} else {
			this.hideTooltip();
		}
	}

	public hideTooltip(): void {
		if (this.skillsContainerRef && this.skillsContainerRef.nativeElement) {
			this.currentTooltipIndex = null;
			this.shownSkillIndexes.clear();
		}
	}
	public getSkillNameByIndex(index: number): string {
		return this.resource?.skills?.[index]?.name || "";
	}

	public getGlobalIndex(index: number, isOverflow: boolean): number {
		return isOverflow ? this.getInitialSkills.length + index : index;
	}

	get getInitialSkills(): ResourceSkill[] {
		return this.resource.skills?.slice(0, 3) || [];
	}

	get getOverflowSkills(): ResourceSkill[] {
		return this.resource.skills?.slice(3) || [];
	}
}
