<div class="main-transparent-mask" (click)="close(); $event.stopPropagation()"></div>

<div class="items">
	<div
		class="menu-item"
		(mouseover)="downloadIconUrl = 'resources/img/drawing-item-icons/download_arrow_hover.svg'"
		(mouseleave)="downloadIconUrl = 'resources/img/drawing-item-icons/download_arrow.svg'"
		(click)="downloadOption()"
	>
		<span>
			<img [src]="downloadIconUrl" alt="Download" />
		</span>
		<span class="menu-item-text">{{ text().DOWNLOAD }}</span>
	</div>

	<div
		class="menu-item"
		(mouseover)="deleteIconUrl = 'resources/img/drawing-item-icons/delete_hover.svg'"
		(mouseleave)="deleteIconUrl = 'resources/img/delete.svg'"
		(click)="deleteOption()"
	>
		<span>
			<img [src]="deleteIconUrl" alt="Delete" />
		</span>
		<span class="menu-item-text">{{ text().DELETE }}</span>
	</div>
</div>
