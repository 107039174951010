import Point from "@arcgis/core/geometry/Point";
import { MAP_ITEM_TYPE } from "src/app/global/constants/enums/map-item-type";

export enum ESRI_BASEMAPS_FREE {
	NONE = "none",
	SATELLITE = "satellite",
	HYBRID = "hybrid",
	OCEANS = "oceans",
	// OSM = "osm",
	TERRAIN = "terrain",
	DARK_GRAY_VECTOR = "dark-gray-vector",
	GRAY_VECTOR = "gray-vector",
	STREETS_VECTOR = "streets-vector",
	STREETS_NIGHT_VECTOR = "streets-night-vector",
	STREETS_NAVIGATION_VECTOR = "streets-navigation-vector",
	TOPO_VECTOR = "topo-vector",
	STREETS_RELIEF_VECTOR = "streets-relief-vector"
}
export enum ESRI_BASEMAPS_KEY {
	ARCGIS_IMAGERY = "arcgis-imagery",
	ARCGIS_IMAGERY_STANDARD = "arcgis-imagery-standard",
	ARCGIS_IMAGERY_LABELS = "arcgis-imagery-labels",
	ARCGIS_LIGHT_GRAY = "arcgis-light-gray",
	ARCGIS_DARK_GRAY = "arcgis-dark-gray",
	ARCGIS_NAVIGATION = "arcgis-navigation",
	ARCGIS_NAVIGATION_NIGHT = "arcgis-navigation-night",
	ARCGIS_STREETS = "arcgis-streets",
	ARCGIS_STREETS_NIGHT = "arcgis-streets-night",
	ARCGIS_STREETS_RELIEF = "arcgis-streets-relief",
	ARCGIS_TOPOGRAPHIC = "arcgis-topographic",
	ARCGIS_OCEANS = "arcgis-oceans",
	OSM_STANDARD = "osm-standard",
	OSM_STANDARD_RELIEF = "osm-standard-relief",
	OSM_STREETS = "osm-streets",
	OSM_STREETS_RELIEF = "osm-streets-relief",
	OSM_LIGHT_GRAY = "osm-light-gray",
	OSM_DARK_GRAY = "osm-dark-gray",
	ARCGIS_TERRAIN = "arcgis-terrain",
	ARCGIS_COMMUNITY = "arcgis-community",
	ARCGIS_CHARTED_TERRITORY = "arcgis-charted-territory",
	ARCGIS_COLORED_PENCIL = "arcgis-colored-pencil",
	ARCGIS_NOVA = "arcgis-nova",
	ARCGIS_MODERN_ANTIQUE = "arcgis-modern-antique",
	ARCGIS_MIDCENTURY = "arcgis-midcentury",
	ARCGIS_NEWSPAPER = "arcgis-newspaper",
	ARCGIS_HILLSHADE_LIGHT = "arcgis-hillshade-light",
	ARCGIS_HILLSHADE_DARK = "arcgis-hillshade-dark"
}

export class EsriGraphicAttrs {
	id: number;
	type: MAP_ITEM_TYPE;
	hoverable: boolean;
	clickable: boolean;

	constructor(id: number, type: MAP_ITEM_TYPE, hov?: boolean, click?: boolean) {
		this.id = id;
		this.type = type;
		this.hoverable = hov !== undefined ? hov : true;
		this.clickable = click !== undefined ? click : true;
	}
}
export interface EsriClickEvent {
	mapPoint: Point;
	x: number;
	y: number;
	button: 0 | 1 | 2;
	buttons: number;
	type: "immediate-click" | "immediate-double-click";
	stopPropagation: Function;
	timestamp: number;
	native: Object;
}
export interface EsriPointerEvent {
	pointerId: number;
	pointerType: "mouse" | "touch";
	x: number;
	y: number;
	button: number;
	buttons: number;
	type: "pointer-down";
	stopPropagation: Function;
	timestamp: number;
	native: Object;
}
export abstract class ESRI_CONSTS {
	static readonly HOLD_TRESHOLD_MS = 1000;
}
