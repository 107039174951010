import { DTO } from "../net/dto";

export class Methane implements DTO {
	public id: number;

	public id_mission: number;
	public id_user: number;
	public M: string;
	public E: string;
	public T: string;
	public H: string;
	public A: string;
	public N: string;
	public _E: string;
	public first_caller_name: string;
	public first_caller_org: string;
	public timestamp_ms: number;
	public timestamp: number;

	constructor(id: number, id_mission: number, id_user: number, M?: string, E?: string, T?: string, H?: string, A?: string, N?: string, _E?: string, first_caller_name?: string, first_caller_org?: string, timestamp_ms?: number) {
		this.id = id ? id : -1;
		this.id_mission = id_mission;
		this.id_user = id_user;
		this.M = M || "";
		this.E = E || "";
		this.T = T || "";
		this.H = H || "";
		this.A = A || "";
		this.N = N || "";
		this._E = _E || "";
		this.first_caller_name = first_caller_name || "";
		this.first_caller_org = first_caller_org || "";
		this.timestamp_ms = timestamp_ms ? timestamp_ms : -1;
		this.timestamp = Date.now();
	}

	public static readonly fromJson = (json: string): Methane => {
		const jc = JSON.parse(json);
		return new Methane(jc.id ? jc.id : -1, jc.id_mission, jc.id_user, jc.M, jc.E, jc.T, jc.H, jc.A, jc.N, jc._E, jc.first_caller_name, jc.first_caller_org, jc.timestamp_ms);
	};

	public readonly getJson = (): string => {
		return JSON.stringify(this, ["id", "id_mission", "id_user", "M", "E", "T", "H", "A", "N", "_E", "first_caller_name", "first_caller_org", "timestamp_ms"]);
	};
}
export enum METHANE_ITEM {
	M = "M",
	E = "E",
	T = "T",
	H = "H",
	A = "A",
	N = "N",
	_E = "_E"
}
