<button
	data-add-action-name="signature-button"
	class="sign-button"
	[disabled]="disabled"
	(click)="openSignatureCanvas($event)"
>
	<img data-cy="signature-error" *ngIf="!ready && !inError" class="sign-icon" />
	<img data-cy="signature-error" *ngIf="!ready && inError" class="error-icon" src="resources/img/warning-icon.svg" />
	<lottie-player
		data-cy="signature-ready"
		*ngIf="ready"
		class="check"
		src="{{ 'resources/lottie/green-check.json' | baseHref }}"
		background="transparent"
		speed="1"
		autoplay
	></lottie-player>
	<p class="sign-text">{{ text().SIGNATURE }}</p>
</button>
