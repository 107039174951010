import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ConfigurationService } from "src/app/settings/types/configuration.service";
import { PoiType } from "src/app/dto/items/types/poi-type";
import { Size } from "src/app/dto/map/size";
import { LocaleMap } from "src/app/global/constants/text/text-interface";
import { TextProvider } from "src/app/global/constants/text/text-provider";
import { MainService } from "src/app/global/main.service";

@Component({
	selector: "app-poi-card",
	templateUrl: "poi-card.component.html",
	styleUrls: ["poi-card.css"]
})
export class PoiCardComponent implements OnInit {
	@Input() poiType: PoiType = new PoiType(-1, "", "white");

	@Input() canDelete: boolean = false;

	@Output() saveCb = new EventEmitter<PoiType>();

	@Output() deleteCb = new EventEmitter<PoiType>();

	@Input() checkEdit: Function = () => {
		return {};
	};

	public showColorPicker: boolean = false;

	public readonly text: () => LocaleMap;

	private readonly conf: ConfigurationService;
	private readonly main: MainService;
	constructor(conf: ConfigurationService, main: MainService, textProv: TextProvider) {
		this.conf = conf;
		this.main = main;
		this.text = textProv.getStringMap;
	}

	ngOnInit() {
		this.save = () => {
			this.saveCb.emit(this.poiType);
		};

		this.delete = () => {
			this.deleteCb.emit(this.poiType);
		};
	}

	save: Function = () => {};

	delete: Function = () => {};

	toggleGlobalSwitch: Function = () => {
		this.poiType.is_global = !this.poiType.is_global;
	};

	isPoiGlobal: Function = () => {
		return this.poiType.is_global;
	};

	getTypeColorAsBg: Function = () => {
		return {
			"background-color": this.poiType.color
		};
	};

	public readonly hasPoiBeenEdited = (): boolean => {
		const originalPoiType = { ...this.conf.configuration.poiTypes.find((e) => e.id === this.poiType.id) };
		if (!originalPoiType) return true;
		return (
			this.poiType.name.toUpperCase() !== originalPoiType.name?.toUpperCase() ||
			this.poiType.description.toUpperCase() !== originalPoiType.description?.toUpperCase() ||
			this.poiType.color !== originalPoiType.color ||
			this.poiType.icon_path !== originalPoiType.icon_path ||
			this.poiType.icon_size.x != originalPoiType.icon_size!.x ||
			this.poiType.icon_size.y != originalPoiType.icon_size!.y ||
			this.poiType.is_global !== originalPoiType.is_global
		);
	};

	addNewIcon = (evt: { target: any }): void => {
		const file = evt.target.files[0];
		this.readAsText(file);
	};

	attachSvgToPoiType: Function = (svg: any) => {
		try {
			let svgElem = svg.children[0];
			const width = svgElem.getAttribute("width").match(/\d+/)[0];
			const height = svgElem.getAttribute("height").match(/\d+/)[0];
			// to take the first path to make it single path and avoid the clip-path issue
			let pathElem = svgElem.getElementsByTagName("path");
			const path = pathElem[0].getAttribute("d");
			if (!this.testSvgPathDAttr || pathElem[1]) throw "invalid path";
			const color = pathElem[0].getAttribute("fill");
			if (!this.testSvgPathFillAttr) throw "invalid color";
			this.poiType.icon_size = new Size(width, height);
			this.poiType.icon_path = path;
			this.poiType.color = color;
			return true;
		} catch (e) {
			this.main.addDangerAlert(this.text().INVALID_ICON);
			return false;
		}
	};

	testSvgPathDAttr: Function = (path: string) => {
		// copied from https://stackoverflow.com/questions/54961620/test-if-svg-path-d-property-string-is-valid
		const reEverythingAllowed = /[MmZzLlHhVvCcSsQqTtAa0-9-,.\s]/g;

		const bContainsIllegalCharacter = !!path.replace(reEverythingAllowed, "").length;
		const bContainsAdjacentLetters = /[a-zA-Z][a-zA-Z]/.test(path);
		const bInvalidStart = /^[0-9-,.]/.test(path);
		const bInvalidEnd = /.*[-,.]$/.test(path.trim());
		return !bContainsIllegalCharacter && !bContainsAdjacentLetters && !bInvalidStart && !bInvalidEnd;
	};

	testSvgPathFillAttr: Function = (fill: string) => {
		// copied from https://stackoverflow.com/questions/1636350/how-to-identify-a-given-string-is-hex-color-format
		return fill.match(/^#(([0-9a-fA-F]{2}){3}|([0-9a-fA-F]){3})$/) !== null;
	};

	public readonly openColorPicker = (evt: Event): boolean => {
		evt.stopPropagation();
		return (this.showColorPicker = true);
	};

	public readonly closeColorPicker = (): boolean => {
		return (this.showColorPicker = false);
	};

	public readonly changeColor = (color: string): string => {
		return (this.poiType.color = color);
	};

	private readAsText = (file: File): void => {
		let e = new FileReader();
		e.readAsText(file);
		e.onload = () => {
			const parser = new DOMParser();
			const svg = parser.parseFromString(e.result as string, "image/svg+xml");
			this.attachSvgToPoiType(svg);
		};
	};
}
