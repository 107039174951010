import { Component, Input } from "@angular/core";
import { Area } from "src/app/dto/items/area";
import { LocaleMap } from "src/app/global/constants/text/text-interface";
import { TextProvider } from "src/app/global/constants/text/text-provider";

@Component({
	selector: "app-area-label",
	templateUrl: "area-label.component.html",
	styleUrls: ["area-label.css", "../map-label.css"]
})
export class AreaLabelComponent {
	@Input() area!: Area;

	text: () => LocaleMap;

	constructor(textProv: TextProvider) {
		this.text = textProv.getStringMap;
	}
}
