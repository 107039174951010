import { Component, EventEmitter, Input, Output } from "@angular/core";
import { SECTOR_COLOURS } from "src/app/dto/fsg/enums";
import { LocaleMap } from "src/app/global/constants/text/text-interface";
import { TextProvider } from "src/app/global/constants/text/text-provider";

@Component({
	selector: "app-sector-info-tooltip",
	templateUrl: "fsg-sector-info.component.html",
	styleUrls: ["fsg-sector-info.css"]
})
export class FsgSectorInfoTooltipComponent {
	@Input() header: string = "";

	@Output() cancel = new EventEmitter<void>();

	public readonly text: () => LocaleMap;
	public readonly sector_colours = SECTOR_COLOURS;

	constructor(tp: TextProvider) {
		this.text = tp.getStringMap;
	}
}
