import { Component, EventEmitter, Input, Output } from "@angular/core";
import { LocaleMap } from "src/app/global/constants/text/text-interface";
import { TextProvider } from "src/app/global/constants/text/text-provider";
import { JesipCard, JESIP_CARD_BODY_TYPE, JESIP_CARD_INITIAL_COLOR } from "../../jesip-card.interface";
import { UserService } from "src/app/settings/user/user.service";

@Component({
	selector: "app-fsg-app-set-jesip-card",
	templateUrl: "fsg-set-jesip-card.component.html",
	styleUrls: ["../../jesip-card.css", "../fsg-jesip-card.css"]
})
export class FsgSetJesipCardComponent implements JesipCard {
	@Input() initial: string | undefined;
	@Input() title: string = "";
	@Input() updateAvailable: boolean = false;
	@Input() number: number | (() => number) | undefined;
	@Output() buttonClick = new EventEmitter<Event>();

	@Input() timeText: string | (() => string) | undefined;
	@Input() authorText: string | (() => string) | undefined;
	@Input() buttonText: string | (() => string) | undefined;

	public color: string | (() => string) = JESIP_CARD_INITIAL_COLOR.BLUE;
	public backgroundSrc: string = "";
	public readonly type = JESIP_CARD_BODY_TYPE.SET;
	public readonly text: () => LocaleMap;

	private readonly userServ: UserService;

	constructor(textProv: TextProvider, user : UserService) {
		this.text = textProv.getStringMap;
		this.userServ = user;
	}

	public readonly getTime: () => string = () => {
		if (!this.timeText) return "";
		if (typeof this.timeText === "string") return this.timeText;
		return this.timeText();
	};

	public readonly getAuthor: () => string = () => {
		if (!this.authorText) return "";
		if (typeof this.authorText === "string") return this.authorText;
		return this.authorText();
	};

	public readonly getButton: () => string = () => {
		if (!this.buttonText) return "";
		if (typeof this.buttonText === "string") return this.buttonText;
		return this.buttonText();
	};

	public readonly getColour: () => string = () => {
		if (typeof this.color === "string") return this.color;
		return this.color();
	};

	public readonly getNumber: () => number = () => {
		if (typeof this.number === "number") return this.number;

		return this.number ? this.number() : 0;
	};

	public readonly canEditEF: () => boolean = () => {
		return this.userServ.getCurrentUserLevel()?.edit_ef;
	}
}
