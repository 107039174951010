import { Component, Input, OnDestroy } from "@angular/core";
import { Appliance } from "src/app/dto/resources/appliance";
import { ListColumn } from "src/app/dto/ui/list-column";
import { LocaleMap } from "src/app/global/constants/text/text-interface";
import { TextProvider } from "src/app/global/constants/text/text-provider";
import { Personnel } from "../../../../dto/resources/personnel";

@Component({
	selector: "app-resources-log-list",
	templateUrl: "resources-log-list.component.html",
	styleUrls: ["../blue-list/blue-list.css", "../resources-log-list/resources-log-list.css"]
})
export class ResourceLogListComponent implements OnDestroy {
	@Input() items: Array<any> = []; //ResourceLogEntry -> but doesn't have .expanded property (dictionaries)
	@Input() columns: Array<ListColumn> = [];
	@Input() disabled: boolean = false;

	public readonly text: () => LocaleMap;
	public expanded: boolean = false;
	public shownSkillIndexes: Map<number, boolean> = new Map<number, boolean>();

	constructor(textProv: TextProvider) {
		this.text = textProv.getStringMap;
	}

	ngOnDestroy() {
		this.items.forEach((item) => {
			item.expanded = false;
		});
	}

	public readonly getColumnWidth: Function = (column: ListColumn) => {
		return { width: column.width };
	};

	public readonly setRowHeight: Function = () => {
		return { height: "fit-content" };
	};

	public toggleExpanded: Function = (item: any) => {
		item.expanded = !item.expanded;
	};

	public isToggleExpanded: Function = (item: any) => {
		return item.expanded ? "up" : "down";
	};

	public toggleSkills(index: number): void {
		if (this.shownSkillIndexes.has(index)) {
			this.shownSkillIndexes.delete(index);
		} else {
			this.shownSkillIndexes.set(index, true);
		}
	}

	public isOic(appliance: Appliance, person: Personnel): boolean {
		return appliance.oic?.id === person.id ?? false;
	}

	public showDriversIcon(appliance: Appliance): boolean {
		if ((appliance.oic && appliance.personnel.length > 1) || (!appliance.oic && appliance.personnel.length > 0)) {
			return true;
		}
		return false;
	}
}
