<div
	#pageElements
	*ngFor="let page of pages; let idx = index"
	class="page"
	[style.width.px]="pageSize.width"
	[style.height.px]="pageSize.height"
>
	<div class="header">
		<div class="title underline">{{ name }}</div>
	</div>

	<app-cs-header [commandStructure]="cs" [event]="event" [pdf]="name"></app-cs-header>

	<span class="dividing-line"></span>
	<div *ngIf="isFirstPage(page)" class="control-room margin-bottom-8">
		<div class="margin-bottom-8">
			<div>
				<b>{{ text().CONTROL_ROOM }}: </b>
			</div>
			<div>{{ cs.commander.control_room }}</div>
		</div>
		<span class="dividing-line"></span>
	</div>
	<div class="body">
		<div *ngFor="let item of page | filter : isSector">
			<div class="title margin-bottom-8">
				<div>{{ item.idx + ". " + item.sector?.name }}</div>
				<span class="horizontal-line"></span>
			</div>
			<div class="sector-commander margin-bottom-8">
				<div><b>S.C:</b></div>
				<div>{{ getSectorCommanderName(item.sector) }}</div>
			</div>
			<div class="sector-commander margin-bottom-8">
				<div><b>S.O:</b></div>
				<div>{{ getSafetyOfficerName(item.sector) }}</div>
			</div>
			<div class="tasks margin-bottom-8">
				<div>
					<b>{{ text().NOTES }}: </b>
				</div>
				<div>
					{{ getTasks(item.sector) }}
				</div>
			</div>
			<div class="resources">
				<div>
					<b>{{ text().RESOURCES }}: </b>
				</div>
				<div class="resource-list">
					<div *ngIf="item.sector.resources.length <= 0">---</div>
					<div
						class="resource-item {{
							resource.__personnel && resource.__personnel.length
								? ''
								: 'margin-bottom-8'
						}}"
						*ngFor="let resource of item.sector.resources"
					>
						{{ resource.__object.name }}

						<div *ngIf="resource.__closed_personnel" class="personnel-list">
							<div
								*ngFor="let person of resource.__closed_personnel"
								class="personnel-item"
							>
								<span
									*ngIf="
										resource.__object.oic &&
										resource.__object.oic.id === person.id
									"
								>
									OIC:
								</span>
								{{ person.name }}
							</div>
						</div>
					</div>
				</div>
			</div>
			<span class="dividing-line margin-top-8"></span>
			<span
				*ngIf="!isLast(item.idx) && !isLastOfPage(page, item.idx)"
				class="vertical-line"
			></span>
			<div *ngIf="isFirstOfPage(page, item.idx) && !isFirst(item.idx)">
				<span class="top-span"> </span>
				<div class="top-dotted"></div>
			</div>
		</div>

		<div *ngFor="let item of page | filter : isSupport">
			<div class="title margin-bottom-8">
				<div>{{ item.idx + ". " + item.sector?.name }}</div>
				<span class="horizontal-line"></span>
			</div>
			<div class="sector-commander margin-bottom-8">
				<div><b>S.C:</b></div>
				<div>{{ getSectorCommanderName(item.sector) }}</div>
			</div>
			<div class="sector-commander margin-bottom-8">
				<div><b>S.O:</b></div>
				<div>{{ getSafetyOfficerName(item.sector) }}</div>
			</div>
			<div class="tasks margin-bottom-8">
				<div>
					<b>{{ text().NOTES }}: </b>
				</div>
				<div>
					{{ getTasks(item.sector) }}
				</div>
			</div>
			<div class="resources">
				<div>
					<b>{{ text().RESOURCES }}: </b>
				</div>
				<div class="resource-list">
					<div *ngIf="item.sector.resources.length <= 0">---</div>
					<div
						class="resource-item {{
							resource.__personnel && resource.__personnel.length
								? ''
								: 'margin-bottom-8'
						}}"
						*ngFor="let resource of item.sector.resources"
					>
						{{ resource.__object.name }}
						<div *ngIf="resource.__closed_personnel" class="personnel-list">
							<div
								*ngFor="let person of resource.__closed_personnel"
								class="personnel-item"
							>
								{{ person.name }}
							</div>
						</div>
					</div>
				</div>
			</div>
			<span class="dividing-line margin-top-8"></span>
			<span
				*ngIf="!isLast(item.idx) && !isLastOfPage(page, item.idx)"
				class="vertical-line"
			></span>
			<div *ngIf="isFirstOfPage(page, item.idx) && !isFirst(item.idx)">
				<span class="top-span"> </span>
				<div class="top-dotted"></div>
			</div>
		</div>

		<!-- for old support case scenarios -->
		<div *ngFor="let item of page | filter : isOldSupport">
			<div class="title margin-bottom-8">
				<div>
					{{
						item.idx +
							"." +
							(item.support.title !== "" ? item.support.title : text().SUPPORT)
					}}
				</div>
				<span class="horizontal-line"></span>
			</div>
			<div class="support-item margin-bottom-8">
				<div>
					<b>{{ text().NEW + " " + text().SUPPORT }}: </b>
				</div>
				<div>{{ item.support.name !== "" ? item.support.name : "---" }}</div>
			</div>
			<div class="support-item margin-bottom-8">
				<div>
					<b>{{ text().CAPABILITY }} :</b>
				</div>
				<div>
					{{ item.support.capabilities !== "" ? item.support.capabilities : "---" }}
				</div>
			</div>
			<span class="dividing-line"></span>
			<span
				*ngIf="!isLast(item.idx) && !isLastOfPage(page, item.idx)"
				class="vertical-line"
			></span>
			<div *ngIf="isFirstOfPage(page, item.idx) && !isFirst(item.idx)">
				<span class="top-span"> </span>
				<div class="top-dotted"></div>
			</div>
		</div>
	</div>
	<div *ngIf="!isLastPage(page)" class="bottom-line-decoration">
		<span class="bottom-span"></span>
		<div class="bottom-dotted"></div>
	</div>
	<div class="page-index">
		<b>{{ text().PAGE }}:</b> <span>{{ idx + 1 }} / {{ pages.length }}</span>
	</div>
</div>

<div class="inspector">
	<div #inspectedSectorElement *ngIf="inspectedSectorItem">
		<div class="title margin-bottom-8">
			<div>
				{{
					inspectedSectorItem.idx + ". " + inspectedSectorItem.sector?.name
						? inspectedSectorItem.sector?.name
						: "Sector"
				}}
			</div>
			<span class="horizontal-line"></span>
		</div>
		<div class="sector-commander margin-bottom-8">
			<div><b>S.C:</b></div>
			<div>{{ getSectorCommanderName(inspectedSectorItem.sector) }}</div>
		</div>
		<div class="sector-commander margin-bottom-8">
			<div><b>S.O:</b></div>
			<div>{{ getSafetyOfficerName(inspectedSectorItem.sector) }}</div>
		</div>
		<div class="tasks margin-bottom-8">
			<div><b>Tasks: </b></div>
			<div>{{ getTasks(inspectedSectorItem.sector) }}</div>
		</div>
		<div class="resources">
			<div><b>Resources: </b></div>
			<div class="resource-list">
				<div
					class="resource-item"
					*ngFor="let resource of inspectedSectorItem.sector?.resources"
				>
					{{ resource.__object?.name }}
					<div *ngIf="resource.__closed_personnel" class="personnel-list">
						<div
							*ngFor="let person of resource.__closed_personnel"
							class="personnel-item"
						>
							{{ person.object.name }}
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div #inspectedSupportElement *ngIf="inspectedSupportItem">
		<div class="title margin-bottom-8">
			<div>{{ inspectedSupportItem.idx + ". Support" }}</div>
			<span class="horizontal-line"></span>
		</div>
		<div class="support-item margin-bottom-8">
			<div><b>New support: </b></div>
			<div>
				{{
					inspectedSupportItem.support?.name
						? inspectedSupportItem.support?.name
						: "Support"
				}}
			</div>
		</div>
		<div class="support-item margin-bottom-8">
			<div><b>Capability :</b></div>
			<div>{{ inspectedSupportItem.support?.capabilities }}</div>
		</div>
	</div>
</div>
