<div
	class="item-card person-card {{ open ? 'width-520px' : 'width-270px' }} box-sizing-border-box {{
		hasResourceBeenEdited() || canDelete ? 'active' : ''
	}}"
>
	<div
		data-cy="personnel-card-name"
		class="item-card-title resources-card-name"
		appScrollToElement
		[scrollIdentifierId]="personnel.id"
	>
		<input
			[attr.data-cy]="'personnel-name-input-' + personnel.name"
			required
			placeholder="{{ text().NAME }}"
			[(ngModel)]="personnel.name"
			type="text"
			(ngModelChange)="editingPersonnel()"
		/>
	</div>
	<div class="card-half-container">
		<div class="personnel-first-half">
			<div>
				<app-resource-icon [personnel]="personnel"></app-resource-icon>
				<div class="role-station-section">
					<div class="dropdown-container">
						<app-blue-dropdown
							class="dropdown"
							[object]="personnel"
							[options]="resourceTypes"
							[getObjectText]="typeDropdownGetMainText"
							[getOptionText]="typeDropdownGetOptionText"
							[compareSelect]="typeDropdownCompareSelect"
							(itemClickCallback)="typeDropdownChangeCallback($event)"
							[placeholderText]="text().TYPE"
						>
						</app-blue-dropdown>
					</div>
					<div class="dropdown-container">
						<app-blue-dropdown
							class="dropdown"
							[object]="personnel"
							[options]="stations"
							[getObjectText]="stationDropdownGetMainText"
							[getOptionText]="stationDropdownGetOptionText"
							[compareSelect]="stationDropdownCompareSelect"
							(itemClickCallback)="stationDropdownChangeCallback($event)"
							[placeholderText]="text().STATION"
						></app-blue-dropdown>
					</div>
					<div class="dropdown-container">
						<app-blue-dropdown
							class="dropdown"
							[object]="personnel"
							[options]="incidents"
							[getObjectText]="incidentDropdownGetMainText"
							[getOptionText]="incidentDropdownGetOptionText"
							[compareSelect]="incidentDropdownCompareSelect"
							(itemClickCallback)="incidentDropdownChangeCallback($event)"
							[placeholderText]="text().INCIDENT"
						></app-blue-dropdown>
					</div>
					<div class="gps-toggle-container">
						<span>GPS</span>
						<app-toggle-switch
							class="toggle-switch"
							(clickCb)="toggleGpsSwitch(); $event.stopPropagation()"
							[toggle]="isResourceTracked"
						>
						</app-toggle-switch>
					</div>
				</div>
				<div class="skills-container" style="display: flex">
					<app-add-skill
						[personnel]="personnel"
						(showSkillModal)="openSkillModal()"
					></app-add-skill>
					<app-skills-display
						[resource]="personnel"
						[size]="24"
						[overflowSkillsVisible]="areOverflowSkillsVisible"
						(toggleSkillsOverflowEvent)="toggleSkills()"
					>
					</app-skills-display>
				</div>
				<app-add-camera
					[personnel]="personnel"
					(assignHardwareCb)="addCamera()"
				></app-add-camera>

				<div class="comment-section">
					<div class="button-label-container">
						<span class="resource-card-label">{{ text().DETAILS }}</span>
						<button
							class="main-blue-gradient-button mb open-card-button"
							*ngIf="user && !open"
							(click)="openFullProfile(); $event.stopPropagation()"
						>
							{{ text().VIEW_FULL_PROFILE }}
						</button>
					</div>
					<textarea
						class="card-textarea-input"
						[(ngModel)]="personnel.description"
					></textarea>
				</div>
			</div>
			<button
				data-add-action-name="personnel-save-button"
				class="main-blue-gradient-button card-bottom-button font14"
				*ngIf="hasResourceBeenEdited()"
				(click)="save()"
			>
				{{ text().SAVE }}
			</button>
			<button
				data-add-action-name="personnel-delete-button"
				class="main-blue-gradient-button card-bottom-button font14"
				*ngIf="canDelete"
				(click)="delete()"
			>
				{{ text().DELETE }}
			</button>
		</div>
		<div class="personnel-second-half {{ open ? 'open' : '' }}" *ngIf="user">
			<div class="cross-button-container">
				<app-blue-cross (clickCb)="closeCard($event)" [open]="true"> </app-blue-cross>
			</div>
			<div class="user-section">
				<img src="resources/img/dtlogo64.png" alt="User Photo" height="60" width="60" />
				<div>
					<span class="resource-card-label">{{ text().USER_ACCESS }}</span>
					<input
						[attr.data-cy]="'personnel-user-name-input-' + personnel.__user!.name"
						class="blue-plain-input margin-4"
						[ngModel]="personnel.__user!.name"
						(ngModelChange)="checkChanges($event)"
						placeholder="{{ text().USERNAME | uppercase }}"
					/>
					<input
						[attr.data-cy]="
							'personnel-user-password-input-' + personnel.__user!.password
						"
						type="password"
						class="blue-plain-input force-uppercase margin-4"
						[(ngModel)]="personnel.__user!.password"
						[disabled]="!isNewResource()"
						placeholder="{{ text().PASSWORD }}"
					/>
					<label
						*ngIf="!isNewResource()"
						(click)="changePasswordCb.emit(user)"
						class="change-password-label margin-4"
					>
						{{ text().CHANGE_PASSWORD }}</label
					>
				</div>
			</div>
			<div class="user-role-section">
				<span class="resource-card-label"> {{ text().LEVELS }}</span>
				<div data-cy="role-dropdown-container" class="role-dropdown-container">
					<app-blue-dropdown
						class="dropdown"
						[options]="levels"
						[getObjectText]="roleDropdownGetMainText"
						[getOptionText]="roleDropdownGetOptionText"
						[compareSelect]="roleDropdownCompareSelect"
						(itemClickCallback)="roleDropdownChangeCallback($event)"
						[placeholderText]="text().ROLE"
					></app-blue-dropdown>
				</div>
			</div>
		</div>
	</div>

	<app-assign-skill-modal
		*ngIf="showSkillModal"
		(closeCb)="closeSkillModal()"
		(confirmCb)="setNewSkills($event)"
		[resource]="personnel"
	></app-assign-skill-modal>
</div>
