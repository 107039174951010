import { Component } from "@angular/core";
import { MainService } from "../../main.service";

@Component({
	selector: "app-main-load-snackbar",
	templateUrl: "app-main-load-snackbar.component.html",
	styleUrls: ["app-main-load-snackbar.css"]
})
export class MainLoadSnackbarComponent {
	public main: MainService;
	public snackbarText: string = "";
	public showSnackbar: boolean = false;

	constructor(main: MainService) {
		this.main = main;
	}

	public readonly show: () => boolean = () => {
		this.showSnackbar = this.main.showLoadSnackbar;
		return this.showSnackbar;
	};

	public readonly getText: () => string = () => {
		this.snackbarText = this.main.loadSnackbarText;
		return this.snackbarText;
	};

	public readonly onCancel = (): void => {
		this.main.showLoadSnackbar = false;
	};
}
