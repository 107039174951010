<div class="container">
    <app-header-chip
    appDetectClickOut
    (callback)="onClickOut()"
    (click)="open = !open"
    [wide]="true">
        <img *ngIf="hasIcon" src="resources/img/info-tooltip.svg">
        {{chipText}}
    </app-header-chip>
    <div *ngIf="open" class="content">
        <ng-content></ng-content>
    </div>
</div>