<div class="main-black-mask z-index-33"></div>
<div class="alert-dialog loading-dialog">
	<div class="dialog-header">
		{{ headerText }}
	</div>
	<app-white-cross class="dialog-cross-button" (clickCb)="closeCallback.emit()"></app-white-cross>
	<div class="load-icon">
		<img src="resources/img/loading-icon.svg" />
	</div>
	<div class="body">{{ bodyText }}</div>
	<div *ngIf="bodyText && !downloadingVideo" class="body alert">
		{{ text().CLOSING_THE_DIALOG }}
	</div>
	<div *ngIf="infoText" class="body alert">
		<p class="info-text">{{ infoText }}</p>
	</div>
</div>
