<div
	#pageElements
	*ngFor="let page of pages; let idx = index"
	class="page"
	[style.width.px]="pageSize.width"
	[style.height.px]="pageSize.height"
>
	<div class="header">
		<div class="title">{{ fsg.name }}</div>
	</div>

	<app-fsg-header [fsg]="fsg" [event]="event"></app-fsg-header>

	<div class="table-header">
		<div>{{ text().FSG_SOURCE }}</div>
		<div>{{ text().FSG_FLOOR }}</div>
		<div>{{ text().FSG_FLAT }}</div>
		<div>{{ text().FSG_PEOPLE }}</div>
		<div>{{ text().CONDITIONS }}</div>
		<div>{{ text().FSG_ADVICE }}</div>
		<div>{{ text().FSG_PRIORITY }}</div>
		<div>{{ text().FSG_COMMENTS }}</div>
		<div>{{ text().FSG_CASUALTY_STATUS }}</div>
	</div>
	<div class="table-row" *ngFor="let flat of page">
		<div></div>
		<div>{{ flat.floor_number }}</div>
		<div>{{ flat.flat_name }}</div>
		<div></div>
		<div>
			<b>{{ isFlatOnFire(flat) ? text().FIRE : "" }}</b>
		</div>
		<div></div>
		<div></div>
		<div></div>
		<div></div>
	</div>
</div>

<div class="inspector" #inspectedHeaderElement *ngIf="inspectedHeader">
	<div class="header">
		<div class="title">{{ fsg.name }}</div>
		<div class="timestamp">
			<b>{{ text().DOCUMENT_DOWNLOADED }}: </b>
			{{ generationTime | date : "HH:mm dd/MM/YYYY" }}
		</div>
	</div>

	<app-fsg-header [fsg]="fsg" [event]="event"></app-fsg-header>

	<div class="table-header">
		<div>{{ text().FSG_SOURCE }}</div>
		<div>{{ text().FSG_FLOOR }}</div>
		<div>{{ text().FSG_FLAT }}</div>
		<div>{{ text().FSG_PEOPLE }}</div>
		<div>{{ text().CONDITIONS }}</div>
		<div>{{ text().FSG_ADVICE }}</div>
		<div>{{ text().FSG_PRIORITY }}</div>
		<div>{{ text().FSG_COMMENTS }}</div>
		<div>{{ text().FSG_CASUALTY_STATUS }}</div>
	</div>
</div>
