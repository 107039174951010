<div style="display: inline-block; vertical-align: middle; margin-left: 2px">
	<input
		data-cy="user-filter-input"
		#inputRef
		autocomplete="off"
		id="filter-input"
		class="agent-name-filter-text {{ open ? 'open' : '' }} selectable-input"
		[(ngModel)]="queryOutput"
		(ngModelChange)="changeEmit.emit($event)"
	/>
</div>
<div
	data-add-action-name="button-glass-icon"
	class="button-glass-icon"
	(click)="toggleFilter(); $event.stopPropagation()"
>
	<img src="resources\img\search.svg" />
</div>
