import { DTO } from "../net/dto";
import { Resource } from "../resources/resource";
import { CSCommander } from "./cs-commander";
import { CSSectorResourceAssignation } from "./cs-resource";
import { CSSector } from "./cs-sector";
import { CSSupport } from "./cs-support";

export class CommandStructure {
	commander: CSCommander;
	supports: Array<CSSupport>;
	sectors: Array<CSSector>;

	id: number; // commander id
	id_mission: number;
	timestamp: number;
	constructor(commander: CSCommander, supports?: Array<CSSupport>, sectors?: Array<CSSector>, resources?: Array<CSSectorResourceAssignation>) {
		this.commander = commander;
		this.id = commander.id_commander;
		this.id_mission = commander.id_incident;
		this.supports = supports ? supports : new Array();
		this.sectors = sectors ? sectors : new Array();
		this.timestamp = Date.now();
	}

	public toString: Function = () => {
		let ans = "Command structure of id (commander) " + this.id + " on mission " + this.id_mission + " with timestamp " + this.timestamp + ".\n\n";
		ans += this.supports.length + " supports found.\n";
		for (let i = 0; i < this.supports.length; i++) ans += "Support " + this.supports[i].id + " with name " + this.supports[i].name + " and capabilities " + this.supports[i].capabilities + ".\n";
		ans += "\n" + this.sectors.length + " sectors found.\n";
		for (let i = 0; i < this.sectors.length; i++) {
			ans += "Sector " + this.sectors[i].id + " with name " + this.sectors[i].name + " and " + this.sectors[i].resources.length + " resources.\n";
			for (let j = 0; j < this.sectors[i].resources.length; j++) {
				ans += "- Resource " + this.sectors[i].resources[j].id + " of name " + this.sectors[i].resources[j].__name + " and ";
				ans += this.sectors[i].resources[j].__personnel ? this.sectors[i].resources[j].__personnel!.length + " personnel.\n" : "no personnel.\n";
				if (this.sectors[i].resources[j].__personnel && this.sectors[i].resources[j].__personnel!.length > 0) {
					for (var k = 0; k < this.sectors[i].resources[j].__personnel!.length; k++) ans += "- - Person " + this.sectors[i].resources[j].__personnel![k].id + " of name " + this.sectors[i].resources[j].__personnel![k].name + ".\n";
				}
			}
			ans += "\n";
		}
		return ans;
	};

	
	public getResourceByAgentId(id: number): CSSectorResourceAssignation | undefined {
		for (let j = 0; j < this.sectors.length; j++) {
			if (this.sectors[j].resources.find((e: CSSectorResourceAssignation) => e.id_resource === id)) return this.sectors[j].resources.find((e: CSSectorResourceAssignation) => e.id_resource === id);
		}
		return undefined;
	};

	public getSectorBySectorCommanderId(id: number): CSSector | undefined {
		const ans = this.sectors.find((e: CSSector) => e.id_resource_comm === id);
		return ans;
	};

	
	public getSectorBySafetyOfficerId(id: number): CSSector | undefined {
		const ans = this.sectors.find((e: CSSector) => e.id_safety_officer === id);
		return ans;
	};

	
	public readonly checkCommanderId: (id: number) => CSCommander | undefined = (id) => {
		return this.commander.id_resource === id ? this.commander : undefined;
	}

	public checkIfPersonnelAssignedToAppliance(id: number): boolean {
		for (let i = 0; i < this.sectors.length; i++) {
			for (let j = 0;j < this.sectors[i].resources.length; j++){
				if(this.sectors[i].resources[j].__personnel?.find((e) => e.id === id)) return true;
			}
		}
		return false;
	}

	public isResourceAnywhereInside(input: number | Resource): boolean {
		const id = input instanceof Resource ? input.id : input;
		return !!this.getResourceByAgentId(id) || !!this.getSectorBySectorCommanderId(id) || !!this.getSectorBySafetyOfficerId(id) || !!this.checkCommanderId(id) || !!this.checkIfPersonnelAssignedToAppliance(id);
	}
}
