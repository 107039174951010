import { Component, OnDestroy, Input } from "@angular/core";
import { Subscription } from "rxjs";
import { MainService } from "src/app/global/main.service";
import { FUNCTIONALITY } from "src/app/global/functionality.service";

@Component({
	selector: "app-support-dialog-container",
	templateUrl: "./support-dialog-container.component.html",
	styleUrls: ["./support-dialog-container.component.css"]
})
export class SupportDialogContainerComponent implements OnDestroy {
	@Input() isFuncAvailable!: Map<FUNCTIONALITY, boolean>;

	public supportMenu: boolean = false;
	private subscriber: Subscription;

	constructor(main: MainService) {
		this.subscriber = main.openSupportDialog.subscribe(() => (this.supportMenu = true));
	}

	ngOnDestroy(): void {
		this.subscriber.unsubscribe();
	}

	public toggleMenu(): boolean {
		return (this.supportMenu = !this.supportMenu);
	}
}
