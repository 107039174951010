import { AfterViewInit, Component, ElementRef, EventEmitter, Input, NgZone, OnInit, Output, QueryList, Sanitizer, ViewChild, ViewChildren, ɵdetectChanges } from "@angular/core";
import { LocaleMap } from "src/app/global/constants/text/text-interface";
import { TextProvider } from "src/app/global/constants/text/text-provider";
import * as html2canvas from "html2canvas";
import { DocumentService } from "src/app/document/document.service";
import { DocumentTemplate } from "../../../template.interface";
import { FormsService } from "src/app/incident/incident-tools/forms/forms.service";
import { Methane, METHANE_ITEM } from "src/app/dto/jesip/Methane";
import { Member } from "src/app/dto/jesip/member";
import { JESIP_FORMS } from "src/app/global/constants/enums/jesip_forms";
import { PAGE_SIZES } from "src/app/dto/Documents/page-sizes";
import { Incident } from "src/app/dto/items/incident";

@Component({
	selector: "app-methane-current-template",
	templateUrl: "methane-current.component.html",
	styleUrls: ["methane-current.css", "../../jesip.css", "../../../../document.css"]
})
export class CurrentMethaneTemplateComponent implements OnInit, DocumentTemplate, AfterViewInit {
	@Output() docReady = new EventEmitter<HTMLCanvasElement[]>();

	@ViewChildren("pageElements") pageElements!: QueryList<ElementRef>;
	@ViewChild("inspectedOptionElement") inspectedOptionElement!: ElementRef;
	@ViewChild("inspectedHeaderElement") inspectedHeaderElement!: ElementRef;

	@Input() generationTime!: number;
	@Input() event!: Incident;
	@Input() methane!: Methane;

	public inspectedHeader: boolean = false;
	public inspectedOption: MethaneItem | undefined;
	public numberOfPages: number = 1;
	public pages = new Array<Array<MethaneItem>>();
	public readonly pageSize = PAGE_SIZES.STANDARD;
	public commanders = new Array<Member>();

	public readonly text: () => LocaleMap;

	private readonly jesip: FormsService;
	private readonly zone: NgZone;
	private readonly docService: DocumentService;
	private abortDownload = false;

	constructor(tp: TextProvider, jes: FormsService, z: NgZone, doc: DocumentService) {
		this.text = tp.getStringMap;
		this.jesip = jes;
		this.zone = z;
		this.docService = doc;
	}

	ngOnInit(): void {
		if (!this.event) this.docService.downloadError$.next();
		this.commanders = this.jesip.teamInfo.get(JESIP_FORMS.METHANE) ? this.jesip.teamInfo.get(JESIP_FORMS.METHANE)! : new Array<Member>();
		this.setPages();
		this.docService.onDownloadCancel$.subscribe(() => (this.abortDownload = true));
	}

	async ngAfterViewInit(): Promise<void> {
		try {
			this.zone.runOutsideAngular(async () => {
				const pages = this.pageElements["_results"];
				//Patch for preventing browser crash aborting de generation of documents and download
				if (pages.length >= 20) {
					this.docService.setUnavailabeError();
					return;
				}
				const output = new Array<HTMLCanvasElement>();
				for (let i = 0; i < pages.length; i++) {
					if (!this.docService.downloadingFullIncident) this.docService.setLoadingMessage(this.text().GENERATING_DOCUMENTATION_PAGE(i, pages.length));
					else {
						console.info(this.text().GENERATING_DOCUMENTATION_PAGE(i, pages.length));
						this.docService.setLoadingMessage(this.text().DOWNLOAD_PROCESS_TEXT);
					}
					const canvas = await html2canvas.default(this.pageElements["_results"][i].nativeElement);
					if (this.abortDownload) return;
					output.push(canvas);
				}
				this.docReady.emit(output);
			});
		} catch (error) {
			if (this.abortDownload) return;
			console.error(error);
			this.docService.downloadError$.next();
		}
	}

	public readonly setPages: Function = () => {
		let page = new Array<MethaneItem>();
		let height = this.calculateHeaderHeight();
		let index = 0;
		for (let keys in METHANE_ITEM) {
			const key = keys as keyof Methane;
			const newOption = new MethaneItem((this.text() as any)["JESIP_METHANE_ITEMS_TITLE_" + index], (this.text() as any)["JESIP_METHANE_ITEMS_DESCRIPTION_" + index], (this.methane as any)[key]);
			if (height + this.calculateOptionHeight(newOption) > this.pageSize.height - 150) {
				this.pages.push(page);
				page = new Array<MethaneItem>();
				height = this.calculateHeaderHeight();
			}
			page.push(newOption);
			height += this.calculateOptionHeight(newOption);
			index++;
		}
		if (!this.pages.find((e) => e === page)) this.pages.push(page);
	};

	private readonly calculateHeaderHeight: () => number = () => {
		this.inspectedHeader = true;
		ɵdetectChanges(this);
		const output = this.inspectedHeaderElement!.nativeElement.getBoundingClientRect().height;
		this.inspectedHeader = false;
		return output;
	};

	private readonly calculateOptionHeight: (opt: MethaneItem) => number = (opt) => {
		this.inspectedOption = opt;
		ɵdetectChanges(this);
		const output = this.inspectedOptionElement!.nativeElement.getBoundingClientRect().height;
		this.inspectedOption = undefined;
		return output;
	};
}

class MethaneItem {
	header: string;
	question: string;
	answer: string;

	constructor(h: string, q: string, a: string) {
		this.header = h;
		this.question = q;
		this.answer = a;
	}
}
