import { AfterViewInit, Component, ElementRef, Input, NgZone, OnInit, ViewChild, ViewChildren, OnDestroy } from "@angular/core";
import { CommandStructure } from "src/app/dto/command-structure/command-structure";
import { CSBlock } from "src/app/dto/command-structure/cs-block";
import { CSCommander } from "src/app/dto/command-structure/cs-commander";
import { CSSector } from "src/app/dto/command-structure/cs-sector";
import { CSSupport } from "src/app/dto/command-structure/cs-support";
import { IncidentService } from "src/app/incident/incident.service";
import { CommandStructureService } from "src/app/incident/incident-tools/command-structure/command-structure.service";
import { LocaleMap } from "src/app/global/constants/text/text-interface";
import { TextProvider } from "src/app/global/constants/text/text-provider";
import { MESSAGE_TYPE } from "src/app/global/messaging/messages";
import { MessagingService } from "src/app/global/messaging/messaging.service";
import { CSCommanderNodeComponent } from "./components/commander/commander.component";
import { CSControlRoomNodeComponent } from "./components/control-room/control-room.component";
import { CSSectorNodeComponent } from "./components/sector/sector.component";
import { CSSupportNodeComponent } from "./components/support/support.component";
import { Subject } from "rxjs";
import { debounceTime } from "rxjs/operators";

@Component({
	selector: "app-command-chart",
	templateUrl: "command-chart.component.html",
	styleUrls: ["chart.css"]
})
export class CommandChartGraphicComponent implements OnInit, AfterViewInit, OnDestroy {
	@Input() commandStructure: CommandStructure | undefined;
	@Input() disable: boolean = false;
	@Input() isResourceBeingDragged: boolean = false;
	@Input() isPersonnelBeingDragged: boolean = false;

	@ViewChild("svg", { static: true }) svg!: ElementRef<SVGSVGElement>;
	@ViewChild("viewport", { static: true }) viewPort!: ElementRef;
	@ViewChild(CSCommanderNodeComponent) commanderNode: CSCommanderNodeComponent | undefined;
	@ViewChild(CSControlRoomNodeComponent) controlRoomNode: CSControlRoomNodeComponent | undefined;

	@ViewChildren(CSSupportNodeComponent) supportNodes: Array<CSSupportNodeComponent> | undefined;
	@ViewChildren(CSSectorNodeComponent) sectorNodes: Array<CSSectorNodeComponent> | undefined;
	@ViewChild("dragContainer") dragContainer!: ElementRef;

	@Input() onResourceDragStart: Function = () => {};
	@Input() onResourceDragCancel: Function = () => {};
	@Input() onResourceDragEnd: Function = () => {};

	public connectors = new Array<NodeConnector>();
	public isIpad = false;
	public readonly nodeTypes = NODE_TYPE;
	public isDraggingNode: boolean = false;
	public coolDownPeriod: number = 1000;
	public disabled: boolean = false;
	public classDisabled: boolean = false;

	private initialPinchDistance: number | null = null;
	private scaleFactor: number = 1;
	private previousX: number = 0;
	private previousY: number = 0;
	private lastDist: number = 0;
	private draggingNode: NODE_TYPE | undefined;
	private draggingId: number | undefined;
	private dragging: boolean = false;
	private newSectorIdCounter: number = -1;
	private readonly text: () => LocaleMap;
	private zone: NgZone;

	private addSectorSubject = new Subject<void>();
	private addSupportSubject = new Subject<void>();

	private readonly ems: IncidentService;
	private readonly commServ: CommandStructureService;
	private readonly mssg: MessagingService;

	/**
	 * [a,b,c,d,tx,ty] where:
	 * a, b, c, d are linear transformations in which only a and d will be used to zoom in/out
	 * (since they're the scaling coefficients for the x and y coordinates respectively)
	 * tx and ty are direct translation x,y values
	 *
	 */
	private transformCoefficients = [1, 0, 0, 1, document.body.offsetWidth / 2 - 300, document.body.offsetHeight / 2 - 200];

	constructor(textProv: TextProvider, ems: IncidentService, cs: CommandStructureService, mssg: MessagingService, z: NgZone) {
		this.ems = ems;
		this.commServ = cs;
		this.mssg = mssg;
		this.text = textProv.getStringMap;
		this.zone = z;

		cs.commandStructureLoaded$.subscribe(this.setupCs);

		this.mssg.registerListener(MESSAGE_TYPE.UPDATE_CS_SECTOR, this.updateSector, false, false);
		this.mssg.registerListener(MESSAGE_TYPE.DELETE_CS_SECTOR, this.removeSectorUpdate, false, false);
		this.mssg.registerListener(MESSAGE_TYPE.DELETE_CS_SUPPORT, this.removeSupportUpdate, false, false);
		this.mssg.registerListener(MESSAGE_TYPE.UPDATE_CS_SUPPORT, this.updateSupport, false, false);
		this.mssg.registerListener(MESSAGE_TYPE.UPDATE_CS_COMMANDER, this.updateCs, false, false);
		this.mssg.registerListener(MESSAGE_TYPE.SET_CS_COMMANDER, this.updateCs, false, false);

		this.isIpad = /iPad/.test(navigator.userAgent) || (/Macintosh/.test(navigator.userAgent) && ("ontouchend" in document || navigator.maxTouchPoints > 1));
	}

	ngOnInit(): void {
		this.addSectorSubject.pipe(debounceTime(300)).subscribe(() => {
			this.executeAddSector();
		});
		this.addSupportSubject.pipe(debounceTime(300)).subscribe(() => {
			this.executeAddSupport();
		});
	}

	async ngAfterViewInit(): Promise<void> {
		if (this.isIpad) this.enableDragOnContainer();
		this.applyTransformation();
		const struct = await this.commServ.getStructureFromMission(this.ems.getCurrentIncident()!.id);
		if (struct) this.setupCs(struct);
		else this.setupNewCs();
	}

	ngOnDestroy(): void {
		this.addSupportSubject.unsubscribe();
		this.addSectorSubject.unsubscribe();
	}
	/*** Node dragging ****/

	public readonly onNodeMousedown: Function = (evt: MouseEvent, typ: NODE_TYPE, id?: number) => {
		if (this.disable) return;
		this.draggingNode = typ;
		this.draggingId = id;
		this.previousX = evt.pageX;
		this.previousY = evt.pageY;
		document.body.addEventListener("mousemove", this.onNodeDrag as any);
		document.body.addEventListener("mouseup", this.stopNodeDrag as any);

		evt.stopPropagation();
	};

	public readonly onNodeTouchdown: Function = (evt: TouchEvent, typ: NODE_TYPE, id?: number) => {
		evt.stopPropagation();
		if (this.disable || evt.touches.length > 1) return;
		this.isDraggingNode = true;
		this.draggingNode = typ;
		this.draggingId = id;
		this.previousX = evt.touches[0].clientX;
		this.previousY = evt.touches[0].clientY;
		document.body.addEventListener("touchmove", this.onNodeDrag as any, { passive: false });
		document.body.addEventListener("touchend", this.stopNodeDrag as any, { passive: false });
	};

	public readonly onViewportTouchdown = (evt: TouchEvent): void => {
		evt.preventDefault();
		if (evt.touches.length === 1) {
			this.saveNodeChanges();
			this.previousX = evt.touches[0].clientX;
			this.previousY = evt.touches[0].clientY;
			document.body.addEventListener("touchmove", this.onViewportDrag as any, { passive: false });
			document.body.addEventListener("touchend", this.stopViewportTransform as any);
		}
		if (evt.touches.length === 2) {
			this.stopViewportTransform();
			document.body.addEventListener("touchmove", this.isIpad ? this.handleIpadZoom.bind(this) : this.handleTouchZoom.bind(this), { passive: false });
			document.body.addEventListener("touchend", this.stopViewportTransform as any);
		}
	};

	public readonly onViewportMousedown: Function = (evt: MouseEvent) => {
		this.saveNodeChanges();
		this.previousX = evt.pageX;
		this.previousY = evt.pageY;
		document.body.addEventListener("mousemove", this.onViewportDrag as any);
		document.body.addEventListener("mouseup", this.stopViewportTransform as any);
	};

	public readonly saveNodeChanges: Function = () => {
		if (this.commanderNode) this.commanderNode.saveChanges();
		if (this.controlRoomNode) this.controlRoomNode.saveChanges();
		if (this.sectorNodes) this.sectorNodes.forEach((sector) => sector.saveChanges());
	};

	public readonly onViewportWheelMovement: Function = (evt: WheelEvent) => {
		const deltaZoom = this.transformCoefficients[0] - evt.deltaY / 1000 <= 0.5 ? 0.5 : this.transformCoefficients[0] - evt.deltaY / 1000;
		this.transformCoefficients[0] = this.transformCoefficients[3] = deltaZoom;
		this.applyTransformation();
	};

	public readonly preventTransform: Function = (evt: MouseEvent | TouchEvent | WheelEvent) => {
		if (evt instanceof TouchEvent && evt.touches.length === 2) this.onViewportTouchdown(evt);
		else evt.stopPropagation();
	};

	public addSector(): void {
		if (!this.disabled) {
			this.addSectorSubject.next();
		}
	}

	public addSupport(): void {
		if (!this.disabled) {
			this.addSupportSubject.next();
		}
	}

	public readonly updateSector: Function = (sector: CSSector) => {
		this.setupConnectors();
	};

	public readonly removeSectorUpdate: (sector: CSSector) => void = (sector) => {
		const idx = this.commandStructure?.sectors.findIndex((e) => e.id === sector.id);
		if (idx && idx > -1) this.commandStructure?.sectors.splice(idx, 1);
		this.setupConnectors();
	};

	public readonly updateSupport: Function = (support: CSSupport) => {
		this.setupConnectors();
	};

	public readonly removeSupportUpdate: (support: CSSupport) => void = (support) => {
		const idx = this.commandStructure?.supports.findIndex((e) => e.id === support.id);
		if (idx && idx > -1) this.commandStructure?.supports.splice(idx, 1);
		this.setupConnectors();
	};

	public readonly removeSupport: Function = async (support: CSSupport) => {
		const ans = await this.commServ.deleteSupport(support);
		if (ans) this.removeConnector(support);
	};

	public readonly removeSector: Function = async (sector: CSSector) => {
		let ans = false;
		if (sector.id > -1) ans = await this.commServ.deleteSector(sector);
		else {
			const idx = this.commandStructure!.sectors.findIndex((e) => e.id === sector.id);
			this.commandStructure!.sectors.splice(idx, 1);
			ans = true;
		}
		if (ans) this.removeConnector(sector);
	};

	public isIncidentClosed(): boolean {
		const currentIncident = this.ems.getCurrentIncident();
		return currentIncident?.closed ?? false;
	}
	private async executeAddSector(): Promise<void> {
		if (this.disabled || this.isIncidentClosed()) return;
		const newSectorName = `${this.text().UNASSIGNED_SECTOR} ${this.getIndexForNewSectorName()}`;
		await this.createNewSectorType(newSectorName, NODE_TYPE.SECTOR, "Sector");
	}

	private async executeAddSupport(): Promise<void> {
		if (this.disabled || this.isIncidentClosed()) return;
		const newSupportName = `${this.text().SUPPORT_SECTOR} ${this.getIndexForNewSupportName()}`;
		await this.createNewSectorType(newSupportName, NODE_TYPE.SUPPORT, "Support");
	}

	private async createNewSectorType(name: string, nodeType: NODE_TYPE, title: string): Promise<void> {
		if (!this.commandStructure || this.disabled) {
			return;
		}

		this.disabled = true;
		const startTime = Date.now();
		const newSectorId = this.newSectorIdCounter--;
		const pos = nodeType === NODE_TYPE.SUPPORT ? this.getInitialSupportPosition() : this.getInitialSectorPosition();
		const newSector = new CSSector(-1, this.commandStructure.commander.id, newSectorId, name);
		newSector.title = title;
		newSector.x = pos.x;
		newSector.y = pos.y;

		this.commandStructure.sectors.push(newSector);

		try {
			await this.commServ.saveSector(newSector);
			const endTime = Date.now();
			const responseTime = endTime - startTime;
			this.coolDownPeriod = responseTime + 500;
			this.addSectorConnector(newSector);
		} catch (error) {
			console.error(error);
		} finally {
			setTimeout(() => (this.disabled = false), this.coolDownPeriod);
		}
	}

	private readonly onViewportDrag = (evt: MouseEvent | TouchEvent): void => {
		let newX: number, newY: number;
		if (evt instanceof MouseEvent) {
			newX = evt.pageX;
			newY = evt.pageY;
		} else {
			newX = evt.touches[0].clientX;
			newY = evt.touches[0].clientY;
			evt.preventDefault();
		}
		this.handleDrag(newX, newY);
	};

	private handleDrag = (x: number, y: number): void => {
		const deltaX = x - this.previousX;
		const deltaY = y - this.previousY;
		this.previousX = x;
		this.previousY = y;
		this.transformCoefficients[4] += deltaX;
		this.transformCoefficients[5] += deltaY;
		this.applyTransformation();
	};

	private readonly setupConnectors: () => void = () => {
		if (!this.commandStructure) return;
		this.connectors = [];
		this.connectors.push(
			new NodeConnector(
				() => {
					return this.isIpad ? (this.commandStructure ? this.commandStructure.commander.x + 150 + 125 : 0) : this.commandStructure ? this.commandStructure.commander.x + 125 : 0;
				},
				() => {
					return this.isIpad ? (this.commandStructure ? this.commandStructure.commander.y + 150 + 50 : 0) : this.commandStructure ? this.commandStructure.commander.y + 50 : 0;
				},
				() => {
					return this.isIpad ? (this.commandStructure ? this.commandStructure.commander.x + 150 + 425 : 0) : this.commandStructure ? this.commandStructure.commander.x + 425 : 0;
				},
				() => {
					return this.isIpad ? (this.commandStructure ? this.commandStructure.commander.y + 150 - 150 + 75 / 2 : 0) : this.commandStructure ? this.commandStructure.commander.y - 150 + 75 / 2 : 0;
				},
				250,
				100,
				250,
				75,
				this.commandStructure.commander
			)
		);
		this.commandStructure.sectors.forEach(this.addSectorConnector);
	};

	private readonly addSectorConnector: (sector: CSSector) => void = (sector) => {
		if (!this.commandStructure) return;
		this.connectors.push(
			new NodeConnector(
				() => {
					return this.isIpad ? (this.commandStructure ? this.commandStructure.commander.x + 150 + 125 : 0) : this.commandStructure ? this.commandStructure.commander.x + 125 : 0;
				},
				() => {
					return this, this.isIpad ? (this.commandStructure ? this.commandStructure.commander.y + 150 + 50 : 0) : this.commandStructure ? this.commandStructure.commander.y + 30 : 0;
				},
				() => {
					return sector.x + 125;
				},
				() => {
					return sector.y + (sector.id_area > -1 ? 160 : 60) / 2;
				},
				265,
				90,
				265,
				sector.id_area > -1 ? 160 : 60,
				sector
			)
		);
	};

	private readonly removeConnector: (elem: CSBlock) => void = (elem) => {
		const idx = this.connectors.findIndex((e) => e.ref && e.ref === elem);
		if (idx > -1) this.connectors.splice(idx, 1);
	};

	private readonly isColliding: (item: { x: number; y: number }, env: Array<CSBlock>) => boolean = (item, env) => {
		for (let i = 0; i < env.length; i++) {
			if (Math.abs(item.x - env[i].x) < 300 && Math.abs(item.y - env[i].y) < 200) {
				return true;
			}
		}
		return false;
	};

	private getInitialSupportPosition(): { x: number; y: number } {
		const baseX = this.commandStructure!.commander.x;
		const baseY = this.commandStructure!.commander.y;
		const ipadAdjustment = { x: 150, y: 150 };
		const defaultAdjustment = { x: 0, y: 0 };

		const adjustment = this.isIpad ? ipadAdjustment : defaultAdjustment;

		let ansX = baseX + adjustment.x - (this.isIpad ? 325 : 450);
		let ansY = baseY + adjustment.y - (this.isIpad ? 175 : 200);

		let items: Array<CSBlock> = this.commandStructure!.sectors;
		while (this.isColliding({ x: ansX, y: ansY }, items)) {
			ansY += 350;
			if (ansY > baseY + adjustment.y + 600) {
				ansY = baseY + adjustment.y - (this.isIpad ? 325 : 350);
				ansX -= this.isIpad ? 250 : 350;
			}
		}

		return { x: ansX, y: ansY };
	}

	private getInitialSectorPosition(): { x: number; y: number } {
		const baseX = this.commandStructure!.commander.x;
		const baseY = this.commandStructure!.commander.y;

		const ipadAdjustmentX = 150;
		const ipadAdjustmentY = 150;

		let ansX = baseX + (this.isIpad ? ipadAdjustmentX : 0) - 300;
		let ansY = baseY + (this.isIpad ? ipadAdjustmentY : 0) + 300;

		let items: Array<CSBlock> = this.commandStructure!.supports.concat(this.commandStructure!.sectors as any);

		while (this.isColliding({ x: ansX, y: ansY }, items)) {
			ansX += 300;
			if (ansX > baseX + (this.isIpad ? ipadAdjustmentX : 0) + 300) {
				ansY += 400;
				ansX = baseX + (this.isIpad ? ipadAdjustmentX : 0) - 300;
			}
		}

		return { x: ansX, y: ansY };
	}

	private readonly updateCs: (cs?: CommandStructure) => void = async (cs) => {
		this.applyTransformation(); // wat

		const struct = await this.commServ.getStructureFromMission(this.ems.getCurrentIncident()!.id);

		if (struct) {
			this.setupCs(struct);
		} else {
			this.setupNewCs();
		}
	};

	private readonly setupCs: (cs?: CommandStructure) => void = (cs) => {
		if (cs?.id_mission !== this.ems.getCurrentIncident()!.id) return;
		this.commandStructure = cs;
		this.setupConnectors();
	};

	private readonly setupNewCs: Function = () => {
		this.commandStructure = new CommandStructure(new CSCommander(-1, this.ems.getCurrentIncident()!.id, ""));
		this.setupConnectors();
	};

	private readonly onNodeDrag: Function = (evt: MouseEvent | TouchEvent) => {
		this.isDraggingNode = true;
		evt.preventDefault();

		let newX: number, newY: number;
		if (evt instanceof MouseEvent) {
			newX = evt.pageX;
			newY = evt.pageY;
		} else {
			newX = evt.touches[0].clientX;
			newY = evt.touches[0].clientY;
		}
		const deltaX = newX - this.previousX;
		const deltaY = newY - this.previousY;
		if (deltaX || deltaY) {
			this.dragging = true;
			const zoomCoefficient = this.transformCoefficients[0];
			const moveNode = (node: any) => {
				node.x += deltaX / zoomCoefficient;
				node.y += deltaY / zoomCoefficient;
			};
			switch (this.draggingNode) {
				case NODE_TYPE.COMMANDER:
					if (this.commandStructure?.commander) {
						moveNode(this.commandStructure.commander);
					}
					break;
				case NODE_TYPE.SUPPORT:
					const support = this.commandStructure?.supports.find((e) => e.id === this.draggingId);
					if (support) {
						moveNode(support);
					}
					break;
				case NODE_TYPE.SECTOR:
					const sector = this.commandStructure?.sectors.find((e) => e.id === this.draggingId);
					if (sector) {
						moveNode(sector);
					}
					break;
			}
			this.previousX = newX;
			this.previousY = newY;
		}
	};

	private enableDragOnContainer(): void {
		const container = this.dragContainer.nativeElement;
		let startX = 0;
		let startY = 0;
		let translateX = 0;
		let translateY = 0;

		container.addEventListener(
			"touchstart",
			(event: TouchEvent) => {
				if (this.isDraggingNode || this.isPersonnelBeingDragged || this.isResourceBeingDragged) {
					return;
				}

				startX = event.touches[0].clientX - translateX;
				startY = event.touches[0].clientY - translateY;
				event.preventDefault();
			},
			{ passive: false }
		);

		container.addEventListener(
			"touchmove",
			(event: TouchEvent) => {
				if (this.isDraggingNode || this.isPersonnelBeingDragged || this.isResourceBeingDragged) {
					event.preventDefault();
					return;
				}

				translateX = event.touches[0].clientX - startX;
				translateY = event.touches[0].clientY - startY;
				container.style.transform = `translate(${translateX}px, ${translateY}px)`;
				event.preventDefault();
			},
			{ passive: false }
		);
	}

	private handleTouchZoom(evt: TouchEvent): void {
		const touch1 = evt.touches[0];
		const touch2 = evt.touches[1];
		if (touch1 && touch2) {
			evt.preventDefault();
			this.dragging && this.stopNodeDrag();
			const p1: TouchPoint = { x: touch1.clientX, y: touch1.clientY };
			const p2: TouchPoint = { x: touch2.clientX, y: touch2.clientY };
			const dist = Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2));
			if (!this.lastDist) this.lastDist = dist;
			let scale = this.transformCoefficients[0] * (dist / this.lastDist);
			scale = scale < 0.3 ? 0.3 : scale > 3 ? 3 : scale;
			this.transformCoefficients[0] = this.transformCoefficients[3] = scale;
			!this.isIpad ? this.applyTransformation() : this.applyScaleToSvg(this.scaleFactor);
			this.lastDist = dist;
		}
	}

	private handleIpadZoom(evt: TouchEvent): void {
		if (evt.touches.length === 2) {
			evt.preventDefault();
			const touch1 = evt.touches[0];
			const touch2 = evt.touches[1];

			const currentPinchDistance = Math.sqrt(Math.pow(touch2.pageX - touch1.pageX, 2) + Math.pow(touch2.pageY - touch1.pageY, 2));

			if (this.initialPinchDistance == null) {
				this.initialPinchDistance = currentPinchDistance;
			} else {
				const pinchScale = currentPinchDistance / this.initialPinchDistance;
				this.scaleFactor *= pinchScale;
				this.applyScaleToSvg(this.scaleFactor);
				this.initialPinchDistance = currentPinchDistance;
			}
		}
	}

	private applyScaleToSvg(scaleFactor: number): void {
		if (this.isIpad) {
			this.svg.nativeElement.style.transform = `scale(${scaleFactor})`;
			this.svg.nativeElement.style.transformOrigin = "0 0";
		}
	}

	private readonly stopNodeDrag: Function = () => {
		this.isDraggingNode = false;
		if (this.dragging) {
			switch (this.draggingNode) {
				case NODE_TYPE.COMMANDER:
					// This IF avoids that drag events from Command Screen triggers a new empty Commander :)
					if (this.commandStructure?.commander.id_commander !== -1) {
						this.commServ.saveCommander(this.commandStructure!.commander);
					}
					break;
				case NODE_TYPE.SUPPORT:
					const support = this.commandStructure!.supports.find((e) => e.id === this.draggingId);
					if (support && support.id > -1) this.commServ.saveSupport(support);
					break;
				case NODE_TYPE.SECTOR:
					const sector = this.commandStructure!.sectors.find((e) => e.id === this.draggingId);
					if (sector && sector.id > -1) this.commServ.saveSector(sector);
			}
		}
		this.draggingNode = this.draggingId = undefined;
		this.dragging = false;

		document.body.removeEventListener("mousemove", this.onNodeDrag as any);
		document.body.removeEventListener("touchmove", this.onNodeDrag as any);

		document.body.removeEventListener("mouseup", this.stopNodeDrag as any);
		document.body.removeEventListener("touchend", this.stopNodeDrag as any);
	};
	private readonly stopViewportTransform: Function = () => {
		this.lastDist = 0;
		document.body.removeEventListener("mousemove", this.onViewportDrag as any);
		document.body.removeEventListener("mouseup", this.stopViewportTransform as any);
		document.body.removeEventListener("touchmove", this.onViewportDrag as any);
		document.body.removeEventListener("touchmove", this.handleTouchZoom as any);
		document.body.removeEventListener("touchmove", this.handleIpadZoom as any);
		document.body.removeEventListener("touchend", this.stopViewportTransform as any);
		this.initialPinchDistance = null;
	};

	private readonly applyTransformation: Function = () => {
		const [a, b, c, d, e, f] = this.transformCoefficients;
		(this.viewPort.nativeElement as HTMLElement).setAttribute("transform", `matrix(${a},${b},${c},${d},${this.isIpad ? 0 : e},${this.isIpad ? 0 : f})`);
	};

	private getIndexForNewSectorName(): number {
		if (!this.commandStructure || !this.commandStructure.sectors.length) return 1;

		const existingSectorIndices = this.commandStructure.sectors
			.map((sector) => {
				const match = sector.name.match(new RegExp(`${this.text().UNASSIGNED_SECTOR} (\\d+)`));
				return match ? parseInt(match[1], 10) : 0;
			})
			.filter((index) => !isNaN(index));

		const highestSectorIndex = existingSectorIndices.length === 0 ? 0 : Math.max(...existingSectorIndices);

		return highestSectorIndex + 1;
	}

	private getIndexForNewSupportName(): number {
		if (!this.commandStructure || !this.commandStructure.sectors.length) return 1;

		const existingSupportIndices = this.commandStructure.sectors
			.map((sector) => {
				const match = sector.name.match(new RegExp(`${this.text().SUPPORT_SECTOR} (\\d+)`));
				return match ? parseInt(match[1], 10) : 0;
			})
			.filter((index) => !isNaN(index));

		const highestSupportIndex = existingSupportIndices.length === 0 ? 0 : Math.max(...existingSupportIndices);

		return highestSupportIndex + 1;
	}
}

enum NODE_TYPE {
	COMMANDER,
	SUPPORT,
	SECTOR
}
class NodeConnector {
	public readonly ref: CSBlock | undefined;

	private sourceX: () => number;
	private sourceY: () => number;
	private sourceW: number;
	private sourceH: number;
	private destW: number;
	private destH: number;
	private sourceTangentX: () => number;
	private sourceTangentY: () => number;
	private middleTangentX: () => number;
	private middleTangentY: () => number;
	private destTangentX: () => number;
	private destTangentY: () => number;
	private destX: () => number;
	private destY: () => number;

	constructor(sourceX: () => number, sourceY: () => number, destX: () => number, destY: () => number, sourceW: number, sourceH: number, destW: number, destH: number, reference?: CSBlock) {
		this.sourceX = sourceX;
		this.sourceY = sourceY;
		this.destX = destX;
		this.destY = destY;
		this.sourceW = sourceW;
		this.sourceH = sourceH;
		this.destW = destW;
		this.destH = destH;
		this.ref = reference;
		this.sourceTangentX = () => {
			switch (this.getSourceOrientation()) {
				case "N":
					return this.sourceX();
				case "W":
					return this.sourceX() - 25 - sourceW / 2;
				case "E":
					return this.sourceX() + 25 + sourceW / 2;
				case "S":
					return this.sourceX();
			}
		};
		this.sourceTangentY = () => {
			switch (this.getSourceOrientation()) {
				case "N":
					return this.sourceY() - 25 - sourceH / 2;
				case "W":
					return this.sourceY();
				case "E":
					return this.sourceY();
				case "S":
					return this.sourceY() + 25 + sourceH / 2;
			}
		};
		this.destTangentX = () => {
			switch (this.getDestinationOrientation()) {
				case "N":
					return this.destX();
				case "W":
					return this.destX() - 25 - destW / 2;
				case "E":
					return this.destX() + 25 + destW / 2;
				case "S":
					return this.destX();
			}
		};
		this.destTangentY = () => {
			switch (this.getDestinationOrientation()) {
				case "N":
					return this.destY() - 25 - destH / 2;
				case "W":
					return this.destY();
				case "E":
					return this.destY();
				case "S":
					return this.destY() + 25 + destH / 2;
			}
		};
		this.middleTangentX = this.sourceTangentX;
		this.middleTangentY = this.destTangentY;
	}

	public readonly getPath: () => string = () => {
		const path = `M ${this.sourceX()}, ${this.sourceY()} L ${this.sourceTangentX()}, ${this.sourceTangentY()} ${this.middleTangentX()}, ${this.middleTangentY()} ${this.destTangentX()}, ${this.destTangentY()} ${this.destX()}, ${this.destY()}`;
		return path;
	};

	private readonly getSourceOrientation: () => "N" | "E" | "S" | "W" = () => {
		if (this.destY() > this.sourceY() + this.sourceH) return "S";
		if (this.destY() < this.sourceY()) return "N";
		if (this.destX() > this.sourceX() + this.sourceW) return "E";
		return "W";
	};

	private readonly getDestinationOrientation: () => "N" | "E" | "S" | "W" = () => {
		if (this.destY() > this.sourceY() + this.sourceH) return "N";
		if (this.destY() < this.sourceY()) return "S";
		if (this.destX() > this.sourceX() + this.sourceW) return "W";
		return "E";
	};
}

type TouchPoint = {
	x: number;
	y: number;
};
