<div class="main-black-mask" (click)="onCancel()" data-add-action-name="closeImageViewer"></div>
<div
	class="image-container"
	(touchstart)="onTouchStart($event)"
	(touchmove)="onTouchMove($event)"
	(touchend)="onTouchEnd()"
>
	<div class="image-viewer">
		<app-white-cross class="white-cross" (clickCb)="onCancel()"></app-white-cross>
		<img [src]="safeSrc" alt="Image Viewer" />
		<div
			*ngIf="images.length > 1 && currentIndex > 0"
			class="chevron-left"
			(click)="previousImage()"
			[class.center]="images.length === 2"
		></div>
		<div
			*ngIf="images.length > 1 && currentIndex < images.length - 1"
			class="chevron-right"
			(click)="nextImage()"
			[class.center]="images.length === 2"
		></div>
	</div>
</div>
