import { Component } from "@angular/core";
import { SCREEN } from "src/app/global/constants/enums/screens";
import { URLMap } from "src/app/global/constants/enums/url-map";
import { LocaleMap } from "src/app/global/constants/text/text-interface";
import { TextProvider } from "src/app/global/constants/text/text-provider";
import { NavigationService } from "src/app/navigation/navigation.service";

@Component({
	selector: "app-glass-background",
	templateUrl: "./glass-background.component.html",
	styleUrls: ["./glass-background.component.css"]
})
export class GlassBackgroundComponent {
	public bottomPage: string = "OFV";

	public text: () => LocaleMap;

	private navService: NavigationService;

	constructor(textProvider: TextProvider, nav: NavigationService) {
		this.text = textProvider.getStringMap;
		this.navService = nav;
		this.bottomPage = this.navService.getCurrentBottomPage() === SCREEN.HOME ? this.text().HOMEPAGE : "OFV";
	}

	get isOffline(): boolean {
		return URLMap.use_backup;
	}

	public goBack(): void {
		this.navService.goBottom();
	}
}
