import { Component, Input, OnInit } from "@angular/core";
import { LocaleMap } from "src/app/global/constants/text/text-interface";
import { TextProvider } from "src/app/global/constants/text/text-provider";

@Component({
	selector: "app-online-offline-banner",
	templateUrl: "./online-offline-banner.component.html",
	styleUrls: ["./online-offline-banner.component.css"]
})
export class OnlineOfflineBannerComponent {
	@Input() showOnlineBanner: boolean = false;
	@Input() showIncidentInfo: boolean = false;
	@Input() isLoginPage: boolean = false;
	@Input() isOffline: boolean = false;

	public isTooltipVisible: boolean = false;
	public text: () => LocaleMap;

	constructor(textProv: TextProvider) {
		this.text = textProv.getStringMap;
	}

	public toggleTooltip(): void {
		this.isTooltipVisible = !this.isTooltipVisible;
	}
}
