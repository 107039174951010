import { Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";

@Pipe({
	name: "sanitizeUrl"
})
export class UrlSanitizerPipe implements PipeTransform {
	constructor(private sanitizer: DomSanitizer) {}

	transform(value: string): SafeResourceUrl {
		return this.sanitizer.bypassSecurityTrustResourceUrl(value);
	}
}
