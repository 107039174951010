import { Component, OnDestroy, OnInit } from "@angular/core";
import { AUTH } from "src/app/global/constants/enums/auth";
import { LoginService } from "../../login.service";
import { ProbeService } from "../../../http/probe.service";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { URLMap } from "src/app/global/constants/enums/url-map";

@Component({
	selector: "app-login-component",
	templateUrl: "login.component.html"
})
export class LoginComponent implements OnInit, OnDestroy {
	public authMode: AUTH = AUTH.UNBLUR;
	public credentials: { username: string; password: string } | false = false;

	public offline = false;

	private destroy$: Subject<boolean> = new Subject();
	private readonly loginServ: LoginService;
	private readonly probeService: ProbeService;

	constructor(login: LoginService, probeService: ProbeService) {
		this.loginServ = login;
		this.authMode = !navigator.onLine ? AUTH.UNBLUR : login.authMode;
		this.credentials = login.autologin;
		this.probeService = probeService;

		this.offline = URLMap.use_backup;
	}

	ngOnInit(): void {
		if(!this.offline) this.loginServ.logWithStoredSession();
		this.subscribeToProbeService();
	}

	ngOnDestroy(): void {
		this.destroy$.next(true);
	}

	get authenticated(): boolean {
		return this.loginServ.authenticated;
	}

	public readonly log = (params?: { username: string; password: string; captcha?: string }): Promise<void> => {
		return this.loginServ.log(params ? params : false);
	};

	private subscribeToProbeService(): void {
		this.probeService.$systemOffline.pipe(takeUntil(this.destroy$)).subscribe((isOffline) => {
			this.offline = isOffline;
		});
	}
}
