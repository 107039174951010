<div *ngIf="showOnlineBanner" class="banner online">
	<lottie-player
		src="{{ 'resources/lottie/online.json' | baseHref }}"
		background="transparent"
		speed="1"
		autoplay
	></lottie-player>
	<span class="banner-message">{{ text().ONLINE_MODE_ENABLED }}</span>
</div>

<div
	*ngIf="!isLoginPage && isOffline"
	class="banner"
	[class.animate-offline-banner]="showIncidentInfo"
>
	<div class="display-flex relative">
		<lottie-player
			src="{{ 'resources/lottie/offline.json' | baseHref }}"
			background="transparent"
			speed="1"
			autoplay
			[class.pointer]="showIncidentInfo"
			(click)="showIncidentInfo ? toggleTooltip() : false"
		></lottie-player>

		<div class="display-flex" [class.hide]="showIncidentInfo">
			<span class="banner-message">{{ text().OFFLINE_MODE_ENABLED }}</span>
			<img
				src="resources/img/information.svg"
				alt="information icon"
				class="information-icon"
				(click)="toggleTooltip()"
			/>
		</div>
		<app-info-tooltip
			*ngIf="isTooltipVisible"
			[style.width.px]="310"
			[tooltipText]="text().SESSION_SYNC"
			[arrowPosition]="6"
			class="tooltip-text-container"
			[class.tooltip-position-adjust]="showIncidentInfo"
		></app-info-tooltip>
	</div>
</div>
