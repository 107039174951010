import { Component } from "@angular/core";
import { UserService } from "./settings/user/user.service";
import { AutomationService } from "./global/automation/automation.service";
import { NavigationService } from "./navigation/navigation.service";
import { ConstantsService } from "./global/constants/constants.service";
import { LoginService } from "./login/login.service";
import { SsoService } from "./login/sso.service";
import { WebRequestFactory } from "./http/web.request.factory";
import { LocaleMap } from "./global/constants/text/text-interface";
import { TextProvider } from "./global/constants/text/text-provider";

@Component({
	selector: "app-root",
	templateUrl: "./app.html",
	styleUrls: ["./app.component.css"]
})
export class AppComponent {
	title = "IRIS";

	private readonly ssoService: SsoService;
	private readonly loginService: LoginService;

	private readonly text: () => LocaleMap

	constructor(
		// all services that require to be instantiated on bootup are injected here
		constantsService: ConstantsService,
		userService: UserService,
		navService: NavigationService,
		automation: AutomationService,
		login: LoginService,
		sso: SsoService,
		wreq: WebRequestFactory,
		textProv: TextProvider
	) {
		this.text = textProv.getStringMap;
		this.loginService = login;
		this.ssoService = sso;
		this.loginService.$onInitialLoadIng.subscribe(() => (this.loginService.hideSplashScreen = true));

		if (constantsService.CLARITY_ID) this.loadClarity(constantsService.CLARITY_ID);
	} // code that needs to run on application bootup should go here

	get authenticated(): boolean {
		return this.ssoService.authenticated || this.loginService.logged;
	}

	public readonly initialLoading = (): boolean => {
		return !this.loginService.splashScreenReady || !this.loginService.hideSplashScreen;
	};

	public readonly redirectToLogin = (): void => {
		if(!location.href.match(/login/)) this.loginService.onLogout();
	};

	public readonly splashScreenLoaded = (): boolean => {
		return (this.loginService.splashScreenReady = true);
	};

	public readonly loadClarity = (clarity_id: string): void => {
		(function (c, l, a, r, i, t, y) {
			// @ts-ignore
			c[a] =
				// @ts-ignore
				c[a] ||
				function () {
					// @ts-ignore
					(c[a].q = c[a].q || []).push(arguments);
				};
			// @ts-ignore
			t = l.createElement(r);
			// @ts-ignore
			t.async = 1;
			// @ts-ignore
			t.src = "https://www.clarity.ms/tag/" + i;
			// @ts-ignore
			y = l.getElementsByTagName(r)[0];
			// @ts-ignore
			y.parentNode.insertBefore(t, y);
		})(window, document, "clarity", "script", clarity_id);
	};
}
