<div class="main-black-mask" (click)="cancel(); $event.stopPropagation()"></div>
<div class="alert-dialog">
	<div class="white-cross-container">
		<app-white-cross class="dialog-cross-button" (clickCb)="cancel()"></app-white-cross>
	</div>

	<div class="row-upper">
		{{ headerText }}
	</div>
	<div class="row-middle">
		<span class="dialog-text">
			{{ bodyText }}
		</span>
	</div>
	<div class="row-lower">
		<button
			data-add-action-name="confirm-dialog-cancel"
			class="main-blue-gradient-border-button margin-right-12px"
			(click)="cancel()"
		>
			{{ cancelText }}
		</button>
		<button
			data-add-action-name="confirm-dialog-confirm"
			class="main-blue-gradient-button"
			[disabled]="disabled"
			(click)="confirm()"
		>
			{{ confirmText }}
		</button>
	</div>
</div>
