import { AfterContentInit, AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from "@angular/core";

@Component({
	selector: "app-color-slider",
	templateUrl: "./color-slider.component.html",
	styleUrls: ["./color-slider.component.css"]
})
export class ColorSliderComponent implements AfterViewInit, OnChanges {
	@ViewChild("canvas") canvas!: ElementRef<HTMLCanvasElement>;

	@Input() hue: string = "";

	@Output() selectedHue: EventEmitter<string> = new EventEmitter<string>();
	@Output() changeOriginOut: EventEmitter<number> = new EventEmitter();

	@HostListener("window:mouseup", ["$event"])
	public readonly onMouseUp: (evt: MouseEvent) => void = (evt) => {
		this.mousedown = false;
	};

	private ctx: CanvasRenderingContext2D | null = null;
	private mousedown: boolean = false;
	private selectedHeight: number | undefined = undefined;
	private firstLoad: boolean = true;

	private auxHue: string = "";

	constructor() {}

	ngAfterViewInit() {
		this.firstLoad = false;
		this.draw();
		this.selectedHeight = this.getColorHeight(this.hue);
		this.auxHue = this.hue;
		this.drawCirle(this.selectedHeight);
	}

	// Redraw when a hue is selected
	ngOnChanges(changes: SimpleChanges) {
		if (changes["hue"] && !this.firstLoad && this.hue !== this.auxHue) {
			this.selectedHeight = this.getColorHeight(this.hue);
			if (this.selectedHeight >= 0) {
				this.draw();
				this.selectedHue.emit(this.hue);
			}
		}
	}

	public readonly emitColor: (y: number) => void = (y) => {
		const rgbaColor = this.getColorAtPosition(y);
		this.auxHue = rgbaColor;
		this.changeOriginOut.emit(2);
		this.selectedHue.emit(rgbaColor);
	};

	public readonly getColorAtPosition: (y: number) => string = (y) => {
		const width = this.canvas.nativeElement.width;
		const imageData = this.ctx!.getImageData(width / 2, y, 1, 1).data;
		return "rgba(" + imageData[0] + "," + imageData[1] + "," + imageData[2] + ",1)";
	};

	public readonly getColorHeight: (rgba: string) => number = (rgbaColor) => {
		let min_distance = 765;
		let min_heigth = 0;

		const rgb = rgbaColor.match(/^rgba?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i);
		const red = parseInt(rgb![1]);
		const green = parseInt(rgb![2]);
		const blue = parseInt(rgb![3]);

		const ctx = this.ctx!;
		const width = ctx.canvas.width;
		const height = ctx.canvas.height;
		const data = ctx.getImageData(0, 0, width, height);
		const buffer = data.data;

		for (let y = 0; y < height; y++) {
			let p = y * 4 * width;

			let aux_distance = Math.abs(buffer[p] - red) + Math.abs(buffer[p + 1] - green) + Math.abs(buffer[p + 2] - blue);

			if (aux_distance < min_distance) {
				min_distance = aux_distance;
				min_heigth = y;
			}
		}
		return min_heigth;
	};

	// MOUSE EVENTS
	public readonly onMouseDown: (evt: MouseEvent) => void = (evt) => {
		this.mousedown = true;
		this.selectedHeight = evt.offsetY;
		this.draw();
		this.emitColor(evt.offsetY);
	};

	public readonly onMouseMove: (evt: MouseEvent) => void = (evt) => {
		if (this.mousedown) {
			this.selectedHeight = evt.offsetY;
			this.draw();
			this.emitColor(evt.offsetY);
		}
	};

	private readonly fillGradient: (width: number, heigh: number) => void = (width, height) => {
		const ctx = this.ctx!;
		const gradient = ctx.createLinearGradient(0, 0, 0, height);
		gradient.addColorStop(0, COLOR.RED);
		gradient.addColorStop(0.17, COLOR.RED_BLUE);
		gradient.addColorStop(0.34, COLOR.BLUE);
		gradient.addColorStop(0.51, COLOR.GREEN_BLUE);
		gradient.addColorStop(0.68, COLOR.GREEN);
		gradient.addColorStop(0.85, COLOR.RED_GREEN);
		gradient.addColorStop(1, COLOR.RED);

		ctx.beginPath();
		ctx.rect(0, 0, width, height);
		ctx.fillStyle = gradient;
		ctx.fill();
		ctx.closePath();
	};

	private readonly drawCirle: (heigh: number) => void = (height) => {
		const ctx = this.ctx!;
		const width = this.canvas.nativeElement.width;
		ctx.strokeStyle = "white";
		ctx.fillStyle = "white";
		ctx.beginPath();
		ctx.arc(width / 2, height, width / 2, 0, 2 * Math.PI);
		ctx.lineWidth = 2;
		ctx.stroke();
	};

	private readonly draw: () => void = () => {
		if (!this.ctx) {
			this.ctx = this.canvas.nativeElement.getContext("2d");
		}

		const width = this.canvas.nativeElement.width;
		const height = this.canvas.nativeElement.height;
		this.ctx!.clearRect(0, 0, width, height);

		this.fillGradient(width, height);

		if (this.selectedHeight! >= 0) {
			this.drawCirle(this.selectedHeight!);
		}
	};
}

enum COLOR {
	RED = "rgba(255, 0, 0, 1)",
	RED_BLUE = "rgba(255, 0, 255, 1)",
	BLUE = "rgba(0, 0, 255, 1)",
	GREEN_BLUE = "rgba(0, 255, 255, 1)",
	GREEN = "rgba(0, 255, 0, 1)",
	RED_GREEN = "rgba(255, 255, 0, 1)"
}
