import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FsgBuilding } from "src/app/dto/fsg/fsg-building";
import { SettingsParser } from "src/app/global/constants/settings.parser";
import { LocaleMap } from "src/app/global/constants/text/text-interface";
import { TextProvider } from "src/app/global/constants/text/text-provider";

@Component({
	selector: "app-select-address-popup",
	templateUrl: "select-address.component.html",
	styleUrls: ["select-address.css"]
})
export class SelectAddressPopupComponent implements OnInit {
	@Input() fsg!: FsgBuilding;

	@Output() cancel = new EventEmitter<void>();
	@Output() confirm = new EventEmitter<string>();

	public currentAddress: string = "";

	public readonly text: () => LocaleMap;
	public results = new Array();

	private gbox: any;

	constructor(textProv: TextProvider) {
		this.text = textProv.getStringMap;
	}

	ngOnInit(): void {
		if (!this.fsg) throw "SelectAddressPopup component initialized without fsg";
		this.currentAddress = this.fsg.address;
		//	this.setGeolocator();
		const geo = new google.maps.Geocoder();
		geo.geocode({ location: new google.maps.LatLng(this.fsg.lat, this.fsg.lon) }, (results) => {
			const restemp = [];
			if (!results) return;
			for (let i = 0; i < results.length; i++) {
				restemp.push(results[i].formatted_address);
			}
			this.results = restemp;
		});
	}

	public readonly isGeocoderEnabled = (): boolean => {
		if (!SettingsParser.settings.google) return false;
		if (!SettingsParser.settings.google.key || SettingsParser.settings.google.testing) return false;
		return true;
	};

	public readonly setAddress = (addr: string): void => {
		this.currentAddress = addr;
		this.update();
	};

	private readonly update: Function = () => {
		this.confirm.emit(this.currentAddress);
	};

	private readonly getInputElement: Function = (elem: HTMLElement) => {
		if (elem instanceof HTMLInputElement) return elem;
		if (!elem.hasChildNodes) return null;
		let i = 0;
		while (i < elem.childElementCount) {
			let ans = this.getInputElement(elem.children[i]);
			if (ans !== null) return ans;
			i++;
		}
		return null;
	};

	private readonly setGeolocator: Function = () => {
		let geoBoxInput = this.getInputElement(document.querySelectorAll("#selectAddressInputComponent")[0]);
		this.gbox = new google.maps.places.SearchBox(geoBoxInput);
		this.gbox.addListener("places_changed", this.gboxChanged);
	};

	private readonly gboxChanged: Function = () => {
		let places = this.gbox.getPlaces();
		this.currentAddress = places[0].formatted_address;
	};
}
