<div class="container">
	<div
		#pageElements
		*ngFor="let page of pages; let i = index"
		class="page"
		[style.width.px]="pageSize.width"
		[style.height.px]="pageSize.height"
	>
		<div class="title">IIMARCH</div>
		<div class="header">
			<div class="header-row">
				<div class="header-col">
					<div>
						<b>{{ text().TIME }}:</b> {{ iimarch.timestamp_ms | date: "HH:mm" }}
					</div>
					<div>
						<b>{{ text().NAME_OF_CALLER }}:</b>
						{{ filledBy && filledBy.name ? filledBy.name : text().UNSPECIFIED }}
					</div>
					<div>
						<b>{{ text().DATE }}:</b> {{ iimarch.timestamp_ms | date: "dd/MM/YYYY" }}
					</div>
				</div>
				<div class="header-col">
					<div *ngFor="let comm of commanders; let i = index">
						<b>{{ text().COMMANDER + " " + (i + 1) + ": " }}</b> {{ comm.name }} (
						{{ comm.organization }})
					</div>
				</div>
			</div>
			<div class="doc-generated-on">
				<b>{{ text().DOCUMENT_GENERATED_ON }}</b
				>: {{ generationTime | date: "dd/MM/YY
				HH:mm" }}
			</div>
		</div>
		<div *ngFor="let option of page" class="debrief-option">
			<div class="option-title">
				<div class="line"></div>
				<div class="title-text">
					<span class="initial">{{ option.header[0] }}</span>
					<span>{{ option.header.substr(1, 999) }}</span>
				</div>
			</div>
			<div class="option-body">
				<span>{{ option.question }}</span>
				<span class="answer">- {{ option.answer }}</span>
			</div>
		</div>
		<div class="page-index">
			<b>{{ text().PAGE }}:</b> <span>{{ i + 1 }} / {{ pages.length }}</span>
		</div>
	</div>
</div>

<div class="inspector">
	<div #inspectedHeaderElement *ngIf="inspectedHeader">
		<div class="title">M/ETHANE</div>
		<div class="header">
			<div class="header-row">
				<div class="header-col">
					<div>
						<b>{{ text().TIME }}: {{ iimarch.timestamp_ms | date: "HH:mm" }}</b>
					</div>
					<div>
						<b
							>{{ text().NAME_OF_CALLER }}:
							{{ filledBy && filledBy.name ? filledBy.name : text().UNSPECIFIED }}</b
						>
					</div>
					<div>
						<b>{{ text().DATE }}: {{ iimarch.timestamp_ms | date: "dd/MM/YYYY" }}</b>
					</div>
				</div>
				<div class="header-col">
					<div *ngFor="let comm of commanders">
						<b>{{ text().COMMANDER + " " + comm.id + ": " + comm.name }}</b>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div #inspectedOptionElement *ngIf="inspectedOption">
		<div class="option-title">
			<div class="line"></div>
			<div class="title-text">
				<span class="initial">{{ inspectedOption.header[0] }}</span>
				<span>{{ inspectedOption.header.substr(1, 999) }}</span>
			</div>
		</div>
		<div class="option-body">
			<span>{{ inspectedOption.question }}</span>
			<span class="answer">- {{ inspectedOption.answer }}</span>
		</div>
	</div>
</div>
