import { ResourceSkill } from "./skill";

export class ListItem {
	public agentid: number;
	public checked: boolean = false;
	public available: boolean = true;
	public name: string = "";
	public skills: Array<ResourceSkill> = [];
	public role: string = "";
	public station: string = "";

	constructor(agentid: number, name: string, roleName: string, station: string, skills: Array<ResourceSkill> | undefined, available: boolean) {
		this.agentid = agentid;
		this.name = name;
		this.role = roleName;
		this.station = station;
		this.skills = skills ? skills : [];
		this.available = available;
	}

	public readonly isResourceChecked: Function = () => {
		return this.checked;
	};

	public readonly checkResource: Function = (value?: boolean) => {
		this.checked = value ?? !this.checked;
	};
}
