import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, ViewChild, ViewChildren, ɵdetectChanges } from "@angular/core";
import { LocaleMap } from "src/app/global/constants/text/text-interface";
import { TextProvider } from "src/app/global/constants/text/text-provider";
import { DocumentTemplate } from "../template.interface";
import { DocumentService } from "src/app/document/document.service";
import { ControlMeasureRelation } from "src/app/dto/ara/control-measure-relation";
import { RiskInformation } from "src/app/dto/ara/risk-information";
import { CSSector } from "src/app/dto/command-structure/cs-sector";
import { SubControlMeasureRelation } from "src/app/dto/ara/sub-control-measure-relation";
import { RiskInformationRelation } from "src/app/dto/ara/risk-information-relation";
import { RiskTypeRelation } from "src/app/dto/ara/risk-type-relation";
import { AraService } from "src/app/incident/incident-tools/ara/ara.service";
import { PAGE_SIZES } from "src/app/dto/Documents/page-sizes";
import { Ara } from "src/app/dto/ara/ara";
import * as html2canvas from "html2canvas";
import { Scenario } from "src/app/dto/ara/scenario";
import { ScenarioRelation } from "../../../../dto/ara/scenario-relation";
import { FUNCTIONALITY, FunctionalityService } from "../../../../global/functionality.service";
import { ControlMeasure } from "../../../../dto/ara/control-measure";
import { Incident } from "src/app/dto/items/incident";

@Component({
	selector: "app-ara-template",
	templateUrl: "ara.component.html",
	styleUrls: ["ara.css", "../../document.css"]
})
export class AraTemplateComponent implements OnInit, DocumentTemplate, AfterViewInit {
	@Input() event!: Incident;
	@Input() sector!: CSSector;

	@Output() docReady = new EventEmitter<HTMLCanvasElement[]>();

	@ViewChildren("pageElements") pageElements!: QueryList<ElementRef>;
	@ViewChild("inspectedAraHeaderElement") inspectedAraHeaderElement!: ElementRef;
	@ViewChild("inspectedScenarioElement") inspectedScenarioElement!: ElementRef;
	@ViewChild("inspectedRiElement") inspectedRiElement!: ElementRef;
	@ViewChild("inspectedCmElement") inspectedCmElement!: ElementRef;
	@ViewChild("inspectedScmElement") inspectedScmElement!: ElementRef;
	@ViewChild("inspectedFooterEl") inspectedFooterEl!: ElementRef;
	@ViewChildren("signatureEl") imageElements!: QueryList<ElementRef>;

	public inspectedPage: AraPage | undefined;
	public inspectedScenario: Scenario | undefined;
	public inspectedRiskInfo: RiskInformation | undefined;
	public inspectedCm: ControlMeasure | undefined;
	public inspectedScm: SubControlMeasureRelation | undefined;

	public numberOfPages: number = 1;
	public pages = new Array<AraPage>();
	public readonly pageSize = PAGE_SIZES.STANDARD;
	public totalPageHeight: number = PAGE_SIZES.STANDARD.height - 40; //margin-bottom;
	public araScore: boolean = true;
	public araRight: boolean = true;

	public readonly text: () => LocaleMap;
	private abortDownload = false;
	private readonly pageMargin = 20;
	private readonly docService: DocumentService;
	private readonly araService: AraService;
	private readonly functionalityService: FunctionalityService;

	constructor(tp: TextProvider, doc: DocumentService, araServ: AraService, functionalityService: FunctionalityService) {
		this.text = tp.getStringMap;
		this.docService = doc;
		this.araService = araServ;
		this.functionalityService = functionalityService;
	}

	ngOnInit(): void {
		if (!this.event || !this.sector || !this.sector.aras) this.docService.downloadError$.next();
		this.setPages();
		this.docService.onDownloadCancel$.subscribe(() => (this.abortDownload = true));
		this.araScore = this.functionalityService.isFunctionalityAvailable.get(FUNCTIONALITY.ARA_SCORE)!;
		this.araRight = this.functionalityService.isFunctionalityAvailable.get(FUNCTIONALITY.ARA_RIGHT)!;
	}

	async ngAfterViewInit(): Promise<void> {
		const pages = this.pageElements["_results"];
		//Patch for preventing browser crash aborting de generation of documents and download
		if (pages.length >= 20) {
			this.docService.setUnavailabeError();
			this.docService.onDownloadCancel();
			return;
		}
		this.waitForSignaturesToLoad()
			.then(async () => {
				const output = new Array<HTMLCanvasElement>();
				for (let i = 0; i < pages.length; i++) {
					if (!this.docService.downloadingFullIncident) this.docService.setLoadingMessage(this.text().GENERATING_DOCUMENTATION_PAGE(i, pages.length));
					console.info(this.text().GENERATING_DOCUMENTATION_PAGE(i, pages.length));
					const canvas = await html2canvas.default(this.pageElements["_results"][i].nativeElement);
					if (this.abortDownload) return;
					output.push(canvas);
				}
				this.docReady.emit(output);
			})
			.catch((error) => {
				if (this.abortDownload) return;
				console.error(error);
				this.docService.downloadError$.next();
			});
	}

	public getHighestRisk(ara: Ara): number | undefined {
		const riskIndicators = ara.getSL();
		const highestRisk = riskIndicators.severity * riskIndicators.likelihood;
		return highestRisk > 0 ? highestRisk : undefined;
	}

	public getCMTimstamp(cm: ControlMeasureRelation): number | undefined {
		return this.araService.updateCMTimestamps(cm);
	}

	public getWhoIsAtRisk(atRisk: RiskTypeRelation[]): string {
		if (atRisk && atRisk.length) {
			const whoIsAtRisk: string[] = [];
			atRisk.forEach((ri) => ri.__object && whoIsAtRisk.push(ri.__object.name));
			const atRiskString: string = whoIsAtRisk.join("; ").replace(/,/g, "; ");
			return atRiskString;
		}
		return "";
	}

	public setPages(): void {
		let currentPage: AraPage | undefined;
		for (const currentAra of this.sector.aras) {
			const activeSc = currentAra.scenarios.filter((e) => !e.__object?.deleted);
			currentPage = new AraPage(currentAra);
			currentPage.height = this.calculatePageHeaderHeight(currentPage);
			currentPage = this.updatePageWithScenarios(currentPage, activeSc);
			if (this.araRight && this.araScore) this.addFooter(currentPage, currentAra);
		}
		return;
	}

	public getTimeStringText(page: AraPage): string {
		return this.araScore && this.araRight ? (page.ara.is_new ? this.text().ARA_CREATION_TIME : this.text().ARA_REVIEW_TIME) : page.ara.is_new ? this.text().TM_CREATION_TIME : this.text().TM_REVIEW_TIME;
	}

	private setupPageAndDefineAddScenarioHeader(currentPage: AraPage, shouldAddScenarioHeader: boolean, scenarioHeaderHeight: number): boolean {
		currentPage = this.startNewPage(currentPage.ara);

		if (shouldAddScenarioHeader) {
			currentPage.height += scenarioHeaderHeight;
			return false;
		}
		return true;
	}

	private splitStringToFitPage(inputString: string, availablePixelHeight: number): [string, string] {
		const tempElement = document.createElement("div");
		tempElement.style.position = "absolute";
		tempElement.style.visibility = "hidden";
		tempElement.style.fontSize = "10px";
		tempElement.style.fontFamily = "Poppins";
		tempElement.style.width = `465.63px`;
		tempElement.style.whiteSpace = "pre-wrap";
		document.body.appendChild(tempElement);

		let fitIndex = 0;
		let currentHeight = 0;

		for (let i = 0; i < inputString.length; i++) {
			tempElement.textContent = inputString.substring(0, i + 1);
			const measuredHeight = tempElement.clientHeight;

			if (measuredHeight > availablePixelHeight) {
				break;
			}

			fitIndex = i;
			currentHeight = measuredHeight;
		}

		let lastSpaceIndex = inputString.lastIndexOf(" ", fitIndex);
		if (lastSpaceIndex === -1) {
			lastSpaceIndex = fitIndex;
		}

		const firstPageString = inputString.substring(0, lastSpaceIndex).trim();
		const remainingString = inputString.substring(lastSpaceIndex).trim();

		document.body.removeChild(tempElement);

		return [firstPageString + "...", "..." + remainingString];
	}

	private updatePageWithScenarios(currentPage: AraPage, scenarios: ScenarioRelation[]): AraPage {
		scenarios.forEach((scenario) => {
			let partialScenario: ScenarioRelation | null;
			const scenarioHeaderHeight = this.calculateScenarioHeaderHeight(scenario.__object);
			let addScenarioHeader = true;
			const currentHeight = currentPage.height + scenarioHeaderHeight;
			const scenarioFits = currentHeight < this.totalPageHeight - 100;
			if (!scenarioFits) {
				this.pages.push(currentPage);
				currentPage = this.startNewPage(currentPage.ara);
				currentPage.height += scenarioHeaderHeight;
			}

			scenario.riskInformations.forEach((risk, index) => {
				const riskHeight = index === 0 ? this.calculateRiHeaderHeight(risk.__object) : this.calculateRiHeaderHeight(risk.__object) + 16;
				const addScenarioHeaderHeight = (hasHeader: boolean): number => (hasHeader ? scenarioHeaderHeight : 0);
				const currentContentHeight = currentPage.height + addScenarioHeaderHeight(addScenarioHeader) + riskHeight;
				const totalAvailablePageHeight = this.totalPageHeight - 100;

				const riskFits = currentContentHeight <= totalAvailablePageHeight;

				if (!riskFits) {
					addScenarioHeader = this.setupPageAndDefineAddScenarioHeader(currentPage, addScenarioHeader, scenarioHeaderHeight);
				}

				let newRisk = { ...risk, controlMeasures: [] as ControlMeasureRelation[] };

				let spilled: string | any[] = [];
				if (!partialScenario) {
					partialScenario = { ...scenario, riskInformations: [newRisk] };
					currentPage.scenarios.push(partialScenario);
				} else {
					partialScenario.riskInformations.push(newRisk);
				}
				currentPage.height += riskHeight;

				const riskCloned = JSON.parse(JSON.stringify(risk));

				riskCloned.controlMeasures.forEach((cm: ControlMeasureRelation, index: number) => {
					if (spilled.length && index) {
						const [text, height] = spilled;
						newRisk.controlMeasures.push(text);
						currentPage.height += height;
						spilled = [];
					}
					const cmHeight = this.calculateCmHeight(cm.__object);
					const currentPageWithControlMeasureIsBigger = currentPage.height + cmHeight > totalAvailablePageHeight - 40; // bottom margin

					const [fit, spillover] = this.splitStringToFitPage(cm.__object.info, totalAvailablePageHeight - currentPage.height);
					if (currentPageWithControlMeasureIsBigger && spillover.length) {
						const spilledHeight = currentPage.height + cmHeight - totalAvailablePageHeight;
						cm.__object.info = cm.__object.info.length < 80 ? cm.__object.info : fit; // ensure it does not cut short strings
						newRisk.controlMeasures.push(cm);
						this.pages.push(currentPage);
						currentPage = this.startNewPage(currentPage.ara);
						currentPage.height += addScenarioHeaderHeight(addScenarioHeader) + riskHeight;

						newRisk = { ...newRisk, controlMeasures: [] };
						if (fit.length + spillover.length > 80) {
							const spilledControlMeasure: ControlMeasureRelation = JSON.parse(JSON.stringify(cm));
							spilledControlMeasure.__object.info = spillover;
							spilled = [spilledControlMeasure, spilledHeight + 50];
						}
						partialScenario = { ...scenario, riskInformations: [newRisk] };

						currentPage.scenarios.push(partialScenario);
					} else {
						newRisk.controlMeasures.push(cm);
						currentPage.height += cmHeight;
					}
				});
			});

			partialScenario = null;
			addScenarioHeader = true;
		});

		return currentPage;
	}

	private startNewPage(ara: Ara): AraPage {
		const newPage = new AraPage(ara);
		newPage.height = this.calculatePageHeaderHeight(newPage);
		return newPage;
	}

	private addFooter(currentPage: AraPage, currentAra: Ara): void {
		const footerHeight = this.calculateFooterHeight(currentPage);
		if (currentPage.height + footerHeight > this.totalPageHeight + 40) {
			this.pages.push(currentPage);
			currentPage = new AraPage(currentAra);
		}
		currentPage.printFooter = true;
		this.pages.push(currentPage);
	}

	// builds a mock header to calculate its height

	private readonly calculatePageHeaderHeight: (ara: AraPage) => number = (ara) => {
		this.inspectedPage = ara;
		ɵdetectChanges(this);
		let output = this.inspectedAraHeaderElement!.nativeElement.getBoundingClientRect().height;
		this.inspectedPage = undefined;
		const highestRiskSectionHeight = 48;
		output += highestRiskSectionHeight;
		return output;
	};

	private calculateScenarioHeaderHeight(scenario: Scenario | undefined): number {
		this.inspectedScenario = scenario;
		ɵdetectChanges(this);
		const output = this.inspectedScenarioElement?.nativeElement.getBoundingClientRect().height;
		this.inspectedRiskInfo = undefined;
		return output;
	}

	private readonly calculateRiHeaderHeight: (ri: RiskInformation | undefined) => number = (ri) => {
		this.inspectedRiskInfo = ri;
		ɵdetectChanges(this);
		const output = this.inspectedRiElement?.nativeElement.getBoundingClientRect().height;
		this.inspectedRiskInfo = undefined;
		return output;
	};

	private readonly calculateCmHeight: (cm: ControlMeasure) => number = (cm) => {
		this.inspectedCm = cm;
		ɵdetectChanges(this);
		const output = this.inspectedCmElement!.nativeElement.getBoundingClientRect().height;
		this.inspectedCm = undefined;
		return output;
	};

	private readonly calculateFooterHeight = (ara: AraPage): number => {
		this.inspectedPage = ara;
		ara.printFooter = true;
		ɵdetectChanges(this);
		const output = this.inspectedFooterEl.nativeElement.getBoundingClientRect().height;
		this.inspectedPage = undefined;
		ara.printFooter = false;
		return output;
	};

	private waitForSignaturesToLoad(): Promise<void[]> {
		const promises: Promise<void>[] = [];

		this.imageElements.forEach((imageElement: ElementRef) => {
			const imgEl = imageElement.nativeElement;
			if (imgEl.tagName === "IMG" && imgEl.src) {
				const promise = new Promise<void>((resolve) => {
					if (imgEl.complete) {
						this.onSignatureLoad(imgEl);
						resolve();
					} else {
						imgEl.addEventListener("load", () => {
							this.onSignatureLoad(imgEl);
							resolve();
						});
						imgEl.addEventListener("error", () => {
							resolve();
						});
					}
				});
				promises.push(promise);
			}
		});

		return Promise.all(promises);
	}

	private onSignatureLoad(image: HTMLImageElement): void {
		const parent = image.parentElement as HTMLElement;
		if (!parent || !image) return;
		let canvas = document.createElement("canvas") as HTMLCanvasElement;
		let ctx = canvas.getContext("2d")!;
		canvas.style.marginLeft = "112px";

		// Draw image into canvas element
		ctx.drawImage(image, 0, 0, image.width, image.height);
		const imgData = ctx.getImageData(0, 0, image.width, image.height);
		this.invertColors(imgData.data);
		ctx.putImageData(imgData, 0, 0);
		parent.removeChild(image);
		parent.appendChild(canvas);
	}

	private invertColors: (data: Uint8ClampedArray) => void = (data) => {
		for (let i = 0; i < data.length; i += 4) {
			data[i] = 255 - data[i];
			data[i + 1] = 255 - data[i + 1];
			data[i + 2] = 255 - data[i + 2];
		}
	};
}
class AraPage {
	public ara: Ara;
	public scenarios = new Array<ScenarioRelation>();
	public riskInformations = new Array<RiskInformationRelation>();
	public height: number = 0;
	public printFooter: boolean = false;

	constructor(ara: Ara) {
		this.ara = ara;
	}
}
