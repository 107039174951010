import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Appliance } from "src/app/dto/resources/appliance";
import { Personnel } from "src/app/dto/resources/personnel";
import { Resource } from "src/app/dto/resources/resource";
import { IncidentService } from "../../../../../../incident/incident.service";

@Component({
	selector: "app-cs-appliance",
	templateUrl: "./cs-appliance.component.html",
	styleUrls: ["./cs-appliance.css"]
})
export class CSApplianceComponent {
	@Input() resource: any;
	@Input() expanded: boolean = false;
	@Input() nameFilter: string = "";
	@Input() draggingResource: boolean = false;
	@Input() draggingPersonnel: boolean = false;
	@Input() incidentClosed: boolean = false;
	@Input() disabled = false;

	@Output() dragStart = new EventEmitter<number>();
	@Output() dragCancel = new EventEmitter<{ info: string | number | undefined }>(); // Emits an object
	@Output() dragEnd = new EventEmitter<{ zone: string; info: string | number | undefined; target: HTMLElement | undefined }>(); // Emits an object

	public shownSkillIndexes: Map<number, boolean> = new Map<number, boolean>();

	private touchStartY: number = 0;
	private touchStartX: number = 0;
	private isScrolling: boolean = false;

	private dragTimeout: any;
	private isClick: boolean = true;

	constructor(private incidentService: IncidentService) {}

	public matchesFilter(): boolean {
		const generalFilter = this.nameFilter.toUpperCase();
		if (this.resource.name.toUpperCase().includes(generalFilter)) {
			return true;
		}
		return this.resource.personnel && this.resource.personnel.some((person: Personnel) => person.name.toUpperCase().includes(generalFilter));
	}

	public toggleExpanded(): void {
		this.expanded = !this.expanded;
	}

	public getPersonnel(appliance: Resource): Personnel[] | undefined {
		return (appliance as Appliance).personnel;
	}

	public filterClosedPersonnelList(resource: Resource): Personnel[] {
		if (!(resource instanceof Appliance)) return [];
		const closedPersonnel = resource.closed_personnel.filter((e) => e.id_incident === this.getCurrentIncidentId()).map((e) => e.object);
		return closedPersonnel;
	}

	public setStatusBackground(resource: Resource): { backgroundColor?: string } {
		return { backgroundColor: resource.__state?.color ?? undefined };
	}

	public toggleSkills(index: number): void {
		if (this.shownSkillIndexes.has(index)) {
			this.shownSkillIndexes.delete(index);
		} else {
			this.shownSkillIndexes.set(index, true);
		}
	}

	public isOic(resource: Resource, person: Personnel): boolean {
		const appliance = resource as Appliance;
		const oic = appliance?.oic;
		if (!oic) return false;
		return oic.id === person.id;
	}

	public shouldDisplaySkills(person: Personnel, resource?: Resource): boolean {
		const appliance = resource as Appliance;
		const incidentId = this.getCurrentIncidentId()!;
		const displayAllowed = !!person.skills && !person.deleted;
		return (displayAllowed && person.id_incident === incidentId) || this.isOic(appliance, person);
	}

	public hasPersonnel(resource: Resource): boolean {
		return !!this.getPersonnel(resource)?.length || !!this.filterClosedPersonnelList(resource)?.length;
	}

	public onDragStart(event: number): void {
		this.dragStart.emit(event);
	}

	public onDragCancel(event: { info: string | number | undefined }): void {
		this.dragCancel.emit(event);
	}

	public onDragEnd(event: { zone: string; info: string | number | undefined; target: HTMLElement | undefined }): void {
		this.dragEnd.emit(event);
	}

	public onTouchStart(event: TouchEvent): void {
		this.touchStartY = event.touches[0].clientY;
		this.touchStartX = event.touches[0].clientX;
		this.isScrolling = false;
		this.isClick = true;
		this.dragTimeout = setTimeout(() => {
			this.isClick = false;
			this.draggingResource = true;
		}, 200);
	}

	public onTouchMove(event: TouchEvent): void {
		const touchEndY = event.touches[0].clientY;
		const touchEndX = event.touches[0].clientX;
		const diffY = Math.abs(touchEndY - this.touchStartY);
		const diffX = Math.abs(touchEndX - this.touchStartX);
		if (diffY > diffX && diffY > 10) {
			this.isScrolling = true;
		}
		if (diffY > 10 || diffX > 10) {
			this.isClick = false;
			clearTimeout(this.dragTimeout);
		}
	}

	public onTouchEnd(event: TouchEvent): void {
		clearTimeout(this.dragTimeout);
		if (this.isClick && !this.draggingResource) {
			this.toggleExpanded();
		}
		if (!this.draggingResource || this.isScrolling) {
			this.resetDragState();
		} else {
			this.draggingResource = false;
		}
	}

	public onMouseDown(event: MouseEvent): void {
		this.touchStartY = event.clientY;
		this.touchStartX = event.clientX;
		this.isScrolling = false;
		this.isClick = true;
		this.dragTimeout = setTimeout(() => {
			this.isClick = false;
			this.draggingResource = true;
		}, 200);
	}

	public onMouseMove(event: MouseEvent): void {
		const diffY = Math.abs(event.clientY - this.touchStartY);
		const diffX = Math.abs(event.clientX - this.touchStartX);
		if (diffY > 10 || diffX > 10) {
			this.isClick = false;
			clearTimeout(this.dragTimeout);
		}
	}

	public onMouseUp(event: MouseEvent): void {
		clearTimeout(this.dragTimeout);
		if (this.isClick && !this.draggingResource) {
			this.toggleExpanded();
		}
		if (!this.draggingResource) {
			this.resetDragState();
		} else {
			this.draggingResource = false;
		}
	}

	private getCurrentIncidentId(): number | undefined {
		const currentIncidentId = this.incidentService.getCurrentIncident()?.id;
		if (currentIncidentId) return currentIncidentId;
		return;
	}

	private resetDragState(): void {
		this.draggingResource = false;
		this.draggingPersonnel = false;
	}
}
