<div class="document-header">
	<app-event-header [event]="event"></app-event-header>
	<div *ngIf="fsg">
		<div class="subheader-section">
			<div class="item-title">{{ text().LOCATION }}:</div>
			<div>{{ fsg.address ? fsg.address : text().UNSPECIFIED }}</div>
		</div>
		<div class="subheader-section">
			<div class="item-title">{{ text().FIRE_ORIGIN }}:</div>
			<div>{{ getFireOrigin() }}</div>
		</div>
	</div>
</div>
