import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { LocaleMap } from "src/app/global/constants/text/text-interface";
import { TextProvider } from "src/app/global/constants/text/text-provider";

@Component({
	selector: "app-transparent-dropdown",
	templateUrl: "transparent-dropdown.component.html",
	styleUrls: ["transparent-dropdown.css", "../dropdown.css"]
})
export class TransparentDropdownComponent {
	/** itemClickCallback is executed whenever the user clicks on an item, most likely to select it */
	@Output() itemClickCallback = new EventEmitter<any>();
	@Output() newItemEvent = new EventEmitter<string>();
	@Output() newItemKeydownEvent = new EventEmitter<string>();

	// object is the data object
	@Input() object: any;
	// disabled is the boolean to set disabled the dropdown
	@Input() disabled: boolean = false;

	// Used in Jesip history, partially disable the dropdown
	@Input() partialDisable: boolean = false;

	// options the array of items that will show on the dropdown menu
	@Input() options: Array<any> = [];

	@Input() placeholderText: string = "";
	// Specifies whether filtering should be enabled in the dropdown
	@Input() filterEnabled: boolean = false;

	// Specifies the field to search by
	@Input() searchBy: string = "name";

	@Input() fontSize: number = 16;

	@Input() menuMaxHeight: number = 170;

	@Input() selectingAddress: boolean = false;

	@Input() addString: string = "";

	@ViewChild("filterInput") filterInp: ElementRef | undefined;

	// Used for multiselect
	@Input() autoCloseOnClick: boolean = true;

	@Input() withBorderRadius: boolean = false;

	@Input() allowAddItem: boolean = false;

	/**
	 * onChangeCb is executed whenever the content of the input field changes
	 */
	@Input() onChangeCb: Function = () => {};

	/** getObjectText returns the text value that'll be written on the top of the dropdown */
	@Input() getObjectText: Function = (object: any) => {
		return typeof object === "string" ? object : "";
	};
	/** compareSelect returns whether an option is the selected one  */
	@Input() compareSelect: Function = (object: any, option: any) => {
		return object === option;
	};
	/** getOptionText returns the text value that'll be written on each item */
	@Input() getOptionText: Function = (option: any) => {
		return typeof option === "string" ? option : "";
	};
	/** getOptionImage ret  */
	@Input() getOptionColor: Function = () => {
		return "";
	};

	public readonly text: () => LocaleMap;

	openMenu: boolean = false;
	query: string = "";
	makingNewItem: boolean = false;
	newItem: string | null = "";

	constructor(textProv: TextProvider) {
		this.text = textProv.getStringMap;
	}

	change: Function = (query: string) => {
		this.onChangeCb(query);
	};

	filterFunc: (item: any) => boolean = (item: any) => {
		if (this.query && this.query.length > 0) {
			return item[this.searchBy].toUpperCase().includes(this.query.toUpperCase()) ? true : false;
		}
		return true;
	};

	public readonly headerBodyClick: Function = () => {
		this.openMenu = !this.openMenu;
		this.query = "";
		if (this.filterEnabled) setTimeout(() => this.filterInp && this.filterInp.nativeElement.focus());
	};

	addNewItem: (value: string) => void = (value) => {
		this.newItemEvent.emit(value);
	};

	newItemKeydownController: (evt: any, type: string) => void = (evt, type) => {
		if (evt.type !== "keydown") return;
		switch (evt.key) {
			case "Enter":
				this.newItemKeydownEvent.emit(evt.target.value);
				evt.stopPropagation();
				this.makingNewItem = false;
				const scrollObj = evt.target.parentNode.parentNode;
				scrollObj.scrollTop = 0;
				this.newItem = null;
				break;
			case "Escape":
				this.stopCreatingNewValue();
				break;
			default:
				return;
		}
	};

	stopCreatingNewValue: () => void = () => {
		this.makingNewItem = false;
		this.newItem = null;
	};

	transMaskClick: () => void = () => {
		this.closeAllMenus();
	};

	closeAllMenus: () => void = () => {
		this.openMenu = false;
		this.makingNewItem = false;
		this.newItem = "";
		document.removeEventListener("click", this.closeAllMenus);
	};

	openNewItem: (evt: any) => void = (evt) => {
		if (this.allowAddItem) {
			this.makingNewItem = true;
			const scrollObj = evt.target.parentNode.parentNode.parentNode.firstChild;
			scrollObj.scrollTop = 0;
			document.addEventListener("click", this.closeAllMenus);
		}
	};

	closeNewItem(): void {
		this.newItem = "";
		this.makingNewItem = false;
	}

	getOptionLength(): number {
		return this.options.filter(this.filterFunc).length;
	}

	onClick(option: string | any): void {
		if (this.autoCloseOnClick) this.openMenu = false;
		this.itemClickCallback.emit(option);
	}
}
