import { Component, ElementRef, EventEmitter, Input, Output } from "@angular/core";

@Component({
	selector: "app-debrief-list",
	templateUrl: "debrief-list.component.html",
	styleUrls: ["debrief-list.css"]
})
export class DebriefListComponent {
	@Input() header!: string;
	@Input() boldHeader: boolean = true;
	@Input() items: Array<DebriefListComponentItem> = new Array<DebriefListComponentItem>();
	@Input() helpText: string = "";
	@Input() placeholder: string = "";
	@Input() isDisabled: boolean = false;

	@Output() itemUpdate = new EventEmitter<DebriefListComponentItem>();

	public isExpanded: boolean = false;
	public expandedItemIndex: number = -1;

	private readonly elRef: ElementRef;

	constructor(elRef: ElementRef) {
		this.elRef = elRef;
	}

	public toggleItem(idx: number): void {
		const currentItem = this.items[idx];
		const previousItem = this.expandedItemIndex !== -1 ? this.items[this.expandedItemIndex] : null;

		if (currentItem.isExpanded) {
			if (!currentItem.value || currentItem.value.trim() === "") {
				this.collapseItem(currentItem, idx);
			}
		} else {
			if (previousItem && this.shouldCollapsePreviousItem(previousItem)) {
				this.collapseItem(previousItem, this.expandedItemIndex);
			}

			this.expandItem(currentItem, idx);
		}
	}

	public onKeyPressed(event: Event, inputElement: HTMLTextAreaElement, item: DebriefListComponentItem): void {
		const trimmedValue = item.value ? item.value.trim() : "";

		if (event instanceof KeyboardEvent) {
			const keyboardEvent = event as KeyboardEvent;
			if (keyboardEvent.key === "Enter" || keyboardEvent.key === "Tab") {
				event.preventDefault();
				if (trimmedValue === "") {
					item.isExpanded = false;
					this.expandedItemIndex = -1;
					inputElement.blur();
				}
			}
		} else if (trimmedValue === "") {
			event.preventDefault();
			if (!item.isExpanded) {
				item.isExpanded = true;
				this.expandedItemIndex = this.items.indexOf(item);
				setTimeout(() => {
					inputElement.focus();
				});
			} else {
				item.isExpanded = false;
				this.expandedItemIndex = -1;
				inputElement.blur();
			}
		}
	}

	public onEnterPressed(event: Event, inputElement: HTMLTextAreaElement, item: DebriefListComponentItem): void {
		const trimmedValue = item.value ? item.value.trim() : "";
		if (trimmedValue === "") {
			event.preventDefault();
			item.isExpanded = false;
			this.expandedItemIndex = -1;
			inputElement.blur();
		}
	}

	public onMousedownOutside(event: Event): void {
		const isClickInside = this.elRef.nativeElement.contains(event.target);
		if (!isClickInside && this.expandedItemIndex !== null && !this.items[this.expandedItemIndex].value) {
			this.items[this.expandedItemIndex].isExpanded = false;
			this.expandedItemIndex = -1;
		}
	}

	private shouldCollapsePreviousItem(previousItem: DebriefListComponentItem): boolean {
		return !previousItem.value || previousItem.value.trim() === "";
	}

	private collapseItem(item: DebriefListComponentItem, itemIndex: number): void {
		item.isExpanded = false;
		this.expandedItemIndex = -1;
	}

	private expandItem(item: DebriefListComponentItem, itemIndex: number): void {
		item.isExpanded = true;
		this.expandedItemIndex = itemIndex;

		setTimeout(() => {
			const inputElement = document.getElementById(`input_${item.id}`);
			if (inputElement) {
				inputElement.focus();
			}
		});
	}
}
export class DebriefListComponentItem {
	public header: string | undefined;
	public text: string;
	public id: number;
	public value: string | undefined;
	public isExpanded: boolean = false;

	constructor(id: number, text: string, value?: string, header?: string) {
		this.id = id;
		this.text = text;
		this.value = value;
		this.header = header;
	}
}
