import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from "@angular/forms";
import { Level } from "src/app/dto/user/level";

export function forbiddenNameValidator(levels: Level[], currentUserName?: string): ValidatorFn {
	return (control: AbstractControl): ValidationErrors | null => {
		if (currentUserName === control.value) return null;
		const forbidden = levels.some((level) => level.name && level.name.toLowerCase() === control.value.toLowerCase());
		return forbidden ? { forbiddenName: { value: control.value } } : null;
	};
}
