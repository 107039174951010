<div class="support-dialog">
	<div
		class="icon-support pointer"
		[class.support-icon-active]="supportMenu"
		(click)="toggleMenu()"
	></div>
	<div *ngIf="supportMenu">
		<div class="main-black-mask" (click)="toggleMenu()"></div>
		<app-support-dialog [isFuncAvailable]="isFuncAvailable"></app-support-dialog>
	</div>
</div>
