<div *ngIf="event" class="document-subheader">
	<div class="subheader-section">
		<div class="item-title">{{ this.text().INCIDENT_ID }}</div>
		<div>{{ event.name }}</div>
	</div>
	<div class="subheader-section">
		<div class="item-title">{{ this.text().INCIDENT_DATE }}:</div>
		<div class="event-date">
			{{ event.start_time_ms | date : "dd/MM/YY hh:mm:ss" }}
			-
			<span *ngIf="event.closed && event.end_time_ms">
				{{ event.end_time_ms | date : "dd/MM/YY hh:mm:ss" }}
			</span>
		</div>
	</div>
</div>
