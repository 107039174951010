import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { DEFAULT_SOI } from "src/app/dto/fsg/enums";
import { FsgFlat } from "src/app/dto/fsg/fsg-flat";
import { FsgService } from "src/app/incident/incident-tools/fsg/fsg.service";
import { LocaleMap } from "src/app/global/constants/text/text-interface";
import { TextProvider } from "src/app/global/constants/text/text-provider";

@Component({
	selector: "app-select-soi-popup",
	templateUrl: "fsg-select-soi.component.html",
	styleUrls: ["../fsg-editor-popup.css", "fsg-select-soi.css"]
})
export class FsgSelectSoiPopupComponent implements OnInit {
	@Input() flat!: FsgFlat;
	@Output() selectEmit = new EventEmitter<string>();
	@Output() cancel = new EventEmitter<void>();

	public readonly text: () => LocaleMap;
	public readonly default_sois = DEFAULT_SOI;
	public openNewSoiInput = false;
	public newSoiValue = "";

	public options = new Array<string>();
	public getSoiIndex = new Map<string, string>();

	private readonly fsg: FsgService;

	constructor(tp: TextProvider, fsgS: FsgService) {
		this.text = tp.getStringMap;
		this.fsg = fsgS;
		let defaults = Object.values(this.default_sois);
		defaults.forEach((val) => {
			if (val !== DEFAULT_SOI.UNSET) {
				this.options.push(this.fsg.getSoiValueAsLocalizedString(val));
				this.getSoiIndex.set(this.fsg.getSoiValueAsLocalizedString(val), val);
			}
		});
	}

	ngOnInit(): void {
		if (!this.flat) throw "FsgSelectSoi component initialized without a valid flat object";
	}

	public readonly hasSoi: () => boolean = () => {
		return this.flat.source_of_information !== DEFAULT_SOI.UNSET;
	};
	public readonly isSoiSelected: (val: string) => boolean = (val) => {
		return this.getSoiIndex.get(val) ? this.getSoiIndex.get(val) === this.flat.source_of_information : val === this.flat.source_of_information; // if custom value compare directly
	};
	public readonly getFlatSoi: (flat: FsgFlat | undefined) => string = (flat) => {
		switch (flat?.source_of_information) {
			case DEFAULT_SOI.CIVILIANS:
				return this.text().CIVILIANS;
			case DEFAULT_SOI.CONTROL_ROOM:
				return this.text().CONTROL_ROOM;
			case DEFAULT_SOI.FIREFIGHTERS:
				return this.text().FIREFIGHTERS;
			case DEFAULT_SOI.OTHER_AGENCIES:
				return this.text().OTHER_AGENCIES;
			case DEFAULT_SOI.UNSET:
				return this.text().FSG_SOI;
			default:
				return this.flat.source_of_information;
		}
	};

	public readonly toggleNewInput: (evt?: Event) => void = (evt?) => {
		this.openNewSoiInput = !this.openNewSoiInput;
		if (!this.openNewSoiInput) this.newSoiValue = "";
		if (evt) evt.stopPropagation();
	};

	public readonly newSoiClickOut: Function = () => {
		if (this.newSoiValue) {
			this.options.push(this.newSoiValue);
		}
		this.toggleNewInput();
	};

	public readonly optionClick: (val: string) => void = (val) => {
		this.flat.source_of_information = this.flat.source_of_information === val ? DEFAULT_SOI.UNSET : val;
	};
}
