<div *ngIf="isOffline" class="glass-background">
	<div class="offline-message">
		<img src="resources/img/offline/offline-icon.svg" alt="Orange cloud icon" />
		{{ text().SIGNAL_LOST }} <br />
		{{ text().OFFLINE_GO_BACK }}
		<button class="main-blue-gradient-button" (click)="goBack()">
			{{ text().GO_TO + " " + bottomPage }}
		</button>
	</div>
</div>
