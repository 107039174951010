import { Component, Input, OnInit } from "@angular/core";
import { TextProvider } from "src/app/global/constants/text/text-provider";

@Component({
	selector: "app-schematic-label",
	templateUrl: "./schematic.component.html",
	styleUrls: ["../map-label.css"]
})
export class SchematicLabelComponent implements OnInit {
	@Input() label: string = "";

	private readonly tp: TextProvider;

	constructor(textProv: TextProvider) {
		this.tp = textProv;
	}

	ngOnInit(): void {
		if (!this.label) this.label = this.tp.getStringMap().OVERLAY_INFORMATION_AVAILABLE;
	}
}
