<div class="main-black-mask" (click)="close(); $event.stopPropagation()"></div>

<div data-cy="ef-information-container" class="information-container">
	<div data-cy="ef-address-container" class="address-container">
		<app-select-address-popup
			data-cy="ef-address-popup"
			[fsg]="currentFsg"
			(cancel)="toggleSelectAddressPopup()"
			(confirm)="setFsgAddress($event)"
		></app-select-address-popup>

		<div class="copy-container">
			<img data-add-action-name="ef-copy-address" class="copy-icon" (click)="copyAddress()" />
		</div>
	</div>

	<div class="info-container">
		<div class="group-container">
			<img class="icon" src="resources/img/icons-fsg/mini-clock.svg" />
			<p class="text">{{ text().LAST_UPDATED }}</p>
		</div>
		<div class="updates-container">
			<p class="time">{{ getLastUpdated() }}</p>
			<p *ngIf="!getLastUpdated()" class="time empty">{{ text().NONE }}</p>
			<ng-container *ngIf="currentFsg && currentFsg.__flats && currentFsg.__flats.length">
				<p
					data-add-action-name="ef-go-to-flat"
					*ngFor="let flat of getLastUpdatedFlats()"
					class="fire-flat"
					(click)="goToFlat(flat.id)"
				>
					{{
						text().FSG_FLOOR +
							" " +
							flat.floor_number +
							". " +
							text().FSG_FLAT +
							" " +
							flat.flat_name || " - "
					}}
				</p>
			</ng-container>
		</div>
	</div>
	<div class="info-container">
		<div class="group-container">
			<img class="icon" src="resources/img/icons-fsg/fire-icon.svg" />
			<p class="text">{{ text().ORIGIN }}</p>
		</div>
		<div class="updates-container">
			<p
				data-add-action-name="ef-go-to-fire-flat"
				*ngIf="fireFlat"
				class="fire-flat"
				(click)="goToFlat(fireFlat.id)"
			>
				{{
					text().FSG_FLOOR +
						" " +
						fireFlat.floor_number +
						". " +
						text().FSG_FLAT +
						" " +
						getUpdatedFlatName(fireFlat)
				}}
			</p>
			<p *ngIf="!fireFlat" class="fire-flat empty">{{ text().NONE }}</p>
		</div>
	</div>
	<div class="separator-line"></div>
	<div class="info-container sector">
		<p class="text {{ sectorInfoOpened ? 'active' : '' }}">{{ text().SECTOR_INFO }}</p>
		<img class="chevron {{ sectorInfoOpened ? 'active' : '' }}" (click)="toggleSectorInfo()" />
	</div>
	<div *ngIf="sectorInfoOpened" class="sector-container">
		<app-sector-info-tooltip
			*ngIf="sectorInfoOpened"
			(cancel)="toggleSectorInfo()"
			appPositionRespectToElement
			[referenceEl]="infoIconRef"
			appDetectClickOut
			[onlyOnce]="true"
			(callback)="toggleSectorInfo()"
		></app-sector-info-tooltip>
	</div>
</div>
