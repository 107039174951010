<div
	class="item-card width-210px box-sizing-border-box {{
		hasCameraBeenEdited() || canDelete ? 'active' : ''
	}}"
>
	<div class="item-card-title" appScrollToElement [scrollIdentifierId]="camera.id">
		<input
			data-add-action-name="camera-name"
			required
			placeholder="{{ text().NAME }}"
			[(ngModel)]="camera.name"
			type="text"
			(ngModelChange)="editingCamera()"
		/>
	</div>
	<div class="icon-section">
		<div class="icon">
			<img src="resources/img/icons-hw/cam.png" />
		</div>
	</div>
	<div class="station-section">
		<app-blue-dropdown
			class="dropdown"
			[camera]="camera"
			[options]="stations"
			[getObjectText]="stationDropdownGetMainText"
			[getOptionText]="stationDropdownGetOptionText"
			[compareSelect]="stationDropdownCompareSelect"
			(itemClickCallback)="stationDropdownChangeCallback($event)"
			[placeholderText]="text().STATION"
		></app-blue-dropdown>
	</div>
	<div class="body-section" *ngIf="!camera.milestone_name || camera.id === -1">
		<div class="body-element-container">
			<span class="card-label">ID : </span>
			<div class="input-container">
				<input
					data-add-action-name="camera-key"
					class="blue-plain-input"
					placeholder="{{ text().IMS_NAME_INPUT_PLACEHOLDER }}"
					[ngModel]="camera.ims_name"
					(ngModelChange)="checkInputValues($event)"
				/>
			</div>
		</div>
		<div class="body-element-container">
			<span class="card-label">URL : </span>
			<div class="input-container">
				<input
					class="blue-plain-input"
					placeholder="{{ text().URL_RTSP_ORIGIN }}"
					[(ngModel)]="camera.rtsp_url"
				/>
			</div>
		</div>
		<div
			class="camera-rtmpurl"
			*ngIf="
				camera.ims_name &&
				camera.ims_name !== '' &&
				!(camera.rtsp_url && camera.rtsp_url !== '') &&
				!(camera.rtmp_url && camera.rtmp_url !== '')
			"
		>
			{{ default_rtmp_url }}/{{ camera.ims_name }}
		</div>
		<div
			class="camera-rtmpurl"
			*ngIf="
				camera.rtmp_url &&
				camera.rtmp_url !== '' &&
				!(camera.rtsp_url && camera.rtsp_url !== '')
			"
		>
			{{ camera.rtmp_url }}
		</div>
	</div>
	<div class="body-filler" *ngIf="camera.milestone_name"></div>
	<div class="comments-section">
		<div>
			<span class="card-label">{{ text().DETAILS.toUpperCase() }}: </span>
		</div>
		<textarea class="card-textarea-input" [(ngModel)]="camera.description"></textarea>
	</div>
	<button
		data-cy="camera-save-button"
		class="main-blue-gradient-button card-bottom-button"
		*ngIf="hasCameraBeenEdited()"
		(click)="save()"
	>
		{{ text().SAVE }}
	</button>
	<button
	[attr.data-cy]="'camera-delete-button-' + camera.name"
		class="main-blue-gradient-button card-bottom-button small-button"
		*ngIf="canDelete"
		(click)="delete()"
	>
		{{ text().DELETE }}
	</button>
</div>
