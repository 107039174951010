import { DTO } from "../net/dto";

export class Level implements DTO {
	id: number;
	name: string;
	edit_agents: boolean;
	delete_agents: boolean;
	edit_AsPs: boolean;
	delete_AsPs: boolean;
	users_manage: boolean;
	edit_missions: boolean;
	delete_missions: boolean;
	edit_preplanned: boolean;
	edit_types: boolean;
	history_access: boolean;
	all_incident_access: boolean;
	configure_ara: boolean;
	sign_ara: boolean;
	create_review_ara: boolean;
	view_ara: boolean;
	download_media: boolean;
	view_ef: boolean;
	edit_ef: boolean;
	delete_ef: boolean;
	create_dl: boolean;
	view_dl: boolean;
	description: string;

	timestamp = Date.now();
	constructor(
		id?: number,
		name?: string,
		edit_agents?: boolean,
		delete_agents?: boolean,
		edit_AsPs?: boolean,
		delete_AsPs?: boolean,
		users_manage?: boolean,
		edit_missions?: boolean,
		delete_missions?: boolean,
		edit_preplanned?: boolean,
		edit_types?: boolean,
		history_access?: boolean,
		all_incident_access?: boolean,
		configure_ara?: boolean,
		sign_ara?: boolean,
		create_review_ara?: boolean,
		view_ara?: boolean,
		download_media?: boolean,
		view_ef?: boolean,
		edit_ef?: boolean,
		delete_ef?: boolean,
		create_dl?: boolean,
		view_dl?: boolean,
		description?: string
	) {
		this.id = id ? id : -1;
		this.name = name ? name.trim() : "none";

		this.edit_agents = edit_agents ? edit_agents : false;
		this.delete_agents = delete_agents ? delete_agents : false;
		this.edit_AsPs = edit_AsPs ? edit_AsPs : false;
		this.delete_AsPs = delete_AsPs ? delete_AsPs : false;
		this.users_manage = users_manage ? users_manage : false;
		this.edit_missions = edit_missions ? edit_missions : false;
		this.delete_missions = delete_missions ? delete_missions : false;
		this.edit_preplanned = edit_preplanned ? edit_preplanned : false;
		this.edit_types = edit_types ? edit_types : false;
		this.history_access = history_access ? history_access : false;
		this.all_incident_access = all_incident_access ? all_incident_access : false;
		this.configure_ara = configure_ara ? configure_ara : false;
		this.sign_ara = sign_ara ? sign_ara : false;
		this.create_review_ara = create_review_ara ? create_review_ara : false;
		this.view_ara = view_ara ? view_ara : false;
		this.download_media = download_media ? download_media : false;
		this.view_ef = view_ef ? view_ef : false;
		this.edit_ef = edit_ef ? edit_ef : false;
		this.delete_ef = delete_ef ? delete_ef : false;
		this.create_dl = create_dl ? create_dl : false;
		this.view_dl = view_dl ? view_dl : false;
		this.description = description ? description : "";
	}

	public static readonly nullLevel: () => Level = () => {
		return new Level(-1, "None", false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, "");
	};

	public static readonly adminLevel: () => Level = () => {
		return new Level(-1, "None", true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, "");
	};

	public static readonly fromJson: (json: string) => Level = (json: string) => {
		var uj = JSON.parse(json);
		return new Level(
			uj.id,
			uj.name,
			uj.edit_agents,
			uj.delete_agents,
			uj.edit_AsPs,
			uj.delete_AsPs,
			uj.users_manage,
			uj.edit_missions,
			uj.delete_missions,
			uj.edit_preplanned,
			uj.edit_types,
			uj.history_acces,
			uj.all_incident_access,
			uj.configure_ara,
			uj.sign_ara,
			uj.create_review_ara,
			uj.view_ara,
			uj.download_media,
			uj.view_ef,
			uj.edit_ef,
			uj.delete_ef,
			uj.create_dl,
			uj.view_dl,
			uj.description
		);
	};

	public readonly getJson: () => string = () => {
		(this as any).history_acces = this.history_access;
		return JSON.stringify(this, [
			"id",
			"name",
			"edit_agents",
			"delete_agents",
			"edit_AsPs",
			"delete_AsPs",
			"users_manage",
			"edit_missions",
			"delete_missions",
			"edit_preplanned",
			"edit_types",
			"history_acces",
			"all_incident_access",
			"configure_ara",
			"sign_ara",
			"create_review_ara",
			"view_ara",
			"download_media",
			"view_ef",
			"edit_ef",
			"delete_ef",
			"create_dl",
			"view_dl",
			"description"
		]);
	};
}
