<app-blue-cross
	class="nav-side-menu-button {{ isMenuOpen() ? 'open' : '' }}"
	(clickCb)="openMenu($event)"
	[open]="isMenuOpen()"
>
</app-blue-cross>

<div class="main-transparent-mask nav-mask" *ngIf="__open" (click)="openMenu($event)"></div>

<div class="nav-side-menu {{ isMenuOpen() ? 'open' : '' }}" appExtendToBottom>
	<div class="content">
		<div
			[attr.data-add-action-name]="route.route"
			[attr.data-cy]="route.name"
			#navMenuElem
			class="nav-menu-option {{ selectedRoute.name === route.name ? 'active' : '' }}"
			*ngFor="let route of routes"
			(click)="onClick(route)"
			(mouseover)="setOpenSubMenu(route, navMenuElem)"
			(mouseleave)="closeSubMenu()"
			[class.disabled]="offlineDisabledRoutes(route.route)"
		>
			<img
				src="{{ route.iconLocation }}"
				[class.grayscale]="offlineDisabledRoutes(route.route)"
			/>
			<span>{{ route.name }}</span>
		</div>
	</div>
	<div class="nav-footer">
		<span>Unblur {{ _year }}</span>
	</div>

	<div
		class="nav-sub-menu {{ __openSubMenu && isMenuOpen() ? '' : 'closed' }}"
		[ngStyle]="{ top: selectedRoute.subMenuY + 'px' }"
		(mouseover)="openSubMenu()"
		(mouseleave)="closeSubMenu()"
	>
		<li
			[attr.data-add-action-name]="option.tabIndex"
			class="nav-sub-menu-option"
			*ngFor="let option of selectedRoute.options"
			(click)="option.clickCb(option.route, option.tabIndex); closeBothMenus()"
		>
			<span>{{ option.name }}</span>
		</li>
	</div>
</div>
