import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { SLRating } from "src/app/dto/ara/risk-information-relation";
import { LocaleMap } from "src/app/global/constants/text/text-interface";
import { TextProvider } from "src/app/global/constants/text/text-provider";
import { SLMatrixComponent } from "../../../elements/sl-matrix/sl-matrix.component";

@Component({
	selector: "app-risk-rating-modal",
	templateUrl: "risk-rating-modal.component.html",
	styleUrls: ["risk-rating-modal.css"]
})
export class RiskRatingModalComponent implements OnInit, AfterViewInit {
	@Input() initialRating: SLRating = { severity: 0, likelihood: 0 };

	@Output() confirmRating = new EventEmitter<SLRating>();
	@Output() cancel = new EventEmitter<void>();

	@ViewChild(SLMatrixComponent) matrix!: SLMatrixComponent;

	public slRating: SLRating = { severity: 0, likelihood: 0 };

	public text: () => LocaleMap;

	constructor(textProv: TextProvider) {
		this.text = textProv.getStringMap;
	}

	ngOnInit(): void {
		this.slRating.severity = this.initialRating.severity;
		this.slRating.likelihood = this.initialRating.likelihood;
	}

	ngAfterViewInit(): void {
		this.setActiveRating();
	}

	public readonly isSeverityActive = (value: number): boolean => {
		return value === this.slRating.severity;
	};

	public readonly setSeverity = (value: number): void => {
		this.slRating.severity = value;
		this.setActiveRating();
	};

	public readonly isLikelihoodActive = (value: number): boolean => {
		return value === this.slRating.likelihood;
	};
	public readonly setLikelihood = (value: number): void => {
		this.slRating.likelihood = value;
		this.setActiveRating();
	};

	public readonly setSL = (rating: SLRating): void => {
		this.setSeverity(rating.severity);
		this.setLikelihood(rating.likelihood);
	};

	public readonly canConfirm = (): boolean => {
		return !!this.slRating.severity && !!this.slRating.likelihood;
	};

	public readonly getRating = (): number => {
		return this.slRating.severity * this.slRating.likelihood;
	};

	public readonly confirm = (): void => {
		this.confirmRating.emit(this.slRating);
	};

	public readonly _cancel = (): void => {
		this.cancel.emit();
	};

	private readonly setActiveRating = (): void => {
		if (this.canConfirm()) this.matrix.selectCell(this.slRating.likelihood, this.slRating.severity);
	};
}
