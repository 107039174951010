import { DTO } from "../net/dto";
import { Ara } from "./ara";
import { RiskInformation } from "./risk-information";

export class Scenario implements DTO {
	id_scenario: number;
	name: string;
	deleted: boolean;
	riskInformations: Array<RiskInformation> = new Array<RiskInformation>();
	id: number;
	timestamp: number = Date.now();

	constructor(id: number, name: string, deleted?: boolean) {
		this.id = this.id_scenario = id;
		this.name = name;
		this.deleted = deleted ? deleted : false;
	}

	public static readonly fromJson: (json: string) => Scenario = (json: string) => {
		let jc: any = JSON.parse(json);
		return new Scenario(jc.id_scenario, jc.name, jc.deleted);
	};

	public readonly getJson: () => string = () => {
		return JSON.stringify(this, ["id_scenario", "name"]);
	};
}
