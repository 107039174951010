import { FsgFlat } from "src/app/dto/fsg/fsg-flat";
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { MainService } from "../../../../../global/main.service";
import { FsgService } from "src/app/incident/incident-tools/fsg/fsg.service";
import { TextProvider } from "src/app/global/constants/text/text-provider";
import { LocaleMap } from "src/app/global/constants/text/text-interface";
import { FsgBuilding } from "src/app/dto/fsg/fsg-building";

@Component({
	selector: "app-information-popup",
	templateUrl: "information-popup.component.html",
	styleUrls: ["information-popup.css"]
})
export class InformationPopupComponent implements OnInit {
	@Input() currentFsg!: FsgBuilding;

	@Output() closed = new EventEmitter<void>();
	@ViewChild("infoIcon") infoIconRef!: ElementRef;

	public showSelectAddressPopup: boolean = false;
	public sectorInfoOpened: boolean = false;
	public fireFlat: FsgFlat | undefined;
	public readonly text: () => LocaleMap;

	private readonly fsgService: FsgService;
	private readonly main: MainService;

	constructor(textprov: TextProvider, fsgService: FsgService, main: MainService) {
		this.text = textprov.getStringMap;
		this.fsgService = fsgService;
		this.main = main;
	}

	async ngOnInit() {
		const allFlats = await this.fsgService.getAllFlatValues(this.currentFsg);
		this.fireFlat = this.fsgService.getFireOrigin(allFlats!);
	}

	public readonly close = (): void => {
		this.closed.emit();
	};

	public async setFsgAddress(address: string): Promise<void> {
		if (this.currentFsg) {
			this.currentFsg.address = address;
			await this.fsgService.saveFsg(this.currentFsg); // savFsg after ref
		}
		this.toggleSelectAddressPopup();
	}

	public readonly toggleSelectAddressPopup: Function = () => {
		this.showSelectAddressPopup = !this.showSelectAddressPopup;
	};

	public readonly getAddressAndCoordinates: () => string = () => {
		if (!this.currentFsg) return this.text().LOCATION;
		if (!this.currentFsg.address) return `${this.currentFsg.lat.toFixed(5)}º ${this.currentFsg.lon.toFixed(5)}º`;
		return `${this.currentFsg.address}`;
	};

	public copyAddress(): void {
		const coordinates = `${this.currentFsg.lat.toFixed(5)}º ${this.currentFsg.lon.toFixed(5)}º`;
		coordinates && navigator.clipboard.writeText(coordinates).then(() => this.main.setSnackbar(this.text().COPIED_COORDINATES));
	}

	public toggleSectorInfo(): void {
		this.sectorInfoOpened = !this.sectorInfoOpened;
	}

	public getLastUpdated(): string {
		if (!this.currentFsg?.__flats) return this.text().UNSPECIFIED;
		const ts = this.currentFsg.getLastUpdateTs();
		const date = new Date(ts);
		const dateTime =
			(date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) +
			":" +
			(date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
			" - " +
			(date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) +
			"/" +
			(date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1);

		return this.currentFsg.last_update_ms > 0 ? dateTime : "";
	}

	public getLastUpdatedFlats(): FsgFlat[] {
		if (!this.currentFsg?.__flats) return [];
		let flats = [];
		const updated = this.currentFsg.__flats.filter((flat) => flat.timestamp_ms === this.currentFsg.last_update_ms);
		for (let flat of updated || []) {
			if (this.fsgService.hasFlatBeenUpdated(flat)) {
				flats.push(flat);
			}
		}
		return flats;
	}

	public goToFlat(id: number): void {
		const element = document.getElementById(`${id}`);
		if (!element) {
			return;
		}
		this.close();
		element.scrollIntoView({
			behavior: "smooth",
			block: "start",
			inline: "nearest"
		});
	}

	public getUpdatedFlatName(fireFlat: FsgFlat): string {
		const latestFlatValue = this.currentFsg!.__flats?.find((flat) => flat.id_flat === fireFlat.id_flat);
		return latestFlatValue ? latestFlatValue.flat_name : " - ";
	}
}
