import { ChangeDetectorRef, Component, Input, Output, EventEmitter } from "@angular/core";
import { Size } from "src/app/dto/map/size";
import { Personnel } from "src/app/dto/resources/personnel";
import { LocaleMap } from "src/app/global/constants/text/text-interface";
import { TextProvider } from "src/app/global/constants/text/text-provider";
import { MainService } from "src/app/global/main.service";

@Component({
	selector: "app-resource-icon",
	templateUrl: "./resource-icon.component.html",
	styleUrls: ["../resource-card.css"]
})
export class ResourceIconComponent {
	@Input() personnel!: Personnel;
	@Output() hasChanged = new EventEmitter<boolean>();

	public text: () => LocaleMap;
	public showColorPicker: boolean = false;

	private readonly main: MainService;
	private readonly cdRef: ChangeDetectorRef;

	constructor(main: MainService, textProv: TextProvider, cdRef: ChangeDetectorRef) {
		this.main = main;
		this.text = textProv.getStringMap;
		this.cdRef = cdRef;
	}

	public readonly addNewIcon = (event: Event): void => {
		if ((event.target as HTMLInputElement).files && (event.target as HTMLInputElement).files!.length) {
			const file = (event.target as HTMLInputElement).files![0];
			this.readAsText(file);
		}
	};

	public readonly attachSvgToResource = (svg: Document): boolean => {
		try {
			if (svg && svg.children && svg.children.length) {
				let svgElem = svg.children[0];
				const width = Number(svgElem.getAttribute("width")!.match(/\d+/)![0]);
				const height = Number(svgElem.getAttribute("height")!.match(/\d+/)![0]);
				// to take the first path to make it single path and avoid the clip-path issue
				let pathElem = svgElem.getElementsByTagName("path");
				const path = pathElem[0].getAttribute("d");
				if (!this.testSvgPathDAttr || path == null || pathElem[1]) throw "invalid path";
				const color = pathElem[0].getAttribute("fill") ? pathElem[0].getAttribute("fill") : "#FFFFFF";
				if (!this.testSvgPathFillAttr) throw "invalid color";
				this.personnel.icon_size = new Size(width, height);
				this.personnel.icon_width = width;
				this.personnel.icon_height = height;
				this.personnel.icon_path = path;
				this.personnel.color = color!;
				this.cdRef.detectChanges();
				return true;
			}
			return false;
		} catch (e) {
			this.main.addDangerAlert(this.text().INVALID_ICON);
			return false;
		}
	};

	public readonly testSvgPathDAttr = (path: string): boolean => {
		// copied from https://stackoverflow.com/questions/54961620/test-if-svg-path-d-property-string-is-valid
		const reEverythingAllowed = /[MmZzLlHhVvCcSsQqTtAa0-9-,.\s]/g;

		const bContainsIllegalCharacter = !!path.replace(reEverythingAllowed, "").length;
		const bContainsAdjacentLetters = /[a-zA-Z][a-zA-Z]/.test(path);
		const bInvalidStart = /^[0-9-,.]/.test(path);
		const bInvalidEnd = /.*[-,.]$/.test(path.trim());

		return !bContainsIllegalCharacter && !bContainsAdjacentLetters && !bInvalidStart && !bInvalidEnd;
	};

	public readonly testSvgPathFillAttr = (fill: string): boolean => {
		// copied from https://stackoverflow.com/questions/1636350/how-to-identify-a-given-string-is-hex-color-format
		return fill.match(/^#(([0-9a-fA-F]{2}){3}|([0-9a-fA-F]){3})$/) !== null;
	};

	public readonly getIconColorAsBg = (): { "background-color": string } => {
		return { "background-color": this.personnel.color };
	};

	public readonly openColorPicker = (evt: Event): boolean => {
		evt.stopPropagation();
		return (this.showColorPicker = true);
	};

	public readonly closeColorPicker = (): boolean => {
		return (this.showColorPicker = false);
	};

	public readonly changeColor = (color: string): void => {
		this.personnel.color = color;
		this.cdRef.detectChanges();
	};

	private readonly readAsText = (file: File): void => {
		let e = new FileReader();
		e.readAsText(file);
		e.onload = () => {
			const parser = new DOMParser();
			const svg = parser.parseFromString(e.result as string, "image/svg+xml");
			this.attachSvgToResource(svg);
		};
	};
}
