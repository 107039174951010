import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, Sanitizer, ViewChild, ViewChildren, ɵdetectChanges } from "@angular/core";
import { LocaleMap } from "src/app/global/constants/text/text-interface";
import { TextProvider } from "src/app/global/constants/text/text-provider";
import * as html2canvas from "html2canvas";
import { DocumentService } from "src/app/document/document.service";
import { DocumentTemplate } from "../../../template.interface";
import { DEBRIEF_OPTION } from "src/app/dto/jesip/debrief";
import { FormsService } from "src/app/incident/incident-tools/forms/forms.service";
import { PAGE_SIZES } from "src/app/dto/Documents/page-sizes";
import { Incident } from "src/app/dto/items/incident";

@Component({
	selector: "app-debrief-blank-template",
	templateUrl: "debrief-blank.component.html",
	styleUrls: ["debrief-blank.css", "../../jesip.css", "../../../../document.css"]
})
export class BlankDebriefTemplateComponent implements OnInit, DocumentTemplate, AfterViewInit {
	@Input() generationTime!: number;
	@Input() event!: Incident;

	@Output() docReady = new EventEmitter<HTMLCanvasElement[]>();

	@ViewChildren("pageElements") pageElements!: QueryList<ElementRef>;
	@ViewChild("inspectedOptionElement") inspectedOptionElement!: ElementRef;

	public inspectedOption: DebriefItem | undefined;
	public numberOfPages: number = 1;
	public pages = new Array<Array<DebriefItem>>();
	public readonly pageSize = PAGE_SIZES.STANDARD;

	public readonly text: () => LocaleMap;

	private readonly jesip: FormsService;
	private readonly docService: DocumentService;
	private abortDownload = false;

	constructor(tp: TextProvider, jes: FormsService, doc: DocumentService) {
		this.text = tp.getStringMap;
		this.jesip = jes;
		this.docService = doc;
	}

	ngOnInit(): void {
		if (!this.event) this.docService.downloadError$.next();
		this.setPages();
		this.docService.onDownloadCancel$.subscribe(() => this.abortDownload = true);
	}

	async ngAfterViewInit(): Promise<void> {
		try {
			const pages = this.pageElements["_results"];
			const output = new Array<HTMLCanvasElement>();
			for (let i = 0; i < pages.length; i++) {
				this.docService.setLoadingMessage(this.text().GENERATING_DOCUMENTATION_PAGE(i, pages.length));
				const canvas = await html2canvas.default(this.pageElements["_results"][i].nativeElement);
				if(this.abortDownload) return;
				output.push(canvas);
			}
			this.docReady.emit(output);
		} catch (error) {
			if(this.abortDownload) return;
			console.error(error);
			this.docService.downloadError$.next();
		}
	}

	public readonly setPages: Function = () => {
		const debriefOptions = Object.keys(DEBRIEF_OPTION);
		const debrief = this.jesip.currentDebrief;
		let currentPage = new Array<DebriefItem>();
		const headerHeight = this.calculateHeaderHeight();
		let currentHeight = headerHeight;
		if (!debrief) throw "BlankDebriefTemplateComponent initialized without a valid Debrief template on JESIP service";
		for (let i = 0; i < debriefOptions.length; i++) {
			const option = debriefOptions[i];
			let item = new DebriefItem((this.text() as any)["JESIP_DEBRIEF_SUBPAGE_TITLE_" + option]);
			const arr = debrief!.getOptionItem((DEBRIEF_OPTION as any)[option]);
			arr!.items.forEach((opt, index) => {
				item.options.push((this.text() as any)["JESIP_DEBRIEF_" + option + "_" + (index + 1)]);
			});
			if (currentHeight + this.calculateOptionHeight(item) < this.pageSize.height - 150) {
				currentPage.push(item);
			} else {
				this.pages.push(currentPage);
				currentPage = new Array<DebriefItem>();
				currentPage.push(item);
				currentHeight = headerHeight;
			}
			currentHeight += this.calculateOptionHeight(item);
		}
		if (!this.pages.find((e) => e === currentPage)) this.pages.push(currentPage);
	};

	private readonly calculateHeaderHeight: () => number = () => {
		return 130;
	};

	private readonly calculateOptionHeight: (option: DebriefItem) => number = (option) => {
		this.inspectedOption = option;
		ɵdetectChanges(this);
		const output = this.inspectedOptionElement!.nativeElement.getBoundingClientRect().height;
		this.inspectedOption = undefined;
		const bodyTopMargin = 10;
		return output + bodyTopMargin;
	};
}
class DebriefItem {
	header: string;
	options = new Array<string>();

	constructor(header: string) {
		this.header = header;
	}
}
