import { Component, EventEmitter, HostBinding, Input, Output } from "@angular/core";
import { TextProvider } from "src/app/global/constants/text/text-provider";
import { LocaleMap } from "src/app/global/constants/text/text-interface";
import { Overlay } from "src/app/dto/items/overlay";

@Component({
	selector: "app-download-delete-menu",
	templateUrl: "download-delete-popup.component.html",
	styleUrls: ["download-delete-popup.component.css"]
})
export class DownloadDeletePopupComponent {
	@Input() drawing!: Overlay;

	@Output() deleteDrawing = new EventEmitter<Overlay>();
	@Output() downloadDrawing = new EventEmitter<Overlay>();
	@Output() closed = new EventEmitter<void>();

	@HostBinding("style.top") @Input() axisY = "-70px";
	@HostBinding("style.left") @Input() axisX = "0px";

	public downloadIconUrl = "resources/img/drawing-item-icons/download_arrow.svg";
	public deleteIconUrl = "resources/img/delete.svg";
	public readonly text: () => LocaleMap;

	constructor(textprov: TextProvider) {
		this.text = textprov.getStringMap;
	}

	public readonly close = (): void => {
		this.closed.emit();
	};

	public readonly deleteOption = (): void => {
		this.deleteDrawing.emit(this.drawing);
		this.close();
	};

	public readonly downloadOption = (): void => {
		this.downloadDrawing.emit(this.drawing);
		this.close();
	};
}
