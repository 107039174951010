import { DTO } from "../net/dto";

export class Jdm implements DTO {
	public id: number;
	public id_user: number;
	public id_mission: number;
	public gather: string;
	public assess: string;
	public consider: string;
	public identify: string;
	public takeAction: string;
	public timestamp_ms: number;
	public timestamp: number;

	constructor(id: number, id_user: number, id_mission: number, gather?: string, assess?: string, consider?: string, identify?: string, takeAction?: string, timestamp_ms?: number) {
		this.id = id ? id : -1;
		(this.id_user = id_user || 0),
			(this.id_mission = id_mission || 0),
			(this.gather = gather || ""),
			(this.assess = assess || ""),
			(this.consider = consider || ""),
			(this.identify = identify || ""),
			(this.takeAction = takeAction || ""),
			(this.timestamp_ms = timestamp_ms || -1),
			(this.timestamp = Date.now());
	}

	public static readonly fromJson = (json: string): Jdm => {
		const jc = JSON.parse(json);
		return new Jdm(jc.id ? jc.id : -1, jc.id_user, jc.id_mission, jc.gather, jc.assess, jc.consider, jc.identify, jc.takeAction, jc.timestamp_ms);
	};

	public readonly getJson = (): string => {
		return JSON.stringify(this, ["id", "id_user", "id_mission", "gather", "assess", "consider", "identify", "takeAction", "timestamp_ms"]);
	};
}

export enum JDM_ITEM {
	gather = "gather",
	assess = "assess",
	consider = "consider",
	identify = "identify",
	takeAction = "takeAction"
}

export class JesipJdmItem {
	public readonly key: JDM_ITEM; //used to identify the key
	public readonly shortTitle: string;
	public readonly fullTitle: string;
	public readonly description: string;
	public value: string | undefined;

	constructor(key: JDM_ITEM, shortTitle: string, fullTitle: string, description: string, value?: string) {
		this.key = key;
		this.shortTitle = shortTitle;
		this.fullTitle = fullTitle;
		this.description = description;
		this.value = value;
	}
}
