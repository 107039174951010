export class NotificationUpdate {
	id_data: number;
	title: NOTIFICATION_TYPE;
	mission: number;
	read: boolean;

	constructor(id_data: number, title: NOTIFICATION_TYPE, missionId: number, read: boolean) {
		this.id_data = id_data;
		this.title = title;
		this.mission = missionId;
		this.read = read;
	}
}

export enum NOTIFICATION_TYPE {
	OFV,
	ARA,
	COMMAND_STRUCTURE,
	TACTICAL,
	OVERLAY,
	DEBRIEF,
	FSG,
	DECISION,
	METHANE,
	IIMARCH,
	JDM
}
