import { Component, Input } from "@angular/core";
import { Ara } from "src/app/dto/ara/ara";
import { Incident } from "src/app/dto/items/incident";
import { LocaleMap } from "src/app/global/constants/text/text-interface";
import { TextProvider } from "src/app/global/constants/text/text-provider";

@Component({
	selector: "app-ara-header",
	templateUrl: "./ara-header.component.html",
	styleUrls: ["../../../document.css"]
})
export class AraHeaderComponent {
	@Input() event?: Incident;
	@Input() ara?: Ara;
	public multiscenario: boolean = false;

	public readonly text: () => LocaleMap;

	constructor(tp: TextProvider) {
		this.text = tp.getStringMap;
	}

	public getScenarioString(): string {
		if (!this.ara) return this.text().UNSPECIFIED;
		if (this.ara.__scenario) {
			return this.ara.__scenario.name;
		}
		let output = "";
		for (let i = 0; i < this.ara.scenarios.length; i++) {
			output += this.ara.scenarios[i].__object?.name;
			if (i < this.ara.scenarios.length - 1) {
				output += "; ";
			}
		}
		return output.length > 100 ? output.substring(0, 100) + "..." : output;
	}
}
