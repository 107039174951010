import { DTO } from "../net/dto";
import { Appliance } from "../resources/appliance";
import { ClosedPersonnelRelation } from "../resources/appliance-relation";
import { Personnel } from "../resources/personnel";
import { Resource } from "../resources/resource";
import { ResourceState } from "../resources/state";
import { User } from "../user/user";

export class CSSectorResourceAssignation implements DTO {
	id_assignation: number;
	id_sector: number;
	id_resource: number;
	is_closed: boolean;

	__object: Resource | undefined;
	__state: ResourceState | undefined;
	__name: string | undefined;
	__user: User | undefined;
	__personnel: Array<Personnel> | undefined;
	__closed_personnel: Array<ClosedPersonnelRelation> | undefined;

	id: number;
	timestamp = Date.now();
	constructor(id_resource: number, id_sector: number, id_agent: number, is_closed: boolean) {
		this.id_sector = id_sector;
		this.id = this.id_assignation = id_resource;
		this.id_resource = id_agent;
		this.is_closed = is_closed;
	}

	public static readonly fromJson: (json: string) => CSSectorResourceAssignation = (json) => {
		var jc = JSON.parse(json);
		return new CSSectorResourceAssignation(jc.id_assignation, jc.id_sector, jc.id_resource, jc.is_closed);
	};

	public readonly getJson: Function = () => {
		return JSON.stringify(this, ["id_assignation", "id_sector", "id_resource", "is_closed"]);
	};

	public readonly addAgentObject: Function = (age: Resource) => {
		if (!age) return false;
		this.__object = age;
		this.__state = age.__state;
		this.__name = age.name;
		if (age instanceof Personnel) this.__user = age.__user;
		if (age instanceof Appliance) this.__personnel = age.personnel;
		if (age instanceof Appliance) this.__closed_personnel = age.closed_personnel;
		return true;
	};
}
