import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { LocaleMap } from "src/app/global/constants/text/text-interface";
import { TextProvider } from "src/app/global/constants/text/text-provider";

@Component({
	selector: "app-task-completed-dialog",
	templateUrl: "task-completed.component.html",
	styleUrls: ["task-completed.css"]
})
export class TaskCompletedDialogComponent implements OnInit {
	@Input() headerText: string = "";
	@Input() bodyText: string = "";
	@Input() infoText: string = "";

	@Output() closeCallback = new EventEmitter<void>();
	@Output() gotoAraCallBack = new EventEmitter();

	public readonly text: () => LocaleMap;

	constructor(text: TextProvider) {
		this.text = text.getStringMap;
	}

	ngOnInit(): void {
		if (!this.headerText) this.headerText = this.text().INCIDENT_CLOSED;
	}

	public readonly close: Function = () => {
		this.closeCallback.emit();
	};

	public readonly goToAra: Function = (param: any) => {
		this.gotoAraCallBack.emit(param);
	};
}
