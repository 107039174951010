import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { LocaleMap } from "src/app/global/constants/text/text-interface";
import { TextProvider } from "src/app/global/constants/text/text-provider";

@Component({
	selector: "app-confirmation-dialog",
	templateUrl: "confirmation-dialog.component.html",
	styleUrls: ["confirmation-dialog.component.css"]
})
export class ConfirmationDialogComponent implements OnInit {
	@Input() headerText: string = "Error";
	@Input() bodyText: string = "";
	@Input() confirmText: string = "";
	@Input() cancelText: string = "";
	@Input() disabled: boolean = false;

	@Output() closeCallback = new EventEmitter<void>();
	@Output() cancelCallback = new EventEmitter();
	@Output() confirmCallback = new EventEmitter();

	public readonly text: () => LocaleMap;

	constructor(textProv: TextProvider) {
		this.text = textProv.getStringMap;
		this.confirmText = this.text().CONFIRM;
	}

	ngOnInit(): void {
		if (!this.cancelText) this.cancelText = this.text().CANCEL;
		if (!this.confirmText) this.confirmText = this.text().CONFIRM;
	}

	public readonly confirm: Function = () => {
		this.confirmCallback.emit();
	};

	public readonly cancel: Function = () => {
		this.cancelCallback.emit();
	};

	public close(): void {
		this.closeCallback.emit();
	}
}
