<div class="icon-section">
	<app-svg-preview
		*ngIf="personnel.icon_path"
		class="icon-preview big"
		[path]="personnel.icon_path"
		[color]="personnel.color"
		[size]="personnel.icon_size"
	></app-svg-preview>
	<label *ngIf="personnel.icon_path" class="edit-photo edit-photo-res">
		<input
			svg-icon-select
			popover-trigger="'mouseenter'"
			(change)="addNewIcon($event)"
			id="file-upload"
			type="file"
			accept=".svg"
		/>
		{{ text().EDIT }}
	</label>

	<div *ngIf="!personnel.icon_path">
		<label class="select-photo">
			<input
				data-add-action-name="personnel-file-uploader"
				svg-icon-select
				popover-trigger="'mouseenter'"
				(change)="addNewIcon($event)"
				id="file-upload"
				type="file"
				accept=".svg"
			/>
			{{ text().CLICK_ADD_ICON }}
		</label>
	</div>

	<div class="resource-color-input">
		<div class="card-color-sample" appSetStyle [ngStyle]="getIconColorAsBg()"></div>
		<input
			data-cy="personnel-colourpicker"
			colorpicker
			class="card-input card-color-input force-uppercase"
			required
			[(ngModel)]="personnel.color"
			placeholder="Colour"
			type="text"
			(click)="openColorPicker($event)"
		/>
	</div>
	<app-color-picker
		*ngIf="showColorPicker"
		(selectedColor)="changeColor($event)"
		[showPreferred]="true"
		[inputColor]="personnel.color"
		[closeCb]="closeColorPicker"
	></app-color-picker>
</div>
