import { Directive, Output, EventEmitter, ElementRef, NgZone, Inject, OnInit, OnDestroy } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { ConstantsService } from "src/app/global/constants/constants.service";
import { SettingsParser } from "src/app/global/constants/settings.parser";

@Directive({
	selector: "[appGrecaptcha]"
})
export class RecaptchaDirective implements OnInit, OnDestroy {
	@Output() recaptchaLoaded = new EventEmitter<void>();
	@Output() recaptchaSuccess = new EventEmitter<string>();
	@Output() recaptchaExpired = new EventEmitter<unknown>();
	@Output() recaptchaError = new EventEmitter<unknown>();

	public widgetId: number | null = null;

	private readonly elementRef: ElementRef;
	private readonly ngZone: NgZone;
	private readonly dom: Document;
	private readonly cnst: ConstantsService;
	private readonly scriptId = "volt-recaptcha";
	private config: {
		sitekey: string;
		callback: Function;
		"error-callback": Function;
		"expired-callback": Function;
		theme: string;
	} | undefined;

	constructor(elementRef: ElementRef, ngZone: NgZone, @Inject(DOCUMENT) dom: Document, cnst: ConstantsService) {
		this.elementRef = elementRef;
		this.ngZone = ngZone;
		this.dom = dom;
		this.cnst = cnst;
		const settings = SettingsParser.getSettings();
		if(settings && settings.recaptchaKey){
			this.config = {
				sitekey: settings.recaptchaKey,
				callback: this.onSuccessCallback.bind(this),
				"error-callback": this.onErrorCallback.bind(this),
				"expired-callback": this.onExpiredCallback.bind(this),
				theme: "dark"
			};
		}
		SettingsParser.settingsLoaded$.subscribe(() => {
			const settings = SettingsParser.getSettings();
			if(settings && settings.recaptchaKey){
				this.config = {
					sitekey: settings.recaptchaKey,
					callback: this.onSuccessCallback.bind(this),
					"error-callback": this.onErrorCallback.bind(this),
					"expired-callback": this.onExpiredCallback.bind(this),
					theme: "dark"
				};
			}
		})
	}

	ngOnInit(): void {
		this.registerCaptchaCallback();
		this.addScript();
	}

	ngOnDestroy(): void {
		this.widgetId = null;
	}

	public registerCaptchaCallback(): void {
		(window as any).recaptchaCallback = () => (this.widgetId = this.renderCaptcha());
		this.recaptchaLoaded.emit();
	}

	private readonly renderCaptcha = (): number => {
		return (window as any).grecaptcha.render(this.elementRef.nativeElement, this.config);
	};

	private readonly addScript = (): void => {
		if (this.dom.getElementById(this.scriptId) !== null) {
			this.renderCaptcha();
			return;
		}

		const script = this.dom.createElement("script");
		script.src = "https://www.google.com/recaptcha/api.js?onload=recaptchaCallback&render=explicit";
		script.id = this.scriptId;
		script.async = true;
		script.defer = true;
		this.dom.body.appendChild(script);
	};

	private readonly onSuccessCallback = (token: string): void => {
		this.ngZone.run(() => {
			this.recaptchaSuccess.emit(token);
		});
	};

	private readonly onErrorCallback = (): void => {
		this.ngZone.run(() => {
			this.recaptchaError.emit();
		});
	};

	private readonly onExpiredCallback = (): void => {
		this.ngZone.run(() => {
			this.recaptchaExpired.emit();
		});
	};
}
