import { CSBlock } from "./cs-block";
import { DTO } from "../net/dto";

export class CSSupport extends CSBlock implements DTO {
	id_support: number;
	id_commander: number;
	name: string;
	capabilities: string;

	id: number;
	timestamp = Date.now();
	constructor(id_support: number, id_commander: number, name: string, capabilities?: string, color?: string, title?: string, x?: number, y?: number) {
		super(color ? color : "#377ab7", title ? title : "", x ? x : 0, y ? y : 0);
		this.id = this.id_support = id_support ? id_support : -1;
		this.id_commander = id_commander ? id_commander : -1;
		this.name = name ? name : "";
		this.capabilities = capabilities ? capabilities : "";
	}

	public static readonly fromJson: (json: string) => CSSupport = (json) => {
		var jc = JSON.parse(json);
		return new CSSupport(jc.id_support, jc.id_commander, jc.name, jc.capabilities, jc.color, jc.title, jc.x, jc.y);
	};

	public readonly getJson: Function = () => {
		return JSON.stringify(this, ["id_support", "id_commander", "name", "capabilities", "color", "title", "x", "y"]);
	};
}
