import { Size } from "../../map/size";
import { DTO } from "../../net/dto";

export class PoiType implements DTO {
	id: number;
	name: string;
	color: string;
	description: string;
	icon_path: string;
	icon_size: Size;
	is_global: boolean;
	specialType: boolean;
	visible: boolean;
	timestamp: number;
	constructor(id: number, name: string, color?: string, description?: string, icon_path?: string, icon_size?: Size, is_global?: boolean, specialType?: boolean) {
		this.id = id;
		this.name = name;
		this.color = color ? color : "#000000";
		this.description = description ? description : "";
		this.icon_path = icon_path ? icon_path : "";
		this.icon_size = icon_size ? icon_size : new Size(0, 0);
		this.is_global = is_global ? is_global : false;
		this.specialType = specialType ? specialType : false;
		this.visible = true;
		this.timestamp = Date.now();
	}

	public static readonly fromJson: (json: string) => PoiType = (json: string) => {
		var jc = JSON.parse(json);
		return new PoiType(jc.id, jc.name, jc.color, jc.description, jc.icon_path, jc.icon_size, jc.is_global, jc.specialType);
	};

	public readonly getJson: Function = () => {
		return JSON.stringify(this, ["id", "name", "color", "description", "icon_path", "icon_size", "x", "y", "is_global", "specialType"]);
	};
}
