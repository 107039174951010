import { Component, EventEmitter, Output } from "@angular/core";
import { UploadedFile } from "src/app/dto/net/uploaded-file";
import { LocaleMap } from "src/app/global/constants/text/text-interface";
import { TextProvider } from "src/app/global/constants/text/text-provider";
import { MainService } from "src/app/global/main.service";

@Component({
	selector: "app-add-image-dialog",
	templateUrl: "add-image.component.html",
	styleUrls: ["add-image-dialog.component.css"]
})
export class AddImageDialogComponent {
	@Output() cancel = new EventEmitter<void>();
	@Output() confirm = new EventEmitter<number>();
	@Output() failure = new EventEmitter<void>();

	public readonly text: () => LocaleMap;
	private readonly main: MainService;
	constructor(textProv: TextProvider, main: MainService) {
		this.text = textProv.getStringMap;
		this.main = main;
	}

	public readonly onCancel: Function = () => {
		this.cancel.emit();
	};

	public readonly onConfirm: Function = (id: number) => {
		this.confirm.emit(id);
	};

	public readonly onFileUploaded: Function = (imgsArr: Array<UploadedFile> | null) => {
		if (!imgsArr) {
			this.main.addDangerAlert(this.text().SAVE_ERROR);
			return;
		}
		if (imgsArr) {
			if (imgsArr[0].id === -1) this.failure.emit();
			else this.onConfirm(imgsArr[0].id);
		}
	};
}
