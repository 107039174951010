export enum LOG_TYPE {
	CHANGE_SCREEN = "CHANGE_SCREEN",
	UNHANDLED_EXCEPTION = "UNHANDLED_EXCEPTION",
	VIDEO_OPEN = "STREAM_OPEN",
	VIDEO_CLOSE = "STREAM_CLOSE",
	INCIDENT_LOGIN = "INCIDENT_LOGIN",
	CLOSED_INCIDENT_LOGIN = "CLOSED_INCIDENT_LOGIN",
	APP_LOGIN = "APP_LOGIN",
	CHANGE_RECV = "CHANGE_RECEIVED",
	REQUEST_ERROR = "REQUEST_ERROR"
}
