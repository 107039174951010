export interface Hardware {
	id: number;
	station: string;
	name: string;
	description: string;
	type: HARDWARE_TYPE;
}

export enum HARDWARE_TYPE {
	CAMERA,
	GPS
}