<div class="nav-tab" appSetChildrenMaxWidth>
	<div
		data-cy="nav-tab"
		[attr.data-add-action-name]="'nav-tab-' + tab.index"
		*ngFor="let tab of tabs"
		class="tab {{ getActiveTab() === tab.index ? 'active' : '' }}"
		(click)="onClickOption(tab)"
	>
		<a>{{ tab.text }}</a>
		<div
			[id]="tab.index"
			class="line {{ getActiveTab() === tab.index ? 'active' : '' }}"
			[class.filled]="isFilled(tab.index)"
		></div>
	</div>
</div>
