import { Component, Input } from "@angular/core";
import { FsgBuilding } from "src/app/dto/fsg/fsg-building";
import { Incident } from "src/app/dto/items/incident";
import { LocaleMap } from "src/app/global/constants/text/text-interface";
import { TextProvider } from "src/app/global/constants/text/text-provider";

@Component({
	selector: "app-fsg-header",
	templateUrl: "./fsg-header.component.html",
	styleUrls: ["../../../document.css"]
})
export class FsgHeaderComponent {
	@Input() event?: Incident;
	@Input() fsg?: FsgBuilding;

	public readonly text: () => LocaleMap;

	constructor(tp: TextProvider) {
		this.text = tp.getStringMap;
	}

	public getFireOrigin(): string {
		if (this.fsg) {
			this.fsg.fire_floor = this.fsg.fire_floor ?? -1;
			return this.fsg.fire_floor !== -1 && this.fsg.fire_flat_name ? `${this.text().FSG_FLOOR} ${this.fsg.fire_floor}. ${this.text().FSG_FLAT} ${this.fsg.fire_flat_name}` : "-";
		}
		return "-";
	}
}
