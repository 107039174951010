<div class="main-black-mask" (click)="onCancel(); $event.stopPropagation()"></div>
<div class="alert-dialog">
	<div class="dialog-header">
		{{ text().ADD_CANVAS_IMAGE }}
	</div>
	<app-white-cross class="dialog-cross-button" (clickCb)="onCancel()"></app-white-cross>

	<div class="dialog-single-button">
		<app-upload-drag-zone (fileUploaded)="onFileUploaded($event)"></app-upload-drag-zone>
	</div>
</div>
