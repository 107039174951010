<div *ngIf="hasNavigation" class="space-around">
	<div class="nav-tab black tabs-container">
		<div
			[attr.data-add-action-name]="'nav-tab-' + tab.index"
			*ngFor="let tab of tabs"
			class="black {{ getActiveTab() === tab.index ? 'active' : '' }} {{
				tab.text === '+' ? 'add-tab' : ''
			}}"
			(click)="onClickOption(tab, $event)"
		>
			{{ tab.text }}
		</div>
	</div>
	<div class="arrows-container">
		<div
			class="forward  {{ !areThereMoreTabsFw ? 'empty' : 'arrow-on' }}"
			(click)="areThereMoreTabsFw && incrementTabIndex()"
		></div>
		<div
			class="backward  {{ !areThereMoreTabsBw ? 'empty' : 'arrow-on' }}"
			(click)="areThereMoreTabsBw && decrementTabIndex()"
		></div>
	</div>
</div>
<div *ngIf="!hasNavigation" appSetChildrenMaxWidth class="nav-tab black">
	<div
		[attr.data-add-action-name]="'nav-tab-' + tab.index"
		*ngFor="let tab of tabs"
		class="black {{ getActiveTab() === tab.index ? 'active' : '' }} {{
			tab.disabled ? 'disabled' : ''
		}}"
		(click)="onClickOption(tab, $event)"
	>
		{{ tab.text }}
	</div>
</div>
<div class="nav-divider" [class.schematic]="hasNavigation"></div>
