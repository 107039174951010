import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
	selector: "app-header-chip",
	templateUrl: "./header-chip.component.html",
	styleUrls: ["./header-chip.component.css"]
})
export class HeaderChipComponent {
	@Input() wide = false;

	@Output() clickEvent = new EventEmitter();

	public clicked = false;

	onClick(): void {
		this.clicked = !this.clicked;
		this.clickEvent.emit();
	}

	close(): void {
		this.clicked = false;
	}

}
