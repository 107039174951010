export enum FORM_SCREENS {
	MAIN = "JESIP_MAIN",
	METHANE = "JESIP_METHANE",
	IIMARCH = "JESIP_IIMARCH",
	JDM = "JESIP_JDM",
	DEBRIEF = "JESIP_DEBRIEF",
	CHECKLIST = "JESIP_CHECKLIST",
	FSG = "JESIP_FSG",
	DECISION = "JESIP_DECISION"
}
