import { DTO } from "../net/dto";

export class ResourceState implements DTO {
	id: number;
	id_state: number;
	name: string;
	color: string;
	code: string;
	is_initial: boolean;

	timestamp = Date.now();
	constructor(id: number, name: string, color?: string, code?: string, is_initial?: boolean) {
		this.id = this.id_state = id;
		this.name = name;
		this.color = color ? color : "#000000";
		this.code = code ? code : name.substring(4).toUpperCase();
		this.is_initial = is_initial ? is_initial : false;
	}

	public static readonly fromJson: (json: string) => ResourceState = (json: string) => {
		var jc = JSON.parse(json);
		return new ResourceState(jc.id_state ? jc.id_state : jc.id, jc.name, jc.color, jc.code, jc.is_initial);
	};
	public readonly getJson: Function = () => {
		this.id_state = this.id;
		return JSON.stringify(this, ["id_state", "name", "color", "code", "is_initial"]);
	};
}
