<div class="main-black-mask" (click)="close(); $event.stopPropagation()"></div>
<div *ngIf="currentFsg" class="dialog-container">
	<app-white-cross class="dialog-cross-button" (clickCb)="close()"></app-white-cross>
	<div class="dialog-header">
		{{ text().EF_PREFERENCES }}
	</div>
	<app-simple-text-field
		[data]="currentFsg.name"
		(dataChange)="currentFsg.name = $event"
		[withBorders]="true"
		[label]="text().NAME"
		[placeholderText]="text().NAME"
	></app-simple-text-field>

	<app-naming-convention [fsg]="currentFsg"></app-naming-convention>

	<div class="row-bottom">
		<button
			data-add-action-name="confirm-dialog-cancel"
			class="main-blue-gradient-border-button margin-right-12px"
			(click)="close()"
		>
			{{ text().CANCEL }}
		</button>
		<button
			data-add-action-name="confirm-dialog-confirm"
			class="main-blue-gradient-button"
			(click)="confirm()"
		>
			{{ text().SAVE }}
		</button>
	</div>
</div>
