import { Resource } from "src/app/dto/resources/resource";
import { User } from "src/app/dto/user/user";
import { Personnel } from "./personnel";
import { ClosedPersonnelRelation } from "./appliance-relation";

export class Appliance extends Resource {
	personnel: Array<Personnel>;
	closed_personnel: Array<ClosedPersonnelRelation>;
	oic: Personnel | undefined;
	closed_oics: Array<ClosedOic>; // can have one per each incident it has been in

	constructor(
		id: number,
		id_mission: number,
		latitude: number,
		longitude: number,
		id_camera: number,
		type: number,
		name: string,
		description?: string,
		telemetry_id?: number,
		id_gps_device?: number,
		deleted?: boolean,
		timestamp?: number,
		fixed_position?: boolean,
		public_id?: string,
		id_state?: number,
		color?: string,
		icon_path?: string,
		icon_width?: number,
		icon_height?: number,
		track_color?: string,
		station?: string,
		id_user?: number,
		user?: User
	) {
		super(id, id_mission, latitude, longitude, id_camera, type, name, description, telemetry_id, id_gps_device, deleted, timestamp, fixed_position, public_id, id_state, color, icon_path, icon_width, icon_height, track_color, station, true);
		this.personnel = [];
		this.closed_personnel = [];
		this.closed_oics = [];
	}
}

interface ClosedOic {
	object : Personnel;
	id_incident : number;
}