import { Component, EventEmitter, Output } from "@angular/core";
import { FLAT_SECTOR, SECTOR_COLOURS } from "src/app/dto/fsg/enums";
import { FsgService } from "src/app/incident/incident-tools/fsg/fsg.service";
import { LocaleMap } from "src/app/global/constants/text/text-interface";
import { TextProvider } from "src/app/global/constants/text/text-provider";

@Component({
	selector: "app-sector-select-popup",
	templateUrl: "sector-select.component.html",
	styleUrls: ["sector-select.css"]
})
export class FsgSectorSelectPopupComponent {
	@Output() selectEmit = new EventEmitter<FLAT_SECTOR>();
	@Output() cancel = new EventEmitter<void>();

	public readonly text: () => LocaleMap;
	public readonly sectors: Array<number>;
	public readonly sector_colours = SECTOR_COLOURS;

	private readonly fsg: FsgService;

	constructor(tp: TextProvider, fsgs: FsgService) {
		this.text = tp.getStringMap;
		this.fsg = fsgs;
		this.sectors = [0, 1, 2, 3];
	}

	public readonly getSectorValueAsLocalizedString: (sect: number) => string = (sect) => {
		return this.fsg.getSectorValueAsLocalizedString(sect);
	};

	public readonly getSectorColour: (sect: number) => string = (sect) => {
		switch (sect) {
			case FLAT_SECTOR.FIRE:
				return SECTOR_COLOURS.FIRE;
			case FLAT_SECTOR.LOBBY:
				return SECTOR_COLOURS.LOBBY;
			case FLAT_SECTOR.SEARCH:
				return SECTOR_COLOURS.SEARCH;
			case FLAT_SECTOR.BRIDGE:
				return SECTOR_COLOURS.BRIDGE;
			default:
				return "#000000";
		}
	};

	public readonly selectSector: (sect: number) => void = (sect) => {
		this.selectEmit.emit(sect);
	};
}
