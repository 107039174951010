import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { User } from "src/app/dto/user/user";

@Component({
	selector: "app-blue-dropdown",
	templateUrl: "blue-dropdown.component.html",
	styleUrls: ["blue-dropdown.css", "../dropdown.css"]
})
export class BlueDropdownComponent {
	// object is the data object
	@Input() object: any;

	// options the array of items that will show on the dropdown menu
	@Input() options: Array<any> = [];

	@Input() placeholderText: string = "";

	@ViewChild("blueDrop") blueDropdownElem!: ElementRef;
	@ViewChild("blueMenu") blueMenuElem!: ElementRef;

	/**
	 * getObjectText returns the text value that'll be written on the top of the dropdown
	 */
	@Input() getObjectText: (selected: any) => string = (option) => {
		return option ? (option.name ? option.name : option) : "";
	};

	/**
	 * compareSelect returns whether an option is the selected one
	 */
	@Input() compareSelect: (selected: any, option: any) => boolean = (selected, option) => {
		return selected === option;
	};

	/**
	 * getOptionText returns the text value that'll be written on each item
	 */
	@Input() getOptionText: (option: any) => string = (option) => {
		return option ? (option.name ? option.name : option) : "";
	};

	@Input() getOptionColor: (option: any) => string = (option) => {
		return "";
	};

	@Input() isOptionDisabled: (option: any) => boolean = (option) => {
		return false;
	}


	@Input() scrollInitialPercentage: number = 0; // 0 to 1

	// special option that has a separator (a gray line), it interacts the same as any other
	@Input() separateLastOption: any | undefined;

	@Input() canSearch: boolean = false;

	@Input() searchAttributeName: string = 'name';

	@Input() withBorderRadius: boolean = false;

	@Input() smallFont: boolean = false;

	@Input() disabled: boolean = false;

	@Input() addString: string = "";

	// for it to be multiselect it really depends on the parent setting the "compareSelect" and "itemClickCallback" functions properly
	// what this attribute does is render checkboxes inside each item and disable the automatic closeup of the dropdown when selecting
	@Input() isMultiselect: boolean = false;
	/**
	 * itemClickCallback is executed whenever the user clicks on an item, most likely to select it
	 */
	@Output() itemClickCallback = new EventEmitter<any>();
	@Output() newItemEvent = new EventEmitter<string>();
	@Output() newItemKeydownEvent = new EventEmitter<string>();

	public filterQuery: string = '';

	public openMenu: boolean = false;
	public showMenuAtTop: boolean = false;
	public heightOffset = 0;
	
	makingNewItem: boolean = false;
	newItem: string | null = "";

	public readonly isUserDropdown = (): boolean => {
		return this.object instanceof User;
	};

	public readonly onOpenMenu = (): void => {
		this.filterQuery = '';
		this.openMenu = !this.openMenu;
	}

	public readonly isFilterActive = (): boolean => {
		return this.canSearch && this.openMenu;
	}

	public readonly filterFunc: (data: any) => boolean = (data: any) => {
		if (this.filterQuery && this.filterQuery.length > 0) {
			for (var i = 0; i < data[this.searchAttributeName].length; i++) {
				if (this.filterQuery.toUpperCase() === data[this.searchAttributeName].toUpperCase().substring(i, i + this.filterQuery.length)) return true;
			}
			return false;
		}
		return true;
	};

	public readonly getInputPlaceholderText = (): string => {
		return this.object && this.getObjectText(this.object) ? this.getObjectText(this.object) : this.placeholderText;
	}

	public readonly hasSearchResults = (): boolean => {
		return this.options.filter(this.filterFunc).length > 0;
	}

	openNewItem: (evt: any) => void = (evt) => {
		if (this.addString) {
			this.makingNewItem = true;
			const scrollObj = evt.target.parentNode.parentNode.parentNode.firstChild;
			scrollObj.scrollTop = 0;
			document.addEventListener("click", this.closeAllMenus);
		}
	};

	getCheckFunction: (item : any) => Function = (item) => {
		return () => { return this.compareSelect(this.object, item) };
	}

	
	closeNewItem(): void {
		this.newItem = "";
		this.makingNewItem = false;
	}

	
	closeAllMenus: () => void = () => {
		this.openMenu = false;
		this.makingNewItem = false;
		this.newItem = "";
		document.removeEventListener("click", this.closeAllMenus);
	};

	newItemKeydownController: (evt: any, type: string) => void = (evt, type) => {
		if (evt.type !== "keydown") return;
		switch (evt.key) {
			case "Enter":
				this.newItemKeydownEvent.emit(evt.target.value);
				evt.stopPropagation();
				this.makingNewItem = false;
				const scrollObj = evt.target.parentNode.parentNode;
				scrollObj.scrollTop = 0;
				this.newItem = null;
				break;
			case "Escape":
				this.stopCreatingNewValue();
				break;
			default:
				return;
		}
	};

	
	stopCreatingNewValue: () => void = () => {
		this.makingNewItem = false;
		this.newItem = null;
	};

	onItemClick: (evt: Event, option: any) => void = (evt, option) => {
		this.itemClickCallback.emit(option); 
		if(!this.isMultiselect) this.openMenu = false;
	}

	onClickOut: () => void = () => {
		this.openMenu = false
	}
}
