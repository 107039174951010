import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { Listener } from "./listener";
import { MessageEvent } from "./message-event";
import { MESSAGE_TYPE } from "./messages";

@Injectable({
	providedIn: "root"
})
export class MessagingService {
	public listeners: Array<Listener> = [];
	public triggered: Array<MessageEvent> = [];
	public readonly settingsUpdate$ = new Subject<{ screen: "poi" | "area" | "incident"; id: number }>();

	public readonly registerListener: (eventName: MESSAGE_TYPE, callback: Function, retroactive?: boolean, onlyonce?: boolean) => void = (eventName, callback, retroactive, onlyonce) => {
		var idx = this.listeners.findIndex((e) => e.eventName === eventName && e.callback === callback);
		if (idx === -1) {
			let list: Listener = new Listener(eventName, callback, retroactive, onlyonce);
			this.listeners.push(list);
			if (retroactive) {
				let i: number = this.triggered.findIndex((e) => e.name === eventName);
				if (i > -1) {
					list.fire(this.triggered[i].params);
				}
			}
		} else {
			this.listeners[idx].retroactive = retroactive ? true : false;
			this.listeners[idx].onlyonce = onlyonce ? true : false;
		}
	};

	public readonly destroyCallbacks: Function = () => {
		this.listeners = [];
	};

	public readonly fire: (eventName: MESSAGE_TYPE, parameter?: any, dontSave?: boolean) => void = (eventName, parameter, dontSave) => {
		for (let i = 0; i < this.listeners.length; i++) {
			if (this.listeners[i].eventName === eventName) {
				this.listeners[i].fire(parameter);
				if (this.listeners[i].onlyonce) this.listeners.splice(i, 1);
			}
		}
		if (!this.triggered.find((e) => e.name === eventName) && !dontSave) this.triggered.push(new MessageEvent(eventName, parameter));
	};

	public readonly removeListener: (eventName: MESSAGE_TYPE, callback: Function) => void = (name, callback) => {
		var idx = this.listeners.findIndex((e) => e.eventName === name && e.callback === callback);
		if (idx > -1) this.listeners.splice(idx, 1);
	};
}
