<div class="main-black-mask" (click)="closeModalEmit.emit()"></div>
<div [ngClass]="'main-container'">
	<div [ngClass]="'top'">
		<div [ngClass]="'top-left'">
			<div
				[ngClass]="[
					'clickable-item',
					filter.sortDirection === 'asc' ? 'clickable-item-active' : ''
				]"
				(click)="setSortDirection('asc'); $event.stopPropagation()"
			>
				{{ text().SORT }} A {{ text().TO_A }} Z
			</div>
			<div
				[ngClass]="[
					'clickable-item',
					filter.sortDirection === 'desc' ? 'clickable-item-active' : ''
				]"
				(click)="setSortDirection('desc'); $event.stopPropagation()"
			>
				{{ text().SORT }} Z {{ text().TO_A }} A
			</div>
		</div>
		<div [ngClass]="['cancel-button']">
			<button
				[ngClass]="'main-cross-button delete white'"
				(click)="closeModal(); $event.stopPropagation()"
			>
				<span></span><span></span>
			</button>
		</div>
	</div>

	<div [ngClass]="'center'">
		<div>{{ text().FILTER_BY + " " + text().TEXT }}:</div>
		<div>
			<span [ngClass]="'clickable-item'" (click)="selectAll(); $event.stopPropagation()"
				>{{ text().SELECT_ALL }}
			</span>
			<span>- </span>
			<span [ngClass]="'clickable-item'" (click)="clearAll(); $event.stopPropagation()">{{
				text().CLEAR
			}}</span>
		</div>
	</div>

	<input type="text" [(ngModel)]="query" />

	<div [ngClass]="'scroll-box'">
		<div
			*ngFor="let eventType of incidentTypes | filter: filterFunc"
			[ngClass]="'event-container'"
		>
			<app-blue-checkbox
				[ngClass]="'check-box'"
				[check]="eventType.isTypeChecked"
				(clickCb)="addValueToFilter(eventType.name)"
			></app-blue-checkbox>
			<div
				[ngClass]="'event-color'"
				[ngStyle]="{ 'background-color': eventType.color }"
			></div>
			<div [ngClass]="'event-text'">{{ eventType.name }}</div>
		</div>
	</div>

	<div [ngClass]="'bottom'">
		<span
			[ngClass]="[
				'clickable-item',
				filters.sortDirection || filter.filterValues ? 'clickable-item-active' : ''
			]"
			(click)="applyFilter(filter); $event.stopPropagation()"
			>{{ text().APPLY }}</span
		>
	</div>
</div>
