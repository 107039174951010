import { Injectable } from "@angular/core";
import { WebRequestFactory } from "../http/web.request.factory";
import { ConfigurationService } from "../settings/types/configuration.service";
import { DTOArray } from "../dto/net/dto-array";
import { Incident } from "../dto/items/incident";
import { MessagingService } from "../global/messaging/messaging.service";
import { MESSAGE_TYPE } from "../global/messaging/messages";
import { Subject } from "rxjs";
import { MAP_PROFILES } from "../global/constants/enums/map-profiles";
import { LOG_TYPE } from "../global/constants/enums/log_types";
import { IncidentType } from "../dto/items/types/event-type";

@Injectable({
	providedIn: "root"
})
export class IncidentService {
	public readonly loadMission$ = new Subject<Incident>();

	public currentProfile: MAP_PROFILES = MAP_PROFILES.OFV;
	public isBuffered?: boolean;
	/**
	 * Array of all incidents
	 * @property Incidents
	 * @type Incident[]
	 */
	public Incidents: Array<Incident> = new Array<Incident>();

	public $incidentChange = new Subject<Incident>();

	private currentIncident: Incident | undefined;
	private readonly wreq: WebRequestFactory;
	private readonly mssg: MessagingService;
	private readonly conf: ConfigurationService;

	constructor(wreq: WebRequestFactory, mssg: MessagingService, conf: ConfigurationService) {
		this.wreq = wreq;
		this.mssg = mssg;
		this.conf = conf;

		mssg.registerListener(MESSAGE_TYPE.PLAY_MODE_CHANGE, (params: { liveMode: boolean; buffered: boolean }) => {
			this.currentProfile = params.liveMode ? MAP_PROFILES.OFV : MAP_PROFILES.HISTORIC;
			this.isBuffered = params.buffered;
		});
	}

	public isLive(): boolean {
		return this.currentProfile === MAP_PROFILES.OFV;
	};

	public getCurrentIncident(): Incident | undefined {
		return this.currentIncident;
	};

	// if lazyLoad is not set, the front-end will send several requests to fetch mission-related data
	public async setCurrentIncident(mission: Incident, lazyLoad?: boolean): Promise<boolean> {
		if (mission && (!this.currentIncident || mission.id !== this.currentIncident.id)) {
			this.currentIncident = mission;
			await this.conf.setCurrentmissionId(mission.id);
			this.wreq.logInformation(mission.closed ? LOG_TYPE.CLOSED_INCIDENT_LOGIN : LOG_TYPE.INCIDENT_LOGIN, mission.id.toString());
			if (!lazyLoad) {
				this.loadMission$.next(mission);
				this.mssg.fire(MESSAGE_TYPE.TRY_UPDATE);
			}
			return true;
		} else return false;
	};

	public isIncidentSet(): boolean {
		return this.currentIncident != null;
	};

	public async load(success: any): Promise<boolean> {
		const result = await this.updateIncidents(success);
		this.mssg.fire(MESSAGE_TYPE.LOAD_EVENTS_MISSION);
		return result;
	};

	public unload(): void {
		this.unsetCurrentIncident();
		this.Incidents.length = 0;
	};
	
	public async closeCurrentIncident(): Promise<boolean> {
		if (this.currentIncident) {
			this.currentIncident.closed = true;
			this.currentIncident.end_time = new Date().toISOString();
			this.currentIncident.end_time_ms = Date.now();
			this.currentIncident.closed = true;
			/* 			var ev = this.Events.find((e) => e.mission && this.currentMission && e.mission.id == this.currentMission.id);
			if (ev) ev.mission = this.currentMission; */
			await this.saveIncident(this.currentIncident);
			//this.mssg.fire(MESSAGE_TYPE.CLOSE_CURR_MISSION, this.currentMission ? this.currentMission.id : null);
			this.exitCurrentIncident();
			return true;
		}
		return true;
	};

	public async reopenCurrentIncident(): Promise<boolean> {
		if (this.currentIncident) {
			this.currentIncident.closed = false;
			this.currentIncident.end_time = "";
			this.currentIncident.end_time_ms = 0;
			await this.saveIncident(this.currentIncident);
			this.mssg.fire(MESSAGE_TYPE.OPEN_CURR_MISSION, this.currentIncident ? this.currentIncident.id : null);
			this.exitCurrentIncident();
			return true;
		}
		return true;
	};

	public exitCurrentIncident(): void {
		this.unsetCurrentIncident();
	};

	/**
	 * Sends request to the webservive to save an Mission to the server. Returns the Mission as it was saved
	 * (if it's not able to save some part of it , it returns the area as exists on the server  )
	 * @method saveIncident
	 * @param {Incident} mission The mission to be saved.
	 * @return {boolean}
	 */
	public async saveIncident(inc: Incident): Promise<Incident | undefined> {
		const incJson = await this.wreq.saveIncident(inc);
		if (incJson) {
			const incident = Incident.fromJson(incJson);
			DTOArray.AddFromJson(this.Incidents, incJson, Incident, null);
			this.$incidentChange.next(incident);
			return incident;
		} else return;
	};

	
	/**
	 * Sends request to server to delete an event.
	 * @method deleteEvent
	 * @param {Event} event The event to delete
	 * @return {Boolean} True if it was deleted, false otherwise.
	 */
	public readonly deleteIncident: Function = async (event: Incident): Promise<boolean> => {
		if (!this.isLive()) return false;
		const success = await this.wreq.deleteIncident(event.id);
		if (success) {
			DTOArray.DeleteById(this.Incidents, event.id);
			this.$incidentChange.next(event);
			return true;
		}

		return false;
	};


	private unsetCurrentIncident(): void {
		this.currentIncident = undefined;
		this.conf.setCurrentmissionId(-1);
		this.mssg.fire(MESSAGE_TYPE.UNLOAD_MISSION);
	};

	/**
	 * Requests all the Missions data to the server.
	 * @method updateIncidents
	 * @return {Mission[]}
	 */
	private async updateIncidents(success: any): Promise<boolean> {
		if (!success) return Promise.resolve(success);
		const jsonArray = await this.wreq.getAllMissions();
		if (!jsonArray) return false;
		DTOArray.UpdateFromJsonArray(this.Incidents, jsonArray, Incident);
		this.Incidents.forEach((value) => {
			value.duration = value.end_time && value.start_time ? value.end_time_ms - value.start_time_ms : -1;
			value.__typeObj = this.conf.configuration.incidentTypes.find((e) => e.id === value.id_type);
			if(!value.__typeObj) value.__typeObj = new IncidentType(-1, '', '#FFF');
		});
		this.Incidents.sort((a, b) => {
			return a.name > b.name ? 1 : 0;
		});
		//If current mission is not set but selected, assign and fire mission loaded
		//This happens when, at start, the current mission selection is retreived from user configuration,
		//but missions array is not yet retreived from server, so after we update it, notify that it has been loaded
		if (this.conf.configuration.id_current_incident > -1) {
			const mission = this.Incidents.find((e) => e.id === this.conf.configuration.id_current_incident);
			if (this.currentIncident == null && mission) return this.setCurrentIncident(mission);
		}
		return true;
	};

}
