import { MESSAGE_TYPE } from "src/app/global/messaging/messages";

export class Listener {
	eventName: MESSAGE_TYPE;
	callback: Function;
	retroactive: boolean;
	onlyonce: boolean;

	called: boolean = false;

	public constructor(name: MESSAGE_TYPE, callback: Function, retroactive: boolean = true, onlyonce: boolean = false) {
		this.eventName = name;
		this.callback = callback;
		this.retroactive = retroactive;
		this.onlyonce = onlyonce;
	}

	public readonly fire: Function = (params: Object) => {
		this.callback(params);
		this.called = true;
	};
}
