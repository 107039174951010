import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, Output, EventEmitter, OnChanges, SimpleChanges, ChangeDetectionStrategy } from "@angular/core";
import { Subscription } from "rxjs";
import { Incident } from "src/app/dto/items/incident";
import { ResourceType } from "src/app/dto/items/types/resource-type";
import { Personnel } from "src/app/dto/resources/personnel";
import { Resource } from "src/app/dto/resources/resource";
import { Station } from "src/app/dto/resources/station";
import { Level } from "src/app/dto/user/level";
import { User } from "src/app/dto/user/user";
import { IncidentService } from "src/app/incident/incident.service";
import { LocaleMap } from "src/app/global/constants/text/text-interface";
import { TextProvider } from "src/app/global/constants/text/text-provider";
import { ResourceService } from "src/app/settings/resource/resource.service";
import { ConfigurationService } from "src/app/settings/types/configuration.service";
import { UserService } from "src/app/settings/user/user.service";
import { SkillUserRelation } from "src/app/dto/user/skill-user-relation";
import { CloneFactory } from "src/app/dto/net/clone-factory";
import { MessagingService } from "src/app/global/messaging/messaging.service";
import { MESSAGE_TYPE } from "src/app/global/messaging/messages";

@Component({
	selector: "app-personnel-card",
	templateUrl: "personnel-card.component.html",
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ["resource-card.css"]
})
// If you modify any content of this component, please make sure this change is not required in the component PersonnelCardSsoComponent
// otherwise kindly make the changes on both
export class PersonnelCardComponent implements OnInit, OnDestroy {
	@Input() personnel: Personnel = new Personnel(-1, -1, 0, 0, -1, -1, "");

	@Input() canDelete: boolean = false;

	@Output() saveCb = new EventEmitter<Personnel>();

	@Output() deleteCb = new EventEmitter<Personnel>();

	@Output() assignHardwareCb = new EventEmitter<Personnel>();

	@Output() changePasswordCb = new EventEmitter<User>();

	@Output() addNewTypeCb = new EventEmitter<Resource>();

	@Output() editing = new EventEmitter<boolean>();

	public text: () => LocaleMap;
	public resourceTypes: Array<ResourceType> = [];
	public incidents: Array<Incident> = [];
	public stations: Array<Station> = [];
	public levels: Array<Level> = [];
	public open: boolean = false;
	public stationPlaceholder: string = "Station";
	public changed: boolean = false;
	public showSkillModal: boolean = false;
	public user: User = new User();
	public areOverflowSkillsVisible: boolean = false;

	private readonly ems: IncidentService;
	private readonly res: ResourceService;
	private readonly conf: ConfigurationService;
	private readonly userService: UserService;
	private readonly cdRef: ChangeDetectorRef;
	private readonly mssg: MessagingService;
	private resourceTypeDeletedSub: Subscription;
	private resourceTypeCreatedSub: Subscription;

	constructor(userService: UserService, conf: ConfigurationService, res: ResourceService, ems: IncidentService, textProv: TextProvider, cdRef: ChangeDetectorRef, mssg: MessagingService) {
		this.text = textProv.getStringMap;
		this.ems = ems;
		this.res = res;
		this.conf = conf;
		this.userService = userService;
		this.cdRef = cdRef;
		this.mssg = mssg;

		this.resourceTypeDeletedSub = this.conf.resourceTypeDeleted$.subscribe(this.refreshResourceTypes as any);
		this.resourceTypeCreatedSub = this.conf.resourceTypeCreated$.subscribe(this.refreshResourceTypes as any);
	}

	ngOnInit() {
		this.user = this.personnel.__user as User;
		this.open = this.personnel.id === -1 ? true : false;

		this.refreshResourceTypes();

		this.incidents.push(new Incident(-1, -1, this.text().NONE));
		this.incidents.push(...this.ems.Incidents.filter((incident) => !incident.closed));

		this.stations.push(new Station(-1, this.text().UNSPECIFIED));
		this.stations.push(...this.res.Stations);

		if (!this.personnel.__typeObj) this.personnel.__typeObj = this.resourceTypes.find((e) => e.id === this.personnel.type);

		this.save = () => {
			this.saveCb.emit(this.personnel);
		};

		this.delete = () => {
			this.deleteCb.emit(this.personnel);
		};

		this.levels = this.userService.Levels;

		this.mssg.registerListener(MESSAGE_TYPE.UPDATE_AGENT, this.updateAgent);
	}

	ngOnDestroy(): void {
		this.resourceTypeDeletedSub.unsubscribe();
		this.resourceTypeCreatedSub.unsubscribe();
	}

	save: Function = () => {};
	delete: Function = () => {};

	public openFullProfile(): void {
		this.open = !this.open;
		if (this.open) this.areOverflowSkillsVisible = false;
	}

	public typeDropdownGetMainText: (selected: any) => string = () => {
		const type = this.resourceTypes.find((e) => e.id === this.personnel.type);
		return type ? type.name : "";
	};
	public incidentDropdownGetMainText: (selected: any) => string = () => {
		const incident = this.ems.Incidents.find((e) => e.id === this.personnel.id_incident);
		return incident ? incident.name : "";
	};
	public stationDropdownGetMainText: (selected: any) => string = () => {
		const station = this.stations.find((e) => e.name === this.personnel.station);
		return station ? station.name : "";
	};
	public roleDropdownGetMainText: (selected: any) => string = () => {
		if (!(this.personnel.__user as User).__level) (this.personnel.__user as User).__level = this.userService.Levels.find((e) => e.id == this.personnel.__user?.id_level);
		return (this.personnel.__user as User).__level ? (this.personnel.__user as User).__level!.name : "";
	};

	public typeDropdownGetOptionText: (option: any) => string = (type: ResourceType) => {
		return type.name;
	};
	public incidentDropdownGetOptionText: (option: any) => string = (incident: Incident) => {
		return incident.name;
	};
	public stationDropdownGetOptionText: (option: any) => string = (station: Station) => {
		return station.name;
	};
	public roleDropdownGetOptionText: (option: any) => string = (option: Level) => {
		return option.name;
	};

	public typeDropdownCompareSelect: (selected: any, option: any) => boolean = (obj: Resource, type: ResourceType) => {
		return obj.type === type.id;
	};
	public incidentDropdownCompareSelect: (selected: any, option: any) => boolean = (obj: Resource, incident: Incident) => {
		return obj.id_incident === incident.id;
	};
	public stationDropdownCompareSelect: (selected: any, option: any) => boolean = (obj: Resource, station: Station) => {
		return obj.station === station.name;
	};
	public roleDropdownCompareSelect: (selected: any, option: any) => boolean = (obj: User, role: Level) => {
		return (this.personnel.__user as User).id_level === role.id;
	};

	public typeDropdownChangeCallback: Function = ( type: ResourceType) => {
		if (type.id < 0) {
			this.addNewTypeCb.emit(this.personnel);
		} else {
			this.personnel.type = type.id;
			this.personnel.__typeObj = type;
		}
	};
	public incidentDropdownChangeCallback: Function = (incident: Incident) => {
		this.personnel.id_incident = incident.id;
	};

	public stationDropdownChangeCallback: Function = (station: Station) => {
		this.personnel.station = station.name;
	};

	public roleDropdownChangeCallback: Function = (role: Level) => {
		(this.personnel.__user as User).id_level = role.id;
		(this.personnel.__user as User).__level = role;
	};

	public readonly isNewResource = (): boolean => {
		return this.personnel.id === -1 || this.personnel.__user?.id === -1;
	};

	public hasResourceBeenEdited = (): boolean => {
		const originalResource = this.res.Resources.find((e) => e.id === this.personnel.id);
		if (!originalResource) return true;
		const originalUser = this.userService.Users.find((e) => e.id_resource === this.personnel.id);
		if (!originalUser && (this.personnel.__user as User)) return true;
		else {
			if (!(this.personnel.__user as User))
				return (
					originalResource.name.toUpperCase() !== this.personnel.name.toUpperCase() ||
					originalResource.type !== this.personnel.type ||
					originalResource.color !== this.personnel.color ||
					originalResource.fixed_position !== this.personnel.fixed_position ||
					originalResource.station !== this.personnel.station ||
					originalResource.id_incident !== this.personnel.id_incident ||
					originalResource.description !== this.personnel.description ||
					originalResource.icon_path !== this.personnel.icon_path
				);
			else {
				originalResource.name.toUpperCase() === this.personnel.name.toUpperCase() && this.editing.emit(false);
				return (
					originalResource.name.toUpperCase() !== this.personnel.name.toUpperCase() ||
					originalResource.type !== this.personnel.type ||
					originalResource.color !== this.personnel.color ||
					originalResource.fixed_position !== this.personnel.fixed_position ||
					originalResource.station !== this.personnel.station ||
					originalResource.id_incident !== this.personnel.id_incident ||
					originalResource.description !== this.personnel.description ||
					originalResource.icon_path !== this.personnel.icon_path ||
					(this.personnel.__user as User).id_level !== originalUser!.id_level ||
					originalUser!.name !== (this.personnel.__user as User).name
				);
			}
		}
	};

	public editingPersonnel(): void {
		this.editing.emit(true);
	}

	public checkChanges: (check_string: string) => void = (check_string: string) => {
		if (check_string != (this.personnel.__user as User).name) {
			(this.personnel.__user as User).name = check_string;
		}
	};

	public readonly toggleGpsSwitch: Function = () => {
		this.areOverflowSkillsVisible = false;
		this.personnel.fixed_position = !this.personnel.fixed_position;
	};

	public readonly isResourceTracked: Function = () => {
		return !this.personnel.fixed_position;
	};

	public readonly closeCard: Function = (evt: Event) => {
		this.open = false;
		evt.stopPropagation();
		this.cdRef.detectChanges();
	};

	public readonly openSkillModal: Function = () => {
		this.areOverflowSkillsVisible = false;
		this.showSkillModal = true;
	};

	public readonly closeSkillModal: Function = () => {
		this.showSkillModal = false;
	};

	public addCamera(): void {
		this.areOverflowSkillsVisible = false;
		this.assignHardwareCb.emit(this.personnel);
	}

	public readonly setNewSkills = (skillAssig: Map<number, boolean>): void => {
		if (this.personnel.id !== -1) {
			for (let skill of this.res.Skills) {
				const idx = this.personnel.skills ? this.personnel.skills.findIndex((e) => e.id === skill.id) : -1;
				if (!skillAssig.get(skill.id)) {
					if (idx > -1) {
						this.res.removeSkillFromAgent(this.personnel.id, skill.id);
						this.personnel.skills?.splice(idx, 1);
					}
				} else {
					if (idx === -1) {
						this.res.addSkillToAgent(new SkillUserRelation(-1, skill.id, this.personnel.id));
						this.personnel.skills ? this.personnel.skills.push(skill) : (this.personnel.skills = [skill]);
					}
				}
			}
		} else {
			// if new user, add locally
			this.personnel.skills = [];
			for (let skill of this.res.Skills) {
				skillAssig.get(skill.id) && this.personnel.skills.push(skill);
			}
		}
		this.closeSkillModal();
		this.cdRef.detectChanges();
	};

	public toggleSkills(): void {
		this.areOverflowSkillsVisible = !this.areOverflowSkillsVisible;
	}

	private readonly refreshResourceTypes: Function = () => {
		this.resourceTypes = [...this.conf.configuration.resourceTypes].reverse().sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));
		this.resourceTypes.push(new ResourceType(-2, "+ " + this.text().ADD.toUpperCase()));
	};

	private readonly updateAgent: (agent: Resource) => void = (agent) => {
		if (agent.id !== this.personnel.id) return;
		let person = new Personnel(
			agent.id,
			agent.id_incident,
			agent.latitude,
			agent.longitude,
			agent.id_camera,
			agent.type,
			agent.name,
			agent.description,
			agent.telemetry_id,
			agent.id_gps_device,
			agent.deleted,
			agent.timestamp,
			agent.fixed_position,
			agent.public_id,
			agent.id_state,
			agent.color,
			agent.icon_path,
			agent.icon_width,
			agent.icon_height,
			agent.track_color,
			agent.station,
			(agent as Personnel).id_user,
			new User(
				(agent as Personnel).__user?.id,
				(agent as Personnel).__user?.name,
				(agent as Personnel).__user?.id_level,
				(agent as Personnel).__user?.role,
				(agent as Personnel).__user?.missions_permitted,
				(agent as Personnel).__user?.timestamp,
				(agent as Personnel).__user?.id_resource,
				(agent as Personnel).__user?.id_picture,
				(agent as Personnel).__user?.email,
				(agent as Personnel).__user?.real_name,
				(agent as Personnel).__user?.real_id,
				(agent as Personnel).__user?.birthday,
				(agent as Personnel).__user?.phonenumber,
				(agent as Personnel).__user?.token
			)
		);
		person.__typeObj = agent.__typeObj;
		person.skills = agent.skills;
		CloneFactory.cloneProperties(person.__user, (agent as Personnel).__user);
		CloneFactory.cloneProperties(this.personnel, person);
		this.cdRef.detectChanges();
	};
}
