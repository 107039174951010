import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ResourceService } from "src/app/settings/resource/resource.service";
import { Resource } from "src/app/dto/resources/resource";
import { ResourceSkill } from "src/app/dto/resources/skill";
import { SkillUserRelation } from "src/app/dto/user/skill-user-relation";
import { LocaleMap } from "src/app/global/constants/text/text-interface";
import { TextProvider } from "src/app/global/constants/text/text-provider";

@Component({
	selector: "app-assign-skill-modal",
	templateUrl: "assign-skill.component.html",
	styleUrls: ["assign-skill.css"]
})
export class AssignSkillModalComponent implements OnInit {
	@Input() resource!: Resource;
	@Output() closeCb = new EventEmitter();
	@Output() confirmCb = new EventEmitter<Map<number, boolean>>();

	public readonly text: () => LocaleMap;
	public readonly skillAssignation = new Map<number, boolean>();
	public Skills: ResourceSkill[] = [];

	private readonly resourceService: ResourceService;

	constructor(textProv: TextProvider, resourceService: ResourceService) {
		this.resourceService = resourceService;
		this.text = textProv.getStringMap;
	}

	ngOnInit(): void {
		if (!this.resource) throw "AssignSkillModalComponent initialized without a valid resource";
		this.setSkill();
	}

	public readonly closeCallback = (): void => {
		this.closeCb.emit();
	};

	/**
	 * @param skill_id the id of skill to check if it has been selected
	 * @returns boolean result of the skill value
	 */
	public readonly getSkill = (skill_id: number): boolean => {
		return !!this.skillAssignation.get(skill_id);
	};

	/**
	 * @param id_skill the id of skill to check if it has been selected
	 * @returns callback of type boolean
	 */
	public readonly getSkillAssignation = (id_skill: number): (() => boolean) => {
		return () => !!this.skillAssignation?.get(id_skill);
	};

	public readonly setSkillAssignation = (id_skill: number): void => {
		this.skillAssignation.set(id_skill, !this.skillAssignation.get(id_skill));
	};

	/**
	 * Commits the selected values to the database and closes the popup
	 */
	public readonly commitSkillAssignment = (): void => {
		this.confirmCb.emit(this.skillAssignation);
	};

	/**
	 * Function executed on Init, brings the list of all Skills from the service, adds an extra key value through Map (dictionary)
	 * for skills checkboxes and followup of additions or deletions of them and save them in a local array called Skills[]
	 */
	private readonly setSkill = (): void => {
		this.Skills = this.resourceService.Skills;
		for (let skill of this.Skills) {
			this.skillAssignation.set(skill.id, false);
			if (this.resource.skills?.includes(skill)) this.skillAssignation.set(skill.id, true);
		}
	};
}
