import { APP_BASE_HREF, CommonModule, PlatformLocation } from "@angular/common";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { DirectiveModule } from "src/app/widgets/directive/directive.module";
import { UIModule } from "src/app/widgets/ui/ui.module";
import { ExtendedMenuComponent } from "./left-nav-menu/extended-menu/extended-menu.component";
import { FixedBarComponent } from "./left-nav-menu/fixed-bar/fixed-bar.component";
import { JesipToolsComponent } from "./left-nav-menu/jesip-tools/jesip-tools.component";
import { LeftNavMenuComponent } from "./left-nav-menu/left-nav-menu.component";
import { MainNavigationComponent } from "./main-nav/main-navigation.component";
import { SideNavMenuComponent } from "../../widgets/ui/popups/side-nav-menu/side-nav-menu.component";

function getBaseHref(platformLocation: PlatformLocation): string {
	return platformLocation.getBaseHrefFromDOM();
}
@NgModule({
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
	declarations: [MainNavigationComponent, SideNavMenuComponent, LeftNavMenuComponent, FixedBarComponent, ExtendedMenuComponent, JesipToolsComponent],
	imports: [CommonModule, UIModule, DirectiveModule],
	exports: [MainNavigationComponent, LeftNavMenuComponent],
	providers: [
		{
			provide: APP_BASE_HREF,
			useFactory: getBaseHref,
			deps: [PlatformLocation]
		}
	]
})
export class NavigationModule {}
