import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";
import { SettingsParser } from "./app/global/constants/settings.parser";
import { URLMap } from "./app/global/constants/enums/url-map";

if (environment.production) {
	enableProdMode();
}

const loadFonts = (): void => {
	const settings = SettingsParser.getSettings();
	const hrefVal = settings ? settings.serveHref : "";
	const absolutePath = "/" + (hrefVal !== undefined ? hrefVal : "") + "resources/css/fonts/";
	const link = document.createElement("link");
	link.setAttribute("rel", "preload");
	link.setAttribute("as", "font");
	link.setAttribute("href", absolutePath + "Poppins-Light.woff");
	link.setAttribute("type", "font/woff");
	link.setAttribute("crossorigin", "anonymous");
	document.head.appendChild(link);

	const cssLink = document.createElement("link");
	cssLink.setAttribute("rel", "stylesheet");
	cssLink.setAttribute("href", absolutePath + (hrefVal === "front/" ? "font-faces-front.css" : "font-faces.css"));
	document.head.appendChild(cssLink);
};

	const logVersion = (): void => {
		const templ = SettingsParser.getTemplate();
		console.log("Using IRIS v" + (templ ? templ.version : "0.0"));
}

const setupSettings = (): void => {
	SettingsParser.TESTING = false;
	SettingsParser.loadSettings().then(() => {
		SettingsParser.loadTemplate().then(() => {
			onSettingsLoad();
			platformBrowserDynamic()
			.bootstrapModule(AppModule)
			.catch((err : any) => console.error(err));
		})
	})
}

const onSettingsLoad = (): void => {
		loadFonts();
		logVersion();
}


SettingsParser.settingsLoaded$.subscribe(onSettingsLoad);
setupSettings();


