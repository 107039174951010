<div class="document-header ara-header">
	<app-event-header [event]="event"></app-event-header>
	<div *ngIf="ara" class="document-subheader">
		<div class="subheader-section">
			<div class="item-title">{{ text().SECTOR }}:</div>
			<div>
				{{
					ara.__sector
						? ara.__sector.name
						: ara.id_ics_sector === -3
						? text().WHOLE_INCIDENT
						: text().UNSPECIFIED
				}}
			</div>
		</div>

		<div class="subheader-section">
			<div class="item-title">
				{{ ara.scenarios.length === 1 ? text().SCENARIO : text().SCENARIOS }}:
			</div>
			<div>{{ getScenarioString() }}</div>
		</div>

		<div class="subheader-section">
			<div class="item-title">{{ text().FILLED_BY }}:</div>
			<div>
				{{ ara.__fill_user ? (ara.__fill_user.name | titlecase) : text().UNSPECIFIED }}
			</div>
		</div>
	</div>
</div>
