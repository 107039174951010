<div
	class="container"
	appDetectClickOut
	[onlyOnce]="true"
	(callback)="close()"
	(click)="$event.stopPropagation()"
>
	<div class="states-container">
		<div class="scroll" [class.height-200]="resourceStates.length > 5">
			<div *ngFor="let state of resourceStates">
				<button
					class="state-button {{ selectedState(state) ? 'selected' : '' }}"
					(click)="selectState(state)"
				>
					<div class="state-dot" [style.background]="state.color"></div>
					<span>{{ state.name }}</span>
				</button>
			</div>
		</div>
	</div>
</div>
