<div class="main-black-mask" (click)="cancel()"></div>
<div class="edit-modal min-height-unset">
	<div class="title">
		{{ header.toUpperCase() }}
	</div>
	<app-white-cross class="dialog-cross-button" (clickCb)="cancel()"></app-white-cross>
	<div class="body">
		<div class="text-container">
			<app-simple-text-field
				[data]="item.name"
				[type]="'text'"
				(dataChange)="refreshName($event)"
				[placeholderText]="text().NAME"
				[label]="text().NAME"
			></app-simple-text-field>
		</div>
		<div class="text-container margin-bottom-20">
			<app-simple-text-box
				[data]="item.description"
				[type]="'text'"
				(dataChange)="refreshComments($event)"
				[placeholderText]="text().DESCRIPTION"
				[label]="text().DESCRIPTION"
				[labelBackground]="'#051c2a'"
				[maxHeight]="96"
				[padding]="16"
			></app-simple-text-box>
		</div>
		<div>
			<app-upload-drag-zone
				[attachment]="true"
				[itemId]="item.id"
				[isPoi]="true"
				(fileUploaded)="onFileUploaded($event)"
				(uploading)="isloading($event)"
			></app-upload-drag-zone>
			<div class="item-list">
				<div *ngIf="loading" class="attachment-item">
					<div class="orange-dot"></div>
					<div class="img-container">
						<div>
							<img
								src="resources/img/documents_generic_icon.svg"
								width="24"
								height="24"
							/>
						</div>
					</div>
					<span>{{ shortenName(loading) }}</span>
					<div class="delete-container">
						<img src="resources/img/animations/loader.gif" />
					</div>
				</div>

				<div *ngFor="let file of item.attachmentList">
					<div class="attachment-item" (click)="openFile(file)">
						<div
							*ngIf="justUploaded.get(file.id)"
							class="orange-dot should-disappear"
						></div>
						<div *ngIf="file.__unseen" class="orange-dot"></div>
						<div class="img-container">
							<div *ngIf="fileType(file.filename) === 'pdf'">
								<img
									class="pdf-document"
									src="resources/img/document_pdf.svg"
									width="24"
									height="24"
								/>
								<object type="application/pdf" appIrisImage [id]="file.id"></object>
							</div>
							<div *ngIf="fileType(file.filename) === 'image'">
								<img appIrisImage [id]="file.id" width="24" height="24" />
							</div>
							<div *ngIf="fileType(file.filename) === 'video'">
								<video appIrisImage [id]="file.id" width="24" height="24"></video>
							</div>
						</div>
						<span>{{ shortenName(file.filename) }}</span>
						<div class="delete-container">
							<img
								src="resources/img/delete.svg"
								(click)="deattach(file.id, $event)"
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="dialog-single-button">
		<button class="main-blue-gradient-border-button cancel-button" (click)="cancel()">
			{{ text().CANCEL }}
		</button>
		<button class="main-blue-gradient-button" (click)="save(true)">{{ text().SAVE }}</button>
	</div>
</div>
<app-loading-dialog
	*ngIf="dialogs.loading"
	[downloadingVideo]="true"
	[headerText]="text().DOWNLOADING_VIDEO"
	[bodyText]="text().PLAYBACK_ISSUE"
	[infoText]="dialogs.loading"
>
</app-loading-dialog>
<app-task-completed-dialog
	*ngIf="dialogs.taskCompleted"
	[headerText]="text().DOWNLOADED_VIDEO"
	[bodyText]="text().COMPLETED"
	[infoText]="text().PLAYBACK_INFO"
	(closeCallback)="dialogs.taskCompleted = false"
></app-task-completed-dialog>
