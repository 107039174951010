export class CSBlock {
	color: string;
	title: string;
	x: number;
	y: number;

	constructor(color: string, title: string, x: number, y: number) {
		this.color = color ? color : "#377ab7";
		this.title = title ? title : "";
		this.x = x;
		this.y = y;
	}
}
