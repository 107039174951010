import { DTO } from "../net/dto";
import { DecisionFile } from "./decision-file";

export class Decision implements DTO {
	id: number;
	id_mission: number;
	name: string;
	type: string;
	tactical: string;
	description: string;
	result: string;
	timestamp_ms: number;
	timestamp_full: number;
	content_audio: number;
	result_audio: number;
	content_filename: string;
	result_filename: string;
	full_audio: number;
	audio_filename: string;
	state: string;
	id_signature: number;
	id_fill_signature: number;
	is_event: boolean;
	is_decision: boolean;
	is_message: boolean;
	id_prediction: number;

	files: Array<DecisionFile>;
	notUploaded: boolean | undefined;

	__signName: string | undefined;

	timestamp = Date.now();
	constructor(
		id: number,
		id_mission: number,
		name: string,
		type: string,
		tactical?: string,
		description?: string,
		result?: string,
		timestamp_ms?: number,
		content_audio?: number,
		result_audio?: number,
		content_filename?: string,
		result_filename?: string,
		full_audio?: number,
		audio_filename?: string,
		state?: string,
		id_signature?: number,
		id_fill_signature?: number,
		is_event?: boolean,
		is_decision?: boolean,
		is_message?: boolean,
		id_prediction?: number
	) {
		this.id = id ? id : -1;
		this.id_mission = id_mission;
		this.name = name;
		this.type = type;
		this.tactical = tactical ? tactical : "";
		this.state = state ? state : "";
		this.description = description ? description : "";
		this.result = result ? result : "";
		this.timestamp_ms = timestamp_ms ? timestamp_ms : Date.now();
		this.timestamp_full = Date.parse(this.timestamp_ms.toString());
		this.content_audio = content_audio ? content_audio : -1;
		this.result_audio = result_audio ? result_audio : -1;
		this.content_filename = content_filename ? content_filename : "";
		this.result_filename = result_filename ? result_filename : "";
		this.full_audio = full_audio ? full_audio : -1;
		this.audio_filename = audio_filename ? audio_filename : "";
		this.id_signature = id_signature ? id_signature : -1;
		this.id_fill_signature = id_fill_signature ? id_fill_signature : -1;
		this.is_event = is_event ? is_event : false;
		this.is_decision = is_decision ? is_decision : false;
		this.is_message = is_message ? is_message : false;
		this.id_prediction = id_prediction ? id_prediction : -1;
		this.files = new Array();
		return this;
	}

	public static readonly fromJson: (json: string) => Decision = (json) => {
		var jc = JSON.parse(json);
		var output = new Decision(
			jc.id,
			jc.id_mission,
			jc.name,
			jc.type,
			jc.tactical,
			jc.description,
			jc.result,
			jc.timestamp,
			jc.content_audio,
			jc.result_audio,
			jc.content_filename,
			jc.result_filename,
			jc.full_audio,
			jc.audio_filename,
			jc.state,
			jc.id_signature,
			jc.id_fill_signature,
			jc.is_event,
			jc.is_decision,
			jc.is_message
		);
		return output;
	};

	public readonly getJson: Function = () => {
		(this as any).mission_id = this.id_mission;
		return JSON.stringify(this, [
			"id",
			"mission_id",
			"id_mission",
			"name",
			"type",
			"tactical",
			"description",
			"result",
			"timestamp_full",
			"timestamp_ms",
			"content_audio",
			"result_audio",
			"content_filename",
			"result_filename",
			"full_audio",
			"audio_filename",
			"state",
			"id_signature",
			"id_fill_signature",
			"is_event",
			"is_decision",
			"is_message"
		]);
	};
}
