import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, ɵdetectChanges } from "@angular/core";
import { TextProvider } from "src/app/global/constants/text/text-provider";
import { LocaleMap } from "src/app/global/constants/text/text-interface";
import { Scenario } from "src/app/dto/ara/scenario";
import { RiskInformation } from "src/app/dto/ara/risk-information";
import { AraService } from "src/app/incident/incident-tools/ara/ara.service";
import { RiskInformationRelation } from "../../../../../dto/ara/risk-information-relation";
import { CM_DONE_STATUS, ControlMeasureRelation } from "src/app/dto/ara/control-measure-relation";

@Component({
	selector: "app-risk-informations-modal",
	templateUrl: "risk-informations-modal.component.html",
	styleUrls: ["risk-informations-modal.css"]
})
export class RiskInformationsModalComponent implements OnInit, AfterViewInit {
	@Input() scenario: Scenario | undefined;
	@Input() riskInfoTypes: Array<RiskInformation> = new Array<RiskInformation>();
	@Input() riskInfoRelations!: Array<RiskInformationRelation>;
	@Input() disabled: boolean = false;

	@Output() closeEvent = new EventEmitter();
	@Output() deleteRI = new EventEmitter<RiskInformation>();

	@ViewChild("inputNewItem") inputNewItem!: ElementRef;
	@ViewChild("inputHiddenText") inputHiddenText!: ElementRef;

	public text: () => LocaleMap;

	public written: boolean = false;
	public makingNewItem: boolean = false;
	public newItem: string | null = "";
	public isNewRiskSelected: boolean = false;
	public allRisksSelected: boolean = false;
	public chipSelected: boolean = false;
	public selectedRiskInformations: Array<RiskInformationRelation> = [];
	public width: number = 64;

	private readonly araServ: AraService;

	private minWidth: number = 64;

	constructor(araServ: AraService, textProv: TextProvider) {
		this.araServ = araServ;
		this.text = textProv.getStringMap;
	}

	ngOnInit(): void {
		this.selectedRiskInformations = [...this.riskInfoRelations];
	}

	ngAfterViewInit(): void {
		this.allRisksSelected = this.areAllRisksSelected();
	}

	public readonly resizeInput: () => void = () => {
		this.width = Math.max(this.minWidth, this.inputHiddenText.nativeElement.offsetWidth);
	};

	public readonly closeDialog: Function = () => {
		this.closeEvent.emit();
	};

	public readonly confirm: Function = () => {
		this.riskInfoRelations.length = 0;
		this.selectedRiskInformations.forEach((r) => !this.riskInfoRelations.find((risk) => risk.id_risk_information === r.id_risk_information) && this.riskInfoRelations.push(r));
		this.riskInfoRelations.sort((a, b) => a.id_risk_information - b.id_risk_information);
		this.riskInfoRelations.forEach((ri) => {
			ri.controlMeasures.sort((a, b) => a.id_cm - b.id_cm);
		})
		this.closeDialog();
	};

	public readonly openNewItem: (type: string) => void = (type) => {
		if (!this.disabled) {
			this.makingNewItem = true;
			ɵdetectChanges(this);
			this.inputNewItem.nativeElement.addEventListener("keypress", this.resizeInput);
		}
	};

	public readonly stopCreatingNewValue: () => void = () => {
		this.written = false;
		this.inputNewItem && this.inputNewItem.nativeElement.removeEventListener("keypress", this.resizeInput);
		this.makingNewItem = false;
		this.newItem = "";
	};

	public readonly newItemKeydownController: (evt: any, type: string) => void = (evt, type) => {
		if (evt.type !== "keydown") return;

		switch (evt.key) {
			case "Enter":
				switch (type) {
					case "RISK_INFORMATION":
						this.commitNewRiskInformation();
						break;
				}
				this.newItem = null;
				break;
			case "Escape":
				this.stopCreatingNewValue();
				break;
			default:
				this.written = true;
				return;
		}
	};

	public commitNewRiskInformation(): void {
		if (this.scenario && this.scenario?.id_scenario > -1) {
			const newRiskInfo = new RiskInformation(-1, this.newItem ? this.newItem : "", this.scenario.id_scenario);
			this.saveRiskInformation(newRiskInfo);
		}

		this.stopCreatingNewValue();
	}

	public clickOutsideChip: () => void = () => {
		if (this.scenario && this.scenario?.id_scenario > -1 && this.makingNewItem) {
			const newRiskInfo = new RiskInformation(-1, this.newItem ? this.newItem : "", this.scenario.id_scenario);
			this.saveRiskInformation(newRiskInfo);
		}
		this.stopCreatingNewValue();
	};

	public deleteRiskInfoDialog(riskInfo: RiskInformation): void {
		this.deleteRI.emit(riskInfo);
	}

	public readonly isNewItemCheckedFunc = (): (() => boolean) => {
		return () => this.isNewRiskSelected;
	};

	public readonly checkNewItem: () => void = () => {
		this.isNewRiskSelected = !this.isNewRiskSelected;
	};

	public getRiskInformationCheckFunction(ri: RiskInformation): RiskInformationRelation | undefined {
		return this.selectedRiskInformations.find((e) => e.id_risk_information === ri.id_risk_information);
	}

	public readonly selectRiskInformation: (riskInfo: RiskInformation) => void = (riskInfo) => {
		const idx = this.selectedRiskInformations.findIndex((r) => r.id_risk_information === riskInfo.id_risk_information);
		if (idx > -1) this.selectedRiskInformations.splice(idx, 1);
		else {
			const existingRel = this.wasAlreadySelected(riskInfo);
			if (existingRel) this.selectedRiskInformations.push(existingRel);
			else {
				const newRiskInfoRel = new RiskInformationRelation(-1, -1, riskInfo.id_risk_information);
				newRiskInfoRel.__object = riskInfo;

				newRiskInfoRel.controlMeasures = this.araServ.ControlMeasureTypes.filter((c) => {
					return c.id_risk_information === riskInfo.id_risk_information && !c.deleted;
				}).map((cm) => {
					const cmRelEl = new ControlMeasureRelation(-1, -1, cm.id_cm, CM_DONE_STATUS.UNSET);
					cmRelEl.__object = cm;
					return cmRelEl;
				});
				this.selectedRiskInformations.push(newRiskInfoRel);
			}
		}
	};

	public readonly selectAllRisks: () => void = () => {
		if (!this.areAllRisksSelected()) {
			this.selectedRiskInformations = [...this.riskInfoRelations];
			this.riskInfoTypes.forEach((riskInfo: RiskInformation) => !this.wasAlreadySelected(riskInfo) && this.selectRiskInformation(riskInfo));
		} else this.selectedRiskInformations = new Array<RiskInformationRelation>();
	};

	public readonly areAllRisksSelected: () => boolean = () => {
		for (let i = 0; i < this.riskInfoTypes.length; i++) {
			if (!this.selectedRiskInformations.filter((riskInfo) => !riskInfo.__object?.deleted).find((riskInfo) => riskInfo.id_risk_information === this.riskInfoTypes[i].id_risk_information)) return false;
		}
		return true;
	};

	private wasAlreadySelected(riskInfo: RiskInformation): RiskInformationRelation | undefined {
		return this.riskInfoRelations.find((ri) => ri.id_risk_information === riskInfo.id_risk_information);
	}

	private readonly saveRiskInformation: (riskInfo: RiskInformation) => void = (riskInfo) => {
		if (riskInfo.name !== "") {
			this.araServ.saveRiskInformation(riskInfo).then((response: RiskInformation) => {
				this.scenario = this.araServ.Scenarios.find((scenario) => scenario.id_scenario === riskInfo.id_scenario);
				this.riskInfoTypes.push(response);
				this.allRisksSelected = this.areAllRisksSelected();
			});
		}
	};
}
