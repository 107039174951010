<div
	class="main-transparent-mask"
	*ngIf="open && !alwaysOpen"
	(click)="open = false; $event.stopPropagation()"
></div>
<div class="container {{ open || alwaysOpen ? 'open' : '' }}">
	<div class="input-field" *ngIf="open || alwaysOpen">
		<input
			data-cy="search-input"
			placeholder="{{ placeholderText }}"
			class="main-input-field"
			appInstantFocus
			[(ngModel)]="queryOutput"
			(ngModelChange)="change(queryOutput)"
		/>
		<label>{{ text().SEARCH }}</label>
	</div>

	<span
		data-cy="search-button"
		class="glass-icon {{ open || alwaysOpen ? 'open' : '' }}"
		[class.glass-icon-blue]="queryOutput.length"
		(click)="open = !open; $event.stopPropagation()"
	></span>
</div>
