import { Injectable } from "@angular/core";
import { WebRequestFactory } from "src/app/http/web.request.factory";
import { DTOArray } from "src/app/dto/net/dto-array";
import { FsgBuilding } from "src/app/dto/fsg/fsg-building";
import { MainService } from "src/app/global/main.service";
import { CloneFactory } from "src/app/dto/net/clone-factory";
import { LocaleMap } from "src/app/global/constants/text/text-interface";
import { TextProvider } from "src/app/global/constants/text/text-provider";
import { IncidentService } from "../../incident.service";
import { DEFAULT_SOI, DEFAULT_ADVICE, DEFAULT_CONDITION, FLAT_TYPE, FLAT_SECTOR } from "src/app/dto/fsg/enums";
import { FsgFlat } from "src/app/dto/fsg/fsg-flat";
import { FsgFlatHistory } from "src/app/dto/fsg/flat-history";
import { FormsService } from "../forms/forms.service";
import { NOTIFICATION_TYPE } from "src/app/dto/notification/notification";
import { LoginService } from "src/app/login/login.service";
import { Incident } from "src/app/dto/items/incident";
import { Subject } from "rxjs";
import { FORM_SCREENS } from "../forms/screens.enum";

@Injectable({
	providedIn: "root"
})
export class FsgService {
	public readonly $fsgsLoaded = new Subject<void>();
	public readonly $fsgUpdate = new Subject<{ fsg: FsgBuilding; removed?: boolean }>();
	public Fsgs: Array<FsgBuilding> = new Array();

	private readonly main: MainService;
	private readonly wreq: WebRequestFactory;
	private readonly ems: IncidentService;
	private readonly jesip: FormsService;
	private readonly loginService: LoginService;
	private readonly text: () => LocaleMap;

	private mission: Incident | undefined;
	private skipFlatUpdate: number[] = [];

	constructor(main: MainService, wreq: WebRequestFactory, tp: TextProvider, ems: IncidentService, fs: FormsService, login: LoginService) {
		this.main = main;
		this.wreq = wreq;
		this.ems = ems;
		this.jesip = fs;
		this.loginService = login;

		this.text = tp.getStringMap;
		this.ems.loadMission$.subscribe(async () => await this.onLoad());
	}

	public readonly onLoad = async (): Promise<void> => {
		this.mission = this.main.getCurrentIncident();
		this.mission && (await this.getFsg());
	};

	// gets an array of ones without any flat information
	public readonly getFsg = async (id_mission: number = this.mission ? this.mission.id : -1): Promise<FsgBuilding[] | undefined> => {
		this.Fsgs = []; //if the user leave the incident and come back to it, the array should be rebuild so that flats are removed and added when the form is accessed (to include if there has been any flat updates during the absense).
		const ans = await this.wreq.getFsg(-1, id_mission);
		ans && DTOArray.UpdateFromJsonArray(this.Fsgs, ans, FsgBuilding);
		this.$fsgsLoaded.next();
		return (this.Fsgs = this.Fsgs.filter((fsg) => !fsg.deleted));
	};

	// gets an array of all flats related to an fsg
	public getFsgFlats = async (id_fsg: number): Promise<Array<FsgFlat> | undefined> => {
		if (id_fsg && this.mission) {
			const flatsArray = await this.wreq.getFullFsg(id_fsg, this.mission.id);
			if (flatsArray && flatsArray.length) {
				let flats: Array<FsgFlat> = [];
				DTOArray.UpdateFromJsonArray(flats, flatsArray, FsgFlat);
				return flats.filter((flat) => !flat.deleted).reverse();
			}
		}
		return;
	};

	/**
	 * Calls the SP SaveFsg, it saves the new fsg and create its floors as per number of floors, flat per floor and naming convention
	 * or update the fsg data if it already exists.
	 * Once received, it calls the getFsgFlats method which return an array of flats related to the fsg
	 * @returns fsgBuilding object with __flats
	 */
	public readonly saveFsg = async (fsg: FsgBuilding): Promise<FsgBuilding | undefined> => {
		if (fsg) {
			if(fsg.id_incident < 1) fsg.id_incident = fsg.id_last_incident;
			const isNewFsg: boolean = !fsg.id || fsg.id < 1;
			const ans = await this.wreq.saveNewFsg(fsg);
			if (ans) {
				const updatedFsg = FsgBuilding.fromJson(ans);
				const idx = this.Fsgs.findIndex((fsg: FsgBuilding) => fsg.id === updatedFsg.id);
				if (idx === -1) this.Fsgs.push(updatedFsg);
				else CloneFactory.cloneProperties(this.Fsgs[idx], updatedFsg);
				const snackbarMessage = isNewFsg ? `"${updatedFsg.name}" ${this.text().FORM_CREATED}` : !updatedFsg.active ? `"${updatedFsg.name}" ${this.text().DEACTIVATED}` : this.text().PREFERENCES_UPDATED;
				this.main.setSnackbar(snackbarMessage);
				return updatedFsg;
			}
		}
		return;
	};

	// flat is a Flat object, should send the save request and return the result to the caller
	public readonly saveFlat = async (flat: FsgFlat): Promise<FsgFlat | undefined> => {
		if (flat) {
			flat.timestamp_ms = Date.now();
			let newFlat = await this.wreq.saveFsgFlat(flat);
			if (newFlat) {
				newFlat = FsgFlat.fromJson(newFlat);
				newFlat.id !== -1 && this.skipFlatUpdate.push(newFlat.id);
				const fsg = this.Fsgs.find((fsg) => fsg.id === newFlat.id_fsg);
				if (fsg) fsg.last_update_ms = flat.timestamp_ms;
				return newFlat;
			}
		}
		return;
	};

	/**
	 * This function is called by automation service which is listening all the lastUpdates which includes, save delete and update operations
	 * related to Fsgs. Current id_mission is passed as an argument to only bring fsgs inside incident´s perimeter. If the object existed
	 * and is not retrieved by get, it may indicate that it has been moved outside the perimeter. Other wise, the new FsgObject is received
	 * and treated accordingly. It also launches the fsgUpdate$ event which informs maps to refresh their markers and arrays.
	 * @param id_fsg: should be a valid fsg id
	 */
	public readonly getUpdateFsg = async (id_fsg: number): Promise<FsgBuilding | undefined> => {
		const currentIncident = this.ems.getCurrentIncident();
		if (id_fsg > -1 && currentIncident && currentIncident.id !== -1) {
			const simpleFsg = await this.wreq.getFsg(id_fsg, currentIncident.id);
			if (simpleFsg && simpleFsg.length) {
				const fsg = FsgBuilding.fromJson(simpleFsg[0]);
				const index = this.Fsgs.findIndex((e) => e.id_fsg === id_fsg);
				if (index >= 0) CloneFactory.cloneProperties(this.Fsgs[index], fsg);
				else this.Fsgs.push(fsg);
				this.generateNotification(fsg);
				this.$fsgUpdate.next({ fsg: fsg });
				return fsg;
			} else this.removeIfOutsidePerimeter(id_fsg);
		}
		return;
	};

	public readonly getUpdateFlat = async (id_flat: number, id_mission: number): Promise<FsgFlat | undefined> => {
		if (id_flat > -1) {
			if (this.skipFlatUpdate.includes(id_flat)) this.skipFlatUpdate.splice(this.skipFlatUpdate.indexOf(id_flat), 1);
			else {
				const flat = await this.wreq.getFlat(id_flat, id_mission);
				if (flat && flat[0]) {
					const flatEl = FsgFlat.fromJson(flat[0]);
					const fsg = this.Fsgs.find((e) => e.id_fsg === flatEl.id_fsg);
					if (fsg) {
						if (fsg.__flats) {
							const idx = fsg.__flats.findIndex((e) => e.id_flat === flatEl.id_flat);
							if (idx !== -1) {
								fsg.__flats.splice(idx, 1);
							}
						}
						this.orderedFlatInsert(fsg, flatEl);
						this.$fsgUpdate.next({ fsg: fsg });
					}
					this.jesip.jesipUpdate$.next({ form: FORM_SCREENS.FSG, mission: id_mission });
					return flatEl;
				}
			}
		}
		return;
	};

	// id is of a valid Fsg object, should return true or false depending on the server reply
	public readonly deleteFsg: (fsg: FsgBuilding) => Promise<boolean> = async (fsg: FsgBuilding) => {
		if (fsg && !fsg.__flats) fsg.__flats = await this.getFsgFlats(fsg.id);
		if (fsg) {
			const res = await this.wreq.deleteFsg(fsg);
			if (res) {
				const idx = this.Fsgs.findIndex((e) => e.id_fsg === fsg.id_fsg);
				if (idx !== -1) this.Fsgs.splice(idx, 1);
				this.main.setSnackbar(this.text().FSG_DELETED);
				return true;
			} else return false;
		} else {
			return false;
		}
	};

	public async deleteFlat(flat: FsgFlat): Promise<boolean> {
		if (flat) {
			flat.timestamp_ms = Date.now();
			await this.wreq.deleteFlat(flat);
			return true;
		}
		return false;
	}

	public async activateEvacuationForm(fsg: FsgBuilding): Promise<void> {
		const canBeActivated = await this.canActivate(fsg);
		if (!canBeActivated) {
			const incidentName = this.ems.Incidents.find((incident) => incident.id === fsg.id_last_incident)!.name;
			this.main.addDangerAlert(`${this.text().UNABLE_TO_ACTIVATE_INFO}  "${incidentName}".`, this.text().FORM_USED);
		} else {
			try {
				fsg.active = true;
				await this.addFsgToMission(fsg, this.mission!.id);
				this.main.setSnackbar(`"${fsg.name}" ${this.text().ACTIVATED}`);
			} catch (ex) {
				console.error(ex);
				fsg.active = false;
			}
		}
	}

	public async deactivateEvacuationForm(fsg: FsgBuilding): Promise<void> {
		fsg.active = false;
		await this.saveFsg(fsg);
	}

	// fsg should be a valid Fsg object and id_mission should be a valid index, returns the flatvalue array of that particular mission and fsg
	public async getAllFlatValues(fsg: FsgBuilding): Promise<Array<FsgFlat> | undefined> {
		const jsonArray = await this.wreq.getFsgHistory(fsg.id_fsg, fsg.id_last_incident);
		if (jsonArray && jsonArray.length > 0) {
			let faltValues: Array<FsgFlat> = [];
			faltValues = DTOArray.UpdateFromIdlessJsonArray(faltValues, jsonArray, FsgFlat);
			return faltValues;
		}
		return;
	}

	// returns an array of objects
	// each object has the id of a flat and a mission and an array containing all of its FlatValue entries with those ids ordered chronologically ([0] is oldest)
	public setupFsgHistory(flatvals: Array<FsgFlat>): FsgFlatHistory[] {
		let history: Array<FsgFlatHistory> = [];
		flatvals.sort((a, b) => a.id_flat - b.id_flat);
		for (let flat of flatvals) {
			let idx = history.findIndex((e) => e.id_flat === flat.id_flat);
			if (idx === -1) {
				history.push(new FsgFlatHistory(flat.id_flat, flat.id_incident, flat.flat_name, flat.floor_number));
				idx = history.length - 1;
			}
			history[idx].history.push(flat);
			history[idx].history.sort((a, b) => {
				return a.timestamp_ms - b.timestamp_ms; //if return less than 0, 'a' element goes first
			});
		}
		return history;
	}

	public getFireOrigin(flats: FsgFlat[]): FsgFlat | undefined {
		if (flats && flats.length) {
			const isOnFire = (e: string) => e.toUpperCase() === "FIRE" || e.toUpperCase() === this.getConditionValueAsLocalizedString(DEFAULT_CONDITION.defaults.get("FIRE")!);
			const fireFlats = flats.filter((flat) => flat.condition.some(isOnFire));
			const timestamps = fireFlats.map((f) => f.timestamp_ms);
			const oldestTimestamp = Math.min(...timestamps);
			const firstFireFlat = fireFlats.find((flat) => flat.timestamp_ms === oldestTimestamp);
			return firstFireFlat;
		}
		return;
	}

	public readonly getSoiValueAsLocalizedString: (val: string) => string = (val) => {
		switch (val.toUpperCase()) {
			case DEFAULT_SOI.CIVILIANS.toUpperCase():
				return this.text().CIVILIANS;
			case DEFAULT_SOI.CONTROL_ROOM.toUpperCase():
				return this.text().CONTROL_ROOM;
			case DEFAULT_SOI.FIREFIGHTERS.toUpperCase():
				return this.text().FIREFIGHTERS;
			case DEFAULT_SOI.OTHER_AGENCIES.toUpperCase():
				return this.text().OTHER_AGENCIES;
			case DEFAULT_SOI.UNSET.toUpperCase():
				return "";
			default:
				return val;
		}
	};

	public readonly getAdviceValueAsLocalizedString: (val: string) => string = (val) => {
		switch (val.toUpperCase()) {
			case DEFAULT_ADVICE.defaults.get("DEFAULT")!.toUpperCase():
				return "";
			case DEFAULT_ADVICE.defaults.get("FULL_EVACUATION")!.toUpperCase():
				return this.text().FULL_EVACUATION;
			case DEFAULT_ADVICE.defaults.get("PHASE_EVACUATION")!.toUpperCase():
				return this.text().PHASE_EVACUATION;
			case DEFAULT_ADVICE.defaults.get("STAY_PUT")!.toUpperCase():
				return this.text().STAY_PUT;
			default:
				return val;
		}
	};

	public readonly getPriorityValueAsLocalizedString: (val: number) => string = (val) => {
		if (val === -1) return this.text().PENDING;
		else return this.text().FSG_PRIORITY + " " + val;
	};

	public readonly getConditionValueAsLocalizedString: (val: string) => string = (val) => {
		val = val ? val.trim() : "";
		switch (val.toUpperCase()) {
			case "".toUpperCase():
				return "";
			case DEFAULT_CONDITION.defaults.get("FIRE")!.toUpperCase():
				return this.text().FIRE;
			case DEFAULT_CONDITION.defaults.get("FLOOD")!.toUpperCase():
				return this.text().FLOOD;
			case DEFAULT_CONDITION.defaults.get("SMELL")!.toUpperCase():
				return this.text().SMELL_OF_BURNING;
			case DEFAULT_CONDITION.defaults.get("SMOKE")!.toUpperCase():
				return this.text().SMOKE;
			default:
				return val;
		}
	};

	public readonly getFlatTypeIcon: (type: string) => string = (type) => {
		switch (type) {
			case FLAT_TYPE.BASEMENT:
				return "resources/img/icons-fsg/flat-types/Basement.svg";
			case FLAT_TYPE.ROOFTOP:
				return "resources/img/icons-fsg/flat-types/Rooftop.svg";
			case FLAT_TYPE.SUBBASEMENT:
				return "resources/img/icons-fsg/flat-types/Sub-basement.svg";
			default:
				return "resources/img/icons-fsg/flat-types/Flat.svg";
		}
	};

	public readonly getFlatTypeValueAsLocalizedString: (type: string) => string = (type) => {
		switch (type.toUpperCase()) {
			case FLAT_TYPE.BASEMENT.toUpperCase():
				return this.text().BASEMENT;
			case FLAT_TYPE.ROOFTOP.toUpperCase():
				return this.text().ROOFTOP;
			case FLAT_TYPE.SUBBASEMENT.toUpperCase():
				return this.text().SUBBASEMENT;
			case FLAT_TYPE.FLAT.toUpperCase():
				return this.text().FLAT;
			default:
				return type;
		}
	};

	public readonly getSectorValueAsLocalizedString: (sect: number) => string = (sect) => {
		switch (sect) {
			case FLAT_SECTOR.BRIDGE:
				return this.text().BRIDGEHEAD;
			case FLAT_SECTOR.FIRE:
				return this.text().FIRE;
			case FLAT_SECTOR.LOBBY:
				return this.text().LOBBY;
			case FLAT_SECTOR.SEARCH:
				return this.text().SEARCH;
			default:
				return this.text().UNSPECIFIED;
		}
	};

	public readonly getCurrentActiveForms = (id_incident?: number): Array<FsgBuilding> => {
		const idIncident = id_incident ?? this.mission?.id;
		return this.Fsgs.filter((el) => el.active && !el.deleted && idIncident && idIncident === el.id_last_incident);
	};

	public hasFlatBeenUpdated(flat: FsgFlat): boolean {
		return (
			flat.comments !== "" ||
			flat.condition.length > 1 ||
			(flat.condition[0] && flat.condition[0] !== "") ||
			flat.cleared ||
			!!flat.people ||
			// (flat.flat_name !== `${flat.floor_number}-${flat.flat_pos}` && flat.flat_name !== "") ||
			flat.flat_type !== FLAT_TYPE.FLAT ||
			flat.fsg_advice !== DEFAULT_ADVICE.defaults.get("DEFAULT") ||
			flat.priority !== -1 ||
			flat.source_of_information !== DEFAULT_SOI.UNSET ||
			flat.casualty_status !== "" ||
			flat.status !== -1
		);
	}

	public readonly findLastTs: (arr: Array<FsgFlat>) => number = (arr) => {
		let ans = 0;
		if (arr?.length) {
			for (let i = 0; i < arr.length; i++) {
				if (arr[i].timestamp_ms > ans) ans = arr[i].timestamp_ms;
			}
		}
		return ans;
	};

	public orderedFlatInsert(fsg: FsgBuilding, flat: FsgFlat): void {
		if (!fsg.__flats) {
			fsg.__flats = [flat];
			return;
		}
		if (flat.deleted) return;
		let inserted = false;
		for (let i = 0; i < fsg.__flats.length && !inserted; i++) {
			if (fsg.__flats[i].floor_number < flat.floor_number || (fsg.__flats[i].floor_number === flat.floor_number && fsg.__flats[i].flat_pos < flat.flat_pos)) {
				fsg.__flats.splice(i, 0, flat);
				inserted = true;
			}
		}
		if (!inserted) fsg.__flats.push(flat);
	}

	private async canActivate(fsg: FsgBuilding): Promise<boolean> {
		const flats = await this.getFsgFlats(fsg.id);
		if (!flats) return true;
		const flat = flats.some((flat) => this.hasFlatBeenUpdated(flat));
		const closedIncidentEF = this.ems.Incidents.filter((incident) => incident.closed && incident.id === fsg.id_last_incident);
		return closedIncidentEF && !flat;
	}

	/**
	 * @param fsg: Should be a valid fsg object
	 * @param id_mission: Current Incident id
	 * Connected to the procedure AddFsgToMission, it takes the flat structure of the fsg and insert new flats by
	 * copying the name, position and floor from the previous flats, associate them with the fsg and id_mission passed, add the id mission
	 * as fsg´s last_id_mission and turn it´s active property to 1.
	 * @returns Array of flats of the fsg
	 */
	private readonly addFsgToMission = async (fsg: FsgBuilding, id_mission: number): Promise<FsgBuilding | undefined> => {
		if (fsg && id_mission) {
			fsg.id_incident = id_mission;
			const jsonArray = await this.wreq.addFsgToMission(fsg, id_mission);
			if (jsonArray && jsonArray.length) {
				const flats: Array<FsgFlat> = [];
				DTOArray.UpdateFromJsonArray(flats, jsonArray, FsgFlat);
				fsg.__flats = flats.filter((flat) => !flat.deleted).reverse();
				return fsg;
			}
		}
		return;
	};

	private generateNotification = (fsg: FsgBuilding): void => {
		if (fsg.id_user !== -1 && fsg.id_user !== this.loginService.user.id && fsg.id_last_incident === this.ems.getCurrentIncident()!.id) {
			this.jesip.setNotification(NOTIFICATION_TYPE.FSG, fsg.id, fsg.id_last_incident);
			this.jesip.jesipUpdate$.next({ form: FORM_SCREENS.FSG, mission: fsg.id_last_incident });
		}
	};

	private removeIfOutsidePerimeter = (id_fsg: number): void => {
		const index = this.Fsgs.findIndex((fsg) => fsg.id === id_fsg);
		if (index !== -1) {
			const fsgOutsidePerimeter = this.Fsgs.find((fsg) => fsg.id === id_fsg);
			this.Fsgs.splice(index, 1);
			this.$fsgUpdate.next({ fsg: fsgOutsidePerimeter!, removed: true });
			this.main.addDangerAlert(this.text().OUTSIDE_AREA_INFO(this.text().EF), this.text().OUTSIDE_AREA);
		}
	};
}
