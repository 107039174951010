import { AfterViewInit, Directive, ElementRef, EventEmitter, Input, Output } from "@angular/core";

@Directive({
	selector: "[appBoldTextPattern]"
})
export class BoldTextPatternDirective implements AfterViewInit {
	/*
    It could be necesary to put a stop propagation in the callback function
    in order to avoid that the click could be detected by elements that are
    overlayed
    */

	@Input() pattern!: string;

	private element: HTMLElement;

	constructor(el: ElementRef) {
		this.element = el.nativeElement;
	}

	ngAfterViewInit(): void {
		const text = this.element.innerText;
		const result = text.match(new RegExp(this.pattern));
		if (!result) return;
		this.element.innerText = "";
		const firstElem = document.createElement("span");
		const boldElem = document.createElement("span");
		const lastElem = document.createElement("span");
		firstElem.textContent = text.slice(0, result.index);
		boldElem.textContent = text.slice(result.index, result.index! + this.pattern.length);
		boldElem.className = "bold";
		lastElem.textContent = text.slice(result.index! + this.pattern.length, text.length);
		this.element.appendChild(firstElem);
		this.element.appendChild(boldElem);
		boldElem.insertAdjacentHTML("afterbegin", "&nbsp;");
		boldElem.insertAdjacentHTML("afterend", "&nbsp;");
		this.element.appendChild(lastElem);
	}
}
