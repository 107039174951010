import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { LocaleMap } from "src/app/global/constants/text/text-interface";
import { TextProvider } from "src/app/global/constants/text/text-provider";

@Component({
	selector: "app-two-option-dialog",
	templateUrl: "two-option-dialog.component.html"
})
export class TwoOptionDialogComponent implements OnInit {
	@Input() headerText: string = "Error";
	@Input() bodyText: string = "";
	@Input() leftText: string = "";
	@Input() rightText: string = "";
	@Input() rightButtonDisable: boolean = false;
	@Output() cancelCallback = new EventEmitter();
	@Output() leftCallback = new EventEmitter();
	@Output() rightCallback = new EventEmitter();

	public readonly text: () => LocaleMap;

	constructor(textProv: TextProvider) {
		this.text = textProv.getStringMap;
	}

	ngOnInit(): void {
		if (!this.leftText) this.leftText = this.text().CANCEL;
		if (!this.rightText) this.rightText = this.text().CONFIRM;
	}

	public readonly cancel: Function = () => {
		this.cancelCallback.emit();
	};
}
