<div class="main-black-mask" (click)="cancel()"></div>
<div class="container">
	<div class="top-row">
		<div class="title">
			<img class="top-row-icon" src="resources/img/jesip/jesipNotesIcon.svg" />
			<div class="top-row-text">{{ text().NOTES }}</div>
			<app-white-cross class="dialog-cross-button" (clickCb)="cancel()"></app-white-cross>
		</div>
	</div>

	<div #notesContainer class="middle-section">
		<div *ngIf="!notes.length" class="empty-notes-area">
			<div class="empty-notes-icon"></div>
			<div class="empty-notes-text">{{ text().SAVE_NOTES_HERE }}</div>
		</div>

		<div [hidden]="!notes.length" class="notes-container">
			<div *ngFor="let note of notes">
				<div class="timestamp-area">
					<div>
						<div class="timestamp-area-icon">
							<img src="resources/img/icons-fsg/fsg-mini-clock-icon.svg" />
						</div>
						<span>{{ note.timestamp_ms | date: "shortTime" }}</span>
					</div>
					<div>
						<div class="timestamp-area-icon">
							<img src="resources/img/homepage-calendar-icon-off.svg" />
						</div>
						<span>{{ note.timestamp_ms | date: "dd/MM/YYYY" }}</span>
					</div>
				</div>
				<div class="single-note-content">{{ note.content }}</div>
			</div>
		</div>
	</div>

	<div class="bottom-row">
		<textarea
			#notesInput
			[disabled]="recording"
			placeholder="{{ text().TYPE_IN }}"
			(keydown.enter)="$event.preventDefault(); addNewNote()"
			(keydown.escape)="$event.preventDefault(); cancel()"
			[value]="''"
			class="input"
			rows="1"
		>
		</textarea>

		<div class="bottom-row-icons-container">
			<img
				appSpeechToText
				(click)="recordNote()"
				(outcome)="onTranscriptionAvailable($event)"
				src="resources/img/mic.svg"
				class="microphone-icon pointer"
				(recordingEnd)="recording = false"
				[class.recording]="recording"
			/>
			<img
				src="resources/img/jesip/jesipSendNoteBlue.svg"
				(click)="addNewNote()"
				class="send-note-icon"
				[class.recording]="notesInput.value"
			/>
		</div>
	</div>
</div>
