<div class="container">
	<div class="header">
		<div class="title heavy">
			<span>{{ text().FSG_TITLE }}</span>
			<div class="fsg-number">{{ getNumber() }}</div>
		</div>
		<div class="subtitle">
			<div>
				<div class="last-update">
					<div class="subtitle-icon">
						<img src="resources/img/icons-fsg/fsg-mini-clock-icon.svg" />
					</div>
					<b>{{ text().LAST_UPDATE }}: </b>
					<span> {{ getTime() }}</span>
				</div>
				<div class="by">
					<div class="subtitle-icon">
						<img src="resources/img/jesip/author-icon.svg" />
					</div>
					<b>{{ text().BY }}:</b>
					<span> {{ getAuthor() }}</span>
				</div>
			</div>
		</div>
	</div>
	<div class="evacuation-card-table">
		<div class="evacuation-card-table-header">
			<div class="ng-binding">Source of info</div>
			<div class="ng-binding">Conditions</div>
			<div class="ng-binding">Priority</div>
			<div class="ng-binding">Flat</div>
		</div>
		<div class="evacuation-card-table-body">
			<div class="evacuation-card-table-row">
				<div class="evacuation-card-table-color yellow"></div>
				<div class="evacuation-card-table-floor-chip">
					<div class="evacuation-card-flat-icon"></div>
					<div class="evacuation-card-floor-chip-text">6</div>
					<div class="evacuation-card-floor-chip-arrows-container">
						<div class="evacuation-card-arrow"></div>
						<div class="evacuation-card-arrow evacuation-card-arrow-down"></div>
					</div>
				</div>
				<div class="evacuation-card-table-condition-chip ng-binding">Conditions</div>
				<div class="evacuation-card-table-priority-chip priority1">
					<div class="evacuation-card-priority1-chip-icon"></div>
					<div class="evacuation-card-priority-chip-label ng-binding">P1</div>
				</div>
				<div class="evacuation-card-table-name-chip">6B</div>
			</div>
			<div class="evacuation-card-table-row">
				<div class="evacuation-card-table-color red"></div>
				<div class="evacuation-card-table-floor-chip">
					<div class="evacuation-card-flat-icon"></div>
					<div class="evacuation-card-floor-chip-text">6</div>
					<div class="evacuation-card-floor-chip-arrows-container">
						<div class="evacuation-card-arrow"></div>
						<div class="evacuation-card-arrow evacuation-card-arrow-down"></div>
					</div>
				</div>
				<div class="evacuation-card-table-condition-chip ng-binding">Conditions</div>
				<div class="evacuation-card-table-priority-chip priority2">
					<div class="evacuation-card-priority2-chip-icon"></div>
					<div class="evacuation-card-priority-chip-label ng-binding">P2</div>
				</div>
				<div class="evacuation-card-table-name-chip">6B</div>
			</div>
		</div>
	</div>
	<div class="line"></div>
	<div class="footer">
		<app-notification [updateAvailable]="updateAvailable"></app-notification>
		<button
			data-add-action-name="ef-card-update-button"
			*ngIf="canEditEF()"
			class="main-blue-gradient-border-button margin-left-auto"
			(click)="buttonClick.emit($event)"
		>
			{{ getButton() }}
		</button>
	</div>
</div>
