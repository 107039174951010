import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
	selector: "app-chips",
	templateUrl: "./chips.component.html",
	styleUrls: ["./chips.component.css"]
})
export class ChipsComponent {
	@Input() chips: string[] = [];
	@Input() compareSelect: string = "";

	@Output() clickEvent = new EventEmitter();

	public getSelectedChips(chip: string): boolean {
		return !!this.compareSelect && this.compareSelect === chip;
	}
}
