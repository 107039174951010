import { LocaleMap } from "../text-interface";

export class TextEN implements LocaleMap {
	ACCEPT = "Accept";
	ADD = "Add";
	ADD_FILES = "Add Files";
	ACCESS_HISTORY = "Access";
	ACCESS_HISTORY_PERMISSION = "this permission allows the user to access to historical incidents and data";
	ACCESS_LIVE_INCIDENTS = "Access";
	ACCOUNT_MENU = "My account";
	ACTIONS = "Actions";
	ACTIVATE = "Activate";
	ACTIVATE_EF = "Activate Form";
	ACTIVATE_EF_INFO = "will appear as active and will be editable.";
	ACTIVE = "Active";
	ACTIVATED = "activated";
	ADD_ICON = "Add icon";
	ADD_CANVAS_IMAGE = "Add new image";
	ADD_CANVAS_IMAGE_INFO = "Drag or upload an image (JPG, PNG or SVG)";
	ADD_DETAILS = "Add details";
	ADD_OVERLAY_INFO = "Drag or click here to add your files";
	ADDITIONAL_INFO = "Additional info";
	ADDRESS = "Address";
	AGENT = "Resource";
	AGENTS = "Resources";
	AGENTS_MENU = "Resources Configuration";
	AGENTS_LOADED = "Resources loaded";
	AGENTS_LOADING = "Loading resources";
	AGENT_TYPE = "Resource type";
	ALPHABETICAL = "Alphabetical";
	ALREADY_EXISTS = "That value already exists";
	AND = "and";
	APPLY = "Apply";
	ARA = "Analytical Risk Assessment";
	ARA_SHORT = "ARA";
	ARA_CREATION_TIME = "ARA creation time";
	ARA_DRAFT_DIALOG_TEXT = "There is a draft for New ARA. Discard it to start again or Continue to complete it";
	TM_DRAFT_DIALOG_TEXT = "There is a saved draft.Discard it to start again or Continue to complete it";
	ARA_SECTOR_DRAFT = "There is a draft for this Sector. Discard it to start again or Continue to complete it";
	ARA_FOR = "Analytical Risk Assessments for ";
	ARA_FORMS = "Analytical Risk Assessments Forms";
	ARA_LAST_REVIEW = "Last Review";
	ARA_LOADING = "Loading ARAs";
	ARA_NO_SECTOR = "NO SECTOR";
	ARAS_WITHOUT_SECTOR = "Analytical risk assessments without sector";
	ARA_SAVED = "ARA Saved";
	ARA_SAVED_POPUP_TEXT_1 = "You can find the completed ARA in the ";
	ARA_SAVED_UPDATED_POPUP_TEXT_2 = "ARA page";
	ARA_REVIEWED = "ARA reviewed";
	ARA_UPDATED_POPUP_TEXT1 = "You can find the last ARA reviewed in the";
	ARA_TT_MARGINAL_DESC = "Minor injury/Equipment damage";
	ARA_TT_SERIOUS_DESC = "Injuries likely classed as notifiable";
	ARA_TT_VERY_SERIOUS_DESC = "Severe injury";
	ARA_TT_CRITICAL_DESC = "Fatality serious injury/Equip damage";
	ARA_TT_CATASTROPHIC_DESC = "Death/injury on wide scale";
	ARA_TT_UNLIKELY_DESC = "Very few or no instances";
	ARA_TT_REMOTE_DESC = "May occur in time";
	ARA_TT_REASONABLY_DESC = "Probably occur";
	ARA_TT_PROBABLE_DESC = "Will occur in time";
	ARA_TT_HIGH_DESC = "Very likely to occur";
	ARA_TT_LOW_RISK_DESC = "No action or low priority action";
	ARA_TT_MEDIUM_RISK_DESC = "Unsatisfactory. Eliminate or control hazards. Regular reassessment";
	ARA_TT_HIGH_RISK_DESC = "Unacceptable. Take immediate action";
	ARA_REVIEW_TIME = "ARA review time";
	ARAS = "ARA's";
	AREA = "Area";
	AREA_DELETED = "Area deleted";
	AREA_SAVED = "Area saved";
	AREA_SAVED_NEW = "New Area added";
	AREA_HAS_SECTOR = "This area is currently assigned to a sector";
	AREAS = "Areas";
	AREA_ALREADY_HAS_SECTOR = "Picked area has been assigned to a sector already";
	AREA_EDITING_MESSAGE = "Editing Area. Block area when finish editing to continue";
	AREA_MARKERS = "Area markers";
	AREA_NAME_ON_USE = "Area name on use";
	AREA_TYPE = "Area type";
	AREA_VIEW_DETAILS = "(Segments, Areas)";
	AREYOUSURE = "Are you sure you want to";
	ASSIGN = "Assign";
	ASSIGNED = "Assigned";
	ASSIGNED_TO_INCIDENT = "Assigned to incident";
	ASSIGN_AREA = "Assign area";
	ASSOCIATED_USER = "Associated user";
	AUTHOR = "Author";
	AT = "at";
	AT_RISK = "At risk";
	ATTACH = "Attach";
	ATTACHMENT = "Attachment";
	ATTACH_FILE_FOR = "Attach file for";
	ATTACH_FILE = "Attach file";
	ATTACHED_FILE = "Attached file";
	APPLIANCES = "Appliances";
	APPLIANCE = "Appliance";
	APPLIANCE_TYPE = "Appliance type";
	ALL = "All";
	ALL_INFO_LOST = "All information entered will be lost";
	ALL_INCIDENTS = "All Incidents";
	AUTHENTICATION_FAILED = "Authentication failed";
	AUTHENTICATION_REQUIRED = "Authentication required";
	AUTOMATIC = "Automatic";
	AVAILABLE = "Available";
	AVAILABLE_CAMERAS = "Available Cameras";
	AVAILABLE_RESOURCES = "Available Resources";
	AMBULANCE = "Ambulance";
	BACK = "Back";
	BASEMENT = "Basement";
	BEGIN_BRIEFING_SYNTHESIS = "Begin situation briefing for incident";
	BIRTHDATE = "Birth date";
	BLUEPRINT = "Overlay";
	BUILDING_DOCUMENTATION = "Building log documentation";
	BUILDING_ARA_DOCUMENTACION = "Building ARA documentation";
	BUILDING_FULL_DOCUMENTACION = "Building full documentation";
	BUTTON_INFORMATION = "Information";
	CALLSIGN = "Call Sign";
	CALLOUT = "Callout";
	CANCEL = "Cancel";
	CAMERA = "Camera";
	CAMERA_ASSIGNED = "This camera is already assigned to another resource";
	CAMERA_ASSIGNED_TO = "This camera is currently assigned to resource ";
	CAMERAS = "Cameras";
	CAMERAS_AND_GPS = "Cameras & GPS";
	CAMERA_DELETED = "The camera has been deleted.";
	CAMERA_SAVED = "The camera has been saved";
	CANT_DELETE_MILESTONE_CAM = "Cameras from Milestone can not be deleted.";
	CAPABILITY = "Capability";
	CATASTROPHIC = "Catastrophic";
	CENTER_AT = "Center";
	CHANGE_FILE = "Change file";
	CHANGE_PASSWORD = "Change password";
	CHANGES_NOT_SAVED = "Changes not saved";
	CHANGES_SAVED = "Succesfully saved changes";
	CHECKLIST = "JESIP Checklist";
	CHOOSE_PERMISSION = "Choose the permission you want to associate with the user:";
	CHOOSE_VIDEO_SLOT = "Click on a slot to open video feed.";
	CLEAR = "Clear";
	CLEARED = "Cleared";
	CLICK_TO_ASSIGN = "Click to set up";
	CLICK_ADD_ICON = "Click here to add an icon";
	CLICK_ADD_PHOTO = "Click here to add a photo";
	CLICK_VIDEO = "( CLICK HERE TO SELECT VIDEO FEED )";
	CLOSE = "Close";
	CLOSE_INCIDENT = "Close Incident";
	CLOSE_INCITENT_AUTHENTICATION = "Please enter your password to close the incident";
	CLOSE_INCIDENT_PROMPT = "You are going to close this incident. Would you like to continue?";
	CLOSED_EVENTS = "Closed incidents";
	CLOSED_INCIDENT = "Closed Incidents";
	CLOSED_INCIDENT_INFO = "This is now a closed incident, you can visualize it in the Closed Incidents tab.";
	CLOSED_INCIDENT_NO_ACCESS = "This is now a closed incident. You will be redirected to the Live Incidents Homepage.";
	CLOSED_INCIDENT_USERS_INFO = "The commander has closed the Incident. You can visualize it in the Closed Incidents tab.";
	CLOSED_INCIDENT_GUEST_INFO = "The commander has closed the Incident.";
	CLOSING_THE_DIALOG = "Closing the dialog will cancel the download";
	CLOSING_INCIDENT = "Closing Incident";
	CREATION = "Creation";
	CREATE_DL = "Create";
	CREATE_NEW_ITEM = "Create New";
	CREATE_NEW_SCENARIO = "Create new Scenario";
	CREATE_NEW_RISK = "Create new Risk";
	CREATE_NEW_CM = "Create new control measure";
	CREATE_NEW_SUBCM = "Create new sub control measure";
	CREATE_NEW_FSG_MAP_PROMPT = "Click on the map to set the Evacuation Form location";
	CREW = "Crew";
	CREDENTIALS = "Credentials";
	COASTGUARD = "HM Coastguard";
	COMPATIBILITY = "Compatibility";
	COMPATIBILITY_POPUP_SHORT = "Some KML/KMZ features might not be supported by the map provider.";
	COMPATIBILITY_POPUP_1 = "Some KML/KMZ features might not be supported by the map provider, check out unsupported features";
	COMPATIBILITY_POPUP_2 = "here";
	COMMANDER = "Commander";
	COMMAND = "Command";
	COMMAND_STRUCTURE = "Command Structure";
	COMMANDER_SIGNATURE = "Commander Signature";
	COMMENTS = "Details";
	COMMENTS2 = "Comments";
	COMPLETED = "Completed";
	COMPLETED_ARA = "Completed ARA";
	COMPULSORY_FIELDS = "Pending fields";
	CONDITIONS = "Conditions";
	CONF_LOADING = "Loading configuration";
	CONFIGURE = "Configure";
	CONFIGURE_ARA = "Configure ARA";
	CONFIGURATION = "Configuration";
	CONFIGURATION_MENU = "Iris Settings";
	CONFIRM = "Confirm";
	CONFIRM_NEW_PASSWORD = "Confirm new password";
	CONTACT = "Contact";
	CONTACT_NUMBER = "Contact number";
	CONTENT = "Content";
	CONTINUE = "Continue";
	CONTROL_MEASURE_DELETE = "Delete Control measure";
	CONTROL_MEASURES = "Control Measures";
	CONTROL_MEASURES_ABBR = "C. measures";
	CONTROL_ROOM = "Control Room";
	CONTROLLED = "Controlled";
	CONNECTION_ERROR = "Connection error";
	CONSIDER_ADD_CONTROL = "Consider additional control measures";
	COMMENT_HERE = "Comment here";
	CODE = "Code";
	COLOR = "Colour";
	COPIED_COORDINATES = "Coordinates copied to clipboard";
	COPY = "Copy";
	COPY_URL = "Copy URL";
	CORDON = "Cordon";
	CIRCLE = "Circle";
	CIVILIANS = "Civilians";
	CREATE = "Create";
	CREATE_INCIDENT = "Create Incident";
	CREATED = "created";
	CREATE_REVIEW = "Create/Review";
	CREATE_REVIEW_ARA = "Create/Review ARA";
	CRITICAL = "Critical";
	CURRENT_RECOMMENDATION = "Current recommendation";
	CURRENTLY = "Currently";
	CUSTOM_COLOR = "Custom Colour";
	DATA = "Data";
	DATE = "Date";
	DATE_OF_EVENT = "Date of event";
	DAYS_ARRAY = ["M", "T", "W", "T", "F", "S", "S"];
	DB_QUERY_ERROR = "An error has occured in the query to the database.";
	DB_CONNECT_ERROR = {
		agent_not_found: "Specified resource was not found",
		appliance_not_found: "Specified appliance was not found",
		ara_exists: "This area is being used in an ARA and cannot be deleted",
		areatype_fk_undeletable: "The area type is being used. Delete areas or change their type",
		camera_in_use: "The camera is assigned to another resource",
		cant_delete_user: "The user is related to some Logs or ARAs and cannot be deleted",
		fk_undeletable: "The item couldn't be deleted because it has associated information",
		id_not_found: "Specified item was not found",
		ims_name_in_use: "The name already exists, choose another",
		incident_closed: "You can't add more data",
		mission_already_exists: "An incident with this number already exists, please choose another",
		mission_not_found: "Specified incident was not found",
		name_in_use: "The name already exists, choose another",
		name_not_found: "Specified item was not found",
		permission_denied: "User permission level does not allow for the requested action",
		poitype_fk_undeletable: "The POI type couldn't be deleted because it's being used",
		query_error: "There is a problem with the request, please try again or refresh the browser",
		user_is_personnel: "The user could not be removed because it has an associated resource",
		insecure_password: "The password is too easy to guess, please choose another one"
	};
	DEACTIVATE = "Deactivate";
	DEACTIVATE_INFO = "Unable to deactivate once the form contains information";
	DEACTIVATED = "deactivated";
	DEACTIVATE_EF = "Deactivate form";
	DEACTIVATE_EF_INFO = "will appear as inactive and will not be editable.";
	DEACTIVATED_EF = "Deactivated Form";
	DEACTIVATED_EF_INFO = "can't be edited or seen.";
	DEATTACH = "Remove attachment";
	DECISION = "Decision";
	DECISION_LOG = "Decision log";
	DECISION_LOG_SHORT = "Decision log";
	DECISION_TYPE_MISSING = "Log TYPE is missing.";
	DECISION_OPERATIONAL_MISSING = "OPERATIONAL info is missing.";
	DECISION_SAVED = "Decision has been saved";
	DECISION_LOG_SEARCH = "Search logs";
	DEFAULT = "Default";
	DEFAULT_CONNECT_ERROR = "An error has occured with the connection to the server.";
	DEFAULT_DB_ERROR = "An error has occured in the connection process or query to the database.";
	DEFAULT_WEBREQ_ERROR = "An error has occured with the petition to the server.";
	DEFENSIVE = "Defensive";
	DELETE = "Delete";
	DELETE_AGENTS = "Delete Resources";
	DELETE_AGENTS_PERMISSION = "This permission allows the user to delete resource entries";
	DELETE_ITEM = "Delete";
	DELETE_INCIDENT = "Delete Closed Incidents";
	DELETE_MISSION_PERMISSION = "This permission allows the user to delete incidents";
	DELETE_MODAL_MESSAGE = "It will be deleted for your whole organisation.";
	DELETE_POIAREA = "Delete POIs and areas";
	DELETE_POIAREA_PERMISSION = "This permission allows to remove areas and POIs.";
	DELETE_PLANE_CONFIRMATION = "This action will remove the building information and blueprint across all incidents.";
	DELETE_CONFIRM = "Are you sure you want to delete ";
	DELETE_USER = "You are going to delete ";
	DELETED = "Deleted";
	DELETED_RISK_INFORMATION = "Risk Information deleted";
	DELETED_CONTROL_MEASURE = "Control Measure deleted";
	DELETED_SUB_CONTROL_MEASURE = "Sub Control Measure deleted";
	DESCRIPTION = "Description";
	DETAILS = "Details";
	DEVELOPING = "Developing";
	DISCARD = "Discard";
	DOCUMENT_GENERATED_ON = "Document generated on";
	DOCUMENT_DOWNLOADED = "Document Downloaded";
	DOCUMENTATION = "Documentation";
	DONE = "Done";
	DOWNLOAD = "Download";
	DOWNLOAD_DOC_EMPTY = "Nothing to download";
	DOWNLOAD_ERROR = "An error has occurred and the files could not be generated. Please try again";
	DOWNLOAD_MEDIA = "Download media";
	DOWNLOAD_MESSAGE_FSG = "What type of Evacuation Form do you want to download?";
	DOWNLOAD_MESSAGE_CHECKLIST = "What type of Checklist Form do you want to download?";
	DOWNLOAD_MESSAGE_IIMARCH = "What type of IIMARCH Form do you want to download?";
	DOWNLOAD_MESSAGE_JDM = "What type of JDM Form do you want to download?";
	DOWNLOAD_MESSAGE_METHANE = "What type of M/ETHNE Form do you want to download?";
	DOWNLOAD_MESSAGE_DEBRIEF = "What type of DEBRIEF Form do you want to download?";
	DOWNLOAD_POPUP_TEXT = "Are you sure you want to download this incident documents?";
	DOWNLOAD_PROCESS_TEXT = "This process might take a few minutes";
	DOWNLOAD_NOT_POPUP_TEXT = "This incident has no documents to download; please select another one.";
	DOWNLOAD_UNAVAILABLE_BODY = "The files for this incident are too big. If you need them, please contact the Technical Support";

	DOWNLOADED_FILES = "Files Downloaded";
	DOWNLOADING_FILES = "Downloading Files";
	DOWNLOADING_VIDEO = "Downloading video";
	DOWNLOADING_CHECKING_VIDEO_DOTTED = "Checking and downloading video...";
	DOWNLOADED_VIDEO = "Video downloaded";
	DRAFT_AVAILABLE = "Draft available";
	DRAFT_SAVED = "Saved as draft";
	DRAG_HERE = "Drag here";
	DRAG_POI = "Drag the POI to update the position";
	DRAG_POI_2 = "Click anywhere on the map to set the new position";
	DRAG_ZONE = "Drag or click here to add your files";
	DRAW_POI = "Click on the map to draw the POI";
	DRAWING = "Drawing";
	DRAWINGS = "Drawings";
	DRAW_CIRCULAR_AREA = "Draw the circular area on the map";
	DRAW_POLYGON_AREA = "Click on the map to set the points of the area";
	DRONE = "Drone";
	DRONES = "Drones";
	DRONES_LOADED = "Drones loaded";
	DRONES_LOADING = "Loading drones";
	DISMISS = "Dismiss";
	DL_NO_INFORMATION = "Selected mission doesn't have downloadable information";
	EDIT = "Edit";
	EDITED = "Edited";
	EDITING = "Editing";
	EDITING_AREA = "Editing area. Click anywhere to save changes.";
	EDIT_AGENTS = "Resource configuration";
	EDIT_AGENTS_PERMISSION = "This permission allows editing values of resources and assign them to incidents";
	EDIT_FILE = "Edit file";
	EDIT_ICON = "Edit icon";
	EDIT_MISSION = "Create Incident";
	EDIT_MISSION_PERMISSION = "This permission allows the user to add new incidents";
	EDIT_OFV_TOOLS = "Edit OFV tools";
	EDIT_POIAREA = "Edit POIs and Areas";
	EDIT_POIAREA_PERMISSION = "This permission allows the user to add and edit POIs and Areas on a incident";
	EDIT_PREPLANNED = "Edit preplanned";
	EDIT_PREPLANNED_PERMISSION = "This permission allows the user to add; edit and delete pre-planned POIs";
	EDIT_TYPE = "Edit Types";
	EDIT_TYPE_PERMISSION = "With this permission the user can acces to the configuration tab and edit the different types";
	EMAIL = "Email";
	EMAIL_UNSPECIFIED = "Email unspecified";
	EMPTY = "Empty";
	ENVIRONMENTAL_RISK = "Risk to environment";
	ENVIRONMENTAL_RISK_MEASURES = "Risk to environment measures";
	ENVIRONMENT_UNSPECIFIED = "Environment risk details unspecified";
	ERROR_PROMPT = "Error. The server returned the following message: \n";
	EVACUATION = "Evacuation";
	EVACUATION_FORM_SHORT = "Evacuation Form";
	EF = "EF";
	EVENT = "Incident";
	EVENT_2 = "Event";
	EVENTS = "Incidents";
	EVENT_TYPE = "Incident type";
	EVENT_TYPES = "Incident types";
	ESCALATING = "Escalating";
	EXIT = "exit";
	EXISTING_CONTROL_MEASURES = "Existing control measures";
	EXPECTED_RESULT = "Expected result";
	EXPECTED_RESULTS = "Expected results";
	FAQ_MENU = "FAQ";
	FEED = "Feed";
	FILES = "Files";
	FILE_TO_LARGE = "File too large";
	FILE_TO_LARGE_MESSAGE = "Choose a file that is smaller than 100 MB";
	FIRE = "Fire";
	FIREFIGHTERS = "Firefighters";
	FILE_UNSUPPORTED = "File unsupported. ";
	FILE_UNSUPPORTED_2 = "Unsupported file";
	FILE_ATTACHED = "File attached";
	FILE_TYPES = "(JPG, JPEG, SVG or PNG)";
	FILE_TYPES_OVERLAY = "(JPG, JPEG, SVG, PNG or PDF)";
	FILE_TYPES_POI = "(JPG, JPEG, PNG, MP4 or PDF)";
	FILE_MAX_LIMIT_10MB = "File max limit: 10MB";
	FILTERS = "Filters";
	FILTERS_SHOW_PRIORITISED = "Show only prioritised";
	FILTER_BY = "Filter by";
	FILL = "Fill";
	FILLED_BY = "Filled by";
	FILL_REQUIRED_FIELDS = "To save fill: ";
	FINISH = "Finish";
	FINISHED = "Finished";
	FINISH_EDITING = "Finish editing";
	FIRE_ORIGIN = "Fire origin";
	FIRE_SERVICE = "Fire Service";
	FLAT = "Flat";
	FLOOD = "Flood";
	FLOOR = "Floor";
	FLOOR_UP = "Floor up";
	FOR = "for";
	FORM_CREATED = "Form Created";
	FORM_USED = "Form is being used";
	FORMS = "Forms";
	FULL_EVACUATION = "Full Evacuation";
	FSG_ADD_FIRE = "Add fire location";
	FSG_ADD_FLAT = "Add flat row below";
	FSG_ADD_ROW_ABOVE = "Add flat row above";
	FSG_ADD_ROW = "Add flat row";
	FSG_ADVICE = "FSG Advice";
	FSG_BRIDGE_SECTOR = "Bridgehead location";
	FSG_CASUALTY_STATUS = "Casualty status";
	FSG_CLEAR = "Clear";
	FSG_CLEARED = "Cleared";
	FSG_CONDITIONS = "Conditions";
	FSG_COMMENTS = "Comments";
	FSG_CREATION = {
		NAME: "Evacuation Form Name",
		FLOORS: "Nº of Floors",
		FLATS_PER_FLOOR: "Nº of Flats",
		FIRE_ORIGIN: "Add Fire Origin",
		FLATS: "Flats"
	};
	FSG_DELETE = "Delete Row";
	FSG_DELETE_FSG = "Delete Form";
	FSG_DELETED = "Evacuation Form has been deleted.";
	FSG_DELETE_MODAL_MESSAGE = "Are you sure you want to delete the selected row?";
	FSG_DELETE_ERROR = "Delete Error";
	FSG_DELETE_DESCR = "The selected form can’t be deleted since it contains data";
	FSG_DELETE_ROW_DESCR = "The selected row can’t be deleted since it contains data";
	FSG_DELETE_ROW = "Delete flat row";
	DOWNLOAD_FORM = "Download form";
	DOWNLOAD_EF_INFO = "Blank or current state";
	FSG_DOWNLOAD_EMPTY = "Blank";
	FSG_DOWNLOAD_FULL = "Log History";
	FSG_FIRE_SECTOR = "Fire sector";
	FSG_FIRE_LOCATION = "Fire location";
	FSG_FLAT = "Flat";
	FSG_FLAT_NUMBER = "Flat Nº";
	FSG_FLOOR = "Floor";
	FSG_FLOOR_NUMBER = "Floor Nº";
	FSG_FLOOR_UPDATED = "Floor Number has been updated";
	FSG_HISTORY = "History";
	FSG_LOBBY_SECTOR = "Lobby sector";
	FSG_LOCATION_FORM = "EF Location";
	FSG_LOCATION_TITLE = "Location";
	FSG_PEOPLE = "People";
	FSG_PRIORITY = "Priority";
	FSG_SAVED = "New Evacuation Form created";
	FSG_SEARCH_SECTOR = "Search sector";
	FSG_SECTOR_INFO = "Sector Info";
	FSG_SELECT_ADDRESS = "Select address";
	FSG_SELECT_TEXT = "Select one option";
	FSG_SELECT_MORE_TEXT = "Select one or more options";
	FSG_SOI = "Source of info";
	FSG_SOURCE = "Source";
	FSG_TITLE = "Evacuation Form";
	FSG_SHORT_TITLE = "EF";
	FSG_UNCLEAR = "Unclear";
	FSG_UPDATED = "updated";
	FSG_UPDATE_SECT = "Update Sector";
	FROM = "From";
	GENERATING_FSG_BLANK_DOCUMENTATION = "Generating blank Evacuation Form template";
	GENERATING_FSG_DOCUMENTATION = "Generating current Evacuation Form log";
	OFFLINE_GO_BACK = "You can go back to the main page and access another part of IRIS";
	GO_TO = "Go to";
	GPS_DEVICE = "GPS device";
	GPS_DEVICES = "GPS devices";
	GPS_ID = "GPS Identifier";
	GPS_TRACK = "GPS track";
	GOALS = "Goals";
	GOTO_MISSION = "Go to incident";
	HELP_CENTER = "Help Center";
	JESIP_DEBRIEF_COLOCATE_1 = "Were commanders easily identifiable?";
	JESIP_DEBRIEF_COLOCATE_2 = "What command structures were in place?";
	JESIP_DEBRIEF_COLOCATE_3 = "Did commanders meet face to face?";
	JESIP_DEBRIEF_COLOCATE_4 = "Was a Forward Command Post (FCP) established?";
	JESIP_DEBRIEF_COLOCATE_5 = "Did commanders have timely on-scene briefings?";
	JESIP_DEBRIEF_COMMUNICATE_1 = "Was common terminology used?";
	JESIP_DEBRIEF_COMMUNICATE_2 = "Was an Airwave interoperability talk group used?";
	JESIP_DEBRIEF_COMMUNICATE_3 = "Was relevant information shared across all services and control rooms throughout the incident?";
	JESIP_DEBRIEF_COMMUNICATE_4 = "Was METHANE used to pass information to control?";
	JESIP_DEBRIEF_COMMUNICATE_5 = "Were effective communications established?";
	JESIP_DEBRIEF_COORDINATE_1 = "Did Commanders use the JDM as single decision model?";
	JESIP_DEBRIEF_COORDINATE_2 = "Were Capabilities & Responsibilities identified?";
	JESIP_DEBRIEF_COORDINATE_3 = "Were joint decisions on priorities made and if so, how were the priorities arrived at and agreed?";
	JESIP_DEBRIEF_COORDINATE_4 = "Were actions joined up and therefore efficient and effective?";
	JESIP_DEBRIEF_COORDINATE_5 = "Were ALL on scene resources used appropriately?";
	JESIP_DEBRIEF_COORDINATE_6 = "Was there an understanding of the capability, capacity and limitations of each other’s assets?";
	JESIP_DEBRIEF_COORDINATE_7 = "Did someone take the lead co-ordinators role during Multi-Agency meetings?";
	JESIP_DEBRIEF_JOINTLY_1 = "Were threats and hazards identified, understood and treated different by each emergency service?";
	JESIP_DEBRIEF_JOINTLY_2 = "Were limitations and capabilities of people and equipment identified?";
	JESIP_DEBRIEF_JOINTLY_3 = "Was a joint understanding of risk achieved by sharing information about the likelihood and potential impacts of threats and hazards?";
	JESIP_DEBRIEF_SHARED_1 = "Did Commanders have a common understanding of what has happened, what is happening now and the consequences of events?";
	JESIP_DEBRIEF_SHARED_2 = "Did each of the emergency services understand their roles in resolving the emergency?";
	JESIP_DEBRIEF_SHARED_3 = "Was M/ETHANE regularly used to provide a Common Operating Picture (COP)?";
	JESIP_DEBRIEF_SHARED_4 = "What is happening? What are the impacts and risks? What might happen and what is being done about it?";
	JESIP_DEBRIEF_SHARED_5 = "What end state is desired? What is the aim and objective of the emergency response? What priorities will inform and guide direction?";
	JESIP_DEBRIEF_SHARED_6 = "Were actions decided? What needed to be done to achieve a positive end state?";

	JESIP_DEBRIEF_SHARED_SUBHEADER = "Was the Joint Decision Model utilised identifying:";
	JESIP_DEBRIEF_SUBPAGE_TITLE_COLOCATE = "Co-locate";
	JESIP_DEBRIEF_SUBPAGE_TITLE_COMMUNICATE = "Communicate";
	JESIP_DEBRIEF_SUBPAGE_TITLE_COORDINATE = "Co-ordinate";
	JESIP_DEBRIEF_SUBPAGE_TITLE_JOINTLY = "Jointly understand risk";
	JESIP_DEBRIEF_SUBPAGE_TITLE_SHARED = "Shared situational awareness";
	JESIP_VOID_DECISION_CARD_BODY = "Click Add New to share real-time incident information, as well as the events that are recorded by the support assistance.";
	JESIP_VOID_METHANE_CARD_BODY = "Click Create to start passing incident information between services and their control rooms.";
	JESIP_VOID_IIMARCH_CARD_BODY = "Click Create to start briefing the incident information.";
	JESIP_VOID_JDM_CARD_BODY = "Click Create to bring together the available information, and make effective decisions.";
	JESIP_VOID_DEBRIEF_CARD_BODY = "Click Create to help capture all the learnings relevant to JESIP and interoperability.";
	JESIP_VOID_FSG_CARD_BODY = "Click Create to keep track of an evacuation process. This form can be created before or during an incident.";
	JESIP_VOID_CHECKLIST_CARD_BODY = "Click Create to keep track of all the JESIP first steps so you don’t forget any task.";
	JESIP_UNSAVED_CHANGES_POPUP_BODY = "Save to update these changes into the history section";
	HAZARD = "Hazard";
	HAZARD_UNSPECIFIED = "Hazard type not specified";
	HIDE = "Hide";
	HIDE_LABEL = "Hide label";
	HIGH = "High";
	HIGHEST_RISK = "Highest Risk";
	HIGHLY_LIKELY = "Highly likely";
	HISTORIC_LOG = "Historic log";
	HOME = "Home";
	HOME_MENU = "Incident Homepage";
	IC = "I.C";
	HOMEPAGE = "Homepage";
	ICON = "Icon";
	ICON_PATH = "Icon path";
	ICS_LOADING = "Loading ICS data";
	ICS_LOADED = "ICS Data loaded";
	IC_SIGNATURE = "I.C Signature";
	ID = "Identifier";
	IMS_NAME_INPUT_PLACEHOLDER = "A-Z; a-z; 0-9 without spaces allowed";
	IMS_NAME_ON_USE = "The specified camera ID is already on use";
	INCIDENT_COMMANDER = "Incident Commander";
	INCIDENT_COMMANDER_SIGNATURE = "Incident Commander signature";
	INCIDENT_DATE = "Incident date";
	INCIDENT_ID = "Incident ID:";
	INCIDENT_NUM = "Incident Number";
	INCIDENT_OPEN_TIME = "Incident Open Time";
	INCIDENT_CLOSE_TIME = "Incident Close Time";
	INCIDENT_CHECKS = "Incident Checks";
	INITIAL = "Initial";
	INITIAL_STATUS = "Initial status";
	INITIAL_STATUS_ALERT = "There can be only one status at any time";
	INPUT_REQUIRED = "Fill the required inputs before continuing.";
	INTERVENTIONS = "Interventions";
	INVALID_CAPTCHA = "Incorrect captcha. Try again to log in.";
	INVALID_ICON = "The provided icon is not valid. The icon must be a single path.";
	INVALID_PASSWORD = "Invalid password";
	INVALID_TOKEN = "There has been a problem with your session. Ask the Admin to fix it.";
	INVALID_URL = "The provided RTMP URL is not valid. No key value";
	IRIS_SETTINGS = "Iris Settings";
	IS_INITIAL = "Marks whether the status is the default one";
	JESIPCHECKLISTITEMS = {
		ITEM1: "Request multi-agency talk group.",
		ITEM2: "Confirm talk group allocated.",
		ITEM3: "Confirm arrival on scene.",
		ITEM4: "Put on appropriate tabard.",
		ITEM5: "Provide METHANE message.",
		ITEM6: "Identify commanders from other services and co-locate.",
		ITEM7: "Establish Forward Command Post (FCP).",
		ITEM8: "Commence log.",
		ITEM9: "Brief other members of your command team."
	};
	KEY = "Key";
	KML_FILES = "KML Files";
	KML_FILE = "KML File";
	KML_INFORMATION = "KML Information";
	KML_LOADING = "Loading KML data";
	KML_FILE_INVALID = "Invalid file format. Only kml and kmz formats are accepted.";
	KML_FEATURE_INVALID = "This KML/KMZ contains unsupported features.";
	LAST_UPDATED = "Last update:";
	LAST_INCIDENT_CHECK = "Last incident check";
	LEAVE_PAGE_POPUP_TEXT = "Are you sure you want to leave this page?";
	LEAVE_PAGE_POPUP_BUTTON = "Leave page";
	LEVEL = "User level";
	LEVEL_NAME_TAKEN = "Permission level name taken. Try another.";
	LEVELS = "Permission levels";
	LEVELS2 = "Levels";
	LIKELIHOOD = "Likelihood";
	LIKELIHOOD_L = "Likelihood (L)";
	LIKELIHOOD_RATING = "Likelihood rating";
	LIKEHOOD = "Likehood";
	LINE = "Line";
	LIVE_INCIDENTS = "Incidents";
	LIVE_INCIDENTS_OFV = "Live Incidents";
	LOADING_FINISHED = "Loading finished";
	LOCATION = "Enter address";
	LOCATION2 = "Location";
	LOGGED = "Logged";
	LOGGED_AS = "Logged as";
	LOG_FORMS = "Decision Log Records";
	LOGOUT = "Logout";
	LOW = "Low";
	MAIN = "Main";
	MARGINAL = "Marginal";
	MAP_VIEW = "Map view";
	MAP_VIEW_DETAILS = "(Terrain, Satellite)";
	MAP_OBJECTS_LOADING = "Loading map objects";
	MAP_OBJECTS_LOADED = "Map objects loaded";
	MAP_LOADING = "Loading map";
	MAP_LOADED = "Map loaded";
	MEASUREMENTS_TAKEN = "Measurements taken";
	ME = "(me)";
	MEDIUM = "Medium";
	MESH = "Mesh";
	MESSAGE = "Message";
	MICROSOFT = "Microsoft login not available.";
	MILESTONE_ACCESS = "Milestone access";
	MILESTONE_CAMS = "Milestone cameras";
	MILESTONE_USER = "Milestone user";
	MILESTONE_URL = "Milestone URL";
	MILESTONE_PASSWORD = "Milestone password";
	MISSING_CAPTCHA = "reCaptcha value must be validated";
	MISSION = "Incident";
	MISSION_CLOSED = "Incident closed";
	MISSION_CREATED_ON = "Incident created on";
	MISSION_INFORMATION = "Incident information";
	MISSION_INFORMATION_INSTRUCTION = "Write the incident name and select its type.";
	MISSION_PERMISSIONS = "Incident permissions";
	MISSIONS_LOADED = "Incidents loaded";
	MISSIONS_LOADING = "Loading incidents";
	MISSION_NAME = "Incident name";
	MISSION_NAME_REQUIRED = "Fill incident name field to continue";
	MISSION_NAME_ON_USE = "Incident name already on use";
	MISSION_TYPE_REQUIRED = "Select a incident type to continue";
	MY_RESOURCES = "My resources";
	MOBILE = "Mobile";
	MONTHS_ARRAY = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
	MORE = "more";
	MOVE = "Move";
	MOVING_FSG_PROMPT = "Drag the EF marker to set its new location";
	NAME = "Name";
	NAME_ICON_ERROR = "You need to introduce a name and an icon to save";
	NAMING_CONVENTION = "Flat naming convention";
	NAME_IN_USE = "Name in use";
	NEW = "New";
	NEW_ARA = "New ARA";
	NEW_FSG = "New Evacuation Form";
	NEW_CONTROL_MEASURE = "New control measure";
	NEW_EVENT = "New incident";
	NEW_EVENT_SELECT_AREA = "Draw the operational area for the incident";
	NEW_FLOOR = "New floor";
	NEW_PLANE = "New overlay";
	NEW_TYPE = "NEW TYPE";
	NEW_USER = "New user";
	NO = "No";
	NO_ARAS = "There are no ARAs for this incident.";
	NO_ARAS_YET = "There are no ARAs for this incident yet.";
	NO_AVAILABLE_RESOURCES = "There are no available resources right now.";
	NO_CAMERA = "No camera assigned";
	NO_COMMAND_STRUCTURE = "No command structure found";
	NO_CREDENTIALS = "Please provide a username and password.";
	NO_MISSION = "No incident";
	NO_MISSION_SELECTED = "Please select a incident to continue.";
	NO_MORE_CHANGES = "No more changes";
	NO_TELEMETRY = "Unable to get telemetry.";
	NO_HAZARD = "No significant hazard";
	NONE = "None";
	NO_HAZARDS = "No hazards with this risk";
	NO_RESULTS = "No results found";
	NO_RESULTS_INFO = "Try adjusting your search";
	NO_SECTOR = "No sector";
	NO_STREAM = "No stream found";
	NO_TASKS = "There are no tasks for this incident.";
	NO_TASKS_YET = "There are no tasks for this incident yet.";
	NOT_FOUND = "Not found";
	NEXT = "Next";
	NEW_PASSWORD = "New password";
	NEW_SUPPORT = "New support";
	NEW_SECTOR = "New sector";
	NUMBER = "Number";
	NUMBER_ABBR = "No.";
	NUMERIC = "Numeric";
	NUMBER_IN_USE = "Number in use";
	OBSERVATION = "Observation";
	OF = "of";
	OFFENSIVE = "Offensive";
	ORGANIZATION = "Organisation";
	OVERLAY_INFORMATION_AVAILABLE = "Overlay information available";
	OVERVIEW = "Overview";
	ON_SCENE = "On scene";
	OFFLINE_MODE_ENABLED = "Offline mode enabled";
	ONLINE_MODE_ENABLED = "Online mode enabled";
	ONLY_ONE_INITIAL = "There can be only one initial status at any time";
	OPEN_EVENTS = "Current incidents";
	OPENING_INCIDENT = "Opening Incident";
	OPERATIONAL = "Operational";
	OPTIONS = "Options";
	OFFLINE_MODE = "OFFLINE MODE";
	OFV_TOOLS = "OFV tools";
	OTHER_AGENCIES = "Other Agencies";
	OUTSIDE_AREA = "Out of incident area";
	PRIVATEFORM = "Private Form";
	PAGE = "Page";
	PASSWORD = "Password";
	PASSWORD_CONFIRM = "Confirm password";
	PASSWORD_MISMATCH = "The passwords do not match";
	PASSWORD_NOT_ALLOWED = "The password must contain a minimum of 8 characters, at least 1 lowercase, 1 uppercase, 1 number. It cannot contain the username.";
	PASSWORD_CRITERIA = "The password must meet the following criteria:";
	PASSWORD_CRITERIA_MIN = "Use a minimum of 8 characters.";
	PASSWORD_CRITERIA_LETTERS = "Include at least two of these: lowercase letters, uppercase letters, numbers, and symbols.";
	PASSWORD_CRITERIA_USERNAME = "Avoid using your username.";
	PASSWORD_CRITERIA_SAFE = "Stay away from easily guessable number sequences like 1234.";
	PASSWORD_REQUIREMENTS = "The password does not meet the security requirements";
	PATTERN = "Pattern";
	PER_BUILDING = "per building";
	PER_FLOOR = "per floor";
	PERMISSIONS = "Permissions";
	PERMISSION_ACCESS_FULL = "Full access";
	PERMISSION_ACCESS_NO = "No access";
	PERMISSION_ACCESS_PARTIAL = "Partial access";
	PERMISSION_ACCESS_VIEW_ONLY = "View only";
	PERMISSION_GROUP_ARA = "ARA";
	PERMISSION_GROUP_CLOSED = "Homepage - Closed Incidents";
	PERMISSION_GROUP_IRIS_SETTINGS = "IRIS Settings";
	PERMISSION_GROUP_LIVE = "Homepage - Live Incidents";
	PERMISSION_GROUP_MEDIA = "Download media";
	PERMISSION_GROUP_OFV_TOOLS = "OFVs tools";
	PERMISSION_GROUP_RESOURCES_CONFIG = " Resources Configuration";
	PERMISSION_GROUP_TIMELINE = "Live Incident Timeline";
	PERMISSION_GROUP_TASK_MANAGER = "Task Manager";
	PERMISSION_GROUP_USER_CONFIG = "User Configuration";
	PERMISSION_INFO_ADMIN = "Ultimate platform control. Pre, live, and post-incident management.";
	PERMISSION_INFO_COMMANDER = "Full access, excluding user management.";
	PERMISSION_INFO_CREW = "Restricted incident access with limitations on forms editing.";
	PERMISSION_INFO_GUEST = "People outside the organisation with restricted  access to live incidents.";
	PERMISSION_INFO_SUPPORT = "Members of other agencies with limited collaboration permissions.";
	PERMISSION_INSTRUCTIONS = "Check the boxes and switch the toggles to choose the actions that can be performed with this Permission level:";
	PERMISSION_LEVEL = "Permission Level";
	PERMISSION_LEVEL_NAME = "Permission level name";
	PERMISSION_DENIED = "Permission denied";
	PERSONAL = "Personnel";
	PERSONNEL = "Personnel";
	PERSONNEL_SELECTED = "Personnel selected";
	PERSONNEL_SELECTED_REMOVE_PROMPT = "To remove proceed to the personnel setup screen.";
	PLANE = "Overlay";
	PLANES = "Overlays";
	PREFERENCES_UPDATED = "Preferences updated";
	PHASE_EVACUATION = "Phase Evacuation";
	PHONE_NUMBER_COPIED = "Phone number copied";
	ORIGIN = "Origin:";
	OVERLAY_SAVED = "Overlay saved";
	OVERLAY_SAVED_NEW = "New Overlay created";
	OVERLAY_DELETED = "Overlay deleted";
	PLAYBACK_LOADING = "Playback is loading; please wait.";
	PLAYBACK_INFO = "Play it on your usual player or download VLC Video Player.";
	PLAYBACK_ISSUE = "The video can’t be played in the browser";
	POI = "Point of interest";
	POI_1 = "POI";
	POIS = "Points of interest";
	POI_ADDED = "New POI added";
	POI_TYPE = "Point of interest type";
	POI_DELETED = "Point of interest deleted";
	POI_DELETED_HEADER = "POI Deleted";
	POI_DELETED_BODY = "Point Of Interest has been deleted";
	POI_UPDATED = "Point of interest updated";
	POI_SAVED = "New POI added";
	POI_STATUS_PREDICT = "Predict";
	POI_STATUS_CURRENT = "Current";
	POI_STATUS_OVER = "Over";
	POLICE = "Police";
	POSITION_TRACK = "Track GPS position. Can be manually changed";
	EF_PREFERENCES = "EF Preferences";
	PREFERENCE_INFO = "Change name and flat naming convention";
	PREPLANNED = "Preplanned";
	PREPLANNED_DESCRIPTION = "Points of this type will appear in every incident";
	PREPLANNED_POI = "Pre-planned POI";
	PROBABLE = "Probable";
	PROGRESS = "Progress";
	PENDING = "Pending";
	RADIUS = "Radius";
	RATIONALE = "Rationale";
	REASONABLY = "Reasonably";
	REASSIGN = "Reassign";
	RECOMMENDATION = "Recommendation";
	RECOMMENDATION_NOT_AVAILABLE = "No recommendations available yet.";
	RECOMMENDATION_HELP_TOOLTIP = "After saving or updating an ARA, IRIS might offer a recommendation on which resources to deploy depending on their availability";
	RECOMMENDATION_PERSONNEL_TOOLTIP = "Total number of personnel recommended for this incident";
	RECOMMENDATION_APPLIANCES_TOOLTIP = "Total number of appliances recommended for this incident";
	RECOMMENDED_RESOURCES = "Recommended Resources";
	RELOAD = "Reload";
	RECORD = "Record";
	REMOTE = "Remote";
	REMOVE = "Remove";
	REMOVE_ERROR = "Unable to remove the object because it is already being used.";
	REMOVE_FLOOR = "Remove floor";
	RENAME = "Rename";
	REOPEN = "Reopen";
	REOPEN_MISSION = "Reopen Incident";
	REOPEN_MISSION_PROMPT = "You are going to reopen this incident. Would you like to continue?";
	REQUIRED = "required";
	REQUIRED_FEMININE = "required";
	RESERVED_KEYWORD = "That keyword is reserved.";
	RESIZE = "Resize";
	RESOURCE = "Resource";
	RESOURCES = "Resources";
	RESOURCES_CONFIGURATION = "Resources configuration";
	RESOURCE_ASSIGNMENT = "Resource assignment";
	RESOURCE_ASSIGNMENT_DESCRIPTION = "Select the resources that are to be deployed in the incident. Can be changed any time.";
	RESOURCE_LOG = "Resource log";
	RESTRICTED = "Restricted";
	REVIEW = "Review";
	REVIEWED = "Reviewed";
	REVIEW_NOM = "Review";
	REQUESTED = "Requested";
	REQ = "Req.";
	REQUESTED_CONTROL_MEASURES = "Requested control measures";
	RISK = "Risk";
	RISK_INFORMATION = "Risk information";
	RISK_INFORMATION_COMPULSORY = "At least one Risk per scenario";
	RISK_INFORMATION_SELECT = "Select Risk Information";
	RISK_INFORMATION_CHIPS = "Select the risk information that best applies to the scenario";
	RISK_TO_ENVIRONMENT_DEATILS = "Indicate the measurements taken to mitigate the risk to the environment during the incident";
	WHO_IS_AT_RISK = "Who is at risk";
	RISKS = "Risks";
	RTMP_CAMS = "RTMP cameras";
	RTMP_SERVER = "RTMP Server";
	ROADS = "Roads";
	ROADMAP = "Roadmap";
	ROLE = "Role";
	ROOFTOP = "Rooftop";
	RULER_TOOL_PROMPT = "Click on the map to know the distance in meters";
	SATELLITE = "Satellite";
	SAVE = "Save";
	SAVE_ERROR = "The object couldn't be saved.";
	SAVE_ERROR_MESSAGE = "Try again or contact";
	HAS_BEEN_SAVED = "has been saved.";
	SAVED = "saved";
	SCALE_DOWN = "Scale down";
	SCENARIO = "Scenario";
	SCENARIO_UNSPECIFIED = "Scenario not specified";
	SCENARIOS = "Scenarios";
	SEARCH = "Search";
	SEARCH_AREA = "Search Area type";
	SEARCH_POI = "Search all POIs";
	SEARCH_LOCATION = "Incident Address";
	SEARCH_TYPE = "Search type";
	SECTOR = "Sector";
	SECTOR_UNSPECIFIED = "Sector unspecified";
	SECTORS = "Sectors";
	SECTOR_SELECT_AREA = "Click an area marker to attach the new sector; or press Cancel to go back to the command structure screen";
	SECTOR_INFO = "Sectorisation information";
	SEE_ALL = "See All";
	SEGMENT = "Segment";
	SEGMENTS = "Segments";
	SELECT = "Select";
	SELECT_ALL = "Select All";
	SELECT_COMMANDER = "Set the position of the incident commander by clicking on the map. It can be changed anytime.";
	SELECT_FILE = "Select file";
	SELECT_LAYER_PROMPT = "Select the layers to display";
	SELECT_LAYER = "Select the layers to display";
	SELECT_LEVEL = "Select a level/role";
	SELECT_LIKELIHOOD = "Select Likelihood";
	SELECT_SEVERITY = "Select Severity";
	SELECT_RISK = "Select Risk";
	SELECT_RISK_WHOIS = "Select who is at Risk";
	SELECT_MISSION = "SELECT INCIDENT";
	SELECT_MISSION_TYPE = "Select the type of incident";
	SELECT_TYPE = "Select type";
	SELECT_SECTOR = "Select Sector";
	SELECT_SCENARIO = "Select Scenario";
	SELECT_KML = "Select the KML/KMZ file";
	SERIOUS = "Serious";
	SERVER_ERROR_MESSAGE_1 = "Unexpected error. Changes made will not be saved";
	SERVER_ERROR_MESSAGE_2 = "Please try refreshing or contact";
	SERVER_ERROR_MESSAGE_3 = "if this error persists";
	SESSION_SYNC = "Your session will be synced automatically when you reconnect.";
	SET_ADDRESS = "Update";
	SET_OVERLAY_LOCATION = "Drag and click save to set the Overlay location";
	SMELL_OF_BURNING = "Burning smell";
	VERY_SERIOUS = "Very serious";
	SEVERITY = "Severity";
	SEVERITY_S = "Severity (S)";
	SEVERITY_UNSPECIFIED = "Severity rating unspecified";
	SEVERITY_RATING = "Severity Rating";
	SHAPE = "Shape";
	SHARE_LOCATION = "Share location";
	SHARE_URL = "Copy this URL to share=";
	SHOW_IN_MAP = "Show in map";
	SHOW_LABEL = "Show label";
	SHOW_SOCIAL_FEED = "Show social feed";
	SHOW_VIDEO_FEED = "Show video feed";
	SHOW_TRACK = "Show track";
	SIGNAL_LOST = "The connection has been lost.";
	SIGN = "Sign";
	SIGN_BELLOW = "Sign below";
	SIGN_ARA = "Sign";
	SIGNATURE = "Signature";
	SIGNATURE_UNSPECIFIED = "Signature required";
	SIGNATURE_MISSING = "Signature missing"; //modified in August 2022
	SIGNATURE_PENDING = "Signature pending";
	SIGNED = "Signed";
	SIGNED_BY = "Signed by";
	SIZE = "Size";
	SITUATION = "Situation";
	SKILLS = "Skills";
	SKIP = "Skip";
	S_L = "S+L";
	SPEECH_ERROR = "Error detecting voice; remember to set the language setting";
	SPEECH_NOT_SUPPORTED = "Speech recognition not supported on current browser. Using Google Chrome v33+ is recommended.";
	STATUS = "Status";
	STATE = "State";
	STATUS_NAME_ON_USE = "Status name on use";
	STATION = "Station";
	STATION_UNSPECIFIED = "Station";
	STREAM_CONNECTION_LOST = "Stream connection lost";
	STREAM_FINISHED = "Stream finished";
	STREAM_NOT_FOUND = "Stream not found. Click to retry.";
	SUPPORT = "Support";
	SUPPORT_PHONE_NUMBER = "Support phone number";
	SUPPORT_SECTOR = "Support Sector";
	SUPPORT_TEAM_EMAIL = "Support team email";
	SITUATION_BRIEFING = "Situation briefing";
	SMOKE = "Smoke";
	SUBBASEMENT = "Subbasement";
	SWITCH_BUILDING = "Switch Building";
	REQUEST_ERROR = "Request error";
	TACTICAL = "Tactical";
	TACTICAL_MODE = "Tactical mode";
	TACTICS_UNSPECIFIED = "Tactical mode unspecified";
	TAKE_INMEDIATE_ACTION = "Take inmediate action";
	TASK_MANAGER = "Task Manager";
	TASKS = "Tasks";
	TERRAIN = "Terrain";
	TIME = "Time";
	TIME_ARRIVAL = "Time of arrival";
	TIME_LEAVE = "Time of leave";
	TIME_REQUESTED = "Time of request";
	TIME_STAMP = "Time stamp";
	TRANSITIONAL = "No overall mode";
	TOTAL_DISTANCE = "Total distance";
	TO_BE_SIGNED = "To sign";
	TRY_AGAIN = "Try again";
	TYPE = "Type";
	TYPE_IN = "Type in here";
	TYPE_INFORMATION_HERE = "Type information here";
	TYPE_OF_EVENT = "Type of event";
	TITLE = "Title";
	TM_CREATION_TIME = "Task Manager creation time";
	TM_REVIEW_TIME = "Task Manager review time";
	TO = "To";
	UNABLE_TO_DEACTIVATE = "Unable to deactivate";
	UNABLE_TO_ACTIVATE_INFO = "This Evacuation Form is active in incident";
	UNABLE_TO_DEACTIVATE_INFO = "can not be deactivated since it contains data";
	UNABLE_TO_DELETE = "Unable to delete";
	UNABLE_TO_DOWNLOAD = "Unable to download";
	UNABLE_TO_GENERATE = "Unable to generate";
	UNAVAILABLE = "Unavailable";
	UNASSIGNED = "Unassigned";
	UNASSIGNED_SECTOR = "Unassigned sector";
	UNASSIGNED_USER_HEADER = "Unassigned user";
	UNASSIGNED_USER_BODY = "You have been unassigned from this incident";
	UNCHECK = "Uncheck";
	UNCHECKED = "Unchecked";
	UNCHECK_SINGLE_POPUP_MESSAGE = "You are going to uncheck this box. Would you like to continue?";
	UNCHECK_SINGLE_POPUP_TITLE = "Uncheck box";
	UNCHECK_ALL_POPUP_MESSAGE = "You are going to uncheck all the boxes.";
	UNCHECK_ALL_POPUP_TITLE = "Uncheck boxes";
	UNCLEARED = "Uncleared";
	UNSAVED_CHANGES = "Unsaved Changes";
	UNSAVED_INFO = "There are unsaved changes. Discard them to leave the page, or review them to save.";
	UNSPECIFIED = "Unspecified";
	UPDATE = "Update";
	UPDATED = "updated";
	UPDATE_AVAILABLE = "New updates available";
	UPDATE_TELEMETRY = "Update telemetry";
	UNLIKELY = "Unlikely";
	UPLOAD = "Upload";
	UPLOAD_IMAGE_INSTRUCTION = "Upload an overlay or a file (JPG, PNG, SVG or PDF)";
	URL_RTSP_ORIGIN = "RTSP url or empty if RTMP";
	URL_RTMP_ORIGIN = "RTMP or similar origin URL";
	URL_TOOLTIP1 = "The address where the video is being streamed.";
	URL_TOOLTIP2 = "Top one is the RTSP stream which is optional.";
	URL_TOOLTIP3 = "Bottom one is the RTMP which should be specified.";
	USER = "User";
	USER_ACCESS = "User access";
	USER_FILL_UNSPECIFIED = "Signing user unspecified";
	USER_AND_PERMISSIONS = "User and permissions";
	USER_LOADED = "User data loaded";
	USER_LOADING = "Loading user data";
	USER_LEVELS = "User levels";
	USER_CONFIGURATION = "User configuration";
	USER_MANAGEMENT = "User management";
	USER_MANAGEMENT_PERMISSION = "This permission allows the user to add; edit and delete the users accounts and roles";
	USER_MANUAL = "End User Manual";
	USER_MISSING_FIELDS = "Cannot create an user without name, password or permission level";
	USER_NAME_IN_USE = "User name in use";
	USER_NAME_TOO_LONG = "User name too long. It must be less than  50 characters";
	USER_RESOURCE = "The user is not a resource";
	USERNAME = "Username";
	USERNAME_TAKEN = "Username taken. Try another";
	USERS = "Users";
	USERS_MENU = "User configuration";
	STAY_PUT = "Stay put";
	VIEW = "View";
	VIEW_ARA = "View ARA";
	VIEW_DL = "View";
	VIEW_LOG = "View log";
	VIDEO_FEED_ERROR = "Unable to connect to video feed.";
	VIDEO_FEEDS_ROW = "Video feeds per row";
	VIEW_ATTACHMENT = "View attached file";
	VIEW_FULL_PROFILE = "View full profile";
	WAITING_ROOM = "Waiting Room";
	WAITING_ROOM_MESSAGE = "You have not been assigned to an incident yet.";
	WAITING_ROOM_RETRY = "Try again to reload the incident or contact your team to get assigned.";
	WARNING = "Warning";
	WHOLE_INCIDENT = "Whole Incident";
	WORK_SECTOR = "Work sector";
	WRONG_CREDENTIALS = "The username or password is incorrect";
	TOKEN_EXPIRED = "The session has expired. Please log in again.";
	YES = "Yes";
	BRIDGEHEAD = "Bridgehead";
	LOBBY = "Lobby";
	BY = "By";
	LAST_UPDATE = "Last Update";
	ADD_NEW = "Add New";
	COMPLETE = "Complete";
	DIRECTION = "Direction";
	ACTION = "Action";
	LOCATION3 = "Location";
	TYPE_OF_INCIDENT = "Incident type";
	DEBRIEF = "Debrief";
	SHARED_FORM = "Shared Form";
	METHANE = "M/ETHANE";
	JESIP_COLOCATE_TOOLTIP = "Co-locate with commanders as soon as practicably possible at a single, safe and easily identified location near to the scene.";
	JESIP_COMMUNICATE_TOOLTIP = "Communicate clearly using plain english.";
	JESIP_COORDINATE_TOOLTIP = "Co-ordinate by agreeing the lead service. Indentify priorities, resources and capabilities for an effective response, including the timing of further meetings.";
	JESIP_JOINTLY_TOOLTIP = "Jointly understand risk by sharing information about the likelihood and potential impact of threats and hazards to agree potential control measures";
	JESIP_SHARED_TOOLTIP = "Shared situational awareness established by using METHANE and the Joint Decision Model.";
	INCIDENT_INFORMATION = "Incident Information";
	GENERAL_INFORMATION = "General Information";
	DEBRIEF_TEAM_INFORMATION = "Debrief Team Information";
	ADD_TEAM_MEMBER = "Add Team Member";
	ADD_COMMANDER = "Add Commander";
	COMMANDER_INFO = "Commanders Information";
	FIRST_CALLER_INFO = "First Caller Information"; //jesip > methane
	HISTORY = "History";
	NOTES = "Notes";
	JESIP_METHANE_ITEMS_TITLE_0 = "Major Incident";
	JESIP_METHANE_ITEMS_TITLE_1 = "Exact Location";
	JESIP_METHANE_ITEMS_TITLE_2 = "Type of incident";
	JESIP_METHANE_ITEMS_TITLE_3 = "Hazards";
	JESIP_METHANE_ITEMS_TITLE_4 = "Access";
	JESIP_METHANE_ITEMS_TITLE_5 = "Number of casualties";
	JESIP_METHANE_ITEMS_TITLE_6 = "Emergency Services";
	JESIP_METHANE_ITEMS_DESCRIPTION_0 = "Has a Major Incident been declared? (If no, then complete ETHANE message)";
	JESIP_METHANE_ITEMS_DESCRIPTION_1 = "What is the exact location or geographical area of incident?";
	JESIP_METHANE_ITEMS_DESCRIPTION_2 = "What kind of incident is it?";
	JESIP_METHANE_ITEMS_DESCRIPTION_3 = "What hazards or potential hazards can be identified?";
	JESIP_METHANE_ITEMS_DESCRIPTION_4 = "What are the best routes for access and egress?";
	JESIP_METHANE_ITEMS_DESCRIPTION_5 = "How many casualties are there and what condition are they in?";
	JESIP_METHANE_ITEMS_DESCRIPTION_6 = "Which and how many emergency responder assets/personnel are required or are present?";
	SAVE_TO_HISTORY = "Save to history";
	METHANE_SAVED = "M/ETHANE SAVED";
	IIMARCH_SAVED = "IIMARCH SAVED";
	IIMARCH = "IIMARCH";
	JESIP_IIMARCH_ITEMS_TITLE_0 = "Information";
	JESIP_IIMARCH_ITEMS_TITLE_1 = "Intent";
	JESIP_IIMARCH_ITEMS_TITLE_2 = "Method";
	JESIP_IIMARCH_ITEMS_TITLE_3 = "Administration";
	JESIP_IIMARCH_ITEMS_TITLE_4 = "Risk Assessment";
	JESIP_IIMARCH_ITEMS_TITLE_5 = "Communications";
	JESIP_IIMARCH_ITEMS_TITLE_6 = "Humanitarian Issues";
	JESIP_IIMARCH_ITEMS_DESCRIPTION_0 = "What, where, when, how, how many, so what, what might?";
	JESIP_IIMARCH_ITEMS_DESCRIPTION_1 = "Why are we here, what are we trying to achieve?";
	JESIP_IIMARCH_ITEMS_DESCRIPTION_2 = "How are we going to do it?";
	JESIP_IIMARCH_ITEMS_DESCRIPTION_3 = "What is required for effective, efficient and safe implementation?";
	JESIP_IIMARCH_ITEMS_DESCRIPTION_4 = "What are the relevant risks, and what measures are required to mitigate them?";
	JESIP_IIMARCH_ITEMS_DESCRIPTION_5 = "How are we going to initiate and maintain communications with all partners and interested parties?";
	JESIP_IIMARCH_ITEMS_DESCRIPTION_6 = "What humanitarian assistance and human rights issues arise or may arise from this event and the response to it?";
	JESIP_JDM = "Joint Decision Model";
	JDM_SAVED = "Joint Decision Model SAVED";
	JESIP_JDM_CIRCLE_TEXT = ["Working Together", "Saving Lives", "Reducing Harm"];
	JESIP_JDM_ITEMS_FULL_TITLE_0 = "Gather information & Inteligence";
	JESIP_JDM_ITEMS_FULL_TITLE_1 = "Assess risks & Develop a working strategy";
	JESIP_JDM_ITEMS_FULL_TITLE_2 = "Consider powers, policies & procedures";
	JESIP_JDM_ITEMS_FULL_TITLE_3 = "Identify options & contigencies";
	JESIP_JDM_ITEMS_FULL_TITLE_4 = "Take action & Review what happened";
	JESIP_JDM_ITEMS_SHORT_TITLE_0 = "Information & Inteligence";
	JESIP_JDM_ITEMS_SHORT_TITLE_1 = "Risks & Working Strategy";
	JESIP_JDM_ITEMS_SHORT_TITLE_2 = "Powers, Policies & Procedures";
	JESIP_JDM_ITEMS_SHORT_TITLE_3 = "Options & Contigencies";
	JESIP_JDM_ITEMS_SHORT_TITLE_4 = "Actions Required";
	JESIP_JDM_ITEMS_DESCRIPTION_0 = "This stage involves gathering and sharing information and intelligence, for example through a METHANE message. The purpose is to achieve a shared situational awareness.";
	JESIP_JDM_ITEMS_DESCRIPTION_1 = "Commanders should ensure they have reviewed and understood all risks so that appropriate control measures can be put in place. They should work together to agree a working strategy which is an action plan to address the immediate situation.";
	JESIP_JDM_ITEMS_DESCRIPTION_2 = "This stage relates to any relevant laws, operating procedures or policies that may impact on the response plan and the capabilities that are available to be deployed.";
	JESIP_JDM_ITEMS_DESCRIPTION_3 = "There will almost always be more than one option to achieve the desired end state. Commanders and responders should all work together to evaluate the options and contingencies.";
	JESIP_JDM_ITEMS_DESCRIPTION_4 = "Building shared situational awareness, setting direction, evaluating options and making decisions all lead to taking the actions that are judged to be the most effective and efficient in resolving an emergency and returning to a new normality.";
	SAVE_NOTES_HERE = "Save your notes here";
	DEBRIEF_TEAM_NAMES = "Debrief team names";
	NAME_OF_CALLER = "Name of caller";
	INCIDENT_LOCATION = "Incident location";
	SPANISH = "Spanish";
	ENGLISH = "English";
	RESOURCE_TYPE = "Resource type is required";
	INCIDENT = "Incident";
	INCIDENT_CLOSED = "Incident Closed";
	PREPARING_DOCUMENTATION = "Preparing Documentation...";
	GENERATING_DOCUMENTATION = "Generating Documentation...";
	GENERATING_FILES = "Generating Files";
	COMPRESSING_DOCUMENTATION = "Compressing Documentation...";
	USER_AND_RESOURCE = "The resource and user";
	DELETE_RESOURCE = "Do you want to also delete the user from IRIS";
	NO_RESULTS_FOUND = "No results found";
	OVERLAY = "Overlay";
	OVERLAY_2 = "Overlay";
	COORDINATES_COPIED = "Coordinates copied";
	SHARE_POI = "Share POI";
	DELETE_POI = "Delete POI";
	CONTROL_MEASURE = "Control Measure";
	SUB_CONTROL_MEASURE = "Sub Control Measure";
	ICON_REQUIRED = "Icon required";
	EMPTY_EF_WARNING = "To create an Evacuation form, you need to set the location on the OFV first";
	SORT = "Sort";
	TO_A = "to";
	TEXT = "text";
	PREDICTED = "Predicted";
	CURRENT = "Current";
	OVER = "Over";
	OR = "or";
	WELCOME_IRIS = "Welcome to IRIS Core";
	LOG_INTO_ACCOUNT = "Log in into your account";
	KEEP_ME_LOGGED = "Keep me logged in";
	LOG_IN = "Log In";
	MISSING_NAME_INFO = "Missing username information";
	MISSING_PASSWORD_INFO = "Missing password information";
	RISK_SCORE_AND_RATING = "Risk Score and Rating";
	RISK_SCORE_AND_RATING_SMALL = "Risk Score & Rating";
	RISK_RATING_MODAL_SELECT_TEXT = "Select the Severity and Likelihood to calculate the Risk Score and Rating";
	RISK_RATING_MODAL_SEVERITY_1 = "1 - Minor Injury: first aid only";
	RISK_RATING_MODAL_SEVERITY_2 = "2 - Harmful: slight injury or ill health under 3 day absence";
	RISK_RATING_MODAL_SEVERITY_3 = "3 - Extremely Harmful: lost time event, over 3 days absence";
	RISK_RATING_MODAL_SEVERITY_4 = "4 - Major Injury: unconsciousness/fracture";
	RISK_RATING_MODAL_SEVERITY_5 = "5 - Death: single or multiple death or high financial loss to the organisation";
	RISK_RATING_MODAL_LIKELIHOOD_1 = "1 - Rare: the situation is well controlled";
	RISK_RATING_MODAL_LIKELIHOOD_2 = "2 - Unlikely: the situation is well controlled but lapses could occur";
	RISK_RATING_MODAL_LIKELIHOOD_3 = "3 - Likely: the situation is not managed and an accident could occur";
	RISK_RATING_MODAL_LIKELIHOOD_4 = "4 - Very Likely: if conditions remain unchanged there is a high probability of an accident";
	RISK_RATING_MODAL_LIKELIHOOD_5 = "5 - Will Occur: an accident will occur";
	RISK_RATING_MODAL_GREEN_DESCRIPTOR = "1-4 Not a significant hazard";
	RISK_RATING_MODAL_YELLOW_DESCRIPTOR = "5 - 10 Consider additional control measures";
	RISK_RATING_MODAL_RED_DESCRIPTOR = "12 - 25 Take immediate action";
	RISK_RATING_TOOLTIP = "Highest Risk Score & Rating from all the Risk Information added to the sector";
	VIEW_EF = "View EF";
	EDIT_EF = "Edit EF";
	DELETE_EF = "Delete EF";
	OUTSIDE_AREA_INFO = (element: string): string => {
		return `The ${element} has been added outside incident area. Expand the area in order to view it.`;
	};
	GENERATING_DOCUMENTATION_PAGE = (idx: number, total: number): string => {
		return "Page " + (idx + 1) + " of " + total;
	};
	DOWNLOADING_DOCUMENTATION_PAGE = (idx: number, total: number): string => {
		return "Page " + (idx + 1) + " of " + total;
	};
}
