import { MAP_ITEM_TYPE } from "src/app/global/constants/enums/map-item-type";
import { MapItem } from "../items/map-item";

export class BufferItem {
	json: string;
	timestamp: number;
	type: MAP_ITEM_TYPE;
	object: MapItem | undefined;

	constructor(json: string, type: MAP_ITEM_TYPE, timestamp?: number, object?: MapItem) {
		this.json = json;
		this.type = type;
		this.timestamp = timestamp ? timestamp : 0;
		this.object = object;
	}

	public static fromJson: Function = (json: string) => {
		const json_obj = JSON.parse(json);
		return new BufferItem(json_obj.json, json_obj.timestamp);
	};
}
