import { EventEmitter } from "@angular/core";

export interface JesipCard {
	initial: string | undefined;
	readonly title: string;
	color: string | (() => string);
	buttonText: string | (() => string) | undefined;
	readonly buttonClick: EventEmitter<Event>;
	readonly backgroundSrc: string | undefined;

	readonly type: JESIP_CARD_BODY_TYPE;
}
export enum JESIP_CARD_INITIAL_COLOR {
	BLUE = "#04394b",
	RED = "#dd3220",
	GREEN = "#00b45d"
}
export enum JESIP_CARD_BODY_TYPE {
	VOID,
	SET
}
