import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { WebRequestFactory } from "src/app/http/web.request.factory";
import { Overlay } from "src/app/dto/items/overlay";
import { Floor } from "src/app/dto/map/floor";
import { UploadedFile } from "src/app/dto/net/uploaded-file";
import { NavTabItem } from "src/app/dto/ui/tab";
import { BlobObj } from "src/app/dto/blob-obj";
import { MapItemsService } from "src/app/incident/map/map-items.service";
import { LocaleMap } from "src/app/global/constants/text/text-interface";
import { TextProvider } from "src/app/global/constants/text/text-provider";
import { PdfJsDist } from "src/app/dto/pdfjs-dist/pdfJs-dist";
import { MessagingService } from "src/app/global/messaging/messaging.service";
import { MESSAGE_TYPE } from "src/app/global/messaging/messages";

@Component({
	selector: "app-schematic-edit-modal",
	templateUrl: "schematic-edit.component.html",
	styleUrls: ["schematic-edit.css"]
})
export class SchematicEditModalComponent implements OnInit {
	@Input() schematic!: Overlay;

	@Output() closeCb = new EventEmitter<void>();

	public tabs!: Array<NavTabItem>;
	public currentTabs!: Array<NavTabItem>;
	public tabIndex: number = 0;
	public readonly text: () => LocaleMap;
	public currentImage: SafeResourceUrl | undefined;

	private readonly mis: MapItemsService;
	private readonly wreq: WebRequestFactory;
	private readonly mssg: MessagingService;
	private editMap: Array<number> = [];
	private startingTabIndex: number = 0;
	private endingTabIndex: number = 4;
	private maxVisibleTabs: number = 4;
	private supportedFiles: RegExp = /\.jpg|\.jpeg|\.png|\.svg|\.pdf/i;

	constructor(mis: MapItemsService, textProv: TextProvider, wreq: WebRequestFactory, mssg: MessagingService) {
		this.mis = mis;
		this.text = textProv.getStringMap;
		this.wreq = wreq;
		this.mssg = mssg;
		this.mssg.registerListener(MESSAGE_TYPE.UPDATE_OVERLAY, this.refreshModal);
	}

	ngOnInit(): void {
		if (!this.schematic) throw "SchematicEditModal initialized without schematic";
		if (!this.schematic.floors) this.schematic.floors = [];
		this.setNavTabs();
		this.refreshImage();
	}

	public readonly onCancel: () => void = () => {
		this.closeCb.emit();
	};

	public readonly getActiveTab: () => void = () => {
		return this.tabIndex;
	};

	public readonly refreshImage = async (): Promise<void> => {
		if (this.tabIndex === 0) {
			if (!this.schematic.fileImg) await this.mis.loadOverlayImg(this.schematic);
		} else {
			const floor = this.schematic.floors[this.tabIndex - 1];
			if (!floor) return;
			!floor.fileImg && (await this.mis.loadOverlayImg(this.schematic.floors[this.tabIndex - 1]));
		}
	};

	public readonly showOnlySliceOfTabs: () => void = () => {
		this.currentTabs = this.tabs.slice(this.startingTabIndex, this.endingTabIndex);
		this.currentTabs.push(new NavTabItem("+", this.tabs.length, this.addNewTab));
	};

	public calculateFwArrow(): boolean {
		return this.tabIndex !== this.tabs.length - 1;
	}

	public calculateBwArrow(): boolean {
		return this.tabIndex > 0;
	}

	public readonly incrementTabIndex: () => void = () => {
		const nextIndex = this.tabIndex + 1;
		if (nextIndex > this.tabs.length) return;
		if (nextIndex >= this.startingTabIndex && nextIndex < this.endingTabIndex) {
			this.tabIndex++;
		}
		if (nextIndex >= this.endingTabIndex && nextIndex <= this.tabs.length - 1) {
			this.startingTabIndex++;
			this.endingTabIndex++;
			this.tabIndex++;
			this.showOnlySliceOfTabs();
		}
	};

	public readonly decrementTabIndex: () => void = () => {
		const prevIndex = this.tabIndex - 1;
		if (prevIndex < 0) return;
		if (prevIndex >= this.startingTabIndex && prevIndex <= this.endingTabIndex) {
			this.tabIndex--;
		}
		if (prevIndex < this.startingTabIndex) {
			this.startingTabIndex--;
			this.endingTabIndex--;
			this.tabIndex--;
		}
		this.showOnlySliceOfTabs();
	};

	public readonly addNewTab: () => void = () => {
		this.schematic.floors?.push(new Floor(-1, this.schematic.id, -1, this.text().NEW_FLOOR));
		this.setNavTabs(true);
		this.showOnlySliceOfTabs();
		this.tabIndex = this.tabs.length - 1;
	};

	public readonly getCurrentFloor: () => Overlay | Floor = () => {
		return this.tabIndex > 0 ? this.schematic.floors![this.tabIndex - 1] : this.schematic;
	};

	public readonly refreshFloorName: (data: string) => void = (data) => {
		this.getCurrentFloor().name = data;
		if (!this.editMap.find((e) => e === this.getCurrentFloor().id) && this.getCurrentFloor().id !== -1) this.editMap.push(this.getCurrentFloor().id);
	};

	public readonly refreshComments: (data: string) => void = (data) => {
		this.schematic.description = data;
		if (!this.editMap.find((e) => e === this.schematic.id)) this.editMap.push(this.schematic.id);
	};

	public readonly areMandatoryFieldsEmpty: () => Boolean = () =>{
		for(let i = 0; i<this.schematic.floors.length; i++ )
			if (this.schematic.floors[i].id_img === null || this.schematic.floors[i].id_img < 1 || 
				this.schematic.floors[i].name === null || this.schematic.floors[i].name === undefined || this.schematic.floors[i].name === "") 
				return true;
		return false;
	}

	public async saveChanges(): Promise<void> {
		if (this.schematic.id === -1) {
			this.closeCb.emit();
			return;
		}
		for (let i = 0; i < this.editMap.length; i++) {
			if (this.editMap[i] === this.schematic.id) await this.mis.saveOverlay(this.schematic);
			else await this.mis.saveFloor(this.schematic.floors?.find((e) => e.id === this.editMap[i]));
		}
		if (this.schematic.floors)
			for (let i = 0; i < this.schematic.floors?.length; i++) {
				if (this.schematic.floors[i].id === -1)
					await this.mis.saveFloor(this.schematic.floors[i]).then((ans) => {
						if (!ans) return;
						this.schematic.floors![i].id = ans.id;
					});
			}
		this.closeCb.emit();
	}

	public readonly addNewFile: (evt: Event) => void = async (evt) => {
		const target = evt.target as HTMLInputElement;
		const file: File = (target.files as FileList)[0];
		await this.uploadNewFile(file);
		await this.mis.loadOverlayImg(this.schematic.floors[this.tabIndex - 1], true);
		target.value = "";
	};

	public readonly deleteFloor: () => Promise<boolean> = () => {
		return new Promise((resolve, reject) => {
			try {
				let result: boolean = false;
				if (this.getCurrentFloor().id > -1) {
					result = this.mis.deleteFloor(this.getCurrentFloor());
				}
				this.schematic.floors?.splice(this.tabIndex - 1, 1);
				this.tabIndex -= 1;
				if (this.startingTabIndex > 0) {
					this.startingTabIndex -= 1;
					this.endingTabIndex -= 1;
				}
				this.setNavTabs();
				resolve(result);
			} catch (error) {
				reject(error);
			}
		});
	};

	public readonly modifyFile: (evt: Event) => void = async (evt) => {
		const target = evt.target as HTMLInputElement;
		const file: File = (target.files as FileList)[0];
		const ext = file.name.slice(-4);

		if (ext.match(this.supportedFiles)) {
			if (ext === ".pdf") this.savePdfFile(file);
			else {
				const result: number = await this.uploadFileModify(file);

				if (result && result > 0) {
					await this.mis.loadOverlayImg(this.tabIndex === 0 ? this.schematic : this.schematic.floors[this.tabIndex - 1], true);

					if (this.tabIndex === 0) {
						this.schematic.id_img = result;
						await this.mis.saveOverlay(this.schematic);
					} else {
						this.schematic.floors[this.tabIndex - 1].id_img = result;
						await this.mis.saveFloor(this.schematic.floors[this.tabIndex - 1]);
					}
				}
			}
		}

		target.value = "";
	};

	private readonly uploadFileModify: (file: File) => Promise<number> = async (file) => {
		return new Promise(async (resolve, reject) => {
			try {
				const idImg = await this.uploadImgFile(file.name, file);
				this.tabIndex === 0 ? (this.schematic.id_img = idImg) : (this.schematic.floors[this.tabIndex - 1].id_img = idImg);
				resolve(idImg);
			} catch (error) {
				console.log("error", error);
				reject(error);
			}
		});
	};

	private readonly uploadNewFile: (file: File) => Promise<boolean> = async (file) => {
		return new Promise(async (resolve, reject) => {
			try {
				let idImg: number;
				if (file.name.slice(-4) === ".pdf") this.savePdfFile(file)
					else {
					idImg = await this.uploadImgFile(file.name, file);
					const currentFloor = this.schematic.floors[this.tabIndex - 1];
					currentFloor.id_img = idImg;
					this.mis.loadOverlayImg(currentFloor);
				}
				resolve(true);
			} catch (error) {
				console.log("error", error);
				reject(error);
			}
		});
	};

	private readonly savePdfFile = async (file: File): Promise<void> => {
		let imgIdsArray: Array<number> = new Array<number>();
		imgIdsArray = await this.uploadPdfFile(file.name, file);
		let updateFloor = true;
		const floorToUpdate: Floor | false = this.tabIndex > 0 && this.schematic.floors[this.tabIndex - 1];

		for (let i = 0; i < imgIdsArray.length; i++) {
			if (this.tabIndex === 0) {
				this.schematic.id_img = imgIdsArray[0];
				await this.mis.loadOverlayImg(this.schematic, true);
				await this.mis.saveOverlay(this.schematic);
			} else {
				const name = "Floor " + this.tabIndex;
				if (updateFloor && floorToUpdate) {
					floorToUpdate.id_img = imgIdsArray[i];
					floorToUpdate.name = name;
					await this.mis.loadOverlayImg(floorToUpdate);
					await this.mis.saveFloor(floorToUpdate);
					updateFloor = false;
				} else {
					const newFloor = new Floor(-1, this.schematic.id, imgIdsArray[i], name);
					this.schematic.floors.splice(this.tabIndex - 1, 0, newFloor);
					await this.mis.loadOverlayImg(newFloor);
					await this.mis.saveFloor(newFloor);
				}
			}
			this.tabIndex++;
		}
		this.setNavTabs(imgIdsArray && imgIdsArray.length ? true : false);
	};

	private readonly uploadImgFile: (fileName: string, file: File) => Promise<number> = async (fileName, file) => {
		return new Promise(async (resolve, reject) => {
			try {
				const ans: UploadedFile = await this.wreq.uploadFile(fileName, file);
				resolve(ans.id);
			} catch (error) {
				console.log("error", error);
				reject(error);
			}
		});
	};

	private readonly refreshModal = (schematic: Overlay): void => {
		if (this.schematic.id === schematic.id) {
			this.schematic = schematic;
			this.ngOnInit();
		}
	};

	private setNavTabs: (addingMoreFloor?: boolean) => void = (addingMoreFloor = false) => {
		this.tabs = [];
		this.tabs.push(new NavTabItem(this.schematic.name, 0, () => (this.tabIndex = 0)));
		this.schematic.floors?.forEach((floor: Floor, idx: number) => {
			if (!floor.fileImg) this.mis.loadOverlayImg(floor);
			const floorName = floor.name.slice(0, 3)[0].toUpperCase() + floor.name.slice(0, 3).slice(1).toLowerCase();
			this.tabs.push(new NavTabItem(idx + 1 + "." + floorName, idx + 1, () => (this.tabIndex = idx + 1)));
		});

		if (addingMoreFloor) {
			this.endingTabIndex = this.tabs.length;
			this.startingTabIndex = this.tabs.length >= this.maxVisibleTabs ? this.endingTabIndex - this.maxVisibleTabs : 0;
			this.tabIndex = this.tabs.length - 1;
		}
		this.showOnlySliceOfTabs();
	};

	private readonly uploadPdfFile: (fileName: string, file: File) => Promise<Array<number>> = async (fileName, file) => {
		return new Promise(async (resolve, reject) => {
			try {
				const ArrayBuffer: ArrayBufferLike = await file.arrayBuffer();
				const typedarray = new Uint8Array(ArrayBuffer);
				const pdfExporter: PdfJsDist = new PdfJsDist(typedarray, fileName);
				const imgIdsArray: Array<number> = new Array<number>();
				const blobObjArray: Array<BlobObj> = await pdfExporter.esportPdfToBlob();
				for (let i = 0; i < blobObjArray.length; i++) {
					const idx = await this.blobToImg(blobObjArray[i].blob, blobObjArray[i].fileName);
					imgIdsArray.push(idx);
				}
				resolve(imgIdsArray);
			} catch (error) {
				console.log("error", error);
				reject(error);
			}
		});
	};

	private readonly blobToImg: (blob: Blob | null, fileName: string) => Promise<number> = async (blob, fileName) => {
		return new Promise(async (resolve, reject) => {
			try {
				if (blob) {
					const imgFile = new File([blob], fileName, blob);
					const idFile = await this.uploadImgFile(fileName, imgFile);
					resolve(idFile);
				}
			} catch (error) {
				console.log("error", error);
				reject(error);
			}
		});
	};
}
