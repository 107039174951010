import { Component, Input } from "@angular/core";
import { ARROW_POSITION } from "./info-tooltip/info-tooltip.component";

@Component({
	selector: "app-info-tooltip-icon",
	templateUrl: "./info-tooltip-wrapper.component.html"
})
export class InfoTooltipWrapperComponent {
	@Input() hideIcon: boolean = false;
	@Input() tooltipText: string = "";
	@Input() arrowPosition: ARROW_POSITION = ARROW_POSITION.NONE;
	@Input() width: number = 310;

	public hovered: boolean = false;
	public clicked: boolean = false;

	public onMouseOver(): boolean {
		return (this.hovered = true);
	}

	public onMouseLeave(): boolean {
		return (this.hovered = false);
	}

	public onClick(): boolean {
		return (this.clicked = !this.clicked);
	}

	public onClickOutside(): boolean {
		return (this.clicked = false);
	}
}
