<div
	class="transparent-dropdown-filter {{ openMenu ? 'open' : '' }}{{
		selectingAddress ? ' expanded' : ''
	}}"
	[class.round-shaped]="withBorderRadius"
	appDetectClickOut
	(callback)="openMenu = false; $event.stopPropagation()"
>
	<div class="input-span disabled" *ngIf="disabled">
		<span>{{ getObjectText(object) }}</span>
	</div>
	<div
		*ngIf="!disabled"
		[class.events-none]="partialDisable"
		class="transparent-dropdown-filter-text {{ selectingAddress ? 'address' : '' }}"
		(click)="headerBodyClick()"
	>
		<div
			[ngClass]="{
				'input-span': true,
				'address-input': selectingAddress,
				'font-size-12px': withBorderRadius
			}"
		>
			<div *ngIf="filterEnabled">
				<input
					#filterInput
					*ngIf="!getObjectText(object) || openMenu"
					[ngClass]="{
						unfilled: true,
						'font-size-12px': withBorderRadius
					}"
					[placeholder]="placeholderText"
					[(ngModel)]="query"
					(ngModelChange)="change(query)"
					(click)="openMenu = true; $event.stopPropagation()"
				/>
				<span *ngIf="!openMenu && getObjectText(object)">{{ getObjectText(object) }}</span>
			</div>

			<div *ngIf="!filterEnabled">
				<span *ngIf="getObjectText(object)">{{ getObjectText(object) }}</span>
				<span
					*ngIf="!getObjectText(object) && placeholderText"
					class="placeholder-text"
					[ngStyle]="{ 'font-size.px': fontSize }"
					>{{ placeholderText }}</span
				>
			</div>
		</div>
		<div
			data-cy="dropdown-button"
			[ngClass]="{
				'dropdown-arrow-mask': true,
				hide: selectingAddress,
				'adjust-bottom': withBorderRadius
			}"
		>
			<div class="dropdown-triangle {{ openMenu ? 'up' : 'down' }}"></div>
		</div>
	</div>
	<div
		*ngIf="openMenu"
		[ngClass]="{ menu: true, dopen: openMenu, 'margin-top-8': withBorderRadius }"
		[style.maxHeight.px]="menuMaxHeight"
	>
		<div class="scroll">
			<div *ngIf="!getOptionLength()" class="no-results-string">
				{{ "( " + text().NO_RESULTS_FOUND + " )" }}
			</div>
			<div class="item active new-item" *ngIf="makingNewItem">
				<input
					class="new-item-input new-scenario"
					(keydown)="newItemKeydownController($event, 'SCENARIO')"
					spawn-focus=""
					autofocus
					(click)="$event.stopPropagation()"
					[(ngModel)]="newItem"
				/>
				<app-white-cross
					class="cross-button"
					(clickCb)="closeNewItem(); $event.stopPropagation()"
				></app-white-cross>
			</div>
			<div
				data-add-action-name="transparent-dropdown-item"
				[attr.data-cy]="'transparent-dropdown-item-' + getOptionText(option)"
				*ngFor="let option of options | filter : filterFunc"
				[ngClass]="{
					item: true,
					active: compareSelect(object, option),
					'adjust-hover': withBorderRadius
				}"
				(click)="onClick(option)"
			>
				<div
					*ngIf="getOptionColor(option)"
					[ngStyle]="{ 'background-color': getOptionColor(option) }"
					class="color-container"
				></div>
				<div class="text-container {{ selectingAddress ? 'text' : '' }}">
					{{ getOptionText(option) }}
				</div>
			</div>
		</div>

		<div
			*ngIf="allowAddItem"
			[ngClass]="{
				item: true,
				'new-item-dropdown-option': true,
				'adjust-background': withBorderRadius
			}"
			(click)="$event.stopPropagation(); openNewItem($event)"
		>
			<div class="plus-button"></div>
			<div class="new-item-dropdown-add-text">
				<span>{{ addString }}</span>
			</div>
		</div>
	</div>
</div>
