export enum FLAT_SECTOR {
	DEFAULT = -1,
	LOBBY = 0,
	BRIDGE = 1,
	FIRE = 2,
	SEARCH = 3
}
export enum SECTOR_COLOURS {
	DEFAULT = "transparent",
	SEARCH = "#ffc700",
	FIRE = "#e74c3c",
	BRIDGE = "#0dbff9",
	LOBBY = "#09ca82"
}
export enum DEFAULT_SOI {
	UNSET = "-1",
	CIVILIANS = "Civilians",
	CONTROL_ROOM = "Control Room",
	FIREFIGHTERS = "Firefighters",
	OTHER_AGENCIES = "Other Agencies"
}
export enum FLAT_TYPE {
	FLAT = "Flat",
	BASEMENT = "Basement",
	ROOFTOP = "Rooftop",
	SUBBASEMENT = "Sub-basement"
}
export abstract class DEFAULT_CONDITION {
	public static values = ["Fire", "Floody", "Smoke", "Smell", ""];

	public static readonly defaults = new Map<string, string>([
		["FIRE", DEFAULT_CONDITION.values[0]],
		["FLOOD", DEFAULT_CONDITION.values[1]],
		["SMOKE", DEFAULT_CONDITION.values[2]],
		["SMELL", DEFAULT_CONDITION.values[3]],
		["DEFAULT", DEFAULT_CONDITION.values[4]]
	]);
}
export abstract class DEFAULT_ADVICE {
	public static values = ["Stay put", "Phase Evacuation", "Full Evacuation", ""];

	public static readonly defaults = new Map<string, string>([
		["STAY_PUT", DEFAULT_ADVICE.values[0]],
		["PHASE_EVACUATION", DEFAULT_ADVICE.values[1]],
		["FULL_EVACUATION", DEFAULT_ADVICE.values[2]],
		["DEFAULT", DEFAULT_ADVICE.values[3]]
	]);
}
