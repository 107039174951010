import { Component, EventEmitter, Input, Output } from "@angular/core";
import { LocaleMap } from "src/app/global/constants/text/text-interface";
import { TextProvider } from "src/app/global/constants/text/text-provider";

@Component({
	selector: "app-compulsory-fields",
	templateUrl: "./compulsory-fields.component.html",
	styleUrls: ["./compulsory-fields.component.css"]
})
export class CompulsoryFieldsComponent {
	@Input() fields: string[] = [];
	@Output() cancel = new EventEmitter<void>();

	public text: () => LocaleMap;

	constructor(textProv: TextProvider) {
		this.text = textProv.getStringMap;
	}

	public closeDialog(): void {
		this.fields = [];
		this.cancel.emit();
	}
}
