import * as pdfjsLibImport from "pdfjs-dist";
import { RenderParameters } from "pdfjs-dist/types/src/display/api";
import { BlobObj } from "../blob-obj";

export class PdfJsDist {
	private uint8Array: Uint8Array = new Uint8Array();
	private fileName: string = "";
	private pdfjsLib: any;

	constructor(uint8Array: Uint8Array, fileName: string) {
		this.uint8Array = uint8Array;
		this.pdfjsLib = pdfjsLibImport;
		this.fileName = fileName;
	}

	public readonly esportPdfToBlob: () => Promise<Array<BlobObj>> = async () => {
		return new Promise(async (resolve, reject) => {
			try {
				this.pdfjsLib.GlobalWorkerOptions.workerSrc = "pdf.worker.js";
				let loadingTask = this.pdfjsLib.getDocument(this.uint8Array);
				const pdf: pdfjsLibImport.PDFDocumentProxy = await loadingTask.promise;
				const imgBlobArray: Array<BlobObj> = await this.callExtractPdf(pdf, this.fileName);
				resolve(imgBlobArray);
			} catch (error) {
				console.log("error", error);
				reject(error);
			}
		});
	};

	private readonly callExtractPdf: (pdf: pdfjsLibImport.PDFDocumentProxy, fileName: any) => Promise<Array<BlobObj>> = (pdf, fileName) => {
		return new Promise(async (resolve, reject) => {
			try {
				const imgBlobArray: Array<BlobObj> = await this.extractImgsFromPdf(pdf, fileName);
				resolve(imgBlobArray);
			} catch (error) {
				console.log("error", error);
				reject(error);
			}
		});
	};

	private readonly extractImgsFromPdf: (pdf: pdfjsLibImport.PDFDocumentProxy, fileName: string) => Promise<Array<BlobObj>> = (pdf, fileName) => {
		return new Promise(async (resolve, reject) => {
			try {
				const imgBlobArray = new Array<BlobObj>();
				for (let i = 0; i < pdf.numPages; i++) {
					const page = await pdf.getPage(i + 1);
					const imgFileName = fileName.replace(".pdf", "") + "-pg-" + (i + 1) + ".png";
					const blob: BlobObj = await this.extractPage(page, imgFileName);
					imgBlobArray.push(blob);
				}
				resolve(imgBlobArray);
			} catch (error) {
				console.log("error", error);
				reject(error);
			}
		});
	};

	private readonly extractPage: (page: pdfjsLibImport.PDFPageProxy, imgFileName: string) => Promise<BlobObj> = async (page, imgFileName) => {
		return new Promise(async (resolve, reject) => {
			try {
				const outputScale = window.devicePixelRatio || 1;
				const viewport = page.getViewport({ scale: 1.0 });
				const canvas = document.createElement("canvas");
				const ctx = canvas.getContext("2d");
				canvas.width = Math.floor(viewport.width * outputScale);
				canvas.height = Math.floor(viewport.height * outputScale);
				canvas.style.width = Math.floor(viewport.width) + "px";
				canvas.style.height = Math.floor(viewport.height) + "px";
				let transform = outputScale !== 1 ? [outputScale, 0, 0, outputScale, 0, 0] : null;
				let renderContext = {
					canvasContext: ctx,
					transform: transform,
					viewport: viewport
				};
				const renderTask = page.render(renderContext as RenderParameters);
				await renderTask.promise;
				//Transform to Img
				const blobObj: BlobObj = await this.canvasToBlob(canvas, imgFileName);
				resolve(blobObj);
			} catch (error) {
				console.log("error", error);
				reject(error);
			}
		});
	};

	private canvasToBlob: (canvas: HTMLCanvasElement, imgFileName: string) => Promise<BlobObj> = (canvas, imgFileName) => {
		return new Promise((resolve, reject) => {
			try {
				canvas.toBlob(async (blob) => {
					if (blob) {
						resolve({ blob: blob, fileName: imgFileName });
					}
				});
			} catch (error) {
				console.log("error", error);
				reject(error);
			}
		});
	};
}
