import { Directive, ElementRef, Input } from "@angular/core";
import { PATTERN } from "src/app/dto/items/types/area-type";
import { LocaleMap } from "src/app/global/constants/text/text-interface";
import { TextProvider } from "src/app/global/constants/text/text-provider";

@Directive({
	selector: "[appAreaPatternTypeOption]"
})
export class AreaPatternTypeOptionDirective {
	@Input() pattern: PATTERN = PATTERN.NONE;
	@Input() color: string = "#fff";

	private text: () => LocaleMap;
	private elem: any;

	constructor(el: ElementRef, txtProv: TextProvider) {
		let elem = el.nativeElement;
		let drawnPattern: PATTERN | undefined;
		let drawnColor: string | undefined;
		this.text = txtProv.getStringMap;
		setInterval(() => {
			if (this.pattern !== drawnPattern || this.color !== drawnColor) {
				if (elem.lastChild) elem.removeChild(elem.lastChild);
				elem.appendChild(this.createElement(this.pattern, this.color));
				drawnPattern = this.pattern;
				drawnColor = this.color;
			}
		}, 10);
	}

	private readonly createLineSVGPattern: Function = (strokeColor: string, fillColor: string, id: number) => {
		var def = document.createElementNS("http://www.w3.org/2000/svg", "defs");

		var pattern = document.createElementNS("http://www.w3.org/2000/svg", "pattern");
		pattern.setAttributeNS(null, "id", "lineFill" + id);
		pattern.setAttributeNS(null, "patternUnits", "userSpaceOnUse");
		pattern.setAttributeNS(null, "patternTransform", "rotate(33)");
		pattern.setAttributeNS(null, "height", "7");
		pattern.setAttributeNS(null, "width", "7");
		def.appendChild(pattern);
		var rect = document.createElementNS("http://www.w3.org/2000/svg", "rect");
		rect.setAttributeNS(null, "id", "rectFill");
		rect.setAttributeNS(null, "fill", fillColor || "red");
		rect.setAttributeNS(null, "fill-opacity", "0.3");
		rect.setAttributeNS(null, "stroke", strokeColor || "#000");
		rect.setAttributeNS(null, "stroke-dasharray", "7,7");
		rect.setAttributeNS(null, "height", "7");
		rect.setAttributeNS(null, "width", "7");
		pattern.appendChild(rect);
		return def;
	};

	private readonly createMeshSVGPattern: Function = (strokeColor: string, fillColor: string, id: number) => {
		var def = document.createElementNS("http://www.w3.org/2000/svg", "defs");

		var pattern = document.createElementNS("http://www.w3.org/2000/svg", "pattern");

		pattern.setAttributeNS(null, "id", "meshFill" + id);
		pattern.setAttributeNS(null, "patternUnits", "userSpaceOnUse");
		pattern.setAttributeNS(null, "patternTransform", "rotate(45)");
		pattern.setAttributeNS(null, "height", "17");
		pattern.setAttributeNS(null, "width", "17");
		def.appendChild(pattern);
		var rect = document.createElementNS("http://www.w3.org/2000/svg", "rect");
		rect.setAttributeNS(null, "id", "rectFill");
		rect.setAttributeNS(null, "fill", fillColor || "red");
		rect.setAttributeNS(null, "fill-opacity", "0.3");
		rect.setAttributeNS(null, "stroke", strokeColor || "#000");
		rect.setAttributeNS(null, "height", "250");
		rect.setAttributeNS(null, "width", "250");
		pattern.appendChild(rect);
		return def;
	};

	private readonly createElement: Function = (pattern: string, color: string) => {
		let rootDiv = document.createElement("div");
		let textDiv = document.createElement("div");
		const id = Math.random();
		rootDiv.appendChild(textDiv);
		let svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
		svg.setAttributeNS(null, "height", "30px");
		svg.setAttributeNS(null, "width", "100%");
		svg.setAttributeNS(null, "preserveAspectRatio", "xMidYMid meet");
		switch (pattern) {
			case "none":
				break;
			case "line":
				svg.appendChild(this.createLineSVGPattern(color, "none", id));
				break;
			case "mesh":
				svg.appendChild(this.createMeshSVGPattern(color, "none", id));
				break;
		}
		var rect = document.createElementNS("http://www.w3.org/2000/svg", "rect");
		rect.setAttributeNS(null, "width", "100%");
		rect.setAttributeNS(null, "height", "100%");
		rect.setAttributeNS(null, "preserveAspectRatio", "xMidYMid meet");
		rect.setAttributeNS(null, "fill", "url(#" + (pattern == "line" ? "lineFill" : pattern == "none" ? "null" : "meshFill") + id + ")");
		svg.appendChild(rect);
		const none = document.createElement("div");
		const txt = document.createTextNode(this.text().NONE);
		none.appendChild(txt);
		none.style.textAlign = "center";
		none.style.lineHeight = "34px";
		none.style.fontSize = "16px";

		this.pattern === PATTERN.NONE ? rootDiv.appendChild(none) : rootDiv.appendChild(svg);

		return rootDiv;
	};

	private readonly setHeights = () => {
		const rect = this.elem.getBoundingClientRect();
		this.elem.style.height = window.innerHeight - rect.y + "px";
		this.elem.style.maxHeight = window.innerHeight - rect.y + "px";
	};
}
