import { Injectable } from "@angular/core";
import { NavigationStart, Router } from "@angular/router";
import { LoginService } from "../login/login.service";
import { ConfigurationService } from "../settings/types/configuration.service";
import { UserService } from "../settings/user/user.service";
import { Incident } from "../dto/items/incident";
import { Level } from "../dto/user/level";
import { IncidentService } from "../incident/incident.service";
import { AraService } from "../incident/incident-tools/ara/ara.service";
import { DecisionLogService } from "../incident/incident-tools/forms/decision-log/decision-log.service";
import { AutomationService } from "../global/automation/automation.service";
import { SCREEN, PATH } from "../global/constants/enums/screens";
import { ALERT_TYPE, MainService } from "../global/main.service";
import { MESSAGE_TYPE } from "../global/messaging/messages";
import { MessagingService } from "../global/messaging/messaging.service";
import { Subject } from "rxjs";
import { TextProvider } from "../global/constants/text/text-provider";
import { LocaleMap } from "../global/constants/text/text-interface";
import { ResourceService } from "../settings/resource/resource.service";
import { CSCommander } from "../dto/command-structure/cs-commander";
import { Area } from "../dto/items/area";
import { CSSector } from "../dto/command-structure/cs-sector";
import { WebRequestFactory } from "../http/web.request.factory";
import { LOG_TYPE } from "../global/constants/enums/log_types";
import { FORM_SCREENS } from "../incident/incident-tools/forms/screens.enum";
import { FsgBuilding } from "../dto/fsg/fsg-building";
import { FormsService } from "../incident/incident-tools/forms/forms.service";

@Injectable({
	providedIn: "root"
})
export class NavigationService {
	public currentScreen: SCREEN;
	public tabIndex: number = 0;
	public navRoutes: Array<Route> = [];
	public firstConfLoad: boolean = false;
	public readonly changeRoute$ = new Subject<string>();
	public commandStructureQuery$ = new Subject<{ type: "goTo" | "assign"; object: CSCommander | Area | CSSector }>();

	public AuthorizedPages = [];
	public _cuLevel!: Level;

	public $authorizedPagesChange = new Subject<void>();
	public $changeScreen = new Subject<SCREEN>();

	private readonly router: Router;
	private readonly userService: UserService;
	private readonly conf: ConfigurationService;
	private readonly mssg: MessagingService;
	private readonly ems: IncidentService;
	private readonly automs: AutomationService;
	private readonly aras: AraService;
	private readonly decisions: DecisionLogService;
	private readonly main: MainService;
	private readonly login: LoginService;
	private readonly resourceServ: ResourceService;
	private readonly wreq: WebRequestFactory;
	private readonly jesip: FormsService;

	private confAlreadyLoaded: boolean = false;

	private currentBottomPage: SCREEN.HOME | Incident = SCREEN.HOME;
	private previousScreen: SCREEN | undefined;

	private unAuthorizedPages: Array<string> = [];

	private previousRoutes: Array<{ path: SCREEN; index: number }> = [];

	private text: () => LocaleMap;

	constructor(
		router: Router,
		user: UserService,
		conf: ConfigurationService,
		mssg: MessagingService,
		ems: IncidentService,
		automs: AutomationService,
		ara: AraService,
		decision: DecisionLogService,
		main: MainService,
		login: LoginService,
		tp: TextProvider,
		resourceServ: ResourceService,
		wreq: WebRequestFactory,
		jesip: FormsService
	) {
		this.router = router;
		this.mssg = mssg;
		this.userService = user;
		this.conf = conf;
		this.ems = ems;
		this.automs = automs;
		this.aras = ara;
		this.decisions = decision;
		this.login = login;
		this.main = main;
		this.text = tp.getStringMap;
		this.resourceServ = resourceServ;
		this.wreq = wreq;
		this.jesip = jesip;

		this.login.$loginFinished.subscribe((changeRoute) => this.onLogin(changeRoute.changeRoute));
		router?.events.subscribe((event: any) => {
			if (event instanceof NavigationStart) this.routeChangeHandler(event);
		});

		mssg.registerListener(MESSAGE_TYPE.LOAD_CONFIGURATION, this.onConfLoad);
		mssg.registerListener(MESSAGE_TYPE.LOAD_EVENTS_MISSION, this.onConfLoad);
		this.userService.$userLevelChanged.subscribe(this.onChangePermissions);

		this.currentScreen = SCREEN.LOGIN;
	}

	public readonly shouldShowNavigation = (): boolean => {
		return this.currentScreen !== SCREEN.LOGIN && this.login.splashScreenReady && this.login.hideSplashScreen;
	};

	public readonly shouldShowTopLeftNavigation: Function = () => {
		return (
			this.currentScreen !== SCREEN.LOGIN &&
			this.currentScreen !== SCREEN.HOME &&
			this.currentScreen !== SCREEN.INCIDENT &&
			this.currentScreen !== SCREEN.OVERLAY &&
			this.currentScreen !== SCREEN.TACTICAL &&
			this.currentScreen !== SCREEN.CALLOUT &&
			this.currentScreen !== SCREEN.FORMS &&
			this.currentScreen !== SCREEN.FSG &&
			this.currentScreen !== SCREEN.ARA &&
			this.currentScreen !== SCREEN.USERS &&
			this.currentScreen !== SCREEN.COMMAND_STRUCTURE &&
			this.currentScreen !== SCREEN.RECOMMENDATION
		);
	};

	public readonly logOut = (): void => {
		this.login.onLogout();
	};

	public readonly isAuthorized: (route: string) => boolean = (route: string) => {
		return this.unAuthorizedPages.indexOf(route) == -1;
	};

	public readonly goBottom = (): void => {
		if (this.currentBottomPage instanceof Incident) {
			if (!this.isScreenIncident(this.currentScreen))
				this.ems.setCurrentIncident(this.currentBottomPage).then(() => {
					this.goToIncident(false);
				});
			else {
				this.goTo(SCREEN.INCIDENT);
			}
		} else {
			this.goTo(this.currentBottomPage);
		}
	};

	public readonly goToIncident: (reload?: boolean) => void = async (reload) => {
		if (await this.canAccessIncident()) {
			let missionPage = this.isMissionPath(this.conf.configuration.custom_homepage) ? this.conf.configuration.custom_homepage : SCREEN.INCIDENT;
			let i = 0;
			while (!this.isAuthorized(missionPage!) || !this.isMissionPath(missionPage)) {
				missionPage = this.navRoutes[i].path;
				i++;
			}

			this.goTo(missionPage!);
			this.mssg.fire(MESSAGE_TYPE.LOAD_MISSION_PAGE);
		} else this.goTo(SCREEN.HOME);
	};

	public readonly goTo: (screen: SCREEN, dontAddToStack?: boolean, tabIndex?: number) => void = (screen, dontAddToStack?, tabIndex?) => {
		// used for route changes
		this.mssg.fire(MESSAGE_TYPE.BEGIN_ROUTE_CHANGE);
		this.wreq.logInformation(LOG_TYPE.CHANGE_SCREEN, screen);
		this.previousScreen = this.currentScreen;
		this.currentScreen = screen;
		this.addRouteToStack(this.previousScreen, dontAddToStack);
		this.tabIndex = tabIndex ? tabIndex : 0;
		if (this.theRouteWillChange(screen)) {
			this.goToNewRoute(screen);
		} else {
			this.openMissionSpecificScreen();
		}
	};

	public readonly changeScreen: Function = (screen: SCREEN) => {
		// used for screen changes (incident router)
		this.router.navigate(screen !== SCREEN.INCIDENT && screen !== SCREEN.OVERLAY && screen !== SCREEN.RECOMMENDATION && screen !== SCREEN.CALLOUT ? [SCREEN.INCIDENT, screen] : [SCREEN.INCIDENT]);
		this.mssg.fire(MESSAGE_TYPE.BEGIN_ROUTE_CHANGE);
		this.wreq.logInformation(LOG_TYPE.CHANGE_SCREEN, screen);
		this.previousScreen = this.currentScreen;
		this.setScreen(screen);
		this.previousRoutes.push({ path: SCREEN.INCIDENT, index: this.tabIndex });
		this.currentBottomPage = this.ems.getCurrentIncident()!;
		this.openMissionSpecificScreen();
		this.changeRoute$.next(this.currentScreen);
	};

	public readonly setScreen = (screen: SCREEN): void => {
		// changes variable silently, without triggering any events
		this.currentScreen = screen;
	};

	public readonly setJesipScreen = (form: FORM_SCREENS, item?: FsgBuilding): void => {
		if (item && item instanceof FsgBuilding) this.jesip.currentFsg = item;
		this.changeScreen(SCREEN.FORMS);
		this.jesip.currentScreen = form;
	};

	public readonly finishMission: Function = async (): Promise<boolean> => {
		return await this.ems.closeCurrentIncident();
	};

	public readonly reopenMission: Function = async (): Promise<boolean> => {
		let result = await this.ems.reopenCurrentIncident();
		await this.ems.load(true);
		return result;
	};

	public readonly isScreenPreIncident: (screen: SCREEN) => boolean = (screen) => {
		return screen === SCREEN.HOME || screen === SCREEN.ASSETS || screen === SCREEN.CONFIGURATION || screen === SCREEN.USERS || screen === SCREEN.ACCOUNT;
	};

	public readonly isScreenIncident: (screen: SCREEN) => boolean = (screen) => {
		return (
			screen === SCREEN.INCIDENT ||
			screen === SCREEN.ARA ||
			screen === SCREEN.TACTICAL ||
			screen === SCREEN.COMMAND_STRUCTURE ||
			screen === SCREEN.CALLOUT ||
			screen === SCREEN.FORMS ||
			screen === SCREEN.OVERLAY ||
			screen === SCREEN.DECISION ||
			screen === SCREEN.FSG ||
			screen === SCREEN.RECOMMENDATION
		);
	};

	public readonly getMenuIndex: (screen: SCREEN) => number | undefined = (screen) => {
		if (screen === SCREEN.HOME) return 0;
		if (screen === SCREEN.ASSETS) return 1;
		if (screen === SCREEN.USERS) return 2;
		if (screen === SCREEN.CONFIGURATION) return 3;
		if (screen === SCREEN.ACCOUNT) return 4;
		return;
	};

	public get cuLevel(): Level {
		return this._cuLevel;
	}

	public async getUserCurrentIncidentId(): Promise<number | undefined> {
		const resourceId = this.login.user.id_resource;
		if (resourceId > 0) {
			let resource = await this.resourceServ.getResourceById(resourceId);
			return resource && resource.id_incident;
		}
		return;
	}

	public getCurrentBottomPage(): SCREEN | Incident {
		return this.currentBottomPage;
	}

	private async canAccessIncident(): Promise<boolean> {
		let incident = this.ems.Incidents.find((m) => {
			return this.conf.configuration.id_current_incident === m.id;
		});
		if (incident?.closed) return this.userService.getCurrentUserLevel().history_access;
		else if (this.userService.getCurrentUserLevel().all_incident_access || (await this.getUserCurrentIncidentId()) === this.conf.configuration.id_current_incident) return true;
		return false;
	}

	private set cuLevel(cuLevel: Level) {
		this._cuLevel = cuLevel;
	}

	private readonly onLogin = (changeRoute = true): void => {
		this.onChangePermissions();
		changeRoute && this.goTo(SCREEN.HOME);
	};

	private readonly onChangePermissions = (): void => {
		this.cuLevel = this.userService.getCurrentUserLevel() ? this.userService.getCurrentUserLevel() : new Level();
		this.unAuthorizedPages.length = 0;
		if (!this.cuLevel?.users_manage) {
			this.unAuthorizedPages.push(PATH.USERS);
		}
		if (!this.cuLevel?.edit_agents) {
			this.unAuthorizedPages.push(PATH.RESOURCES);
		}
		if (!this.cuLevel?.edit_types) {
			this.unAuthorizedPages.push(PATH.CONFIGURATION);
		}
		if (!this.cuLevel?.edit_AsPs) {
			this.unAuthorizedPages.push("mission/edit");
		}
		this.navRoutes.length = 0;
		this.router.config.forEach((route) => {
			const name = route.path!.replace(/\//g, "");
			const screen = (SCREEN as any)[route.path!.toUpperCase()];
			if (this.isAuthorized(route.path!)) {
				this.navRoutes.push(new Route(screen, name, this.isMissionPath(route.path), this.getMenuIndex(screen)));
			}
		});
		this.$authorizedPagesChange.next();
		if (this.unAuthorizedPages.find((e) => "/" + e === this.currentScreen)) {
			this.main.addAlert(ALERT_TYPE.DANGER, this.text().PERMISSION_DENIED);
			this.goTo(SCREEN.HOME);
		}
	};

	private readonly onConfLoad: Function = async () => {
		if (!this.confAlreadyLoaded) {
			const id = this.conf.configuration.id_current_incident;

			if (id > -1) {
				const target = this.ems.Incidents.find((event) => {
					return event.id === id;
				});

				if (target && (!target?.closed || this.userService.getCurrentUserLevel().history_access)) {
					// Load incident from conf and denying future load tries
					await this.ems.setCurrentIncident(target!);
					this.goToIncident();
					this.confAlreadyLoaded = true;
				}
			} else {
				// There's nothing to load, avoid future useless loads
				this.confAlreadyLoaded = true;
				this.goTo(SCREEN.HOME);
			}
			this.login.$onInitialLoadIng.next();
		}
	};

	private readonly routeChangeHandler = (route: NavigationStart) => {
		if (route.url.match(SCREEN.LOGIN)) return;
		if (this.unAuthorizedPages.find((e) => e === route.url)) {
			this.router.navigate([window.location.pathname]);
		}
		if (!this.cuLevel) this.router.navigate([SCREEN.LOGIN]);
	};

	private readonly isMissionPath: Function = (path: string) => {
		return path.match(/incident|ara|command-structure|tactical|overlay|callout|decision|fsg|jesip/g) !== null;
	};

	private readonly theRouteWillChange: Function = (path: SCREEN) => {
		if (this.isScreenPreIncident(this.currentScreen)) return true;
		if (this.currentScreen === SCREEN.INCIDENT && this.isScreenIncident(path)) return true;
		return false;
	};

	private readonly goToNewRoute: Function = (path: string) => {
		if (!this.isMissionPath(path)) this.ems.exitCurrentIncident();
		//Go if it is not already current page
		if (path !== this.router.url) {
			//Only go if current mission is set or we go to a non mission page (we don't want to go to a mission page with current mission undefined)
			if ((!this.isMissionPath(path) || this.ems.isIncidentSet()) && this.isAuthorized(path)) {
				this.router.navigate([path]); // go to mission if the mission is set
			} else {
				this.router.navigate([SCREEN.HOME]); //if we wanted to go to a mission but its not set, go home
			}
			this.mssg.fire(MESSAGE_TYPE.END_ROUTE_CHANGE);
		}
	};

	private readonly addRouteToStack: Function = (path: SCREEN, dontAddToStack: boolean) => {
		if (path == SCREEN.LOGIN) return;
		if (path == SCREEN.HOME) {
			this.currentBottomPage = path;
		}
		if (path == SCREEN.INCIDENT) {
			this.currentBottomPage = this.ems.getCurrentIncident()!;
		}
		if (dontAddToStack !== true && this.previousRoutes[this.previousRoutes.length - 1]?.path !== path) this.previousRoutes.push({ path: path, index: this.tabIndex });
	};

	private readonly openMissionSpecificScreen: Function = () => {
		this.openScreen();
	};

	private readonly openScreen: Function = () => {
		if (this.currentScreen === SCREEN.DECISION) this.decisions.newDecisions = 0;
		this.currentScreen === SCREEN.COMMAND_STRUCTURE && this.automs.pause();
		this.currentScreen === SCREEN.ARA && this.automs.pause();
	};
}
export class Route {
	path: SCREEN;
	name: string;
	is_mission_route: boolean;
	menu_index: number | undefined;

	constructor(path: SCREEN, name: string, is_mission_route?: boolean, idx?: number) {
		this.path = path;
		this.name = name;
		this.is_mission_route = !!is_mission_route;
		if (idx !== undefined) this.menu_index = idx;
	}
}
