import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { CSCommander } from "src/app/dto/command-structure/cs-commander";
import { CommandStructureService } from "src/app/incident/incident-tools/command-structure/command-structure.service";
import { LocaleMap } from "src/app/global/constants/text/text-interface";
import { TextProvider } from "src/app/global/constants/text/text-provider";
import { CSNode } from "../CSNode";
import { NavigationService } from "src/app/navigation/navigation.service";
import { SCREEN } from "src/app/global/constants/enums/screens";
import { ResourceService } from "src/app/settings/resource/resource.service";
import { Resource } from "src/app/dto/resources/resource";

@Component({
	selector: "app-cs-commander",
	templateUrl: "commander.component.html",
	styleUrls: ["../../chart.css", "commander.css"]
})
export class CSCommanderNodeComponent implements CSNode {
	@Input() object!: CSCommander;
	@Input() width!: number;
	@Input() height!: number;
	@Input() disable: boolean = false;
	@Input() disabled: boolean = false;
	@Input() dragging: boolean = false;
	@Input() draggingPersonnel = false;

	@Output() addSupport = new EventEmitter<void>();
	@Output() addSector = new EventEmitter<void>();

	@Input() onResourceDragStart: Function = () => {};
	@Input() onResourceDragCancel: Function = () => {};
	@Input() onResourceDragEnd: Function = () => {};

	public readonly text: () => LocaleMap;
	public editable: boolean = false;
	public padding: string = "8px";
	public areOverflowSkillsVisible: boolean = false;
	public draggingOwnResource: boolean = false;

	private showMenu: boolean = false;

	private readonly res: ResourceService;
	private readonly cs: CommandStructureService;
	private readonly nav: NavigationService;

	constructor(textProv: TextProvider, cs: CommandStructureService, nav: NavigationService, res: ResourceService) {
		this.text = textProv.getStringMap;
		this.res = res;
		this.nav = nav;
		this.cs = cs;
	}

	public clickAndAddSector(): void {
		if (!this.disabled) {
			this.addSector.emit();
		}
		return;
	}

	public clickAndAddSupport(): void {
		if (!this.disabled) {
			this.addSupport.emit();
		}
		return;
	}

	public readonly onMainUnassignedClick: Function = () => {
		this.nav.changeScreen(SCREEN.INCIDENT);
		if (this.object.id === -1) this.nav.commandStructureQuery$.next({ type: "assign", object: this.object });
	};

	public readonly onButtonClick: Function = () => {
		this.nav.changeScreen(SCREEN.INCIDENT);
		this.nav.commandStructureQuery$.next({ type: "goTo", object: this.object });
	};

	public readonly isMenuOpen: Function = () => {
		return this.showMenu;
	};

	public readonly openMenu: Function = (evt: MouseEvent) => {
		this.showMenu = !this.showMenu;
	};

	public readonly toggleEditable: Function = () => {
		this.editable = !this.editable;
	};

	public setNewCommanderName(): string {
		const commanderName = this.getResource() ? this.getResource()!.name : "-";
		this.object.name = commanderName;
		return `${commanderName}`;
	}

	public readonly setNewCommanderTitle: Function = (newData: string) => {
		this.object.title = newData;
	};

	public getResource(): Resource | undefined {
		return this.res.Resources.find((e) => e.id === this.object.id_resource);
	}

	public readonly setStatusBackground: Function = (resource: Resource) => {
		return resource && resource.__state ? { backgroundColor: resource.__state?.color } : {};
	};

	public toggleSkills(): void {
		this.areOverflowSkillsVisible = !this.areOverflowSkillsVisible;
	}

	public readonly saveChanges: Function = async () => {
		if (this.editable) {
			await this.cs.saveCommander(this.object);
		}
		this.editable = this.showMenu = false;
	};

	public readonly onDragStart: Function = (params: any) => {
		this.draggingOwnResource = true;
		this.onResourceDragStart(params);
	};

	public readonly onDragEnd: Function = (params: any) => {
		this.draggingOwnResource = false;
		this.onResourceDragEnd(params);
	};

	public shouldShowDropZone(): boolean {
		return this.object.id_resource === -1 || (this.dragging && !this.draggingOwnResource);
	}

	public isIncidentClosed(): boolean {
		return this.cs.isIncidentClosed();
	}
}
export abstract class COMMANDER_DEFS {
	public static readonly icon_path =
		"M483.932,47.29c-26.72,21.71-55.73,42.46-89.55,51.16c-20.05,5.17-41.561,4.15-61.05-2.83 " +
		"c-32.49-11.5-59.341-34.58-91.771-46.22c-19.03-7.16-39.91-9.12-59.95-5.83c-29.68,4.86-56.739,19.26-81.62,35.58 " +
		"c-0.1,86.75-0.039,173.51-0.029,260.27c25.46-16.641,53.189-31.38,83.63-35.891c21.93-3.389,44.66,0.061,64.97,8.871 " +
		"c27.48,11.369,51.18,30.14,78.811,41.209c19.33,7.971,41,10.641,61.529,6.311c36.04-7.58,66.9-29.439,94.97-52.29 " +
		"C484.092,220.859,483.972,134.07,483.932,47.29z " +
		"M73.5,426.784V73.216c9.064-6.325,15-16.824,15-28.716c0-19.33-15.67-35-35-35s-35,15.67-35,35 " +
		"c0,11.892,5.936,22.391,15,28.716v353.568c-9.064,6.325-15,16.824-15,28.716c0,19.33,15.67,35,35,35s35-15.67,35-35 " +
		"C88.5,443.608,82.565,433.109,73.5,426.784z";
	public static readonly icon_weight = 0.5;
	public static readonly icon_opacity = 1;
	public static readonly icon_scale = 0.05;
}
