import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Member } from "src/app/dto/jesip/member";
import { LocaleMap } from "src/app/global/constants/text/text-interface";
import { TextProvider } from "src/app/global/constants/text/text-provider";

@Component({
	selector: "app-team-list",
	templateUrl: "team-list.component.html",
	styleUrls: ["team-list.css"]
})
export class TeamListComponent implements OnInit {
	@Input() header: string = "Team Information";
	@Input() memberTitle!: string;
	@Input() members = new Array<Member>();
	@Input() canAdd: boolean = false;
	@Input() addText: string = "Add Team Member";
	@Input() helpText: string = "Co-locate with commanders as soon as practicably possible at a single, safe and easily identified location near to the scene.";
	@Input() headerIcon: string = "resources/img/team-icon.svg";
	@Input() placeholder: string = "Organisation";
	@Input() disable: boolean = false;
	@Input() organizations = new Array<string>();
	@Input() historyMode: boolean = false;

	@Output() memberEdit = new EventEmitter<Member>();
	@Output() dropDownValue = new EventEmitter<string>();
	@Output() organizationAdd = new EventEmitter<string>();
	@Output() memberRemove = new EventEmitter<void>();

	public readonly text: () => LocaleMap;

	constructor(tp: TextProvider) {
		this.text = tp.getStringMap;
	}

	ngOnInit(): void {
		if (!this.members || !this.members.length) throw "TeamListComponentComponent initialized without a non-empty list of members";
	}

	public readonly orgDropdownGetMainText = function (member: Member): string {
		return member ? member.organization : "Organisation";
	};

	public readonly orgDropdownGetOptionText = function (opt: string): string {
		return opt;
	};

	public readonly orgDropdownCompareSelect = function (mem: Member, opt: string): boolean {
		return mem?.organization === opt;
	};

	public readonly orgDropdownChangeCallback = (mem: Member, opt: string): void => {
		this.emitDropDownValue(opt);
		mem.organization = opt;
	};

	public readonly onNameChange: (item: Member, newVal: string) => void = (item, name) => {
		item.name = name;
		this.memberEdit.emit(item);
	};

	public readonly emitDropDownValue = (value: string): void => {
		return this.dropDownValue.emit(value);
	};

	public readonly addNewMember: Function = () => {
		const lastId = this.members[this.members.length - 1].id;
		this.members.push(new Member(lastId + 1, this.members[0].id_mission, this.members[0].form));
	};

	public readonly removeMember: (numb: number) => void = (numb) => {
		const idx = this.members.findIndex((e) => e.id === numb);
		if (idx > -1) {
			this.memberRemove.emit();
			this.members.splice(idx, 1);
		}
	};
}
