import { Injectable, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";
import { UploadedFile } from "src/app/dto/net/uploaded-file";
import { WebRequestFactory } from "src/app/http/web.request.factory";



@Injectable({
	providedIn: "root"
})
export class IrisImageService {
	
    private readonly blobDictionary = new Map<number | string, Blob>();

    private readonly wreq : WebRequestFactory;

    constructor(wreq : WebRequestFactory) {
        this.wreq = wreq;
    }

    public async fetch(file : UploadedFile): Promise<Blob> {
        if (this.blobDictionary.get(file.id)) return this.blobDictionary.get(file.id)!;
        if (this.blobDictionary.get(file.filename)) return this.blobDictionary.get(file.filename)!;
         let ans;
         if (file.filename){
             ans = await this.wreq.getFileByName(file.filename);
             this.blobDictionary.set(file.filename, ans);
         } 
		if (file.id && !file.filename){
            ans = await this.wreq.getFile(file.id);
            this.blobDictionary.set(file.id, ans);
        } 
        if(!ans) throw "Cannot download file " + file.filename;
        return ans;  
    }
}
