<!-- Backdrop & close the modal on click outside it  -->
<div class="main-black-mask" (click)="closeCallback(); $event.stopPropagation()"></div>

<!-- Container class -->
<div class="edit-modal">
	<div class="title">
		<span>{{ text().SKILLS.toUpperCase() }}</span>
	</div>

	<!-- Top right Close button -->
	<app-white-cross
		class="dialog-cross-button cross-button"
		(click)="closeCallback(); $event.stopPropagation()"
	>
	</app-white-cross>

	<!-- List of skills -->
	<div class="skill-list">
		<div
			*ngFor="let skill of Skills"
			(click)="setSkillAssignation(skill.id); $event.stopPropagation()"
			class="list-item pointer"
			[class.selected]="getSkill(skill.id)"
		>
			<div class="skill-icon">
				<img
					src="{{ 'resources/img/icons-skills/' + skill.name + '.png' }}"
					width="24"
					height="24"
				/>
			</div>

			<span class="skill-name">{{ skill.name }}</span>

			<app-blue-checkbox
				class="main-skill-checkbox"
				(click)="$event.stopPropagation(); setSkillAssignation(skill.id)"
				[check]="getSkillAssignation(skill.id)"
			>
			</app-blue-checkbox>
		</div>
	</div>

	<!-- Footer -->
	<div class="modal-button-footer">
		<div class="modal-button-footer-content text-center margin-16">
			<button
				class="main-blue-gradient-button"
				(click)="closeCallback(); $event.stopPropagation()"
			>
				{{ text().CANCEL }}
			</button>
			<button
				class="main-blue-gradient-button"
				(click)="commitSkillAssignment(); $event.stopPropagation()"
			>
				{{ text().SELECT }}
			</button>
		</div>
	</div>
</div>
