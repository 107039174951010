<div class="container">
	<div class="header">
		<app-priority-chip [priority]="flat.priority">
			<div>P{{ flat.priority }}</div>
		</app-priority-chip>
	</div>
	<div class="body">
		<div class="set-text">
			<span>{{ this.text().FSG_SELECT_TEXT }}:</span>
		</div>
		<div class="option-list">
			<app-priority-chip
				*ngFor="let option of options"
				[priority]="option"
				(clickEvent)="optionClick(option)"
			>
				<div>P{{ option }}</div>
			</app-priority-chip>
		</div>
	</div>
</div>

<div class="main-black-mask" (click)="cancel.emit()"></div>
