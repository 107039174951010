import { Component, EventEmitter, Output, Input } from "@angular/core";
import { LocaleMap } from "src/app/global/constants/text/text-interface";
import { TextProvider } from "src/app/global/constants/text/text-provider";

export interface FilterFlatProps {
	withoutPriority: boolean;
	priority1: boolean;
	priority2: boolean;
	priority3: boolean;
}

@Component({
	selector: "app-filter-flats-dialog",
	templateUrl: "./filter-flats-dialog.component.html",
	styleUrls: ["./filter-flats-dialog.component.css"]
})
export class FilterFlatsDialogComponent {
	@Input() filterState!: FilterFlatProps;
	@Output() filterChangeEvent: EventEmitter<FilterFlatProps> = new EventEmitter();
	@Output() dismiss: EventEmitter<void> = new EventEmitter();

	public readonly text: () => LocaleMap;

	constructor(tp: TextProvider) {
		this.text = tp.getStringMap;
	}

	public handleBackdropClick = (): void => {
		this.dismiss.emit();
	};

	public getFilterState = (): boolean => {
		return this.filterState.withoutPriority;
	};

	handleToggleClick(): void {
		this.filterState.withoutPriority = !this.filterState.withoutPriority;

		this.filterChangeEvent.emit({
			withoutPriority: this.filterState.withoutPriority,
			priority1: false,
			priority2: false,
			priority3: false
		});
	}
}
