export enum INCIDENT_TOOL {
	OFV = "ofv",
	COMMAND = "command",
	ARA = "ara",
	CALLOUT = "callout",
	RECOMMENDATION = "recommendation",
	JESIP = "jesip",
	TACTICAL = "tactical",
	OVERLAY = "overlay"
}
