import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
	selector: "app-blue-checkbox",
	templateUrl: "blue-checkbox.component.html",
	styleUrls: ["blue-checkbox.css"]
})
export class BlueCheckboxComponent {
	@Output() clickCb = new EventEmitter<boolean>();

	@Input() disabled: boolean = false;
	@Input() inactive: boolean = false;

	@Input() check: Function = () => {
		return false;
	};

	public readonly onClick: Function = () => {
		this.clickCb.emit(!this.check());
	};
}
