import { Component, Input } from "@angular/core";

@Component({
	selector: "app-info-tooltip",
	templateUrl: "./info-tooltip.component.html",
	styleUrls: ["./info-tooltip.component.css"]
})
export class InfoTooltipComponent {
	@Input() tooltipText: string = "";
	@Input() arrowPosition: ARROW_POSITION = ARROW_POSITION.NONE;

	public readonly getPositioningClass = (): "right-shift" | "left-shift" | "" => {
		if (!this.arrowPosition) return "";
		switch (this.arrowPosition) {
			case ARROW_POSITION.UPPER_LEFT:
				return "right-shift";
			case ARROW_POSITION.UPPER_RIGHT:
				return "left-shift";
			default:
				return "";
		}
	};
}

export enum ARROW_POSITION {
	UP = 0,
	DOWN = 1,
	LEFT = 2,
	RIGHT = 3,
	NONE = 4,
	UPPER_LEFT = 5,
	UPPER_RIGHT = 6
}
