import { DTO } from "src/app/dto/net/dto";
import { ControlMeasure } from "./control-measure";

export class ControlMeasureRelation implements DTO {
	id: number;
	id_ara: number;
	id_cm: number;
	done: CM_DONE_STATUS;
	level: number;
	comments?: string | null;

	__object: ControlMeasure = new ControlMeasure(-1, "", -1);
	__isNew: boolean = false;

	timestamp = Date.now();
	timestamp_ms: number = 0;

	constructor(id: number, id_ara: number, id_cm: number, done?: CM_DONE_STATUS, level?: number, comments?: string | null) {
		this.id = id;
		this.id_ara = id_ara;
		this.id_cm = id_cm;
		this.done = done !== undefined ? done : CM_DONE_STATUS.UNSET;
		this.level = level ? level : -1;
		this.comments = comments ? comments : "";
	}

	public static readonly fromJson: (json: string) => ControlMeasureRelation = (json: string) => {
		let jc: any = JSON.parse(json);
		if (jc.done === true) jc.done = CM_DONE_STATUS.DONE;
		if (jc.done === false) jc.done = CM_DONE_STATUS.REQUESTED;
		const cmRelEl = new ControlMeasureRelation(jc.id, jc.id_ara, jc.id_cm, jc.done, jc.level, jc.comments);
		return cmRelEl;
	};

	public readonly getJson: () => string = () => {
		return JSON.stringify(this, ["id", "id_ara", "id_cm", "done", "level", "comments"]);
	};
}

export enum CM_DONE_STATUS {
	UNSET = -1,
	REQUESTED = 0,
	DONE = 1
}
