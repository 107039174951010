export class AreaType {
	id: number;
	name: string;
	color: string;
	description: string;
	pattern: PATTERN;
	visible: boolean;
	type: AREA_TYPE;

	constructor(id: number, name: string, color?: string, description?: string, pattern?: PATTERN, type?: AREA_TYPE) {
		this.id = id;
		this.name = name;
		this.color = color ? color : "#000000";
		this.description = description ? description : "";
		this.pattern = pattern ? pattern : PATTERN.NONE;
		this.visible = true;
		this.type = type ? type : AREA_TYPE.segment;
	}

	public static readonly fromJson: (json: string) => AreaType = (json: string) => {
		const jc = JSON.parse(json);
		return new AreaType(jc.id, jc.name, jc.color, jc.description, jc.pattern, jc.type);
	};

	public readonly getJson: Function = () => {
		return JSON.stringify(this, ["id", "name", "color", "description", "pattern", "type"]);
	};
}

export enum PATTERN {
	NONE = "none",
	MESH = "mesh",
	LINE = "line"
}

export enum AREA_TYPE {
	segment = "segment",
	cordon = "cordon"
}
