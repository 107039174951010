<div class="wrapper" [style.marginBottom.px]="margin">
	<div class="main-input-container">
		<input
			data-cy="simple-text-input"
			#input
			maxlength="{{ maxLength }}"
			[min]="minValue"
			[max]="maxValue"
			[type]="type === 'number' ? 'text' : type"
			[ngModel]="data"
			[disabled]="isDisabled"
			(keydown)="onKeyDown($event)"
			(ngModelChange)="onChange($event)"
			(blur)="blur()"
			(focus)="isFocused = true"
			class="main-input-field"
			[class.error]="isWithError()"
			[class.filled]="isFilled() && !isWithError()"
			[style.padding]="padding"
			[style.fontSize]="fontSize"
			[style.lineHeight]="lineHeight"
			[style.textAlign]="textAlign"
			[class.disabled]="isDisabled"
			[class.with-borders]="withBorders && !withError"
			[class.with-error]="withError"
			placeholder=""
			autocomplete="false"
		/>
		<div
			*ngIf="!isDisabled && !input.value && !isFocused"
			class="placeholder"
			(click)="input.focus()"
		>
			<span>{{ placeholderText }}</span>
			<span *ngIf="required" class="required">*</span>
		</div>
		<label
			*ngIf="label && (withError || isFocused || isFilled() || isDisabled)"
			[ngStyle]="{ 'background-color': labelBackground ? labelBackground : '#051c2a' }"
		>
			<span>{{ label }}</span>
			<span *ngIf="required" class="required-label"> *</span>
		</label>
		<div class="icon ierror" *ngIf="withError && !isPassword && !isPasswordData()"></div>
		<img
			*ngIf="isPassword && isPasswordData()"
			class="icon clickable"
			(click)="changeType('text')"
			src="resources/img/show-password.svg"
		/>
		<img
			*ngIf="isPassword && !isPasswordData()"
			class="icon clickable"
			(click)="changeType('password')"
			src="resources/img/eye-show.svg"
		/>
	</div>
	<p *ngIf="withError && errorText" class="error-text">{{ errorText }}</p>
</div>
