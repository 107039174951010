import { DTO } from "../net/dto";
import { Resource } from "../resources/resource";
import { Level } from "./level";

export class User implements DTO {
	id: number;
	name: string;
	id_level: number;
	role: string;
	password: string;
	passwordConfirm: string;
	missions_permitted: Array<number>;
	id_resource: number;
	id_picture: number | undefined;
	email: string | undefined;
	real_name: string | undefined;
	real_id: string | undefined;
	birthday: string | undefined;
	phonenumber: number | undefined;
	token: string | undefined;

	picture_filename: string | undefined;

	__resource: Resource | undefined;
	__level: Level | undefined;

	timestamp = Date.now();
	public constructor(id?: number, name?: string, id_level?: number, role?: string, missions_permitted?: Array<number>, timestamp?: number, id_resource?: number, id_picture?: number, email?: string, real_name?: string, real_id?: string, birthday?: string, phonenumber?: number, token?: string) {
		this.id = id ? id : -1;
		this.name = name ? name : "";
		this.id_level = id_level ? id_level : -1;
		this.role = role ? role.trim() : "";
		this.password = "";
		this.passwordConfirm = "";
		this.missions_permitted = missions_permitted ? missions_permitted : []; //angular.isString(missions_permitted) && missions_permitted.length > 0 ? missions_permitted.split(",").map(function(m){return parseInt(m);}) : [];
		this.id_resource = id_resource ? id_resource : -1;
		this.id_picture = id_picture ? id_picture : -1;
		this.email = email ? email : "";
		this.real_name = real_name ? real_name : "";
		this.real_id = real_id ? real_id : "";
		this.birthday = birthday;
		this.phonenumber = phonenumber;
		this.token = token;
	}
	public static readonly fromJson: (json: string) => User = (json: string) => {
		var uj = JSON.parse(json);
		return new User(uj.id, uj.name, uj.id_level, uj.role, uj.missions_permitted, uj.timestamp, uj.id_resource, uj.id_picture, uj.email, uj.real_name, uj.real_id, uj.birthday, uj.phonenumber, uj.token);
	};

	public readonly getJson: () => string = () => {
		return JSON.stringify(this, ["id", "name", "id_level", "role", "password", "timestamp", "id_resource", "id_picture", "email", "real_name", "real_id", "birthday", "phonenumber"]);
	};
	public readonly doPasswordMeetCriteria: Function = () => {
		if (this.password.toLowerCase().indexOf(this.name.toLowerCase()) !== -1) return false;
		if (this.password.length < 8) return false;
		if (/^[0-9]+$/.test(this.password)) return false;
		if (/^[a-z]+$/.test(this.password)) return false;
		if (/^[A-Z]+$/.test(this.password)) return false;

		return true;
	};
}
