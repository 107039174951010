<div
	class="side-menu closed"
	[class.extended]="extended"
	[style.zIndex]="zIndex() | async"
	(click)="$event.stopPropagation()"
>
	<div class="extend-icon extended-icon" (click)="closeCb.emit()"></div>
	<section class="container" *ngIf="items && items.length">
		<div
			*ngFor="let item of items"
			appHover
			(isHovered)="item.onHover($event)"
			class="item"
			[ngClass]="{
				hovered: getHoveredState(item),
				selected: getActiveState(item)
			}"
			(click)="item.onClick()"
		>
			<span>{{ item.title }}</span>
		</div>
	</section>
</div>
