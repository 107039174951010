import { Component, EventEmitter, Input, Output } from "@angular/core";
import { LocaleMap } from "src/app/global/constants/text/text-interface";
import { TextProvider } from "src/app/global/constants/text/text-provider";

@Component({
	selector: "app-pad-lock-open",
	templateUrl: "pad-lock-open.component.html",
	styleUrls: ["pad-lock-open.css"]
})
export class PadLockOpenComponent {
	public readonly text: () => LocaleMap;

	constructor(tp: TextProvider) {
		this.text = tp.getStringMap;
	}
}
