<div data-cy="kebab-container" class="menu-container">
	<button
		[attr.data-add-action-name]="'ef-options-' + item.title"
		*ngFor="let item of items"
		(click)="clickEvent.emit(item.id)"
		[disabled]="item.disabled"
	>
		<div class="title-section">
			<div class="item-icon icon-{{ item.id }}"></div>
			<div class="title">{{ item.title }}</div>
			<div *ngIf="item.id === 'phone'" class="action-icon">
				<img src="resources/img/support-dialog/copy-paste.svg" alt="Copy Icon" />
			</div>
			<div *ngIf="item.id === 'email' || item.id === 'domain'" class="action-icon">
				<img src="resources/img/support-dialog/redirection.svg" alt="Redirect Icon" />
			</div>
		</div>
		<div class="description">{{ item.description }}</div>
	</button>
</div>
