import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, Sanitizer, ViewChild, ViewChildren, ɵdetectChanges } from "@angular/core";
import { LocaleMap } from "src/app/global/constants/text/text-interface";
import { TextProvider } from "src/app/global/constants/text/text-provider";
import * as html2canvas from "html2canvas";
import { DocumentService } from "src/app/document/document.service";
import { DocumentTemplate } from "../../../template.interface";
import { JDM_ITEM } from "src/app/dto/jesip/jdm";
import { PAGE_SIZES } from "src/app/dto/Documents/page-sizes";
import { Incident } from "src/app/dto/items/incident";

@Component({
	selector: "app-jdm-blank-template",
	templateUrl: "jdm-blank.component.html",
	styleUrls: ["jdm-blank.css", "../../jesip.css", "../../../../document.css"]
})
export class BlankJdmTemplateComponent implements OnInit, DocumentTemplate, AfterViewInit {
	@Input() generationTime!: number;
	@Input() event!: Incident;

	@Output() docReady = new EventEmitter<HTMLCanvasElement[]>();

	@ViewChildren("pageElements") pageElements!: QueryList<ElementRef>;

	public numberOfPages: number = 1;
	public pages = new Array<Array<JdmItem>>();
	public readonly pageSize = PAGE_SIZES.STANDARD;

	public readonly text: () => LocaleMap;

	private readonly docService: DocumentService;
	private abortDownload = false;

	constructor(tp: TextProvider, doc: DocumentService) {
		this.text = tp.getStringMap;
		this.docService = doc;
	}

	ngOnInit(): void {
		if (!this.event) this.docService.downloadError$.next();
		this.setPages();
		this.docService.onDownloadCancel$.subscribe(() => this.abortDownload = true);
	}

	async ngAfterViewInit(): Promise<void> {
		try {
			const pages = this.pageElements["_results"];
			const output = new Array<HTMLCanvasElement>();
			for (let i = 0; i < pages.length; i++) {
				this.docService.setLoadingMessage(this.text().GENERATING_DOCUMENTATION_PAGE(i, pages.length));
				const canvas = await html2canvas.default(this.pageElements["_results"][i].nativeElement);
				if(this.abortDownload) return;
				output.push(canvas);
			}
			this.docReady.emit(output);
		} catch (error) {
			if(this.abortDownload) return;
			console.error(error);
			this.docService.downloadError$.next();
		}
	}

	public readonly setPages: Function = () => {
		const page = new Array<JdmItem>();
		let index = 0;
		for (let keys in JDM_ITEM) {
			page.push(new JdmItem((this.text() as any)["JESIP_JDM_ITEMS_SHORT_TITLE_" + index], (this.text() as any)["JESIP_JDM_ITEMS_DESCRIPTION_" + index]));
			index++;
		}
		this.pages.push(page);
	};
}

class JdmItem {
	header: string;
	question: string;

	constructor(h: string, q: string) {
		this.header = h;
		this.question = q;
	}
}
