import { Component, Input } from "@angular/core";
import { NavTabItem } from "src/app/dto/ui/tab";

@Component({
	selector: "app-blue-bar-nav-tab",
	templateUrl: "blue-bar-nav-tab.component.html",
	styleUrls: ["blue-bar-nav-tab.css"]
})
export class BlueBarNavTabComponent {
	@Input() tabs: Array<NavTabItem> = [];
	@Input() filledTabs: Array<number> = [];

	@Input() getActiveTab: Function = () => {
		return 0;
	};

	onClickOption: Function = (tab: any) => {
		tab.callback(tab);
	};

	public readonly isFilled = (tabIndex: number): boolean => {
		return this.filledTabs.includes(tabIndex);
	};
}
