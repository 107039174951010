import { Directive, ElementRef, Input, AfterViewInit, Output, EventEmitter } from "@angular/core";

@Directive({
	selector: "[appLongTap]"
})
export class LongTapDirective implements AfterViewInit {
	@Output() onlongtap = new EventEmitter<TouchEvent>();

	public static LONG_PRESS_TIMEOUT_MS = 500;

	private elem: HTMLElement;

	private tapTime = 0;
	private timeInterval = -1;

	constructor(el: ElementRef) {
		this.elem = el.nativeElement;
	}

	ngAfterViewInit(): void {
		this.elem.ontouchstart = this.onTouchStart;
	}

	onTouchStart = (evt: TouchEvent): void => {
		this.timeInterval = window.setInterval(() => {
			this.tapTime += 1000;
			if (this.tapTime >= LongTapDirective.LONG_PRESS_TIMEOUT_MS) this.onlongtap.emit(evt);
		}, 1000);
		document.body.ontouchend = this.onTouchEnd;
		evt.preventDefault();
	};

	onTouchEnd = (evt: TouchEvent): void => {
		clearInterval(this.timeInterval);
		this.tapTime = 0;
		document.body.ontouchend = null;
		evt.preventDefault();
	};
}
