<div class="container">
	<div class="header">
		<div class="title">
			<div *ngIf="initial" class="header-icon" [style.backgroundColor]="getColour()">
				{{ initial }}
			</div>
			<span>{{ title }}</span>
		</div>
		<div class="subtitle">
			<div>
				<div class="last-update">
					<div class="subtitle-icon">
						<img src="resources/img/icons-fsg/fsg-mini-clock-icon.svg" />
					</div>
					<b>{{ text().LAST_UPDATE }}: </b>
					<span> {{ getTime() }}</span>
				</div>
				<div class="by">
					<div class="subtitle-icon">
						<img src="resources/img/jesip/author-icon.svg" />
					</div>
					<b>{{ text().BY }}:</b>
					<span> {{ getAuthor() }}</span>
				</div>
			</div>
		</div>
	</div>
	<div class="line"></div>
	<div class="footer">
		<app-notification [updateAvailable]="updateAvailable"></app-notification>
		<button
			data-add-action-name="jesip-confirmation-button"
			class="main-blue-gradient-border-button margin-left-auto"
			(click)="buttonClick.emit($event)"
		>
			{{ buttonText }}
		</button>
	</div>
	<div class="icon" *ngIf="backgroundSrc">
		<img [attr.src]="backgroundSrc" />
	</div>
</div>
