<div class="app-kml-item">
	<div *ngIf="kml.attachment_id > -1" class="kml-picture">
		<div class="background"></div>
		<div class="foreground">
			<span>KML</span>
		</div>
	</div>
	<div *ngIf="kml.attachment_id === -1" class="kml-picker">
		<span>{{ text().SELECT_KML }}</span>
		<span class="size-text">(max 10MB)</span>
		<label class="kml-uploader">
			<input data-add-action-name="kml-file-input" id="file-upload" type="file" (change)="addNewFile($event)" accept=".kml, .kmz" />
			{{ text().SELECT_FILE }}
		</label>
	</div>
	<div class="kml-inputs">
		<div class="name" appScrollToElement [scrollIdentifierId]="kml.id">
			<input
			data-add-action-name="kml-name-input"
				class="card-input kml-input"
				required=""
				[(ngModel)]="kml.name"
				placeholder="{{ text().NAME }}"
				type="text"
				title=""
			/>
		</div>
		<div class="mission">
			<app-transparent-dropdown
				[object]="kml"
				[options]="incidents"
				[getObjectText]="incidentDropdownGetMainText"
				[getOptionText]="incidentDropdownGetOptionText"
				[compareSelect]="incidentDropdownCompareSelect"
				(itemClickCallback)="incidentDropdownChangeCallback($event)"
			></app-transparent-dropdown>
		</div>
	</div>
</div>
<button
	data-add-action-name="save-kml"
	type="submit"
	*ngIf="hasKmlBeenModified()"
	class="main-blue-gradient-button card-bottom-button"
	[disabled]="!(kml.name && kml.attachment_filename)"
	(click)="save()"
>
	{{ text().SAVE }}
</button>
<button
	data-add-action-name="delete-kml"
	type="submit"
	*ngIf="canDelete"
	class="main-blue-gradient-button card-bottom-button"
	(click)="delete()"
>
	{{ text().DELETE }}
</button>
