import { SafeResourceUrl } from "@angular/platform-browser";

export class UploadedFile {
	id: number;
	filename: string;
	id_object: number;
	timestamp: number;

	__file: File | Blob | undefined;
	__uri: string | SafeResourceUrl | undefined;

	// this flag marks whether the file has been uploaded from another user (has come from the server), case in which it'll be true,
	// or from the user itself, case in which it'll be false
	__unseen: boolean = false;

	constructor(id?: number, filename?: string, id_object?: number, timestamp?: number) {
		this.id = id ? id : -1;
		this.filename = filename ? filename : "";
		this.id_object = id_object ? id_object : -1;
		this.timestamp = timestamp ? timestamp : Date.now();
	}

	public static readonly fromJson: Function = (json: string) => {
		let upj = JSON.parse(json);
		return new UploadedFile(upj.id, upj.filename, upj.id_object, upj.timestamp);
	};

	
	public readonly getJson: () => string = () => {
		return JSON.stringify(this, ["id", "filename", "id_object", "timestamp"]);
	};
}
