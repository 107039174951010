<div data-cy="csSupport" class="node-container">
	<div class="title">
		<span *ngIf="!editable">{{ this.object.title ? this.object.title : text().SUPPORT }}</span>
		<app-simple-text-field
			class="name-input"
			*ngIf="editable"
			[type]="'text'"
			[padding]="padding"
			[data]="object.title"
			(dataChange)="setSupportTitle($event)"
			[placeholderText]="text().TITLE"
			[label]="text().TITLE"
			[maxLength]="20"
		></app-simple-text-field>
		<app-blue-cross
			*ngIf="!disable"
			class="menu-button {{ isMenuOpen() ? 'open' : '' }}"
			(clickCb)="openMenu($event)"
			[open]="isMenuOpen"
			[isInCommandSector]="true"
		>
		</app-blue-cross>
	</div>
	<div class="body">
		<div class="assigned">
			<div class="name-text">
				<span *ngIf="!editable">{{ object.name ? object.name : text().NEW_SUPPORT }}</span>
				<app-simple-text-field
					class="name-input"
					*ngIf="editable"
					[type]="'text'"
					[padding]="padding"
					[data]="object.name"
					(dataChange)="setSupportName($event)"
					[placeholderText]="text().NAME"
					[label]="text().NAME"
					[maxLength]="20"
				></app-simple-text-field>
			</div>
			<div class="capability-text">
				<span *ngIf="!editable"
					>{{ object.capabilities ? object.capabilities : text().CAPABILITY }}
				</span>
				<app-simple-text-field
					class="name-input"
					*ngIf="editable"
					[type]="'text'"
					[padding]="padding"
					[data]="object.capabilities"
					(dataChange)="setSupportCapabilities($event)"
					[placeholderText]="text().CAPABILITY"
					[label]="text().CAPABILITY"
					[maxLength]="20"
				></app-simple-text-field>
			</div>
		</div>
	</div>
</div>
<div class="extended-menu" *ngIf="isMenuOpen()">
	<div class="title"></div>
	<div class="option" (click)="removeSupport.emit()">{{ text().DELETE }}</div>
	<div class="option" (click)="toggleEditable()">{{ text().EDIT }}</div>
</div>
