<div class="main-black-mask" (click)="onCancel()"></div>
<div class="container">
	<app-black-nav-tab
		[tabs]="currentTabs"
		[hasNavigation]="true"
		[areThereMoreTabsFw]="calculateFwArrow()"
		[areThereMoreTabsBw]="calculateBwArrow()"
		[getActiveTab]="getActiveTab"
		[incrementTabIndex]="incrementTabIndex"
		[decrementTabIndex]="decrementTabIndex"
	></app-black-nav-tab>
	<div class="form">
		<div class="image-section">
			<div class="image-container">
				<img
					*ngIf="getCurrentFloor() && getCurrentFloor().fileImg"
					[src]="getCurrentFloor().fileImg! | sanitizeUrl"
				/>
				<label class="file-edit">
					<input
						svg-icon-select
						accept=".jpg, .jpeg, .png, .svg, .pdf"
						uploader="fileUploader"
						id="file-upload"
						(change)="
							getCurrentFloor() && getCurrentFloor().id_img > -1
								? modifyFile($event)
								: addNewFile($event)
						"
						type="file"
					/>
					<span *ngIf="getCurrentFloor() && getCurrentFloor().id_img > -1">{{
						text().CHANGE_FILE
					}}</span>
					<span
						*ngIf="getCurrentFloor() && getCurrentFloor().id_img === -1"
						class="main-blue-gradient-button file-button"
						>{{ text().SELECT_FILE }}</span
					>
				</label>
				<div
					class="delete-tag"
					(click)="deleteFloor()"
					*ngIf="getCurrentFloor() && getCurrentFloor() !== this.schematic"
				>
					{{ text().DELETE }}
				</div>
			</div>
		</div>
		<div class="inputs-section">
			<app-simple-text-field
				*ngIf="getCurrentFloor()"
				[data]="getCurrentFloor().name"
				(dataChange)="refreshFloorName($event)"
				[type]="'text'"
				[placeholderText]="text().NAME"
				[label]="text().NAME"
				[labelBackground]="'#080809'"
			></app-simple-text-field>
			<app-simple-text-box
				[data]="schematic.description"
				(dataChange)="refreshComments($event)"
				[type]="'text'"
				[placeholderText]="text().DETAILS"
				[label]="text().DETAILS"
				[minHeight]="96"
				[maxHeight]="96"
			></app-simple-text-box>
		</div>
	</div>
	<div class="footer">
		<button class="main-blue-gradient-button" [disabled]="areMandatoryFieldsEmpty()" (click)="saveChanges()">{{ text().SAVE }}</button>
	</div>
</div>
