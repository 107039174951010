import { ResourceType } from "../items/types/resource-type";
import { AreaType } from "../items/types/area-type";
import { CameraType } from "../items/types/camera-type";
import { IncidentType } from "../items/types/event-type";
import { PoiType } from "../items/types/poi-type";
import { DTO } from "../net/dto";
import { LANGUAGE } from "src/app/global/constants/enums/languages";
import { SCREEN } from "src/app/global/constants/enums/screens";

export class Configuration implements DTO {
	id: number;
	poiTypes: Array<PoiType>;
	resourceTypes: Array<ResourceType>;
	areaTypes: Array<AreaType>;
	incidentTypes: Array<IncidentType>;
	text: any;
	languages: any;
	language: LANGUAGE;
	custom_homepage: SCREEN | null;
	id_current_incident: number;
	milestone_user: string | null;
	milestone_pass: string | null;
	milestone_url: string | null;

	timestamp = Date.now();
	public constructor(
		id: number,
		poiTypes: Array<PoiType> | null,
		agentTypes: Array<ResourceType> | null,
		areaTypes: Array<AreaType> | null,
		eventTypes: Array<IncidentType> | null,
		text: string,
		languages: Array<any> | null,
		language: LANGUAGE,
		custom_homepage: string,
		milestone_user: string,
		milestone_pass: string,
		milestone_url: string,
		id_current_mission: number
	) {
		this.id = id;
		this.poiTypes = poiTypes ? poiTypes : [];
		this.resourceTypes = agentTypes ? agentTypes : [];
		this.areaTypes = areaTypes ? areaTypes : [];
		this.incidentTypes = eventTypes ? eventTypes : [];
		this.text = text;
		this.languages = languages;
		this.language = language;
		this.custom_homepage = custom_homepage === "/home" ? SCREEN.HOME : SCREEN.INCIDENT;
		this.id_current_incident = id_current_mission;
		this.milestone_user = milestone_user;
		this.milestone_pass = milestone_pass;
		this.milestone_url = milestone_url;
	}

	public static readonly fromJson: (json: string) => Configuration = (json: string) => {
		let jc: any = json;
		while (typeof jc === "string") jc = JSON.parse(jc);
		let tempPois: Array<PoiType> = [];
		jc.poiTypes.forEach((poi: any) => {
			tempPois.push(new PoiType(poi.id, poi.name, poi.color, poi.description, poi.icon_path, poi.icon_size, poi.is_global, poi.specialType));
		});

		let tempAgents: Array<ResourceType> = [];
		jc.resourceTypes.forEach((res: any) => {
			tempAgents.push(new ResourceType(res.id, res.name, res.color, res.description, res.icon_path, res.icon_size, res.fixed_position));
		});

		let tempAreas: Array<AreaType> = [];
		jc.areaTypes.forEach((res: any) => {
			tempAreas.push(new AreaType(res.id, res.name, res.color, res.description, res.pattern, res.type));
		});

		let tempEvents: Array<IncidentType> = [];
		jc.eventTypes.forEach((res: any) => {
			tempEvents.push(new IncidentType(res.id, res.name, res.color));
		});

		if (jc.language.trim()) {
			switch (jc.language) {
				case "en":
					jc.language = LANGUAGE.ENGLISH;
					break;
				case "es":
					jc.language = LANGUAGE.SPANISH;
					break;
				default:
					jc.language = navigator.language.startsWith("es") ? LANGUAGE.SPANISH : LANGUAGE.ENGLISH;
			}
		} else jc.language = navigator.language.startsWith("es") ? LANGUAGE.SPANISH : LANGUAGE.ENGLISH;

		return new Configuration(jc.id, tempPois, tempAgents, tempAreas, tempEvents, jc.text, jc.languages, jc.language, jc.custom_homepage, jc.milestone_user, jc.milestone_pass, jc.milestone_url, jc.id_current_incident ? jc.id_current_incident : jc.id_current_mission);
	};

	public static readonly voidConfiguration: Function = () => {
		return new Configuration(-1, null, null, null, null, "", null, LANGUAGE.ENGLISH, "", "", "", "", -1);
	};

	public readonly getJson: () => string = () => {
		return JSON.stringify(this, ["id", "language", "custom_homepage", "milestone_user", "milestone_pass", "milestone_url", "id_current_incident"]);
	};

	public readonly voidConfiguration: Function = () => {
		this.id = -1;
		this.poiTypes = [];
		this.resourceTypes = [];
		this.areaTypes = [];
		this.incidentTypes = [];
		this.text = null;
		this.languages = null;
		this.language = LANGUAGE.ENGLISH;
		this.custom_homepage = null;
		this.id_current_incident = -1;
		this.milestone_user = null;
		this.milestone_pass = null;
		this.milestone_url = null;
	};
}
