import { DTO } from "src/app/dto/net/dto";
import { FLAT_SECTOR } from "./enums";

export class FsgFlat implements DTO {
	id_fsg: number;
	id_incident: number;
	deleted: boolean;
	timestamp_ms: number;
	id_flat: number;
	id_last_value: number;
	source_of_information: string;
	floor_number: number;
	flat_pos: number;
	flat_name: string;
	flat_type: string;
	people: number | null;
	fsg_advice: string;
	priority: number;
	comments: string;
	casualty_status: string;
	cleared: boolean;
	clear_time_ms: number;
	status: FLAT_SECTOR;
	condition: Array<string>;
	custom_flat: boolean;
	idUser: number;

	// useful for EF historic document, in any other context should be ignored
	__changeAttr: undefined | keyof FsgFlat;

	id: number;
	timestamp = Date.now();
	constructor(
		id_fsg: number,
		id_flat: number,
		id_mission: number,
		id_last_value: number,
		flat_name: string,
		flat_type?: string,
		source_of_information?: string,
		floor_number?: number,
		flat_pos?: number,
		people?: number | null,
		fsg_advice?: string,
		priority?: number,
		comments?: string,
		casualty_status?: string,
		cleared?: boolean,
		clear_time_ms?: number,
		condition?: Array<string>,
		deleted?: boolean,
		timestamp_ms?: number,
		status?: FLAT_SECTOR,
		custom_flat?: boolean,
		idUser?: number
	) {
		this.id = this.id_flat = id_flat || -1;
		this.id_fsg = id_fsg || -1;
		this.id_incident = id_mission || -1;
		this.id_last_value = id_last_value || -1;
		this.source_of_information = source_of_information ? source_of_information : "-1";
		this.floor_number = floor_number ? floor_number : 0;
		this.flat_pos = flat_pos || -1;
		this.flat_name = flat_name || "";
		this.flat_type = flat_type || "Flat";
		this.people = people && people !== -1 ? people : null;
		this.fsg_advice = fsg_advice || "";
		this.priority = priority || -1;
		this.comments = comments || "";
		this.casualty_status = casualty_status ? casualty_status : "";
		this.cleared = cleared || false;
		this.clear_time_ms = clear_time_ms || -1;
		this.condition = condition || [];
		this.deleted = deleted || false;
		this.timestamp_ms = timestamp_ms === undefined ? Date.now() : timestamp_ms;
		this.status = typeof status === "number" ? status : FLAT_SECTOR.DEFAULT;
		this.custom_flat = custom_flat ?? true;
		this.idUser = idUser ?? -1;
	}

	public static readonly fromJson: (json: string) => FsgFlat = (json: string) => {
		const jc = JSON.parse(json);
		if (typeof jc.condition === "string") jc.condition = jc.condition.split(",");
		return new FsgFlat(
			jc.id_fsg,
			jc.id_flat,
			jc.id_incident,
			jc.id_last_value,
			jc.flat_name,
			jc.flat_type,
			jc.source_of_information,
			jc.floor_number,
			jc.flat_pos,
			jc.people,
			jc.fsg_advice,
			jc.priority,
			jc.comments,
			jc.casualty_status,
			jc.cleared,
			jc.clear_time_ms,
			jc.condition,
			jc.deleted,
			jc.timestamp_ms,
			jc.status,
			jc.custom_flat,
			jc.idUser
		);
	};

	public readonly getJson: Function = () => {
		let dtoObject: any = { ...this };
		if (typeof this.condition === "object") {
			dtoObject.condition = this.condition.join(",");
		}
		return JSON.stringify(dtoObject, [
			"id_fsg",
			"id_flat",
			"id_incident",
			"id_last_value",
			"source_of_information",
			"floor_number",
			"flat_pos",
			"flat_name",
			"flat_type",
			"people",
			"fsg_advice",
			"priority",
			"comments",
			"casualty_status",
			"cleared",
			"clear_time_ms",
			"condition",
			"deleted",
			"timestamp_ms",
			"status",
			"custom_flat",
			"idUser"
		]);
	};
}
