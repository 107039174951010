import { Injectable } from "@angular/core";

import { SettingsParser } from "src/app/global/constants/settings.parser";

@Injectable({
	providedIn: "root"
})
export class FunctionalityService {
	public functionality = new Map<FUNCTIONALITY, boolean>();
	public isFunctionalityAvailable = new Map<FUNCTIONALITY, boolean>();
	public isDLType: boolean = true;
	public isDLSignature: boolean = true;
	public isDLOperational: boolean = true;
	public isAraRight: boolean = true;
	public isAraScore: boolean = true;

	constructor() {}
	public readonly setFunctionalityVisibility: Function = () => {
		const keys = Object.keys(FUNCTIONALITY);
		keys.forEach((key) => {
			this.isFunctionalityAvailable.set((FUNCTIONALITY as any)[key], true);
		});
		if (!SettingsParser.getSettings().hide) return;
		if (SettingsParser.getSettings().hide[FUNCTIONALITY.RECOMMENDER] !== false) {
			this.isFunctionalityAvailable.set(FUNCTIONALITY.RECOMMENDER, false);
		}
		if (SettingsParser.getSettings().hide[FUNCTIONALITY.CHECKLIST] !== false) {
			this.isFunctionalityAvailable.set(FUNCTIONALITY.CHECKLIST, false);
		}
		if (SettingsParser.getSettings().hide[FUNCTIONALITY.DECISION] !== false) {
			this.isFunctionalityAvailable.set(FUNCTIONALITY.DECISION, false);
		}
		if (SettingsParser.getSettings().hide[FUNCTIONALITY.DL_SIGNATURE] !== false) {
			this.isFunctionalityAvailable.set(FUNCTIONALITY.DL_SIGNATURE, false);
			this.isDLSignature === false;
		}
		if (SettingsParser.getSettings().hide[FUNCTIONALITY.DL_OPERATIONAL] !== false) {
			this.isFunctionalityAvailable.set(FUNCTIONALITY.DL_OPERATIONAL, false);
			this.isDLOperational === false;
		}
		if (SettingsParser.getSettings().hide[FUNCTIONALITY.DL_TYPE] !== false) {
			this.isFunctionalityAvailable.set(FUNCTIONALITY.DL_TYPE, false);
			this.isDLType === false;
		}
		if (SettingsParser.getSettings().hide[FUNCTIONALITY.METHANE] !== false) {
			this.isFunctionalityAvailable.set(FUNCTIONALITY.METHANE, false);
		}
		if (SettingsParser.getSettings().hide[FUNCTIONALITY.JDM] !== false) {
			this.isFunctionalityAvailable.set(FUNCTIONALITY.JDM, false);
		}
		if (SettingsParser.getSettings().hide[FUNCTIONALITY.IIMARCH] !== false) {
			this.isFunctionalityAvailable.set(FUNCTIONALITY.IIMARCH, false);
		}
		if (SettingsParser.getSettings().hide[FUNCTIONALITY.DEBRIEF] !== false) {
			this.isFunctionalityAvailable.set(FUNCTIONALITY.DEBRIEF, false);
		}
		if (SettingsParser.getSettings().hide[FUNCTIONALITY.EF] !== false) {
			this.isFunctionalityAvailable.set(FUNCTIONALITY.EF, false);
		}
		if (SettingsParser.getSettings().hide[FUNCTIONALITY.CALLOUT] !== false) {
			this.isFunctionalityAvailable.set(FUNCTIONALITY.CALLOUT, false);
		}
		if (SettingsParser.getSettings().hide[FUNCTIONALITY.TACTICALD] !== false) {
			this.isFunctionalityAvailable.set(FUNCTIONALITY.TACTICALD, false);
		}
		if (SettingsParser.getSettings().hide[FUNCTIONALITY.SUPPORTPHONE] !== false) {
			this.isFunctionalityAvailable.set(FUNCTIONALITY.SUPPORTPHONE, false);
		}
		if (SettingsParser.getSettings().hide[FUNCTIONALITY.CS] !== false) {
			this.isFunctionalityAvailable.set(FUNCTIONALITY.CS, false);
		}
		if (SettingsParser.getSettings().hide[FUNCTIONALITY.ARA_RIGHT] !== false) {
			this.isFunctionalityAvailable.set(FUNCTIONALITY.ARA_RIGHT, false);
		}
		if (SettingsParser.getSettings().hide[FUNCTIONALITY.ARA_SCORE] !== false) {
			this.isFunctionalityAvailable.set(FUNCTIONALITY.ARA_SCORE, false);
		}
	};

	public getFunctionalityVisibility: Function = () => {
		this.isAraRight = this.isFunctionalityAvailable.get(FUNCTIONALITY.ARA_RIGHT)!;
		this.isAraScore = this.isFunctionalityAvailable.get(FUNCTIONALITY.ARA_SCORE)!;
		this.isDLOperational = this.isFunctionalityAvailable.get(FUNCTIONALITY.DL_OPERATIONAL)!;
		this.isDLSignature = this.isFunctionalityAvailable.get(FUNCTIONALITY.DL_SIGNATURE)!;
		this.isDLType = this.isFunctionalityAvailable.get(FUNCTIONALITY.DL_TYPE)!;
	};
}
export enum FUNCTIONALITY {
	RECOMMENDER = "recommendation",
	CHECKLIST = "jchecklist",
	DECISION = "decisionLog",
	METHANE = "jmethane",
	JDM = "jjdm",
	IIMARCH = "jiimarch",
	DEBRIEF = "debrief",
	EF = "eF",
	CALLOUT = "callout",
	TACTICALD = "tacticalD",
	SUPPORTPHONE = "supportP",
	CS = "cS",
	ARA_SCORE = "araScore",
	ARA_RIGHT = "araRight",
	DL_SIGNATURE = "dlSignature",
	DL_TYPE = "dlType",
	DL_OPERATIONAL = "dlOperational"
}
