/**
 * Contains constants for map items types.
 * @property MAP_ITEM_TYPE
 * @type Object
 */
export enum MAP_ITEM_TYPE {
	AREA = "area",
	POI = "poi",
	RESOURCE = "resource",
	OVERLAY = "overlay",
	KML = "kml",
	FSG = "fsg",
	TELEMETRY = "telemetry",
	COMMANDER = "commander"
}
