import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild, ɵdetectChanges } from "@angular/core";
import { SLRating } from "src/app/dto/ara/risk-information-relation";
import { LocaleMap } from "src/app/global/constants/text/text-interface";
import { TextProvider } from "src/app/global/constants/text/text-provider";
import { SLMatrixComponent } from "../../../elements/sl-matrix/sl-matrix.component";

@Component({
	selector: "app-ara-severity-rating-tooltip",
	templateUrl: "ara-severity-rating-tooltip.component.html",
	styleUrls: ["ara-severity-rating-tooltip.css"]
})
export class AraSeverityRatingTooltipComponent implements AfterViewInit {
	@Input() initialRating : SLRating = { severity : 0, likelihood : 0 };

	@Output() cancelCallback = new EventEmitter();

	@ViewChild(SLMatrixComponent) matrix! : SLMatrixComponent;
	
	public readonly text: () => LocaleMap;

	constructor(textProv: TextProvider) {
		this.text = textProv.getStringMap;
	}

	ngAfterViewInit(): void {
		if(this.initialRating.severity && this.initialRating.likelihood){
			this.matrix.selectCell(this.initialRating.likelihood, this.initialRating.severity);	
			ɵdetectChanges(this);
		} 
	}
	

	public readonly cancel: Function = () => {
		this.cancelCallback.emit();
	};
}
