<div *ngIf="extended" class="main-black-mask" (click)="closeMenu(); $event.stopPropagation()"></div>
<app-fixed-bar
	[extended]="extended"
	[items]="items"
	[canEditIncidents]="canEditIncidents"
	[isInHistoric]="isInHistoric"
	(goToForm)="goToForm($event)"
	(reopenIncident)="reopenIncident.emit(); closeMenu()"
	(openCb)="openMenu()"
></app-fixed-bar>
<app-extended-menu [items]="items" [extended]="extended" (closeCb)="closeMenu()">
</app-extended-menu>
