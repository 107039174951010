<div class="main-black-mask" (click)="cancelCallback.emit()"></div>
<div class="edit-modal">
	<div class="title">
		{{ text().AGENT_TYPE.toUpperCase() }}
	</div>
	<app-white-cross
		class="dialog-cross-button cross-button"
		(clickCb)="cancelCallback.emit()"
	></app-white-cross>
	<div class="new-resource-type-input">
		<app-simple-text-field
			class="name-input"
			(dataChange)="refreshNewName($event)"
			[placeholderText]="text().NAME"
			[label]="text().NAME"
		></app-simple-text-field>
	</div>
	<div class="resource-types-list">
		<li *ngFor="let type of resourceTypes">
			{{ type.name }}
			<button (click)="delete(type)" class="main-blue-gradient-button type-delete-button">
				<img src="resources/img/delete.svg" />
			</button>
		</li>
	</div>
	<div class="footer-buttons">
		<button class="main-blue-gradient-button" (click)="save()">{{ text().SAVE }}</button>
	</div>
</div>
