import { Component, Input, Output, EventEmitter } from "@angular/core";

import { LocaleMap } from "src/app/global/constants/text/text-interface";
import { TextProvider } from "src/app/global/constants/text/text-provider";
import { UserService } from "src/app/settings/user/user.service";

@Component({
	selector: "app-signature-button",
	templateUrl: "signature-button.component.html",
	styleUrls: ["signature-button.css"]
})
export class SignatureButtonComponent {
	@Input() disabled: boolean = false;
	@Input() inError: boolean = false;

	@Input() ready: boolean = false;

	@Output() openEvent = new EventEmitter<Event>();

	public text: () => LocaleMap;

	private readonly userService: UserService;

	constructor(textProv: TextProvider, userService: UserService) {
		this.text = textProv.getStringMap;
		this.userService = userService;
	}

	public openSignatureCanvas(event: Event): void {
		this.openEvent.emit(event);
	}

	public canSign(): boolean {
		return this.userService.getCurrentUserLevel().sign_ara;
	}
}
