import { Component } from "@angular/core";
import { ConfirmationDialog, ErrorBannerData, ErrorDialogData } from "src/app/dto/ui/confirmation-dialog";
import { MainService } from "../../main.service";

@Component({
	selector: "app-main-dialog",
	templateUrl: "main-dialog.component.html"
})
export class MainDialogComponent {
	private readonly main: MainService;

	constructor(mainServ: MainService) {
		this.main = mainServ;
	}

	get errorBanner(): ErrorBannerData {
		return this.main.showErrorBanner;
	}

	get ErrorDialogComponentParams(): ErrorDialogData {
		return this.main.ErrorDialogComponent;
	}

	get confirmationDialogParams(): ConfirmationDialog {
		return this.main.confirmationDialog;
	}

	closeErrorDialogComponent(): void {
		if (this.ErrorDialogComponentParams.closeCallback) this.ErrorDialogComponentParams.closeCallback();
		this.main.closeAlert();
	}
}
