import { Component, OnInit } from "@angular/core";
import { AUTH } from "src/app/global/constants/enums/auth";
import { ConstantsService } from "src/app/global/constants/constants.service";
import { LocaleMap } from "src/app/global/constants/text/text-interface";
import { TextProvider } from "src/app/global/constants/text/text-provider";
import { MESSAGE_TYPE } from "src/app/global/messaging/messages";
import { MessagingService } from "src/app/global/messaging/messaging.service";
import { NavigationService, Route } from "../../navigation.service";
import { PATH, SCREEN } from "../../../global/constants/enums/screens";

@Component({
	selector: "app-main-nav",
	templateUrl: "main-navigation.component.html"
})
export class MainNavigationComponent implements OnInit {
	public routes: Array<any> = [];

	public shouldShowNavigation: Function;

	private readonly navServ: NavigationService;
	private readonly mssg: MessagingService;
	private readonly navIcons: any;
	private readonly cnst: ConstantsService;
	private readonly text: () => LocaleMap;

	constructor(nav: NavigationService, mssg: MessagingService, cnst: ConstantsService, textProv: TextProvider) {
		this.navServ = nav;
		this.mssg = mssg;
		this.cnst = cnst;
		this.text = textProv.getStringMap;
		this.shouldShowNavigation = this.navServ.shouldShowNavigation;

		this.navIcons = {
			home: "resources/img/world.svg",
			configuration: "resources/img/settings.svg",
			assets: "resources/img/resource.svg",
			users: "resources/img/004-padlock.svg",
			account: "resources/img/profile.svg",
			logout: "resources/img/LOGOUT.png"
		};
	}

	ngOnInit(): void {
		this.mssg.registerListener(MESSAGE_TYPE.LOAD_CONFIGURATION, this.setRoutes);
		this.navServ.$authorizedPagesChange.subscribe(this.setRoutes);
		this.setRoutes();
	}

	public goToAndSetTab: Function = (route: any, idx: number): void => {
		this.navServ.goTo(route.path);
		this.navServ.tabIndex = idx;
	};

	private readonly setRoutes = (): void => {
		const navText = {
			home: this.text().HOME_MENU,
			configuration: this.text().CONFIGURATION_MENU,
			assets: this.text().RESOURCES_CONFIGURATION,
			users: this.cnst.AUTH === AUTH.SSO ? this.text().USER_LEVELS : this.text().USER_CONFIGURATION,
			account: this.text().ACCOUNT_MENU,
			logout: this.text().LOGOUT
		};

		const subOptions = {
			home: [],
			assets: [
				{
					name: this.text().PERSONNEL,
					route: { path: SCREEN.ASSETS },
					tabIndex: 0,
					clickCb: this.goToAndSetTab
				},
				{
					name: this.text().APPLIANCES,
					route: { path: SCREEN.ASSETS },
					tabIndex: 1,
					clickCb: this.goToAndSetTab
				},
				{
					name: this.text().CAMERAS,
					route: { path: SCREEN.ASSETS },
					tabIndex: 2,
					clickCb: this.goToAndSetTab
				},
				{
					name: this.text().STATUS,
					route: { path: SCREEN.ASSETS },
					tabIndex: 3,
					clickCb: this.goToAndSetTab
				}
			],
			configuration: [
				{
					name: "POI",
					route: { path: SCREEN.CONFIGURATION },
					tabIndex: 0,
					clickCb: this.goToAndSetTab
				},
				{
					name: this.text().AREAS,
					route: { path: SCREEN.CONFIGURATION },
					tabIndex: 1,
					clickCb: this.goToAndSetTab
				},
				{
					name: this.text().EVENT_TYPES,
					route: { path: SCREEN.CONFIGURATION },
					tabIndex: 2,
					clickCb: this.goToAndSetTab
				},
				{
					name: this.text().KML_FILES,
					route: { path: SCREEN.CONFIGURATION },
					tabIndex: 3,
					clickCb: this.goToAndSetTab
				}
			],
			users: [
				{
					name: this.text().USERS,
					route: { path: SCREEN.USERS },
					tabIndex: 0,
					clickCb: this.goToAndSetTab
				},
				{
					name: this.text().LEVELS,
					route: { path: SCREEN.USERS },
					tabIndex: 1,
					clickCb: this.goToAndSetTab
				}
			],
			logout: []
		};
		if (this.cnst.AUTH === AUTH.SSO) subOptions["users"] = [];
		this.routes = [];
		this.navServ.navRoutes.forEach((route: Route) => {
			if (route.menu_index !== undefined && this.navServ.isAuthorized(route.name)) {
				const idx = route.menu_index;
				this.routes.splice(idx, 0, {
					name: (navText as any)[route.name],
					route: route.name,
					goCallback: () => {
						this.navServ.goTo(route.path);
					},
					iconLocation: this.navIcons[route.name],
					options: (subOptions as any)[route.name]
				});
			}
		});
		this.routes.push({
			name: "logout",
			goCallback: () => {
				this.navServ.logOut();
			},
			iconLocation: this.navIcons["logout"]
		});
	};
}
