import { Component, Input } from "@angular/core";
import { Resource } from "src/app/dto/resources/resource";

@Component({
	selector: "app-resource-tooltip",
	templateUrl: "resource-tooltip.component.html",
	styleUrls: ["resource-tooltip.css"]
})
export class ResourceTooltipComponent {
	@Input() resource!: Resource;

	constructor() {}

	public getStateColor(): string {
		return this.resource.__state ? this.resource.__state.color : "black";
	}
}
