<div data-cy="ef-form-container" class="form-container">
	<div class="title">{{ text().FILTERS }}</div>
	<span class="inline-field">
		<div>{{ text().FILTERS_SHOW_PRIORITISED }}</div>
		<app-toggle-switch
			data-add-action-name="ef-toogle-switch"
			class="toggle-switch"
			(clickCb)="handleToggleClick()"
			[toggle]="getFilterState"
		></app-toggle-switch>
	</span>
</div>

<div data-add-action-name="ef-backdrop" class="backdrop" (click)="handleBackdropClick()"></div>
