import { APP_BASE_HREF } from "@angular/common";
import { Inject, Pipe, PipeTransform } from "@angular/core";

@Pipe({
	name: "baseHref"
})
export class BaseHrefPipe implements PipeTransform {
	baseUrl: string;
	constructor(@Inject(APP_BASE_HREF) baseHref: string) {
		this.baseUrl = baseHref;
	}

	transform(value: string): string {
		return this.baseUrl + value;
	}
}
