<div *ngIf="fsg">
	<div class="naming-header">{{ text().NAMING_CONVENTION }}</div>
	<div class="naming">
		<app-round-checkbox
			data-add-action-name="ef-naming-alphabetical"
			[check]="getNamingConvention(fsgNaming.ALPHABETICAL)"
			(clickCb)="setNamingConvention(fsgNaming.ALPHABETICAL)"
		></app-round-checkbox>
		<span class="naming-convention">{{ text().ALPHABETICAL }}</span>
		<span class="naming-hint">{{ text().FSG_CREATION.FLATS }} A, B, C...</span>
	</div>
	<div class="naming">
		<app-round-checkbox
			data-add-action-name="ef-naming-numeric-floor"
			[check]="getNamingConvention(fsgNaming.NUMERIC_PER_FLOOR)"
			(clickCb)="setNamingConvention(fsgNaming.NUMERIC_PER_FLOOR)"
		></app-round-checkbox>
		<span class="naming-convention">{{ text().NUMERIC + " - " + text().PER_FLOOR }}</span>
		<span class="naming-hint">{{ text().FSG_CREATION.FLATS }} 1, 2, 3...</span>
	</div>
	<div class="naming">
		<app-round-checkbox
			data-add-action-name="ef-naming-numeric-building"
			[check]="getNamingConvention(fsgNaming.NUMERIC_PER_BUILDING)"
			(clickCb)="setNamingConvention(fsgNaming.NUMERIC_PER_BUILDING)"
		></app-round-checkbox>
		<span class="naming-convention">{{ text().NUMERIC + " - " + text().PER_BUILDING }}</span>
		<span class="naming-hint">{{ text().FSG_CREATION.FLATS }} 1, 2, 3...</span>
	</div>
</div>
