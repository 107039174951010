export enum SCREEN {
	LOGIN = "login",
	HOME = "home",
	ASSETS = "assets",
	USERS = "users",
	CONFIGURATION = "configuration",
	ACCOUNT = "account",
	INCIDENT = "incident",
	ARA = "ara",
	COMMAND_STRUCTURE = "command",
	TACTICAL = "tactical",
	OVERLAY = "overlay",
	CALLOUT = "callout",
	DECISION = "decision",
	FSG = "fsg",
	JESIP = "jesip",
	FORMS = "forms",
	RECOMMENDATION = "recommendation"
}

export enum PATH {
	LOGIN = "login",
	HOME = "home",
	RESOURCES = "assets",
	USERS = "users",
	CONFIGURATION = "configuration",
	ACCOUNT = "account",
	INCIDENT = "incident"
}
export enum INCIDENT_PATH {
	ARA = "ara",
	COMMAND_STRUCTURE = "command",
	FORMS = "forms"
}
