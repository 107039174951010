<div class="container">
	<div class="header">
		<div class="chip {{ hasSoi() ? 'selected' : '' }}">
			{{ getFlatSoi(flat) }}
		</div>
	</div>
	<div class="body">
		<div class="set-text">
			<span>{{ this.text().FSG_SELECT_TEXT }}:</span>
		</div>
		<div class="option-list">
			<div
				[attr.data-add-action-name]="'ef-sois-' + option"
				*ngFor="let option of options"
				class="chip option {{ !isSoiSelected(option) ? 'available' : '' }}"
				(click)="optionClick(option)"
			>
				{{ option }}
			</div>
			<div *ngIf="openNewSoiInput" class="chip option">
				<input
					autofocus
					appDetectClickOut
					[onlyOnce]="true"
					(callback)="newSoiClickOut()"
					[(ngModel)]="newSoiValue"
					[maxLength]="20"
				/>
			</div>
			<div
				class="add-button {{ openNewSoiInput ? 'disabled' : '' }}"
				(click)="toggleNewInput($event)"
			></div>
		</div>
	</div>
</div>
<div class="main-black-mask" (click)="cancel.emit()"></div>
