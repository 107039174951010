import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ResourceService } from "src/app/settings/resource/resource.service";
import { Resource } from "src/app/dto/resources/resource";
import { ResourceState } from "src/app/dto/resources/state";
import { LocaleMap } from "src/app/global/constants/text/text-interface";
import { TextProvider } from "src/app/global/constants/text/text-provider";

@Component({
	selector: "app-resource-edit-modal",
	templateUrl: "resource-edit.component.html",
	styleUrls: ["../map-item-edit.css"]
})
export class ResourceEditModalComponent implements OnInit {
	@Input() item!: Resource;

	@Output() cancelCb = new EventEmitter<void>();

	public readonly text: () => LocaleMap;
	public readonly resourceStates: Array<ResourceState> = [];

	private readonly res: ResourceService;

	constructor(textProv: TextProvider, res: ResourceService) {
		this.text = textProv.getStringMap;
		this.res = res;
		this.resourceStates = this.res.States;
	}

	ngOnInit() {
		if (!this.item) throw 'ResourceEditModal initialized without "item" input';
	}

	public readonly refreshName: (value: string) => void = (value) => {
		this.item.name = value;
	};

	public readonly refreshComments: (value: string) => void = (value) => {
		this.item.description = value;
	};

	public readonly getHeader = (): string => {
		return this.item.name;
	};

	public readonly save: Function = () => {
		this.cancelCb.emit();
		return this.res.saveAgent(this.item);
	};

	public readonly cancel: Function = () => {
		this.cancelCb.emit();
	};

	public readonly typeDropdownGetMainText = (): string => {
		return this.item.__state!.name;
	};
	public readonly typeDropdownGetOptionText = (opt: ResourceState): string => {
		return opt.name;
	};
	public readonly typeDropdownCompareSelect = (obj: ResourceState | undefined, opt: ResourceState): boolean | number => {
		if (!obj) return false;
		else return obj.id === opt.id;
	};
	public readonly typeDropdownChangeCallback = (opt: ResourceState): void => {
		this.item.__state = opt;
		this.item.id_state = opt!.id;
	};

	public readonly typeDropdownGetOptionColor = (opt: ResourceState): string => {
		return opt.color;
	};
}
