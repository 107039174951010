import { Component, OnInit } from "@angular/core";
import { LANGUAGE } from "src/app/global/constants/enums/languages";
import { LocaleMap } from "src/app/global/constants/text/text-interface";
import { TextProvider } from "src/app/global/constants/text/text-provider";
import { MainService } from "src/app/global/main.service";
import { kebabMenuItem, KEBAB_MENU_ITEM_ID } from "src/app/widgets/ui/navigation/kebab-menu/kebab-menu.component";
import { FUNCTIONALITY } from "src/app/global/functionality.service";
import { FunctionalityService } from "src/app/global/functionality.service";

@Component({
	selector: "app-support-dialog",
	templateUrl: "./support-dialog.component.html",
	styleUrls: ["./support-dialog.component.css"]
})
export class SupportDialogComponent implements OnInit {
	public menuItems: kebabMenuItem[] = [];
	public kebabMenuOpened: boolean = false;
	public isFuncAvailable = new Map<FUNCTIONALITY, boolean>();
	public funcServ: FunctionalityService;
	private readonly text: () => LocaleMap;
	private readonly mainServ: MainService;

	private readonly currentLanguage: LANGUAGE;

	constructor(tp: TextProvider, mainServ: MainService, funcServ: FunctionalityService) {
		this.currentLanguage = tp.getCurrentLanguage();
		this.text = tp.getStringMap;
		this.mainServ = mainServ;
		this.funcServ = funcServ;
		this.menuItems = [];
	}
	ngOnInit(): void {
		this.isFuncAvailable = this.funcServ.isFunctionalityAvailable;
		this.setMenuItems();
	}
	public handleClickEvent(id: KEBAB_MENU_ITEM_ID): void {
		switch (id) {
			case KEBAB_MENU_ITEM_ID.phone:
				if (navigator.clipboard) {
					const phoneNumber = this.menuItems.find((i) => i.id === KEBAB_MENU_ITEM_ID.phone)?.title;
					phoneNumber && navigator.clipboard.writeText(phoneNumber).then(() => this.mainServ.setSnackbar(this.text().PHONE_NUMBER_COPIED));
				}
				break;
			case KEBAB_MENU_ITEM_ID.email:
				const email = this.menuItems.find((i) => i.id === KEBAB_MENU_ITEM_ID.email)?.title;
				window.open("mailto:" + email, "_blank");
				break;
			case KEBAB_MENU_ITEM_ID.domain:
				const domain = this.menuItems.find((i) => i.id === KEBAB_MENU_ITEM_ID.domain)?.title;
				domain && window.open("https://" + domain, "_blank");
				break;
		}
	}

	public closePopupMenu(): void {
		this.kebabMenuOpened = false;
	}
	public setMenuItems(): void {
		const support = this.isFuncAvailable.get(FUNCTIONALITY.SUPPORTPHONE);
		if (support) {
			this.menuItems = [
				{
					id: KEBAB_MENU_ITEM_ID.phone,
					title: "0333 023 1050",
					description: this.text().SUPPORT_PHONE_NUMBER
				},
				{
					id: KEBAB_MENU_ITEM_ID.email,
					title: "support@unblur.co",
					description: this.text().SUPPORT_TEAM_EMAIL
				},
				{
					id: KEBAB_MENU_ITEM_ID.domain,
					title: "support.unblur.co",
					description: this.text().HELP_CENTER
				}
			];
		} else {
			this.menuItems = [
				{
					id: KEBAB_MENU_ITEM_ID.email,
					title: "support@unblur.co",
					description: this.text().SUPPORT_TEAM_EMAIL
				},
				{
					id: KEBAB_MENU_ITEM_ID.domain,
					title: "support.unblur.co",
					description: this.text().HELP_CENTER
				}
			];
		}
	}
}
