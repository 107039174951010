export class JesipItem {
	public readonly key: string; //used to identify the key
	public readonly title: string;
	public readonly text: string;
	public value: string | undefined;
	public isExpanded: boolean = false;

	constructor(key: string, title: string, text: string, value?: string) {
		this.key = key;
		this.title = title;
		this.text = text;
		this.value = value;
	}
}
