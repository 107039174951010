import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { CSSectorResourceAssignation } from "src/app/dto/command-structure/cs-resource";
import { CSSector } from "src/app/dto/command-structure/cs-sector";
import { CommandStructureService } from "src/app/incident/incident-tools/command-structure/command-structure.service";
import { LocaleMap } from "src/app/global/constants/text/text-interface";
import { TextProvider } from "src/app/global/constants/text/text-provider";
import { CSNode } from "../CSNode";
import { NavigationService } from "src/app/navigation/navigation.service";
import { SCREEN } from "src/app/global/constants/enums/screens";
import { IncidentService } from "../../../../../../incident/incident.service";

@Component({
	selector: "app-cs-sector",
	templateUrl: "sector.component.html",
	styleUrls: ["../../chart.css", "sector.css"]
})
export class CSSectorNodeComponent implements CSNode, OnInit {
	@Input() object!: CSSector;
	@Input() width!: number;
	@Input() height!: number;
	@Input() mapMode: boolean = false;
	@Input() disable: boolean = false;
	@Input() dragging: boolean = false;
	@Input() draggingPersonnel = false;

	@Output() removeSector = new EventEmitter<void>();
	@Output() cancel = new EventEmitter<void>();

	@ViewChild("notesTextarea") notesTextarea!: ElementRef;

	@Input() onResourceDragStart: Function = () => {};
	@Input() onResourceDragCancel: Function = () => {};
	@Input() onResourceDragEnd: Function = () => {};

	public readonly text: () => LocaleMap;
	public editingSector: CSSector | undefined;
	public isFocused: boolean = false;
	public isInFocus: boolean = false;
	public shownSkillIndexes: Map<number, boolean> = new Map<number, boolean>();
	public expandedStates: { [key: number]: boolean } = {};
	public collapsed: boolean = false;

	private touchStartY: number = 0;
	private touchStartX: number = 0;
	private isScrolling: boolean = false;
	private showMenu: boolean = false;
	private nav: NavigationService;
	private ems: IncidentService;
	private cs: CommandStructureService;

	constructor(textProv: TextProvider, cs: CommandStructureService, nav: NavigationService, ems: IncidentService, private cdr: ChangeDetectorRef) {
		this.text = textProv.getStringMap;
		this.nav = nav;
		this.ems = ems;
		this.cs = cs;
	}

	ngOnInit() {
		this.collapsed = this.cs.getSectorCollapsedState(this.object.id);
	}

	public toggleSector(): void {
		this.collapsed = !this.collapsed;
		this.cs.setSectorCollapsedState(this.object.id, this.collapsed);
	}

	public onTouchStart(event: TouchEvent): void {
		this.touchStartY = event.touches[0].clientY;
		this.touchStartX = event.touches[0].clientX;
		this.isScrolling = false;
	}

	public onTouchMove(event: TouchEvent): void {
		const touchEndY = event.touches[0].clientY;
		const touchEndX = event.touches[0].clientX;
		const diffY = Math.abs(touchEndY - this.touchStartY);
		const diffX = Math.abs(touchEndX - this.touchStartX);

		if (diffY > diffX && diffY > 10) {
			this.isScrolling = true;
		}
	}

	public onTouchEnd(event: TouchEvent): void {
		if (this.isScrolling) {
			this.resetDragState();
		}
	}

	public readonly onMainUnassignedClick: Function = () => {
		this.nav.changeScreen(SCREEN.INCIDENT);
		this.nav.commandStructureQuery$.next({ type: "assign", object: this.object });
	};

	public readonly isMenuOpen: Function = () => {
		return this.showMenu;
	};

	public readonly openMenu: Function = (evt: MouseEvent) => {
		this.showMenu = !this.showMenu;
		evt.stopPropagation();
		return false;
	};

	public readonly toggleEditable = (sector: CSSector): CSSector => {
		this.isFocused = true;
		return (this.editingSector = { ...sector });
	};

	public setTitleBackground(): { background?: string } {
		return this.object.id_area > -1 ? { background: this.hexToRgba(this.object.color) } : {};
	}

	public setFlagBackground(): string {
		return this.object.id > -1 ? this.object.color : "white";
	}

	public readonly setStatusBackground: Function = (resource: CSSectorResourceAssignation) => {
		return resource.__state ? { backgroundColor: resource.__state!.color } : {};
	};

	public readonly setSectorName: Function = (newData: string) => {
		this.object.name = newData;
		if (this.object.__area) this.object.__area.name = newData;
	};

	public onNotesInput(event: Event): void {
		this.isInFocus = true;
		const inputElement = event.target as HTMLTextAreaElement;
		this.object.tasks = inputElement.value;
	}

	public onNotesBlur(): void {
		this.isInFocus = false;
		if (!this.shouldDisable()) {
			this.cs.saveSector(this.object);
		}
	}

	public expandNotes(): void {
		this.isInFocus = true;
		this.cdr.detectChanges();
		setTimeout(() => this.focusTextarea(), 0);
	}

	public readonly saveChanges: Function = () => {
		if (this.editingSector) {
			const saveAreaName = this.object.name !== this.editingSector.name;
			this.cs.saveSector(this.object, saveAreaName);
		}
		if (!this.isIncidentClosed() && !this.disable) {
			this.cs.saveSector(this.object);
			this.editingSector = undefined;
			this.showMenu = false;
		}
	};

	public readonly goToArea: Function = () => {
		if (!this.object.__area) this.cs.setupSector(this.object);

		if (this.object.__area) {
			this.nav.changeScreen(SCREEN.INCIDENT);
			this.nav.commandStructureQuery$.next({ type: "goTo", object: this.object.__area });
		}
	};

	public isIncidentClosed(): boolean {
		const currentIncident = this.ems.getCurrentIncident();
		return currentIncident?.closed || false;
	}

	public shouldDisable(): boolean {
		return this.dragging || this.draggingPersonnel || this.isIncidentClosed();
	}

	public getCurrentIncidentId(): number | undefined {
		const currentIncidentId = this.ems.getCurrentIncident()?.id;
		if (currentIncidentId) return currentIncidentId;
		return;
	}

	public toggleSkills(index: number): void {
		if (this.shownSkillIndexes.has(index)) {
			this.shownSkillIndexes.delete(index);
		} else {
			this.shownSkillIndexes.set(index, true);
		}
	}

	public shouldShowFooterButton(): boolean {
		return this.object.id_area === -1 && !this.isIncidentClosed() && !this.disable && !this.collapsed;
	}

	private resetDragState(): void {
		this.dragging = false;
		this.draggingPersonnel = false;
	}

	private hexToRgba(hex: string): string {
		if (!hex) return "#041520";
		const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
		return result ? `rgba(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}, 0.3)` : "#041520";
	}

	private focusTextarea(): void {
		if (this.notesTextarea && this.notesTextarea.nativeElement) {
			this.notesTextarea.nativeElement.focus();
		}
	}
}
