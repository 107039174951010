<div
	data-add-action-name="burger-menu"
	*ngIf="shouldShow"
	class="blue-cross-button {{ openFunc() ? 'open' : '' }}"
	[ngClass]="{ cs: isInCommandSector }"
	(click)="clickCallback($event)"
>
	<div class="group-container">
		<span></span>
		<span></span>
		<span></span>
		<span></span>
	</div>
</div>
