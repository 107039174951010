import { Component, Input } from "@angular/core";

@Component({
	selector: "app-area-radius-label",
	templateUrl: "./area-radius.component.html",
	styleUrls: ["../map-label.css"]
})
export class AreaRadiusLabelComponent {
	@Input() radius: number = 0;
}
