import { HttpErrorResponse } from "@angular/common/http";
import { ErrorHandler, Injectable } from "@angular/core";
import { DBError } from "../../dto/net/dberror";
import { LocaleMap } from "../constants/text/text-interface";
import { TextProvider } from "../constants/text/text-provider";
import { ALERT_TYPE, MainService } from "../main.service";
import { MESSAGE_TYPE } from "../messaging/messages";
import { LoginService } from "../../login/login.service";
import { LOG_TYPE } from "../constants/enums/log_types";
import { WebRequestFactory } from "src/app/http/web.request.factory";
import { DB_ERRORS } from "../constants/enums/db-errors-list";
import { WebRequestsService } from "src/app/http/web-request.service";

@Injectable({
	providedIn: "root"
})
export class ErrorHandlerService implements ErrorHandler {
	private lastLoggedItem: any;

	private readonly loginService: LoginService;
	private readonly main: MainService;
	private readonly wreq: WebRequestFactory;
	private readonly wres: WebRequestsService;
	private readonly text: () => LocaleMap;

	constructor(login: LoginService, main: MainService, wreq: WebRequestFactory, wres: WebRequestsService, textProv: TextProvider) {
		this.loginService = login;
		this.main = main;
		this.wreq = wreq;
		this.wres = wres;
		this.text = textProv.getStringMap;
		this.handleWSResponseErrors();
	}

	/**
	 * Any runtime exception not controlled is captured here. This function is provided in the root of the app.
	 * It checks if the error is not the same as previously logged item and log it to the remote server if the condition is true.
	 * @param error Any runtime exception
	 */
	handleError(error: string | any): void {
		console.error(error);
		if (error && error.item) {
			!this.lastLoggedItem || (this.lastLoggedItem && this.lastLoggedItem !== error.item && this.logToServer(LOG_TYPE.UNHANDLED_EXCEPTION, error.toString()));
			this.lastLoggedItem = JSON.stringify(error.item);
		}
	}

	/**
	 * It subscribes to the errors launched from webRequestService
	 * @param type the status of the response generated in WSResponse class
	 * @param item the data returned from response, managed in WSResponse class || direct response || DbError response
	 * @param url url of the current http request
	 * @returns It categorizes the erros in 3 types, make checks and show the error in form of personalized dialog or
	 * banner on the system header
	 */
	private handleWSResponseErrors(): void {
		this.wres.errorHandler$.subscribe((error: { type: MESSAGE_TYPE; item: any; url: string }) => {
			try {
				switch (error.type) {
					case MESSAGE_TYPE.ERROR_LOGIN:
						//error 401 unAuthorized
						this.onLoginError(error.item);
						break;
					case MESSAGE_TYPE.ERROR_DATABASE:
						//errors returned by database
						if (error.item && error.item instanceof DBError && error.item.error_code) {
							if (this.isSaveCall(error.url) && !this.isControlledError(error.item.error_code)) {
								this.main.addAlert(ALERT_TYPE.DANGER, this.text().SAVE_ERROR_MESSAGE, this.text().CHANGES_NOT_SAVED);
							} else if (this.isControlledError(error.item.error_code)) this.onDatabaseError(error.item);
							else this.main.setErrorBanner();
						}
						break;
					case MESSAGE_TYPE.ERROR_FILE_TOO_LARGE:
						this.main.addAlert(ALERT_TYPE.DANGER, this.text().FILE_TO_LARGE_MESSAGE, this.text().FILE_TO_LARGE);
						break;
					default:
						// case MESSAGE_TYPE.ERROR_WEB_REQUEST || MESSAGE_TYPE.ERROR_NOT_FOUND || MESSAGE_TYPE.ERROR_UNKNOW || MESSAGE_TYPE.ERROR_CONNECTION:
						// error 400 or 200 answer with malformed body || 404 || 500 || any other http error not captured in previous categories
						if (this.isSaveCall(error.url)) this.onSaveError(this.text().SAVE_ERROR_MESSAGE, this.text().CHANGES_NOT_SAVED);
						else !this.isLastChangeCall(error.url) && !this.isErrorLogCall(error.url) && this.main.setErrorBanner();
				}
				if (error && error.item) {
					console.error(error);
					!this.lastLoggedItem || (this.lastLoggedItem && this.lastLoggedItem !== error.item && !this.isLastChangeCall(error.url) && !this.isErrorLogCall(error.url) && this.logToServer(LOG_TYPE.REQUEST_ERROR, error.item.toString()));
					this.lastLoggedItem = error.item.toString();
				}
			} catch (error) {
				console.error(error);
			}
		});
	}

	private readonly onLoginError = (errorMessage: any): void => {
		if (errorMessage instanceof HttpErrorResponse) {
			const msg = errorMessage.headers.get("error_description");
			const authHeader = errorMessage.headers.get("WWW-Authenticate");
			errorMessage = msg ? msg : errorMessage.statusText;
			if (authHeader && authHeader.toLowerCase().includes("token expired")) {
				errorMessage = LOGIN_ERROR_MESSAGE.EXPIRED_TOKEN;
			}
		}
		if (errorMessage) {
			if (errorMessage === LOGIN_ERROR_MESSAGE.EXPIRED_TOKEN) {
				this.main.addAlert(ALERT_TYPE.DANGER, this.text().TOKEN_EXPIRED, "", this.loginService.onLogout);
			} else {
				switch (errorMessage) {
					case LOGIN_ERROR_MESSAGE.INVALID_PASSWORD:
						this.main.addDangerAlert(this.text().WRONG_CREDENTIALS, this.text().AUTHENTICATION_FAILED);
						break;
					case LOGIN_ERROR_MESSAGE.INVALID_TOKEN:
						this.main.addDangerAlert(this.text().INVALID_TOKEN);
						break;
					case LOGIN_ERROR_MESSAGE.MISSING_CAPTCHA:
						this.main.addDangerAlert(this.text().MISSING_CAPTCHA, this.text().AUTHENTICATION_FAILED);
						break;
					case LOGIN_ERROR_MESSAGE.INVALID_CAPTCHA:
						this.main.addDangerAlert(this.text().INVALID_CAPTCHA);
						break;
					default:
						this.main.setErrorBanner();
				}
				this.loginService.resetLogin();
			}
		} else {
			this.main.addAlert(ALERT_TYPE.DANGER, this.text().INVALID_TOKEN, "", this.loginService.onLogout);
		}
	};

	private onSaveError(body: string, header: string): void {
		this.main.addAlert(ALERT_TYPE.DANGER, body, header);
	}

	private onDatabaseError(error: DBError): void {
		this.main.addAlert(ALERT_TYPE.DANGER, this.getDbErrorText(error.error_code), this.getDbErrorTitle(error.error_code));
	}

	private isSaveCall(url: string): boolean {
		return !!url.trim() && url.toLowerCase().includes("save");
	}

	private isLastChangeCall(url: string): boolean {
		return !!url.trim() && url.toLowerCase().includes("lastchange");
	}

	private isErrorLogCall(url: string): boolean {
		return !!url.trim() && url.toLowerCase().includes("error");
	}

	private isControlledError(error_code: string): boolean {
		return Object.values(DB_ERRORS).includes(error_code);
	}

	private getDbErrorText(error_code: string): string {
		const localizedText = this.text().DB_CONNECT_ERROR;
		return localizedText[error_code as keyof typeof localizedText];
	}

	private getDbErrorTitle(error_code: string): string {
		const code = DB_ERRORS[error_code as keyof typeof DB_ERRORS];
		if (code === DB_ERRORS.agent_not_found || code === DB_ERRORS.appliance_not_found || code === DB_ERRORS.id_not_found || code === DB_ERRORS.mission_not_found || code === DB_ERRORS.name_not_found) return this.text().NOT_FOUND;
		else if (code === DB_ERRORS.ara_exists || code === DB_ERRORS.areatype_fk_undeletable || code === DB_ERRORS.cant_delete_user || code === DB_ERRORS.fk_undeletable || code === DB_ERRORS.poitype_fk_undeletable || code === DB_ERRORS.user_is_personnel) return this.text().UNABLE_TO_DELETE;
		else if (code === DB_ERRORS.mission_already_exists) return this.text().NUMBER_IN_USE;
		else if (code === DB_ERRORS.name_in_use || code === DB_ERRORS.ims_name_in_use) return this.text().NAME_IN_USE;
		else if (code === DB_ERRORS.camera_in_use) return this.text().UNAVAILABLE;
		else if (code === DB_ERRORS.incident_closed) return this.text().INCIDENT_CLOSED;
		else if (code === DB_ERRORS.permission_denied) return this.text().RESTRICTED;
		else if (code === DB_ERRORS.insecure_password) return this.text().INVALID_PASSWORD;
		else return "Error";
	}

	private logToServer(type: LOG_TYPE, item: string): void {
		this.wreq.logError(type, item);
	}
}

enum LOGIN_ERROR_MESSAGE {
	INVALID_PASSWORD = "Invalid password",
	EXPIRED_TOKEN = "Token expired",
	INVALID_TOKEN = "Invalid token",
	MISSING_CAPTCHA = "Missing captcha",
	INVALID_CAPTCHA = "Error validating captcha"
}
