<div class="main-container">
	<div class="mssg-types-box">
		<div class="mssg-labels" *ngFor="let option of filters" (click)="toggleFilters(option)">
			<app-blue-checkbox
				[ngClass]="'check-box'"
				[check]="getCheckboxCheck(option)"
			></app-blue-checkbox>
			<span *ngIf="option === 'Event'">{{ text().EVENT_2 }}</span>
			<span *ngIf="option === 'Decision'">{{ text().DECISION }}</span>
			<span *ngIf="option === 'Message'">{{ text().MESSAGE }}</span>
			<span *ngIf="option === 'Automatic'">{{ text().AUTOMATIC }}</span>
		</div>
	</div>

	<div class="row-lower">
		<span
			class="left-button-label"
			(click)="clearAll(); $event.stopPropagation()"
			style="color: generalCheckboxCheck()"
			[class.default]="generalCheckboxCheck() === true"
			>{{ text().CLEAR }}</span
		>
		<span class="right-button-label" (click)="doneModal(); $event.stopPropagation()">{{
			text().DONE
		}}</span>
	</div>
</div>
