import { AfterViewInit, Directive, ElementRef, Input } from "@angular/core";

@Directive({
	selector: "[appPositionRespectToElement]"
})
export class PositionRespectToElementDirective implements AfterViewInit {
	@Input() referenceEl!: ElementRef | HTMLElement;

	@Input() offsetTop: number = 0;

	@Input() offsetLeft: number = 0;

	private element: any;
	private referenceElement!: HTMLElement;

	constructor(private el: ElementRef) {
		this.element = el.nativeElement;
	}

	ngAfterViewInit(): void {
		this.referenceElement = this.referenceEl instanceof ElementRef ? this.referenceEl.nativeElement : this.referenceEl;
		if (!this.referenceElement) throw "PositionRespectToElement directive called without valid element";
		this.computeOffsets();
	}

	private readonly computeOffsets: Function = () => {
		const refDims = this.referenceElement.getBoundingClientRect();
		const refCenter = refDims.left + refDims.width / 2;

		const topPos = refDims.top + refDims.height + this.offsetTop;
		const leftPos = refCenter - this.element.getBoundingClientRect().width / 2 + this.offsetLeft;

		this.element.style.setProperty("position", "fixed");
		this.element.style.setProperty("top", topPos + "px");
		this.element.style.setProperty("left", leftPos + "px");
	};
}
