<div #dragContainer class="svg-container">
	<svg
		#svg
		class="structure-graph"
		[class.iPad]="isIpad"
		xmlns="http://www.w3.org/2000/svg"
		(mousedown)="onViewportMousedown($event)"
		(touchstart)="onViewportTouchdown($event)"
		(wheel)="onViewportWheelMovement($event)"
	>
		<g #viewport id="viewport" (touchstart)="onViewportTouchdown($event)">
			<g id="connectors">
				<path
					data-cy="connector"
					*ngFor="let connector of connectors"
					class="connector"
					[attr.d]="connector.getPath()"
				></path>
			</g>
			<g
				id="commander"
				*ngIf="commandStructure?.commander"
				(touchstart)="onNodeTouchdown($event, nodeTypes.COMMANDER)"
				(mousedown)="onNodeMousedown($event, nodeTypes.COMMANDER)"
			>
				<foreignObject
					[attr.width]="263"
					height="100"
					[attr.x]="
						isIpad ? commandStructure!.commander.x + 150 : commandStructure!.commander.x
					"
					[attr.y]="
						isIpad ? commandStructure!.commander.y + 150 : commandStructure!.commander.y
					"
				>
					<app-cs-commander
						[object]="commandStructure!.commander"
						[width]="263"
						[height]="100"
						[disable]="disable"
						[disabled]="disabled"
						(addSupport)="addSupport()"
						(addSector)="addSector()"
						[onResourceDragStart]="onResourceDragStart"
						[onResourceDragCancel]="onResourceDragCancel"
						[onResourceDragEnd]="onResourceDragEnd"
						[dragging]="isResourceBeingDragged"
						[draggingPersonnel]="isPersonnelBeingDragged"
					></app-cs-commander>
				</foreignObject>
			</g>
			<g
				id="control room"
				*ngIf="commandStructure?.commander"
				(touchstart)="preventTransform($event)"
				(mousedown)="preventTransform($event)"
			>
				<foreignObject
					[style.pointer-events]="disable ? 'none' : 'all'"
					[attr.width]="263"
					height="75"
					[attr.x]="
						isIpad
							? commandStructure!.commander.x + 150 + 300
							: commandStructure!.commander.x + 300
					"
					[attr.y]="
						isIpad ? commandStructure!.commander.y : commandStructure!.commander.y - 150
					"
				>
					<app-cs-control-room
						[disable]="disable"
						[object]="commandStructure!.commander"
						[width]="263"
						[height]="75"
					></app-cs-control-room>
				</foreignObject>
			</g>
			<g
				id="support"
				*ngIf="commandStructure?.supports && commandStructure!.supports.length > 0"
			>
				<foreignObject
					*ngFor="let support of commandStructure!.supports"
					[style.pointer-events]="disable ? 'none' : 'all'"
					[attr.width]="263"
					height="100"
					[attr.x]="support.x"
					[attr.y]="support.y"
					(touchstart)="onNodeTouchdown($event, nodeTypes.SUPPORT, support.id)"
					(mousedown)="onNodeMousedown($event, nodeTypes.SUPPORT, support.id)"
				>
					<app-cs-support
						[object]="support"
						[width]="263"
						[height]="100"
						[disable]="disable"
						(removeSupport)="removeSupport(support)"
					>
					</app-cs-support>
				</foreignObject>
			</g>
			<g
				id="sector"
				*ngIf="commandStructure?.sectors && commandStructure!.sectors.length > 0"
			>
				<foreignObject
					*ngFor="let sector of commandStructure!.sectors"
					[attr.width]="263"
					[attr.height]="200"
					[attr.x]="sector.x"
					[attr.y]="sector.y"
					(touchstart)="onNodeTouchdown($event, nodeTypes.SECTOR, sector.id)"
					(mousedown)="onNodeMousedown($event, nodeTypes.SECTOR, sector.id)"
				>
					<app-cs-sector
						[object]="sector"
						[width]="263"
						[height]="200"
						[disable]="disable"
						(removeSector)="removeSector(sector)"
						[onResourceDragStart]="onResourceDragStart"
						[onResourceDragCancel]="onResourceDragCancel"
						[onResourceDragEnd]="onResourceDragEnd"
						[dragging]="isResourceBeingDragged"
						[draggingPersonnel]="isPersonnelBeingDragged"
					>
					</app-cs-sector>
				</foreignObject>
			</g>
		</g>
	</svg>
</div>
