import { Component, ViewChild } from "@angular/core";
import { DocumentService } from "../document.service";
import { LocaleMap } from "src/app/global/constants/text/text-interface";
import { TextProvider } from "src/app/global/constants/text/text-provider";
import { LoadingDialogComponent } from "src/app/widgets/ui/popups/loading-dialog/loading-dialog.component";
import { DOCUMENT_TYPE } from "src/app/dto/Documents/document-type";
import { DocumentData } from "src/app/dto/Documents/document-data";
import {
	DocsInProgress,
	DownloadDialogs,
	PagesAsCanvas,
	PreparedDocs
} from "src/app/dto/Documents/document";
import { MainService } from "src/app/global/main.service";
import { IncidentService } from "src/app/incident/incident.service";
import { Incident } from "src/app/dto/items/incident";

@Component({
	selector: "app-document-generator",
	templateUrl: "document.component.html",
	styleUrls: ["document.css"]
})
export class DocumentComponent {
	@ViewChild(LoadingDialogComponent) loadingDialog!: LoadingDialogComponent;

	public generationTime: number = Date.now();
	public currentEvent: Incident = new Incident(-1, -1, "");
	public documentTypes = DOCUMENT_TYPE;
	public docsInProgress: DocsInProgress = new Map();
	public docsReady: PreparedDocs = new Map();
	public text: () => LocaleMap;
	public dialogs: DownloadDialogs = {
		loading: "",
		taskCompleted: false,
		unavailable: false
	};
	public ErrorDialogComponentParams: {
		show: boolean;
		header: string;
		body: string;
		confirmText: string;
		closeCallBack: Function;
	} = {
		show: false,
		header: "",
		body: "",
		confirmText: "",
		closeCallBack: () => {
			this.ErrorDialogComponentParams.show = false;
		}
	};

	constructor(
		private readonly docService: DocumentService,
		private readonly ems: IncidentService,
		tp: TextProvider,
		private readonly mainService: MainService
	) {
		this.text = tp.getStringMap;
		this.docService.downloadSignal$.subscribe((params) => this.startDocumentGeneration(params));
		this.docService.loadingMessageChange$.subscribe((text) => {
			if (text && this.loadingDialog) this.loadingDialog.refreshMessage(text);
			else this.dialogs.loading = text;
		});
		this.docService.unavailableError$.subscribe(() => this.unavailableError());
		this.docService.downloadError$.subscribe(() => this.onError());
		this.docService.finishedDownloadInit$.subscribe(() =>
			this.sendDocumentsToServiceIfFinished()
		);
		this.docService.onDownloadFinish$.subscribe(() => this.finishDialog());
		this.docService.onDownloadCancel$.subscribe(() => this.onDownloadCancel());
	}

	public isDocumentBeingGenerated(type: DOCUMENT_TYPE): boolean {
		return !!this.docsInProgress.get(type)?.instances;
	}

	public getDocumentData(type: DOCUMENT_TYPE): DocumentData[] {
		try {
			return this.docsInProgress.get(type)!.documents!;
		} catch (error) {
			this.onError();
			throw "Expected data not found on getting docsInProgress at DocumentComponent";
		}
	}

	public docReady(
		docType: DOCUMENT_TYPE,
		data: HTMLCanvasElement[] | File,
		index?: number
	): void {
		try {
			const name = this.createFileName(docType, index);
			this.removeFromInProgress(docType);
			this.addToPreparedDocuments(docType, data, name);

			this.sendDocumentsToServiceIfFinished();
		} catch (error) {
			this.onError();
		}
	}

	public readonly closeModalCallback: Function = () => {
		this.docService.onDownloadCancel();
	};
	public readonly unavailableError: Function = () => {
		this.ErrorDialogComponentParams = {
			show: (this.dialogs.unavailable = true),
			header: this.text().UNAVAILABLE,
			body: this.text().DOWNLOAD_UNAVAILABLE_BODY,
			confirmText: "",
			closeCallBack: () => {
				this.dialogs.unavailable = false;
				this.dialogs.loading = "";
			}
		};
		this.onDownloadCancel();
	};

	private readonly onDownloadCancel: Function = () => {
		setTimeout(() => {
			this.docsReady.clear();
			this.docsInProgress.clear();
			this.dialogs.loading = "";
			this.dialogs.taskCompleted = false;
		});
	};

	private createFileName(docType: DOCUMENT_TYPE, index?: number): string {
		return this.docService.createFileName(
			this.currentEvent,
			docType,
			this.docsInProgress,
			index
		);
	}

	private removeFromInProgress(docType: DOCUMENT_TYPE): void {
		const docInprogress = this.docsInProgress.get(docType);
		if (docInprogress) docInprogress.instances -= 1;
		if (docInprogress && docInprogress.instances === 0) this.docsInProgress.delete(docType);
	}

	private addToPreparedDocuments(
		docType: DOCUMENT_TYPE,
		data: HTMLCanvasElement[] | File,
		name: string
	): void {
		if (data instanceof File) {
			this.docsReady.set(docType, data);
			return;
		}
		let setDocs = this.docsReady.get(docType);
		if (!setDocs) {
			setDocs = [];
			this.docsReady.set(docType, setDocs);
		}
		(setDocs as PagesAsCanvas[]).push(new PagesAsCanvas(data, name));
	}

	private sendDocumentsToServiceIfFinished(): void {
		if (!this.docService.pendingDocumentsToInit && this.docsInProgress.size === 0) {
			this.docService.downloadAllPreparedDocs(this.currentEvent!, this.docsReady);
			this.docsInProgress.clear();
			this.finishDialog();
		}
	}

	private finishDialog(): void {
		this.dialogs.loading = "";
		this.dialogs.taskCompleted = true;
	}

	private async startDocumentGeneration(params: {
		type: DOCUMENT_TYPE;
		data: DocumentData;
	}): Promise<void> {
		if (!params.data && !this.isBlankDocType(params.type)) return this.onError();
		this.generationTime = Date.now();
		this.currentEvent = params.data.evt ? params.data.evt : this.ems.getCurrentIncident()!;
		this.loadDocumentData(params.type, params.data);
		this.dialogs.loading = this.text().PREPARING_DOCUMENTATION;
		if (this.docService.downloadableDocuments.includes(params.type)) {
			// case for documents generated on the backend, from the frontend pov there's no generation, just a download
			const doc = await this.docService.downloadFileFromServer(params.type, params.data);
			if (!doc) {
				this.onError();
				return;
			}
			const file = new File(
				[doc],
				this.docService.createFileName(this.currentEvent, params.type, this.docsInProgress)
			);
			this.docReady(params.type, file);
		}
	}

	private isBlankDocType(docType: DOCUMENT_TYPE): boolean {
		const blankDocs: DOCUMENT_TYPE[] = [
			DOCUMENT_TYPE.CHECKLIST_BLANK,
			DOCUMENT_TYPE.DEBRIEF_BLANK,
			DOCUMENT_TYPE.DEBRIEF_LAST,
			DOCUMENT_TYPE.IIMARCH_BLANK,
			DOCUMENT_TYPE.JDM_BLANK,
			DOCUMENT_TYPE.METHANE_BLANK
		];
		if (blankDocs.includes(docType)) return true;
		return false;
	}

	private loadDocumentData(docType: DOCUMENT_TYPE, docData: DocumentData): void {
		let docs = this.docsInProgress.get(docType)?.documents;
		if (!docs) docs = [];
		docs.push(docData);
		this.docsInProgress.set(docType, { documents: docs, instances: docs.length });
	}

	private readonly onError = (): void => {
		// Inform about expected data not found? Remove one instance silently (this.docsInProgress.delete(type)) or cancel all the download?
		this.docService.onDownloadCancel();
		this.mainService.addDangerAlert(this.text().DOWNLOAD_ERROR, this.text().UNABLE_TO_DOWNLOAD);
	};
}
