import { FsgFlat } from "./fsg-flat";

export class FsgFlatHistory {
	public readonly id: number;
	public id_mission: number;

	public readonly id_flat: number;
	public flat_name: string;
	public floor_number: number;
	public history = new Array<FsgFlat>();

	constructor(id: number, id_mission: number, flat_name?: string, floor_number?: number) {
		this.id = this.id_flat = id;
		this.id_mission = id_mission;
		this.flat_name = flat_name ? flat_name : "";
		this.floor_number = floor_number ? floor_number : 0;
	}
}
