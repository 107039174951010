import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FsgBuilding, FSG_NAMING_CONVENTION } from "src/app/dto/fsg/fsg-building";
import { LocaleMap } from "src/app/global/constants/text/text-interface";
import { TextProvider } from "src/app/global/constants/text/text-provider";

@Component({
	selector: "app-naming-convention",
	templateUrl: "./naming-convention.component.html",
	styleUrls: ["./naming-convention.component.css"]
})
export class NamingConventionComponent {
	@Input() fsg!: FsgBuilding;

	public fsgNaming = FSG_NAMING_CONVENTION;
	public readonly text: () => LocaleMap;

	constructor(tp: TextProvider) {
		this.text = tp.getStringMap;
	}

	public getNamingConvention = (type: FSG_NAMING_CONVENTION): boolean => {
		return this.fsg.naming_convention === type;
	};

	public setNamingConvention = (type: FSG_NAMING_CONVENTION): FSG_NAMING_CONVENTION => {
		return (this.fsg.naming_convention = type);
	};
}
