<div class="table-container">
	<table class="responsive-table header-table">
		<thead>
			<tr class="list-row header">
				<th
					*ngFor="let column of columns"
					class="header-item-resource-log"
					appSetStyle
					[ngStyle]="getColumnWidth(column)"
				>
					<span class="callout-span">{{ column.columnName }}</span>
				</th>
			</tr>
		</thead>
	</table>

	<div class="container-scroll">
		<table class="responsive-table body-table">
			<tbody class="body-callout">
				<tr
					class="list-row"
					*ngFor="let item of items; let i = index"
					[ngStyle]="setRowHeight()"
					id="{{ i }}"
				>
					<td class="call_sign width-12">
						<span class="callout-span">{{ item.__object.name }}</span>
						<div *ngIf="item.expanded" class="row-item-callout expanded">
							<app-svg-preview
								*ngIf="item.__object.icon_path"
								[path]="item.__object.icon_path"
								[color]="item.__object.color"
								[size]="item.__object.icon_size"
								class="icon-preview-resources"
							></app-svg-preview>
						</div>
					</td>
					<td class="appliance_type width-15">
						<span class="callout-span">{{ item.__object.__typeObj.name }}</span>
						<div *ngIf="item.expanded" class="display-column">
							<span class="callout-station-title">{{ text().STATION }}:</span>
							<span class="callout-span">{{ item.__object.station }}</span>
						</div>
					</td>
					<td class="personnel width-27">
						<div class="callout-oic">
							<div class="oic-icon-name-wrapper">
								<img class="callout-oic-icon" src="resources/img/oic-icon.svg" />
								<span class="callout-oic-value"
									>{{ item.__object.oic ? item.__object.oic.name : text().NONE }}
								</span>
							</div>
							<app-skills-display
								[resource]="item.__object.oic"
								[shownSkillIndexes]="shownSkillIndexes"
								(toggleSkillsOverflowEvent)="toggleSkills(i)"
							>
							</app-skills-display>
						</div>

						<!-- EXPANDED -->
						<div
							*ngIf="item.expanded && item.__object.personnel.length > 0"
							class="display-personnel-list"
						>
							<img
								*ngIf="item.expanded && showDriversIcon(item.__object)"
								class="callout-driver-icon"
								src="resources/img/driver-icon.svg"
							/>

							<div class="resource-skill-container">
								<ng-container
									*ngFor="let person of item.__object.personnel; let j = index"
								>
									<div
										*ngIf="!isOic(item.__object, person)"
										class="display-personnel width-100"
									>
										<span class="callout-oic-value">{{ person.name }}</span>

										<app-skills-display
											*ngIf="person.skills"
											[resource]="person"
											[shownSkillIndexes]="shownSkillIndexes"
											(toggleSkillsOverflowEvent)="toggleSkills(j)"
										>
										</app-skills-display>
									</div>
								</ng-container>
							</div>
						</div>
					</td>
					<td class="assigned width-10">
						<span class="callout-span">{{
							item.assigned_ms !== -1
								? (item.assigned_ms | date : "HH:mm:ss")
								: "- - "
						}}</span>
					</td>
					<td class="mobile width-10">
						<span>{{
							item.mobile_ms !== -1 ? (item.mobile_ms | date : "HH:mm:ss") : "- - "
						}}</span>
					</td>
					<td class="on_scene width-10">
						<span class="callout-span">{{
							item.arrival_ms !== -1 ? (item.arrival_ms | date : "HH:mm:ss") : "- - "
						}}</span>
					</td>
					<td class="available width-10">
						<span class="callout-span">{{
							item.available_ms !== -1
								? (item.available_ms | date : "HH:mm:ss")
								: "- - "
						}}</span>
					</td>
					<td
						class="resources-triangle"
						id="{{ i }}"
						style="width: 6%"
						(click)="toggleExpanded(item)"
					>
						<div
							class="main-triangle {{ isToggleExpanded(item) }}"
							[class.disabled]="disabled"
						></div>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</div>
