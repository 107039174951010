import { AfterViewChecked, AfterViewInit, Directive, ElementRef, NgZone, OnChanges, OnDestroy } from "@angular/core";

@Directive({
	selector: "[appAutoResize]"
})
export class TextareaAutoResizeDirective implements AfterViewInit, OnDestroy {
	private readonly elem: HTMLElement;

	constructor(el: ElementRef, private zone: NgZone) {
		this.elem = el.nativeElement;
	}

	ngAfterViewInit(): void {
		setTimeout(() => { 
			this.elem.style.height = "";
			this.elem.style.height = this.elem.scrollHeight + "px";
			this.elem.addEventListener("input", this.resize);
		}, 1);
	}

	ngOnDestroy(): void {
		this.elem.removeEventListener("input", this.resize);
	}

	private readonly resize: () => void = () => {
		this.elem.style.height = "";
		this.elem.style.height = this.elem.scrollHeight + "px";
	};
}
