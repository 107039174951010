import { Directive, ElementRef, Input, OnInit } from "@angular/core";

@Directive({
	selector: "[appSetStyle]"
})
export class SetStyleDirective implements OnInit {
	@Input() ngStyle: any;

	private elem: HTMLElement;
	constructor(el: ElementRef) {
		this.elem = el.nativeElement;
	}

	ngOnInit(): void {
		Object.assign(this.elem.style, this.ngStyle);
	}
}
