import { Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from "@angular/core";
import { LocaleMap } from "src/app/global/constants/text/text-interface";
import { TextProvider } from "src/app/global/constants/text/text-provider";
import { SimpleTextFieldComponent } from "src/app/widgets/ui/elements/simple-text-field/simple-text-field.component";
import { LoginService } from "../../login.service";

@Component({
	selector: "app-login-unblur",
	templateUrl: "./login-unblur.component.html",
	styleUrls: ["login-unblur.css"]
})
export class LoginUnblurComponent implements OnInit {
	@Input() credentials: { username: string; password: string } | false = false;

	@Output() log = new EventEmitter<{ username: string; password: string; token: string }>();

	@ViewChild('userInputComponent') userInputComponent! : SimpleTextFieldComponent;
	@ViewChild('passwordInputComponent') passwordInputComponent! : SimpleTextFieldComponent;

	@HostListener("document:keydown.enter")
	keypressListener(): void {
		this.onLog();
	}

	public text: () => LocaleMap;
	public captchaResult: string = "";
	public userInput: string = "";
	public passwordInput: string = "";
	public rememberMe: boolean = false;

	public showingPassword: boolean = false;

	private _usernameError: boolean = false;
	private _passwordError: boolean = false;

	private readonly loginServ: LoginService;

	constructor(textProvider: TextProvider, loginServ: LoginService) {
		this.text = textProvider.getStringMap;
		this.loginServ = loginServ;
	}

	ngOnInit(): void {
		this.credentials && this.autoLogin();
	}

	public onNameChange(value : string): void {
		this.userInput = value;
	}

	get usernameError(): boolean {
		return this._usernameError;
	}

	get passwordError(): boolean {
		return this._passwordError;
	}

	public refreshNameError(): void {
		this._usernameError = false;
	}

	public refreshPasswordError(): void {
		this._passwordError = false;
	}


	
	public onPasswordChange(value : string): void {
		this.passwordInput = value;
	}

	public onLog(): void {
		if(!this.passwordInput){
			this._passwordError = true;
		}
		if(!this.userInput){
			this._usernameError = true;
		}
		if(this._passwordError || this._usernameError) return;
		this.log.emit({ username: this.userInput, password: this.passwordInput, token: this.captchaResult });
	}

	public getShouldStoreTokenFunction(): () => boolean {
		return () => { return this.loginServ.keepSession };
	}

	public setSession(): void {
		this.loginServ.keepSession = !this.loginServ.keepSession;
	}

	public showPassword(): void {
		this.passwordInputComponent.changeType("text");
		this.showingPassword = true;
	}

	public hidePassword(): void {
		this.passwordInputComponent.changeType("password");
		this.showingPassword = false;
	}

	public onRecaptchaLoaded(): void {
		setTimeout(() => this.credentials && this.autoLogin());
	}

	public onRecaptchaSuccess(token: string): string {
		return (this.captchaResult = token);
	}

	public autoLogin(): void {
		if (this.credentials) {
			this.userInput = this.credentials.username;
			this.passwordInput = this.credentials.password;
			this.log.emit({ username: this.userInput, password: this.passwordInput, token: this.captchaResult });
		}
	}

	/**
	 * Tries to log in with the user inputs.
	 */
	public login(): void {
		return this.log.emit({ username: this.userInput, password: this.passwordInput, token: this.captchaResult });
	}

	
}
