import { Component, Input } from "@angular/core";
import { FsgBuilding } from "src/app/dto/fsg/fsg-building";
import { LocaleMap } from "src/app/global/constants/text/text-interface";
import { TextProvider } from "src/app/global/constants/text/text-provider";

@Component({
	selector: "app-fsg-label",
	templateUrl: "fsg-label.component.html",
	styleUrls: ["fsg-label.css", "../map-label.css"]
})
export class FsgLabelComponent {
	@Input() fsg!: FsgBuilding;

	text: () => LocaleMap;

	constructor(textProv: TextProvider) {
		this.text = textProv.getStringMap;
	}
}
