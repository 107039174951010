import { Component, EventEmitter, Output } from "@angular/core";
import { UploadedFile } from "src/app/dto/net/uploaded-file";
import { LocaleMap } from "src/app/global/constants/text/text-interface";
import { TextProvider } from "src/app/global/constants/text/text-provider";
@Component({
	selector: "app-add-overlay-dialog",
	templateUrl: "add-overlay.component.html",
	styleUrls: ["add-overlay.css"]
})
export class AddOverlayDialogComponent {
	@Output() cancel = new EventEmitter<void>();
	@Output() confirm = new EventEmitter<Array<UploadedFile>>();

	public readonly text: () => LocaleMap;
	public notAccepted: boolean = false;

	constructor(textProv: TextProvider) {
		this.text = textProv.getStringMap;
	}

	public readonly onCancel: Function = () => {
		this.cancel.emit();
	};

	public readonly onFileUploaded = (arrImgsIdx: Array<UploadedFile>): void => {
		return this.confirm.emit(arrImgsIdx);
	};
}
