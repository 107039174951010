import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
	selector: "app-priority-chip",
	templateUrl: "./priority-chip.component.html",
	styleUrls: ["./priority-chip.component.css"]
})
export class PriorityChipComponent {
	@Input() priority = -1;
	@Output() clickEvent = new EventEmitter();
}
