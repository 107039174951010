import { Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { HeaderChipComponent } from "../chips/header-chip/header-chip.component";

@Component({
	selector: "app-popup-chip",
	templateUrl: "popup-chip.component.html",
	styleUrls: ["popup-chip.css"]
})
export class PopupChipComponent {
	@Input() chipText: string = "";
	@Input() hasIcon: boolean = false;

	@ViewChild(HeaderChipComponent) chipComponent!: HeaderChipComponent;

	public open : boolean = false;

	constructor() {
	}

	onClickOut(): void {
		this.open = false;
		this.chipComponent.close();
	}
}
