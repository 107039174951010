import { DTO } from "../net/dto";
import { IncidentType } from "./types/event-type";

export class Incident implements DTO {
	id: number;
	perimeter: number;
	name: string;
	description: string;
	start_time: string;
	start_time_ms: number;
	end_time: string;
	end_time_ms: number;
	closed: boolean;
	timestamp: number;
	duration: number;
	id_type: number;
	num: number;

	__typeObj?: IncidentType;

	constructor(id: number, perimeter: number, name: string, description?: string, start_time?: string, end_time?: string, closed?: boolean, timestamp?: number, type?: number, num?: number) {
		this.id = id;
		this.perimeter = perimeter;
		this.name = name;
		this.description = description ? description : "";
		this.start_time = start_time ? start_time : new Date().toISOString();
		this.start_time_ms = new Date(this.start_time.replace("Z", "") + "Z").getTime();
		this.end_time = end_time ? end_time : "";
		this.end_time_ms = new Date(this.end_time.replace("Z", "") + "Z").getTime();
		this.duration = end_time ? this.start_time_ms - this.end_time_ms : -1;
		this.closed = closed ? closed : false;
		this.timestamp = timestamp ? timestamp : Date.now();
		this.id_type = type ?? -1;
		this.num = num ?? -1;
	}

	public static readonly fromJson: (json: string) => Incident = (json: string) => {
		let jm = JSON.parse(json);
		return new Incident(jm.id, jm.perimeter, jm.name, jm.description, jm.start_time, jm.end_time, jm.closed, jm.timestamp, jm.id_type, jm.num);
	};

	public readonly getJson: Function = () => {
		return JSON.stringify(this, ["id", "name", "description", "perimeter", "start_time", "end_time", "closed", "timestamp", "id_type", "num"]);
	};

	
	public getFormattedDate: () => string = () =>{
		const regex = /\-/g;
		const date = this.start_time.split("T", 1)[0];
		return date.replace(regex, "");
	};
}
