import { Directive, ElementRef, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Directive({
	selector: "[appDraggable]"
})
export class DraggableDirective implements OnInit {
	@Input() dragInfo: any;
	@Input() initialTop: number | undefined;
	@Input() initialLeft: number | undefined;
	@Input() disabled: boolean | undefined;

	@Output() dragStart = new EventEmitter<void>();
	@Output() dragEnd = new EventEmitter<void>();

	private elem: HTMLElement;
	private prevX = 0;
	private prevY = 0;

	constructor(el: ElementRef) {
		this.elem = el.nativeElement;
		this.elem.style.cursor = "grab";
		this.elem.style.position = "fixed";
		this.elem.addEventListener("mousedown", this.dragBeginClick as any);
		this.elem.addEventListener("touchstart", this.dragBeginTouch as any);
		this.elem.addEventListener("touchdown", () => {});
	}

	ngOnInit(): void {
		if (this.initialTop) this.elem.style.top = this.initialTop + "px";
		if (this.initialLeft) this.elem.style.left = this.initialLeft + "px";
	}

	private readonly dragBeginTouch: Function = (evt: TouchEvent) => {
		this.dragStart.emit();
		this.prevX = evt.touches[0].clientX;
		this.prevY = evt.touches[0].clientY;
		document.body.addEventListener("touchend", this.dragFinish as any);
		document.body.addEventListener("touchmove", this.onmove as any, { passive: false });
		evt.preventDefault();
		evt.stopPropagation();
	};

	private readonly dragBeginClick: Function = (evt: MouseEvent) => {
		this.dragStart.emit(this.dragInfo);
		this.prevX = evt.x;
		this.prevY = evt.y;
		document.body.addEventListener("mouseup", this.dragFinish as any);
		document.body.addEventListener("mousemove", this.onmove as any);
		evt.preventDefault();
		evt.stopPropagation();
	};

	private readonly dragFinish: Function = () => {
		document.body.style.cursor = "";
		this.dragEnd.emit();
		document.body.removeEventListener("mouseup", this.dragFinish as any);
		document.body.removeEventListener("mousemove", this.onmove as any);
		document.body.removeEventListener("touchend", this.dragFinish as any);
		document.body.removeEventListener("touchmove", this.onmove as any);
	};

	private readonly onmove: Function = (evt: MouseEvent | TouchEvent) => {
		const dims = this.elem.getBoundingClientRect();
		const newX = evt instanceof MouseEvent ? evt.x : evt.touches[0].clientX;
		const newY = evt instanceof MouseEvent ? evt.y : evt.touches[0].clientY;
		const deltaX = newX - this.prevX;
		const deltaY = newY - this.prevY;
		this.elem.style.left = dims.left + deltaX + "px";
		this.elem.style.top = dims.top + deltaY + "px";
		this.prevX = newX;
		this.prevY = newY;
		evt.preventDefault();
		evt.stopPropagation();
	};
}
