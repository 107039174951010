import { Component, Input, OnInit, Output } from "@angular/core";
import { EventEmitter } from "@angular/core";
import { LocaleMap } from "src/app/global/constants/text/text-interface";
import { TextProvider } from "src/app/global/constants/text/text-provider";
import { MainService } from "src/app/global/main.service";
@Component({
	selector: "app-error-dialog",
	templateUrl: "app-error-dialog.component.html",
	styleUrls: ["error-dialog.component.css"]
})
export class ErrorDialogComponent implements OnInit {
	@Input() header: string = "Error";
	@Input() body: string = "";
	@Output() closeCallback = new EventEmitter<void>();

	public readonly text: () => LocaleMap;
	private main: MainService;

	constructor(main: MainService, textProv: TextProvider) {
		this.main = main;
		this.text = textProv.getStringMap;
	}

	ngOnInit(): void {
		if (!this.body) throw new Error("ErrorDialogComponent instantiated without text");
	}

	public readonly isSaveError = (): boolean => {
		return this.header === this.text().CHANGES_NOT_SAVED;
	};

	public openSupportMenu = (): void => {
		this.main.openSupportDialog.next();
		this.closeCallback.emit();
	};
}
