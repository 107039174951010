<img
	[style.opacity]="hideIcon ? 0 : 1"
	src="resources/img/info-tooltip.svg"
	alt="Information tooltip"
	width="24"
	height="24"
	class="pointer"
	(mouseover)="onMouseOver()"
	(mouseleave)="onMouseLeave()"
	(click)="onClick()"
	appDetectClickOut
	(callback)="onClickOutside()"
/>

<app-info-tooltip
	*ngIf="tooltipText && (clicked || hovered)"
	[style.width.px]="width"
	[tooltipText]="tooltipText"
	[arrowPosition]="arrowPosition"
></app-info-tooltip>
