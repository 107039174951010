<div class="supercontainer">
	<div
		*ngIf="openMenu"
		class="main-transparent-mask"
		(click)="openMenu = !openMenu; $event.stopPropagation()"
	></div>
	<div class="container">
		<div class="border-dropdown-filter {{ openMenu ? 'open' : '' }}">
			<div
				class="border-dropdown-filter-text"
				(click)="openMenu = !openMenu; object = null; query = ''; $event.stopPropagation()"
			>
				<div class="input-span">
					<input
						*ngIf="filterEnabled"
						[placeholder]="placeholderText"
						[(ngModel)]="getObjectText(object) ? getObjectText(object) : query"
						(ngModelChange)="change(query)"
						(click)="openMenu = true; $event.stopPropagation()"
					/>
					<span *ngIf="getObjectText(object) && !filterEnabled">{{
						getObjectText(object)
					}}</span>
					<span
						*ngIf="!getObjectText(object) && placeholderText && !filterEnabled"
						class="placeholder-text"
					>
						{{ placeholderText }}</span
					>
				</div>
				<div class="dropdown-arrow-mask">
					<div class="dropdown-triangle {{ openMenu ? 'up' : 'down' }}"></div>
				</div>
			</div>
		</div>
		<label> {{ placeholderText }}</label>
	</div>
	<div class="menu {{ openMenu ? 'dopen' : '' }}" inherit-width>
		<div class="scroll">
			<div
				*ngFor="let option of options | filter: filterFunc"
				class="item {{ compareSelect(object, option) ? 'active' : '' }}"
				(click)="onChangeCb(option); openMenu = !openMenu"
			>
				<div
					*ngIf="getOptionColor(option)"
					[ngStyle]="{ 'background-color': getOptionColor(option) }"
					class="color-container"
				></div>
				<div class="text-container">{{ getOptionText(option) }}</div>
			</div>
		</div>
	</div>
</div>
