import { Injectable } from "@angular/core";
import { LANGUAGE } from "./enums/languages";
import { MAP_ENGINES } from "./enums/map-engines";
import { URLMap } from "./enums/url-map";
import settings from "../../../iris.settings.json";
import settingsTempl from "../../../iris.settings.template.json";
import { KeycloakConfig } from "keycloak-js";
import { AUTH } from "src/app/global/constants/enums/auth";
import { SettingsParser } from "./settings.parser";
import { HttpErrorResponse } from "@angular/common/http";
import { FunctionalityService } from "../functionality.service";

@Injectable({
	providedIn: "root"
})
export class ConstantsService {
	public BACKEND_HOST: string = ""; // used for ims signaling. it is important!!
	public GMAP_KEY: string = "";
	public GMAP_TESTING: boolean = true;
	public VERSION: string = "v0.0";
	public DEFAULT_LANGUAGE!: LANGUAGE;
	public LIVE_UPDATE_REQUEST_INTERVAL_MS = 2000;
	public CLOSED_UPDATE_REQUEST_INTERVAL_MS = 1000;
	public MAP_ENGINE: MAP_ENGINES = MAP_ENGINES.GOOGLE;
	public SNACKBAR_TIME_MS = 3000;
	public ERROR_BANNER_TIME_MS = 5000;
	public AUTH: AUTH = AUTH.UNBLUR;
	public KEYCLOAK_CONFIG: KeycloakConfig = { url: "", realm: "", clientId: "" };
	public SYNCHRONIZATION_ENABLED: boolean = false;
	public HAS_USER_LIMIT: boolean = false;
	// iris media server
	public AUTO_LOGIN: { username: string; password: string } | false = false;
	public CLARITY_ID: string | undefined;

	constructor() {
		if (!SettingsParser.TESTING) {
			this.loadParams(SettingsParser.getSettings());
			this.VERSION = SettingsParser.getTemplate().version;
			this.BACKEND_HOST = URLMap.WSURL().split("/")[2];
		} else {
			// this constructor is called in a lot of beforeEach tests which dont allow promises, hence this clause
			this.loadParams(SettingsParser.default);
			this.VERSION = "0.0";
			this.BACKEND_HOST = "localhost";
		}
		SettingsParser.settingsLoaded$.subscribe(() => {
			this.loadParams(SettingsParser.getSettings());
			this.VERSION = SettingsParser.getTemplate().version;
			this.BACKEND_HOST = URLMap.WSURL().split("/")[2];
		});
	}

	public readonly errorHandler: (reason: any) => void = (e) => {
		console.error(e);
	};

	private readonly loadParams: (settings: any) => void = (settings) => {
		const params = settings;
		if (params.default_lang) {
			if (params.default_lang !== "spanish" && params.default_lang !== "english") {
				console.error("Error parsing IRIS settings. '" + params.default_lang + "' is not a valid 'default_lang' value.\n" + "Only accepted values are 'english' and 'spanish'");
			}
			if (params.default_lang.toUpperCase() === "SPANISH") this.DEFAULT_LANGUAGE = LANGUAGE.SPANISH;
			if (params.default_lang.toUpperCase() === "ENGLISH") this.DEFAULT_LANGUAGE = LANGUAGE.ENGLISH;
		} else this.DEFAULT_LANGUAGE = LANGUAGE.ENGLISH;
		if (params.engine) {
			switch (params.engine.toLowerCase()) {
				case "esri":
					this.MAP_ENGINE = MAP_ENGINES.ESRI;
					break;
				default:
					this.MAP_ENGINE = MAP_ENGINES.GOOGLE;
					break;
			}
		}
		if (!params.google || !params.google.key) {
			console.error("Error parsing IRIS settings. ' Either google or google.key param is missing. Defaulting to none");
		}
		if (params.google) this.GMAP_TESTING = params.google.testing;
		let auth = params.auth.toLowerCase();
		if (auth !== "unblur" && auth !== "hybrid" && auth !== "sso") auth = "unblur"; // default
		if ((auth === AUTH.UNBLUR || auth === AUTH.HYBRID) && params.login) this.AUTO_LOGIN = params.login;
		this.AUTH = auth || "unblur";
		this.KEYCLOAK_CONFIG = params.keycloak;

		if (params.synchronization) this.SYNCHRONIZATION_ENABLED = true;
		if (params.has_user_limit) this.HAS_USER_LIMIT = true;

		this.CLARITY_ID = params.clarity_id;
	};
}
