import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { DocumentService } from "src/app/document/document.service";
import { IiMarch, IIMARCH_ITEM } from "src/app/dto/jesip/iimarch";
import { IncidentInformation } from "src/app/dto/jesip/incident-information";
import { JesipItem } from "src/app/dto/jesip/item-list";
import { Jdm, JDM_ITEM } from "src/app/dto/jesip/jdm";
import { Member } from "src/app/dto/jesip/member";
import { Methane, METHANE_ITEM } from "src/app/dto/jesip/Methane";
import { IncidentService } from "src/app/incident/incident.service";
import { FormsService } from "src/app/incident/incident-tools/forms/forms.service";
import { JESIP_FORMS } from "src/app/global/constants/enums/jesip_forms";
import { LocaleMap } from "src/app/global/constants/text/text-interface";
import { TextProvider } from "src/app/global/constants/text/text-provider";
import { EXCEPTIONS } from "src/app/global/constants/enums/exception";
import { DOCUMENT_TYPE } from "src/app/dto/Documents/document-type";
import { DocumentData } from "src/app/dto/Documents/document-data";

@Component({
	selector: "app-jesip-history",
	templateUrl: "./jesip-history.component.html",
	styleUrls: ["./jesip-history.component.css"]
})
export class JesipHistoryComponent implements OnInit {
	@Input() data!: Methane[] | IiMarch[] | Jdm[];
	@Input() title: string = "";

	@Input() isJdm: boolean = false;

	@Output() cancelCb = new EventEmitter<void>();

	public readonly text: () => LocaleMap;
	public itemList!: JesipItem[];
	public currentItem!: Methane | IiMarch | Jdm;
	public commanders = new Array<Member>();
	public formType!: JESIP_FORMS;
	public jesipForms = JESIP_FORMS;
	public info!: IncidentInformation;

	private readonly jesip: FormsService;
	private readonly ems: IncidentService;
	private readonly docServ: DocumentService;

	constructor(textProv: TextProvider, jesServ: FormsService, ems: IncidentService, ds: DocumentService) {
		this.text = textProv.getStringMap;
		this.jesip = jesServ;
		this.ems = ems;
		this.docServ = ds;
	}

	ngOnInit(): void {
		this.setJesipItem(0);
	}

	public readonly cancel: Function = () => {
		this.cancelCb.emit();
	};

	public readonly firstCallerName = (): string => {
		return this.currentItem instanceof Methane ? this.currentItem.first_caller_name : "";
	};

	public readonly firstCallerOrg = (): string => {
		return this.currentItem instanceof Methane ? this.currentItem.first_caller_org : "";
	};

	public readonly downloadHistoric: Function = () => {
		if (!this.data.length) return;
		let type: DOCUMENT_TYPE;
		let data: DocumentData = {};
		this.data
			.sort((a, b) => (a.timestamp_ms > b.timestamp_ms ? 1 : -1))
			.forEach((form) => {
				if (form instanceof Methane) {
					type = DOCUMENT_TYPE.METHANE_HISTORY;
					data = { jesipMethane: form as Methane };
				}
				if (form instanceof IiMarch) {
					type = DOCUMENT_TYPE.IIMARCH_HISTORY;
					data = { jesipIimarch: form as IiMarch };
				}
				if (form instanceof Jdm) {
					type = DOCUMENT_TYPE.JDM_HISTORY;
					data = { jesipJdm: form as Jdm };
					this.isJdm = true;
				}
				this.docServ.downloadDocument(type!, data);
			});
	};

	public readonly setJesipItem = (idx: number): void => {
		if (this.data[0] instanceof Methane) {
			this.setMethaneList(idx);
			this.formType = JESIP_FORMS.METHANE;
		}

		if (this.data[0] instanceof IiMarch) {
			this.setIimarchList(idx);
			this.formType = JESIP_FORMS.IIMARCH;
		}

		if (this.data[0] instanceof Jdm) {
			this.setJdmList(idx);
			this.formType = JESIP_FORMS.JDM;
			this.isJdm = true;
			this.getIncidentInformation();
		}
		this.getCommanders();
	};

	private readonly setMethaneList = (idx: number): void => {
		this.currentItem = this.data[idx] as Methane;
		let index = 0;
		this.itemList = [];
		for (let keys in METHANE_ITEM) {
			const key = keys as keyof Methane;
			this.itemList.push(new JesipItem(key, (this.text() as any)["JESIP_METHANE_ITEMS_TITLE_" + index], (this.text() as any)["JESIP_METHANE_ITEMS_DESCRIPTION_" + index], this.currentItem[key] as string));
			index++;
		}
	};

	private readonly setIimarchList = (idx: number): void => {
		this.currentItem = this.data[idx] as IiMarch;
		let index = 0;
		this.itemList = [];
		for (let keys in IIMARCH_ITEM) {
			const key = keys as keyof IiMarch;
			this.itemList.push(new JesipItem(key, (this.text() as any)["JESIP_IIMARCH_ITEMS_TITLE_" + index], (this.text() as any)["JESIP_IIMARCH_ITEMS_DESCRIPTION_" + index], this.currentItem[key] as string));
			index++;
		}
	};

	private readonly setJdmList = (idx: number): void => {
		this.currentItem = this.data[idx] as Jdm;
		let index = 0;
		this.itemList = [];
		for (let keys in JDM_ITEM) {
			const key = keys as keyof Jdm;
			this.itemList.push(new JesipItem(key, (this.text() as any)["JESIP_JDM_ITEMS_FULL_TITLE_" + index], "", this.currentItem[key] as string));
			this.isJdm = true;
			index++;
		}
	};

	private readonly getCommanders = (): void => {
		const id_mission = this.ems.getCurrentIncident()!.id;
		if (!id_mission) throw EXCEPTIONS.NO_INCIDENT;
		if (this.data.length)
			this.jesip.loadFormTeam(id_mission, this.formType).then((payload) => {
				const ans: Array<Member> | undefined = payload.filter((com: Member) => com.form === this.formType);
				if (!ans || !ans.length) {
					this.commanders = [new Member(0, id_mission, this.formType), new Member(1, id_mission, this.formType), new Member(2, id_mission, this.formType)];
				} else {
					this.commanders = ans;
				}
			});
	};

	private readonly getIncidentInformation = (): void => {
		const id_mission = this.ems.getCurrentIncident()?.id;
		if (!id_mission) throw EXCEPTIONS.NO_INCIDENT;
		this.jesip.loadIncidentInformation(id_mission, JESIP_FORMS.JDM).then((ans: IncidentInformation | undefined) => {
			if (ans) this.info = ans;
			else this.info = new IncidentInformation(id_mission, JESIP_FORMS.JDM);
		});
	};
}
