<div #pageElements *ngFor="let page of pages; let idx = index" class="page scale-5">
	<div class="document-wrapper">
		<div class="title">{{ fsg.name }}</div>

		<app-fsg-header [fsg]="fsg" [event]="event"></app-fsg-header>

		<section>
			<ng-container *ngFor="let history of page">
				<div class="flat-divider">
					<h2>
						{{ text().FSG_FLOOR }} {{ history.floor_number }} , {{ text().FSG_FLAT }}
						{{ getFlatLatestName(history) }}
					</h2>
					<div><!-- Divider line --></div>
				</div>

				<div *ngFor="let flat of history.history; let i = index" class="flat">
					<div class="flat-column-1">
						<div class="flat-header">
							{{ i === history.history.length - 1 ? text().CREATED : text().EDITED }}
							by <b>{{ getUserNameById(flat.idUser) | titlecase }}</b> at
							<b>{{ flat.timestamp_ms | date : "HH:mm - dd/MM/YYYY" }}</b>
						</div>
						<div class="column-1">
							<div class="flat-item">
								<div class="flat-key">{{ text().FSG_SOURCE }}</div>
								<div
									[style.fontWeight]="
										hasValueChanged(history, i, 'source_of_information')
											? 'bold'
											: 'normal'
									"
								>
									{{
										flat.source_of_information !== "-1"
											? flat.source_of_information
											: "-"
									}}
								</div>
							</div>
							<div class="flat-item">
								<div class="flat-key">{{ text().FSG_PEOPLE }}</div>
								<div
									[style.fontWeight]="
										hasValueChanged(history, i, 'people') ? 'bold' : 'normal'
									"
								>
									{{ flat.people || "-" }}
								</div>
							</div>
							<div class="flat-item">
								<div class="flat-key">{{ text().SECTOR }}</div>
								<div>{{ getSectorName(flat.status) }}</div>
							</div>
						</div>
						<div class="column-2">
							<div class="flat-item">
								<div class="flat-key">{{ text().FSG_PRIORITY }}</div>
								<div
									[style.fontWeight]="
										hasValueChanged(history, i, 'priority') ? 'bold' : 'normal'
									"
								>
									{{ getPriorityText(flat, history.history, i) }}
								</div>
							</div>
							<div class="flat-item">
								<div class="flat-key">{{ text().FSG_ADVICE }}</div>
								<div
									[style.fontWeight]="
										hasValueChanged(history, i, 'fsg_advice')
											? 'bold'
											: 'normal'
									"
								>
									{{ flat.fsg_advice || "-" }}
								</div>
							</div>
							<div class="flat-item">
								<div class="flat-key">{{ text().FSG_CONDITIONS }}</div>
								<div>
									<span
										[style.fontWeight]="
											hasValueChanged(history, i, 'condition')
												? 'bold'
												: 'normal'
										"
									>
										{{ flat.condition.join(", ") || "-" }}
									</span>
								</div>
							</div>
						</div>
					</div>

					<div class="flat-column-2">
						<div class="column-3">
							<div class="flat-key">{{ text().COMMENTS2 }}</div>
							<div
								[style.fontWeight]="
									hasValueChanged(history, i, 'comments') ? 'bold' : 'normal'
								"
							>
								{{ flat.comments || "-" }}
							</div>
						</div>
						<div class="column-3">
							<div class="flat-key">{{ text().FSG_CASUALTY_STATUS }}</div>
							<div
								[style.fontWeight]="
									hasValueChanged(history, i, 'casualty_status')
										? 'bold'
										: 'normal'
								"
							>
								{{ flat.casualty_status || "-" }}
							</div>
						</div>
					</div>
				</div>
			</ng-container>
		</section>

		<section class="document-footer">
			<b>{{ text().PAGE }}:</b>
			<div class="page-number">{{ idx + 1 }} / {{ pages.length }}</div>
		</section>
	</div>
</div>

<div class="inspector page">
	<div class="document-wrapper">
		<div #inspectedPageHeaderElement *ngIf="inspectedPageHeader">
			<div class="title">{{ fsg.name }}</div>
			<app-fsg-header [fsg]="fsg" [event]="event"></app-fsg-header>
		</div>

		<div #inspectedFlatElement *ngIf="inspectedFlat" class="flat">
			<div class="flat-column-1">
				<div class="flat-header">
					{{ text().CREATED }}
					by <b>{{ getUserNameById(inspectedFlat.idUser) | titlecase }}</b> at
					<b>{{ inspectedFlat.timestamp_ms | date : "HH:mm - dd/MM/YYYY" }}</b>
				</div>
				<div class="column-1">
					<div class="flat-item">
						<div class="flat-key">{{ text().FSG_SOURCE }}</div>
						<div [style.fontWeight]="'bold'">
							{{
								inspectedFlat.source_of_information !== "-1"
									? inspectedFlat.source_of_information
									: "-"
							}}
						</div>
					</div>
					<div class="flat-item">
						<div class="flat-key">{{ text().FSG_PEOPLE }}</div>
						<div [style.fontWeight]="'bold'">
							{{ inspectedFlat.people || "-" }}
						</div>
					</div>
					<div class="flat-item">
						<div class="flat-key">{{ text().SECTOR }}</div>
						<div>{{ getSectorName(inspectedFlat.status) }}</div>
					</div>
				</div>
				<div class="column-2">
					<div class="flat-item">
						<div class="flat-key">{{ text().FSG_PRIORITY }}</div>
						<div [style.fontWeight]="'bold'">
							{{
								inspectedFlat.priority !== -1 ? "P " + inspectedFlat.priority : ""
							}}, {{ inspectedFlat.deleted ? " " + this.text().DELETED : "" }},
							{{ " " + this.text().UNCLEARED }}
						</div>
					</div>
					<div class="flat-item">
						<div class="flat-key">{{ text().FSG_ADVICE }}</div>
						<div [style.fontWeight]="'bold'">
							{{ inspectedFlat.fsg_advice || "-" }}
						</div>
					</div>
					<div class="flat-item">
						<div class="flat-key">{{ text().FSG_CONDITIONS }}</div>
						<div>
							<span [style.fontWeight]="'bold'">
								{{ inspectedFlat.condition.join(", ") || "-" }}
							</span>
						</div>
					</div>
				</div>
			</div>

			<div class="flat-column-2">
				<div class="column-3">
					<div class="flat-key">{{ text().COMMENTS2 }}</div>
					<div [style.fontWeight]="'bold'">
						{{ inspectedFlat.comments || "-" }}
					</div>
				</div>
				<div class="column-3">
					<div class="flat-key">{{ text().FSG_CASUALTY_STATUS }}</div>
					<div [style.fontWeight]="'bold'">
						{{ inspectedFlat.casualty_status || "-" }}
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
