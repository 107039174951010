import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { DirectiveModule } from "../widgets/directive/directive.module";
import { LoginUnblurComponent } from "./components/login-unblur/login-unblur.component";
import { LoginSsoComponent } from "./components/login-sso/login-sso.component";
import { SplashScreenComponent } from "./components/splash-screen/splash-screen.component";
import { LoginComponent } from "./components/main/login.component";
import { LoginRoutingModule } from "./login-routing.module";
import { UIModule } from "../widgets/ui/ui.module";
@NgModule({
	declarations: [LoginComponent, LoginUnblurComponent, LoginSsoComponent, SplashScreenComponent],
	imports: [LoginRoutingModule, CommonModule, FormsModule, DirectiveModule, UIModule],
	exports: [SplashScreenComponent]
})
export class LoginModule {}
