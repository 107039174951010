import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { Overlay } from "src/app/dto/items/overlay";
import { WebRequestFactory } from "src/app/http/web.request.factory";

@Component({
	selector: "app-overlay-viewer",
	templateUrl: "./overlay-viewer.component.html",
	styleUrls: ["./overlay-viewer.component.css"]
})
export class OverlayViewerComponent implements OnInit {
	@Input() overlay: Overlay = new Overlay(-1, -1, -1, -1, -1, -1, -1, "", -1, "");
	@Output() cancelEmit = new EventEmitter();

	public safeSrc!: SafeResourceUrl;
	public showPlaneViewer: boolean = false;
	public imgIndex: number = 0;
	public imagesArr: Array<{ id: number; name: string }> = [];

	private readonly wreq: WebRequestFactory;
	private readonly sanitizer: DomSanitizer;

	constructor(sanitizer: DomSanitizer, wreq: WebRequestFactory) {
		this.sanitizer = sanitizer;
		this.wreq = wreq;
	}

	ngOnInit() {
		if (!this.overlay.id_img) throw "ImageViewerComponent component rendered without an image source";
		if (this.overlay.id_img) this.safeSrc = this.getImgSrcById(this.overlay.id_img);
		this.setUpImgsArr();
	}

	setUpImgsArr: () => void = async () => {
		this.imagesArr.push({ id: this.overlay.id_img, name: this.overlay.name });
		this.overlay.floors.forEach((floor) => this.imagesArr.push({ id: floor.id_img, name: floor.name }));
		this.getImgSrcById(this.imagesArr[this.imgIndex].id);
	};

	cancel: () => void = () => {
		this.cancelEmit.emit();
	};

	planeViewerGoDown: () => void = () => {
		if (this.imgIndex > 0) this.imgIndex -= 1;
		this.getImgSrcById(this.imagesArr[this.imgIndex].id);
	};

	planeViewerGoUp: () => void = () => {
		if (this.imgIndex < this.overlay.floors.length) this.imgIndex += 1;
		this.getImgSrcById(this.imagesArr[this.imgIndex].id);
	};

	public readonly getImgSrcById: (idfile: number) => SafeResourceUrl = async (idfile) => {
		const blob: Blob = await this.wreq.getFile(idfile!);
		const url: string = URL.createObjectURL(blob);
		this.safeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(url);
	};
}
