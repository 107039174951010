import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { WebRequestsService } from "src/app/http/web-request.service";
import { WebRequestFactory } from "src/app/http/web.request.factory";
import { UserService } from "src/app/settings/user/user.service";
import { Decision } from "src/app/dto/decision/decision";
import { DecisionFile } from "src/app/dto/decision/decision-file";
import { UploadedFile } from "src/app/dto/net/uploaded-file";
import { User } from "src/app/dto/user/user";
import { LocaleMap } from "src/app/global/constants/text/text-interface";
import { TextProvider } from "src/app/global/constants/text/text-provider";
import { ALERT_TYPE, MainService } from "src/app/global/main.service";
import { SpeechToTextDirective } from "src/app/widgets/directive/speech.directive";
import { BlueSignatureCanvasComponent } from "src/app/widgets/ui/signature/blue-canvas/blue-canvas.component";
import { DecisionLogService } from "../../decision-log.service";
import { LoginService } from "src/app/login/login.service";
import { AIDL } from "src/app/global/constants/enums/aidl";
import { FunctionalityService } from "src/app/global/functionality.service";
import { FUNCTIONALITY } from "src/app/global/functionality.service";
import { IncidentService } from "src/app/incident/incident.service";

@Component({
	selector: "app-decision-form",
	templateUrl: "new-decision.component.html",
	styleUrls: ["new-decision.css"]
})
export class NewDecisionComponent implements OnInit {
	@Input() canCreateDl: boolean = false;

	@ViewChild(SpeechToTextDirective) speech!: SpeechToTextDirective;
	@ViewChild(BlueSignatureCanvasComponent) canvas!: BlueSignatureCanvasComponent;

	public eventFlag: boolean = false;
	public decisionFlag: boolean = false;
	public messageFlag: boolean = false;
	public users = new Array<User>();
	public dlSignature: boolean = true;
	public dlType: boolean = true;
	public dlOperational: boolean = true;

	public selectedUser: User | undefined;
	public selectedTactic: TACTICAL_OPTION | undefined;
	public selectedState: STATE_OPTION | undefined;
	public content: string = "";
	public rationale: string = "";

	public readonly text: () => LocaleMap;
	public uploadedFiles = new Array<DecisionFile>();
	public imageArrays: Array<Array<{ image?: string; filename?: string; idfile?: number }>> = [];
	public inspectedImage = -1;

	public audio: Blob | undefined;
	public recording: boolean = false;
	public predicting: boolean = false;
	public arr = new Array(24).fill(1); //used to loop for the animation

	public currentUser: User | undefined;
	public compulsoryFields: string[] = [];
	public showCompulsoryFields: boolean = false;

	public readonly tacticalOptions = [TACTICAL_OPTION.OFFENSIVE, TACTICAL_OPTION.DEFENSIVE, TACTICAL_OPTION.NONE];
	public readonly stateOptions = [STATE_OPTION.INITIAL, STATE_OPTION.DEVELOPING, STATE_OPTION.ESCALATING, STATE_OPTION.CONTROLLED, STATE_OPTION.SCALE_DOWN];

	private readonly loginServ: LoginService;
	private readonly userServ: UserService;
	private readonly wreq: WebRequestFactory;
	private readonly sanitizer: DomSanitizer;
	private readonly ems: IncidentService;
	private readonly main: MainService;
	private readonly decisionService: DecisionLogService;
	private readonly wres: WebRequestsService;
	private readonly funcService: FunctionalityService;

	constructor(san: DomSanitizer, log: LoginService, usr: UserService, tp: TextProvider, wreq: WebRequestFactory, ems: IncidentService, main: MainService, ds: DecisionLogService, wres: WebRequestsService, funcService: FunctionalityService) {
		this.loginServ = log;
		this.userServ = usr;
		this.text = tp.getStringMap;
		this.wreq = wreq;
		this.sanitizer = san;
		this.ems = ems;
		this.main = main;
		this.decisionService = ds;
		this.wres = wres;
		this.funcService = funcService;
	}

	ngOnInit(): void {
		this.currentUser = this.loginServ.user;
		this.users = this.userServ.Users;
		this.users.sort((a, b) => {
			if (a.name.toUpperCase() >= b.name.toUpperCase()) return -1;
			return 1;
		});
		this.dlSignature = this.funcService.isFunctionalityAvailable.get(FUNCTIONALITY.DL_SIGNATURE)!;
		this.dlType = this.funcService.isFunctionalityAvailable.get(FUNCTIONALITY.DL_TYPE)!;
		this.dlOperational = this.funcService.isFunctionalityAvailable.get(FUNCTIONALITY.DL_OPERATIONAL)!;
		this.funcService.getFunctionalityVisibility();
	}

	public isAudioOrVideo(file: DecisionFile): string {
		if (file.isVideo()) {
			return "resources/img/video-play.svg";
		} else if (file.isAudio()) {
			return "resources/img/sound-wave.svg";
		} else {
			return "resources/img/documents_generic_icon.svg";
		}
	}

	public getDecisionFiles(i: number): DecisionFile[] | undefined {
		if (!this.uploadedFiles) return;
		const decisions = this.uploadedFiles;
		return decisions;
	}

	public readonly openFile = async (file: DecisionFile): Promise<void> => {
		const blob = await this.wreq.getFile(file.id_file);
		if (!blob) return;

		const urlCreator = window.URL || window.webkitURL;
		window.open(urlCreator.createObjectURL(blob));
	};

	public readonly isEvent: () => boolean = () => {
		return this.eventFlag;
	};

	public readonly isDecision: () => boolean = () => {
		return this.decisionFlag;
	};

	public readonly isMessage: () => boolean = () => {
		return this.messageFlag;
	};

	public readonly getCurrentUser: () => User = () => {
		return this.loginServ.user;
	};

	public readonly toggleEvent: Function = () => {
		this.eventFlag = !this.eventFlag;
	};

	public readonly toggleDecision: Function = () => {
		this.decisionFlag = !this.decisionFlag;
	};

	public readonly toggleMessage: Function = () => {
		this.messageFlag = !this.messageFlag;
	};

	public readonly userDropdownGetMainText: () => string = () => {
		return this.selectedUser ? this.selectedUser.name : "";
	};

	public readonly userDropdownCompareSelect: (selected: User, option: User) => boolean = (selected, option) => {
		return option.id === this.selectedUser?.id;
	};

	public readonly userDropdownChangeCallback: (option: User) => void = (option) => {
		this.selectedUser = option;
	};

	public readonly tacticDropdownGetMainText: () => string = () => {
		return this.selectedTactic ? this.text()[this.selectedTactic!] : "";
	};

	public readonly tacticDropdownGetOptionText: (option: TACTICAL_OPTION) => string = (option) => {
		return this.text()[option];
	};

	public readonly tacticDropdownCompareSelect: (selected: TACTICAL_OPTION, option: TACTICAL_OPTION) => boolean = (selected, option) => {
		return option === this.selectedTactic;
	};

	public readonly tacticDropdownChangeCallback: (option: TACTICAL_OPTION) => void = (option) => {
		this.selectedTactic = option;
	};

	public readonly stateDropdownGetMainText: () => string = () => {
		return this.selectedState ? this.text()[this.selectedState!] : "";
	};

	public readonly stateDropdownGetOptionText: (option: STATE_OPTION) => string = (option) => {
		return this.text()[option];
	};

	public readonly stateDropdownCompareSelect: (selected: STATE_OPTION, option: STATE_OPTION) => boolean = (selected, option) => {
		return option === this.selectedState;
	};

	public readonly stateDropdownChangeCallback: (option: STATE_OPTION) => void = (option) => {
		this.selectedState = option;
	};
	public readonly addNewFile: Function = (evt: Event) => {
		const file = (evt.target! as any).files[0] as File;

		const fileSize = file.size / 1024 / 1024; //same size filter as in POI, if POI changes this should change
		if (fileSize >= 100) {
			this.main.addAlert(ALERT_TYPE.DANGER, this.text().FILE_TO_LARGE_MESSAGE, this.text().FILE_TO_LARGE);
			return;
		}
		this.wreq
			.uploadFile(file.name, file)
			.then(async (ans: UploadedFile) => {
				if (!ans) {
					console.error("File upload failed.");
					return;
				}
				let decfile = new DecisionFile(-1, -1, ans.id, file.name);
				decfile.__uri = await this.getFileUri(ans);
				this.uploadedFiles.push(decfile);
				(evt.target! as any).value = "";
			})
			.catch((err) => console.error("Error uploading file:", err));
	};

	public readonly getFileUri: (file: UploadedFile) => Promise<SafeResourceUrl> = async (file) => {
		const blob = await this.wreq.getFile(file.id);
		const url = URL.createObjectURL(blob);
		return this.sanitizer.bypassSecurityTrustResourceUrl(url);
	};

	public readonly saveNewDecision: () => Promise<boolean> = async () => {
		if (!this.dlSignature || !this.dlOperational || (!this.dlType && !this.selectedUser && !this.canvas)) {
			await this.saveAndAttachNoSignature();
			return false;
		}

		this.validateCompulsoryFields();
		if (this.hasCompulsoryFields()) {
			this.showCompulsoryFields = true;
			return false;
		}

		return this.canvas.mainSave(this.saveAndAttachSignature);
	};

	public closeCompulsoryFields(): void {
		this.compulsoryFields = [];
		this.showCompulsoryFields = false;
	}

	public readonly recordDecLog: () => void = () => {
		if (!this.recording) {
			this.recording = true;
			this.speech.startRecording();
		} else {
			this.recording = false;
			this.speech.stopRecognition();
		}
	};

	public readonly onAudioAvailable: (audio: Blob) => void = (audio) => {
		this.audio = audio;
	};

	public readonly onTranscriptionAvailable: (text: string) => void = async (text) => {
		this.predicting = true;
		try {
			const answer = await this.wres.postDLJSON(AIDL.url + "predict", '{"text": "' + text + '"}');
			const prediction = answer.body;
			this.rationale = prediction.rationale;
			this.content = prediction.content;
		} catch (error) {
			this.content = text;
			console.error(error);
			this.predicting = false;
		}
		this.predicting = false;
	};

	public getTypeField(): string {
		const types = [];
		if (this.eventFlag) types.push("Event");
		if (this.decisionFlag) types.push("Decision");
		if (this.messageFlag) types.push("Message");
		return types.join(" ");
	}

	private readonly saveAndAttachSignature: (signature: File) => Promise<boolean> = async (signature) => {
		const signatureUpload: UploadedFile = await this.wreq.uploadFile(signature.name, signature);

		let decision = new Decision(
			-1,
			this.ems.getCurrentIncident()!.id,
			this.loginServ.user.name,
			this.getTypeField(),
			this.selectedTactic,
			this.content,
			this.rationale,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			this.selectedState,
			signatureUpload.id,
			this.selectedUser!.id,
			this.eventFlag,
			this.decisionFlag,
			this.messageFlag
		);
		decision.files = this.uploadedFiles;
		return this.decisionService.saveDecision(decision).then((ans: Decision) => {
			if (this.audio) this.saveRecording(ans);
			this.selectedTactic = this.selectedState = this.selectedUser = this.audio = undefined;
			this.content = this.rationale = "";
			this.eventFlag = this.decisionFlag = this.messageFlag = false;
			this.canvas.eraseAllDraw();
			this.uploadedFiles = new Array<DecisionFile>();
			this.main.setSnackbar(this.text().DECISION_SAVED);
			return true;
		});
	};

	private readonly saveAndAttachNoSignature: () => Promise<boolean> = async () => {
		let decision = new Decision(
			-1,
			this.ems.getCurrentIncident()!.id,
			this.loginServ.user.name,
			this.getTypeField(),
			undefined,
			this.content,
			this.rationale,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			this.decisionFlag,
			this.messageFlag
		);
		decision.files = this.uploadedFiles;
		return this.decisionService.saveDecision(decision).then((ans: Decision) => {
			if (this.audio) this.saveRecording(ans);
			this.selectedTactic = this.selectedState = this.selectedUser = this.audio = undefined;
			this.content = this.rationale = "";
			this.eventFlag = this.decisionFlag = this.messageFlag = false;
			this.uploadedFiles = new Array<DecisionFile>();
			this.main.setSnackbar(this.text().DECISION_SAVED);
			return true;
		});
	};

	private readonly saveRecording: (dec: Decision) => Promise<number | undefined> = async (dec) => {
		if (!this.audio) return undefined;
		const filename = `${dec.id}-${dec.id_mission}-${dec.name}.webm`;
		const uploadedInfo = await this.decisionService.attachAudio(dec, "content", this.audio, filename);
		return uploadedInfo?.id;
	};

	private validateCompulsoryFields(): void {
		this.compulsoryFields = [];

		if (!this.getTypeField()) {
			this.compulsoryFields.push(this.text().TYPE);
		}
		if (!this.selectedUser) {
			this.compulsoryFields.push(this.text().SIGNED_BY);
		}
		if (!this.canvas.isSomethingDrawnOut) {
			this.compulsoryFields.push(this.text().SIGNATURE);
		}
	}

	private hasCompulsoryFields(): boolean {
		return this.compulsoryFields.length > 0;
	}
}

export enum TACTICAL_OPTION {
	NONE = "NONE",
	OFFENSIVE = "OFFENSIVE",
	DEFENSIVE = "DEFENSIVE"
}

export enum STATE_OPTION {
	INITIAL = "INITIAL",
	DEVELOPING = "DEVELOPING",
	ESCALATING = "ESCALATING",
	CONTROLLED = "CONTROLLED",
	SCALE_DOWN = "SCALE_DOWN"
}
