<div
	[ngClass]="{
		'chip-container': true,
		gray: priority === -1,
		red: priority === 1,
		orange: priority === 2,
		yellow: priority === 3
	}"
	(click)="clickEvent.emit($event)"
>
	<div class="exclamation-mark"></div>
	<div class="chip-label" *ngIf="priority !== -1">
		<ng-content></ng-content>
	</div>
</div>
