import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ResourceService } from "src/app/settings/resource/resource.service";
import { Camera } from "src/app/dto/resources/camera";
import { Hardware } from "src/app/dto/resources/hardware";
import { Station } from "src/app/dto/resources/station";
import { LocaleMap } from "src/app/global/constants/text/text-interface";
import { TextProvider } from "src/app/global/constants/text/text-provider";
import { URLMap } from "src/app/global/constants/enums/url-map";

@Component({
	selector: "app-camera-card",
	templateUrl: "camera-card.component.html",
	styleUrls: ["camera-card.css"]
})
export class CameraCardComponent implements OnInit {
	@Output() editing = new EventEmitter<boolean>();

	@Input() camera: Camera = new Camera(-1, "", "", -1);

	@Input() canDelete: boolean = false;

	@Input() saveCb: Function = () => {};

	@Input() deleteCb: Function = () => {};

	public readonly text: () => LocaleMap;
	public default_rtmp_url = "RTMP://" + URLMap.WSURL().split("/")[2].split(":")[0] + "/live";;
	public stations: Array<Station> = [];
	public stationPlaceholder: string = "Station";

	private resourceService: ResourceService;

	constructor(textProv: TextProvider, res: ResourceService) {
		this.text = textProv.getStringMap;
		this.resourceService = res;
	}

	save: Function = () => {
		this.saveCb(this.camera, this.camera);
	};

	delete: Function = () => {
		this.deleteCb(this.camera);
	};

	ngOnInit() {
		this.stations.push(new Station(-1, this.text().UNSPECIFIED));
		this.stations.push(...this.resourceService.Stations);
	}

	public readonly hasCameraBeenEdited = (): boolean => {
		const original = this.resourceService.Cameras.find((e) => e.id === this.camera.id);
		if (!original) return true;
		else 
			return (
				original.name !== this.camera.name ||
				original.description !== this.camera.description ||
				original.station !== this.camera.station ||
				original.rtsp_url !== this.camera.rtsp_url ||
				original.ims_name !== this.camera.ims_name
			);
	};

	public editingCamera(): void {
		this.editing.emit(true);
	}

	public checkInputValues: (check_string: string) => void = (check_string: string) => {
		if (check_string != this.camera.ims_name) {
			this.camera.ims_name = check_string;
		}
		//To check if there are reserved characters in URL as "" and "/"
		if (check_string.indexOf(" ") >= 0) {
			this.camera.ims_name = check_string.replace(/\s/g, "_");
		}
		if (check_string.indexOf("/") >= 0) {
			this.camera.ims_name = check_string.replace(/[\/]/g, "-");
		}
	};

	public stationDropdownGetMainText: (selected: any) => string = () => {
		const station = this.stations.find((e) => e.name === this.camera.station);
		return station ? station.name : "";
	};

	public stationDropdownGetOptionText: (option: any) => string = (station: Station) => {
		return station.name;
	};

	public stationDropdownCompareSelect: (selected: any, option: any) => boolean = (obj: Camera, station: Station) => {
		return obj?.station === station.name;
	};

	public stationDropdownChangeCallback: (option: any) => string = (station: Station) => {
		return (this.camera.station = station.name);
	};
}
