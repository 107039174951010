import { Injectable, OnDestroy } from "@angular/core";


// this service is meant to be used with the detect click out directive
// with this service no matter how many elements with the directive are being rendered at the same time, there's only one event added to the document 
// click event, this is meant to improve performance

@Injectable({
	providedIn: "root"
})
export class DetectClickOutService implements OnDestroy {
	
    private readonly listeners = new Array<{ callback: Function, onlyonce : boolean }>();

    constructor() {
		document.body.addEventListener("click", this.clickHandler);
    }

    ngOnDestroy(): void {
        document.body.removeEventListener("click", this.clickHandler);
    }

    private readonly clickHandler : (evt : Event) => void = (evt) => {
        this.listeners?.forEach((e) => {
            e.callback(evt)
            if(e.onlyonce) this.removeListener(e.callback);
        });
    }

    public registerListener(func : Function, onlyonce?: boolean): void {
        if(!this.listeners.find(e => e.callback === func)) this.listeners.push({ callback: func, onlyonce : !!onlyonce });
    }

    public removeListener(func : Function): void {
        const idx = this.listeners.findIndex(e => e.callback === func);
        if(idx > -1) this.listeners.splice(idx, 1);
    }
}
