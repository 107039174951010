import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { LocaleMap } from "src/app/global/constants/text/text-interface";
import { TextProvider } from "src/app/global/constants/text/text-provider";

@Component({
	selector: "app-closed-incident-info-dialog",
	templateUrl: "closed-incident-info-dialog.component.html",
	styleUrls: ["closed-incident-info-dialog.css"]
})
export class ClosedIncidentInfoDialogComponent implements OnInit {
	@Input() headerText: string = "";
	@Input() bodyText: string = "";

	@Output() closeCallback = new EventEmitter<void>();

	private readonly text: () => LocaleMap;

	constructor(text: TextProvider) {
		this.text = text.getStringMap;
	}

	ngOnInit(): void {
		if (!this.headerText) this.headerText = this.text().INCIDENT_CLOSED;
	}
}
