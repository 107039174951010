import { Component, Input } from "@angular/core";
import { WGSPoint } from "src/app/dto/map/location";

@Component({
	selector: "app-track-point-label",
	templateUrl: "track-point-label.component.html",
	styleUrls: ["track-point-label.css", "../map-label.css"]
})
export class TrackPointLabelComponent {
	@Input() point!: WGSPoint;

	constructor() {}
}
