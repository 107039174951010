import { Component } from "@angular/core";
import { MainService } from "../../main.service";

@Component({
	selector: "app-main-snackbar",
	templateUrl: "app-main-snackbar.component.html",
	styleUrls: ["app-main-snackbar.css"]
})
export class MainSnackbarComponent {
	public main: MainService;

	constructor(main: MainService) {
		this.main = main;
	}

	public readonly show: () => boolean = () => {
		return this.main.showSnackbar;
	};

	public readonly getText: () => string = () => {
		return this.main.snackbarText;
	};
	public readonly onCancel = (): void => {
		this.main.showSnackbar = false;
	};
}
