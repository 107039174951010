import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { AutomationService } from "../global/automation/automation.service";
import { URLMap } from "../global/constants/enums/url-map";
import { MESSAGE_TYPE } from "../global/messaging/messages";
import { LoginService } from "../login/login.service";
import { WebRequestsService } from "./web-request.service";

@Injectable({
	providedIn: "root"
})
export class ProbeService {
	public readonly $systemOffline = new Subject<boolean>();

	private readonly PING_PERIOD_MS = 3000;
	private readonly OFFLINE_THRESHOLD = 0.04;
	private readonly ONLINE_THRESHOLD = 0.04;

	private errorRate = {
		main_url: 0,
		backup_url: 0
	};

	private values = {
		main_url: new Array<number>(),
		backup_url: new Array<number>()
	};

	private readonly wres: WebRequestsService;
	private readonly automs: AutomationService;
	private readonly login: LoginService;

	private readonly backupMode: boolean = false;

	constructor(httpService: WebRequestsService, automs: AutomationService, login: LoginService) {
		this.wres = httpService;
		this.automs = automs;
		this.login = login;

		this.backupMode = !!URLMap.backup_url;

		if (this.backupMode) {
			setInterval(() => this.pingInterval(SERVER.MAIN), this.PING_PERIOD_MS);
			setInterval(() => this.pingInterval(SERVER.BACKUP), this.PING_PERIOD_MS);
			this.wres.errorHandler$.subscribe((object) => {
				if (object.type === MESSAGE_TYPE.ERROR_CONNECTION) this.insertValue(false, URLMap.use_backup ? SERVER.BACKUP : SERVER.MAIN);
			});
			this.wres.successHandler$.subscribe(() => {
				this.insertValue(true, URLMap.use_backup ? SERVER.BACKUP : SERVER.MAIN);
			});
		} else {
			window.addEventListener("offline", this.handleOffline.bind(this));
			window.addEventListener("online", this.handleOnline.bind(this));
		}
	}

	

	private async pingInterval(server: SERVER): Promise<void> {
		if (this.automs.go && ((URLMap.use_backup && server === SERVER.BACKUP) || (!URLMap.use_backup && server === SERVER.MAIN))) return;
		const ans = await this.wres.ping(URLMap[server]!);
		this.insertValue(ans, server);
	}

	private insertValue(val: boolean, server: SERVER): void {
		this.values[server].push(val ? 0 : 1);
		if (this.values[server].length > 20) this.values[server].shift();
		this.errorRate[server] =
			this.values[server].reduce((prev, curr) => {
				return prev + curr;
			}) / this.values[server].length;
		this.switchBackendIfNeeded();
	}

	private switchBackendIfNeeded(): void {
		if ((URLMap.use_backup && this.errorRate[SERVER.MAIN] < this.ONLINE_THRESHOLD) || (!URLMap.use_backup && this.errorRate[SERVER.MAIN] > this.OFFLINE_THRESHOLD)) {
			URLMap.switchMode();
			console.log("changing to " + URLMap.WSURL());
			if (URLMap.use_backup) this.handleOffline();
			else this.handleOnline();
		}
	}

	private handleOffline(): void {
		this.$systemOffline.next(true);
	}

	private handleOnline(): void {
		this.$systemOffline.next(false);
		if (this.backupMode) this.login.onLogout();
		localStorage.setItem("onlineStatus", "online");
	}
}

enum SERVER {
	MAIN = "main_url",
	BACKUP = "backup_url"
}
