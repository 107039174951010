import { DTO } from "../net/dto";
import { Appliance } from "./appliance";
import { Resource } from "./resource";

export class ResourceLogEntry implements DTO {
	id: number;
	id_agent: number;
	id_mission: number;
	assigned_ms: number;
	__assigned_date: Date | undefined;
	mobile_ms: number;
	__mobile_date: Date | undefined;
	arrival_ms: number;
	__arrival_date: Date | undefined;
	available_ms: number;
	__available_date: Date | undefined;

	__object: Resource | undefined; //arrive also Personnel

	timestamp = Date.now();
	constructor(id: number, id_agent: number, id_mission: number, assigned_ms: number, mobile_ms: number, arrival_ms: number, available_ms: number) {
		this.id = id;
		this.id_agent = id_agent;
		this.id_mission = id_mission;

		this.assigned_ms = assigned_ms;
		this.__assigned_date = Number.isInteger(assigned_ms) && assigned_ms > 0 ? new Date(assigned_ms) : undefined;

		this.mobile_ms = mobile_ms;
		this.__mobile_date = Number.isInteger(mobile_ms) && mobile_ms > 0 ? new Date(mobile_ms) : undefined;

		this.arrival_ms = arrival_ms;
		this.__arrival_date = Number.isInteger(arrival_ms) && arrival_ms > 0 ? new Date(arrival_ms) : undefined;

		this.available_ms = available_ms;
		this.__available_date = Number.isInteger(available_ms) && available_ms > 0 ? new Date(available_ms) : undefined;
	}

	public static readonly fromJson: (json: string) => ResourceLogEntry = (json) => {
		var jc = JSON.parse(json);
		return new ResourceLogEntry(jc.id, jc.id_agent, jc.id_mission, jc.assigned_ms, jc.mobile_ms, jc.arrival_ms, jc.available_ms);
	};
	public readonly getJson: Function = () => {
		return JSON.stringify(this, ["id", "id_agent", "id_mission", "assigned_ms", "mobile_ms", "arrival_ms", "available_ms"]);
	};
}
