import { Component, ElementRef, EventEmitter, HostListener, Input, Output } from "@angular/core";
import { JesipItem } from "src/app/dto/jesip/item-list";
import { LocaleMap } from "src/app/global/constants/text/text-interface";
import { TextProvider } from "src/app/global/constants/text/text-provider";

@Component({
	selector: "app-jesip-list",
	templateUrl: "./jesip-list.component.html",
	styleUrls: ["./jesip-list.component.css"]
})
export class JesipListComponent {
	@Input() items: Array<JesipItem> = new Array<JesipItem>();
	@Input() isDisabled: boolean = false;
	@Input() isJdm: boolean = false;

	@Output() itemUpdate = new EventEmitter<JesipItem>();

	public readonly text: () => LocaleMap;
	public expandedItemIndex: number | null = null;

	private readonly elRef: ElementRef;

	constructor(textProv: TextProvider, elRef: ElementRef) {
		this.text = textProv.getStringMap;
		this.elRef = elRef;
	}

	public toggleItem(idx: number): void {
		const currentItem = this.items[idx];

		if (currentItem.isExpanded) {
			if (!currentItem.value || currentItem.value.trim() === "") {
				currentItem.isExpanded = false;
				this.expandedItemIndex = null;
			}
		} else {
			if (this.expandedItemIndex !== null) {
				const previousItem = this.items[this.expandedItemIndex];
				if (!previousItem.value || previousItem.value.trim() === "") {
					previousItem.isExpanded = false;
				}
			}

			currentItem.isExpanded = true;
			this.expandedItemIndex = idx;

			setTimeout(() => {
				const inputElement = document.getElementById(`input_${idx}`);
				if (inputElement) {
					inputElement.focus();
				}
			});
		}
	}

	public onKeyPressed(event: Event, inputElement: HTMLTextAreaElement, item: JesipItem): void {
		const trimmedValue = item.value ? item.value.trim() : "";

		if (event instanceof KeyboardEvent) {
			const keyboardEvent = event as KeyboardEvent;
			if (keyboardEvent.key === "Enter" || keyboardEvent.key === "Tab") {
				event.preventDefault();
				if (trimmedValue === "") {
					item.isExpanded = false;
					this.expandedItemIndex = null;
					inputElement.blur();
				} else if (keyboardEvent.key === "Enter") {
					this.closeItem(item);
				}
			}
		} else if (trimmedValue === "") {
			event.preventDefault();
			if (!item.isExpanded) {
				item.isExpanded = true;
				this.expandedItemIndex = this.items.indexOf(item);
				setTimeout(() => {
					inputElement.focus();
				});
			} else {
				item.isExpanded = false;
				this.expandedItemIndex = null;
				inputElement.blur();
			}
		}
	}

	public readonly closeItem = (item: JesipItem): void => {
		this.itemUpdate.emit(item);
	};

	public onMousedownOutside(event: Event): void {
		const isClickInside = this.elRef.nativeElement.contains(event.target);
		if (!isClickInside && this.expandedItemIndex !== null && !this.items[this.expandedItemIndex].value) {
			this.items[this.expandedItemIndex].isExpanded = false;
			this.expandedItemIndex = null;
		}
	}
}
