<div class="container" *ngIf="!authenticated">
	<span class="welcome-message"> Welcome to IRIS Core </span>
	<label>Use your Microsoft account to Sign in</label>
	<button class="login-button pointer" (click)="loginSso()">
		<img
			class="microsoft-logo"
			src="resources/img/logos/microsoft logo.svg"
			alt="Microsoft logo"
		/>
		<span class="login-button-text">Sign in with Microsoft</span>
	</button>
	<img class="logo-unblur" src="resources/img/logos/Unblur powered by.svg" alt="Powered by" />
</div>
