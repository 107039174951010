<div
	data-add-action-name="toggle-switch"
	[ngClass]="{
		switch: true,
		on: currentToggleState,
		off: '!currentToggleState',
		disabled: isDisabled
	}"
	(click)="clickCb.emit($event)"
>
	<div class="slider"></div>
</div>
