<div class="container">
	<div class="set-text">
		<span>{{ this.text().FSG_SELECT_TEXT }}:</span>
	</div>
	<div class="body">
		<div
			*ngFor="let sector of sectors"
			class="chip"
			[style.border]="'1px solid ' + getSectorColour(sector)"
			(click)="selectSector(sector)"
		>
			{{ getSectorValueAsLocalizedString(sector) }}
		</div>
	</div>
</div>
