import { Size } from "../../map/size";

export class ResourceType {
	id: number;
	name: string;
	color: string;
	description: string;
	icon_path: string;
	icon_size: Size;
	fixed_position: boolean;

	constructor(id: number, name: string, color?: string, description?: string, icon_path?: string, icon_size?: Size, fixed_position?: boolean) {
		this.id = id;
		this.name = name;
		this.color = color ? color : "#fff";
		this.description = description ? description : "";
		this.icon_path = icon_path ? icon_path : "";
		this.icon_size = icon_size ? icon_size : new Size(0, 0);

		e: new Size(0, 0);
		this.fixed_position = fixed_position ? fixed_position : false;
	}

	public static readonly fromJson: (json: string) => ResourceType = (json: string) => {
		var jc = JSON.parse(json);
		return new ResourceType(jc.id, jc.name, jc.color, jc.description, jc.icon_path, jc.icon_size, jc.fixed_position);
	};

	public readonly getJson: Function = () => {
		return JSON.stringify(this, ["id", "name", "color", "description"]);
	};
}
