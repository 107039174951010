<div *ngIf="show()" [attr.data-cy]="'loading-snackbar-' + this.snackbarText" class="container">
	<span>{{ getText() }}</span>
</div>

<div *ngIf="show()" class="spinner-container">
	<lottie-player
		src="{{ 'resources/lottie/loading-blue.json' | baseHref }}"
		background="transparent"
		speed="1"
		autoplay
	></lottie-player>
</div>
