export class IncidentType {
	id: number;
	name: string;
	color: string;

	constructor(id: number, name: string, color: string) {
		this.id = id;
		this.name = name;
		this.color = color;
	}

	public static readonly fromJson: (json: string) => IncidentType = (json: string) => {
		var jc = JSON.parse(json);
		return new IncidentType(jc.id, jc.name, jc.color);
	};

	public readonly getJson: Function = () => {
		return JSON.stringify(this, ["id", "name", "color"]);
	};
}
