<div
	class="main-transparent-mask"
	(click)="openMenu = false; $event.stopPropagation()"
	*ngIf="openMenu"
></div>

<div #blueDrop class="blue-dropdown {{ openMenu ? 'open' : '' }}">
	<div
		data-add-action-name="language-dropdown"
		class="blue-dropdown-text text-div"
		(click)="openMenu = !openMenu; $event.stopPropagation()"
	>
		<div class="flag-icon">
			<img [attr.src]="'resources/img/icons-lang/flag-' + object + '.svg'" />
		</div>
		<span class="font-size-17px">{{ getLangString(object) }}</span>
		<div class="dropdown-arrow-mask arrow-mask">
			<div class="dropdown-triangle {{ openMenu ? 'up' : 'down' }}"></div>
		</div>
	</div>
	<div
		#blueMenu
		class="menu {{ openMenu ? 'dopen' : '' }}"
		inherit-width
		appCheckOrientation
		*ngIf="openMenu"
	>
		<div class="scroll">
			<div
				[attr.data-add-action-name]="option"
				class="item {{ isSelected(option) ? 'active' : '' }} text-div"
				*ngFor="let option of options"
				(click)="select(option); openMenu = false"
			>
				<div class="flag-icon">
					<img [attr.src]="'resources/img/icons-lang/flag-' + option + '.svg'" />
				</div>
				<div class="text-container font-size-17px">{{ getLangString(option) }}</div>
			</div>
		</div>
	</div>
</div>
