import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ExtendToBottomDirective } from "./extend-to-bottom.directive";
import { SetStyleDirective } from "./set-style.directive";
import { AreaPatternTypeOptionDirective } from "./area-pattern-type-option.directive";
import { FilterPipe } from "./filter.pipe";
import { InstantFocusDirective } from "./instant-focus.directive";
import { PositionRespectToElementDirective } from "./position-respect-to-element.directive";
import { IRISImageDirective } from "./iris-image/iris-image.directive";
import { SetChildrenMaxWidthDirective } from "./max-width-no-overflow.directive";
import { DetectClickOutDirective } from "./detect-click-out/detect-click-out.directive";
import { AutoSizeInputDirective } from "./auto-size-input";
import { DropdownMenuCheckOrientationDirective } from "./dropdown-menu-orientation.directive";
import { SpeechToTextDirective } from "./speech.directive";
import { BoldTextPatternDirective } from "./bold-text-pattern.directive";
import { DroppableDirective } from "./droppable.directive";
import { DraggableDirective } from "./draggable.directive";
import { RecaptchaDirective } from "./recaptcha.directive";
import { HoverDirective } from "./hover.directive";
import { ScrollToElementDirective } from "./scroll-to-element.directive";
import { TextareaAutoResizeDirective } from "./textarea-auto-resize.directive";
import { LongTapDirective } from "./long-tap.directive";
import { AutomaticScrollDirective } from "./auto-scroll.directive";

const elements = [
	ExtendToBottomDirective,
	SetStyleDirective,
	AreaPatternTypeOptionDirective,
	FilterPipe,
	InstantFocusDirective,
	PositionRespectToElementDirective,
	IRISImageDirective,
	SetChildrenMaxWidthDirective,
	DroppableDirective,
	DetectClickOutDirective,
	AutoSizeInputDirective,
	DropdownMenuCheckOrientationDirective,
	SpeechToTextDirective,
	BoldTextPatternDirective,
	DraggableDirective,
	RecaptchaDirective,
	HoverDirective,
	ScrollToElementDirective,
	TextareaAutoResizeDirective,
	LongTapDirective,
	AutomaticScrollDirective
];

@NgModule({
	declarations: elements,
	imports: [CommonModule],
	exports: elements
})
export class DirectiveModule {}
