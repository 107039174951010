import { Resource } from "src/app/dto/resources/resource";
import { User } from "src/app/dto/user/user";
import { WGSPoint } from "../map/location";
import { Appliance } from "./appliance";

export class Personnel extends Resource {
	id_user: number | undefined;
	__user: User | undefined;
	__appliance: Appliance | undefined;

	constructor(
		id: number,
		id_mission: number,
		latitude: number,
		longitude: number,
		id_camera: number,
		type: number,
		name: string,
		description?: string,
		telemetry_id?: number,
		id_gps_device?: number,
		deleted?: boolean,
		timestamp?: number,
		fixed_position?: boolean,
		public_id?: string,
		id_state?: number,
		color?: string,
		icon_path?: string,
		icon_width?: number,
		icon_height?: number,
		track_color?: string,
		station?: string,
		id_user?: number,
		user?: User
	) {
		super(id, id_mission, latitude, longitude, id_camera ? id_camera : -1, type, name, description, telemetry_id, id_gps_device, deleted, timestamp, fixed_position, public_id, id_state, color, icon_path, icon_width, icon_height, track_color, station, false);
		// user pointers might be set after initialization
		this.id_user = id_user ? id_user : -1;
		this.__user = user;
	}
	public static readonly fromJson: (json: string) => Resource = (json: string) => {
		let ja = JSON.parse(json);
		if (!ja.position) ja.position = new WGSPoint(ja.latitude, ja.longitude);
		if (ja.position.lat) ja.position.latitude = ja.position.lat;
		if (ja.position.longitude) ja.position.longitude = ja.position.lng;
		if (ja.mission_id) ja.id_mission = ja.mission_id;
		return new Personnel(
			ja.id,
			ja.id_mission,
			ja.position.lat,
			ja.position.lng,
			ja.camera_id,
			ja.type,
			ja.name,
			ja.description,
			ja.telemetry_id,
			ja.id_gps_device,
			ja.deleted,
			ja.timestamp,
			ja.fixed_position,
			ja.public_id,
			ja.id_state,
			ja.color,
			ja.icon_path,
			ja.icon_width,
			ja.icon_height,
			ja.track_color,
			ja.station,
			ja.id_user,
			ja.user
		);
	};
}
