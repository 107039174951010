import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, QueryList, ViewChildren } from "@angular/core";
import * as html2canvas from "html2canvas";
import { first } from "rxjs/operators";
import { DocumentService } from "src/app/document/document.service";
import { PAGE_SIZES } from "src/app/dto/Documents/page-sizes";
import { Incident } from "src/app/dto/items/incident";
import { LocaleMap } from "src/app/global/constants/text/text-interface";
import { TextProvider } from "src/app/global/constants/text/text-provider";

@Component({
	selector: "app-checklist-blank-template",
	templateUrl: "checklist-blank.component.html",
	styleUrls: ["checklist-blank.css", "../../jesip.css", "../../../../document.css"]
})
export class BlankChecklistTemplateComponent implements AfterViewInit {
	@Input() generationTime!: number;
	@Input() event!: Incident;

	@Output() docReady = new EventEmitter<HTMLCanvasElement[]>();

	@ViewChildren("pageElements") pageElementRef!: QueryList<ElementRef>;

	public numberOfPages: number = 1;
	public pages = new Array<Array<ChecklistItem>>();
	public readonly pageSize = PAGE_SIZES.STANDARD;

	public readonly text: () => LocaleMap;

	private readonly docService: DocumentService;
	private abortDownload = false;

	constructor(tp: TextProvider, doc: DocumentService) {
		this.text = tp.getStringMap;
		this.docService = doc;
		this.docService.onDownloadCancel$.subscribe(() => this.abortDownload = true);
	}

	async ngAfterViewInit(): Promise<void> {
		this.pageElementRef.changes.pipe(first()).subscribe((pages) => this.generateDocument(pages));
		this.buildTemplate();
	}

	private async generateDocument(pages: QueryList<ElementRef>): Promise<void> {
		try {
			const output: HTMLCanvasElement[] = [];

			for (let i = 0; i < pages.length; i++) {
				const page = pages.get(i)!;
				const { nativeElement: element } = page;
				this.docService.setLoadingMessage(this.text().GENERATING_DOCUMENTATION_PAGE(i, pages.length));
				const canvas = await html2canvas.default(element);
				if(this.abortDownload) return;
				output.push(canvas);
			}

			this.docReady.emit(output);
		} catch (error) {
			if(this.abortDownload) return;
			console.error(error);
			this.docService.downloadError$.next();
		}
	}

	private buildTemplate() {
		const page: Array<ChecklistItem> = [
			new ChecklistItem(this.text().JESIPCHECKLISTITEMS.ITEM1),
			new ChecklistItem(this.text().JESIPCHECKLISTITEMS.ITEM2),
			new ChecklistItem(this.text().JESIPCHECKLISTITEMS.ITEM3),
			new ChecklistItem(this.text().JESIPCHECKLISTITEMS.ITEM4),
			new ChecklistItem(this.text().JESIPCHECKLISTITEMS.ITEM5),
			new ChecklistItem(this.text().JESIPCHECKLISTITEMS.ITEM6),
			new ChecklistItem(this.text().JESIPCHECKLISTITEMS.ITEM7),
			new ChecklistItem(this.text().JESIPCHECKLISTITEMS.ITEM8),
			new ChecklistItem(this.text().JESIPCHECKLISTITEMS.ITEM9)
		];

		this.pages.push(page);
	}
}

class ChecklistItem {
	text: string;
	timestamps = new Array<CheckTimestamp>();

	constructor(text: string) {
		this.text = text;
	}
}

class CheckTimestamp {
	checked: boolean;
	time: string;

	constructor(time: string) {
		this.time = time;
		this.checked = false;
	}
}
