/**
 * A representation of a latitude/longitude pair using WGS84 representation.
 * It should be used to store georeferenced values independently of which map engine is being used.
 */
export class WGSPoint {
	public static WKID = 4326;

	latitude: number;
	longitude: number;
	altitude: number | undefined;

	// to make this object compatible with google.maps.LatLng
	lat: number;
	lng: number;

	timestamp: number | undefined;

	constructor(latitude: number, longitude: number, altitude?: number | null) {
		this.latitude = this.lat = latitude;
		this.longitude = this.lng = longitude;
		this.altitude = altitude ? altitude : undefined;
	}
}
