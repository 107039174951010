import { DTO } from "src/app/dto/net/dto";
import { ControlMeasure } from "./control-measure";
import { Scenario } from "./scenario";
import { RiskInformationRelation } from "./risk-information-relation";

export class ScenarioRelation implements DTO {
	id: number;
	id_ara: number;
	id_scenario: number;
	riskInformations: Array<RiskInformationRelation>;

	__object: Scenario | undefined;

	timestamp = Date.now();
	timestamp_ms: number = 0;

	constructor(id: number, id_ara: number, id_scenario: number) {
		this.id = id;
		this.id_ara = id_ara;
		this.id_scenario = id_scenario;
		this.riskInformations = [];
	}

	public static readonly fromJson: (json: string) => ScenarioRelation = (json: string) => {
		let jc: any = JSON.parse(json);
		const scenarioRel = new ScenarioRelation(jc.id, jc.id_ara, jc.id_scenario);
		return scenarioRel;
	};

	public readonly getJson: () => string = () => {
		return JSON.stringify(this, ["id", "id_ara", "id_scenario"]);
	};
}
