import { AfterViewInit, Directive, ElementRef, Input, OnInit } from "@angular/core";

@Directive({
	selector: "[appAutoSizeInput]"
})
export class AutoSizeInputDirective implements AfterViewInit {
	private elem: HTMLElement;

	constructor(el: ElementRef) {
		this.elem = el.nativeElement;
	}

	ngAfterViewInit() {
		this.elem.style.width = this.elem.offsetWidth + 30 + "px";
		this.elem.addEventListener("keypress", this.resize);
	}

	public readonly resize: (evt: any) => void = (evt) => {
		const textElem = document.getElementsByClassName("hidden-input")?.item(0);
		let origSize = this.elem.offsetWidth;
		const k = 0.5;
		if (origSize + k * evt.target.value.length < origSize) return;
		const size = origSize + k * evt.target.value.length;
		this.elem.style.width = textElem?.nodeValue?.length + "px";
	};
}
