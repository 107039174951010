import { Component, EventEmitter, Input, Output } from "@angular/core";
import { LANGUAGE } from "src/app/global/constants/enums/languages";
import { LocaleMap } from "src/app/global/constants/text/text-interface";
import { TextProvider } from "src/app/global/constants/text/text-provider";

@Component({
	selector: "app-language-dropdown",
	templateUrl: "language-dropdown.component.html",
	styleUrls: ["language-dropdown.css", "../dropdown.css"]
})
export class LanguageDropdownComponent {
	// object is the data object
	@Input() object!: LANGUAGE;

	/**
	 * itemClickCallback is executed whenever the user clicks on an item, most likely to select it
	 */
	@Output() selected = new EventEmitter<LANGUAGE>();

	public openMenu: boolean = false;
	public showMenuAtTop: boolean = false;
	public heightOffset = 0;
	public readonly text: () => LocaleMap;
	options: Array<LANGUAGE> = [];

	private readonly textProvider: TextProvider;
	constructor(tp: TextProvider) {
		this.textProvider = tp;
		this.options = tp.getSupportedLanguages();
		this.text = tp.getStringMap;
	}

	public readonly getLangString: (lang: LANGUAGE) => string = (lang) => {
		return this.textProvider.getLangLocalizedName(lang);
	};

	public readonly isSelected: (lang: LANGUAGE) => boolean = (lang) => {
		return this.object === lang;
	};

	public readonly select: (lang: LANGUAGE) => void = (lang) => {
		this.object = lang;
		this.selected.emit(lang);
	};
}
