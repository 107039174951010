<div class="main-black-mask" (click)="close(); $event.stopPropagation()"></div>
<div data-cy="video-edit-elem-window" class="edit-elem-window">
	<div class="header">
		{{ text().AVAILABLE_CAMERAS }}
		<app-white-cross
			class="dialog-cross-button cross-button"
			(click)="close()"
		></app-white-cross>
	</div>

	<div data-cy="camera-agent-list" class="agent-list">
		<div
			[attr.data-add-action-name]="
				'resource-' + agent.name + '-camera-' + getCameraName(agent)
			"
			data-cy="camera-item"
			class="agent-list-item"
			*ngFor="let agent of agentsWithCam"
			(click)="selectSlot(agent)"
		>
			<span> {{ agent.name }} - {{ getCameraName(agent) }}</span>
		</div>
	</div>
</div>
