<div class="map-label-container area-label-container">
	<div class="map-label">
		<div class="name">
			{{ area.name }}
		</div>
		<div class="type">
			{{ area.__typeObj!.name }}
		</div>
	</div>
</div>
