import { LocaleMap } from "../text-interface";

export class TextES implements LocaleMap {
	ACCEPT = "Aceptar";
	ADD = "Añadir";
	ADD_FILES = "Añadir Archivos";
	ACCESS_HISTORY = "Acceso";
	ACCESS_HISTORY_PERMISSION = "Este permiso permite acceder al historial de incidentes.";
	ACCESS_LIVE_INCIDENTS = "Acceso";
	ADD_ICON = "Añadir icono";
	ADD_CANVAS_IMAGE = "Añadir nueva imagen";
	ADD_CANVAS_IMAGE_INFO = "Drag or upload an image (JPG; PNG or SVG)";
	ACCOUNT_MENU = "Perfil personal";
	ACTIONS = "Acciones";
	ACTIVATE_EF = "Activar Formulario";
	ACTIVATE_EF_INFO = "estará activo y será editable.";
	ACTIVE = "Activo";
	ACTIVATE = "Activar";
	ACTIVATED = "activado";
	ADD_DETAILS = "Añadir detalles";
	ADD_OVERLAY_INFO = "Arrastra o haga clic aquí para añadir sus archivos.";
	ADDITIONAL_INFO = "Información adicional";
	ADDRESS = "Dirección";
	AGENT = "Recurso";
	AGENTS = "Recursos";
	AGENTS_MENU = "Configuración de Recursos";
	AGENTS_LOADED = "Recursos cargados";
	AGENTS_LOADING = "Cargando recursos";
	AGENT_TYPE = "Tipo de recurso";
	ALPHABETICAL = "Alfabético";
	ALREADY_EXISTS = "Ese valor ya está siendo usado";
	AND = "y";
	APPLY = "Aplicar";
	ARA = "Evaluación analítica de riesgos";
	ARA_SHORT = "ARA";
	ARA_CREATION_TIME = "Hora de creación del ARA";
	ARA_DRAFT_DIALOG_TEXT = "Hay un borrador de Nuevo ARA. Descártelo para empezar de nuevo o Continúe para completarlo";
	TM_DRAFT_DIALOG_TEXT = "Hay un borrador guardado. Descártelo para empezar de nuevo o Continúe para completarlo";
	ARA_SECTOR_DRAFT = "Hay un borrador para este Sector. Descártelo para empezar de nuevo o Continúe para completarlo";
	ARA_FOR = "Evaluación analítica de riesgos para ";
	ARA_FORMS = "Formulario de evaluación analítica de riesgos";
	ARA_LAST_REVIEW = "Última Revisión";
	ARA_LOADING = "Cargando ARAs";
	ARA_NO_SECTOR = "SIN SECTOR";
	ARA_SAVED = "ARA guardado";
	ARA_SAVED_POPUP_TEXT_1 = "Puedes encontrar el ARA guardado en la ";
	ARA_SAVED_UPDATED_POPUP_TEXT_2 = "página de ARAs";
	ARA_REVIEWED = "ARA revisado";
	ARA_UPDATED_POPUP_TEXT1 = "Puedes encontrar el último ARA actualizado en la";
	ARAS_WITHOUT_SECTOR = "Evaluaciones de riesgos sin sector";
	ARA_TT_MARGINAL_DESC = "Daño menor";
	ARA_TT_SERIOUS_DESC = "Daños que se deben notificar";
	ARA_TT_VERY_SERIOUS_DESC = "Daño severo";
	ARA_TT_CRITICAL_DESC = "LDaños fatales";
	ARA_TT_CATASTROPHIC_DESC = "Muerte o daños a gran escala";
	ARA_TT_UNLIKELY_DESC = "Muy poca o ninguna possibilidad";
	ARA_TT_REMOTE_DESC = "Podría ocurrir con el tiempo";
	ARA_TT_REASONABLY_DESC = "Es probable que ocurra";
	ARA_TT_PROBABLE_DESC = "Va ocurrir en algún momento";
	ARA_TT_HIGH_DESC = "Es muy probable que ocurra";
	ARA_TT_LOW_RISK_DESC = "Sin acción o acción de baja prioridad";
	ARA_TT_MEDIUM_RISK_DESC = "Insatisfactorio. Eliminar o controlar peligros. Reevaluar";
	ARA_TT_HIGH_RISK_DESC = "Inaceptable. Tomar acción inmediata";
	ARA_REVIEW_TIME = "Hora de actualización del ARA";
	ARAS = "ARA's";
	AREA = "Área";
	AREA_DELETED = "Area eliminada";
	AREA_SAVED = "Área guardada";
	AREA_SAVED_NEW = "Nueva Área añadida";
	AREA_HAS_SECTOR = "Esta área está asignada a un sector";
	AREAS = "Áreas";
	AREA_ALREADY_HAS_SECTOR = "La área seleccionada ya tiene un sector asignado";
	AREA_EDITING_MESSAGE = "Editando Area. Bloquea la edición al finalizar para continuar";
	AREA_MARKERS = "Marcadores de área";
	AREA_NAME_ON_USE = "Nombre de área en uso";
	AREA_TYPE = "Tipo de área";
	AREA_VIEW_DETAILS = "(Segmentos, Áreas)";
	AREYOUSURE = "Estás seguro que quieres";
	AMBULANCE = "Ambulancia";
	ASSIGN = "Asignar";
	ASSIGNED = "Asignado";
	ASSIGNED_TO_INCIDENT = "Asignados al incidente";
	ASSIGN_AREA = "Asignar area";
	ASSOCIATED_USER = "Usuario asociado";
	AUTHOR = "Autor";
	AT = "a las";
	AT_RISK = "En riesgo";
	ATTACH = "Adjuntar";
	ATTACHMENT = "Archivo adjunto";
	ATTACH_FILE_FOR = "Adjuntar archivo para";
	ATTACH_FILE = "Adjuntar archivo";
	ATTACHED_FILE = "Archivo adjuntado";
	APPLIANCES = "Medios";
	APPLIANCE = "Medio";
	APPLIANCE_TYPE = "Tipo de aparato";
	ALL = "Todo";
	ALL_INFO_LOST = "Si continúa, toda la información se perderá";
	ALL_INCIDENTS = "Todos los incidentes";
	AUTHENTICATION_FAILED = "Autenticación fallada";
	AUTHENTICATION_REQUIRED = "Autenticacion requerida";
	AUTOMATIC = "Automático";
	AVAILABLE = "Disponible";
	AVAILABLE_CAMERAS = "Cámaras disponibles";
	AVAILABLE_RESOURCES = "Recursos disponibles";
	BACK = "Anterior";
	BASEMENT = "Sótano";
	BEGIN_BRIEFING_SYNTHESIS = "Empezando análisis de incidente para ";
	BIRTHDATE = "Fecha de nacimiento";
	BLUEPRINT = "Capa";
	BUILDING_DOCUMENTATION = "Preparando la documentación de los logs";
	BUILDING_ARA_DOCUMENTACION = "Preparando la documentación de ARA";
	BUILDING_FULL_DOCUMENTACION = "Preparando toda la documentación";
	BUTTON_INFORMATION = "Información";
	CALLSIGN = "Identificador";
	CALLOUT = "Medios";
	CANCEL = "Cancelar";
	CAMERA = "Cámara";
	CAMERA_ASSIGNED = "Esta cámara ya está asignada a otro recurso";
	CAMERA_ASSIGNED_TO = "Esta cámara está actualmente asignada al recurso ";
	CAMERAS = "Cámaras";
	CAMERAS_AND_GPS = "Cámaras y GPS";
	CAMERA_DELETED = "La cámara se ha eliminado.";
	CAMERA_SAVED = "La cámara se ha guardado.";
	CANT_DELETE_MILESTONE_CAM = "Las cámaras de Milestone no se pueden eliminar.";
	CAPABILITY = "Capacidad";
	CATASTROPHIC = "Catastrófica";
	CENTER_AT = "Centrar";
	CIRCLE = "Círculo";
	CIVILIANS = "Civiles";
	CHANGE_FILE = "Cambiar archivo";
	CHANGE_PASSWORD = "Cambiar contraseña";
	CHANGES_NOT_SAVED = "Cambios no guardados";
	CHANGES_SAVED = "Cambios guardados con éxito";
	CHECKLIST = "JESIP Checklist";
	CLICK_TO_ASSIGN = "Haz click para asignar";
	CHOOSE_VIDEO_SLOT = "Selecciona una celda para abrir el vídeo.";
	CHOOSE_PERMISSION = "Elija el permiso que deseas asociar con el usuario:";
	CLEAR = "Limpiar";
	CLEARED = "Despejado";
	CLICK_ADD_ICON = "Click para añadir icono";
	CLICK_ADD_PHOTO = "Click para añadir foto";
	CLICK_VIDEO = "( CLICK PARA SELECCIONAR FUENTE DE VIDEO )";
	CLOSE = "Cerrar";
	CLOSE_INCIDENT = "Cerrar Incidente";
	CLOSED_INCIDENT = "Incidente cerrado";
	CLOSE_INCITENT_AUTHENTICATION = "Por favor ingrese su contraseña para cerrar el incidente";
	CLOSE_INCIDENT_PROMPT = "Va a cerrar este incidente. ¿Desea continuar?";
	CLOSED_EVENTS = "Incidentes cerrados";
	CLOSED_INCIDENT_INFO = "Este es ahora un Incidente cerrado, puede visualizarlo en la pestaña de Incidentes Cerrados.";
	CLOSED_INCIDENT_NO_ACCESS = "Este es ahora un Incidente cerrado. Serás redirigido a la página principal de Incidentes en Vivo.";
	CLOSED_INCIDENT_USERS_INFO = "El comandante ha cerrado el Incidente. Puede visualizarlo en la pestaña de Incidentes Cerrados.";
	CLOSED_INCIDENT_GUEST_INFO = "El comandante ha cerrado el Incidente.";
	CLOSING_THE_DIALOG = "Cerrando este mensaje se cancelará la descarga";
	CLOSING_INCIDENT = "Cerrando Incidente";
	CORDON = "Cordón";
	CREATION = "Creación";
	CREATE_DL = "Crear";
	CREATE_NEW_ITEM = "Crear Nuevo";
	CREATE_NEW_SCENARIO = "Crear nuevo escenario";
	CREATE_NEW_RISK = "Crear nuevo riesgo";
	CREATE_NEW_CM = "Crear nueva medida de control";
	CREATE_NEW_SUBCM = "Crear nueva sub medida de control";
	CREATE_NEW_FSG_MAP_PROMPT = "Haga click sobre el mapa para posicionar el Formulario de Evacuación";
	CREW = "Personal";
	CREDENTIALS = "Credenciales";
	COASTGUARD = "Guardacostas";
	COMPATIBILITY = "Compatibilidad";
	COMPATIBILITY_POPUP_SHORT = "Puede que algunos KML/KMZ no sean compatibles con el mapa.";
	COMPATIBILITY_POPUP_1 = "Puede que algunos KML/KMZ no sean compatibles con el mapa. Puede comprobar el listado de elementos incompatibles";
	COMPATIBILITY_POPUP_2 = "aquí";
	COMMANDER = "Comandante";
	COMMAND = "Comando";
	COMMAND_STRUCTURE = "Estructura de comando";
	COMMANDER_SIGNATURE = "Firma de comandante";
	COMMENTS = "Detalles";
	COMMENTS2 = "Comentarios";
	COMPLETED = "Completado";
	COMPLETED_ARA = "ARA completado";
	COMPULSORY_FIELDS = "Campos por completar";
	CONDITIONS = "Condiciones";
	CONF_LOADING = "Cargando configuración";
	CONFIGURE = "Configurar";
	CONFIGURE_ARA = "Configuración de ARA";
	CONFIGURATION = "Configuración";
	CONFIGURATION_MENU = "Ajustes de Iris";
	CONFIRM = "Confirmar";
	CONFIRM_NEW_PASSWORD = "Confirmar nueva contraseña";
	CONTACT = "Contacto";
	CONTACT_NUMBER = "Número de contacto";
	CONTENT = "Contenido";
	CONTINUE = "Continuar";
	CONTROL_MEASURE_DELETE = "Borrar medida de Control";
	CONTROL_MEASURES = "Medidas de control";
	CONTROL_MEASURES_ABBR = "M. de control";
	CONTROL_ROOM = "Sala de control";
	CONTROLLED = "Controlado";
	CONNECTION_ERROR = "Error de conexión";
	CONSIDER_ADD_CONTROL = "Considere medidas adicionales";
	COMMENT_HERE = "Comenta aquí";
	CODE = "Código";
	COLOR = "Color";
	COPIED_COORDINATES = "Coordenadas copiadas al portapapeles";
	COPY = "Copiar";
	COPY_URL = "Copiar enlace";
	CREATE = "Crear";
	CREATE_INCIDENT = "Crear Incidentes";
	CREATED = "creado";
	CREATE_REVIEW = "Crear/Revisar";
	CREATE_REVIEW_ARA = "Crear/Revisar ARA";
	CRITICAL = "Crítica";
	CURRENT_RECOMMENDATION = "Recomendación actual";
	CURRENTLY = "Actualmente";
	CUSTOM_COLOR = "Personalizar color";
	DATA = "Información";
	DATE = "Fecha";
	DATE_OF_EVENT = "Fecha del evento";
	DAYS_ARRAY = ["L", "M", "X", "J", "V", "S", "D"];
	DB_QUERY_ERROR = "Ha habido un error en la consulta a la base de datos";
	DB_CONNECT_ERROR = {
		agent_not_found: "No se ha encontrado el Recurso",
		appliance_not_found: "No se ha encontrado el Medio",
		ara_exists: "El Área se está usando en un ARA",
		areatype_fk_undeletable: "El Tipo de área se está usando en un Área",
		camera_in_use: "La Cámara está asignada a otro Recurso",
		cant_delete_user: "El Usuario está relacionado con el Registro de decisiones o el ARA",
		fk_undeletable: "El elemento está vinculado a otra información",
		id_not_found: "No se ha encontrado el elemento",
		ims_name_in_use: "Ya existe un incidente con este nombre, escoja otro",
		incident_closed: "No se puede añadir nueva información",
		mission_already_exists: "Ya existe un Incidente con este número, escoja otro",
		mission_not_found: "No se ha encontrado el Incidente",
		name_in_use: "Ya existe un incidente con este nombre, escoja otro",
		name_not_found: "No se ha encontrado el elemento",
		permission_denied: "Su Nivel de permiso no le permite completar la acción",
		poitype_fk_undeletable: "El Tipo de POI está siendo usado",
		query_error: "Ha ocurrido un problema, vuelve a intentarlo o refresque la página",
		user_is_personnel: "El Usuario tiene asociado un Recurso",
		insecure_password: "La contraseña es demasiado predecible, por favor escoja otra"
	};
	DEACTIVATE_EF = "Desactivar Formulario";
	DEACTIVATE_EF_INFO = "estará inactivao y no se podrá editar";
	DEACTIVATED_EF = "Formulario desactivado";
	DEACTIVATED_EF_INFO = "can't be edited or seen.";
	DEACTIVATE = "Desactivar";
	DEACTIVATE_INFO = "No se puede desactivar si contiene información";
	DEACTIVATED = "desactivado";
	DEATTACH = "Quitar adjunto";
	DECISION = "Decisión";
	DECISION_LOG = "Registro de decisiones";
	DECISION_LOG_SHORT = "Reg. Decisiones";
	DECISION_TYPE_MISSING = "Falta especificar el TIPO de registro";
	DECISION_OPERATIONAL_MISSING = "Falta especificar la información operacional";
	DECISION_SAVED = "La decisión ha sido guardada";
	DECISION_LOG_SEARCH = "Buscar logs";
	DEFAULT = "Por defecto";
	DEFAULT_CONNECT_ERROR = "Ha habido un error con la conexión al servidor.";
	DEFAULT_DB_ERROR = "Ha habido un error en el proceso de conexion o consulta a la base de datos.";
	DEFAULT_WEBREQ_ERROR = "Ha habido un error en la peticion al servidor.";
	DEFENSIVE = "Defensivo";
	DELETE = "Eliminar";
	DELETE_AGENTS = "Borrar Recursos";
	DELETE_AGENTS_PERMISSION = "Este permiso permite borrar usuarios.";
	DELETE_ITEM = "Borrar";
	DELETE_INCIDENT = "Borrar incidentes cerrados";
	DELETE_MISSION_PERMISSION = "Este permiso permite borrar incidentes.";
	DELETE_MODAL_MESSAGE = "Si continúa; toda la información se perderá";
	DELETE_POIAREA = "Borrar áreas y puntos de interés";
	DELETE_POIAREA_PERMISSION = "Este permiso permite borrar puntos de interés y áreas.";
	DELETE_PLANE_CONFIRMATION = "Esta acción borrará la información del edificio; así como sus planos asociados de todos los incidentes.";
	DELETE_CONFIRM = "Estás seguro de querer borrar ";
	DELETE_USER = "Va a eliminar a ";
	DELETED = "Eliminado";
	DELETED_RISK_INFORMATION = "Información de Riesgo Eliminada";
	DELETED_CONTROL_MEASURE = "Medida de Control Eliminada";
	DELETED_SUB_CONTROL_MEASURE = "Sub Medida de Control Eliminada";
	DESCRIPTION = "Descripción";
	DETAILS = "Detalles";
	DEVELOPING = "En desarrollo";
	DISCARD = "Descartar";
	DISMISS = "Descartar";
	DL_NO_INFORMATION = "La misión seleccionada no tiene información descargable";
	DOCUMENT_GENERATED_ON = "Documento generado en";
	DOCUMENT_DOWNLOADED = "Descarga de Documento";
	DOCUMENTATION = "Documentación";
	DONE = "Hecho";
	DOWNLOAD = "Descargar";
	DOWNLOAD_DOC_EMPTY = "Sin documentos para descargar";
	DOWNLOAD_ERROR = "Ha ocurrido un error y no se ha podido generar los archivos. Inténtalo de nuevo.";
	DOWNLOAD_MEDIA = "Descargar media";
	DOWNLOAD_MESSAGE_FSG = "¿Qué tipo de formulario de evacuación desea descargar?";
	DOWNLOAD_MESSAGE_CHECKLIST = "¿Qué tipo de formulario Checklist desea descargar?";
	DOWNLOAD_MESSAGE_IIMARCH = "¿Qué tipo de formulario IIMARCH desea descargar?";
	DOWNLOAD_MESSAGE_JDM = "¿Qué tipo de formulario JDM desea descargar?";
	DOWNLOAD_MESSAGE_METHANE = "¿Qué tipo de formulario M/ETHANE desea descargar?";
	DOWNLOAD_MESSAGE_DEBRIEF = "¿Qué tipo de formulario DEBRIEF desea descargar?";
	DOWNLOAD_POPUP_TEXT = "¿Estás seguro que quieres descargar los documentos de este incidente?";
	DOWNLOAD_PROCESS_TEXT = "Este proceso puede tardar unos minutos";
	DOWNLOAD_NOT_POPUP_TEXT = "Este incidente no tiene documentos para descargar, por favor selecciona otro.";
	DOWNLOAD_UNAVAILABLE_BODY = "Los archivos de este incidente son demasiado grandes. Si los necesitas contacta con el Servicio Técnico";

	DOWNLOADED_FILES = "Archivos Descargados";
	DOWNLOADING_FILES = "Descargando archivos";
	DRAFT_AVAILABLE = "Borrador disponible";
	DOWNLOADING_VIDEO = "Descargando video";
	DOWNLOADING_CHECKING_VIDEO_DOTTED = "Comprobando y descargando video...";
	DOWNLOADED_VIDEO = "Video descargado";
	DRAFT_SAVED = "Guardado como borrador";
	DRAG_HERE = "Arrastra aquí";
	DRAG_POI = "Arrastre el POI para actualizar su posición";
	DRAG_POI_2 = "Haga click en el mapa para mover el POI a una nueva posición";
	DRAW_POI = "Haga click en el mapa para dibujar el POI";
	DRAG_ZONE = "Arrastra o haga clic aquí para añadir sus archivos.";
	DRAWING = "Dibujo";
	DRAWINGS = "Dibujos";
	DRAW_CIRCULAR_AREA = "Haga click sobre el mapa para establecer el centro de Área";
	DRAW_POLYGON_AREA = "Haga click sobre el mapa para establecer los puntos del Área";
	DRONE = "Dron";
	DRONES = "Drones";
	DRONES_LOADED = "Drones cargados";
	DRONES_LOADING = "Cargando drones";
	EDIT = "Editar";
	EDITED = "Editado";
	EDITING = "Editando";
	EDITING_AREA = "Editando área. Haz click en cualquier punto externo al área para guardar cambios";
	EDIT_AGENTS = "Configuración de recursos";
	EDIT_AGENTS_PERMISSION = "Este permiso permite editar campos de recursos y asignarlos a incidentes.";
	EDIT_FILE = "Editar archivo";
	EDIT_ICON = "Editar icono";
	EDIT_MISSION = "Editar Incidentes";
	EDIT_MISSION_PERMISSION = "Este permiso permite añadir incidentes.";
	EDIT_POIAREA = "Editar Áreas y Puntos de Interés";
	EDIT_POIAREA_PERMISSION = "Este permiso permite añadir y editar áreas y puntos de interés a un incidente.";
	EDIT_PREPLANNED = "Editar información global";
	EDIT_PREPLANNED_PERMISSION = "Este permiso permite editar tipos de punto de interés globales";
	EDIT_TYPE = "Editar Tipos";
	EDIT_TYPE_PERMISSION = "Este permiso permite acceder a la configuración de tipos.";
	EMAIL = "Email";
	EMAIL_UNSPECIFIED = "Email sin especificar";
	EMPTY = "Vacío";
	ENVIRONMENTAL_RISK = "Riesgo al medio ambiente";
	ENVIRONMENTAL_RISK_MEASURES = "Riesgo al medio ambiente";
	ENVIRONMENT_UNSPECIFIED = "Riesgo al medio ambiente sin especificar";
	ERROR_PROMPT = "Error. El servidor devolvió este mensaje: \n";
	FILE_UNSUPPORTED = "Archivo incompatible. ";
	FILE_UNSUPPORTED_2 = "Archivo incompatible";
	FILE_ATTACHED = "Archivo subido";
	GO_TO = "Ir a";
	GPS_DEVICE = "Dispositivo GPS";
	GPS_DEVICES = "Dispositivos GPS";
	GPS_ID = "Identificador de GPS";
	GPS_TRACK = "Seguimiento GPS";
	EVACUATION = "Evacuación";
	EVACUATION_FORM_SHORT = "Form. Evacuación";
	EF = "El FE";
	EVENT = "Incidente";
	EVENT_2 = "Evento";
	EVENTS = "Incidentes";
	EVENT_TYPE = "Tipo de incidente";
	EVENT_TYPES = "Tipos de incidente";
	ESCALATING = "Escalando";
	EXIT = "salir de";
	EXISTING_CONTROL_MEASURES = "Medidas de control existentes";
	EXPECTED_RESULT = "Resultado esperado";
	EXPECTED_RESULTS = "Resultados esperados";
	FAQ_MENU = "FAQ";
	FEED = "Feed";
	FILES = "Archivos";
	FILE_TO_LARGE = "Archivo muy grande";
	FILE_TO_LARGE_MESSAGE = "Elige un archivo que sea más pequeño que 100 MB";
	FIRE = "Fuego";
	FIRE_SERVICE = "Bomberos";
	FIREFIGHTERS = "Bomberos";
	FILE_TYPES = "(JPG, JPEG, SVG o PNG)";
	FILE_TYPES_OVERLAY = "(JPG, JPEG, SVG, PNG o PDF)";
	FILE_TYPES_POI = "(JPG, JPEG, PNG, MP4 o PDF)";
	FILE_MAX_LIMIT_10MB = "Límite máximo del archivo: 10MB";
	FILTERS = "Filtros";
	FILTERS_SHOW_PRIORITISED = "Mostrar solo las prioridades";
	EDIT_OFV_TOOLS = "Editar herramientas";
	FILTER_BY = "Filtrar por";
	FILL = "Llenar";
	FILLED_BY = "Completado por";
	FILL_REQUIRED_FIELDS = "Para guardar completa: ";
	FINISH = "Terminar";
	FINISHED = "Terminada";
	FINISH_EDITING = "Terminar edición";
	FIRE_ORIGIN = "Origen del fuego";
	FLAT = "Apartamento";
	FLOOR = "Planta";
	FLOOR_UP = "Subir planta";
	FLOOD = "Inundación";
	FOR = "para";
	FORM_CREATED = "creado";
	FORM_USED = "Formulario en uso";
	FORMS = "Formularios";
	FULL_EVACUATION = "Evacuación completa";
	FSG_ADD_FIRE = "Añadir Ubicación del fuego";
	FSG_ADD_FLAT = "Añadir abajo";
	FSG_ADD_ROW_ABOVE = "Añadir arriba";
	FSG_ADD_ROW = "Añadir apartamento";
	FSG_ADVICE = "Consejo";
	FSG_BRIDGE_SECTOR = "Sector de enlace";
	FSG_CASUALTY_STATUS = "Estado víctimas";
	FSG_COMMENTS = "Comentarios ";
	FSG_CLEAR = "Despejado";
	FSG_CLEARED = "Evacuado";
	FSG_CONDITIONS = "Condiciones";
	FSG_DELETE = "Eliminar Fila";
	FSG_CREATION = {
		NAME: "Titulo del formulario de evacuación",
		FLOORS: "Nº plantas",
		FLATS_PER_FLOOR: "Nº apart.",
		FIRE_ORIGIN: "Origen del fuego",
		FLATS: "Apart."
	};
	FSG_DELETE_FSG = "Eliminar Formulario";
	FSG_DELETED = "Formulario de evacuación eliminado.";
	FSG_DELETE_MODAL_MESSAGE = "¿Desea eliminar la fila seleccionada?";
	FSG_DELETE_ERROR = "Error";
	FSG_DELETE_DESCR = "El formulario seleccionada no se puede eliminar ya que contiene información";
	FSG_DELETE_ROW_DESCR = "La fila seleccionada no se puede eliminar ya que contiene información";
	FSG_DELETE_ROW = "Eliminar";
	DOWNLOAD_FORM = "Descargar";
	DOWNLOAD_EF_INFO = "En blanco o con la situación actal";
	FSG_DOWNLOAD_EMPTY = "Vacío";
	FSG_DOWNLOAD_FULL = "Histórico";
	FSG_FIRE_SECTOR = "Fuego";
	FSG_FIRE_LOCATION = "Ubicación fuego";
	FSG_FLAT = "Apto.";
	FSG_FLAT_NUMBER = "Nº Apto.";
	FSG_FLOOR = "Piso";
	FSG_FLOOR_NUMBER = "Nº planta";
	FSG_FLOOR_UPDATED = "La planta ha sido actualizada";
	FSG_HISTORY = "Historial";
	FSG_LOBBY_SECTOR = "Vestíbulo";
	FSG_LOCATION_FORM = "Ubicación";
	FSG_LOCATION_TITLE = "Ubicación";
	FSG_PEOPLE = "Personas";
	FSG_PRIORITY = "Prioridad";
	FSG_SAVED = "Nuevo Formulario de Evacuación creado";
	FSG_SEARCH_SECTOR = "Búsqueda";
	FSG_SECTOR_INFO = "Info de Sector";
	FSG_SELECT_ADDRESS = "Seleccione la dirección";
	FSG_SELECT_TEXT = "Selecionar una opción";
	FSG_SELECT_MORE_TEXT = "Selecionar una o más opciones";
	FSG_SOI = "Fuente de información ";
	FSG_SOURCE = "Fuente";
	FSG_TITLE = "Formulario de Evacuación";
	FSG_SHORT_TITLE = "EF";
	FSG_UNCLEAR = "No despejado";
	FSG_UPDATED = "actualizado";
	FSG_UPDATE_SECT = "Actualizar sector";
	FROM = "Desde";
	GENERATING_FSG_BLANK_DOCUMENTATION = "Generando documentación de Evacuation Form template";
	GENERATING_FSG_DOCUMENTATION = "Generando documentación de Evacuation Form log";
	OFFLINE_GO_BACK = "Puedes volver a la Página Principal y acceder a otra parte de IRIS.";
	GOALS = "Objetivos";
	GOTO_MISSION = "Acceder a incidente";
	HELP_CENTER = "Centro de ayuda";
	JESIP_DEBRIEF_COLOCATE_1 = "¿Los comandantes eran fácilmente identificables?";
	JESIP_DEBRIEF_COLOCATE_2 = "¿Qué estructuras de mando estaban activos?";
	JESIP_DEBRIEF_COLOCATE_3 = "¿Se reunieron los comandantes en persona?";
	JESIP_DEBRIEF_COLOCATE_4 = "¿Se estableció un Puesto de Mando Avanzado (PMA)?";
	JESIP_DEBRIEF_COLOCATE_5 = "¿Tuvieron los comandantes sesiones informativas oportunas en el lugar?";
	JESIP_DEBRIEF_COMMUNICATE_1 = "¿Se utilizó terminología común?";
	JESIP_DEBRIEF_COMMUNICATE_2 = "¿Se utilizó un grupo de conversación sobre interoperabilidad de los dispositios de comunicación?";
	JESIP_DEBRIEF_COMMUNICATE_3 = "¿Se compartió información relevante en todos los servicios y salas de control durante todo el incidente?";
	JESIP_DEBRIEF_COMMUNICATE_4 = "¿Se utilizó el M/ETHANE para pasar información al control?";
	JESIP_DEBRIEF_COMMUNICATE_5 = "¿Se establecieron comunicaciones efectivas?";
	JESIP_DEBRIEF_COORDINATE_1 = "¿Los comandantes utilizaron el MDC como un modelo de decisión único?";
	JESIP_DEBRIEF_COORDINATE_2 = "¿Se identificaron las capacidades y responsabilidades de cada uno de los equipos?";
	JESIP_DEBRIEF_COORDINATE_3 = "¿Se tomaron decisiones conjuntas sobre las prioridades y, de ser así, cómo se establecieron y acordaron las prioridades?";
	JESIP_DEBRIEF_COORDINATE_4 = "¿Fueron acciones conjuntas y, por tanto, eficientes y efectivas?";
	JESIP_DEBRIEF_COORDINATE_5 = "¿Se utilizaron adecuadamente TODOS los recursos en el incidente?";
	JESIP_DEBRIEF_COORDINATE_6 = "¿Se entendieron las aptitudes, capacidades y limitaciones de los activos de cada equipo?";
	JESIP_DEBRIEF_COORDINATE_7 = "¿Alguien asumió el papel de coordinadores principales durante las reuniones de múltiples agencias?";
	JESIP_DEBRIEF_JOINTLY_1 = "¿Se identificaron, comprendieron y trataron de manera diferente las amenazas y los peligros en cada equipo de emergencia?";
	JESIP_DEBRIEF_JOINTLY_2 = "¿Se identificaron las limitaciones y capacidades de las personas y los equipos?";
	JESIP_DEBRIEF_JOINTLY_3 = "¿Se logró un entendimiento conjunto del riesgo al compartir información sobre la probabilidad y los impactos potenciales de las amenazas y peligros?";
	JESIP_DEBRIEF_SHARED_1 = "¿Los comandantes tenían un entendimiento común de lo que sucedió, lo que está sucediendo ahora y las consecuencias de los acontecimientos?";
	JESIP_DEBRIEF_SHARED_2 = "¿Comprendió cada uno de los servicios de emergencia su papel en la resolución del incidente?";
	JESIP_DEBRIEF_SHARED_3 = "¿Se utilizó el M/ETHANE con regularidad para proporcionar una imagen operativa común?";
	JESIP_DEBRIEF_SHARED_4 = "¿Qué está sucediendo? ¿Cuáles son los impactos y los riesgos? ¿Qué podría pasar y qué se está haciendo al respecto?";
	JESIP_DEBRIEF_SHARED_5 = "¿Qué estado final se desea? ¿Cuál es el propósito y el objetivo del equipo de emergencia? ¿Qué prioridades informarán y guiarán la dirección de las decisiones?";
	JESIP_DEBRIEF_SHARED_6 = "¿Se decidieron las acciones? ¿Qué se necesitaba hacer para lograr un estado final positivo?";

	JESIP_DEBRIEF_SHARED_SUBHEADER = "¿Se utilizó el modelo de decisión conjunta para identificar la...?";
	JESIP_DEBRIEF_SUBPAGE_TITLE_COLOCATE = "Co-ubicar";
	JESIP_DEBRIEF_SUBPAGE_TITLE_COMMUNICATE = "Comunicar";
	JESIP_DEBRIEF_SUBPAGE_TITLE_COORDINATE = "Coordinar";
	JESIP_DEBRIEF_SUBPAGE_TITLE_JOINTLY = "Comprender el riesgo";
	JESIP_DEBRIEF_SUBPAGE_TITLE_SHARED = "Evaluación situacional";
	JESIP_VOID_DECISION_CARD_BODY = "Haga click en Añadir Nuevo para compartir información del incidente en tiempo real, así como registrar los eventos que sucedan.";
	JESIP_VOID_METHANE_CARD_BODY = "Haga click en Crear para comenzar a compartir información del incidente.";
	JESIP_VOID_IIMARCH_CARD_BODY = "Haga click en Crear para comenzar a presentar la información del incidente.";
	JESIP_VOID_JDM_CARD_BODY = "Haga click en Crear para reunir conjuntamente la información disponible y tomar decisiones.";
	JESIP_VOID_DEBRIEF_CARD_BODY = "Haga click en Crear para capturar todos los aprendizajes relevantes a JESIP.";
	JESIP_VOID_FSG_CARD_BODY = "Haga click en Crear para realizar un seguimiento del proceso de evacuación. Este formulario se puede crear antes o durante un incidente.";
	JESIP_VOID_CHECKLIST_CARD_BODY = "Haga click en Crear para realizar un seguimiento de todos los pasos de JESIP.";
	JESIP_UNSAVED_CHANGES_POPUP_BODY = "Guarde para actualizar estos cambios en la sección de historia";
	HAZARD = "Peligro";
	HAZARD_UNSPECIFIED = "Tipo de peligro no especificado";
	HIDE = "Esconder";
	HIDE_LABEL = "Esconder etiqueta";
	HIGH = "Alto";
	HIGHEST_RISK = "Riesgo más alto";
	HIGHLY_LIKELY = "Muy probable";
	HISTORIC_LOG = "Registro histórico";
	HOME = "Home";
	HOME_MENU = "Inicio incidentes";
	IC = "I.C.";
	HOMEPAGE = "Página Principal";
	ICON = "Icono";
	ICON_PATH = "Dirección del icono";
	ICS_LOADING = "Cargando información ICS";
	ICS_LOADED = "Información ICS cargada";
	IC_SIGNATURE = "Firma de I.C";
	ID = "Identificador";
	IMS_NAME_INPUT_PLACEHOLDER = "A-Z; a-z; 0-9 sin espacios permitidos";
	IMS_NAME_ON_USE = "El ID de cámara especificado ya está en uso.";
	INCIDENT_COMMANDER = "Comandante";
	INCIDENT_COMMANDER_SIGNATURE = "Firma del comandante";
	INCIDENT_ID = "ID del Incidente:";
	INCIDENT_DATE = "Fecha del incidente";
	INCIDENT_NUM = "Núm. de incidente";
	INCIDENT_OPEN_TIME = "Tiempo de apertura";
	INCIDENT_CLOSE_TIME = "Tiempo de cierre";
	INCIDENT_CHECKS = "Comprobación de incidentes";
	INITIAL = "Inicial";
	INITIAL_STATUS = "Estado inicial";
	INITIAL_STATUS_ALERT = "Solo puede haber un estado inicial";
	INPUT_REQUIRED = "Rellena los campos obligatiorios antes de continuar.";
	INTERVENTIONS = "Incidentes";
	INVALID_CAPTCHA = "Captcha incorrecto. Inténtalo de nuevo para iniciar sesión.";
	INVALID_ICON = "El icono no es válido. El icono debe ser de un solo trazo.";
	INVALID_PASSWORD = "Contraseña inválida";
	INVALID_TOKEN = "Ha habido un problema con tu sesión. Habla con el Admin para solucionarlo.";
	INVALID_URL = "La dirección RTMP no es válida. Carece de clave.";
	IRIS_SETTINGS = "Ajustes de Iris";
	IS_INITIAL = "Indica si el estado es el inicial";
	JESIPCHECKLISTITEMS = {
		ITEM1: "Conversaciones multi-agencia solicitadas.",
		ITEM2: "Asignación de grupo de comunicación confirmada.",
		ITEM3: "Llegada a la escena confirmada.",
		ITEM4: "Uso del chaleco adecuado confirmado.",
		ITEM5: "Mensaje METHANE propocionado.",
		ITEM6: "Comandantes de otros servicios identificados y localizados.",
		ITEM7: "Puesto de Mando Avanzado (PMA) establecido.",
		ITEM8: "Registro de decisiones iniciado. ",
		ITEM9: "Miembros del equipo de mando informados."
	};
	KEY = "Clave";
	KML_FILES = "Archivos KML";
	KML_FILE = "Archivo KML";
	KML_INFORMATION = "Información KML";
	KML_LOADING = "Cargando KMLs";
	KML_FILE_INVALID = "Fichero no válido. Solo se aceptan los formatos kml y kmz.";
	KML_FEATURE_INVALID = "El archivo KML/KMZ contiene elementos no compatibles.";
	LAST_UPDATED = "Última actualización:";
	LAST_INCIDENT_CHECK = "Ultima act. de incidente";
	LEAVE_PAGE_POPUP_TEXT = "¿Está seguro que quiere abandonar esta página?";
	LEAVE_PAGE_POPUP_BUTTON = "Abandonar página";
	LEVEL = "Nivel de usuario";
	LEVEL_NAME_TAKEN = "El nombre del nivel de permiso ya está en uso. Intente con otro";
	LEVELS = "Niveles de usuario";
	LEVELS2 = "Niveles";
	LIKELIHOOD = "Probabilidad";
	LIKELIHOOD_L = "Probabilidad (P)";
	LIKELIHOOD_RATING = "Índice de probabilidad";
	LIKEHOOD = "Probab.";
	LINE = "Línea";
	LIVE_INCIDENTS = "Incidentes abiertos";
	LIVE_INCIDENTS_OFV = "Incidentes en vivo";
	LOADING_FINISHED = "Cargado";
	LOCATION = "Ingresa dirección";
	LOCATION2 = "Dirección";
	LOGGED = "Logueado";
	LOGGED_AS = "Logueado como";
	LOG_FORMS = "Registro de decisiones";
	LOGOUT = "Cerrar sesión";
	LOW = "Bajo";
	MAIN = "Principal";
	MARGINAL = "Marginal";
	MAP_VIEW = "Vista de mapa";
	MAP_VIEW_DETAILS = "(Terreno, Satélite)";
	MAP_OBJECTS_LOADING = "Cargando objetos de mapa";
	MAP_OBJECTS_LOADED = "Objetos de mapa cargados";
	MAP_LOADING = "Cargando mapa";
	MAP_LOADED = "Mapa cargado";
	MEASUREMENTS_TAKEN = "Medidas tomadas";
	ME = "(yo)";
	MEDIUM = "Medio";
	MESH = "Malla";
	MESSAGE = "Mensaje";
	MICROSOFT = "Inicio de sesión con Microsoft no disponible.";
	MILESTONE_ACCESS = "Acceso Milestone";
	MILESTONE_CAMS = "Cámaras Milestone";
	MILESTONE_USER = "Usuario Milestone";
	MILESTONE_URL = "URL Milestone";
	MILESTONE_PASSWORD = "Contraseña Milestone";
	MISSING_FIELDS = "Campos sin rellenar";
	MISSION = "Incidente";
	MISSION_CLOSED = "Incidente cerrado";
	MISSING_CAPTCHA = "El valor de reCaptcha debe ser validado";
	MISSION_CREATED_ON = "Misión creada en";
	MISSION_INFORMATION = "Información de incidente";
	MISSION_INFORMATION_INSTRUCTION = "Escribe el nombre del incidente y selecciona el tipo.";
	MISSION_PERMISSIONS = "Permisos de incidente";
	MISSIONS_LOADED = "Incidentes cargados";
	MISSIONS_LOADING = "Cargando incidentes";
	MISSION_NAME = "Nombre de incidente";
	MISSION_NAME_REQUIRED = "Rellena el nombre de incidente para continuar";
	MISSION_NAME_ON_USE = "Nombre de misión en uso";
	MISSION_TYPE_REQUIRED = "Rellena el tipo de incidente para continuar";
	MOBILE = "En movimiento";
	MONTHS_ARRAY = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
	MORE = "más";
	MOVE = "Mover";
	MOVING_FSG_PROMPT = "Arrastra el icono del EF para cambiar su posición";
	MY_RESOURCES = "Mis recursos";
	NAME = "Nombre";
	NAME_ICON_ERROR = "Necesitas introducir un nombre y un icono para guardar";
	NAMING_CONVENTION = "Nomenclatura de apartamentos";
	NAME_IN_USE = "Nombre en uso";
	NAME_REQUIRED = "Nombre requerido";
	NEW = "Nuevo";
	NEW_EVENT = "Nuevo incidente";
	NEW_EVENT_SELECT_AREA = "Dibuja el area de operaciones para el incidente";
	NEW_FLOOR = "Nueva planta";
	NEW_PASSWORD = "Nueva contraseña";
	NEW_SUPPORT = "Nuevo soporte";
	NEW_SECTOR = "Nuevo sector";
	NEW_PLANE = "Nueva capa";
	NEW_TYPE = "NUEVO TIPO";
	NEW_USER = "Nuevo usuario";
	NO = "No";
	NO_ARAS = "No hay ARAs para este incidente.";
	NO_ARAS_YET = "Aún no hay ARAS para este incidente.";
	NO_AVAILABLE_RESOURCES = "No hay ningún recurso disponible en estos momentos.";
	NO_CAMERA = "Sin cámara asignada";
	NO_COMMAND_STRUCTURE = "Sin estructura de comando";
	NO_CREDENTIALS = "Por favor; escriba un nombre de usuario y contraseña.";
	NO_MISSION = "Sin incidente";
	NO_MISSION_SELECTED = "Por favor; selecciona un incidente para continuar.";
	NO_MORE_CHANGES = "No hay más cambios";
	NO_TELEMETRY = "No se puede obtener la telemetría.";
	NO_HAZARD = "Ningún peligro significativo";
	NO_HAZARDS = "No hay peligros con este nivel de peligro";
	NO_RESULTS = "No se han encontrado resultados";
	NO_RESULTS_INFO = "Intente ajustar los parámetros";
	NO_SECTOR = "Sin sector";
	NO_STREAM = "Sin transmisión";
	NO_TASKS = "No hay tareas para este incidente.";
	NO_TASKS_YET = "Aún no hay tareas para este incidente.";
	NOT_FOUND = "No encontrado";
	NONE = "Ninguno";
	NEXT = "Siguiente";
	NEW_ARA = "Nuevo ARA";
	NEW_FSG = "Nuevo Formulario de Evacuación";
	NEW_CONTROL_MEASURE = "Nueva medida de control";
	NUMBER = "Número";
	NUMBER_ABBR = "No.";
	NUMERIC = "Numérico";
	NUMBER_IN_USE = "Número en uso";
	OBSERVATION = "Observación";
	OF = "de";
	OFFENSIVE = "Ofensivo";
	ORGANIZATION = "Organización";
	OVERVIEW = "Vista general";
	ON_SCENE = "En escena";
	OFFLINE_MODE_ENABLED = "Modo offline activado";
	OFV_TOOLS = "Herramientas del OFV";
	ONLINE_MODE_ENABLED = "Modo online activado";
	ONLY_ONE_INITIAL = "Solo puede haber un estado inicial";
	OPEN_EVENTS = "Incidentes abiertos";
	OPENING_INCIDENT = "Abriendo Incidente";
	OPERATIONAL = "Operacional";
	OPTIONS = "Opciones";
	OFFLINE_MODE = "MODO OFFLINE";
	OTHER_AGENCIES = "Otras agencias";
	OUTSIDE_AREA = "Fuera del área de incidente";
	PRIVATEFORM = "Formulario Privado";
	PAGE = "Página";
	PASSWORD = "Contraseña";
	PASSWORD_CONFIRM = "Confirma contraseña";
	PASSWORD_MISMATCH = "Las contraseñas no coinciden";
	PASSWORD_NOT_ALLOWED = "La contraseña debe contener un mínimo de 8 caracteres, al menos 1 minúscula, 1 mayúscula y 1 número. No puede contener el nombre de usuario.";
	PASSWORD_CRITERIA = "La contraseña debe cumplir los siguientes criterios:";
	PASSWORD_CRITERIA_MIN = "Utilice un mínimo de 8 caracteres.";
	PASSWORD_CRITERIA_LETTERS = " Incluya al menos dos de los siguientes: letras minúsculas, letras mayúsculas, números y símbolos.";
	PASSWORD_CRITERIA_USERNAME = "Evita usar tu nombre de usuario.";
	PASSWORD_CRITERIA_SAFE = "Manténgase alejado de secuencias de números fáciles de adivinar, como 1234.";
	PASSWORD_REQUIREMENTS = "La contraseña no cumple con los requisitos de seguridad";
	PATTERN = "Patrón";
	PER_BUILDING = "por edifico";
	PER_FLOOR = "por planta";
	PERMISSIONS = "Permisos";
	PERMISSION_ACCESS_FULL = "Acceso completo";
	PERMISSION_ACCESS_NO = "Sin acceso";
	PERMISSION_ACCESS_PARTIAL = "Acceso parcial";
	PERMISSION_ACCESS_VIEW_ONLY = "Solo visualización";
	PERMISSION_GROUP_ARA = "ARA"; //not given
	PERMISSION_GROUP_CLOSED = "Página de Inicio - Incidentes Cerrados";
	PERMISSION_GROUP_IRIS_SETTINGS = "Configuración de IRIS";
	PERMISSION_GROUP_LIVE = "Página de Inicio - Incidentes abiertos";
	PERMISSION_GROUP_MEDIA = "Descargar multimedia";
	PERMISSION_GROUP_OFV_TOOLS = "Herramientas de OFV";
	PERMISSION_GROUP_RESOURCES_CONFIG = "Configuración de Recursos";
	PERMISSION_GROUP_TIMELINE = "Live Incident Timeline"; //not given
	PERMISSION_GROUP_TASK_MANAGER = "Gestor de tareas";
	PERMISSION_GROUP_USER_CONFIG = "Configuración de Usuarios";
	PERMISSION_INFO_ADMIN = "Control total de la plataforma. Gestión de pre, en vivo y post-incidente.    ";
	PERMISSION_INFO_COMMANDER = "Acceso completo, excluyendo la gestión de usuarios, con la capacidad de invitar invitados a incidentes en vivo.";
	PERMISSION_INFO_CREW = "Acceso restringido a incidentes con limitaciones en la configuración de usuarios y edición de formularios.";
	PERMISSION_INFO_GUEST = "Miembros de otras agencias con permisos limitados de visualización y colaboración.";
	PERMISSION_INFO_SUPPORT = "Personas externas a la organización con acceso restringido a incidentes en tiempo real.";
	PERMISSION_INSTRUCTIONS = "Nivel de Permiso Personalizado. Se le preguntará si quiere guardarlo como nuevo Nivel de Permiso al final del proceso.";
	PERMISSION_LEVEL = "Nivel de usuario";
	PERMISSION_LEVEL_NAME = "Nombre del nivel de usuario";
	PERMISSION_DENIED = "Permiso denegado";
	PERSONAL = "Personal";
	PERSONNEL = "Personal";
	PERSONNEL_SELECTED = "Personal seleccionado";
	PERSONNEL_SELECTED_REMOVE_PROMPT = "Para borrar vaya a la pantalla de configuración de personal.";
	PLANE = "Capa";
	PLANES = "Capas";
	PREFERENCES_UPDATED = "Preferencias actualizadas";
	PENDING = "Pendiente";
	PHASE_EVACUATION = "Evacuación a fases";
	PHONE_NUMBER_COPIED = "Número de teléfono copiado";
	ORIGIN = "Origen del fuego:";
	OVERLAY_INFORMATION_AVAILABLE = "Plano del edificio disponible";
	OVERLAY_SAVED = "Capa guardada";
	OVERLAY_SAVED_NEW = "Nueva Capa creada";
	OVERLAY_DELETED = "Capa borrada";
	PLAYBACK_LOADING = "Cargando revisión; por favor espere.";
	PLAYBACK_INFO = "Reprodúcelo en tu Reproductor habitual o descarga VLC Video Player.";
	PLAYBACK_ISSUE = "El video no se puede reproducir";
	POLICE = "Policía";
	POI = "Punto de interés";
	POI_1 = "El POI";
	POIS = "Puntos de interés";
	POI_ADDED = "Nuevo POI añadido";
	POI_TYPE = "Tipo de punto de interés";
	POI_DELETED = "Punto de interés eliminado";
	POI_DELETED_HEADER = "POI Eliminado";
	POI_DELETED_BODY = "El Punto de interés ha sido eliminado";
	POI_UPDATED = "Punto de interés actualizado";
	POI_SAVED = "Nuevo POI añadido";
	POI_STATUS_PREDICT = "Previsto";
	POI_STATUS_CURRENT = "Presente";
	POI_STATUS_OVER = "Concluido";
	POSITION_TRACK = "Seguimiento GPS de posición; puede ser cambiado manualmente";
	EF_PREFERENCES = "Preferencias";
	PREFERENCE_INFO = "Cambia el nombre y la nomenclatura de pisos";
	PREPLANNED = "Preplanificado";
	PREPLANNED_DESCRIPTION = "POIs de este tipo estarán disponibles en todos los incidentes";
	PREPLANNED_POI = "POI preplaneado";
	PROBABLE = "Probable";
	PROGRESS = "Progreso";
	RADIUS = "Radio";
	RATIONALE = "Razón";
	REASONABLY = "Razonable";
	REASSIGN = "Reasignar";
	RECOMMENDATION = "Recomendación";
	RECOMMENDATION_NOT_AVAILABLE = "No hay recomendaciones disponibles.";
	RECOMMENDATION_HELP_TOOLTIP = "Después de guardar o actualizar un ARA, IRIS puede ofrecer una recomendación sobre cuales recursos implementar según su disponibilidad";
	RECOMMENDATION_PERSONNEL_TOOLTIP = "Número total de personal recomendado para este incidente";
	RECOMMENDATION_APPLIANCES_TOOLTIP = "Número total de medios recomendados para este incidente";
	RECOMMENDED_RESOURCES = "Recursos Recomendados";
	RECORD = "Grabar";
	REMOTE = "Remota";
	RELOAD = "Actualizar";
	REMOVE = "Borrar";
	REMOVE_ERROR = "Imposible borrar el elemento porque se encuentra en uso.";
	REMOVE_FLOOR = "Borrar planta";
	RENAME = "Renombrar";
	REOPEN = "Reabrir";
	REOPEN_MISSION = "Reabrir Incidente";
	REOPEN_MISSION_PROMPT = "Va a reabrir este incidente. ¿Desea continuar?";
	REQUIRED = "requerido";
	REQUIRED_FEMININE = "requerida";
	RESERVED_KEYWORD = "Esa palabra clave esta reservada.";
	RESIZE = "Redimensionar";
	RESOURCE = "Recurso";
	RESOURCES = "Recursos";
	RESOURCES_CONFIGURATION = "Configuración de recursos";
	RESOURCE_ASSIGNMENT = "Asignación de recursos";
	RESOURCE_ASSIGNMENT_DESCRIPTION = "Selecciona los recursos que se desplegarán en el incidente. Puede ser cambiado a posteriori.";
	RESOURCE_LOG = "Registro de recursos";
	RESTRICTED = "Acceso restringido";
	REVIEW = "Revisar";
	REVIEWED = "Revisado";
	REVIEW_NOM = "Revisión";
	REQUESTED = "Pedido";
	REQ = "Ped.";
	REQUESTED_CONTROL_MEASURES = "Medidas de control pedidas";
	RISK = "Riesgo";
	RISK_INFORMATION = "Información de riesgos";
	RISK_INFORMATION_COMPULSORY = "Al menos un Riesgo por Escenario";
	RISK_INFORMATION_SELECT = "Seleccione Información de Riesgo";
	RISK_INFORMATION_CHIPS = "Seleccione la información de riesgos que mejor aplique al escenario";
	RISK_TO_ENVIRONMENT_DEATILS = "Indique las medidas tomadas para mitigar el riesgo al medio ambiente durante el incidente";
	WHO_IS_AT_RISK = "quién está en Riesgo";
	RISKS = "Riesgos";
	RTMP_CAMS = "Cámaras RTMP";
	RTMP_SERVER = "Servidor RTMP";
	ROADS = "Caminos";
	ROADMAP = "Mapa de caminos";
	ROLE = "Rol";
	ROOFTOP = "Azotea";
	RULER_TOOL_PROMPT = "Haga click sobre el mapa para conocer la distancia en metros";
	SATELLITE = "Satélite";
	SAVE = "Guardar";
	SAVE_ERROR = "No se pudo guardar el objeto.";
	SAVE_ERROR_MESSAGE = "Intenta de nuevo o contacta con";
	HAS_BEEN_SAVED = "se ha guardado.";
	SAVED = "guardado";
	SCALE_DOWN = "Desescalar";
	SCENARIO = "Escenario";
	SCENARIO_UNSPECIFIED = "Escenario sin especificar";
	SCENARIOS = "Escenarios";
	SEARCH = "Buscar";
	SEARCH_AREA = "Buscar los tipos de Área";
	SEARCH_POI = "Buscar POIs";
	SEARCH_LOCATION = "BUSCAR DIRECCIÓN";
	//SEARCH_LOCATION= "Dirección de la incidencia";
	SEARCH_TYPE = "Buscar tipo";
	SECTOR = "Sector";
	SECTOR_UNSPECIFIED = "Sector sin especificar";
	SECTORS = "Sectores";
	SECTOR_SELECT_AREA = "Haz click en un área para asignar el nuevo sector; o haz click en Cancelar para volver a la estructura de comando";
	SECTOR_INFO = "Información de sector";
	SEE_ALL = "Ver todo";
	SEGMENT = "Segmento";
	SEGMENTS = "Segmentos";
	SELECT = "Selecciona";
	SELECT_ALL = "Selecciona todos";
	SELECT_COMMANDER = "Indica la posición del comandante del incidente con un click en el mapa. La posición se puede cambiar en cualquier momento.";
	SELECT_FILE = "Selecciona archivo";
	SELECT_LAYER_PROMPT = "Selecciona las capas que mostrar";
	SELECT_LAYER = "Selecciona las capas que mostrar";
	SELECT_LEVEL = "Selecciona nivel/rol";
	SELECT_LIKELIHOOD = "Selecciona probabilidad";
	SELECT_SEVERITY = "Selecciona severidad";
	SELECT_RISK = "Selecciona riesgo";
	SELECT_RISK_WHOIS = "Selecciona quién está en Riesgo";
	SELECT_MISSION = "Seleccione incidente";
	SELECT_MISSION_TYPE = "Selecciona el tipo de incidente";
	SELECT_TYPE = "Selecciona tipo";
	SELECT_SECTOR = "Selecciona sector";
	SELECT_SCENARIO = "Selecciona escenario";
	SELECT_KML = "Selecciona el archivo KML/KMZ";
	SERIOUS = "Seria";
	SET_ADDRESS = "Actualizar ";
	SERVER_ERROR_MESSAGE_1 = "Error inesperado. No se pueden guardar cambios";
	SERVER_ERROR_MESSAGE_2 = "Intente refrescar la página o contacte con";
	SERVER_ERROR_MESSAGE_3 = "si el error persiste.";
	SESSION_SYNC = " La sesión se sincronizará automáticamente cuando recuperes la conexión.";
	SET_OVERLAY_LOCATION = "Arrastre y haga click en guardar para posicionar la Capa";
	SMELL_OF_BURNING = "Olor a quemado";
	VERY_SERIOUS = "Muy seria";
	SEVERITY = "Severidad";
	SEVERITY_S = "Severidad (S)";
	SEVERITY_UNSPECIFIED = "Índice de severidad sin especificar";
	SEVERITY_RATING = "Índice de severidad";
	SHAPE = "Forma";
	SHARE_LOCATION = "Compartir posición";
	SHARE_URL = "Copia el siguiente enlace para compartir";
	SHOW_IN_MAP = "Mostrar en el mapa";
	SHOW_LABEL = "Mostrar etiqueta";
	SHOW_SOCIAL_FEED = "Ver redes sociales";
	SHOW_TRACK = "Ver track";
	SHOW_VIDEO_FEED = "Ver feed de vídeo";
	SIGNAL_LOST = "Se ha perdido la conexión.";
	SIGN = "Firmar";
	SIGN_BELLOW = "Firmar abajo";
	SIGN_ARA = "Firmar";
	SIGNATURE = "Firma";
	SIGNATURE_UNSPECIFIED = "Firma requerida";
	SIGNATURE_MISSING = "Sin firma"; //modified in August 2022
	SIGNATURE_PENDING = "Sin firma";
	SIGNED = "Firmado";
	SIGNED_BY = "Firmado por";
	SIZE = "Tamaño";
	SITUATION = "Situación";
	SKILLS = "Habilidades";
	SKIP = "Omitir";
	S_L = "S+P";
	SPEECH_ERROR = "Error capturando voz; prueba a utilizar el idioma correspondiente";
	SPEECH_NOT_SUPPORTED = "Reconocimiento de voz no implementado en el navegador en uso. Se recomienda usar Google Chrome v33+.";
	STATUS = "Estado";
	STATE = "Estado";
	STATUS_NAME_ON_USE = "Nombre de estado en uso";
	STATION = "Estación";
	STATION_UNSPECIFIED = "Estación sin especificar";
	STREAM_CONNECTION_LOST = "Conexión de transmisión perdida";
	STREAM_FINISHED = "Fin de la transmisión";
	STREAM_NOT_FOUND = "No se ha encontrado transmisión. Click para reintentar.";
	SUPPORT = "Soporte";
	SUPPORT_SECTOR = "Sector Soporte";
	SUPPORT_PHONE_NUMBER = "Teléfono de contacto";
	SUPPORT_TEAM_EMAIL = "Email de contacto";
	SUBBASEMENT = "Sub-sótano";
	SITUATION_BRIEFING = "Análisis de la situación";
	SMOKE = "Humo";
	REQUEST_ERROR = "Error de petición";
	STAY_PUT = "Esperar";
	SWITCH_BUILDING = "Cambiar Edificio";
	TACTICAL = "Táctico";
	TACTICAL_MODE = "Modo táctico";
	TACTICS_UNSPECIFIED = "Modo táctico sin especificar";
	TAKE_INMEDIATE_ACTION = "Adopte medidas inmediatamente";
	TASK_MANAGER = "Gestor de tareas";
	TASKS = "Tareas";
	TERRAIN = "Terreno";
	TIME = "Tiempo";
	TIME_ARRIVAL = "Tiempo de llegada";
	TIME_LEAVE = "Tiempo de retirada";
	TIME_REQUESTED = "Tiempo de petición";
	TIME_STAMP = "Hora";
	TRANSITIONAL = "Ninguno";
	TOTAL_DISTANCE = "Distancia total";
	TO_BE_SIGNED = "Para firmar";
	TRY_AGAIN = "Inténtelo de nuevo";
	TYPE = "Tipo";
	TYPE_IN = "Escriba aquí";
	TYPE_INFORMATION_HERE = "Escriba la información aquí";
	TYPE_OF_EVENT = "Tipo de evento";
	TITLE = "Título";
	TM_CREATION_TIME = "Hora de creación del Gestor de Tareas";
	TM_REVIEW_TIME = "Hora de actualización del Gestor de Tareas";
	TO = "Hasta";
	UNABLE_TO_DEACTIVATE = "Imposible de desactivar";
	UNABLE_TO_ACTIVATE_INFO = "Este Formulario está activo en incidente";
	UNABLE_TO_DEACTIVATE_INFO = "no se puede desactivar porque contiene información.";
	UNABLE_TO_DELETE = "Imposible borrar";
	UNABLE_TO_DOWNLOAD = "Imposible descargar";
	UNABLE_TO_GENERATE = "Archivos no generados";
	UNAVAILABLE = "No disponible";
	UNASSIGNED = "Sin asignar";
	UNASSIGNED_SECTOR = "Sector sin asignar";
	UNASSIGNED_USER_HEADER = "Usuario no asignado";
	UNASSIGNED_USER_BODY = "No se encuentra asignado a este incidente";
	UNCHECK = "Desmarcar";
	UNCHECKED = "Desmarcado";
	UNCHECK_SINGLE_POPUP_MESSAGE = "Va a desmarcar esta casilla. ¿Desea continuar?";
	UNCHECK_SINGLE_POPUP_TITLE = "Desmarcar casilla";
	UNCHECK_ALL_POPUP_MESSAGE = "Va a desmarcar todas las casillas.";
	UNCHECK_ALL_POPUP_TITLE = "Desmarcar casillas";
	UNCLEARED = "Despejado";
	UNSAVED_CHANGES = "Cambios no guardados";
	UNSAVED_INFO = "Hay cambios sin guardar. Descártelos para salir de la página o revíselos para guardarlos.";
	UNSPECIFIED = "Sin especificar";
	UNLIKELY = "No probable";
	UPDATE = "Actualizar";
	UPDATED = "actualizado";
	UPDATE_TELEMETRY = "Actualizar telemetría";
	UPLOAD = "Subir";
	UPDATE_AVAILABLE = "Nuevas actualizaciones disponibles";
	UPLOAD_IMAGE_INSTRUCTION = "Arrastra o busca una imagen (JPG, PNG, SVG o PDF)";
	URL_RTSP_ORIGIN = "URL RTSP o vacío para RTMP";
	URL_RTMP_ORIGIN = "URL de origen RTMP o similar";
	URL_TOOLTIP1 = "La dirección donde se está emitiendo el video.";
	URL_TOOLTIP2 = "Arriba se indica la dirección RTSP que es opcional.";
	URL_TOOLTIP3 = "Abajo se indica la dirección RTMP.";
	USER = "Usuario";
	USER_ACCESS = "Acceso de usuario";
	USER_FILL_UNSPECIFIED = "Usuario firmante sin especificar";
	USER_AND_PERMISSIONS = "Permisos y usuarios";
	USER_LOADED = "Usuario cargado";
	USER_LOADING = "Cargando usuario";
	USER_LEVELS = "Niveles de usuario";
	USER_CONFIGURATION = "Configuración de Usuarios";
	USER_MANAGEMENT = "Administración de usuarios";
	USER_MANAGEMENT_PERMISSION = "Este permiso permite añadir; editar y borrar cuentas de usuario y roles";
	USER_MANUAL = "Manual de usuario";
	USER_MISSING_FIELDS = "No se puede crear un usuario sin nombre, contraseña o nivel de permisos";
	USER_NAME_IN_USE = "Nombre de usuario en uso";
	USER_NAME_TOO_LONG = "Nombre de usuario demasiado largo. Debería contener menos de 50 characters";
	USER_RESOURCE = "El usuario no es un recurso";
	USERNAME = "Usuario";
	USERNAME_TAKEN = "Nombre de usuario en uso. Intente con otro";
	USERS = "Usuarios";
	USERS_MENU = "Configuración usuarios";
	VIDEO_FEED_ERROR = "No se puede conectar al feed de vídeo.";
	VIDEO_FEEDS_ROW = "Feeds de video por fila";
	VIEW = "Ver";
	VIEW_ARA = "Ver ARA";
	VIEW_DL = "Ver";
	VIEW_LOG = "Revisar";
	VIEW_ATTACHMENT = "Ver archivo adjunto";
	VIEW_FULL_PROFILE = "Ver perfil";
	WAITING_ROOM = "Sala de Espera";
	WAITING_ROOM_MESSAGE = "Aún no ha sido asignado a un incidente.";
	WAITING_ROOM_RETRY = "Inténtelo de nuevo o contacte a su equipo para ser asignado.";
	WARNING = "Advertencia";
	WARNING_LARGE_FILES = "Cargar grandes archivos (>3MB) puede ralentizar en exceso la aplicación.";
	WHOLE_INCIDENT = "Todo el incidente";
	WORK_SECTOR = "Sector de trabajo";
	WRONG_CREDENTIALS = "El nombre de usuario o contraseña es incorrecto";
	TOKEN_EXPIRED = "La sesión ha expirado. Por favor, vuelva a introducir su usuario.";
	YES = "Sí";
	BRIDGEHEAD = "Sector de enlace";
	LOBBY = "Vestíbulo";
	BY = "Por";
	LAST_UPDATE = "Última actualización";
	ADD_NEW = "Añadir";
	COMPLETE = "Completar";
	DIRECTION = "Dirección";
	ACTION = "Acción";
	LOCATION3 = "Ubicación";
	TYPE_OF_INCIDENT = "Tipo de incidente";
	DEBRIEF = "Debrief";
	SHARED_FORM = "Formulario compartido";
	METHANE = "M/ETHANE";
	JESIP_COLOCATE_TOOLTIP = "Ubíquese con los comandantes tan pronto como sea posible en un lugar único, seguro y fácilmente identificable cerca de la escena.";
	JESIP_COMMUNICATE_TOOLTIP = "Comunicarse claramente utilizando un lenguaje claro.";
	JESIP_COORDINATE_TOOLTIP = "Coordinar acordando el equipo que liderará el incidente. Identifique las prioridades, los recursos y las capacidades para una respuesta eficaz e incluya el calendario de reuniones adicionales.";
	JESIP_JOINTLY_TOOLTIP = "Comprender conjuntamente el riesgo, compartiendo información sobre la probabilidad y el impacto potencial de las amenazas y peligros para acordar las posibles medidas de control.";
	JESIP_SHARED_TOOLTIP = "Conciencia situacional compartida establecida mediante el uso de M/ETHANE y el Modelo de Decisión Conjunta.";
	INCIDENT_INFORMATION = "Información de incidente";
	GENERAL_INFORMATION = "Información general";
	DEBRIEF_TEAM_INFORMATION = "Información de asistentes al Debrief";
	ADD_TEAM_MEMBER = "Añadir miembro de equipo";
	ADD_COMMANDER = "Añadir comandante";
	COMMANDER_INFO = "Información de los Comandantes";
	FIRST_CALLER_INFO = "Información del primer asistente";
	HISTORY = "Historial";
	NOTES = "Notas";
	JESIP_METHANE_ITEMS_TITLE_0 = "Incidente Grave";
	JESIP_METHANE_ITEMS_TITLE_1 = "Ubicación Exacta";
	JESIP_METHANE_ITEMS_TITLE_2 = "Tipo de Incidente";
	JESIP_METHANE_ITEMS_TITLE_3 = "Peligros";
	JESIP_METHANE_ITEMS_TITLE_4 = "Acceso";
	JESIP_METHANE_ITEMS_TITLE_5 = "Número de Víctimas";
	JESIP_METHANE_ITEMS_TITLE_6 = "Equipos de Emergencia";
	JESIP_METHANE_ITEMS_DESCRIPTION_0 = "¿Se ha declarado un incidente grave? (Si no, complete el mensaje ETHANE)";
	JESIP_METHANE_ITEMS_DESCRIPTION_1 = "¿Cuál es la ubicación exacta o el área geográfica del incidente?";
	JESIP_METHANE_ITEMS_DESCRIPTION_2 = "¿Qué tipo de incidente es?";
	JESIP_METHANE_ITEMS_DESCRIPTION_3 = "¿Qué peligros o peligros potenciales se pueden identificar?";
	JESIP_METHANE_ITEMS_DESCRIPTION_4 = "¿Cuáles son las mejores rutas de acceso y salida?";
	JESIP_METHANE_ITEMS_DESCRIPTION_5 = "¿Cuántas víctimas hay y en qué condición se encuentran?";
	JESIP_METHANE_ITEMS_DESCRIPTION_6 = "¿Cuáles y cuántos activos / personal de respuesta a emergencias se requieren o están presentes?";
	SAVE_TO_HISTORY = "Guardar en Histórico";
	METHANE_SAVED = "M/ETHANE Guardado";
	IIMARCH_SAVED = "IIMARCH Guardado";
	IIMARCH = "IIMARCH";
	JESIP_IIMARCH_ITEMS_TITLE_0 = "Información";
	JESIP_IIMARCH_ITEMS_TITLE_1 = "Intención";
	JESIP_IIMARCH_ITEMS_TITLE_2 = "Método";
	JESIP_IIMARCH_ITEMS_TITLE_3 = "Administración";
	JESIP_IIMARCH_ITEMS_TITLE_4 = "Riesgos y evaluación";
	JESIP_IIMARCH_ITEMS_TITLE_5 = "Comunicaciones";
	JESIP_IIMARCH_ITEMS_TITLE_6 = "Asuntos Humanitarios";
	JESIP_IIMARCH_ITEMS_DESCRIPTION_0 = "¿Qué, dónde, cuándo, cómo, cuántos, entonces qué, que podría?";
	JESIP_IIMARCH_ITEMS_DESCRIPTION_1 = "¿Por qué estamos aquí, qué estamos tratando de lograr?";
	JESIP_IIMARCH_ITEMS_DESCRIPTION_2 = "¿Cómo lo vamos a hacer?";
	JESIP_IIMARCH_ITEMS_DESCRIPTION_3 = "¿Qué se requiere para una implementación efectiva, eficiente y segura?";
	JESIP_IIMARCH_ITEMS_DESCRIPTION_4 = "¿Cuáles son los riesgos relevantes y qué medidas se requieren para mitigarlos?";
	JESIP_IIMARCH_ITEMS_DESCRIPTION_5 = "¿Cómo vamos a iniciar y mantener comunicaciones con todos los colaboradores y partes interesadas?";
	JESIP_IIMARCH_ITEMS_DESCRIPTION_6 = "¿Qué problemas de asistencia humanitaria y derechos humanos surgen o pueden surgir de este evento y la respuesta a ellos?";
	JESIP_JDM = "Joint Decision Model";
	JDM_SAVED = "Joint Decision Model Guardado";
	JESIP_JDM_CIRCLE_TEXT = ["Trabajar juntos", "Salvar vidas", "Reducir daños"];
	JESIP_JDM_ITEMS_FULL_TITLE_0 = "Reunir información e inteligencia";
	JESIP_JDM_ITEMS_FULL_TITLE_1 = "Evaluar riesgos y desarrollar una estrategia de trabajo";
	JESIP_JDM_ITEMS_FULL_TITLE_2 = "Considerar autoridades, políticas y procedimientos";
	JESIP_JDM_ITEMS_FULL_TITLE_3 = "Identificar oportunidades y contingencias";
	JESIP_JDM_ITEMS_FULL_TITLE_4 = "Tomar acciones y revisar qué ha pasado";
	JESIP_JDM_ITEMS_SHORT_TITLE_0 = "Información e inteligencia";
	JESIP_JDM_ITEMS_SHORT_TITLE_1 = "Riesgos y estrategia de trabajo";
	JESIP_JDM_ITEMS_SHORT_TITLE_2 = "Autoridades y procedimientos";
	JESIP_JDM_ITEMS_SHORT_TITLE_3 = "Opciones y contingencias";
	JESIP_JDM_ITEMS_SHORT_TITLE_4 = "Acciones requeridas";
	JESIP_JDM_ITEMS_DESCRIPTION_0 = "Esta etapa implica recopilar y compartir información e inteligencia, por ejemplo a través de un mensaje M/ETHANE. El propósito es lograr una consciencia situacional compartida.";
	JESIP_JDM_ITEMS_DESCRIPTION_1 =
		"Los comandantes deben asegurarse de haber revisado y comprendido todos los riesgos para que se puedan implementar las medidas de control adecuadas. Deben trabajar juntos para acordar una estrategia de trabajo que sea un plan de acción para abordar la situación inmediata.";
	JESIP_JDM_ITEMS_DESCRIPTION_2 = "Esta etapa se relaciona con las leyes, los procedimientos operativos o las políticas pertinentes que pueden afectar el plan de respuesta y las capacidades que están disponibles para su implementación.";
	JESIP_JDM_ITEMS_DESCRIPTION_3 = "Casi siempre habrá más de una opción para lograr el resultado final deseado. Los comandantes y los equipos deben trabajar juntos para evaluar las opciones y contingencias.";
	JESIP_JDM_ITEMS_DESCRIPTION_4 = "Crear una consciencia situacional compartida, establecer una dirección de trabajo y la evaluación de opciones llevan a tomar las acciones que se consideran más efectivas y eficientes para resolver una emergencia y recuperar la normalidad.";
	SAVE_NOTES_HERE = "Guarde sus notas aquí";
	DEBRIEF_TEAM_NAMES = "Nombres del equipo de debrief";
	NAME_OF_CALLER = "Nombre de la fuente";
	INCIDENT_LOCATION = "Ubicación";
	SPANISH = "Español";
	ENGLISH = "Inglés";
	RESOURCE_TYPE = "Debe seleccionar el tipo de recurso";
	INCIDENT = "Incidente";
	INCIDENT_CLOSED = "Incidente cerrado";
	PREPARING_DOCUMENTATION = "Preparando documentación...";
	GENERATING_DOCUMENTATION = "Generando documentación...";
	GENERATING_FILES = "Generando archivos";
	COMPRESSING_DOCUMENTATION = "Comprimiendo documentación...";
	USER_AND_RESOURCE = "El usuario y recurso";
	DELETE_RESOURCE = "Desea eliminar también el usuario";
	NO_RESULTS_FOUND = "No se encontraron resultados";
	OVERLAY = "Capa";
	OVERLAY_2 = "La Capa";
	COORDINATES_COPIED = "Coordenadas copiadas";
	SHARE_POI = "Compartir POI";
	DELETE_POI = "Eliminar POI";
	CONTROL_MEASURE = "Medida de control";
	SUB_CONTROL_MEASURE = "Sub medida de control";
	ICON_REQUIRED = "Icono requerido";
	EMPTY_EF_WARNING = "Para crear un formulario de evacuación, primero debe establecer la ubicación en la OFV";
	SORT = "Ordenar de";
	TO_A = "a";
	TEXT = "texto";
	PREDICTED = "Previsto";
	CURRENT = "Actual";
	OVER = "Concluido";
	OR = "o";
	WELCOME_IRIS = "Bienvenido a IRIS Core";
	LOG_INTO_ACCOUNT = "Inicie sesión en su cuenta";
	KEEP_ME_LOGGED = "Mantener sesión";
	LOG_IN = "Iniciar sesión";
	MISSING_NAME_INFO = "Introducir nombre de usuario";
	MISSING_PASSWORD_INFO = "Introducir contraseña";
	RISK_SCORE_AND_RATING = "Valoración y clasificación de riesgos";
	RISK_SCORE_AND_RATING_SMALL = "Valoración de Riesgo";
	RISK_RATING_MODAL_SELECT_TEXT = "Seleccione la gravedad y la probabilidad para calcular la valoración y la clasificación de riesgos";
	RISK_RATING_MODAL_SEVERITY_1 = "1 - Lesión Menor: primeros auxilios";
	RISK_RATING_MODAL_SEVERITY_2 = "2 - Perjudicial: lesiones leves o problemas de salud, ausencia de menos de 3 días";
	RISK_RATING_MODAL_SEVERITY_3 = "3 - Extremadamente perjudicial: pérdida de tiempo, ausencia de más de 3 días";
	RISK_RATING_MODAL_SEVERITY_4 = "4 - Lesión grave: pérdida de conciencia o fractura";
	RISK_RATING_MODAL_SEVERITY_5 = "5 - Muerte: muerte única/múltiple o alta pérdida financiera";
	RISK_RATING_MODAL_LIKELIHOOD_1 = "1 - Improbable: la situación está controlada";
	RISK_RATING_MODAL_LIKELIHOOD_2 = "2 - Poco probable: la situación está controlada pero podrían cometerse errores";
	RISK_RATING_MODAL_LIKELIHOOD_3 = "3 - Probable: la situación no está  controlada, hay riesgo de accidentes";
	RISK_RATING_MODAL_LIKELIHOOD_4 = "4 - Muy probable: si las condiciones no cambian, hay una alta probabilidad de un accidente";
	RISK_RATING_MODAL_LIKELIHOOD_5 = "5 - Inevitable: habrá un accidente";
	RISK_RATING_MODAL_GREEN_DESCRIPTOR = "1-4 No es un peligro significativo";
	RISK_RATING_MODAL_YELLOW_DESCRIPTOR = "5 - 10 Considere tomar medidas adicionales";
	RISK_RATING_MODAL_RED_DESCRIPTOR = "15 - 25 Tome medidas inmediatas";
	RISK_RATING_TOOLTIP = "La valoración y clasificación más alta de entre todos los riesgos del sector";
	VIEW_EF = "Ver formulario";
	EDIT_EF = "Editar formulario";
	DELETE_EF = "Borrar formulario";
	OUTSIDE_AREA_INFO = (element: string): string => {
		return `${element} ha sido añadido fuera del área de incidente. Amplíe el área para poder verlo`;
	};
	GENERATING_DOCUMENTATION_PAGE = (idx: number, total: number): string => {
		return "Página " + (idx + 1) + " de " + total;
	};
	DOWNLOADING_DOCUMENTATION_PAGE = (idx: number, total: number): string => {
		return "Página " + (idx + 1) + " de " + total;
	};
}
