import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from "@angular/core";

@Component({
	selector: "app-color-palette",
	templateUrl: "./color-palette.component.html",
	styleUrls: ["./color-palette.component.css"]
})
export class ColorPaletteComponent implements AfterViewInit, OnChanges {
	@ViewChild("canvas") canvas!: ElementRef<HTMLCanvasElement>;

	@Input() color: string = "";
	@Input() hue: string = "";
	@Input() changeOrigin: number = 0;
	@Output() selectedColor: EventEmitter<string> = new EventEmitter();
	@Output() changeOriginOut: EventEmitter<number> = new EventEmitter();
	@Input() updateCb: Function = () => {};


	@HostListener("window:mouseup", ["$event"])
	public readonly onMouseUp: (evt: MouseEvent) => void = (evt) => {
		this.mousedown = false;
	};

	public selectedPosition: { x: number; y: number } = { x: 0, y: 0 };

	private ctx: CanvasRenderingContext2D | null = null;
	private mousedown: boolean = false;
	private firstLoad: boolean = true;


	constructor() {}


	ngAfterViewInit() {
		this.firstLoad = false;
		this.draw();

		this.selectedPosition = this.getColorPosition(this.color);
		this.drawCirle(this.selectedPosition);
	}

	// Redraw when a hue or preferred color is selected
	ngOnChanges(changes: SimpleChanges) {
		if (!this.firstLoad) {
			if (changes["color"]) {
				this.draw();
				this.selectedPosition = this.getColorPosition(this.color);
				if (this.changeOrigin !== 0) {
					this.selectedColor.emit(this.color);
				}
			}

			if (changes["hue"]) {
				this.draw();
				if (this.changeOrigin !== 0) {
					this.selectedColor.emit(this.getColorAtPosition(this.selectedPosition.x, this.selectedPosition.y));
				}
			}

			this.updateCb();
		}
	}


	public readonly draw: () => void = () => {
		if (!this.ctx) {
			this.ctx = this.canvas.nativeElement.getContext("2d");
		}

		const width = this.canvas.nativeElement.width;
		const height = this.canvas.nativeElement.height;

		this.fillGradient(width, height);

		if (this.selectedPosition) {
			this.drawCirle(this.selectedPosition);
		}
	};

	public readonly emitColor: (x: number, y: number) => void = (x, y) => {
		const rgbaColor = this.getColorAtPosition(x, y);
		this.changeOriginOut.emit(1);
		this.selectedColor.emit(rgbaColor);
	};

	public readonly getColorAtPosition: (x: number, y: number) => string = (x, y) => {
		const imageData = this.ctx!.getImageData(x, y, 1, 1).data;
		return "rgba(" + imageData[0] + "," + imageData[1] + "," + imageData[2] + ",1)";
	};

	public readonly getColorPosition: (rgba: string) => { x: number; y: number } = (rgbaColor) => {
		let min_distance = 765;
		let min_coord = { x: 0, y: 0 };

		const rgb = rgbaColor.match(/^rgba?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i);
		const red = parseInt(rgb![1]);
		const green = parseInt(rgb![2]);
		const blue = parseInt(rgb![3]);

		const ctx = this.ctx!;
		const width = ctx.canvas.width;
		const height = ctx.canvas.height;
		const data = ctx.getImageData(0, 0, width, height);
		const buffer = data.data;

		for (let y = 0; y < height; y++) {
			let p = y * 4 * width;
			for (let x = 0; x < width; x++) {
				let px = p + x * 4;

				let aux_distance = Math.abs(buffer[px] - red) + Math.abs(buffer[px + 1] - green) + Math.abs(buffer[px + 2] - blue);

				if (aux_distance < min_distance) {
					min_distance = aux_distance;
					min_coord = { x: x, y: y };
				}
			}
		}
		return min_coord;
	};

	// MOUSE EVENTS
	public readonly onMouseDown: (evt: MouseEvent) => void = (evt) => {
		this.mousedown = true;
		this.selectedPosition = { x: evt.offsetX, y: evt.offsetY };
		this.drawCirle(this.selectedPosition);
		this.emitColor(evt.offsetX, evt.offsetY);
		this.updateCb();
	};

	public readonly onMouseMove: (evt: MouseEvent) => void = (evt) => {
		if (this.mousedown) {
			this.selectedPosition = { x: evt.offsetX, y: evt.offsetY };
			this.drawCirle(this.selectedPosition);
			this.emitColor(evt.offsetX, evt.offsetY);
			this.updateCb();
		}
	};

	private readonly fillGradient: (width: number, height: number) => void = (width, height) => {
		const rgbaColor = this.hue || COLOR.WHITE_1;
		const ctx = this.ctx!;

		ctx.rect(0, 0, width, height);
		ctx.fillStyle = rgbaColor;
		ctx.fillRect(0, 0, width, height);

		const whiteGrad = ctx.createLinearGradient(0, 0, width, 0);
		whiteGrad.addColorStop(0, COLOR.WHITE_1);
		whiteGrad.addColorStop(1, COLOR.WHITE_0);
		ctx.fillStyle = whiteGrad;
		ctx.fillRect(0, 0, width, height);

		const blackGrad = ctx.createLinearGradient(0, 0, 0, height);
		blackGrad.addColorStop(0, COLOR.BLACK_0);
		blackGrad.addColorStop(1, COLOR.BLACK_1);
		ctx.fillStyle = blackGrad;
		ctx.fillRect(0, 0, width, height);
	};

	private readonly drawCirle: (position: { x: number; y: number }) => void = (position) => {
		const ctx = this.ctx!;
		ctx.strokeStyle = "white";
		ctx.fillStyle = "white";
		ctx.beginPath();
		ctx.arc(position.x, position.y, 3, 0, 2 * Math.PI);
		ctx.lineWidth = 1;
		ctx.stroke();
	};
}

enum COLOR {
	WHITE_0 = "rgba(255,255,255,0)",
	WHITE_1 = "rgba(255,255,255,1)",
	BLACK_0 = "rgba(0,0,0,0)",
	BLACK_1 = "rgba(0,0,0,1)"
}
