import { Component, Input, OnInit } from "@angular/core";

@Component({
	selector: "app-border-dropdown",
	templateUrl: "border-dropdown.component.html",
	styleUrls: ["border-dropdown.css"]
})
export class BorderDropdownComponent {
	// object is the data object
	@Input() object: any;

	// options the array of items that will show on the dropdown menu
	@Input() options: Array<any> = [];

	@Input() placeholderText: string = "";

	// Specifies whether filtering should be enabled in the dropdown
	@Input() filterEnabled: boolean = false;

	// Specifies the field to search by
	@Input() searchBy: string = "name";

	/**
	 * getObjectText returns the text value that'll be written on the top of the dropdown
	 */
	@Input() getObjectText: Function = () => {
		return "";
	};

	/**
	 * compareSelect returns whether an option is the selected one
	 */
	@Input() compareSelect: Function = () => {
		return false;
	};

	/**
	 * getOptionText returns the text value that'll be written on each item
	 */
	@Input() getOptionText: Function = () => {
		return "";
	};

	/**
	 * getOptionImage ret
	 */
	@Input() getOptionColor: Function = () => {
		return "";
	};

	/**
	 * onChangeCb is executed whenever the content of the input field changes
	 */
	@Input() onChangeCb: Function = () => {};

	openMenu: boolean = false;
	query: string = "";

	constructor() {}

	change: Function = (query: string) => {
		this.onChangeCb(query);
	};

	filterFunc: (item: any) => boolean = (item: any) => {
		if (this.query && this.query.length > 0) {
			return item[this.searchBy].toUpperCase().includes(this.query.toUpperCase()) ? true : false;
		}
		return true;
	};

	public readonly stopPropagation: Function = (evt: MouseEvent | TouchEvent) => {
		evt.stopPropagation();
	};
}
