import { BehaviorSubject } from "rxjs";
import { Injectable } from "@angular/core";

export const DEFAULT = {
	SIDE_MENU_SHORT: "9",
	SIDE_MENU_EXPANDED: "8",
	SIDE_MENU_EVF: "15"
};

export const UNSET = "unset";

@Injectable({
	providedIn: "root"
})
export class MenuService {
	public sideMenuShortZIndex: BehaviorSubject<string> = new BehaviorSubject(DEFAULT.SIDE_MENU_SHORT);
	public sideMenuExtendedZIndex: BehaviorSubject<string> = new BehaviorSubject(DEFAULT.SIDE_MENU_EXPANDED);
	public sideMenuEvf: BehaviorSubject<string> = new BehaviorSubject(UNSET);

	public isExtendedMenu: BehaviorSubject<any> = new BehaviorSubject(false);

	constructor() {}

	public openEvfSideMenu(): void {
		this.sideMenuEvf.next(DEFAULT.SIDE_MENU_EVF);
		this.sideMenuExtendedZIndex.next(UNSET);
		this.sideMenuShortZIndex.next(UNSET);
		this.isExtendedMenu.next(false);
	}

	public closeEvfSideMenu(): void {
		this.sideMenuEvf.next(UNSET);
		this.sideMenuExtendedZIndex.next(DEFAULT.SIDE_MENU_EXPANDED);
		this.sideMenuShortZIndex.next(DEFAULT.SIDE_MENU_SHORT);
	}

	public extendMenu(): void {
		this.isExtendedMenu.next(true);
	}
}
