import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Resource } from "src/app/dto/resources/resource";
import { ResourceState } from "src/app/dto/resources/state";
import { ResourceService } from "src/app/settings/resource/resource.service";

@Component({
	selector: "app-states-menu",
	templateUrl: "states-menu.component.html",
	styleUrls: ["states-menu.css"]
})
export class StatesMenuComponent {
	@Input() item!: Resource;

	@Output() closed = new EventEmitter<void>();

	public readonly resourceStates;
	private readonly resourceService: ResourceService;

	constructor(res: ResourceService) {
		this.resourceService = res;

		this.resourceStates = this.resourceService.States;
	}

	public readonly selectState: (state: ResourceState) => void = (state) => {
		this.item.id_state = state.id;
		this.item.__state = state;
		this.resourceService.saveAgent(this.item);
	};

	public selectedState(state: ResourceState): boolean {
		return state === this.item.__state!;
	}

	public readonly close = (): void => {
		this.closed.emit();
	};
}
