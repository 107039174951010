import { Camera } from "../resources/camera";
import { Resource } from "../resources/resource";

export class VideoFeedSlot {
	agent: Resource | undefined;
	camera: Camera | undefined;
	imsPeer: string | undefined;
	status: VIDEO_FEED_STATUS;
	message: string | undefined;
	paused: boolean | undefined;

	constructor(agent?: Resource, camera?: Camera, message?: string, paused?: boolean) {
		this.agent = agent;
		this.camera = camera;
		this.imsPeer = camera?.ims_name;
		this.status = VIDEO_FEED_STATUS.CLOSED;
		this.message = message;
		this.paused = paused;
	}

	public readonly empty: Function = () => {
		this.agent = undefined;
		this.camera = undefined;
		this.imsPeer = undefined;
		this.status = VIDEO_FEED_STATUS.CLOSED;
		this.message = undefined;
		this.paused = undefined;
	};



	public readonly isActive: () => boolean = () => {
		return this.status === VIDEO_FEED_STATUS.OPENING || this.status === VIDEO_FEED_STATUS.STREAMING;
	};
}
export enum VIDEO_FEED_STATUS {
	CLOSED = 0,
	OPENING = 1,
	STREAMING = 2,
	FINISHED = 3,
	NOT_FOUND = 4
}
