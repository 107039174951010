import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FsgFlat } from "src/app/dto/fsg/fsg-flat";
import { FsgService } from "src/app/incident/incident-tools/fsg/fsg.service";
import { LocaleMap } from "src/app/global/constants/text/text-interface";
import { TextProvider } from "src/app/global/constants/text/text-provider";
import { DEFAULT_CONDITION } from "src/app/dto/fsg/enums";

@Component({
	selector: "app-select-condition-popup",
	templateUrl: "fsg-select-condition.component.html",
	styleUrls: ["../fsg-editor-popup.css", "fsg-select-condition.css"]
})
export class FsgSelectConditionPopupComponent implements OnInit {
	@Input() flat!: FsgFlat;
	@Output() selectEmit = new EventEmitter<Array<string>>();
	@Output() cancel = new EventEmitter<void>();

	public readonly text: () => LocaleMap;
	public readonly defaults = DEFAULT_CONDITION;
	public openNewInput = false;
	public newValue = "";

	public selection = new Array<string>();
	public options = new Array<string>();
	public getValIndex = new Map<string, string>();

	private readonly fsg: FsgService;

	constructor(tp: TextProvider, fsgS: FsgService) {
		this.text = tp.getStringMap;
		this.fsg = fsgS;
		let defaults = DEFAULT_CONDITION.values;
		defaults.forEach((val) => {
			if (val !== "") {
				this.options.push(this.fsg.getConditionValueAsLocalizedString(val));
				this.getValIndex.set(this.fsg.getConditionValueAsLocalizedString(val), val);
			}
		});
	}

	ngOnInit(): void {
		if (!this.flat) throw "FsgSelectCondition component initialized without a valid flat object";
		this.selection = this.flat.condition.slice().filter((e) => e !== DEFAULT_CONDITION.defaults.get("DEFAULT"));
	}

	public readonly isConditionSelected: (val: string) => boolean = (val) => {
		return this.selection.find((e) => e === val) !== undefined;
	};
	public readonly getConditionLocalizedString: (val: string) => string = (val) => {
		return this.fsg.getConditionValueAsLocalizedString(val);
	};
	public readonly isNotDefault: (val: string) => boolean = (val) => {
		return val !== DEFAULT_CONDITION.defaults.get("DEFAULT");
	};

	public readonly toggleNewInput: (evt?: Event) => void = (evt?) => {
		this.openNewInput = !this.openNewInput;
		if (!this.openNewInput) this.newValue = "";
		if (evt) evt.stopPropagation();
	};

	public readonly newValueClickOut: Function = () => {
		if (this.newValue) {
			this.options.push(this.newValue);
			DEFAULT_CONDITION.values.push(this.newValue);
		}
		this.toggleNewInput();
	};

	public readonly isFire: (val: string) => boolean = (val) => {
		return val.toUpperCase() === this.fsg.getConditionValueAsLocalizedString(DEFAULT_CONDITION.defaults.get("FIRE")!).toUpperCase() || val.toUpperCase() === "FIRE";
	};

	public readonly optionClick: (val: string) => void = (val) => {
		if (!this.selection.find((e) => e === val)) this.selection.push(val);
	};

	public readonly selectionClick: (val: string) => void = (val) => {
		const idx = this.selection.findIndex((e) => e === val);
		if (idx !== -1) this.selection.splice(idx, 1);
	};
}
