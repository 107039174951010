<div class="container">
	<div class="list-header">
		<div class="img-container">
			<img [src]="headerIcon" />
		</div>

		<span>{{ header }}</span>
	</div>
	<div class="list-body">
		<div
			data-cy="jesip-team-list-item"
			*ngFor="let item of members; let idx = index"
			class="item"
		>
			<div class="item-header">
				<div *ngIf="memberTitle">{{ memberTitle + " " + (idx + 1) }}</div>
				<div
					*ngIf="item.id > 2 && !disable"
					class="delete-icon"
					(click)="removeMember(item.id)"
				>
					-
				</div>
			</div>

			<div class="input-container">
				<app-simple-text-field
					[type]="'text'"
					[data]="item.name"
					(dataChange)="onNameChange(item, $event)"
					[placeholderText]="text().NAME"
					[label]="text().NAME"
					[labelBackground]="historyMode ? '#051c2a' : '#080809'"
					[isDisabled]="disable"
					[withBorders]="true"
				></app-simple-text-field>
			</div>
			<div class="dropdown-container">
				<app-transparent-dropdown
					appCheckOrientation
					[object]="item"
					[options]="organizations"
					[getObjectText]="orgDropdownGetMainText"
					[getOptionText]="orgDropdownGetOptionText"
					[compareSelect]="orgDropdownCompareSelect"
					[placeholderText]="placeholder"
					[filterEnabled]="true"
					[addString]="text().CREATE_NEW_ITEM"
					[filterEnabled]="true"
					[addString]="text().CREATE_NEW_ITEM"
					[onChangeCb]="emitDropDownValue"
					[partialDisable]="disable"
					[allowAddItem]="true"
					(newItemKeydownEvent)="organizationAdd.emit($event)"
					(itemClickCallback)="orgDropdownChangeCallback(item, $event)"
				>
				</app-transparent-dropdown>
			</div>
		</div>
		<div *ngIf="canAdd" class="add-section" (click)="addNewMember()">
			<img class="add-icon" src="resources/img/jesip/add.svg" />
			<span class="add-text">{{ addText }}</span>
		</div>
	</div>
</div>
