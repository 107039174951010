export class WSResponse {
	status: RESPONSE_STATUS;
	data: any;
	url: string;

	constructor(response: { body: { singleItem: any; arrayItems: Array<any>; status: string }; status: number; singleItem: any; message: string; url: string }) {
		this.url = response.url;
		if (response.status == 200) {
			if (response.body && response.body.status && (response.body.status === RESPONSE_STATUS.SUCCESS || response.body.status === RESPONSE_STATUS.OK)) {
				if (response.body.status === RESPONSE_STATUS.OK) {
					this.status = RESPONSE_STATUS.SUCCESS;
					this.data = undefined;
				} else {
					if ("singleItem" in response.body && "arrayItems" in response.body) {
						// 200 answer with correct format and success
						this.status = response.body.status;
						this.data = response.body.arrayItems ? response.body.arrayItems : response.body.singleItem !== "undefined" && response.body.singleItem ? response.body.singleItem : false;
					} else {
						// 200 answer with malformed body
						this.status = RESPONSE_STATUS.BAD;
						this.data = false;
					}
				}
			} else {
				// 200 answer with correct format but error
				this.status = RESPONSE_STATUS.ERROR;
				this.data = response.body.arrayItems && response.body.arrayItems.length ? response.body.arrayItems : typeof response.body.singleItem !== "undefined" && response.body.singleItem !== null ? response.body.singleItem : false;
			}
		} else {
			switch (response.status) {
				case 400:
					this.status = RESPONSE_STATUS.BAD;
					break;
				case 401:
					this.status = RESPONSE_STATUS.LOGIN;
					break;
				case 404:
					this.status = RESPONSE_STATUS.NOT_FOUND;
					break;
				case 413:
					this.status = RESPONSE_STATUS.FILE_TO_LARGE;
					break;
				case 500:
					this.status = RESPONSE_STATUS.UNKNOWN;
					break;
				default:
					// Any other types which will most likely by internal server errors or timeouts.
					this.status = RESPONSE_STATUS.CONN;
					break;
			}

			try {
				this.data = JSON.parse(response.singleItem);
			} catch (e) {
				this.data = response.message ? response.message : e;
			}
		}
	}
}

export enum RESPONSE_STATUS {
	SUCCESS = "Success",
	OK = "ok",
	NO_CHANGE = "NoChange",
	ERROR = "Error",
	BAD = "Bad",
	CONN = "Conn",
	LOGIN = "Login",
	UNKNOWN = "500",
	NOT_FOUND = "404",
	FILE_TO_LARGE = "413"
}
